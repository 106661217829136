import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  roles: [],
  notifications: [],
  companies: []
};

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    // méthodes qui vont changer le state de search
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
    changeRoles: (state, action) => {
      state.roles = [...action.payload];
    },
    changeNotifications: (state, action) => {
      state.notifications = [...action.payload];
    },

    resetFilters: state => {
      state.search = '';
      state.companies = [];
      state.roles = [];
      state.notifications = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSearch,
  changeCompanies,
  changeRoles,
  changeNotifications,
  resetFilters
} = membersSlice.actions;

export default membersSlice.reducer;
