import React, { useEffect, useState, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Col, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import pictoPdf from 'assets/img/tredunion/pictos/file-pdf.svg';
import FileUploader from '../../commons/fileUploader';
import * as Moment from 'moment';
import { toast } from 'react-toastify';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';
import IconButton from '../../../common/IconButton';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import {
  DeleteUpload,
  IndexUpload,
  PostUpload
} from '../../../../services/UploadService';
import { IndexUploadCategory } from '../../../../services/UploadCategoryService';
import { membersRoutes } from '../../../../routes/authRoutes';

const TransfertFichier = () => {
  const [trigger, setTrigger] = useState(Date.now);
  const [user] = useOutletContext();
  const [uploadCategories, setUploadCategories] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadIsSent, setUploadIsSent] = useState(false);
  const [formData, setFormData] = useState({
    upload_category_id: '',
    title: '',
    file_url: ''
  });
  const [uploads, setUploads] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  useEffect(() => {
    const fetchUploads = async () => {
      const responseUploads = await IndexUpload(user.main_company.id);
      if (responseUploads.success === true) {
        setUploads(responseUploads.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUploadCategories = async () => {
      const responseUploadCategories = await IndexUploadCategory();
      if (responseUploadCategories.success === true) {
        setUploadCategories(responseUploadCategories.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUploads();
    fetchUploadCategories();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await PostUpload(formData);
    if (response.success === true) {
      toast('Votre fichier a été envoyé avec succès.');
      setUploadIsSent(true);
      setTrigger(Date.now);
    } else if (response.validator) {
      setErrorForm(response.validator);
    } else if (response.success === false) {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const deleteUpload = async () => {
    const response = await DeleteUpload(selectedUpload.id);
    if (response.success === true) {
      setShowDelete(false);
      setUploadIsSent(false);
      setTrigger(Date.now());
      toast('Votre fichier a bien été supprimé.');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    if (e.target.name === 'upload_category_id') {
      setFormData({
        ...formData,
        title:
          uploadCategories.filter(
            category => category.id === parseInt(e.target.value)
          )[0].title !== 'Autre'
            ? uploadCategories.filter(
                category => category.id === parseInt(e.target.value)
              )[0].title
            : '',
        [e.target.name]: e.target.value
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  return (
    <Row className="mt-2">
      {uploadCategories.length > 0 && (
        <Col xs={12}>
          <h5 className="mb-4 redHeading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d={
                  membersRoutes.filter(
                    route => route.id === 'transfererFichier'
                  )[0].svg
                }
                fill="rgba(229,47,18,1)"
              />
            </svg>{' '}
            {
              membersRoutes.filter(route => route.id === 'transfererFichier')[0]
                .label
            }
          </h5>
          {uploadIsSent ? (
            <IconAlert variant="success">
              <p className="mb-0">Votre fichier a été envoyé avec succès.</p>
            </IconAlert>
          ) : (
            <Form onSubmit={handleSubmit}>
              {errorForm && <FormAlert variant="danger" data={errorForm} />}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Catégorie du document</Form.Label>
                <Form.Select
                  size="sm"
                  aria-label="Catégorie du document"
                  name="upload_category_id"
                  onChange={e => handleFieldChange(e)}
                >
                  <option disabled selected>
                    Choisir une catégorie dans la liste
                  </option>
                  {uploadCategories
                    .filter(
                      category =>
                        category.parent_id === null &&
                        category.title !== 'Archives' &&
                        category.id !== 63 &&
                        category.id !== 66 &&
                        category.id !== 69
                    )
                    .map((category, index) => (
                      <Fragment key={index}>
                        <option
                          disabled={category.title !== 'Autre'}
                          value={category.id}
                        >
                          {category.title}
                        </option>
                        {uploadCategories
                          .filter(
                            childCategory =>
                              category.id === childCategory.parent_id &&
                              childCategory.id !== 63 &&
                              childCategory.id !== 66 &&
                              childCategory.id !== 69
                          )
                          .map((childCategory, childIndex) => (
                            <option key={childIndex} value={childCategory.id}>
                              -- {childCategory.title}
                            </option>
                          ))}
                      </Fragment>
                    ))}
                </Form.Select>
              </Form.Group>
              {formData.upload_category_id !== '' &&
                uploadCategories.filter(
                  category =>
                    category.id === parseInt(formData.upload_category_id)
                )[0].title === 'Autre' && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Si autre, indiquez la catégorie de votre document :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      placeholder="Indiquez la sous-catégorie"
                      name="title"
                      value={formData.title}
                      onChange={e => handleFieldChange(e)}
                    />
                  </Form.Group>
                )}
              <FileUploader
                pathToUpload="uploads"
                formData={formData}
                setFormData={setFormData}
                format="any"
              />
              <Col xs={12} className="mt-3">
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  type="submit"
                  disabled={isLoading}
                >
                  Envoyer mon fichier
                </Button>
              </Col>
            </Form>
          )}
        </Col>
      )}
      {uploads.length > 0 && (
        <>
          <Col xs={12} className="mt-6">
            <h5 className="mb-3 redHeading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z"
                  fill="rgba(229,47,18,1)"
                />
              </svg>{' '}
              Mes fichiers
            </h5>
            <Table hover responsive className="table-fichiers">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col" className="d-none d-lg-table-cell">
                    Transféré le
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {uploads.map((resource, index) => (
                  <tr key={index} className="hover-actions-trigger">
                    <td>
                      <img
                        src={pictoPdf}
                        className="d-none d-lg-inline"
                        style={{ width: '14px' }}
                      />{' '}
                      {resource.title}
                    </td>
                    <td className="d-none d-lg-table-cell">
                      {Moment(resource.created_at).local().format('DD/MM/YYYY')}
                    </td>
                    <td className="text-end">
                      <IconButton
                        className="mb-1 me-lg-2"
                        variant="falcon-default"
                        size="sm"
                        icon={faFileDownload}
                        transform="shrink-3"
                        onClick={async () => {
                          window.open(
                            resource.file_url,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                      >
                        Accéder
                      </IconButton>
                      <IconButton
                        className="mb-1"
                        variant="falcon-danger"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        onClick={() => {
                          setShowDelete(true);
                          setSelectedUpload(resource);
                        }}
                      >
                        Supprimer
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Offcanvas
              show={showDelete}
              onHide={() => {
                setShowDelete(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  La suppression est définitive.
                  <br />
                  Voulez-vous continuer ?
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    deleteUpload();
                  }}
                >
                  Oui, supprimer
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </>
      )}
    </Row>
  );
};

export default TransfertFichier;
