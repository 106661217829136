import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const CanvasDeleteEvent = ({
  showCanvasDeleteEvent,
  setShowCanvasDeleteEvent,
  deleteEvent
}) => {
  return (
    <>
      {showCanvasDeleteEvent && (
        <Offcanvas
          show={showCanvasDeleteEvent}
          onHide={() => {
            setShowCanvasDeleteEvent(false);
          }}
          placement="end"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Suppression de l'événement</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            La suppression de l'événement est définitive. Les invitations seront
            annulées mais les participants ne seront pas automatiquement
            notifiés.
            <br /> Voulez-vous continuer ?
            <Row className="mt-4 flex-start">
              {' '}
              <Col lg="12">
                <Button
                  size="sm"
                  className="admin-primary me-2"
                  variant="falcon-danger"
                  onClick={() => {
                    deleteEvent();
                  }}
                >
                  Oui, supprimer l'événement
                </Button>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="admin-primary"
                  onClick={() => {
                    setShowCanvasDeleteEvent(false);
                  }}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default CanvasDeleteEvent;

CanvasDeleteEvent.propTypes = {
  showCanvasDeleteEvent: PropTypes.bool,
  setShowCanvasDeleteEvent: PropTypes.func,
  deleteEvent: PropTypes.func
};
