import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FileUploader from '../../../../commons/fileUploader';
import { SendEventNotification } from '../../../../../../services/Event/EventService';
import { toast } from 'react-toastify';
import { SendSurveyNotification } from '../../../../../../services/Survey/SurveyService';
import FormAlert from '../../../../commons/FormAlert';
import SoftBadge from '../../../../../common/SoftBadge';
import * as Moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

const SendNotification = ({ survey, event, recipients }) => {
  const [showCanva, setShowCanva] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newRecipients, setNewRecipients] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [errorForm, setErrorForm] = useState(false);
  const [confirmationForm, setConfirmationForm] = useState({
    note: '',
    attachments: [],
    emails: '',
    mode: 'all',
    is_confirmation: 0
  });
  useEffect(() => {
    if (event && event.notifications.length > 0) {
      let emails = [];
      event.notifications[0].emails.map(email => {
        emails.push(email.email);
      });
      let newRecipients = [];
      recipients.map(recipient => {
        if (!emails.includes(recipient.email)) {
          newRecipients.push(recipient);
        }
      });
      setNewRecipients(newRecipients);
    }
  }, [trigger]);
  const sendConfirmation = async () => {
    setIsLoading(true);
    setErrorForm(false);
    let response = false;
    if (event) {
      response = await SendEventNotification(event.id, confirmationForm);
    } else {
      response = await SendSurveyNotification(survey.id, confirmationForm);
    }
    setErrorForm(response.validator);
    if (response.success === true) {
      setTrigger(Date.now());
      setIsLoading(false);
      toast('La notification a bien été envoyée au destinataires');
      setShowCanva(false);
      setConfirmationForm({
        note: '',
        attachments: [],
        emails: '',
        mode: 'all',
        is_confirmation: 0
      });
      setTimeout(function () {
        window.location.reload();
      }, 1200);
    } else {
      setIsLoading(false);
      setTrigger(Date.now());
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="me-0 mt-3 mb-3"
        onClick={() => {
          setShowCanva(!showCanva);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"
            fill="rgba(75,74,77,1)"
          />
        </svg>
        Envoyer une notification
      </Button>
      {errorForm && (
        <div className="mt-3">
          <FormAlert variant="danger" data={errorForm} />
        </div>
      )}
      <div className={showCanva ? 'd-block' : 'd-none'}>
        {event &&
          event.id &&
          event.participation_form?.id &&
          Moment().diff(event.start_date, 'days') < 1 && (
            <>
              <Form.Group as={Col} className="mb-4" controlId="formGridSubject">
                <Form.Label>Envoyer à :</Form.Label>
                <Form.Select
                  aria-label="Mode"
                  value={confirmationForm.mode}
                  onChange={e => {
                    setConfirmationForm({
                      ...confirmationForm,
                      mode: e.target.value
                    });
                  }}
                  name="mode"
                  required
                >
                  <option value="all">Tous les destinataires</option>
                  <option value="only_participate">
                    Uniquement les participants (répondu "Oui")
                  </option>
                  {newRecipients.length > 0 ? (
                    <Fragment>
                      <option value="new">
                        Nouveaux destinataires depuis la dernière notif. (
                        {newRecipients.length})
                      </option>
                      <option value="new-yes">
                        Nouveaux destinataires depuis la dernière notif. DONT la
                        réponse est "Oui" (
                        {
                          newRecipients.filter(recipient =>
                            recipients.find(
                              r =>
                                r.email === recipient.email &&
                                r.survey_questions?.find(
                                  sq =>
                                    sq.is_participation_form_main_question ===
                                      1 && sq.pivot.value === 'Oui'
                                )
                            )
                          ).length
                        }
                        )
                      </option>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Form.Select>
              </Form.Group>
              {confirmationForm.mode === 'new' && (
                <Col xs={12} className="mb-4">
                  <span className="d-block mb-3">
                    <b>
                      Nouveaux destinataires depuis la dernière notification :
                    </b>
                  </span>
                  {newRecipients.map((recipient, index) => (
                    <span key={index} className="d-inline">
                      {recipient.email}{' '}
                    </span>
                  ))}
                </Col>
              )}
              {confirmationForm.mode === 'new-yes' && (
                <Col xs={12} className="mb-4">
                  <span className="d-block mb-3">
                    <b>
                      Nouveaux destinataires depuis la dernière notification
                      DONT la réponse est "Oui" :
                    </b>
                  </span>
                  {newRecipients
                    .filter(recipient =>
                      recipients.find(
                        r =>
                          r.email === recipient.email &&
                          r.survey_questions?.find(
                            sq =>
                              sq.is_participation_form_main_question === 1 &&
                              sq.pivot.value === 'Oui'
                          )
                      )
                    )
                    .map((recipient, index) => (
                      <span key={index} className="d-inline">
                        {recipient.email}{' '}
                      </span>
                    ))}
                </Col>
              )}
              <Col xs={12} className="mb-4 d-none">
                <label>
                  <input
                    type="checkbox"
                    id="is_confirmation"
                    name="is_confirmation"
                    value="1"
                    className="me-1"
                    checked={
                      confirmationForm.is_confirmation === 1 ||
                      confirmationForm.is_confirmation === '1'
                    }
                    onChange={e => {
                      setConfirmationForm({
                        ...confirmationForm,
                        is_confirmation: e.target.checked === true ? 1 : 0
                      });
                    }}
                  />
                  S'agit-il de la confirmation de l'événement ?
                </label>
                <span className="smaller-p d-block mb-2">
                  <SoftBadge bg="primary" className="me-0">
                    Information
                  </SoftBadge>{' '}
                  Cocher cette case modifie l'email envoyé aux destinataires. Il
                  contiendra l'information que l'événement est confirmé.
                </span>
                {confirmationForm.is_confirmation === 1 &&
                  event.is_confirmation_sended === 1 && (
                    <Alert variant="warning" className="mt-3 mb-0">
                      Attention, vous avez déjà envoyé une confirmation aux
                      participants pour cet événement.
                    </Alert>
                  )}
              </Col>
            </>
          )}
        <Col xs={12} className="mt-0">
          <span className="d-block mb-3">
            <b>
              Les destinataires vont recevoir un email contenant la ou les
              pièces jointes et la note ci-dessous :
            </b>
          </span>
          <Form.Group>
            <Form.Label>
              Attacher des fichiers <small>facultatif</small>
            </Form.Label>
            {confirmationForm.attachments && (
              <>
                {confirmationForm.attachments.map((file, index) => (
                  <Row key={index} className="align-items-center">
                    <Col xs={8} className="pt-2">
                      <FileUploader
                        pathToUpload="surveys/notifications"
                        formData={confirmationForm}
                        setFormData={setConfirmationForm}
                        format="any"
                        databaseField="file_url"
                        databaseFieldArray="attachments"
                        databaseFieldArrayIndex={index}
                        cropperEnabled={false}
                        progressBar={true}
                      />
                    </Col>
                    <Col xs={4} className="pt-2 px-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        className="cursor-pointer"
                        onClick={() => {
                          const newAttachments =
                            confirmationForm.attachments.filter(
                              (attachment, i) => i !== index
                            );
                          setConfirmationForm({
                            ...confirmationForm,
                            attachments: newAttachments
                          });
                        }}
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                          fill="#e52f12"
                        />
                      </svg>
                    </Col>
                  </Row>
                ))}
              </>
            )}
            <Row>
              <Col xs={12} className="pt-2">
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="mt-2 mb-3"
                  onClick={() => {
                    let tmpForm = confirmationForm;
                    if (!tmpForm.attachments) {
                      tmpForm.attachments = [];
                    }
                    tmpForm.attachments.push({
                      file_url: ''
                    });
                    setConfirmationForm(tmpForm);
                    setTrigger(Date.now());
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                      fill="rgba(77,89,105,1)"
                    />
                  </svg>{' '}
                  Ajouter une pièce jointe
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col xs={12} className="mt-3">
          <Form.Group controlId="served_countries">
            <Form.Label>Ajouter une note</Form.Label>
            <Editor
              value={confirmationForm.note}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              init={{
                browser_spellcheck: true,
                contextmenu: false,
                language: 'fr_FR',
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'link'
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'link',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              onEditorChange={content => {
                setConfirmationForm({
                  ...confirmationForm,
                  note: content
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} className="mb-3 mt-3">
          <Form.Group controlId="name">
            <Form.Label>
              Destinataires en copie{' '}
              <small>
                séparer les adresses par des <b>virgules</b>
              </small>{' '}
              :
            </Form.Label>
            <Form.Control
              type="text"
              name="emails"
              placeholder="email1@email.com,email2@email.com..."
              value={confirmationForm.emails || ''}
              onChange={e => {
                setConfirmationForm({
                  ...confirmationForm,
                  emails: e.target.value
                });
              }}
            />
          </Form.Group>
        </Col>
        <Col lg="12" className="mt-3">
          <Button
            size="sm"
            variant="primary"
            className="me-0 admin-primary"
            disabled={isLoading}
            onClick={() => {
              sendConfirmation();
            }}
          >
            Envoyer
          </Button>
          <Button
            variant="falcon-default"
            size="sm"
            className="admin-primary ms-2"
            onClick={() => {
              setShowCanva(false);
            }}
          >
            Annuler
          </Button>
        </Col>
      </div>
    </>
  );
};

// proptypes :
SendNotification.propTypes = {
  survey: PropTypes.object,
  event: PropTypes.object,
  recipients: PropTypes.array
};
export default SendNotification;
