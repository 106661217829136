import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Section from '../../../common/Section';
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import bgReseauTop from '../../../../assets/img/tredunion/bg_reseau_top.jpg';
import bgReseauBottom from '../../../../assets/img/tredunion/bg_reseau_bottom.jpg';
import mapSpain from '../../../../assets/img/tredunion/map_spain.png';
import mapUk from '../../../../assets/img/tredunion/map_uk.png';
import mapGermany from '../../../../assets/img/tredunion/map_germany.png';
import mapBenelux from '../../../../assets/img/tredunion/map_benlux.png';
import { VectorMap } from '@south-paw/react-vector-maps';
import mapFrance from './MapFrance';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutocompleteHomeSearch } from '../../../../services/CompanyService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import MouseTooltip from '../../commons/mouse-tooltip/MouseTooltip';

const Reseau = ({
  reseauSectionSelected,
  setReseauSectionSelected,
  selectedTrade,
  setSelectedTrade,
  companiesSelectedTrade,
  setCompaniesSelectedTrade,
  companies,
  trades,
  back,
  selectedDepartment,
  setSelectedDepartment
}) => {
  let navigate = useNavigate();
  const [hoveredDepartment, setHoveredDepartment] = useState(null);
  const [isMouseTooltipVisible, setIsMouseTooltipVisible] = useState(false);
  const layerProps = {
    onClick: ({ target }) => {
      updateMapSelection(target.attributes.id.value);
      setInputSearch(target.attributes.name.value);
      setSelectedDepartment(target.attributes.id.value);
    },
    onMouseEnter: ({ target }) => {
      setHoveredDepartment(target.attributes.name.value);
      setIsMouseTooltipVisible(true);
    },
    onMouseLeave: () => {
      setHoveredDepartment(null);
      setIsMouseTooltipVisible(false);
    }
  };

  const updateMapSelection = id => {
    setSelectedDepartment(id);
    setShowSuggestions(false);
    switch (id) {
      case 'Allemagne':
        setInputSearch('Allemagne');
        break;
      case 'Benelux':
        setInputSearch('Benelux');
        break;
      case 'Royaume-Uni':
        setInputSearch('Royaume-Uni');
        break;
      case 'Espagne':
        setInputSearch('Espagne');
        break;
    }
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputSearch, setInputSearch] = useState('');

  const searchOnChange = e => {
    const userInput = e.target.value;
    setInputSearch(e.target.value);

    const fetchAutocomplete = async () => {
      const responseAutocomplete = await AutocompleteHomeSearch(userInput);
      if (responseAutocomplete.success === true) {
        setFilteredSuggestions(responseAutocomplete.data);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (e.target.value.length > 2) {
      fetchAutocomplete();
    }
  };

  const searchOnClick = e => {
    setFilteredSuggestions([]);
    setInputSearch(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setSelectedDepartment(e.target.getAttribute('data-department'));
    if (e.target.getAttribute('data-company') !== '') {
      navigate(`/adherents/${e.target.getAttribute('data-company')}`);
    }
  };

  const searchOnKeyDown = key => {
    if (key.keyCode === 13 || key.keyCode === 9) {
      setInputSearch(filteredSuggestions[activeSuggestionIndex]);
      setFilteredSuggestions([]);
    }
  };

  const SuggestionsListComponent = () => {
    return (
      filteredSuggestions.length && (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let initData = '';
            if (suggestion.city_real_name) {
              initData = suggestion.city_real_name;
            } else {
              initData = suggestion.name;
            }
            return (
              <li
                key={index}
                onClick={searchOnClick}
                data-department={
                  suggestion.department !== null
                    ? suggestion.department.name
                    : null
                }
                data-company={suggestion.zip_code ? suggestion.id : ''}
              >
                {initData}
              </li>
            );
          })}
        </ul>
      )
    );
  };

  const Map = styled.div`
    margin: 1rem auto;
    width: 600px;

    svg {
      stroke: #646363;

      path {
        fill: #fff;
        outline: none;
        &:hover {
          fill: #d2d2d2;
          cursor: pointer;
        }
        &[aria-checked='true'] {
          fill: #e52f12;
        }
      }
    }
  `;
  return (
    <>
      <Section
        bg="light"
        className="d-flex p-0"
        id="reseau"
        style={{ backgroundImage: `url("${bgReseauTop}")` }}
      >
        <Row className="h-100">
          <Col xs={6} lg={8} className="align-items-end">
            <Row className="align-items-end h-100">
              <Col xs={12}>
                <h2>RÉSEAU</h2>
              </Col>
            </Row>
          </Col>
          <Col className="d-none d-lg-block" lg={4}>
            <Row className="h-100">
              <Col
                xs={6}
                className={
                  reseauSectionSelected === 'location'
                    ? 'selected reseauSelector'
                    : 'reseauSelector'
                }
                onClick={() => {
                  setReseauSectionSelected('location');
                }}
              >
                <Row className="align-items-end h-100">
                  <Col xs={12} className="pb-4">
                    PAR LIEU
                  </Col>
                </Row>
              </Col>
              <Col
                xs={6}
                className={
                  reseauSectionSelected === 'trade'
                    ? 'selected reseauSelector'
                    : 'reseauSelector'
                }
                onClick={() => {
                  setReseauSectionSelected('trade');
                }}
              >
                <Row className="align-items-end h-100">
                  <Col xs={12} className="pb-4">
                    PAR MÉTIER
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-block d-lg-none pt-3 pb-2">
        <Row className="px-2">
          <Col
            xs={6}
            className={
              reseauSectionSelected === 'location'
                ? 'selected reseauSelector'
                : 'reseauSelector'
            }
            onClick={() => {
              setReseauSectionSelected('location');
            }}
          >
            <Row className="h-100 align-items-center">
              <Col xs={12} className="">
                PAR LIEU
              </Col>
            </Row>
          </Col>
          <Col
            xs={6}
            className={
              reseauSectionSelected === 'trade'
                ? 'selected reseauSelector'
                : 'reseauSelector'
            }
            onClick={() => {
              setReseauSectionSelected('trade');
            }}
          >
            <Row className="h-100 align-items-center">
              <Col xs={12}>PAR MÉTIER</Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <Section
        bg="light"
        className={
          reseauSectionSelected === 'location'
            ? 'd-flex align-items-end p-1 mb-4'
            : 'd-none'
        }
        id="reseau-occupation"
        style={{ backgroundImage: `url("${bgReseauBottom}")` }}
      >
        <Row className="pb-5 pb-lg-0">
          <Col lg={8} className="d-none d-lg-flex map-reseau">
            <div id="france">
              <MouseTooltip
                visible={isMouseTooltipVisible}
                offsetX={15}
                offsetY={10}
                className="hoverMapTooltip"
              >
                <span>{hoveredDepartment}</span>
              </MouseTooltip>
              <Map>
                <VectorMap
                  {...mapFrance}
                  checkedLayers={[selectedDepartment]}
                  layerProps={layerProps}
                />
              </Map>
            </div>
            <div
              id="spain"
              style={{ backgroundImage: `url("${mapSpain}")` }}
              className={selectedDepartment === 'Espagne' ? 'active' : ''}
              onClick={() => {
                updateMapSelection('Espagne');
              }}
            ></div>
            <div
              id="uk"
              style={{ backgroundImage: `url("${mapUk}")` }}
              className={selectedDepartment === 'Royaume-Uni' ? 'active' : ''}
              onClick={() => {
                updateMapSelection('Royaume-Uni');
              }}
            ></div>
            <div
              id="germany"
              style={{ backgroundImage: `url("${mapGermany}")` }}
              className={selectedDepartment === 'Allemagne' ? 'active' : ''}
              onClick={() => {
                updateMapSelection('Allemagne');
              }}
            ></div>
            <div
              id="benelux"
              style={{ backgroundImage: `url("${mapBenelux}")` }}
              className={selectedDepartment === 'Benelux' ? 'active' : ''}
              onClick={() => {
                updateMapSelection('Benelux');
              }}
            ></div>
          </Col>
          <Col xs={12} lg={4}>
            <p className="mt-4 mb-2 redHeading">
              Vous cherchez un transporteur proche de vous, disponible et
              compétent ?
            </p>
            <p className="mb-4">Nous avons la solution à vous apporter.</p>
            <p className="mb-1">
              Recherchez par <b>ville</b> ou par <b>nom de transporteur</b> :
            </p>
            <div className="d-block">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Commencez à écrire..."
                  aria-label="Commencez à écrire..."
                  aria-describedby="basic-addon2"
                  onChange={searchOnChange}
                  onKeyDown={searchOnKeyDown}
                  value={inputSearch}
                />
                {showSuggestions && inputSearch && <SuggestionsListComponent />}
                <InputGroup.Text id="basic-addon2">
                  <FontAwesomeIcon icon="search" />
                </InputGroup.Text>
              </InputGroup>
            </div>
            <p className="mb-1">
              ou recherchez directement votre <b>transporteur</b> :
            </p>
            <div className="d-block">
              <Form.Select
                aria-label="Default select example"
                onChange={e => {
                  navigate(`/adherents/${e.target.value}?back=home-reseau`);
                }}
              >
                <option>Sélectionnez un transporteur</option>
                {companies
                  .filter(company => company.department !== null)
                  .sort(function (a, b) {
                    return a.department_id - b.department_id;
                  })
                  .map((company, index) => {
                    return (
                      <option value={company.id} key={index}>
                        {company.department.name} - {company.name}
                      </option>
                    );
                  })}
                {companies
                  .filter(
                    company =>
                      company.department === null && company.country !== null
                  )
                  .sort(function (a, b) {
                    return a.country.name - b.country.name;
                  })
                  .map((company, index) => {
                    return (
                      <option value={company.id} key={index}>
                        {company.country.name} - {company.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
            {selectedDepartment && companies && (
              <Row className="align-items-center">
                <Col xs={12}>
                  <p className="mt-3 mb-2 redHeading">
                    {companies.filter(
                      company =>
                        (company.department &&
                          company.department.name === selectedDepartment) ||
                        (company.country &&
                          company.country.name === selectedDepartment)
                    ).length > 0 ? (
                      <>Résultats de votre recherche :</>
                    ) : (
                      <>Aucun résultat pour votre recherche.</>
                    )}
                  </p>
                </Col>
                {companies
                  .filter(
                    company =>
                      (company.department &&
                        company.department.name === selectedDepartment) ||
                      (company.country &&
                        company.country.name === selectedDepartment)
                  )
                  .map((company, index) => (
                    <Col xs={6} className="py-3" key={index}>
                      <img
                        src={company.logo_url}
                        className="mw-100 cursor-pointer"
                        onClick={() => {
                          window.location.href = `/adherents/${company.id}?${
                            back ? `back=${back}` : 'back=home-reseau'
                          }`;
                        }}
                      />
                    </Col>
                  ))}
              </Row>
            )}
          </Col>
        </Row>
      </Section>
      <Section
        bg="light"
        className={
          reseauSectionSelected === 'trade'
            ? 'd-flex align-items-end p-1 mb-4'
            : 'd-none'
        }
        id="reseau-occupation"
        style={{ backgroundImage: `url("${bgReseauBottom}")` }}
      >
        <Row>
          <Col lg={12} className="">
            <p className="mt-4 mb-2 redHeading">
              Vous cherchez un transporteur proche de vous, disponible et
              compétent ?
            </p>
            <p>Indiquez-nous son métier, nous trouvons pour vous.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ul className="trade-buttons">
              <li
                className={selectedTrade === 'all' ? 'active' : ''}
                onClick={() => {
                  setSelectedTrade('all');
                  setCompaniesSelectedTrade(companies);
                }}
              >
                TOUS
              </li>
              {trades.map((trade, index) => (
                <li
                  className={selectedTrade === trade.id ? 'active' : ''}
                  key={index}
                  onClick={() => {
                    setSelectedTrade(trade.id);
                    setCompaniesSelectedTrade(
                      trades.filter(item => item.id === trade.id)[0].companies
                    );
                  }}
                >
                  {trade.name}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="align-items-center">
          {companiesSelectedTrade.map((company, index) => (
            <Col key={index} xs={4} lg={2}>
              <img
                className="w-100 pt-2 cursor-pointer"
                src={company.logo_url}
                onClick={() => {
                  window.location.href = `/adherents/${company.id}?${
                    back ? `back=${back}` : ''
                  }`;
                }}
              />
            </Col>
          ))}
        </Row>
      </Section>
    </>
  );
};

Reseau.propTypes = {
  reseauSectionSelected: PropTypes.any,
  setReseauSectionSelected: PropTypes.func,
  selectedTrade: PropTypes.any,
  setSelectedTrade: PropTypes.func,
  companiesSelectedTrade: PropTypes.any,
  setCompaniesSelectedTrade: PropTypes.func,
  companies: PropTypes.array,
  trades: PropTypes.array,
  back: PropTypes.any,
  selectedDepartment: PropTypes.any,
  setSelectedDepartment: PropTypes.any
};

export default Reseau;
