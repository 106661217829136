import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courses: [],
  teachers: [],
  students: [],
  logTypes: []
};

export const studentLogsSlice = createSlice({
  name: 'student-logs',
  initialState,
  reducers: {
    changeCourses: (state, action) => {
      state.courses = [...action.payload];
    },
    changeTeachers: (state, action) => {
      state.teachers = [...action.payload];
    },
    changeStudents: (state, action) => {
      state.students = [...action.payload];
    },
    changeLogTypes: (state, action) => {
      state.logTypes = [...action.payload];
    },

    resetFilters: state => {
      state.courses = [];
      state.teachers = [];
      state.students = [];
      state.logTypes = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeCourses,
  changeTeachers,
  changeLogTypes,
  changeStudents,
  resetFilters
} = studentLogsSlice.actions;

export default studentLogsSlice.reducer;
