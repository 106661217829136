import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IndexPost } from '../../../../services/PostService';
import Actualites from '../home/Actualites';
import NavbarStandard from '../../commons/NavbarStandard';
import FooterStandard from '../../commons/FooterStandard';

const PostIndex = () => {
  const [trigger] = useState(Date.now());
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const responsePosts = await IndexPost();
      if (responsePosts.success === true) {
        setPosts(responsePosts.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchPosts();
  }, [trigger]);

  return (
    <>
      <NavbarStandard />
      <div className="mt-5">
        <Actualites posts={posts} showPagination={true} />
      </div>
      <FooterStandard />
    </>
  );
};

export default PostIndex;
