import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Offcanvas, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  PostCategoriesManage,
  PutCategoriesManage
} from '../../../../../../services/Event/CategoryService';
import FormAlert from '../../../../commons/FormAlert';
import { Circle } from 'react-color/lib/components/circle/Circle';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { IndexEventCategoryGroup } from '../../../../../../services/Event/GroupService';
import {
  IndexAdCategories,
  PostAdCategory,
  PutAdCategory
} from '../../../../../../services/AdCategoryService';

const ManageAdsCategories = ({
  showManageCategories,
  setShowManageCategories,
  selectedCategory,
  setParentTrigger
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    parent_id: ''
  });

  useEffect(() => {
    if (selectedCategory && selectedCategory.id) {
      setFormData({
        name: selectedCategory.original_name
      });
    }
    const fetchCategories = async () => {
      const response = await IndexAdCategories();
      if (response.success) {
        let tmpCat = [];
        response.data.map((c, index) => {
          tmpCat.push({
            id: c.id,
            name: `${c.parent ? `${c.parent.name} > ` : ''}${c.name}`,
            parent_id: c.parent_id
          });
        });
        tmpCat = tmpCat.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCategories(tmpCat);
      }
    };
    fetchCategories();
  }, [showManageCategories]);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = null;
    if (selectedCategory && selectedCategory.id) {
      response = await PutAdCategory(selectedCategory.id, formData);
    } else {
      response = await PostAdCategory(formData);
    }
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    setIsLoading(false);
    if (response.success === true) {
      setParentTrigger(Date.now());
      toast(`Catégorie enregistrée`);
      setShowManageCategories(false);
    } else {
      toast('Une erreur est survenu, veuillez réessayer plus tard');
    }
    setFormData({
      name: ''
    });
  };
  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageCategories}
        onHide={() => {
          setShowManageCategories(false);
          setFormData({
            name: ''
          });
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            {selectedCategory && selectedCategory.id ? 'Modifier' : 'Ajouter'}{' '}
            une catégorie
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          {successForm && !errorForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>Choisir un nom pour la catégorie :</Form.Label>
              <Form.Control
                placeholder="Nom de la catégorie"
                size="sm"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="headquarter_id" className="mt-3">
              <Form.Label>Catégorie parent : </Form.Label>
              <Form.Select
                aria-label="Catégorie"
                name="parent_id"
                value={formData.parent_id}
                onChange={handleFieldChange}
              >
                <option value="">- Choisir (facultatif) -</option>
                {categories.map((c, index) => (
                  <option disabled={c.parent_id} value={c.id} key={index}>
                    {c.parent ? `${c.parent.name} > ` : ''}
                    {c.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <br />
            <Button
              onClick={handleSubmit}
              variant="primary"
              className="admin-primary mt-0"
              size="sm"
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{
                  position: 'relative',
                  bottom: '1px',
                  left: '-1px',
                  paddingRight: '3px'
                }}
              >
                <path
                  fill="#FFFFFF"
                  d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                />
              </svg>
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageAdsCategories;

ManageAdsCategories.propTypes = {
  showManageCategories: PropTypes.bool,
  setShowManageCategories: PropTypes.func,
  selectedCategoryGroup: PropTypes.object,
  setParentTrigger: PropTypes.func,
  selectedCategory: PropTypes.object
};
