import React, { useEffect, useState } from 'react';
import { Col, Form, Offcanvas, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import FormAlert from '../../../../commons/FormAlert';
import moment from 'moment';
import {
  PostLink,
  PutLink
} from '../../../../../../services/Survey/LinkService';
import DatePicker from 'react-datepicker';
import SoftBadge from '../../../../../common/SoftBadge';
import Select from 'react-select';
import { IndexAdmin } from '../../../../../../services/CompanyService';
import { IndexPartnerAdmin } from '../../../../../../services/PartnerService';

const ManageLinks = ({
  survey,
  showManage,
  setShowManage,
  selectedLink,
  setSelectedLink,
  setParentTrigger
}) => {
  const [formData, setFormData] = useState({
    label: '',
    company_id: '',
    is_active: 1,
    show_companies_list: 1,
    show_partners_list: 0,
    max_uses: 20,
    expires_at: moment().add(1, 'month').toDate(),
    survey_id: survey.id
  });
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  useEffect(() => {
    if (selectedLink && selectedLink.id) {
      setFormData({
        label: selectedLink.label || '',
        company_id: selectedLink.company_id || '',
        partner_id: selectedLink.partner_id || '',
        is_active: selectedLink.is_active === 1 ? 1 : 0,
        show_companies_list: selectedLink.show_companies_list === 1 ? 1 : 0,
        show_partners_list: selectedLink.show_partners_list === 1 ? 1 : 0,
        max_uses: selectedLink.max_uses || 20,
        expires_at: new Date(selectedLink.expires_at),
        survey_id: survey.id
      });

      if (selectedLink.company_id) {
        setSelectedCompany({
          value: `${selectedLink.company_id}`,
          label: selectedLink.default_company
        });
      } else if (selectedLink.partner_id) {
        setSelectedCompany({
          value: `partner_${selectedLink.partner_id}`,
          label: selectedLink.default_company
        });
      }
    }

    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `company_${company.id}`,
            label: company.name
          });
          company.childrens.map(children => {
            companiesArray.push({
              value: `company_${children.id}`,
              label: `-- ${children.name}`
            });
          });
        });
        const fetchPartners = async () => {
          const responsePartners = await IndexPartnerAdmin();
          if (responsePartners.success === true) {
            responsePartners.data.map(partner => {
              companiesArray.push({
                value: `partner_${partner.id}`,
                label: partner.name
              });
            });
            setCompaniesSelect(companiesArray);
          } else {
            toast('Une erreur est survenue, veuillez réessayer plus tard');
          }
        };
        fetchPartners();
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
  }, [selectedLink]);
  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedLink && selectedLink.id) {
      response = await PutLink(selectedLink.id, {
        ...formData,
        expires_at: moment(formData.expires_at).format('YYYY-MM-DD')
      });
    } else {
      response = await PostLink({
        ...formData,
        expires_at: moment(formData.expires_at).format('YYYY-MM-DD')
      });
    }
    setErrorForm(response.validator);
    if (response.success === true) {
      toast(`Lien enregistré`);
      setParentTrigger(Date.now());
      setSelectedLink({});
      setShowManage(false);
      setFormData({
        label: '',
        company_id: '',
        is_active: 1,
        max_uses: 20,
        expires_at: moment().add(1, 'month').toDate(),
        survey_id: survey.id
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setSelectedLink({});
  };

  return (
    <>
      <Offcanvas
        show={showManage}
        onHide={() => {
          setShowManage(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedLink.id ? 'Modifier le ' : 'Ajouter un '}lien
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Col xs={12}>
              <Form.Group controlId="name">
                <Form.Label>Désignation du lien :</Form.Label>
                <Form.Control
                  type="text"
                  name="label"
                  placeholder="Désignation"
                  value={formData.label}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      label: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Group controlId="name">
                <Form.Label>
                  Société / partenaire{' '}
                  <small>
                    facultatif{' '}
                    <b>
                      si vous ne renseignez pas ce champs, les destinataires
                      devront sélectionner leur entreprise dans une liste
                      déroulante
                    </b>
                  </small>{' '}
                  :
                </Form.Label>
                {selectedCompany && selectedCompany.value && (
                  <div className="mb-2">
                    <small>
                      <a
                        className="cursor-pointer pb-1 ps-1"
                        onClick={() => {
                          setFormData(
                            {
                              ...formData,
                              company_id: null,
                              partner_id: null
                            },
                            setSelectedCompany(null)
                          );
                        }}
                      >
                        <small>x Retirer</small>
                      </a>
                    </small>
                  </div>
                )}
                <Select
                  closeMenuOnSelect={true}
                  options={companiesSelect}
                  placeholder="Choisir une société / partenaire..."
                  name="company_id"
                  classNamePrefix="react-select"
                  value={selectedCompany}
                  onChange={object => {
                    // if value contains company_ then it's a company :
                    if (object.value.includes('company_')) {
                      setFormData({
                        ...formData,
                        company_id: parseInt(
                          object.value.replace('company_', '')
                        )
                      });
                    } // else if contains partner_ then it's a partner :
                    else if (object.value.includes('partner_')) {
                      setFormData({
                        ...formData,
                        partner_id: parseInt(
                          object.value.replace('partner_', '')
                        )
                      });
                    }
                    setSelectedCompany(object);
                  }}
                />
                {/*<Form.Control
                  type="text"
                  name="default_company"
                  placeholder="Entreprise concernée"
                  value={formData.default_company}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      default_company: e.target.value
                    });
                  }}
                />*/}
              </Form.Group>
            </Col>
            {(!selectedCompany ||
              (selectedCompany && !selectedCompany.value)) && (
              <Col xs={12} className="mt-3">
                <label>
                  <b>
                    La liste présentée sur la page du lien d'invitation contient
                    :
                  </b>
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="show_companies_list"
                    name="show_companies_list"
                    value="1"
                    className="me-1"
                    checked={
                      formData.show_companies_list === 1 ||
                      formData.show_companies_list === '1'
                    }
                    onChange={e => {
                      setFormData({
                        ...formData,
                        show_companies_list:
                          formData.show_companies_list === 1 ? 0 : 1
                      });
                    }}
                  />
                  Les sociétés
                </label>
                <label className="ps-3">
                  <input
                    type="checkbox"
                    id="show_partners_list"
                    name="show_partners_list"
                    value="1"
                    className="me-1"
                    checked={
                      formData.show_partners_list === 1 ||
                      formData.show_partners_list === '1'
                    }
                    onChange={e => {
                      setFormData({
                        ...formData,
                        show_partners_list:
                          formData.show_partners_list === 1 ? 0 : 1
                      });
                    }}
                  />
                  Les partenaires
                </label>
              </Col>
            )}
            <Col xs={12} className="mt-3">
              <Form.Group controlId="name">
                <Form.Label>
                  Nombre d'inscriptions maximum via ce lien :
                </Form.Label>
                <Form.Control
                  type="number"
                  onWheel={e => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  min="1"
                  name="max_uses"
                  placeholder=""
                  value={formData.max_uses}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      max_uses: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Group controlId="name">
                <Form.Label>Date d'expiration du lien :</Form.Label>
                <DatePicker
                  name="expires_at"
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  selected={formData.expires_at}
                  onChange={date => {
                    setFormData({
                      ...formData,
                      expires_at: date
                    });
                  }}
                  formatWeekDay={startDate => startDate.slice(0, 3)}
                  className="form-control"
                  placeholderText="Date d'expiration"
                  value={formData.expires_at}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <label>
                <input
                  type="checkbox"
                  id="is_active"
                  name="is_active"
                  value="1"
                  className="me-1"
                  checked={
                    formData.is_active === 1 || formData.is_active === '1'
                  }
                  onChange={() => {
                    setFormData({
                      ...formData,
                      is_active: formData.is_active === 1 ? 0 : 1
                    });
                  }}
                />
                Lien actif ?
              </label>
              <span className="smaller-p d-block mb-2">
                <SoftBadge bg="warning" className="me-0">
                  Attention
                </SoftBadge>{' '}
                Cocher cette case permet à tous les détenteurs du lien de
                compléter le formulaire. Pour éviter le spam, il est important
                de diffuser le lien uniquement aux personnes concernées.
              </span>
            </Col>
            <Col xs={12} className="mt-3">
              <Button
                variant="primary"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
              >
                Enregistrer
              </Button>
            </Col>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageLinks.propTypes = {
  survey: PropTypes.object,
  selectedLink: PropTypes.object,
  setSelectedLink: PropTypes.func,
  showManage: PropTypes.bool,
  setShowManage: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default ManageLinks;
