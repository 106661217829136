import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'companies';

export async function IndexCompany() {
  return modelService.IndexModel(`${model}?context=generic`);
}

export async function IndexHome() {
  return modelService.IndexModel(`${model}?context=home`);
}

export async function IndexWithChlidrensRecursive() {
  return modelService.IndexModel(`${model}/indexWithChlidrensRecursive`);
}

export async function IndexAdmin() {
  return modelService.IndexModel(`${model}-admin`);
}

export async function IndexAdminUploads() {
  return modelService.IndexModel(`${model}-admin?context=uploads`);
}

export async function ShowAdminUploads(id) {
  return modelService.ShowModel(`${model}-admin/${id}?context=uploads`);
}

export async function IndexAdminInvoices() {
  return modelService.IndexModel(`${model}-admin?context=invoices`);
}

export async function IndexToExport() {
  return modelService.IndexModel(`${model}/export`);
}

export async function ExportScoring(form) {
  return modelService.PostModel(`${model}/export-scoring`, form);
}

export async function ExportAnalytics(form) {
  return modelService.PostModel(`${model}/export-analytics`, form);
}

export async function ShowCompany(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function ShowCompanyFromAuth(id) {
  return modelService.ShowModel(`${model}/show-from-auth/${id}`);
}

export async function ShowCompanyAdmin(id) {
  return modelService.ShowModel(`${model}-admin/${id}`);
}

export async function ShowCompanyFromEditionEntreprise(uuid) {
  return modelService.ShowModel(`company-edition-entreprise/${uuid}`);
}

export async function ShowCompanyFillRequest(uuid) {
  return modelService.ShowModel(`${model}-companies-fill-request/${uuid}`);
}

export async function PostCompany(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutCompany(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function AutocompleteHomeSearch(search) {
  return modelService.ShowModel(`cities?search=${search}`);
}

export async function DeleteCompany(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PostContactCompany(id, formData) {
  return modelService.PostModel(`${model}/${id}/send-contact-email`, formData);
}
