import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Form,
  Alert,
  Row,
  Tooltip,
  OverlayTrigger,
  Button
} from 'react-bootstrap';
import { TagsInput } from 'react-tag-input-component';
import { DeleteSurveyQuestion } from './../../../../../services/Survey/SurveyService';

const ManageSurveyModalQuestion = ({
  group,
  questionGroups,
  setQuestionGroups,
  question,
  index,
  indexGroup,
  questions,
  setQuestions,
  questionTypes,
  event
}) => {
  useEffect(() => {
    //
  }, []);

  const handleFieldChange = (e, index) => {
    let iterable = '';
    if (group && questionGroups && setQuestionGroups) {
      iterable = group.questions;
    } else {
      iterable = questions;
    }
    const newArray = iterable.map((item, i) => {
      if (index === i) {
        if (e.target.name === 'required') {
          return {
            ...item,
            [e.target.name]: e.target.checked ? 1 : 0
          };
        } else if (
          e.target.name === 'type_slug' &&
          e.target.value !== 'radio' &&
          e.target.value !== 'checkbox'
        ) {
          return {
            ...item,
            [e.target.name]: e.target.value,
            answers: []
          };
        } else {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
      } else {
        return item;
      }
    });
    if (group && questionGroups && setQuestionGroups) {
      let tmpGroups = [...questionGroups];
      tmpGroups[indexGroup].questions = newArray;
      if (
        e.target.name === 'type_slug' &&
        e.target.value === 'decisive' &&
        index !== 0
      ) {
        const temp = tmpGroups[indexGroup].questions[index];
        tmpGroups[indexGroup].questions[index] =
          tmpGroups[indexGroup].questions[0];
        tmpGroups[indexGroup].questions[0] = temp;
      }
      setQuestionGroups(tmpGroups);
    } else {
      setQuestions(newArray);
    }
  };

  return (
    <>
      <Card className={`mb-2 p-0 border-2 border-primary`}>
        <Card.Body className="p-3">
          <Form.Group>
            {' '}
            {question.isEditable !== false && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                className="float-end cursor-pointer"
                style={{ position: 'relative', top: '-7px' }}
                onClick={() => {
                  const qid = question.id;
                  if (qid) {
                    DeleteSurveyQuestion(qid);
                  }

                  if (group && questionGroups && setQuestionGroups) {
                    let newArray = [...questionGroups];
                    if (
                      newArray[indexGroup] &&
                      newArray[indexGroup].questions &&
                      newArray[indexGroup].questions.length > 0
                    ) {
                      newArray[indexGroup].questions.splice(index, 1);
                      setQuestionGroups(newArray);
                    }
                  } else {
                    const newArray = [...questions];
                    newArray.splice(index, 1);
                    setQuestions(newArray);
                  }
                }}
              >
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  fill="rgba(229,47,18,1)"
                />
              </svg>
            )}
            <Form.Label>
              Question
              {group && questionGroups && setQuestionGroups ? (
                <span>
                  {' '}
                  rubrique <b>{indexGroup + 1}</b> question <b>{index + 1}</b> :
                </span>
              ) : (
                <span>
                  {' '}
                  <b>{index + 1}</b> :
                </span>
              )}
              {((group && group.questions.length > 1) ||
                questions.length > 1) &&
                question.isEditable !== false && (
                  <span className="float-end">
                    {((index !== 0 && index !== 1) ||
                      (group && index !== 0) ||
                      (index === 1 &&
                        !group &&
                        questions[0].isEditable !== false)) && (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          if (group && questionGroups && setQuestionGroups) {
                            const qid =
                              questionGroups[indexGroup].questions[index + 1]
                                .id;

                            if (qid) {
                              DeleteSurveyQuestion(qid).then(() => {
                                let newArray = [...questionGroups];
                                const temp =
                                  newArray[indexGroup].questions[index];
                                newArray[indexGroup].questions[index] =
                                  newArray[indexGroup].questions[index + 1];
                                newArray[indexGroup].questions[index + 1] =
                                  temp;
                                setQuestionGroups(newArray);
                              });
                            }
                            let newArray = [...questionGroups];
                            const temp = newArray[indexGroup].questions[index];
                            newArray[indexGroup].questions[index] =
                              newArray[indexGroup].questions[index - 1];
                            newArray[indexGroup].questions[index - 1] = temp;
                            setQuestionGroups(newArray);
                          } else {
                            const newArray = [...questions];
                            const temp = newArray[index];
                            newArray[index] = newArray[index - 1];
                            newArray[index - 1] = temp;
                            setQuestions(newArray);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                        </svg>
                      </span>
                    )}
                    {((group && index !== group.questions.length - 1) ||
                      (!group && index !== questions.length - 1)) && (
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          if (group && questionGroups && setQuestionGroups) {
                            let newArray = [...questionGroups];
                            const temp = newArray[indexGroup].questions[index];
                            newArray[indexGroup].questions[index] =
                              newArray[indexGroup].questions[index + 1];
                            newArray[indexGroup].questions[index + 1] = temp;
                            setQuestionGroups(newArray);
                          } else {
                            const newArray = [...questions];
                            const temp = newArray[index];
                            newArray[index] = newArray[index + 1];
                            newArray[index + 1] = temp;
                            setQuestions(newArray);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                      </span>
                    )}
                  </span>
                )}
            </Form.Label>
            <Row>
              <Col
                xs={
                  question.isEditable !== false &&
                  questionGroups.filter(g => g.title).length > 0
                    ? 8
                    : 12
                }
              >
                <Form.Control
                  type="text"
                  placeholder="Titre de la question"
                  value={question.title || ''}
                  name="title"
                  onChange={e => {
                    handleFieldChange(e, index);
                  }}
                />
              </Col>
              {question.isEditable !== false &&
                questionGroups.filter(g => g.title !== '').length > 0 && (
                  <Col xs={4} style={{ position: 'relative', top: '-30px' }}>
                    <Form.Group className="mt-0">
                      <Form.Label>Rubrique :</Form.Label>
                      <Form.Select
                        name="type_slug"
                        onChange={e => {
                          if (e.target.value) {
                            let tmpGroups = [...questionGroups];
                            if (!tmpGroups[e.target.value].questions) {
                              tmpGroups[e.target.value].questions = [];
                            }
                            tmpGroups[e.target.value].questions.push(question);
                            setQuestionGroups(tmpGroups);
                            if (group) {
                              let tmpGroups = [...questionGroups];
                              tmpGroups[indexGroup].questions.splice(index, 1);
                              setQuestionGroups(tmpGroups);
                            } else {
                              let tmpQuestions = [...questions];
                              tmpQuestions.splice(index, 1);
                              setQuestions(tmpQuestions);
                            }
                          } else {
                            let tmpQuestions = [...questions];
                            tmpQuestions.push(question);
                            setQuestions(tmpQuestions);
                            let tmpGroups = [...questionGroups];
                            tmpGroups[indexGroup].questions.splice(index, 1);
                            setQuestionGroups(tmpGroups);
                          }
                        }}
                      >
                        <option value="">Choisir...</option>
                        {indexGroup !== undefined && (
                          <Fragment>
                            <option value="">Pas de rubrique</option>
                          </Fragment>
                        )}
                        {questionGroups
                          .filter(g => g.title !== '')
                          .map((group, index) => (
                            <Fragment key={index}>
                              {indexGroup !== index && (
                                <Fragment>
                                  <option value={index}>{group.title}</option>
                                </Fragment>
                              )}
                            </Fragment>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
            </Row>
          </Form.Group>
          {question.isEditable !== false && (
            <Row className="align-items-center">
              <Col xs={8}>
                <Form.Group className="mt-3">
                  <Form.Label>Type de réponse :</Form.Label>
                  <Form.Select
                    name="type_slug"
                    value={question.type_slug}
                    onChange={e => {
                      handleFieldChange(e, index);
                    }}
                  >
                    <option value="">Choisir...</option>
                    {questionTypes.map((type, index) => (
                      <Fragment key={index}>
                        {(!event || (event && type.slug !== 'decisive')) && (
                          <option
                            value={type.slug}
                            disabled={
                              type.slug === 'decisive' &&
                              ((group &&
                                group.questions.length > 0 &&
                                group.questions.filter(
                                  q => q.type_slug === 'decisive'
                                ).length > 0) ||
                                (!group &&
                                  questions.filter(
                                    q => q.type_slug === 'decisive'
                                  ).length > 0))
                            }
                          >
                            {type.title}
                          </option>
                        )}
                      </Fragment>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={4} className="pt-4">
                <label>
                  <input
                    type="checkbox"
                    name="required"
                    value="1"
                    className="me-1"
                    checked={
                      question.required === 1 || question.required === '1'
                    }
                    onChange={e => {
                      handleFieldChange(e, index);
                    }}
                  />
                  Réponse obligatoire ?
                </label>
              </Col>
              {(question.type_slug === 'checkbox' ||
                question.type_slug === 'radio') && (
                <Col xs={8} className="mt-3">
                  <Form.Label>
                    Choix :{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Renseignez les choix possible. Commencez à taper et
                          appuyer sur Entrée pour enregistrer le choix.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                  <TagsInput
                    value={question.answers}
                    separators={['Enter', 'return', 'Return']}
                    onChange={data => {
                      let iterable = '';
                      if (group && questionGroups && setQuestionGroups) {
                        iterable = group.questions;
                      } else {
                        iterable = questions;
                      }
                      const newArray = iterable.map((item, i) => {
                        if (index === i) {
                          return {
                            ...item,
                            answers: data
                          };
                        } else {
                          return item;
                        }
                      });
                      if (group && questionGroups && setQuestionGroups) {
                        let tmpGroups = [...questionGroups];
                        tmpGroups[indexGroup].questions = newArray;
                        setQuestionGroups(tmpGroups);
                      } else {
                        setQuestions(newArray);
                      }
                    }}
                    name="answers"
                    placeHolder="Renseignez les choix..."
                  />
                </Col>
              )}
              <Col xs={12}>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="mt-3 ms-0"
                  onClick={() => {
                    if (group && questionGroups && setQuestionGroups) {
                      let tmpGroups = [...questionGroups];
                      tmpGroups[indexGroup].questions.splice(index + 1, 0, {
                        ...question
                      });
                      setQuestionGroups(tmpGroups);
                    } else {
                      setQuestions([
                        ...questions.slice(0, index + 1),
                        {
                          ...question
                        },
                        ...questions.slice(index + 1)
                      ]);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      fill="rgba(77,89,105,1)"
                    />
                  </svg>{' '}
                  Dupliquer la question
                </Button>
              </Col>
            </Row>
          )}
          {question.isEditable === false && (
            <Col className="mt-2">
              <Alert variant="primary" className="mb-0">
                <p style={{ fontSize: 13, marginBottom: '0' }}>
                  {' '}
                  Cette question permet de demander au destinataire si il compte
                  participer ou non à l’événement. Vous pouvez uniquement
                  changer le libellé de celle-ci. Les réponses possibles sont
                  “OUI” ou “NON”.
                </p>
              </Alert>
            </Col>
          )}
          {question.type_slug && question.type_slug === 'decisive' && (
            <Col className="mt-2">
              <Alert variant="primary" className="mb-0">
                <p style={{ fontSize: 13, marginBottom: '0' }}>
                  {' '}
                  Cette question permet de masquer la suite du{' '}
                  {group ? 'groupe' : 'questionnaire'} si le destinataire
                  choisit "NON". Vous pouvez uniquement changer le libellé de
                  celle-ci. Les réponses possibles sont “OUI” ou “NON”.{' '}
                  <b>
                    Vous ne pouvez ajouter qu'une seule question décisive par{' '}
                    {group ? 'groupe' : 'questionnaire'}
                  </b>
                  .
                </p>
              </Alert>
            </Col>
          )}
        </Card.Body>
      </Card>
      <Button
        size="sm"
        variant="falcon-default"
        className="mt-2 mb-3"
        onClick={() => {
          let q = {
            title: '',
            required: 1,
            type_slug: '',
            isEditable: true,
            answers: []
          };
          if (group && questionGroups && setQuestionGroups) {
            let tmpGroups = [...questionGroups];
            tmpGroups[indexGroup].questions.splice(index + 1, 0, q);
            setQuestionGroups(tmpGroups);
          } else {
            setQuestions([
              ...questions.slice(0, index + 1),
              q,
              ...questions.slice(index + 1)
            ]);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            fill="rgba(77,89,105,1)"
          />
        </svg>{' '}
        Ajouter une question
      </Button>
    </>
  );
};

export default ManageSurveyModalQuestion;

ManageSurveyModalQuestion.propTypes = {
  group: PropTypes.object,
  indexGroup: PropTypes.number,
  questionGroups: PropTypes.array,
  setQuestionGroups: PropTypes.func,
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  questionTypes: PropTypes.array.isRequired,
  event: PropTypes.bool
};
