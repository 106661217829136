import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IndexSurveys } from '../../../../../services/Survey/SurveyService';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import * as Moment from 'moment/moment';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearch as changeSearchSurveys } from '../../../../../redux/slices/surveysSlice';
import { changeSearch as changeSearchPurchase } from '../../../../../redux/slices/purchaseDeclarationsSlice';

const IndexSurvey = ({ type }) => {
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [surveysFromApi, setSurveysFromApi] = useState([]);
  const [searchInputSurveys, setSearchInputSurveys] = useState('');
  const [searchInputPurchase, setSearchInputPurchase] = useState('');

  const searchSurveysStored = useSelector(
    state => state.persistedSurveysReducer.search
  );

  const searchPurchaseStored = useSelector(
    state => state.persistedPurchaseDeclarationsReducer.search
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setSearchInputSurveys(searchSurveysStored);
  }, [searchSurveysStored]);

  useEffect(() => {
    setSearchInputPurchase(searchPurchaseStored);
  }, [searchPurchaseStored]);

  useEffect(() => {
    applySearch(
      type !== 'purchase-declaration'
        ? searchSurveysStored
        : searchPurchaseStored,
      surveysFromApi
    );
  }, [searchSurveysStored, searchPurchaseStored, surveysFromApi]);

  const applySearch = value => {
    if (value.length > 2) {
      let searchObjects = [];
      searchObjects = surveysFromApi.filter(object => {
        if (type !== 'purchase-declaration') {
          if (
            object.title
              .toLowerCase()
              .replace('.', '')
              .indexOf(searchInputSurveys.toLowerCase().replace('.', '')) >
              -1 ||
            (object.attachable &&
              object.attachable.title
                .toLowerCase()
                .replace('.', '')
                .indexOf(searchInputSurveys.toLowerCase().replace('.', '')) >
                -1)
          ) {
            return true;
          }
        } else {
          if (
            object.title
              .toLowerCase()
              .replace('.', '')
              .indexOf(searchInputPurchase.toLowerCase().replace('.', '')) >
              -1 ||
            (object.attachable &&
              object.attachable.title
                .toLowerCase()
                .replace('.', '')
                .indexOf(searchInputPurchase.toLowerCase().replace('.', '')) >
                -1)
          ) {
            return true;
          }
        }
      });
      setSurveys(searchObjects);
    } else {
      setSurveys(surveysFromApi);
    }
  };

  const transformObjects = objects => {
    let transformedObjects = [];
    objects.map(obj => {
      transformedObjects.push({
        attachable: obj.attachable,
        title: obj.title,
        creator:
          obj.creator && obj.creator.id
            ? `${obj.creator.firstname} ${obj.creator.lastname}`
            : '',
        expires_on: Moment(obj.expires_on).format('DD/MM/YYYY'),
        progress: (
          <>
            {obj.count_recipients > 0 && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    {obj.count_recipients} destinataires -{' '}
                    {obj.count_completed === 0 ? (
                      "personne n'a encore répondu"
                    ) : (
                      <>
                        {obj.count_completed}{' '}
                        {obj.count_completed > 1 ? 'ont' : 'a'} répondu
                      </>
                    )}
                  </Tooltip>
                }
              >
                {obj.count_completed === obj.count_recipients ? (
                  <div
                    className="d-block cursor-pointer"
                    onClick={() => {
                      navigate(
                        `${
                          type === 'purchase-declaration'
                            ? `/administration/declatations-achats/${obj.id}#recipients-table`
                            : `/administration/questionnaires/${obj.id}#recipients-table`
                        }`
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                        fill="#01D27A"
                      />
                    </svg>
                  </div>
                ) : (
                  <ProgressBar
                    className="cursor-pointer"
                    style={{ height: 8 }}
                    now={(obj.count_completed * 100) / obj.count_recipients}
                    onClick={() => {
                      navigate(
                        `${
                          type === 'purchase-declaration'
                            ? `/administration/declarations-achats/${obj.id}#recipients-table`
                            : `/administration/questionnaires/${obj.id}#recipients-table`
                        }`
                      );
                    }}
                  />
                )}
              </OverlayTrigger>
            )}
          </>
        ),
        linked_to: (
          <>
            {obj.attachable && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                  style={{
                    position: 'relative',
                    bottom: 1,
                    marginRight: 4
                  }}
                >
                  <path
                    d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                    fill="rgba(100,99,99,1)"
                  />
                </svg>
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/administration/evenements/${obj.attachable.id}?back=/administration/questionnaires`
                    );
                  }}
                >
                  {obj.attachable.title}
                </a>
              </>
            )}
          </>
        ),
        actions: (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="overlay-trigger-example">Voir les détails</Tooltip>
              }
            >
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  navigate(
                    `${
                      type === 'purchase-declaration'
                        ? `/administration/declarations-achats/${obj.id}`
                        : `/administration/questionnaires/${obj.id}`
                    }`
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                    fill="rgba(100,99,99,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </>
        )
      });
    });
    setSurveys(transformedObjects);
    setSurveysFromApi(transformedObjects);
    setIsLoading(false);
  };

  useEffect(() => {
    setSurveys([]);
    setSurveysFromApi([]);
    setIsLoading(true);
    const fetchObjects = async () => {
      const responseObjects = await IndexSurveys(type);
      if (responseObjects.success === true) {
        //setSurveysFromApiOriginal(responseObjects.data);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [type]);

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des{' '}
                {type === 'purchase-declaration'
                  ? 'déclarations achats'
                  : 'questionnaires'}
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              {/*{surveysFromApiOriginal.length > 0 && (
                <ExportSurveys surveys={surveysFromApiOriginal} />
              )}*/}
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                as={Link}
                to={
                  type === 'purchase-declaration'
                    ? '/administration/declarations-achats/ajouter'
                    : '/administration/questionnaires/ajouter'
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {type === 'purchase-declaration'
                  ? 'Créer une déclaration'
                  : 'Créer un questionnaire'}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <>
        <Card className="mt-3">
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="">
              <Col xs={12} className="mb-0">
                {type === 'purchase-declaration' ? (
                  <Form.Control
                    type="search"
                    className="search-input"
                    placeholder="Rechercher quelque chose..."
                    aria-label="Search"
                    value={searchInputPurchase}
                    size="sm"
                    onChange={e => {
                      dispatch(changeSearchPurchase(e.target.value));
                      setSearchInputPurchase(e.target.value);
                      applySearch(e.target.value);
                    }}
                  />
                ) : (
                  <Form.Control
                    type="search"
                    className="search-input"
                    placeholder="Rechercher quelque chose..."
                    aria-label="Search"
                    value={searchInputSurveys}
                    size="sm"
                    onChange={e => {
                      dispatch(changeSearchSurveys(e.target.value));
                      setSearchInputSurveys(e.target.value);
                      applySearch(e.target.value);
                    }}
                  />
                )}
              </Col>
              {isLoading === true && (
                <Col xs={12} className="mt-4">
                  <Spinner animation="border" role="status" size="xs">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              )}
              {surveys && surveys.length > 0 && (
                <Col xs={12} className="mt-3">
                  <Table data={surveys} />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    </>
  );
};

// Proptypes for Survey :
IndexSurvey.propTypes = {
  type: PropTypes.string
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
        Header: 'Titre'
      },
      {
        accessor: 'creator',
        Header: 'Auteur'
      },
      {
        accessor: 'expires_on',
        Header: 'Date limite',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'progress',
        Header: 'Progression'
      },
      {
        accessor: 'linked_to',
        Header: 'Lié à'
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default IndexSurvey;
