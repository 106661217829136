import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'event-categories';

export async function IndexCategoriesAdminManage() {
  return modelService.IndexModel(`${model}?context=admin.event.manage`);
}

export async function PostCategoriesManage(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutCategoriesManage(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteEventCategory(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
