import React, { useEffect, useState } from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import FooterStandard from '../../commons/FooterStandard';
import { useNavigate, useParams } from 'react-router';
import {
  ShowLink,
  ShowLinkAdmin,
  SubmitLink
} from '../../../../services/Survey/LinkService';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';
import { toast } from 'react-toastify';
import {
  ShowAdminSurvey,
  ShowPublicSurvey
} from '../../../../services/Survey/SurveyService';

const HomeLink = () => {
  const { uuid } = useParams();
  const [event, setEvent] = useState({});
  const [survey, setSurvey] = useState({});
  const [link, setLink] = useState({});
  const [invalidLink, setInvalidLink] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    company_name: '',
    lastname: '',
    firstname: ''
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchObject = async () => {
      let user = JSON.parse(localStorage.getItem('user'));
      let responseObjectsLink = '';
      if (user?.is_admin === 1) {
        responseObjectsLink = await ShowLinkAdmin(uuid);
      } else {
        responseObjectsLink = await ShowLink(uuid);
      }
      if (responseObjectsLink.success === true) {
        navigate(
          `/reponse-formulaire/${responseObjectsLink.data.survey_id}?link=${uuid}`
        );
        /*setLink(responseObjectsLink.data);
        setAttachments(responseObjectsLink.data.survey?.attachments);
        console.log(responseObjectsLink.data.survey?.attachments);
        setFormData({
          company_name: responseObjectsLink.data.default_company
        });*/
      } else {
        setInvalidLink(true);
        //toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
      setIsLoading(false);
    };
    fetchObject();
  }, []);
  /*const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await SubmitLink(uuid, formData);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    if (response.success === true) {
      window.location.href = `/reponse-formulaire/${response.data}`;
    } else {
      setIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };*/
  return (
    <></>
    /*<>
      <NavbarStandard />
      {isLoading === true ? (
        <Section bg="light">
          <Row>
            <Col xs={12} className="mt-0">
              <Spinner animation="border" role="status" size="xs">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      ) : (
        <>
          {invalidLink === true ? (
            <Section bg="light" className="mt-7">
              <Row>
                <Col xs={12} className="mt-3">
                  <IconAlert variant="danger" className="smaller-p">
                    Ce formulaire n'est plus disponible. Pour toute question,
                    vous pouvez contacter <a href="/#contact">Tred Union</a>.
                  </IconAlert>
                </Col>
              </Row>
            </Section>
          ) : (
            <>
              {link && link.label && (
                <>
                  <Section
                    bg="light"
                    className="d-flex align-items-end p-1 mt-7"
                    id="actualites"
                    style={{ backgroundImage: `url("${bgActualites}")` }}
                  >
                    <Row>
                      <Col xs={12}>
                        <h2 style={{ lineHeight: '45px' }}>
                          {link.label}{' '}
                          {link.default_company && `- ${link.default_company}`}
                        </h2>
                      </Col>
                    </Row>
                  </Section>
                  <Section
                    bg="light"
                    className="d-flex align-items-end p-1 mt-3"
                  >
                    {attachments && attachments.length > 0 && (
                      <Col xs={12} className="mt-4 mb-4">
                        <p className="smaller-p mb-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            style={{
                              position: 'relative',
                              bottom: '2px',
                              paddingRight: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M14.8287 7.7574L9.1718 13.4143C8.78127 13.8048 8.78127 14.4379 9.1718 14.8285C9.56232 15.219 10.1955 15.219 10.586 14.8285L16.2429 9.17161C17.4144 8.00004 17.4144 6.10055 16.2429 4.92897C15.0713 3.7574 13.1718 3.7574 12.0002 4.92897L6.34337 10.5858C4.39075 12.5384 4.39075 15.7043 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4143L14.8287 19.0711C12.095 21.8048 7.66283 21.8048 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17161L10.586 3.51476C12.5386 1.56214 15.7045 1.56214 17.6571 3.51476C19.6097 5.46738 19.6097 8.63321 17.6571 10.5858L12.0002 16.2427C10.8287 17.4143 8.92916 17.4143 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34319L14.8287 7.7574Z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          <b>
                            <span
                              className="text-danger"
                              style={{ fontSize: 18 }}
                            >
                              Important !
                            </span>{' '}
                            Veuillez prendre connaissance des pièces jointes
                            suivantes :{' '}
                          </b>
                          {attachments.map((attachment, index) => (
                            <span className="d-block mt-2" key={index}>
                              -{' '}
                              <a
                                href={attachment.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {attachment.file_url.replace(/^.*[\\/]/, '')}
                              </a>
                            </span>
                          ))}
                        </p>
                      </Col>
                    )}
                    <Row className="mt-lg-2 mb-5">
                      <Col xs={12}>
                        <IconAlert variant="info">
                          <p className="mb-0">
                            Complétez le formulaire ci-dessous pour accéder au
                            questionnaire :
                          </p>
                        </IconAlert>
                      </Col>
                      {errorForm && (
                        <Col xs={12} className="mt-3">
                          <FormAlert variant="danger" data={errorForm} />
                        </Col>
                      )}
                      <Col lg={8} className="mt-2">
                        <Form as={Row} onSubmit={handleSubmit}>
                          <Col md="6">
                            <Form.Group controlId="name">
                              <Form.Label>
                                Nom <span className="text-danger">*</span> :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastname"
                                placeholder="Votre nom"
                                value={formData.lastname || ''}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    lastname: e.target.value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6" className="mt-4 mt-md-0">
                            <Form.Group controlId="name">
                              <Form.Label>
                                Prénom <span className="text-danger">*</span> :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstname"
                                placeholder="Votre prénom"
                                value={formData.firstname || ''}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    firstname: e.target.value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="mt-3">
                            <Form.Group controlId="name">
                              <Form.Label>
                                Email <span className="text-danger">*</span> :
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Votre adresse email"
                                value={formData.email || ''}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    email: e.target.value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="mt-3">
                            <Form.Group controlId="name">
                              <Form.Label>
                                Votre entreprise{' '}
                                <span className="text-danger">*</span> :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="company_name"
                                disabled={link.default_company ? true : false}
                                placeholder="Votre entreprise"
                                value={formData.company_name || ''}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    company_name: e.target.value
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="12" className="mt-5">
                            <Button
                              size="sm"
                              variant="primary"
                              className="me-2 admin-primary"
                              type="submit"
                              disabled={isLoading}
                              onClick={handleSubmit}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '4px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                  fill="rgba(255,255,255,1)"
                                />
                              </svg>
                              Envoyer et accéder au questionnaire
                            </Button>
                          </Col>
                        </Form>
                      </Col>
                    </Row>
                  </Section>
                </>
              )}
            </>
          )}
        </>
      )}

      <FooterStandard />
    </>*/
  );
};

export default HomeLink;
