import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Offcanvas
} from 'react-bootstrap';

import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  PostTradePress,
  PutTradePress,
  ShowTradePress
} from '../../../../../services/PostTradePressService';
import FormAlert from '../../../commons/FormAlert';
import IconAlert from '../../../../common/IconAlert';
import FileUploader from '../../../commons/fileUploader';
import classNames from 'classnames';
import { DeleteMedia } from '../../../../../services/PostMediaService';
import { DeleteMediaTradePress } from '../../../../../services/PostMediaTradePressService';
import { PostAd, PutAd, ShowAd } from '../../../../../services/AdService';
import { DeleteAdPicture } from '../../../../../services/AdPictureService';
import { Editor } from '@tinymce/tinymce-react';
import { IndexAdCategories } from '../../../../../services/AdCategoryService';
import ManageAdsCategories from './categories/manage';
import SoftBadge from '../../../../common/SoftBadge';
import PropTypes from 'prop-types';

const ManageAds = ({ fromMembers = false }) => {
  const { id } = useParams();
  const [medias, setMedias] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showManageCategories, setShowManageCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [ad, setAd] = useState({});
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    category_id: '',
    pictures: [],
    is_active: 1
  });
  const addMedia = () => {
    const list = [...medias];
    list.push({
      file_url: '',
      post_id: id
    });
    setMedias(list);
  };
  const handleFieldChange = e => {
    if (e.target.name === 'is_active') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };
  const handleEditorChange = content => {
    setFormData(prevState => ({
      ...prevState,
      description: content
    }));
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorForm(false);
    let response = false;
    let form = {
      ...formData,
      context: 'edit'
    };
    if (id) {
      response = await PutAd(id, form);
    } else {
      response = await PostAd(form);
    }
    setIsLoading(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    if (response.success === true) {
      const successMessage = `Annonce enregistrée.`;
      toast(successMessage);
      if (!id) {
        setTimeout(function () {
          if (fromMembers) {
            window.location.href = `/espace-membre/mes-annonces/${response.data.id}/modifier`;
          } else {
            window.location.href = `/administration/annonces/${response.data.id}/modifier`;
          }
        }, 2000);
      } else {
        setTimeout(function () {
          if (fromMembers) {
            window.location.href = `/espace-membre/mes-annonces`;
          } else {
            window.location.href = `/administration/annonces`;
          }
        }, 1500);
      }
    } else {
      toast('Une erreur est survenue.');
    }
  };

  const removeMedia = async media => {
    let response = await DeleteAdPicture(media.id);
    if (response.success === true) {
      let form = {
        ...formData,
        context: 'edit'
      };
      response = await PutAd(id, form);
      if (response.success === true) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await IndexAdCategories();
      if (response.success) {
        let tmpCat = [];
        response.data.map((c, index) => {
          tmpCat.push({
            id: c.id,
            name: `${c.parent ? `${c.parent.name} > ` : ''}${c.name}`
          });
        });
        tmpCat = tmpCat.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCategories(tmpCat);
      }
    };
    fetchCategories();
    const fetchAd = async () => {
      const response = await ShowAd(id);
      if (response.success) {
        setAd(response.data);
        setFormData({
          title: response.data.title,
          description: response.data.description,
          price: response.data.price,
          contact_name: response.data.contact_name,
          contact_email: response.data.contact_email,
          contact_phone: response.data.contact_phone,
          category_id: response.data.category_id,
          is_active: response.data.is_active
        });
        setMedias([...response.data.pictures]);
      }
    };
    if (id) {
      fetchAd();
    }
  }, []);
  useEffect(() => {
    const fetchCategories = async () => {
      const response = await IndexAdCategories();
      if (response.success) {
        let tmpCat = [];
        response.data.map((c, index) => {
          tmpCat.push({
            id: c.id,
            name: `${c.parent ? `${c.parent.name} > ` : ''}${c.name}`,
            parent_id: c.parent_id
          });
        });
        tmpCat = tmpCat.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCategories(tmpCat);
      }
    };
    fetchCategories();
  }, [trigger]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="justify-content-between align-items-center">
              <Col lg={'auto'}>
                <h3 className="text-primary admin-title mb-0">
                  {ad.id !== null && ad.id !== undefined
                    ? `Modifier l'annonce`
                    : 'Ajouter une annonce'}
                </h3>
              </Col>
              <Col className="text-end">
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    if (fromMembers) {
                      window.location.href = '/espace-membre/mes-annonces';
                    } else {
                      window.location.href = '/administration/annonces';
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    style={{ marginBottom: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                  </svg>
                  {!id ? 'Annuler' : 'Retour'}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {errorForm && (
          <div className="mt-3">
            <FormAlert variant="danger" data={errorForm} />
          </div>
        )}
        <Card className="mt-3">
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="justify-content-between align-items-center">
              <Form>
                {!fromMembers && (
                  <Col xs={12} className="mt-3">
                    <label>
                      <input
                        type="checkbox"
                        id="is_active"
                        name="is_active"
                        value="1"
                        className="me-1"
                        checked={
                          formData.is_active === 1 || formData.is_active === '1'
                        }
                        onChange={handleFieldChange}
                      />
                      Annonce active ?
                    </label>
                    <span className="smaller-p d-block mb-2">
                      <SoftBadge bg="warning" className="me-0">
                        Attention
                      </SoftBadge>{' '}
                      Cocher cette case permet aux membres d'accéder à l'annonce{' '}
                      <b>et de prolonger l'annonce pour 30 jours</b>.
                    </span>
                  </Col>
                )}
                <Col md="6">
                  <Form.Group controlId="name">
                    <Form.Label>Titre :</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Titre de l'annonce"
                      value={formData.title}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Prix :</Form.Label>
                    <Form.Control
                      type="text"
                      name="price"
                      placeholder="2500€"
                      value={formData.price}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className="mt-3">
                  <Form.Group controlId="headquarter_id">
                    <Form.Label>
                      Catégorie :{' '}
                      {!fromMembers && (
                        <a
                          className="cursor-pointer pb-1 ps-2"
                          onClick={() => {
                            setShowManageCategories(true);
                          }}
                        >
                          <small>+ Ajouter une catégorie</small>
                        </a>
                      )}
                    </Form.Label>
                    {!fromMembers && (
                      <ManageAdsCategories
                        showManageCategories={showManageCategories}
                        setShowManageCategories={setShowManageCategories}
                        setParentTrigger={setTrigger}
                      />
                    )}
                    <Form.Select
                      aria-label="Catégorie"
                      name="category_id"
                      value={formData.category_id}
                      onChange={handleFieldChange}
                    >
                      <option value="">Choisir...</option>
                      {categories.map((c, index) => (
                        <option value={c.id} key={index}>
                          {c.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="12" className="mt-3">
                  <Form.Group controlId="contact_website">
                    <Form.Label>Description de l'annonce :</Form.Label>
                    <Editor
                      value={formData.description}
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      init={{
                        browser_spellcheck: true,
                        contextmenu: false,
                        language: 'fr_FR',
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                          'link'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'link',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  </Form.Group>
                </Col>
              </Form>
              <Col md="12" className="pb-2 pt-3 mt-2">
                <div className="border-dashed-bottom"></div>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>
                    Contact - <b>nom</b> (+ entreprise si besoin){' '}
                    <small>facultatif</small> :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_name"
                    placeholder="Contact"
                    value={formData.contact_name}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>
                    Contact - <b>email</b> <small>facultatif</small> :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_email"
                    placeholder="Contact"
                    value={formData.contact_email}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>
                    Contact - <b>téléphone</b> <small>facultatif</small> :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_phone"
                    placeholder="Contact"
                    value={formData.contact_phone}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="pb-2 pt-3 mt-2">
                <div className="border-dashed-bottom"></div>
              </Col>
              {!id ? (
                <Col md={12}>
                  <IconAlert variant="info" className="mb-0">
                    <p className="mb-0">
                      Enregistrez l'annonce pour pouvoir y ajouter des photos.
                    </p>
                  </IconAlert>
                </Col>
              ) : (
                <Col md={12} className="mt-3">
                  <h5 className="admin-title companyRightManagePeople">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginRight: '4px', marginBottom: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M3 21a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H20a1 1 0 0 1 1 1v3h-2V7h-7.414l-2-2H4v11.998L5.5 11h17l-2.31 9.243a1 1 0 0 1-.97.757H3zm16.938-8H7.062l-1.5 6h12.876l1.5-6z"
                        fill="rgba(75,74,77,1)"
                      />
                    </svg>
                    Photos :
                  </h5>
                  <Row className="mt-3">
                    {medias.length > 0 && (
                      <>
                        {medias.map((media, indexMedia) => (
                          <Col xs={12} key={indexMedia} className="mb-4">
                            <Row className="align-items-center">
                              <Col xs={5}>
                                {media.file_url ? (
                                  <FileUploader
                                    pathToUpload="ads"
                                    format="img"
                                    postRoute={`ads-pictures?ad_id=${id}`}
                                    cropperEnabled={false}
                                    currentImage={media.file_url}
                                  />
                                ) : (
                                  <FileUploader
                                    pathToUpload="ads"
                                    format="any"
                                    postRoute={`ads-pictures?ad_id=${id}`}
                                    cropperEnabled={false}
                                  />
                                )}
                              </Col>
                              <Col xs={7}>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className="me-0 mb-1"
                                  type="button"
                                  onClick={() => {
                                    if (media.id) {
                                      removeMedia(media);
                                    } else {
                                      const list = [...medias];
                                      list.splice(indexMedia, 1);
                                      setMedias(list);
                                    }
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                    style={{
                                      marginBottom: '2px'
                                    }}
                                  >
                                    <path
                                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                      fill="rgba(255,255,255,1)"
                                    />
                                  </svg>
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </>
                    )}
                    <Col
                      xs={12}
                      className={classNames('', {
                        'mt-3': medias.length > 0
                      })}
                    >
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          addMedia();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{
                            marginBottom: '2px',
                            marginRight: '3px'
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                        </svg>
                        Ajouter une {medias.length > 0 ? 'autre' : ''} photo
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>

        <Col xs={12} className="mt-3">
          <Card>
            <Card.Body>
              <Row className="flex-between-center">
                <Col xs="auto">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    onClick={() => {
                      if (fromMembers) {
                        window.location.href = '/espace-membre/mes-annonces';
                      } else {
                        window.location.href = '/administration/annonces';
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      style={{ marginBottom: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                    {!id ? 'Annuler' : 'Retour'}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    variant="primary"
                    className="me-2 admin-primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '4px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Form>
    </>
  );
};

ManageAds.propTypes = {
  fromMembers: PropTypes.bool
};

export default ManageAds;
