import React, { Fragment, useEffect, useState } from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import { toast } from 'react-toastify';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Section from '../../../common/Section';
import bgFiche from 'assets/img/tredunion/bg_fiche.jpeg';
import defaultImage from 'assets/img/men.png';
import { useNavigate, useParams } from 'react-router';
import {
  IndexHome,
  ShowCompany,
  ShowCompanyFromAuth
} from '../../../../services/CompanyService';
import FooterStandard from '../../commons/FooterStandard';
import { useSearchParams } from 'react-router-dom';
import { ShowUserFromMembers } from '../../../../services/UserService';
import menAvatar from 'assets/img/men.png';
import womenAvatar from 'assets/img/women.png';
import Masonry from 'react-masonry-css';
import * as Moment from 'moment/moment';

import facebook from 'assets/img/tredunion/facebook.png';
import instagram from 'assets/img/tredunion/instagram.png';
import linkedin from 'assets/img/tredunion/linkedin.png';
import tiktok from 'assets/img/tredunion/tiktok.png';
import Reseau from '../home/Reseau';
import { IndexTrade } from '../../../../services/TradeService';
import FacebookPage from './facebookTimeline';
import { FacebookProvider, Page } from 'react-facebook';
import ContactFromCompany from './Contact';

const CompanyShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [searchParams] = useSearchParams();
  const [trigger] = useState(Date.now());
  const [company, setCompany] = useState({});
  const [user, setUser] = useState({});
  const [reseauSectionSelected, setReseauSectionSelected] =
    useState('location');
  const [selectedTrade, setSelectedTrade] = useState('all');
  const [companiesSelectedTrade, setCompaniesSelectedTrade] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    const fetchCompany = async () => {
      let responseCompany = false;
      if (localStorage.getItem('user')) {
        responseCompany = await ShowCompanyFromAuth(id);
      } else {
        responseCompany = await ShowCompany(id);
      }
      if (responseCompany.success === true) {
        setCompany(responseCompany.data);
        setSelectedDepartment(responseCompany.data.department?.name);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompany();
    const fetchUser = async () => {
      const responseUser = await ShowUserFromMembers(searchParams.get('user'));
      if (responseUser.success === true) {
        setUser(responseUser.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (searchParams.get('user')) {
      fetchUser();
    }
    const fetchCompanies = async () => {
      const responseCompanies = await IndexHome();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
        setCompaniesSelectedTrade(responseCompanies.data); // initialisation à "Tous"
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchTrades = async () => {
      const responseTrades = await IndexTrade();
      if (responseTrades.success === true) {
        setTrades(responseTrades.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
    fetchTrades();
  }, [trigger]);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };

  return (
    <>
      <NavbarStandard />
      {!company ? (
        <Section
          bg="light"
          className="d-flex align-items-end p-1"
          id="banner-company"
          style={{ backgroundImage: `url("${bgFiche}")` }}
        >
          <Row className="justify-content-center mt-7">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      ) : (
        <>
          <Section id="banner-company-v2" className="pb-4 mt-4">
            <Row className="justify-content-center align-items-center">
              {user.id && (
                <Col lg={3}>
                  <Row className="justify-content-center">
                    {searchParams.get('back') && (
                      <Col xs={12} className="pb-4">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            if (searchParams.get('back') === 'home-reseau') {
                              navigate('/#reseau');
                            } else if (
                              searchParams.get('back') === 'home-adherents'
                            ) {
                              navigate('/#adherents');
                            } else if (
                              searchParams.get('back') === 'admin-membres'
                            ) {
                              navigate(-1);
                            } else {
                              window.location.href = searchParams.get('back');
                            }
                          }}
                        >
                          &lt; Retour
                        </Button>
                      </Col>
                    )}
                    <Col xs={12} className="user-search">
                      <Row
                        className="justify-content-center pt-2"
                        style={{ backgroundColor: '#d7f4ff' }}
                      >
                        <Col xs={12} className="">
                          <b className="d-block mb-3">Votre recherche :</b>
                          <Row className="align-items-center">
                            {user.profile_picture_url && (
                              <Col xs={4} className="text-center pe-0">
                                <img
                                  className="rounded-circle mw-100"
                                  src={user.profile_picture_url}
                                />
                              </Col>
                            )}
                            <Col xs={8} className="text-start">
                              <h4 style={{ marginBottom: 0, fontWeight: 900 }}>
                                {user.lastname} {user.firstname}
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="text-start mt-3">
                          <p className="smaller-p">
                            {user.occupation} - {user.email}
                            {user.phone && (
                              <span>
                                <br />
                                Tél. : {user.phone}
                              </span>
                            )}
                            {user.mobile_phone && (
                              <span>
                                <br />
                                Mobile : {user.mobile_phone}
                              </span>
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col lg={6} className="mt-4 mt-lg-0">
                <Row className="justify-content-center">
                  {company.logo_url && (
                    <Col xs={12} className="text-center">
                      <img
                        className="mw-100"
                        src={company.logo_url}
                        title={company.name}
                      />
                    </Col>
                  )}
                  <Col xs={12} className="text-center">
                    <h1>{company.name}</h1>
                    {company.member_types?.length > 0 ? (
                      <p className="smaller-p">
                        <b>
                          Membre de{' '}
                          {company.member_types
                            .map(memberType => memberType.name)
                            .join(', ')}
                        </b>
                      </p>
                    ) : (
                      ''
                    )}
                    {company.headquarter?.id && (
                      <p className="smaller-p">
                        <b>
                          <span style={{ textTransform: 'capitalize' }}>
                            {company.headquarter_type}
                          </span>{' '}
                          de{' '}
                          <a href={`/adherents/${company.headquarter.id}`}>
                            {company.headquarter.name}
                          </a>
                        </b>
                      </p>
                    )}
                    <p className="smaller-p">
                      {company.address} - {company.zip_code} {company.city}{' '}
                      {company.siren || company.siret ? (
                        <span>
                          <br />
                          {company.siren ? `SIREN : ${company.siren}` : ''}{' '}
                          {company.siret ? `SIRET : ${company.siret}` : ''}
                        </span>
                      ) : (
                        ''
                      )}
                      {company.contact_phone && (
                        <span>
                          <br />
                          Tél. : {company.contact_phone}{' '}
                        </span>
                      )}
                      {company.contact_email && (
                        <span>
                          <br />
                          Email : {company.contact_email}
                        </span>
                      )}
                      {company.contact_website && (
                        <a
                          href={company.contact_website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <br />
                          {company.contact_website}
                        </a>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
              {user.id && <Col xs={3}></Col>}
            </Row>
          </Section>
          <Section className="pt-0 pb-0">
            <Row className="mb-3">
              <Col md={8} className="pe-lg-4">
                <Row>
                  <Col xs={12} className="">
                    {!user.id && (
                      <>
                        {searchParams.get('back') && (
                          <Col xs={12} className="pb-4">
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                if (
                                  searchParams.get('back') === 'home-reseau'
                                ) {
                                  navigate('/#reseau');
                                } else if (
                                  searchParams.get('back') === 'home-adherents'
                                ) {
                                  navigate('/#adherents');
                                } else if (
                                  searchParams.get('back') === 'admin-membres'
                                ) {
                                  navigate(-1);
                                } else {
                                  window.location.href =
                                    searchParams.get('back');
                                }
                              }}
                            >
                              &lt; Retour
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                    <h3 style={{ fontWeight: 900 }} className="mb-3">
                      Données clés :
                    </h3>
                  </Col>
                  <Col md={12} className="ps-lg-4 pe-lg-4">
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="masonry-grid row px-2 px-lg-0 donnes-cles"
                    >
                      {company.description && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M3 19V5.70046C3 5.27995 3.26307 4.90437 3.65826 4.76067L13.3291 1.24398C13.5886 1.14961 13.8755 1.28349 13.9699 1.54301C13.9898 1.59778 14 1.65561 14 1.71388V6.6667L20.3162 8.77211C20.7246 8.90822 21 9.29036 21 9.72079V19H23V21H1V19H3ZM5 19H12V3.85543L5 6.40089V19ZM19 19V10.4416L14 8.77488V19H19Z"></path>
                              </svg>
                              Activités
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.description}
                            </p>
                            {company.facebook_url ||
                            company.instagram_url ||
                            company.linkedin_url ||
                            company.tiktok_url ? (
                              <div className="mt-3">
                                {company.facebook_url && (
                                  <a
                                    href={company.facebook_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="mx-2"
                                  >
                                    <img
                                      src={facebook}
                                      style={{ width: '20px' }}
                                    />
                                  </a>
                                )}
                                {company.instagram_url && (
                                  <a
                                    href={company.instagram_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-2"
                                  >
                                    <img
                                      src={instagram}
                                      style={{ width: '21px' }}
                                    />
                                  </a>
                                )}
                                {company.linkedin_url && (
                                  <a
                                    href={company.linkedin_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-2"
                                  >
                                    <img
                                      src={linkedin}
                                      style={{ width: '20px' }}
                                    />
                                  </a>
                                )}
                                {company.tiktok_url && (
                                  <a
                                    href={company.tiktok_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-2"
                                  >
                                    <img
                                      src={tiktok}
                                      style={{ width: '22px' }}
                                    />
                                  </a>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {company.video_url && (
                              <iframe
                                title="Video frame"
                                src={company.video_url}
                                allowFullScreen="allowfullscreen"
                                className="mw-100 mt-3"
                              ></iframe>
                            )}
                          </div>
                        </div>
                      )}
                      {company.cee_countries && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M9.97487 8.97487C11.3417 7.60804 11.3417 5.39196 9.97487 4.02513C8.60804 2.65829 6.39196 2.65829 5.02513 4.02513C3.65829 5.39196 3.65829 7.60804 5.02513 8.97487L7.5 11.4497L9.97487 8.97487ZM7.5 14.2782L3.61091 10.3891C1.46303 8.2412 1.46303 4.7588 3.61091 2.61091C5.7588 0.463029 9.2412 0.463029 11.3891 2.61091C13.537 4.7588 13.537 8.2412 11.3891 10.3891L7.5 14.2782ZM7.5 8C6.67157 8 6 7.32843 6 6.5C6 5.67157 6.67157 5 7.5 5C8.32843 5 9 5.67157 9 6.5C9 7.32843 8.32843 8 7.5 8ZM16.5 20.4497L18.9749 17.9749C20.3417 16.608 20.3417 14.392 18.9749 13.0251C17.608 11.6583 15.392 11.6583 14.0251 13.0251C12.6583 14.392 12.6583 16.608 14.0251 17.9749L16.5 20.4497ZM20.3891 19.3891L16.5 23.2782L12.6109 19.3891C10.463 17.2412 10.463 13.7588 12.6109 11.6109C14.7588 9.46303 18.2412 9.46303 20.3891 11.6109C22.537 13.7588 22.537 17.2412 20.3891 19.3891ZM16.5 17C15.6716 17 15 16.3284 15 15.5C15 14.6716 15.6716 14 16.5 14C17.3284 14 18 14.6716 18 15.5C18 16.3284 17.3284 17 16.5 17Z"></path>
                              </svg>
                              Pays desservis
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.cee_countries
                                .map(country => country.name)
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.french_states && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M9.97487 8.97487C11.3417 7.60804 11.3417 5.39196 9.97487 4.02513C8.60804 2.65829 6.39196 2.65829 5.02513 4.02513C3.65829 5.39196 3.65829 7.60804 5.02513 8.97487L7.5 11.4497L9.97487 8.97487ZM7.5 14.2782L3.61091 10.3891C1.46303 8.2412 1.46303 4.7588 3.61091 2.61091C5.7588 0.463029 9.2412 0.463029 11.3891 2.61091C13.537 4.7588 13.537 8.2412 11.3891 10.3891L7.5 14.2782ZM7.5 8C6.67157 8 6 7.32843 6 6.5C6 5.67157 6.67157 5 7.5 5C8.32843 5 9 5.67157 9 6.5C9 7.32843 8.32843 8 7.5 8ZM16.5 20.4497L18.9749 17.9749C20.3417 16.608 20.3417 14.392 18.9749 13.0251C17.608 11.6583 15.392 11.6583 14.0251 13.0251C12.6583 14.392 12.6583 16.608 14.0251 17.9749L16.5 20.4497ZM20.3891 19.3891L16.5 23.2782L12.6109 19.3891C10.463 17.2412 10.463 13.7588 12.6109 11.6109C14.7588 9.46303 18.2412 9.46303 20.3891 11.6109C22.537 13.7588 22.537 17.2412 20.3891 19.3891ZM16.5 17C15.6716 17 15 16.3284 15 15.5C15 14.6716 15.6716 14 16.5 14C17.3284 14 18 14.6716 18 15.5C18 16.3284 17.3284 17 16.5 17Z"></path>
                              </svg>
                              Départements desservis
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.french_states
                                .map(state => state.name)
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.childrens?.filter(
                        children => children.headquarter_type === 'agence'
                      )?.length > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M3 19V5.70046C3 5.27995 3.26307 4.90437 3.65826 4.76067L13.3291 1.24398C13.5886 1.14961 13.8755 1.28349 13.9699 1.54301C13.9898 1.59778 14 1.65561 14 1.71388V6.6667L20.3162 8.77211C20.7246 8.90822 21 9.29036 21 9.72079V19H23V21H1V19H3ZM5 19H12V3.85543L5 6.40089V19ZM19 19V10.4416L14 8.77488V19H19Z"></path>
                              </svg>
                              Agences
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.childrens
                                .filter(
                                  children =>
                                    children.headquarter_type === 'agence'
                                )
                                .map(agency => (
                                  <Fragment key={agency.id}>
                                    <a href={`/adherents/${agency.id}`}>
                                      {agency.name}
                                    </a>
                                    <br />
                                  </Fragment>
                                ))}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.childrens?.filter(
                        children => children.headquarter_type === 'filiale'
                      )?.length > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M3 19V5.70046C3 5.27995 3.26307 4.90437 3.65826 4.76067L13.3291 1.24398C13.5886 1.14961 13.8755 1.28349 13.9699 1.54301C13.9898 1.59778 14 1.65561 14 1.71388V6.6667L20.3162 8.77211C20.7246 8.90822 21 9.29036 21 9.72079V19H23V21H1V19H3ZM5 19H12V3.85543L5 6.40089V19ZM19 19V10.4416L14 8.77488V19H19Z"></path>
                              </svg>
                              Filiales
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.childrens
                                .filter(
                                  children =>
                                    children.headquarter_type === 'filiale'
                                )
                                .map(subsidiary => (
                                  <Fragment key={subsidiary.id}>
                                    <a href={`/adherents/${subsidiary.id}`}>
                                      {subsidiary.name}
                                    </a>
                                    <br />
                                  </Fragment>
                                ))}
                            </p>
                          </div>
                        </div>
                      )}
                      {/*{company.count_registration_documents > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M9.97487 8.97487C11.3417 7.60804 11.3417 5.39196 9.97487 4.02513C8.60804 2.65829 6.39196 2.65829 5.02513 4.02513C3.65829 5.39196 3.65829 7.60804 5.02513 8.97487L7.5 11.4497L9.97487 8.97487ZM7.5 14.2782L3.61091 10.3891C1.46303 8.2412 1.46303 4.7588 3.61091 2.61091C5.7588 0.463029 9.2412 0.463029 11.3891 2.61091C13.537 4.7588 13.537 8.2412 11.3891 10.3891L7.5 14.2782ZM7.5 8C6.67157 8 6 7.32843 6 6.5C6 5.67157 6.67157 5 7.5 5C8.32843 5 9 5.67157 9 6.5C9 7.32843 8.32843 8 7.5 8ZM16.5 20.4497L18.9749 17.9749C20.3417 16.608 20.3417 14.392 18.9749 13.0251C17.608 11.6583 15.392 11.6583 14.0251 13.0251C12.6583 14.392 12.6583 16.608 14.0251 17.9749L16.5 20.4497ZM20.3891 19.3891L16.5 23.2782L12.6109 19.3891C10.463 17.2412 10.463 13.7588 12.6109 11.6109C14.7588 9.46303 18.2412 9.46303 20.3891 11.6109C22.537 13.7588 22.537 17.2412 20.3891 19.3891ZM16.5 17C15.6716 17 15 16.3284 15 15.5C15 14.6716 15.6716 14 16.5 14C17.3284 14 18 14.6716 18 15.5C18 16.3284 17.3284 17 16.5 17Z"></path>
                              </svg>
                              Nombre de cartes grises :{' '}
                              {company.count_registration_documents}
                            </h4>
                          </div>
                        </div>
                      )}*/}
                      {/*{company.count_vehicles > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              Nombre de véhicules : {company.count_vehicles}
                            </h4>
                          </div>
                        </div>
                      )}*/}
                      {company.environmental_policies?.length > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="rgba(100,205,138,1)"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M20.998 3V5C20.998 14.6274 15.6255 19 8.99805 19L5.24077 18.9999C5.0786 19.912 4.99805 20.907 4.99805 22H2.99805C2.99805 20.6373 3.11376 19.3997 3.34381 18.2682C3.1133 16.9741 2.99805 15.2176 2.99805 13C2.99805 7.47715 7.4752 3 12.998 3C14.998 3 16.998 4 20.998 3ZM12.998 5C8.57977 5 4.99805 8.58172 4.99805 13C4.99805 13.3624 5.00125 13.7111 5.00759 14.0459C6.26198 12.0684 8.09902 10.5048 10.5019 9.13176L11.4942 10.8682C8.6393 12.4996 6.74554 14.3535 5.77329 16.9998L8.99805 17C15.0132 17 18.8692 13.0269 18.9949 5.38766C17.6229 5.52113 16.3481 5.436 14.7754 5.20009C13.6243 5.02742 13.3988 5 12.998 5Z"></path>
                              </svg>
                              Démarches environnementales
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.environmental_policies
                                .map(
                                  policy =>
                                    `${policy.name}${
                                      policy.pivot?.validity_date
                                        ? ` (validité : ${Moment(
                                            policy.pivot.validity_date
                                          )
                                            .local()
                                            .format('DD/MM/YYYY')})`
                                        : ''
                                    }`
                                )
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.carbon_balance_available === 1 ||
                      company.co2_per_km ? (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM4 5V19H20V5H4ZM16.9 13C16.4367 15.2822 14.419 17 12 17C9.23858 17 7 14.7614 7 12C7 9.58104 8.71776 7.56329 11 7.10002V13H16.9ZM16.9 11H13V7.10002C14.9591 7.4977 16.5023 9.04087 16.9 11Z"></path>
                              </svg>
                              Données carbone
                            </h4>
                            {company.carbon_balance_available === 1 && (
                              <p className="smaller-p mb-0">
                                Bilan carbone disponible
                                {company.co2_per_km && (
                                  <span>
                                    , émissions de CO2 par km :{' '}
                                    {company.co2_per_km}
                                  </span>
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {company.total_workforce > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H16C16 18.6863 13.3137 16 10 16C6.68629 16 4 18.6863 4 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11ZM18.2837 14.7028C21.0644 15.9561 23 18.752 23 22H21C21 19.564 19.5483 17.4671 17.4628 16.5271L18.2837 14.7028ZM17.5962 3.41321C19.5944 4.23703 21 6.20361 21 8.5C21 11.3702 18.8042 13.7252 16 13.9776V11.9646C17.6967 11.7222 19 10.264 19 8.5C19 7.11935 18.2016 5.92603 17.041 5.35635L17.5962 3.41321Z"></path>
                              </svg>
                              Total effectifs : {company.total_workforce}
                            </h4>
                            {company.total_workforce_including_drivers > 0 && (
                              <p className="smaller-p mb-0">
                                <b>
                                  Dont{' '}
                                  {company.total_workforce_including_drivers}{' '}
                                  conducteurs
                                </b>
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                      {/*{company.equipments && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              Type de matériel
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.equipments}
                            </p>
                          </div>
                        </div>
                      )}*/}
                      {company.trades?.length > 0 && (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M8.96456 18C8.72194 19.6961 7.26324 21 5.5 21C3.73676 21 2.27806 19.6961 2.03544 18H1V6C1 5.44772 1.44772 5 2 5H16C16.5523 5 17 5.44772 17 6V8H20L23 12.0557V18H20.9646C20.7219 19.6961 19.2632 21 17.5 21C15.7368 21 14.2781 19.6961 14.0354 18H8.96456ZM15 7H3V15.0505C3.63526 14.4022 4.52066 14 5.5 14C6.8962 14 8.10145 14.8175 8.66318 16H14.3368C14.5045 15.647 14.7296 15.3264 15 15.0505V7ZM17 13H21V12.715L18.9917 10H17V13ZM17.5 19C18.1531 19 18.7087 18.5826 18.9146 18C18.9699 17.8436 19 17.6753 19 17.5C19 16.6716 18.3284 16 17.5 16C16.6716 16 16 16.6716 16 17.5C16 17.6753 16.0301 17.8436 16.0854 18C16.2913 18.5826 16.8469 19 17.5 19ZM7 17.5C7 16.6716 6.32843 16 5.5 16C4.67157 16 4 16.6716 4 17.5C4 17.6753 4.03008 17.8436 4.08535 18C4.29127 18.5826 4.84689 19 5.5 19C6.15311 19 6.70873 18.5826 6.91465 18C6.96992 17.8436 7 17.6753 7 17.5Z"></path>
                              </svg>
                              Métiers
                            </h4>
                            <p className="smaller-p mb-0">
                              {company.trades
                                .map(trade => trade.name)
                                .join(', ')}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.facebook_url ? (
                        <div className="col-donnee mb-4">
                          <div
                            className="d-block p-3"
                            style={{ border: '2px dashed lightblue' }}
                          >
                            <h4 style={{ fontWeight: 900 }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                fill="currentColor"
                                style={{
                                  position: 'relative',
                                  bottom: '1px',
                                  marginRight: '5px'
                                }}
                              >
                                <path d="M15.4024 21V14.0344H17.7347L18.0838 11.3265H15.4024V9.59765C15.4024 8.81364 15.62 8.27934 16.7443 8.27934L18.1783 8.27867V5.85676C17.9302 5.82382 17.0791 5.75006 16.0888 5.75006C14.0213 5.75006 12.606 7.01198 12.606 9.32952V11.3265H10.2677V14.0344H12.606V21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H15.4024Z"></path>
                              </svg>
                              Actualités
                            </h4>
                            <FacebookProvider appId="872194074722899">
                              <Page
                                href={company.facebook_url}
                                tabs="timeline"
                              />
                            </FacebookProvider>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Masonry>
                  </Col>
                </Row>
              </Col>
              {company.company_representative_sections?.length > 0 && (
                <Col md={4}>
                  <Row className="mb-3">
                    <Col xs={12} className="ps-lg-0">
                      <h3 style={{ fontWeight: 900 }}>Contacts :</h3>
                    </Col>
                  </Row>
                  <Row>
                    {company.company_representative_sections
                      .sort((a, b) => a.order - b.order)
                      .map((section, index) => (
                        <Col lg={12} className="mb-4 ps-lg-0" key={index}>
                          <h5 style={{ fontWeight: 900 }}>
                            > {section.type?.name}
                          </h5>
                          <Row>
                            {section.users?.map((user, index) => (
                              <Col lg={12} className="mb-1" key={index}>
                                <div className="p-3 pb-0">
                                  <Row className="align-items-center">
                                    <Col xs={2} className="ps-1 pe-1">
                                      <img
                                        src={
                                          user.profile_picture_url
                                            ? user.profile_picture_url
                                            : user.gender !== null
                                            ? user.gender === 1
                                              ? menAvatar
                                              : womenAvatar
                                            : defaultImage
                                        }
                                        className="rounded-circle mw-100"
                                      />
                                    </Col>
                                    <Col xs={10} className="ps-1">
                                      <p className="mb-0">
                                        {localStorage.getItem('user') ? (
                                          <a
                                            href={`/adherents/${company.id}?user=${user.id}`}
                                          >
                                            <b>
                                              {user.firstname} {user.lastname}
                                            </b>
                                          </a>
                                        ) : (
                                          <b>
                                            {user.firstname} {user.lastname}
                                          </b>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      ))}
                  </Row>
                </Col>
              )}
            </Row>
          </Section>
          {company.contact_email ? (
            <ContactFromCompany company={company} />
          ) : (
            ''
          )}
          <Reseau
            reseauSectionSelected={reseauSectionSelected}
            setReseauSectionSelected={setReseauSectionSelected}
            selectedTrade={selectedTrade}
            setSelectedTrade={setSelectedTrade}
            companiesSelectedTrade={companiesSelectedTrade}
            setCompaniesSelectedTrade={setCompaniesSelectedTrade}
            companies={companies}
            trades={trades}
            back={`/adherents/${company.id}`}
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
          />
        </>
      )}

      <FooterStandard />
    </>
  );
};

export default CompanyShow;
