import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RequireAuthPartner from '../middlewares/RequireAuthPartner';

const AuthPartnerLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RequireAuthPartner(navigate);
  }, []);
  return <Outlet />;
};

export default AuthPartnerLayout;
