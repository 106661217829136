import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  Row
} from 'react-bootstrap';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import moment from 'moment';

const EmailLogsTable = ({ object, recipients }) => {
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [showEmailContent, setShowEmailContent] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [formSearch, setFormSearch] = useStateWithCallbackLazy({});
  useEffect(() => {
    let transformedObjects = [];
    object.email_logs?.map(log => {
      transformedObjects.push({
        initialObject: log,
        email: log.email ? log.email : '',
        subject: log.subject ? log.subject : '',
        created_at: log.created_at
          ? moment(log.created_at).format('DD/MM/YYYY HH:mm')
          : '',
        replied_at: recipients.find(recipient => recipient.email === log.email)
          ?.pivot?.survey_completed_on
          ? moment(
              recipients.find(recipient => recipient.email === log.email)?.pivot
                ?.survey_completed_on
            )
              .local()
              .add(2, 'hours')
              .format('DD/MM/YYYY HH:mm')
          : '',
        preview: log.body ? (
          <>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setSelectedLog(log);
                setShowEmailContent(true);
              }}
            >
              Voir
            </Button>
          </>
        ) : (
          ''
        )
      });
    });
    setInitialObjects(transformedObjects);
    setObjects(transformedObjects);
    setFormSearch({
      search: '',
      startDate: '',
      endDate: ''
    });
  }, []);

  const updateSearch = (data, objects) => {
    if (data.search.length === 0) {
      setObjects(objects);
    } else {
      let tmpFilterObjects = objects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.email && obj.email.toLowerCase().includes(tmpSearch)) ||
            (obj.subject && obj.subject.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      <Col xs={12} className="mt-1">
        <Card>
          <Card.Body>
            <h5 className="mb-3">
              Historique des emails automatiques ({initialObjects.length})
            </h5>
            {initialObjects.length > 0 && (
              <>
                <Row>
                  <Col xs={4} className="mb-3">
                    <Form.Label>Recherche</Form.Label>
                    <Form.Control
                      type="search"
                      className="search-input"
                      placeholder="Rechercher quelque chose..."
                      aria-label="Search"
                      value={formSearch.search}
                      size="sm"
                      style={{ height: '38px' }}
                      onChange={e => {
                        setFormSearch(
                          {
                            ...formSearch,
                            search: e.target.value
                          },
                          data => {
                            updateSearch(data, initialObjects, true);
                          }
                        );
                      }}
                    />
                  </Col>
                </Row>
                {objects.length > 0 ? (
                  <Row>
                    <Col xs={12}>
                      <Table data={objects} />
                    </Col>
                  </Row>
                ) : (
                  <Row className="mb-11">
                    <Col xs={12}>
                      <Alert variant="warning">Aucun résultat.</Alert>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Offcanvas
        placement={'end'}
        show={showEmailContent}
        onHide={() => {
          setShowEmailContent(false);
          setSelectedLog({});
        }}
        style={{ width: '900px' }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Aperçu de l'email</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {selectedLog.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: selectedLog.body
              }}
            ></div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

EmailLogsTable.propTypes = {
  object: PropTypes.object.isRequired,
  recipients: PropTypes.array.isRequired
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'email',
        Header: 'Destinataire'
      },
      {
        accessor: 'subject',
        Header: 'Sujet'
      },
      {
        accessor: 'created_at',
        Header: "Date d'envoi",
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'replied_at',
        Header: 'Date de réponse au formulaire',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'preview',
        Header: 'Aperçu'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div
        className="table-responsive scrollbar-visible table-container"
        id="table-logs"
      >
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            if (document.getElementById('table-logs')) {
              document.getElementById('table-logs').scrollIntoView();
            }
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            if (document.getElementById('table-logs')) {
              document.getElementById('table-logs').scrollIntoView();
            }
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            if (document.getElementById('table-logs')) {
              document.getElementById('table-logs').scrollIntoView();
            }
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            if (document.getElementById('table-logs')) {
              document.getElementById('table-logs').scrollIntoView();
            }
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default EmailLogsTable;
