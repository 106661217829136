import React from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import FooterStandard from '../../commons/FooterStandard';

const RGPD = () => {
  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{ backgroundImage: `url("${bgActualites}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>Politique de confidentialité</h2>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-flex align-items-end p-1 mt-3">
        <Row className="mt-lg-5 mb-5">
          <Col xs={12}>
            <p className="smaller-p text-justify ">
              <b>Introduction</b>
              <br />
              Devant le développement des nouveaux outils de communication, il
              est nécessaire de porter une attention particulière à la
              protection de la vie privée. C’est pourquoi, nous nous engageons à
              respecter la confidentialité des renseignements personnels que
              nous collectons.
            </p>
            <p className="smaller-p text-justify ">
              <b>Collecte des renseignements personnels</b>
              <br />
              Nous collectons les renseignements suivants : <br />- Adresse
              électronique
              <br />
              Les renseignements personnels que nous collectons sont recueillis
              au travers de formulaires et grâce à l’interactivité établie entre
              vous et notre site Web. Nous utilisons également, comme indiqué
              dans la section suivante, des fichiers témoins et/ou journaux pour
              réunir des informations vous concernant. <br />
              Formulaires et interactivité : Vos renseignements personnels sont
              collectés par le biais de formulaire, à savoir : <br />-
              Formulaire d’inscription au site Web <br />
              Nous utilisons les renseignements ainsi collectés pour les
              finalités suivantes : <br />- Informations / Offres
              promotionnelles <br />- Contact <br />
              Vos renseignements sont également collectés par le biais de
              l’interactivité pouvant s’établir entre vous et notre site Web et
              ce, de la façon suivante: <br />- Contact <br />
              Nous utilisons les renseignements ainsi collectés pour les
              finalités suivantes : <br />- Correspondance
            </p>
            <p className="smaller-p text-justify ">
              <b>Droit d’opposition et de retrait</b>
              <br />
              Nous nous engageons à vous offrir un droit d’opposition, de
              retrait et d’accès quant à vos renseignements personnels. Le droit
              d’opposition s’entend comme étant la possiblité offerte aux
              internautes de refuser que leurs renseignements personnels soient
              utilisées à certaines fins mentionnées lors de la collecte. Le
              droit de retrait s’entend comme étant la possiblité offerte aux
              internautes de demander à ce que leurs renseignements personnels
              ne figurent plus, par exemple, dans une liste de diffusion. Pour
              pouvoir exercer ces droits, vous pouvez : Courriel :
              info@tredunion.fr.
            </p>
            <p className="smaller-p text-justify ">
              <b>Droit d’accès</b>
              <br />
              Nous nous engageons à reconnaître un droit d’accès et de
              rectification aux personnes concernées désireuses de consulter,
              modifier, voire radier les informations les concernant. <br />
              L’exercice de ce droit se fera : Adresse : 4 rue de Lugano - 68180
              Horbourg-Wihr
              <br /> Mail : info@tredunion.fr
            </p>
            <p className="smaller-p text-justify ">
              <b>Sécurité</b>
              <br />
              Les renseignements personnels que nous collectons sont conservés
              dans un environnement sécurisé. Les personnes travaillant pour
              nous sont tenues de respecter la confidentialité de vos
              informations. Pour assurer la sécurité de vos renseignements
              personnels, nous avons recours aux mesures suivantes : -
              Sauvegarde informatique Nous nous engageons à maintenir un haut
              degré de confidentialité en intégrant les dernières innovations
              technologiques permettant d’assurer la confidentialité de vos
              transactions. Toutefois, comme aucun mécanisme n’offre une
              sécurité maximale, une part de risque est toujours présente
              lorsque l’on utilise Internet pour transmettre des renseignements
              personnels.
            </p>
          </Col>
          <Col xs={12}>
            <p className="smaller-p text-justify ">
              <b>
                Pour faire valoir votre droit de suppression des données
                personnelles sur le site Tred Union, vous pouvez compléter le
                formulaire suivant en cliquant sur le lien :{' '}
                <a href="/#contact">Contact</a>
              </b>
            </p>
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default RGPD;
