import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  Row,
  Table
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import SoftBadge from '../../../../common/SoftBadge';
import { getFrontUrl } from '../../../config';
import {
  DeleteSharedFile,
  IndexSharedFile,
  PostSharedFile,
  PutSharedFile
} from '../../../../../services/SharedFileService';
import FormAlert from '../../../commons/FormAlert';
import FileUploader from '../../../commons/fileUploader';

const IndexSharedFiles = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [errorFormCreate, setErrorFormCreate] = useState(false);
  const [formDataCreate, setFormDataCreate] = useState({
    title: '',
    file_url: '',
    recipients: ''
  });
  const [showShare, setShowShare] = useState(false);
  const [errorFormShare, setErrorFormShare] = useState(false);
  const [formDataShare, setFormDataShare] = useState({
    recipients: ''
  });

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexSharedFile();
      if (responseObjects.success === true) {
        setObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const handleFieldCreateChange = e => {
    setFormDataCreate({
      ...formDataCreate,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitCreate = async e => {
    e.preventDefault();
    setErrorFormShare(false);
    setIsLoading(true);
    let response = await PostSharedFile(formDataCreate);
    setErrorFormCreate(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Fichier ajouté ${
        formDataCreate.recipients !== '' && 'et envoyé aux destinataires'
      }.`;
      toast(successMessage);
      setTrigger(Date.now());
      setShowCreate(false);
      setFormDataCreate({
        title: '',
        file_url: '',
        recipients: ''
      });
    } else {
      toast(
        'Une erreur est survenue, veuillez vérifier vos champs et plus particulièrement que les adresses mail sont valides et bien séparées par des points-virgules.'
      );
    }
  };

  const handleFieldShareChange = e => {
    setFormDataShare({
      ...formDataShare,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitShare = async e => {
    e.preventDefault();
    setErrorFormShare(false);
    setIsLoading(true);
    let response = await PutSharedFile(selectedObject.id, formDataShare);
    setErrorFormShare(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Fichier envoyé aux destinataires.`;
      toast(successMessage);
      setTrigger(Date.now());
      setShowShare(false);
      setSelectedObject({});
      setFormDataShare({
        recipients: ''
      });
    } else {
      toast(
        'Une erreur est survenue, veuillez vérifier que les adresses mail sont valides et bien séparées par des points-virgules.'
      );
    }
  };

  const deleteObject = async () => {
    const response = await DeleteSharedFile(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Fichier supprimé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col xs={6}>
              <h3 className="text-primary admin-title mb-0">
                Partage de fichiers
              </h3>
              <div className="smaller-p mt-2">
                Espace disponible sur le serveur :{' '}
                <SoftBadge bg="success" className="me-2">
                  illimité
                </SoftBadge>
              </div>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="primary"
                className="ms-3 admin-primary"
                size="sm"
                onClick={() => {
                  setShowCreate(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un fichier
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Offcanvas
        show={showCreate}
        onHide={() => {
          setShowCreate(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ajouter un fichier</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="gx-2 gy-3 mb-3">
            <Col xs={12}>
              {errorFormCreate && (
                <div className="mt-0">
                  <FormAlert variant="danger" data={errorFormCreate} />
                </div>
              )}
            </Col>
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Nom du fichier :</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Nom du fichier"
                  value={formDataCreate.title || ''}
                  onChange={handleFieldCreateChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="17"
                    height="17"
                    style={{ marginRight: '4px', marginBottom: '2px' }}
                  >
                    <path
                      d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                  Fichier :
                </Form.Label>
                <FileUploader
                  pathToUpload="sharedfiles"
                  formData={formDataCreate}
                  setFormData={setFormDataCreate}
                  format="any"
                  databaseField="file_url"
                  cropperEnabled={false}
                  progressBar={true}
                />
              </Form.Group>
            </Col>
            <Col md="12" className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>
                  Adresses email{' '}
                  <SoftBadge bg="primary" className="me-2">
                    séparées par des <b>points-virgules</b>
                  </SoftBadge>
                  :
                </Form.Label>
                <Form.Control
                  type="text"
                  name="recipients"
                  placeholder="Email(s) du/des destinataire(s)"
                  value={formDataCreate.recipients || ''}
                  onChange={handleFieldCreateChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowCreate(false);
              setFormDataCreate({
                title: '',
                file_url: '',
                recipients: ''
              });
            }}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            onClick={e => {
              handleSubmitCreate(e);
            }}
            disabled={isLoading}
            className="me-2 admin-primary"
          >
            Enregistrer{' '}
            {formDataCreate.recipients && 'et envoyer aux destinataires'}
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      {objects.length > 0 && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    La suppression du fichier "{selectedObject.title}" est
                    définitive.
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, supprimer
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas
            show={showShare}
            onHide={() => {
              setShowShare(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Partager le fichier</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row className="gx-2 gy-3 mb-3">
                <Col xs={12}>
                  {errorFormShare && (
                    <div className="mt-0">
                      <FormAlert variant="danger" data={errorFormShare} />
                    </div>
                  )}
                </Col>
                <Col md="12">
                  <Form.Group controlId="name">
                    <Form.Label>
                      Adresses email{' '}
                      <SoftBadge bg="primary" className="me-2">
                        séparées par des <b>points-virgules</b>
                      </SoftBadge>
                      :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="recipients"
                      placeholder="Email(s) du/des destinataire(s)"
                      value={formDataShare.recipients || ''}
                      onChange={handleFieldShareChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowShare(false);
                  setSelectedObject({});
                  setFormDataShare({
                    emails: ''
                  });
                }}
              >
                Annuler
              </Button>
              <Button
                size="sm"
                variant="primary"
                type="submit"
                onClick={e => {
                  handleSubmitShare(e);
                }}
                disabled={isLoading}
                className="me-2 admin-primary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    marginBottom: '2px',
                    marginRight: '4px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Envoyer
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Card className="mt-3">
            <Card.Body>
              <div className="table-responsive scrollbar-visible table-container">
                <Table bordered responsive className="admin-table mt-2">
                  <thead>
                    <tr className="table-fixed-header">
                      <th scope="col">Fichier</th>
                      <th scope="col">
                        Lien de téléchargement{' '}
                        <SoftBadge bg="success" className="me-2">
                          sécurisé
                        </SoftBadge>
                      </th>
                      <th scope="col">Envoyé à</th>
                      <th scope="col">Création</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {objects.map((object, index) => (
                      <tr key={index}>
                        <td>{object.title}</td>
                        <td>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${getFrontUrl()}/partage-fichiers/${
                              object.uuid
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="16"
                              height="16"
                              style={{
                                marginRight: '3px',
                                marginBottom: '3px',
                                marginLeft: '3px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                                fill="rgba(44,123,229,1)"
                              />
                            </svg>
                            {`${getFrontUrl()}/partage-fichiers/${object.uuid}`}
                          </a>{' '}
                        </td>
                        <td>
                          {object.recipients && object.recipients.length > 0 && (
                            <>
                              {object.recipients.map((recipient, index) => (
                                <span className="d-block" key={index}>
                                  {recipient.email}
                                </span>
                              ))}
                            </>
                          )}
                        </td>
                        <td className="">
                          {Moment(object.created_at)
                            .local()
                            .format('DD/MM/YYYY')}
                        </td>
                        <td className="text-center">
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-0 mb-2"
                            type="button"
                            onClick={() => {
                              setSelectedObject(object);
                              setShowShare(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                          </Button>
                          <br />
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                              setSelectedObject(object);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array
};

export default IndexSharedFiles;
