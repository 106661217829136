import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'uploads';

export async function IndexUpload(company) {
  return modelService.IndexModel(`${model}?company_id=${company}`);
}

export async function PostUpload(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutUpload(id) {
  return modelService.PutModel(`${model}/${id}`, {
    downloaded: 1
  });
}

export async function DeleteUpload(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
