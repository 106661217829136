import React, { Fragment, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Moment from 'moment/moment';
import { IndexCourse } from '../../../../../../services/Elearning/CourseService';
import Masonry from 'react-masonry-css';
import SoftBadge from '../../../../../common/SoftBadge';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import IconButton from '../../../../../common/IconButton';
import ExportELearningEvaluations from '../../../../../../exports/elearningEvaluations';

const IndexElearningCourses = () => {
  const [trigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [selectedObjectStat, setSelectedObjectStat] = useState({});
  const [selectedObjectAttemps, setSelectedObjectAttemps] = useState([]);
  const [showStats, setShowStats] = useState(false);
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexCourse('admin');
      if (responseObjects.success === true) {
        setInitialObjects(responseObjects.data);
        setObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={5}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des formations E-learning
              </h3>
            </Col>
            <Col xs={7} className="text-end">
              <ExportELearningEvaluations objects={objects} />
              <Button
                variant="primary"
                className="ms-3 admin-primary"
                size="sm"
                as={Link}
                to="/administration/elearning-formations/ajouter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter une formation
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialObjects.length > 0 && (
        <>
          {selectedObjectStat.id !== null && selectedObjectStat !== undefined && (
            <Modal
              show={showStats}
              onHide={() => setShowStats(false)}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <h5 className="admin-title">
                  Statistiques de la formation "{selectedObjectStat.title}"
                </h5>
              </Modal.Header>
              <Modal.Body>
                {selectedObjectStat.logs &&
                selectedObjectStat.logs.length &&
                selectedObjectStat.logs.filter(log => log.log_type_id === 1)
                  .length > 0 ? (
                  <>
                    <h5 className="admin-title mb-2 mt-0">
                      <Badge bg="warning" className="me-2">
                        Formation non démarrée
                      </Badge>
                    </h5>
                    <Table bordered responsive className="admin-table mt-2">
                      <thead>
                        <tr>
                          <th scope="col">Étudiant</th>
                          <th scope="col">Inscrit le</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedObjectStat.logs
                          .filter(log => log.log_type_id === 1)
                          .map((log, index) => (
                            <tr key={index}>
                              <td>
                                {log.student.firstname} {log.student.lastname}
                                {log.student.main_company &&
                                  log.student.main_company.id !== null &&
                                  log.student.main_company.id !== undefined && (
                                    <Fragment>
                                      {' '}
                                      <SoftBadge
                                        bg="primary"
                                        className="ms-1 me-2"
                                      >
                                        {log.student.main_company.name}
                                      </SoftBadge>
                                    </Fragment>
                                  )}
                              </td>
                              <td>
                                {Moment(log.created_at)
                                  .local()
                                  .format('DD/MM/YYYY')}{' '}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <></>
                )}
                <h5 className="admin-title mb-2 mt-4">
                  <Badge bg="primary" className="me-2">
                    En cours de formation
                  </Badge>
                </h5>
                {selectedObjectStat.logs &&
                selectedObjectStat.logs.length &&
                selectedObjectStat.logs.filter(log => log.log_type_id === 2)
                  .length > 0 ? (
                  <Table bordered responsive className="admin-table mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Étudiant</th>
                        <th scope="col">Étape en cours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedObjectStat.logs
                        .filter(log => log.log_type_id === 2)
                        .map((log, index) => (
                          <tr key={index}>
                            <td>
                              {log.student.firstname} {log.student.lastname}
                              {log.student.main_company &&
                                log.student.main_company.id !== null &&
                                log.student.main_company.id !== undefined && (
                                  <Fragment>
                                    {' '}
                                    <SoftBadge
                                      bg="primary"
                                      className="ms-1 me-2"
                                    >
                                      {log.student.main_company.name}
                                    </SoftBadge>
                                  </Fragment>
                                )}
                            </td>
                            <td>
                              {log.student.course_step &&
                                log.student.course_step.id !== null &&
                                log.student.course_step.id !== undefined && (
                                  <Fragment>
                                    {log.student.course_step.title}
                                  </Fragment>
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <SoftBadge bg="warning" className="me-2">
                    Aucun étudiant en cours de formation
                  </SoftBadge>
                )}
                {selectedObjectStat.logs &&
                selectedObjectStat.logs.length > 0 &&
                selectedObjectStat.logs.filter(log => log.log_type_id === 3)
                  .length > 0 ? (
                  <>
                    <h5 className="admin-title mb-2 mt-4">
                      <Badge bg="success" className="me-2">
                        Formation terminée
                      </Badge>
                    </h5>
                    <Table bordered responsive className="admin-table mt-2">
                      <thead>
                        <tr>
                          <th scope="col">Étudiant</th>
                          <th scope="col">Terminée le</th>
                          <th scope="col">Évaluation</th>
                          <th scope="col">Certificat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedObjectStat.logs
                          .filter(log => log.log_type_id === 3)
                          .map((log, index) => (
                            <tr key={index}>
                              <td>
                                {log.student.firstname} {log.student.lastname}
                                {log.student.main_company &&
                                  log.student.main_company.id !== null &&
                                  log.student.main_company.id !== undefined && (
                                    <Fragment>
                                      {' '}
                                      <SoftBadge
                                        bg="primary"
                                        className="ms-1 me-2"
                                      >
                                        {log.student.main_company.name}
                                      </SoftBadge>
                                    </Fragment>
                                  )}
                              </td>
                              <td>
                                {Moment(log.created_at)
                                  .local()
                                  .format('DD/MM/YYYY')}
                              </td>
                              <td>
                                {selectedObjectStat.logs.filter(
                                  searchLog =>
                                    searchLog.log_type_id === 4 &&
                                    searchLog.student_id === log.student_id
                                )[0] &&
                                  selectedObjectStat.logs.filter(
                                    searchLog =>
                                      searchLog.log_type_id === 4 &&
                                      searchLog.student_id === log.student_id
                                  )[0].evaluation && (
                                    <a
                                      href={`/evaluations-elearning/${
                                        selectedObjectStat.logs.filter(
                                          searchLog =>
                                            searchLog.log_type_id === 4 &&
                                            searchLog.student_id ===
                                              log.student_id
                                        )[0].evaluation.uuid
                                      }/print`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="16"
                                        height="16"
                                        style={{
                                          marginRight: '3px',
                                          marginBottom: '3px',
                                          marginLeft: '3px'
                                        }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                                          fill="rgba(44,123,229,1)"
                                        />
                                      </svg>
                                      Voir
                                    </a>
                                  )}
                              </td>
                              <td>
                                {log.attestation && log.attestation.file_url && (
                                  <a
                                    href={log.attestation.file_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="16"
                                      height="16"
                                      style={{
                                        marginRight: '3px',
                                        marginBottom: '3px',
                                        marginLeft: '3px'
                                      }}
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path
                                        d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                                        fill="rgba(44,123,229,1)"
                                      />
                                    </svg>
                                    Accéder
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <></>
                )}
                {selectedObjectStat.steps &&
                  selectedObjectAttemps &&
                  selectedObjectAttemps.length > 0 && (
                    <>
                      <h5 className="admin-title mb-2 mt-4">
                        <Badge bg="secondary" className="me-2">
                          Nombre d'essais pour chaque étape
                        </Badge>
                      </h5>
                      <Table bordered responsive className="admin-table mt-2">
                        <thead>
                          <tr>
                            <th scope="col">Étudiant</th>
                            {selectedObjectStat.steps.map((step, index) => (
                              <th scope="col" key={index}>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="overlay-trigger-example">
                                      {step.title}
                                    </Tooltip>
                                  }
                                >
                                  <span className="cursor-default">
                                    {step.title.length > 10
                                      ? step.title.substring(0, 7) + '...'
                                      : step.title}
                                  </span>
                                </OverlayTrigger>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(selectedObjectAttemps).map((item, i) => (
                            <tr key={i}>
                              <td>
                                {selectedObjectAttemps[item][0].user.firstname}{' '}
                                {selectedObjectAttemps[item][0].user.lastname}
                                {selectedObjectAttemps[item][0].user
                                  .main_company &&
                                  selectedObjectAttemps[item][0].user
                                    .main_company.id !== null &&
                                  selectedObjectAttemps[item][0].user
                                    .main_company.id !== undefined && (
                                    <Fragment>
                                      {' '}
                                      <SoftBadge
                                        bg="primary"
                                        className="ms-1 me-2"
                                      >
                                        {
                                          selectedObjectAttemps[item][0].user
                                            .main_company.name
                                        }
                                      </SoftBadge>
                                    </Fragment>
                                  )}
                              </td>
                              {selectedObjectStat.steps.map((step, index) => (
                                <td key={index}>
                                  {
                                    selectedObjectAttemps[item].filter(
                                      attemp => attemp.step_id === step.id
                                    ).length
                                  }
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setShowStats(false)}
                  variant="primary"
                  size="sm"
                  className="me-2 admin-primary"
                >
                  Fermer
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid actualites elearning-catalog mt-4"
            columnClassName="masonry-grid_column"
          >
            {objects.map((object, index) => (
              <Fragment key={index}>
                <div key={index} className="training">
                  <Card>
                    <Card.Img src={object.image_url} variant="top" />
                    <Card.Body>
                      <Card.Title as="h5" className="mt-0">
                        {object.title}
                      </Card.Title>
                      <Card.Text>
                        {object.content.length > 100
                          ? object.content.substring(0, 97) + '...'
                          : object.content}
                      </Card.Text>
                      {object.teachers.map((teacher, index) => (
                        <SoftBadge key={index} bg="primary" className="me-2">
                          {teacher.lastname} {teacher.firstname}
                        </SoftBadge>
                      ))}
                      <div className="d-block mt-2"></div>
                      {object.is_visible === 1 ? (
                        <SoftBadge bg="success" className="me-2">
                          En ligne
                        </SoftBadge>
                      ) : (
                        <SoftBadge bg="warning" className="me-2">
                          Hors ligne
                        </SoftBadge>
                      )}
                      <div className="d-block mb-3"></div>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="button"
                        onClick={() => {
                          window.location.href = `/administration/elearning-formations/${object.id}/modifier`;
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '2px',
                            marginRight: '2px'
                          }}
                        >
                          <path
                            d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Éditer
                      </Button>
                      <IconButton
                        className="me-lg-2"
                        variant="falcon-default"
                        size="sm"
                        icon={faChartLine}
                        transform="shrink-3"
                        onClick={() => {
                          setShowStats(true);
                          setSelectedObjectStat(object);
                          function groupByKey(array, key) {
                            return array.reduce((hash, obj) => {
                              if (obj[key] === undefined) return hash;
                              return Object.assign(hash, {
                                [obj[key]]: (hash[obj[key]] || []).concat(obj)
                              });
                            }, {});
                          }
                          setSelectedObjectAttemps(
                            groupByKey(object.attemps, 'user_id')
                          );
                        }}
                      >
                        Statistiques
                      </IconButton>
                    </Card.Body>
                  </Card>
                </div>
              </Fragment>
            ))}
          </Masonry>
        </>
      )}
    </>
  );
};

export default IndexElearningCourses;
