import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Section from '../../../common/Section';
import { Col, Image, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const LogosTop = ({ companies }) => {
  let navigate = useNavigate();
  const sliderLogosSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 5,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      }
    ]
  };
  return (
    <>
      {companies.length ? (
        <Section bg="light" className="pt-5 pb-0">
          <Slider {...sliderLogosSettings} className="slick-slider-arrow-inner">
            {companies.map((company, index) => (
              <Image
                className="px-2 cursor-pointer"
                fluid
                rounded
                src={company.logo_url}
                alt=""
                key={index}
                onClick={() => {
                  navigate(`/adherents/${company.id}?back=/`);
                }}
              />
            ))}
          </Slider>
        </Section>
      ) : (
        <Section bg="light" className="py-3 shadow-sm">
          <Row className="justify-content-center">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      )}
    </>
  );
};

LogosTop.propTypes = {
  companies: PropTypes.array
};

export default LogosTop;
