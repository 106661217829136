import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'purchase-codes';

export async function IndexPurchaseCode() {
  return modelService.IndexModel(`${model}?context=memberarea`);
}

export async function IndexPurchaseCodeAdmin() {
  return modelService.IndexModel(`${model}?context=admin`);
}

export async function PostPurchaseCode(formData) {
  return modelService.PostModel(model, formData);
}

export async function DeletePurchaseCode(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PutPurchaseCode(id) {
  return modelService.PutModel(`${model}/${id}`, {
    is_downloaded: 1
  });
}
