import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import RequireAuthMembre from '../middlewares/RequireAuthMembre';
import NavbarStandard from '../components/tredunion/commons/NavbarStandard';
import MemberBanner from '../components/tredunion/authpages/members/global/banner';
import FooterStandard from '../components/tredunion/commons/FooterStandard';
import Section from '../components/common/Section';
import { Col, Row } from 'react-bootstrap';
import { ShowUser } from '../services/UserService';
import { toast } from 'react-toastify';

const AuthMembreElearningLayout = () => {
  const [trigger, setTrigger] = useState(Date.now);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    RequireAuthMembre(navigate);
    const fetchUser = async () => {
      const responseUser = await ShowUser(
        JSON.parse(localStorage.getItem('user')).id
      );
      if (responseUser.success === true) {
        localStorage.setItem('user', JSON.stringify(responseUser.data));
        setUser(responseUser.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUser();
  }, [trigger]);
  return (
    <>
      {user.id !== null && user.id !== undefined && (
        <>
          <NavbarStandard />
          <MemberBanner user={user} trigger={trigger} setTrigger={setTrigger} />
          <Section className="d-flex py-4" id="espace-membre-content">
            <Row>
              <Col xs={12}>
                <Outlet context={[user, setUser]} />
              </Col>
            </Row>
          </Section>
          <FooterStandard />
        </>
      )}
    </>
  );
};

export default AuthMembreElearningLayout;
