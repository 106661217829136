import EncartEntreprise from '../components/tredunion/authpages/members/global/EncartEntreprise';
import { Col, Row } from 'react-bootstrap';
import { membersRoutes } from '../routes/authRoutes';
import Flex from '../components/common/Flex';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const MemberSide = ({ user }) => {
  function pluck(array, key) {
    return array.map(o => o[key]);
  }
  const usePathname = () => {
    return location.pathname.replace(/\/+$/, '');
  };
  return (
    <Col lg={4} id="membre-side">
      {user.main_company !== null && user.main_company !== undefined && (
        <>
          <EncartEntreprise company={user.main_company} />
          <Row>
            <Col xs={12} className="mt-2 mb-2">
              <hr />
            </Col>
            {membersRoutes
              .filter(
                route =>
                  route.menus.includes('rightBottom') &&
                  (route.roles.length === 0 ||
                    route.roles.filter(x => pluck(user.roles, 'id').includes(x))
                      .length > 0) &&
                  (route.exceptRoles.length === 0 ||
                    route.exceptRoles.filter(x =>
                      pluck(user.roles, 'id').includes(x)
                    ).length === 0)
              )
              .map((route, index) => (
                <Flex
                  key={index}
                  className="position-relative align-items-center bottom-actions mb-2"
                >
                  <div className="icon-item icon-item-sm rounded-3 shadow-none me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="25"
                      height="25"
                    >
                      <path d={route.svg} fill="rgba(229,47,18,1)" />
                    </svg>
                  </div>
                  <div className="flex-1 ">
                    <Link
                      to={route.path}
                      className="stretched-link"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        });
                      }}
                    >
                      <h6
                        className={classNames('text-800 mb-0', {
                          active: usePathname() === route.path
                        })}
                      >
                        {route.label}
                      </h6>
                    </Link>
                  </div>
                </Flex>
              ))}
            <Flex className="position-relative align-items-center bottom-actions">
              <div className="icon-item icon-item-sm rounded-3 shadow-none me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="25"
                  height="25"
                >
                  <path
                    d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                    fill="rgba(229,47,18,1)"
                  />
                </svg>
              </div>
              <div className="flex-1 ">
                <Link to="/#reseau" className="stretched-link">
                  <h6 className="text-800 mb-0">Trouver un transporteur</h6>
                </Link>
              </div>
            </Flex>
          </Row>
        </>
      )}
    </Col>
  );
};

MemberSide.propTypes = {
  user: PropTypes.object
};

export default MemberSide;
