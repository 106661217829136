import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

import pictoPdf from '../../../../assets/img/tredunion/pictos/file-pdf.svg';
import IconButton from '../../../common/IconButton';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { membersRoutes } from '../../../../routes/authRoutes';
import { IndexInvoice } from '../../../../services/InvoiceService';
import * as Moment from 'moment';
import { useOutletContext } from 'react-router-dom';

const Factures = () => {
  const [trigger] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [years, setYears] = useState([]);
  const [user] = useOutletContext();

  useEffect(() => {
    const fetchInvoices = async () => {
      const responseInvoices = await IndexInvoice();
      if (responseInvoices.success === true) {
        setInvoices(responseInvoices.data);
        let uniqueYears = [
          ...new Set(responseInvoices.data.map(item => item.year))
        ];
        setYears(uniqueYears);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchInvoices();
  }, [trigger]);

  return (
    <Row className="mt-2" id="tred-factures">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d={
                membersRoutes.filter(route => route.id === 'tredFactures')[0]
                  .svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          {membersRoutes.filter(route => route.id === 'tredFactures')[0].label}
        </h5>
        {user.main_company !== null && user.main_company !== undefined && (
          <>
            <p className="mb-2">
              Vous avez accès aux factures de(s) société(s) suivante(s) :{' '}
            </p>
            <ul className="pb-2">
              <li>{user.main_company && user.main_company.name}</li>
              {user.secondary_companies.map((company, index) => (
                <li key={index}>{company.name}</li>
              ))}
            </ul>
          </>
        )}
      </Col>
      {isLoading === true && (
        <Col xs={12}>
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      )}
      {invoices.length > 0 && (
        <>
          {years.map((year, index) => (
            <Col key={index} xs={12}>
              <div className="d-block p-3 yearHeading">{year}</div>
              <Table responsive className="mb-5">
                <thead>
                  <tr>
                    <th scope="col" style={{ paddingLeft: '0' }}>
                      Facture
                    </th>
                    <th scope="col" className="d-none d-lg-table-cell">
                      Entreprise
                    </th>
                    <th scope="col" className="d-none d-lg-table-cell">
                      Ajoutée le
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {invoices
                    .filter(invoice => invoice.year === year)
                    .map((invoice, indexInvoice) => (
                      <tr key={indexInvoice} className="hover-actions-trigger">
                        <td>
                          <img
                            src={pictoPdf}
                            className="d-none d-lg-inline"
                            style={{ width: '14px' }}
                          />{' '}
                          {invoice.title}
                        </td>
                        <td className="d-none d-lg-table-cell">
                          {invoice.company !== null &&
                            invoice.company !== undefined && (
                              <>{invoice.company.name}</>
                            )}
                        </td>
                        <td className="d-none d-lg-table-cell">
                          {Moment(invoice.created_at)
                            .local()
                            .format('DD/MM/YYYY')}
                        </td>
                        <td className="text-end">
                          <IconButton
                            className="mb-1 me-lg-2"
                            variant="falcon-default"
                            size="sm"
                            icon={faFileDownload}
                            transform="shrink-3"
                            onClick={async () => {
                              window.open(
                                invoice.file_url,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            Accéder
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
};

export default Factures;
