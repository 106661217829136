import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Lottie from 'react-lottie-player';
import playing from '../../../../assets/img/animated-icons/playing.json';
import playWait from '../../../../assets/img/animated-icons/playwait.json';
import penWrite from '../../../../assets/img/animated-icons/penwrite.json';

const ElearningCourseVoletSteps = ({
  course,
  currentStep,
  player,
  playerPlay,
  setPlayerPlay,
  user,
  setFailedMessage,
  setSuccessMessage,
  setCurrentStep,
  updateVideo,
  scrollStepTitle,
  isEvaluationStep,
  setIsEvaluationStep
}) => {
  return (
    <Row className="steps-container">
      {course.steps.map((step, index) => (
        <Col
          xs={12}
          key={index}
          className={classNames('step-item', {
            active:
              currentStep !== null &&
              currentStep !== undefined &&
              step.id === currentStep.id
          })}
        >
          <Row className="align-items-center">
            <Col xs={9} className="py-2">
              {step.title}
              <p className="mb-0">
                <small>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="10"
                    height="10"
                    style={{
                      marginRight: '2px',
                      marginBottom: '2px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                  {step.duration} minutes
                </small>
              </p>
            </Col>
            {isEvaluationStep ? (
              <Col xs={3} className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="17"
                  height="17"
                >
                  <path
                    d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
                    fill="rgba(161,161,161,1)"
                  />
                </svg>
              </Col>
            ) : (
              <Col xs={3} className="text-center">
                {currentStep !== null &&
                currentStep !== undefined &&
                step.id === currentStep.id ? (
                  <div
                    onClick={() => {
                      player.setVolume(1);
                      playerPlay ? player.pause() : player.play();
                      setPlayerPlay(!playerPlay);
                    }}
                  >
                    {playerPlay ? (
                      <Lottie
                        loop
                        animationData={playing}
                        play
                        style={{
                          width: '20px',
                          height: '20px',
                          margin: '0 auto'
                        }}
                      />
                    ) : (
                      <Lottie
                        loop
                        animationData={playWait}
                        play
                        style={{
                          width: '30px',
                          height: '30px',
                          margin: '0 auto'
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {(course.pivot.course_current_step_id !== null &&
                      course.pivot.course_current_step_id !== undefined &&
                      step.order < currentStep.order) ||
                    (course.pivot.course_current_step_id !== null &&
                      course.pivot.course_current_step_id !== undefined &&
                      step.order <=
                        course.steps.find(
                          s => s.id === course.pivot.course_current_step_id
                        ).order) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                        className="cursor-pointer"
                        onClick={() => {
                          setFailedMessage(false);
                          setSuccessMessage(false);
                          setIsEvaluationStep(false);
                          setCurrentStep(step);
                          updateVideo(step.vimeo_id);
                          scrollStepTitle();
                        }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
                          fill="rgba(52,52,52,1)"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                      >
                        <path
                          d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
                          fill="rgba(161,161,161,1)"
                        />
                      </svg>
                    )}
                  </>
                )}
              </Col>
            )}
          </Row>
        </Col>
      ))}
      <Col
        xs={12}
        className={classNames('step-item', {
          active: isEvaluationStep
        })}
      >
        <Row className="align-items-center">
          <Col xs={9} className="py-2">
            Évaluation
            <p className="mb-0">
              <small>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="10"
                  height="10"
                  style={{
                    marginRight: '2px',
                    marginBottom: '2px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                    fill="rgba(100,99,99,1)"
                  />
                </svg>
                5 minutes
              </small>
            </p>
          </Col>
          <Col xs={3} className="text-center">
            {isEvaluationStep ? (
              <Lottie
                loop
                animationData={penWrite}
                play
                style={{ width: '50px', height: '50px', margin: '0 auto' }}
              />
            ) : (
              <>
                {user.course_isEvaluationStep ? (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setFailedMessage(false);
                      setSuccessMessage(false);
                      setCurrentStep({});
                      setIsEvaluationStep(true);
                      scrollStepTitle();
                    }}
                  >
                    <Lottie
                      loop
                      animationData={penWrite}
                      play
                      style={{
                        width: '50px',
                        height: '50px',
                        margin: '0 auto'
                      }}
                    />
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="17"
                    height="17"
                  >
                    <path
                      d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
                      fill="rgba(161,161,161,1)"
                    />
                  </svg>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        className={classNames('step-item', {
          active: false
        })}
      >
        <Row className="align-items-center">
          <Col xs={9} className="py-2">
            Mon certificat
            <p className="mb-0">
              <small>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="10"
                  height="10"
                  style={{
                    marginRight: '2px',
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M9 2.003V2h10.998C20.55 2 21 2.455 21 2.992v18.016a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6-5.997zM5.83 8H9V4.83L5.83 8zM11 4v5a1 1 0 0 1-1 1H5v10h14V4h-8z"
                    fill="rgba(161,161,161,1)"
                  />
                </svg>
                Accessible à l'issue de la formation
              </small>
            </p>
          </Col>
          <Col xs={3} className="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="17"
              height="17"
            >
              <path
                d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
                fill="rgba(161,161,161,1)"
              />
            </svg>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ElearningCourseVoletSteps.propTypes = {
  course: PropTypes.object,
  currentStep: PropTypes.any,
  player: PropTypes.any,
  playerPlay: PropTypes.any,
  setPlayerPlay: PropTypes.func,
  user: PropTypes.object,
  setFailedMessage: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  setCurrentStep: PropTypes.func,
  updateVideo: PropTypes.func,
  scrollStepTitle: PropTypes.func,
  isEvaluationStep: PropTypes.bool,
  setIsEvaluationStep: PropTypes.func
};

export default ElearningCourseVoletSteps;
