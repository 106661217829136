// config redux
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// slices
import { calendarSlice } from './slices/calendarSlice';
import { companiesSlice } from './slices/companiesSlice';
import { resourcesSlice } from './slices/resourcesSlice';
import { membersSlice } from './slices/membersSlice';
import { invoicesSlice } from './slices/invoicesSlice';
import { filesSlice } from './slices/filesSlice';
import { purchaseCodesSlice } from './slices/purchaseCodesSlice';
import { surveysSlice } from './slices/surveysSlice';
import { purchaseDeclarationsSlice } from './slices/purchaseDeclarationsSlice';
import { partnersSlice } from './slices/partnersSlice';
import { studentLogsSlice } from './slices/studentLogsSlice';
import { postsSlice } from './slices/postsSlice';
import { requestsSlice } from './slices/requestsSlice';
import { eventSlice } from './slices/eventSlice';
import { surveyIdSlice } from './slices/surveyIdSlice';
import { surveysParticipationSlice } from './slices/surveysParticipationSlice';

// config
const persistConfigCalendar = {
  key: 'calendar',
  storage
};
const persistConfigCompanies = {
  key: 'companies',
  storage
};
const persistConfigResources = {
  key: 'resources',
  storage
};

const persistConfigMembers = {
  key: 'members',
  storage
};
const persistConfigInvoices = {
  key: 'invoices',
  storage
};
const persistConfigFiles = {
  key: 'files',
  storage
};
const persistConfigPurchaseCodes = {
  key: 'purchase-codes',
  storage
};
const persistConfigSurveys = {
  key: 'surveys',
  storage
};
const persistConfigPurchaseDeclarations = {
  key: 'purchase-declarations',
  storage
};
const persistConfigPartners = {
  key: 'partners',
  storage
};
const persistConfigStudentLogs = {
  key: 'student-logs',
  storage
};
const persistConfigPosts = {
  key: 'posts',
  storage
};
const persistConfigRequests = {
  key: 'requests',
  storage
};
const persistConfigEvent = {
  key: 'event',
  storage
};
const persistConfigSurveyId = {
  key: 'survey-id',
  storage
};
const persistConfigSurveysParticipation = {
  key: 'surveys-participation',
  storage
};

// persisted reducers
const persistedCalendarReducer = persistReducer(
  persistConfigCalendar,
  calendarSlice.reducer
);
const persistedCompaniesReducer = persistReducer(
  persistConfigCompanies,
  companiesSlice.reducer
);
const persistedResourcesReducer = persistReducer(
  persistConfigResources,
  resourcesSlice.reducer
);
const persistedMembersReducer = persistReducer(
  persistConfigMembers,
  membersSlice.reducer
);
const persistedInvoicesReducer = persistReducer(
  persistConfigInvoices,
  invoicesSlice.reducer
);
const persistedFilesReducer = persistReducer(
  persistConfigFiles,
  filesSlice.reducer
);
const persistedPurchaseCodesReducer = persistReducer(
  persistConfigPurchaseCodes,
  purchaseCodesSlice.reducer
);
const persistedSurveysReducer = persistReducer(
  persistConfigSurveys,
  surveysSlice.reducer
);
const persistedPurchaseDeclarationsReducer = persistReducer(
  persistConfigPurchaseDeclarations,
  purchaseDeclarationsSlice.reducer
);
const persistedPartnersReducer = persistReducer(
  persistConfigPartners,
  partnersSlice.reducer
);
const persistedStudentLogsReducer = persistReducer(
  persistConfigStudentLogs,
  studentLogsSlice.reducer
);
const persistedPostsReducer = persistReducer(
  persistConfigPosts,
  postsSlice.reducer
);
const persistedRequestsReducer = persistReducer(
  persistConfigRequests,
  requestsSlice.reducer
);
const persistedEventReducer = persistReducer(
  persistConfigEvent,
  eventSlice.reducer
);
const persistedSurveyIdReducer = persistReducer(
  persistConfigSurveyId,
  surveyIdSlice.reducer
);
const persistedSurveysParticipationReducer = persistReducer(
  persistConfigSurveysParticipation,
  surveysParticipationSlice.reducer
);

// export store persisted
export const store = configureStore({
  reducer: {
    persistedCalendarReducer,
    persistedCompaniesReducer,
    persistedMembersReducer,
    persistedResourcesReducer,
    persistedInvoicesReducer,
    persistedFilesReducer,
    persistedPurchaseCodesReducer,
    persistedSurveysReducer,
    persistedPurchaseDeclarationsReducer,
    persistedPartnersReducer,
    persistedStudentLogsReducer,
    persistedPostsReducer,
    persistedRequestsReducer,
    persistedEventReducer,
    persistedSurveyIdReducer,
    persistedSurveysParticipationReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store);
