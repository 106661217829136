import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'adv';

export async function IndexAd(fromMembers = false, withtrashed) {
  return modelService.IndexModel(
    `${model}${
      fromMembers
        ? '?context=members'
        : withtrashed
        ? '?context=withtrashed'
        : ''
    }`
  );
}

export async function IndexAdMembers() {
  return modelService.IndexModel(`${model}?context=myindexmembers`);
}

export async function IndexAdMembersExpire() {
  return modelService.IndexModel(`${model}?context=indexmembersexpire`);
}

export async function ShowAd(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostAd(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutAd(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteAd(id, reason) {
  return modelService.DeleteModel(`${model}/${id}?cancel_reason=${reason}`);
}
