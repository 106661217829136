import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import ReactExport from 'react-data-export';
import moment from 'moment';
import { IndexToExport } from '../services/CompanyService';
import { toast } from 'react-toastify';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

import { Dropdown } from 'react-bootstrap';
import { IndexEnvironmentalPolicy } from '../services/EnvironmentalPolicyService';

const ExportCompaniesExcel = () => {
  const [multiDataSet, setMultiDataSet] = useState([]);
  const [environmentalPolicies, setEnvironmentalPolicies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObject = await IndexToExport();
      if (responseObject.success === true) {
        let companies = responseObject.data;
        let environmentalPolicies = [];
        const response = await IndexEnvironmentalPolicy();
        if (response.success === true) {
          environmentalPolicies = response.data;
        }
        const columns = [
          { title: 'MEMBRE', width: { wpx: 250 } },
          { title: 'Type' },
          { title: 'Année de création' },
          { title: 'Adresse', width: { wpx: 300 } },
          { title: 'CP' },
          { title: 'Ville', width: { wpx: 250 } },
          { title: 'Vidéo', width: { wpx: 200 } },
          { title: 'Site internet', width: { wpx: 200 } },
          { title: 'Numéro de téléphone', width: { wpx: 200 } },
          { title: 'Email générique', width: { wpx: 200 } },
          { title: 'SIREN', width: { wpx: 200 } },
          { title: 'SIRET', width: { wpx: 200 } },
          { title: 'Membre de', width: { wpx: 200 } },
          { title: 'Total effectifs', width: { wpx: 200 } },
          { title: 'Dont conducteurs', width: { wpx: 200 } },
          { title: 'Métiers', width: { wpx: 200 } },
          { title: 'Pays desservis', width: { wpx: 200 } },
          { title: 'Département desservis', width: { wpx: 200 } },
          { title: 'Activités', width: { wpx: 200 } },
          { title: 'Véhicules moteurs', width: { wpx: 200 } },
          { title: 'Tracteurs', width: { wpx: 50 } },
          { title: 'Porteurs', width: { wpx: 50 } },
          { title: 'TOTAL véhicules moteurs', width: { wpx: 50 } },
          { title: 'Véhicules non-moteurs', width: { wpx: 200 } },
          { title: 'Semi-remorques', width: { wpx: 50 } },
          { title: 'Remorques', width: { wpx: 50 } },
          { title: 'TOTAL véhicules non-moteurs', width: { wpx: 50 } },
          { title: 'Type de matériel non-moteur', width: { wpx: 200 } },
          { title: 'Benne Céréalière', width: { wpx: 50 } },
          { title: 'Benne Ferraille', width: { wpx: 50 } },
          { title: 'Benne TP', width: { wpx: 50 } },
          { title: 'Citerne', width: { wpx: 50 } },
          { title: 'FMA', width: { wpx: 50 } },
          { title: 'Fourgon', width: { wpx: 50 } },
          { title: 'Frigo', width: { wpx: 50 } },
          { title: 'Plateau', width: { wpx: 50 } },
          { title: 'Porte-engins', width: { wpx: 50 } },
          { title: 'Porte voiture', width: { wpx: 50 } },
          { title: 'Tautliner', width: { wpx: 50 } },
          { title: 'VUL', width: { wpx: 50 } },
          { title: 'Démarches environnementales', width: { wpx: 200 } }
        ];
        environmentalPolicies.map(policy => {
          columns.push({ title: policy.name, width: { wpx: 200 } });
          columns.push({
            title: policy.name + ' - Date de validité',
            width: { wpx: 200 }
          });
        });
        columns.push({ title: 'Données carbone', width: { wpx: 200 } });
        columns.push({
          title: 'Bilan carbone disponible ?',
          width: { wpx: 200 }
        });
        columns.push({ title: 'Date du bilan carbone', width: { wpx: 200 } });
        columns.push({
          title: 'g Co2 / T / km transporté',
          width: { wpx: 200 }
        });
        let data = [];
        let companiesWithChildrens = [];
        companies.map(company => {
          companiesWithChildrens.push(company);
          if (company.childrens && company.childrens.length > 0) {
            company.childrens.map(children =>
              companiesWithChildrens.push(children)
            );
          }
        });
        companiesWithChildrens.map(company => {
          data.push([
            {
              value: company.name || '',
              style: {
                font: { bold: true },
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.headquarter_id
                ? company.headquarter_type
                : 'siège',
              style: {
                font: { bold: true },
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.year_of_creation || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.address || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.zip_code || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.city || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.video_url || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.contact_website || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.contact_phone || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.contact_email || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.siren || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.siret || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                company.member_types?.length > 0
                  ? company.member_types.map(type => type.name).join(', ')
                  : '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.total_workforce || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.total_workforce_including_drivers || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                (company.trades?.length > 0 &&
                  company.trades.map(trade => trade.name).join(', ')) ||
                '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                (company.cee_countries?.length > 0 &&
                  company.cee_countries
                    .map(country => country.name)
                    .join(', ')) ||
                '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                (company.french_states?.length > 0 &&
                  company.french_states.map(state => state.name).join(', ')) ||
                '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.description || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.tracteurs || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.porteurs || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                (company.tracteurs ? parseInt(company.tracteurs) : 0) +
                (company.porteurs ? parseInt(company.porteurs) : 0),
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.semiremorques || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.remorques || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value:
                (company.semiremorques ? parseInt(company.semiremorques) : 0) +
                (company.remorques ? parseInt(company.remorques) : 0),
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.bennecerealiere || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.benneferraille || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.bennetp || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.citerne || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.fma || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.fourgon || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.frigo || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.plateau || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.porteengins || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.portevoiture || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.tautliner || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: company.vul || '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            },
            {
              value: '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            }
          ]);
          environmentalPolicies.map(policy => {
            let found = company.environmental_policies?.find(
              p => p.id === policy.id
            );
            data[data.length - 1].push({
              value: found ? 'Oui' : '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            });
            data[data.length - 1].push({
              value: found
                ? moment(found.pivot.validity_date).format('DD/MM/YYYY')
                : '',
              style: {
                fill: !company.headquarter_id
                  ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                  : '',
                border: {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' }
                }
              }
            });
          });
          data[data.length - 1].push({
            value: '',
            style: {
              fill: !company.headquarter_id
                ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                : '',
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              }
            }
          });
          data[data.length - 1].push({
            value: company.carbon_balance_available
              ? company.carbon_balance_available
              : '',
            style: {
              fill: !company.headquarter_id
                ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                : '',
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              }
            }
          });
          data[data.length - 1].push({
            value: company.carbon_balance_date
              ? moment(company.carbon_balance_date).format('DD/MM/YYYY')
              : '',
            style: {
              fill: !company.headquarter_id
                ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                : '',
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              }
            }
          });
          data[data.length - 1].push({
            value: company.co2_per_km ? company.co2_per_km : '',
            style: {
              fill: !company.headquarter_id
                ? { patternType: 'solid', fgColor: { rgb: 'DA9694' } }
                : '',
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' }
              }
            }
          });
        });
        setMultiDataSet([
          {
            columns: columns,
            data: data
          }
        ]);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
  }, []);
  return (
    <>
      {multiDataSet.length > 0 && (
        <>
          <ExcelFile
            element={
              <Dropdown.Item eventKey="1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                    fill="rgba(75,74,77,1)"
                  />
                </svg>
                Exporter les fiches (Excel)
              </Dropdown.Item>
            }
          >
            <ExcelSheet
              dataSet={multiDataSet}
              name={`TRED UNION ${moment().format('DDMMYYYY')}`}
              freezePane={{ xSplit: 1, ySplit: 1 }}
            ></ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

export default ExportCompaniesExcel;
