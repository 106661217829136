import React, { useEffect, useState } from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Offcanvas,
  Row,
  Table
} from 'react-bootstrap';
import bgPartners from '../../../../assets/img/tredunion/bg_fiche.jpeg';
import FooterStandard from '../../commons/FooterStandard';
import pictoPdf from 'assets/img/tredunion/pictos/file-pdf.svg';
import FileUploader from '../../commons/fileUploader';
import * as Moment from 'moment';
import {
  DeleteResource,
  IndexResourcePartner,
  PostResource
} from '../../../../services/ResourceService';
import { toast } from 'react-toastify';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';
import IconButton from '../../../common/IconButton';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import ExportTracks from '../../../../exports/tracks';

const HomePartners = () => {
  const [trigger, setTrigger] = useState(Date.now);
  const [user, setUser] = useState({});
  const [allowedToAdd, setAllowedToAdd] = useState(true);
  const [openAddResource, setOpenAddResource] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceIsSent, setResourceIsSent] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    file_url: ''
  });
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showStats, setShowStats] = useState(false);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
    const fetchResources = async () => {
      const responseResources = await IndexResourcePartner();
      if (responseResources.success === true) {
        setResources(responseResources.data);
        if (responseResources.data.length > 0) {
          var date1 = new Date(
            Moment(responseResources.data[0].created_at)
              .local()
              .format('MM/DD/YYYY')
          );
          var date2 = new Date();
          var Difference_In_Time = date2.getTime() - date1.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          if (Difference_In_Days < 30) {
            setAllowedToAdd(false);
            setOpenAddResource(false);
          } else {
            setOpenAddResource(true);
          }
        } else {
          setOpenAddResource(true);
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchResources();
  }, [trigger]);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await PostResource(formData);
    if (response.success === true) {
      toast('Votre fichier a été envoyé avec succès.');
      setResourceIsSent(true);
      setTimeout(function () {
        setOpenAddResource(false);
      }, 3000);
      setTimeout(function () {
        setTrigger(Date.now());
      }, 3800);
    } else if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    } else if (response.success === false) {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const deleteResource = async () => {
    const response = await DeleteResource(selectedResource.id);
    if (response.success === true) {
      setShowDelete(false);
      setAllowedToAdd(true);
      setResourceIsSent(false);
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{ backgroundImage: `url("${bgPartners}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>Espace partenaire</h2>
          </Col>
        </Row>
      </Section>
      {user.partner !== undefined && (
        <Section bg="light" className="d-flex align-items-end p-1 mt-3 mb-5">
          <Row className="mt-lg-1">
            {user.partner.logo_url !== undefined &&
              user.partner.logo_url !== null && (
                <Col xs={12}>
                  <img
                    src={user.partner.logo_url}
                    style={{ width: '100%', maxWidth: '175px' }}
                  />
                </Col>
              )}
            <Col xs={12} className="mt-2">
              <Alert variant="secondary">
                Bienvenue sur votre espace partenaire.
              </Alert>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <Card className="mb-3">
                <Card.Header
                  className="bg-light cursor-pointer"
                  onClick={() => setOpenAddResource(!openAddResource)}
                >
                  <h5 className="mb-0 redHeading">+ Transmettre un fichier</h5>
                </Card.Header>
                <Collapse in={openAddResource}>
                  <Card.Body className="text-justify text-1000">
                    {!allowedToAdd ? (
                      <IconAlert variant="warning">
                        <p className="mb-0">
                          Vous ne pouvez déposer qu'un seul fichier sur 30 jours
                          glissants.
                        </p>
                      </IconAlert>
                    ) : (
                      <>
                        {resourceIsSent ? (
                          <IconAlert variant="success">
                            <p className="mb-0">
                              Votre fichier a été envoyé avec succès.
                            </p>
                          </IconAlert>
                        ) : (
                          <Form onSubmit={handleSubmit}>
                            {errorForm && (
                              <FormAlert variant="danger" data={errorForm} />
                            )}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Titre du document</Form.Label>
                              <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Mon nouveau fichier"
                                name="title"
                                value={formData.title}
                                onChange={e => handleFieldChange(e)}
                              />
                            </Form.Group>
                            <FileUploader
                              pathToUpload="resources"
                              formData={formData}
                              setFormData={setFormData}
                              format="any"
                            />
                            <Col xs={12} className="mt-3">
                              <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="submit"
                                disabled={isLoading}
                              >
                                Envoyer mon fichier
                              </Button>
                            </Col>
                          </Form>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Collapse>
              </Card>
            </Col>
            {resources.length > 0 && (
              <Col xs={12} className="mt-3">
                <Card className="mb-3">
                  <Card.Header className="bg-light">
                    <h5 className="mb-0 redHeading">Mes fichiers</h5>
                  </Card.Header>
                  <Card.Body className="text-justify text-1000">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th scope="col">Nom</th>
                          <th scope="col" className="d-none d-lg-table-cell">
                            Envoyé le
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {resources.map((resource, index) => (
                          <tr key={index} className="hover-actions-trigger">
                            <td>
                              <img
                                src={pictoPdf}
                                className="d-none d-lg-inline"
                                style={{ width: '14px' }}
                              />{' '}
                              {resource.title}
                            </td>
                            <td className="d-none d-lg-table-cell">
                              {Moment(resource.created_at)
                                .local()
                                .format('DD/MM/YYYY')}
                            </td>
                            <td className="text-end">
                              <IconButton
                                className="mb-1 me-lg-2"
                                variant="falcon-default"
                                size="sm"
                                icon={faFileDownload}
                                transform="shrink-3"
                                onClick={async () => {
                                  window.open(
                                    resource.file_url,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }}
                              >
                                Accéder
                              </IconButton>
                              <IconButton
                                className="mb-1 me-lg-2"
                                variant="falcon-default"
                                size="sm"
                                icon={faChartLine}
                                transform="shrink-3"
                                onClick={() => {
                                  setShowStats(true);
                                  setSelectedResource(resource);
                                }}
                              >
                                Statistiques
                              </IconButton>
                              <IconButton
                                className="mb-1"
                                variant="falcon-danger"
                                size="sm"
                                icon="trash"
                                transform="shrink-3"
                                onClick={() => {
                                  setShowDelete(true);
                                  setSelectedResource(resource);
                                }}
                              >
                                Supprimer
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <Offcanvas
                  show={showStats}
                  onHide={() => {
                    setShowStats(false);
                  }}
                  placement={'end'}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Statistiques du fichier</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    {selectedResource.tracks !== undefined &&
                    selectedResource.tracks.length > 0 ? (
                      <>
                        <Table responsive className="table-stats-tracks">
                          <thead>
                            <tr>
                              <th scope="col">Action</th>
                              <th scope="col">Initiateur</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedResource.tracks.map((track, index) => (
                              <tr key={index}>
                                <td>
                                  {track.category.title === 'downloaded'
                                    ? 'Téléchargé'
                                    : 'Vu'}
                                </td>
                                <td>
                                  {track.user !== undefined &&
                                    track.user !== null && (
                                      <>
                                        {track.user.lastname}{' '}
                                        {track.user.firstname}
                                        {track.user.main_company !==
                                          undefined &&
                                          track.user.main_company !== null && (
                                            <>
                                              {' '}
                                              ({track.user.main_company.name})
                                            </>
                                          )}
                                      </>
                                    )}
                                </td>
                                <td>
                                  {Moment(track.created_at)
                                    .local()
                                    .format('DD/MM/YYYY')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <ExportTracks tracks={selectedResource.tracks} />
                      </>
                    ) : (
                      <p>
                        <small>
                          <i>Aucune donnée à afficher pour le moment.</i>
                        </small>
                      </p>
                    )}
                  </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas
                  show={showDelete}
                  onHide={() => {
                    setShowDelete(false);
                  }}
                  placement={'end'}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      La suppression est définitive.
                      <br />
                      Voulez-vous continuer ?
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Button
                      size="sm"
                      variant="falcon-danger"
                      className="me-2"
                      onClick={() => {
                        deleteResource();
                      }}
                    >
                      Oui, supprimer
                    </Button>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      onClick={() => {
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Offcanvas.Body>
                </Offcanvas>
              </Col>
            )}
          </Row>
        </Section>
      )}
      <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
        <FooterStandard />
      </div>
    </>
  );
};

export default HomePartners;
