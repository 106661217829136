import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'resource-categories';

export async function IndexResourceCategoryMember() {
  return modelService.IndexModel(`${model}?context=userresources`);
}

export async function IndexResourceCategoryAdmin() {
  return modelService.IndexModel(`${model}?context=admin`);
}

export async function PostResourceCategory(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutResourceCategory(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteResourceCategory(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
