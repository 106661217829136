import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'shared-files';

export async function IndexSharedFile() {
  return modelService.IndexModel(model);
}

export async function ShowSharedFiles(uuid) {
  return modelService.ShowModel(`${model}/${uuid}`);
}

export async function PostSharedFile(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutSharedFile(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteSharedFile(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
