import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { ShowBilansPedagogiques } from '../../../../../services/BilanPedagogiqueService';
import DatePicker from 'react-datepicker';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import ExportBilanPedagogiqueElearning from '../../../../../exports/bilanPedagogiqueElearning';

const IndexBilansPedagogiquesElearning = ({
  theme,
  fromMemberArea = false
}) => {
  const [trigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formDataSearch, setFormDataSearch] = useStateWithCallbackLazy({
    companies: [],
    courses: [],
    startDate: '',
    endDate: ''
  });

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects
      .filter(o => !o.deleted_at)
      .map(obj => {
        let inscription = '';
        if (obj.log_type_id === 1) {
          inscription = obj.created_at;
        } else {
          inscription = objects.find(
            o =>
              o.log_type_id === 1 &&
              o.course_id === obj.course_id &&
              o.student_id === obj.student_id
          )?.created_at;
        }
        let start = '';
        if (obj.log_type_id === 2) {
          start = obj.created_at;
        } else {
          start = objects.find(
            o =>
              o.log_type_id === 2 &&
              o.course_id === obj.course_id &&
              o.student_id === obj.student_id
          )?.created_at;
        }
        let end = '';
        if (obj.log_type_id === 3) {
          end = obj.created_at;
        } else {
          end = objects.find(
            o =>
              o.log_type_id === 3 &&
              o.course_id === obj.course_id &&
              o.student_id === obj.student_id
          )?.created_at;
        }
        transformedApiObjects.push({
          object: obj,
          id: obj.id,
          company: obj.student?.main_company?.name,
          user: obj.student?.firstname + ' ' + obj.student?.lastname,
          course: obj.course?.title,
          register: inscription ? Moment(inscription).format('DD/MM/YYYY') : '',
          start: start ? Moment(start)?.format('DD/MM/YYYY') : '',
          end: end ? Moment(end)?.format('DD/MM/YYYY') : '',
          duration:
            Math.round(
              obj.course.steps.reduce(function (acc, obj) {
                return acc + obj.duration;
              }, 0) / 60
            ) === 0
              ? 1
              : Math.round(
                  obj.course.steps.reduce(function (acc, obj) {
                    return acc + obj.duration;
                  }, 0) / 60
                ),
          status:
            obj.log_type_id === 3 || obj.log_type_id === 4
              ? 'Certificat récupéré'
              : 'En cours',
          created_at: obj.created_at
        });
      });
    setObjects(transformedApiObjects);
    setInitialObjects(transformedApiObjects);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await ShowBilansPedagogiques(
        theme,
        fromMemberArea
      );
      if (responseObjects.success === true) {
        getSearchInputsValues(responseObjects.data);
        setInitialApiObjects(responseObjects.data);
        setIsLoading(false);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const getSearchInputsValues = objects => {
    let tmpCourses = [];
    let tmpCompanies = [];
    objects.map(obj => {
      if (
        obj.course &&
        !tmpCourses.filter(course => course.value === obj.course.title).length >
          0
      ) {
        tmpCourses.push({
          value: obj.course.title,
          label: obj.course.title
        });
      }
      if (
        obj.student?.main_company &&
        !tmpCompanies.filter(
          company => company.value === obj.student?.main_company.name
        ).length > 0
      ) {
        tmpCompanies.push({
          value: obj.student?.main_company.name,
          label: obj.student?.main_company.name
        });
      }
    });
    setCourses(tmpCourses);
    setCompanies(tmpCompanies);
  };

  const updateSearch = (data, objects, resetPagination) => {
    if (
      data.courses.length === 0 &&
      data.companies.length === 0 &&
      !data.startDate &&
      !data.endDate
    ) {
      setObjects(objects);
    } else {
      let tmpFilterObjects = objects;
      if (data.startDate || data.endDate) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            data.startDate &&
            Moment(obj.created_at)
              .local()
              .isSameOrAfter(Moment(data.startDate)) &&
            (!data.endDate ||
              (data.endDate &&
                Moment(obj.created_at)
                  .local()
                  .isSameOrBefore(Moment(data.endDate).add(1, 'days'))))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.courses.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.courses.map(course => {
            if (obj.course === course.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (obj.company === company.value) {
              result = true;
            }
          });
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Choisir une date'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formDataSearch.startDate && formDataSearch.endDate && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setFormDataSearch(
              {
                ...formDataSearch,
                startDate: '',
                endDate: ''
              },
              data => {
                updateSearch(data, initialObjects, true);
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  return (
    <>
      {fromMemberArea === true && (
        <Card className="mb-3">
          <Card.Body className="overflow-hidden px-lg-3">
            <Row>
              <Col xs={12} className="mb-3">
                <h4>Bilans disponibles :</h4>
              </Col>
              <Col lg={12}>
                <a
                  href="/espace-membre/bilans-pedagogiques/elearning"
                  className="text-primary admin-title mb-0"
                >
                  <b>E-learning</b>
                </a>{' '}
                -{' '}
                <a
                  href="/espace-membre/bilans-pedagogiques/presentiel"
                  className="text-primary admin-title mb-0"
                >
                  Formations présentielles
                </a>{' '}
                -{' '}
                <a
                  href="/espace-membre/bilans-pedagogiques/webinar"
                  className="text-primary admin-title mb-0"
                >
                  Webinars
                </a>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <h3 className="text-primary admin-title mb-0">
                Bilan pédagogique E-learning
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row>
              <Col xs={3} className="mb-3">
                <Form.Label>Entreprise(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={companies}
                  placeholder="Choisir..."
                  isMulti
                  name="companies"
                  classNamePrefix="react-select"
                  value={formDataSearch.companies}
                  onChange={value => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        companies: value
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Formation(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={courses}
                  placeholder="Choisir..."
                  isMulti
                  name="courses"
                  classNamePrefix="react-select"
                  value={formDataSearch.courses}
                  onChange={value => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        courses: value
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3}>
                <Form.Label>Date</Form.Label>
                <DatePicker
                  onChange={dates => {
                    const [start, end] = dates;
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        startDate: start,
                        endDate: end
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                  startDate={formDataSearch.startDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={formDataSearch.endDate}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  customInput={<DatePickerInput />}
                  locale="fr"
                />
              </Col>
            </Row>
            <Row>
              {objects && objects.length > 0 && (
                <Col xs={12} className="mt-3">
                  <Table data={objects} />
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'company',
        Header: 'Entreprise'
      },
      {
        accessor: 'user',
        Header: 'Étudiant'
      },
      {
        accessor: 'course',
        Header: 'Formation'
      },
      {
        accessor: 'register',
        Header: 'Inscription'
      },
      {
        accessor: 'start',
        Header: 'Début'
      },
      {
        accessor: 'end',
        Header: 'Fin'
      },
      {
        accessor: 'duration',
        Header: 'Durée (heures)'
      },
      {
        accessor: 'status',
        Header: 'Statut'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportBilanPedagogiqueElearning logs={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

IndexBilansPedagogiquesElearning.propTypes = {
  theme: PropTypes.string,
  fromMemberArea: PropTypes.bool
};

export default IndexBilansPedagogiquesElearning;
