import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { PutUser } from '../../../../../services/UserService';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import { IndexRoles } from '../../../../../services/RoleService';
import Avatar from '../../../../common/Avatar';
import SoftBadge from '../../../../common/SoftBadge';
import { IndexCompany } from '../../../../../services/CompanyService';
import ExportUsers from '../../../../../exports/users';
import FalconLightBox from '../../../../common/FalconLightBox';
import IconButton from '../../../../common/IconButton';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { Link } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import {
  changeRoles,
  changeSearch,
  changeCompanies,
  changeNotifications
} from '../../../../../redux/slices/membersSlice';

const TableUsers = ({
  initialApiObjects,
  hideCompanyFilter,
  hideInvoiceColumn,
  hideArchivedButton,
  editBackUrl,
  showAddButton,
  companyContext
}) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [roles, setRoles] = useState([]);
  const [onlyTrashed, setOnlyTrashed] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    roles: [],
    notifications: [],
    companies: [],
    search: ''
  });

  //redux
  const dispatch = useDispatch();

  const {
    search: searchStored,
    companies: companiesStored,
    roles: rolesStored,
    notifications: notificationsStored
  } = useSelector(state => state.persistedMembersReducer);

  useEffect(() => {
    const initialFormDataSearch = {
      ...formData,
      search: searchStored,
      companies: companiesStored,
      roles: rolesStored,
      notifications: notificationsStored
    };
    setFormData(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [initialObjects]);

  useEffect(() => {
    if (formData === null) return;
    const updateFormDataSearch = {
      ...formData,
      search: searchStored,
      companies: companiesStored,
      roles: rolesStored,
      notifications: notificationsStored
    };

    setFormData(updateFormDataSearch);
  }, [searchStored, companiesStored, rolesStored, notificationsStored]);

  useEffect(() => {
    const fetchObjects = async () => {
      if (onlyTrashed) {
        transformObjects(
          initialApiObjects.filter(
            data => data.deleted_at !== null && data.deleted_at !== undefined
          )
        );
      } else {
        transformObjects(initialApiObjects.filter(data => !data.deleted_at));
      }
    };
    const fetchRoles = async () => {
      const responseObjects = await IndexRoles();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.title,
            label: r.title
          });
        });
        setRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseObjects = await IndexCompany();
      if (responseObjects.success === true) {
        let tmpCompanies = [];
        responseObjects.data.map(r => {
          tmpCompanies.push({
            value: r.name,
            label: r.name
          });
        });
        setCompanies(tmpCompanies);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchRoles();
    fetchCompanies();
    fetchObjects();
  }, [trigger]);

  const getFilename = fullPath => {
    return fullPath.replace(/^.*[\\/]/, '');
  };

  const rolesToString = roles => {
    let string = '';
    if (roles && roles.length > 0) {
      roles.map((role, index) =>
        index + 1 < roles.length
          ? (string += `${role.title}, `)
          : (string += role.title)
      );
    }
    return string;
  };

  const companiesToString = companies => {
    let string = '';
    if (companies && companies.length > 0) {
      companies.map((company, index) => {
        if (company) {
          index + 1 < companies.length
            ? (string += `${company.name}, `)
            : (string += company.name);
        }
      });
    }
    return string;
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        profile_pic:
          obj.profile_picture_url &&
          getFilename(obj.profile_picture_url) !== 'default_port.png' ? (
            <FalconLightBox image={obj.profile_picture_url}>
              <Avatar src={obj.profile_picture_url} />
            </FalconLightBox>
          ) : (
            <Avatar name={`${obj.firstname} ${obj.lastname}`} isExact={false} />
          ),
        firstname: obj.firstname,
        lastname: obj.lastname,
        fullname: `${obj.firstname} ${obj.lastname}`,
        occupation: obj.occupation,
        main_company_invoices_recipient:
          obj.main_company_invoices_recipient === 1 ? (
            <SoftBadge bg="success">Oui</SoftBadge>
          ) : (
            <SoftBadge bg="primary">Non</SoftBadge>
          ),
        rolesArray: obj.roles,
        notificationsArray: obj.notifications,
        rolesString: rolesToString(obj.roles),
        roles: (
          <>
            {obj.roles && obj.roles.length > 0 ? (
              <Fragment>
                {obj.roles.map((role, index) => (
                  <Fragment key={index}>
                    {role.title}
                    <br />
                  </Fragment>
                ))}
              </Fragment>
            ) : (
              ''
            )}
            {obj.courses && obj.courses.length > 0 && (
              <Fragment>
                <br />
                <SoftBadge bg="success" className="me-2 text-start">
                  Formation{obj.courses.length > 1 && 's'} :
                  <br />
                  {obj.courses.map((course, index) => (
                    <Fragment key={index}>
                      - {course.title}
                      <br />
                    </Fragment>
                  ))}
                </SoftBadge>
              </Fragment>
            )}
            {obj.calendar_access === 0 && (
              <Fragment>
                <br />
                <SoftBadge bg="warning" className="me-2 text-start">
                  N'a pas accès au calendrier
                </SoftBadge>
              </Fragment>
            )}
          </>
        ),
        companiesArray: obj.secondary_companies.concat([obj.main_company]),
        companiesString: companiesToString(
          obj.secondary_companies.concat([obj.main_company])
        ),
        companies: (
          <>
            {obj.main_company && (
              <SoftBadge bg="primary" className="me-2">
                <a
                  rel="noreferrer"
                  href={`/adherents/${obj.main_company.id}`}
                  target="_blank"
                >
                  {obj.main_company.name}
                </a>
              </SoftBadge>
            )}
            {obj.secondary_companies && obj.secondary_companies.length > 0 && (
              <>
                {obj.secondary_companies.map((company, index) => (
                  <SoftBadge bg="primary" key={index} className="me-2">
                    <a
                      rel="noreferrer"
                      href={`/adherents/${company.id}`}
                      target="_blank"
                    >
                      {company.name}
                    </a>
                  </SoftBadge>
                ))}
              </>
            )}
          </>
        ),
        emailOriginal: obj.email,
        email: (
          <SoftBadge bg="info" className="me-2">
            <a href={`mailto:${obj.email}`}>{obj.email}</a>
          </SoftBadge>
        ),
        login: obj.login,
        action: (
          <>
            {obj.deleted_at !== null && obj.deleted_at !== undefined ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">Remettre</Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="primary"
                  className="me-2 admin-primary"
                  type="button"
                  onClick={async () => {
                    const response = await PutUser(
                      'admin.deletefromtrash',
                      obj.id,
                      {
                        deleted_at: ''
                      }
                    );
                    if (response.success === true) {
                      toast('Opération réalisée avec succès');
                      setTrigger(Date.now());
                    } else {
                      toast(
                        'Une erreur est survenue, veuillez réessayer plus tard'
                      );
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '3px'
                    }}
                  >
                    <path
                      d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8zm0-9h4v2h-4v3l-4-4 4-4v3z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                size="sm"
                variant="primary"
                className="me-2 admin-primary"
                type="button"
                onClick={() => {
                  window.location.href = `/administration/membres/${
                    obj.id
                  }/modifier${editBackUrl ? `?back=${editBackUrl}` : ''}`;
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            )}
          </>
        )
      });
    });
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  const updateSearch = data => {
    if (
      data.roles.length === 0 &&
      data.notifications.length === 0 &&
      data.companies.length === 0 &&
      data.search.length === 0
    ) {
      setObjects(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();

        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.fullname &&
              obj.fullname
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.fullname &&
              obj.fullname
                .split(' ')
                .reverse()
                .join(' ')
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.occupation &&
              obj.occupation.toLowerCase().includes(tmpSearch)) ||
            (obj.emailOriginal &&
              obj.emailOriginal.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.roles.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.roles.map(role => {
            if (obj.rolesArray && obj.rolesArray.length > 0) {
              obj.rolesArray.map(r => {
                if (r.title === role.value) {
                  result = true;
                }
              });
            }
          });
          return result;
        });
      }
      if (data.notifications.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.notifications.map(role => {
            if (obj.notificationsArray && obj.notificationsArray.length > 0) {
              obj.notificationsArray.map(r => {
                if (r.title === role.value) {
                  result = true;
                }
              });
            }
          });
          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (obj.companiesArray && obj.companiesArray.length > 0) {
              obj.companiesArray.map(r => {
                if (r && r.name === company.value) {
                  result = true;
                }
              });
            }
          });
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="align-items-end">
              <Col xs={3} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nom, prénom, fonction, email..."
                  value={formData.search || ''}
                  onChange={event => {
                    dispatch(changeSearch(event.target.value));
                    setFormData(
                      {
                        ...formData,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Role(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={roles}
                  placeholder="Choisir..."
                  isMulti
                  name="roles"
                  classNamePrefix="react-select"
                  value={formData.roles}
                  onChange={value => {
                    dispatch(changeRoles(value));
                    setFormData(
                      {
                        ...formData,
                        roles: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Notification(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={roles}
                  placeholder="Choisir..."
                  isMulti
                  name="notifications"
                  classNamePrefix="react-select"
                  value={formData.notifications}
                  onChange={value => {
                    dispatch(changeNotifications(value));
                    setFormData(
                      {
                        ...formData,
                        notifications: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              {hideCompanyFilter !== true && (
                <Col xs={3} className="mb-3">
                  <Form.Label>Société(s)</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={companies}
                    placeholder="Choisir..."
                    isMulti
                    name="companies"
                    classNamePrefix="react-select"
                    value={formData.companies}
                    onChange={value => {
                      dispatch(changeCompanies(value));
                      setFormData(
                        {
                          ...formData,
                          companies: value
                        },
                        data => {
                          updateSearch(data);
                        }
                      );
                    }}
                  />
                </Col>
              )}
              {showAddButton && companyContext && (
                <Col xs={6} className="mb-3 text-end">
                  <Button
                    variant="primary"
                    className="admin-primary"
                    size="sm"
                    as={Link}
                    to={`/administration/membres/ajouter?company=${companyContext.id}&back=/administration/entreprises/${companyContext.id}/modifier`}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Ajouter un membre
                  </Button>
                </Col>
              )}
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table
                      data={objects}
                      onlyTrashed={onlyTrashed}
                      setOnlyTrashed={setOnlyTrashed}
                      setTrigger={setTrigger}
                      hideInvoiceColumn={hideInvoiceColumn}
                      hideArchivedButton={hideArchivedButton}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({
  data,
  setOnlyTrashed,
  setTrigger,
  onlyTrashed,
  hideInvoiceColumn,
  hideArchivedButton
}) {
  let columns = false;
  if (hideInvoiceColumn === true) {
    columns = React.useMemo(
      () => [
        {
          accessor: 'profile_pic',
          Header: ''
        },
        {
          accessor: 'firstname',
          Header: 'Prénom'
        },
        {
          accessor: 'lastname',
          Header: 'Nom'
        },
        {
          accessor: 'occupation',
          Header: 'Fonction(s)'
        },
        {
          accessor: 'roles',
          Header: 'Rôle(s)'
        },
        {
          accessor: 'companies',
          Header: 'Société(s)'
        },
        {
          accessor: 'email',
          Header: 'Email'
        },
        {
          accessor: 'action',
          Header: ''
        }
      ],
      []
    );
  } else {
    columns = React.useMemo(
      () => [
        {
          accessor: 'profile_pic',
          Header: ''
        },
        {
          accessor: 'firstname',
          Header: 'Prénom'
        },
        {
          accessor: 'lastname',
          Header: 'Nom'
        },
        {
          accessor: 'occupation',
          Header: 'Fonction(s)'
        },
        {
          accessor: 'main_company_invoices_recipient',
          Header: 'Factures'
        },
        {
          accessor: 'roles',
          Header: 'Rôle(s)'
        },
        {
          accessor: 'companies',
          Header: 'Société(s)'
        },
        {
          accessor: 'email',
          Header: 'Email'
        },
        {
          accessor: 'action',
          Header: ''
        }
      ],
      []
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-membres"
        >
          <thead className="">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, indexCol) => (
                  <th
                    key={indexCol}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportUsers users={data} />
        {hideArchivedButton !== true && (
          <IconButton
            className="mb-1 w-auto float-end me-2"
            variant="falcon-default"
            size="sm"
            icon={faEye}
            transform="shrink-3"
            onClick={() => {
              setOnlyTrashed(!onlyTrashed);
              setTrigger(Date.now());
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {onlyTrashed === false ? 'Membres archivés' : 'Tous les membres'}
          </IconButton>
        )}
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  setOnlyTrashed: PropTypes.func,
  onlyTrashed: PropTypes.bool,
  setTrigger: PropTypes.func,
  hideInvoiceColumn: PropTypes.any,
  hideArchivedButton: PropTypes.any
};

TableUsers.propTypes = {
  initialApiObjects: PropTypes.array,
  hideCompanyFilter: PropTypes.any,
  hideInvoiceColumn: PropTypes.any,
  editBackUrl: PropTypes.any,
  hideArchivedButton: PropTypes.any,
  showAddButton: PropTypes.any,
  companyContext: PropTypes.any
};

export default TableUsers;
