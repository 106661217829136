import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { useParams } from 'react-router';
import { ShowTradePress } from '../../../../services/PostTradePressService';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Moment from 'moment';
import downloadPdf from '../../../../assets/img/tredunion/download-pdf.png';

const ShowTradePressMember = () => {
  const { id } = useParams();
  const [tradePost, setTradePost] = useState({});

  useEffect(() => {
    const fecthOneTradePress = async () => {
      const response = await ShowTradePress(id);
      if (response.success) {
        setTradePost(response.data);
      }
    };
    if (id) {
      fecthOneTradePress();
    }
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className="fw-bold mt-3">{tradePost.title}</h4>
          <span className="post-date d-block pb-2">
            {Moment(tradePost.created_at).locale('fr').format('DD MMMM YYYY')}
          </span>
        </div>

        <Link to={`/espace-membre/presse-specialisee`}>
          <Button color="primary" className="mb-3">
            Retour à la liste
          </Button>
        </Link>
      </div>

      <Card className="my-3">
        <Card.Body>
          <Col className="mb-3">
            <p style={{ fontSize: 'initial', margin: 0 }}>
              {tradePost.content}
            </p>
          </Col>
          {tradePost.medias !== undefined &&
            tradePost.medias
              .filter(media =>
                ['jpg', 'jpeg', 'png', 'gif'].includes(
                  media.file_url.split('.').pop().toLowerCase()
                )
              )
              .map((media, index) => (
                <Col xs={12} key={index} className="mb-4">
                  <img className="mw-100" src={media.file_url} />
                </Col>
              ))}
          {tradePost.medias !== undefined &&
            tradePost.medias
              .filter(media =>
                ['pdf'].includes(media.file_url.split('.').pop())
              )
              .map((media, index) => (
                <Col className="text-end" xs={12} key={index}>
                  <p className="text-end">
                    <a href={media.file_url} target="_blank" rel="noreferrer">
                      <span className="d-inline-block link-brochure pr-2 ">
                        Téléchargez
                        <br />
                        le PDF
                      </span>
                      <img className="d-inline-block" src={downloadPdf} />
                    </a>
                  </p>
                </Col>
              ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ShowTradePressMember;
