import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'vis-ma-route-categories';

export async function IndexCategories() {
  return modelService.IndexModel(`${model}`);
}

export async function PutCategories(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostCategories(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteCategories(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
