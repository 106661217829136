import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  companies: [],
  courses: [],
  startDate: '',
  endDate: ''
};

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    // méthodes qui vont changer le state de search
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
    changeCourses: (state, action) => {
      state.courses = [...action.payload];
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    resetFilters: state => {
      state.search = '';
      state.companies = [];
      state.courses = [];
      state.startDate = '';
      state.endDate = '';
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSearch,
  changeCompanies,
  changeCourses,
  changeEndDate,
  changeStartDate,
  resetFilters
} = requestsSlice.actions;

export default requestsSlice.reducer;
