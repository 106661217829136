import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from '../../../../../common/Flex';
import SoftBadge from '../../../../../common/SoftBadge';

const TextAnswers = ({ question, recipients }) => {
  const [texts, setTexts] = useState([]);
  useEffect(() => {
    let tmpTexts = [];
    recipients.map(recipient => {
      if (recipient.survey_questions) {
        let recipientQuestion = recipient.survey_questions.filter(
          q => q.id === question.id
        );
        if (recipientQuestion.length > 0) {
          tmpTexts.push({
            value: recipientQuestion[0].pivot.value,
            user: recipient
          });
        }
      }
    });
    setTexts(tmpTexts);
  }, []);

  return (
    <>
      {texts.length > 0 && (
        <Col xs={4} className="mt-3">
          <Card className="h-100">
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                  <Form.Label>
                    <b>{question.title}</b>
                  </Form.Label>
                </Col>
                <Col xs={12} className="pt-4">
                  <SimpleBarReact
                    className="ask-analytics"
                    style={{ maxHeight: 370 }}
                  >
                    <div className="pt-0">
                      {texts.map((text, index) => (
                        <div
                          key={index}
                          className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3"
                        >
                          <Flex alignItems="center" className="mb-1">
                            <div
                              className="d-block mb-2"
                              style={{ fontSize: '13px' }}
                            >
                              {text.user.firstname ? (
                                <>
                                  {text.user.firstname} {text.user.lastname}
                                </>
                              ) : (
                                <SoftBadge pill bg="primary" className="ms-0">
                                  {text.user.email}
                                </SoftBadge>
                              )}
                              {text.user.main_company && (
                                <SoftBadge pill bg="primary" className="ms-0">
                                  {text.user.main_company.name}
                                </SoftBadge>
                              )}
                              {text.user.company_name && (
                                <SoftBadge pill bg="primary" className="ms-0">
                                  {text.user.company_name} (Ext.)
                                </SoftBadge>
                              )}
                            </div>
                          </Flex>
                          <div className="d-block border-top border-1 border-300 rounded-2 w-75"></div>
                          <p className="mt-2 mb-0">{text.value}</p>
                        </div>
                      ))}
                    </div>
                  </SimpleBarReact>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

// Proptypes for this component
TextAnswers.propTypes = {
  question: PropTypes.object.isRequired,
  recipients: PropTypes.array.isRequired
};

export default TextAnswers;
