import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexLog } from '../../../../../../services/Elearning/LogService';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { IndexTeachers } from '../../../../../../services/UserService';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import ExportELearningLogs from '../../../../../../exports/elearningLogs';
import { getFrontUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCourses,
  changeLogTypes,
  changeStudents,
  changeTeachers
} from '../../../../../../redux/slices/studentLogsSlice';

const IndexCourseLogs = () => {
  const [trigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [logTypes, setLogTypes] = useState([]);
  const [lateUsers, setLateUsers] = useState([]);
  const [formData, setFormData] = useStateWithCallbackLazy({
    courses: [],
    teachers: [],
    students: [],
    log_types: []
  });

  //redux
  const dispatch = useDispatch();

  const {
    courses: coursesStored,
    teachers: teachersStored,
    students: studentsStored,
    logTypes: logTypesStored
  } = useSelector(state => state.persistedStudentLogsReducer);

  useEffect(() => {
    const initialFormDataSearch = {
      ...formData,
      courses: coursesStored,
      teachers: teachersStored,
      students: studentsStored,
      log_types: logTypesStored
    };
    setFormData(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [initialObjects]);

  useEffect(() => {
    if (formData === null) return;

    const updateFormDataSearch = {
      ...formData,
      courses: coursesStored,
      teachers: teachersStored,
      students: studentsStored,
      log_types: logTypesStored
    };

    setFormData(updateFormDataSearch);
  }, [coursesStored, teachersStored, studentsStored, logTypesStored]);

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexLog();
      if (responseObjects.success === true) {
        getSearchInputsValues(responseObjects.data);
        setInitialApiObjects(responseObjects.data);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchTeachers = async () => {
      const responseUsers = await IndexTeachers();
      if (responseUsers.success === true) {
        let dataUsers = responseUsers.data;
        let usersArray = [];
        dataUsers.map(user => {
          usersArray.push({
            value: `${user.firstname} ${user.lastname}`,
            label: `${user.firstname} ${user.lastname}`
          });
        });
        setTeachers(usersArray);
      }
    };
    fetchTeachers();
    fetchObjects();
  }, [trigger]);

  const updateSearch = data => {
    if (
      data.courses.length === 0 &&
      data.teachers.length === 0 &&
      data.students.length === 0 &&
      data.log_types.length === 0
    ) {
      setObjects(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.courses.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.courses.map(course => {
            if (obj.course === course.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.teachers.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.teachers.map(teacher => {
            if (
              obj.teachers.toLowerCase().includes(teacher.value.toLowerCase())
            ) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.students.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.students.map(student => {
            if (obj.student === student.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.log_types.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.log_types.map(type => {
            if (obj.log_type === type.value) {
              result = true;
            }
          });
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  const teachersToString = teachers => {
    let string = '';
    teachers.map(teacher => {
      string += `${teacher.firstname} ${teacher.lastname} `;
    });
    return string;
  };

  const actionToComponent = log => {
    switch (log.log_type_id) {
      case 3:
        return (
          <>
            {log.attestation && (
              <a
                href={log.attestation.file_url}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  style={{
                    marginRight: '3px',
                    marginBottom: '3px',
                    marginLeft: '3px'
                  }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                    fill="rgba(44,123,229,1)"
                  />
                </svg>
                Accéder au certificat
              </a>
            )}
          </>
        );
      case 4:
        if (log.evaluation) {
          return (
            <a
              href={`/evaluations-elearning/${log.evaluation.uuid}/print`}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style={{
                  marginRight: '3px',
                  marginBottom: '3px',
                  marginLeft: '3px'
                }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z"
                  fill="rgba(44,123,229,1)"
                />
              </svg>
              Voir l'évaluation
            </a>
          );
        } else {
          return '';
        }
    }
  };

  const getSearchInputsValues = objects => {
    let tmpCourses = [];
    let tmpStudents = [];
    let tmpLogTypes = [];
    objects.map(obj => {
      if (
        obj.course &&
        !tmpCourses.filter(course => course.value === obj.course.title).length >
          0
      ) {
        tmpCourses.push({
          value: obj.course.title,
          label: obj.course.title
        });
      }
      if (
        obj.student &&
        !tmpStudents.filter(
          student =>
            student.value === `${obj.student.firstname} ${obj.student.lastname}`
        ).length > 0
      ) {
        tmpStudents.push({
          value: `${obj.student.firstname} ${obj.student.lastname}`,
          label: `${obj.student.firstname} ${obj.student.lastname}`
        });
      }
      if (
        obj.type &&
        !tmpLogTypes.filter(type => type.value === obj.type.title).length > 0
      ) {
        tmpLogTypes.push({
          value: obj.type.title,
          label: obj.type.title
        });
      }
    });
    setCourses(tmpCourses);
    setStudents(tmpStudents);
    setLogTypes(tmpLogTypes);
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    let tmpLateUsers = [];
    objects.map(obj => {
      if (obj.type && (obj.type.id === 1 || obj.type.id === 2)) {
        let now = Moment();
        let diff = now.diff(Moment(obj.created_at), 'days');
        if (diff > 60) {
          tmpLateUsers.push({
            student: obj.student,
            course: obj.course,
            diff: diff
          });
        }
      }
      transformedApiObjects.push({
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        log_type: obj.type ? obj.type.title : '',
        student: obj.student
          ? `${obj.student.firstname} ${obj.student.lastname}`
          : '',
        company:
          obj.student && obj.student.main_company
            ? obj.student.main_company.name
            : '',
        course: obj.course ? obj.course.title : '',
        teachers:
          obj.course && obj.course.teachers
            ? teachersToString(obj.course.teachers)
            : '',
        action: actionToComponent(obj),
        actionExcel:
          obj.log_type_id === 3
            ? obj.attestation && obj.attestation.file_url
            : obj.log_type_id === 4 && obj.evaluation
            ? `${getFrontUrl()}/evaluations-elearning/${
                obj.evaluation.uuid
              }/print`
            : ''
      });
    });
    setLateUsers(tmpLateUsers.sort((a, b) => a.diff - b.diff));
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={12}>
              <h3 className="text-primary admin-title mb-0">
                Journal d'activités E-learning
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            {lateUsers.length > 0 && (
              <Alert variant="warning">
                Plusieurs étudiants ont été inscrits sur une formation il y a
                plus de deux mois et n'ont toujours pas terminé :
                <ul>
                  {lateUsers.map((object, index) => (
                    <li key={index}>
                      <a
                        href={`/administration/membres/${object.student.id}/modifier`}
                      >
                        {`${object.student.firstname} ${object.student.lastname}`}
                      </a>{' '}
                      {`(inscrit.e sur la formation ${object.course.title} depuis ${object.diff} jours)`}
                    </li>
                  ))}
                </ul>
              </Alert>
            )}
            <Row>
              <Col xs={3} className="mb-3">
                <Form.Label>Formation(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={courses}
                  placeholder="Choisir..."
                  isMulti
                  name="courses"
                  classNamePrefix="react-select"
                  value={formData.courses}
                  onChange={value => {
                    dispatch(changeCourses(value));
                    setFormData(
                      {
                        ...formData,
                        courses: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Formateur(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={teachers}
                  placeholder="Choisir..."
                  isMulti
                  name="teachers"
                  classNamePrefix="react-select"
                  value={formData.teachers}
                  onChange={value => {
                    dispatch(changeTeachers(value));
                    setFormData(
                      {
                        ...formData,
                        teachers: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Étudiant(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={students}
                  placeholder="Choisir..."
                  isMulti
                  name="students"
                  classNamePrefix="react-select"
                  value={formData.students}
                  onChange={value => {
                    dispatch(changeStudents(value));
                    setFormData(
                      {
                        ...formData,
                        students: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Avancement(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={logTypes}
                  placeholder="Choisir..."
                  isMulti
                  name="log_types"
                  classNamePrefix="react-select"
                  value={formData.log_types}
                  onChange={value => {
                    dispatch(changeLogTypes(value));
                    setFormData(
                      {
                        ...formData,
                        log_types: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table data={objects} />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'created_at',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'log_type',
        Header: 'Événement'
      },
      {
        accessor: 'student',
        Header: 'Étudiant'
      },
      {
        accessor: 'company',
        Header: 'Entreprise'
      },
      {
        accessor: 'course',
        Header: 'Formation'
      },
      {
        accessor: 'teachers',
        Header: 'Formateur(s)'
      },
      {
        accessor: 'action',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportELearningLogs objects={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default IndexCourseLogs;
