import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'bilans-pedagogiques';

export async function ShowBilansPedagogiques(theme, fromMemberArea = false) {
  return modelService.ShowModel(
    `${model}/${theme}${fromMemberArea ? '?fromMemberArea=true' : ''}`
  );
}
