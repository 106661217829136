import { toast } from 'react-toastify';

export default function RequireAuthMembre(navigate) {
  if (!localStorage.getItem('accessToken') && !localStorage.getItem('user')) {
    toast('Veuillez vous connecter pour accéder au contenu');
    navigate('/login');
  } else if (
    JSON.parse(localStorage.getItem('user')).partner_id !== undefined &&
    JSON.parse(localStorage.getItem('user')).partner_id !== null
  ) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  }
}
