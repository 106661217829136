import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FileUploader from '../../../commons/fileUploader';
import FormAlert from '../../../commons/FormAlert';
import classNames from 'classnames';
import { IndexAdminInvoices } from '../../../../../services/CompanyService';
import { PostInvoice } from '../../../../../services/InvoiceService';
import {
  DeleteRecipient,
  PostRecipient
} from '../../../../../services/InvoiceExternalRecipientService';
import { useSearchParams } from 'react-router-dom';

const ManageInvoices = () => {
  const [searchParams] = useSearchParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [internalRecipients, setInternalRecipients] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    file_url: '',
    year: '',
    custom_recipient_email: '',
    company_id: '',
    internal_recipients: [],
    external_recipients: ''
  });
  const [externalRecipients, setExternalRecipients] = useState([]);
  const [selectedExternalRecipientIndex, setSelectedExternalRecipientIndex] =
    useState({});
  const [showDeleteExternalRecipient, setShowDeleteExternalRecipient] =
    useState(false);

  const removeExternalRecipient = async () => {
    if (externalRecipients[selectedExternalRecipientIndex].id) {
      let response = await DeleteRecipient(
        externalRecipients[selectedExternalRecipientIndex].id
      );
      if (response.success === true) {
        setTrigger(Date.now());
        toast('Destinataire supprimé');
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    }
    const list = [...externalRecipients];
    list.splice(selectedExternalRecipientIndex, 1);
    setExternalRecipients(list);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const responseObject = await IndexAdminInvoices();
      if (responseObject.success === true) {
        setCompanies(responseObject.data);
        if (searchParams.get('company')) {
          switchCompany(searchParams.get('company'), responseObject.data);
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCompanies();
  }, [trigger]);

  const handleFieldExternalRecipientChange = (e, indexRecipient) => {
    let tmpExternalRecipients = [...externalRecipients];
    tmpExternalRecipients[indexRecipient][e.target.name] = e.target.value;
    setExternalRecipients(tmpExternalRecipients);
  };

  const addExternalRecipient = () => {
    const list = [...externalRecipients];
    list.push({
      name: '',
      email: '',
      occupation: '',
      company_id: selectedCompanyId
    });
    setExternalRecipients(list);
  };

  const switchCompany = (id, companiesObjects) => {
    setInternalRecipients([]);
    let tmpInternalRecipientsFormData = [];
    if (id !== null && id !== '') {
      let tmpInternalRecipients = [];
      let tmpCompany = companiesObjects.filter(c => c.id === parseInt(id))[0];
      setSelectedCompanyId(tmpCompany.id);
      setExternalRecipients(tmpCompany.invoice_external_recipients);
      tmpCompany.main_users.map(user => {
        if (user.main_company_invoices_recipient === 1) {
          tmpInternalRecipientsFormData.push(user.id);
        }
        tmpInternalRecipients.push({
          id: user.id,
          text: (
            <>
              {user.firstname} {user.lastname} - {user.email}{' '}
              {user.occupation && <>({user.occupation})</>}
            </>
          )
        });
      });
      tmpCompany.users?.map(user => {
        if (user.pivot?.invoices_recipient === 1) {
          tmpInternalRecipientsFormData.push(user.id);
        }
        tmpInternalRecipients.push({
          id: user.id,
          text: (
            <>
              {user.firstname} {user.lastname} - {user.email}{' '}
              {user.occupation && <>({user.occupation})</>}
            </>
          )
        });
      });
      setInternalRecipients(tmpInternalRecipients);
    }
    setFormData({
      ...formData,
      company_id: id,
      internal_recipients: tmpInternalRecipientsFormData
    });
  };

  const handleFieldChange = e => {
    if (e.target.name === 'company_id') {
      switchCompany(e.target.value, companies);
    } else if (e.target.name === 'internal_recipients') {
      let findIdx = formData.internal_recipients.indexOf(e.target.value);
      if (findIdx <= -1) {
        findIdx = formData.internal_recipients.indexOf(
          parseInt(e.target.value)
        );
      }
      let tmpInternalRecipients = formData.internal_recipients;
      if (findIdx > -1) {
        tmpInternalRecipients.splice(findIdx, 1);
        setFormData({
          ...formData,
          internal_recipients: tmpInternalRecipients
        });
      } else {
        tmpInternalRecipients.push(e.target.value);
        setFormData({
          ...formData,
          internal_recipients: tmpInternalRecipients
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = false;
    if (externalRecipients.length > 0) {
      let tmpExternalRecipientsIds = [];
      externalRecipients.map(async recipient => {
        if (!recipient.id) {
          let postResponse = await PostRecipient(recipient);
          tmpExternalRecipientsIds.push(postResponse.data.id);
        } else {
          tmpExternalRecipientsIds.push(recipient.id);
        }
      });
      setTimeout(async function () {
        let tmpFormData = formData;
        tmpFormData = {
          ...tmpFormData,
          external_recipients: tmpExternalRecipientsIds
        };
        response = await PostInvoice(tmpFormData);
        setSuccessForm(false);
        if (response.validator) {
          setErrorForm(response.validator);
          setIsLoading(false);
        }
        if (response.success === true) {
          setIsLoading(false);
          setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 250);
          const successMessage = `Facture enregistrée et envoyée aux destinataires.`;
          //toast(successMessage);
          setSuccessForm(successMessage);
          setTrigger(Date.now());
          setTimeout(function () {
            window.location.href = `/administration/factures`;
          }, 2500);
        }
      }, 1500);
    } else {
      response = await PostInvoice(formData);
      setSuccessForm(false);
      if (response.validator) {
        setErrorForm(response.validator);
        setIsLoading(false);
      }
      if (response.success === true) {
        setIsLoading(false);
        setTimeout(function () {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 250);
        const successMessage = `Facture enregistrée et envoyée aux destinataires.`;
        //toast(successMessage);
        setSuccessForm(successMessage);
        setTrigger(Date.now());
        setTimeout(function () {
          window.location.href = `/administration/factures`;
        }, 2500);
      }
    }
  };

  return (
    <>
      {companies.length > 0 && (
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                        Ajouter une facture
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          if (searchParams.get('company')) {
                            window.location.href = `/administration/entreprises/${searchParams.get(
                              'company'
                            )}/modifier`;
                          } else {
                            window.location.href = '/administration/factures';
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        Annuler
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              {successForm && !errorForm && (
                <div className="mt-3">
                  <FormAlert
                    className="mt-3"
                    variant="success"
                    data={successForm}
                  />
                </div>
              )}
            </Col>
            <Col xs={12}>
              <Card className="mb-3 pb-3">
                <Card.Body className="bg-light">
                  <Row className="gx-2 gy-3">
                    <Col md="12">
                      <Form.Group controlId="name">
                        <Form.Label>Titre du document :</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Titre du document"
                          value={formData.title || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="name">
                        <Form.Label>Année :</Form.Label>
                        <Form.Control
                          type="text"
                          name="year"
                          placeholder="2022"
                          value={formData.year || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="pb-2 pt-3">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>Fichier :</Form.Label>
                        <FileUploader
                          pathToUpload="invoices"
                          formData={formData}
                          setFormData={setFormData}
                          format="any"
                          databaseField="file_url"
                          cropperEnabled={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="pb-2 pt-3">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="main_company_id">
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Société concernée :
                        </Form.Label>
                        <Form.Select
                          aria-label="Société concernée"
                          name="company_id"
                          value={formData.company_id}
                          onChange={handleFieldChange}
                        >
                          <option value="">Choisir...</option>
                          {companies.map((c, index) => (
                            <option value={c.id} key={index}>
                              {c.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {formData.company_id !== null &&
                      formData.company_id !== '' && (
                        <>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md={12} className="mb-2">
                            <h5 className="admin-title companyRightManagePeople">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginRight: '4px',
                                  marginBottom: '2px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                  fill="rgba(75,74,77,1)"
                                />
                              </svg>
                              Destinataires (membres inscrits sur Tred Union) :
                            </h5>
                          </Col>
                          {internalRecipients.length > 0 && (
                            <>
                              {internalRecipients.map((recipient, index) => (
                                <Col
                                  key={index}
                                  xs={12}
                                  className="mb-1 mt-0"
                                  style={{ paddingLeft: '6px' }}
                                >
                                  <label className="mb-0 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      id={`internal-recipient-${recipient.id}`}
                                      name="internal_recipients"
                                      value={recipient.id}
                                      style={{
                                        position: 'relative',
                                        marginRight: '4px',
                                        top: '1px'
                                      }}
                                      className="cursor-pointer"
                                      checked={
                                        formData.internal_recipients.filter(
                                          r => {
                                            return (
                                              r === recipient.id ||
                                              parseInt(r) === recipient.id
                                            );
                                          }
                                        ).length > 0
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    {recipient.text}
                                  </label>
                                </Col>
                              ))}
                            </>
                          )}
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md={12}>
                            <h5 className="admin-title companyRightManagePeople">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginRight: '4px',
                                  marginBottom: '2px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                  fill="rgba(75,74,77,1)"
                                />
                              </svg>
                              Destinataires non-inscrits sur Tred Union :
                            </h5>
                            <Row className="mt-3">
                              {externalRecipients.length > 0 && (
                                <>
                                  <Offcanvas
                                    show={showDeleteExternalRecipient}
                                    onHide={() => {
                                      setShowDeleteExternalRecipient(false);
                                    }}
                                    placement={'end'}
                                  >
                                    <Offcanvas.Header closeButton>
                                      <Offcanvas.Title>
                                        La suppression du destinataire est
                                        définitive.
                                        <br />
                                        Voulez-vous continuer ?
                                      </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                      <Button
                                        size="sm"
                                        variant="falcon-danger"
                                        className="me-2"
                                        onClick={() => {
                                          setShowDeleteExternalRecipient(false);
                                          removeExternalRecipient(
                                            selectedExternalRecipientIndex
                                          );
                                        }}
                                      >
                                        Oui, supprimer
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        onClick={() => {
                                          setShowDeleteExternalRecipient(false);
                                        }}
                                      >
                                        Annuler
                                      </Button>
                                    </Offcanvas.Body>
                                  </Offcanvas>
                                  {externalRecipients.map(
                                    (recipient, indexRecipient) => (
                                      <Col
                                        xs={12}
                                        key={indexRecipient}
                                        className="mb-2"
                                      >
                                        <Row className="align-items-end">
                                          <Col xs={3}>
                                            <Form.Group controlId="formGridLogin">
                                              <Form.Label>
                                                Nom / prénom
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Nom / prénom"
                                                name="name"
                                                disabled={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].id
                                                }
                                                value={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].name || ''
                                                }
                                                onChange={e => {
                                                  handleFieldExternalRecipientChange(
                                                    e,
                                                    indexRecipient
                                                  );
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xs={4}>
                                            <Form.Group controlId="formGridEmail">
                                              <Form.Label>Email</Form.Label>
                                              <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                disabled={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].id
                                                }
                                                value={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].email || ''
                                                }
                                                onChange={e => {
                                                  handleFieldExternalRecipientChange(
                                                    e,
                                                    indexRecipient
                                                  );
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>

                                          <Col xs={4}>
                                            <Form.Group controlId="formGridLastname">
                                              <Form.Label>Fonction</Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Fonction"
                                                name="occupation"
                                                disabled={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].id
                                                }
                                                value={
                                                  externalRecipients[
                                                    indexRecipient
                                                  ].occupation || ''
                                                }
                                                onChange={e => {
                                                  handleFieldExternalRecipientChange(
                                                    e,
                                                    indexRecipient
                                                  );
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col xs={1}>
                                            <Button
                                              size="sm"
                                              variant="danger"
                                              className="me-0 mb-1"
                                              type="button"
                                              onClick={() => {
                                                setSelectedExternalRecipientIndex(
                                                  indexRecipient
                                                );
                                                setShowDeleteExternalRecipient(
                                                  true
                                                );
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="14"
                                                height="14"
                                                style={{
                                                  marginBottom: '2px'
                                                }}
                                              >
                                                <path
                                                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                                  fill="rgba(255,255,255,1)"
                                                />
                                              </svg>
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )
                                  )}
                                </>
                              )}
                              <Col
                                xs={12}
                                className={classNames('', {
                                  'mt-3': externalRecipients.length > 0
                                })}
                              >
                                <Button
                                  size="sm"
                                  variant="falcon-default"
                                  className="me-2"
                                  onClick={() => {
                                    addExternalRecipient();
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                    style={{
                                      marginBottom: '2px',
                                      marginRight: '3px'
                                    }}
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                                  </svg>
                                  Ajouter un{' '}
                                  {externalRecipients.length > 0 ? 'autre' : ''}{' '}
                                  destinataire
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="12" className="pb-2 pt-3">
                            <div className="border-dashed-bottom"></div>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="name">
                              <Form.Label>
                                Transmettre le fichier par pièce jointe à
                                l'adresse mail (facultatif) :
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="custom_recipient_email"
                                placeholder="mail@tredunion.fr"
                                value={formData.custom_recipient_email || ''}
                                onChange={handleFieldChange}
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/factures';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        Annuler
                      </Button>
                      {formData.company_id !== null &&
                        formData.company_id !== '' && (
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                              style={{
                                marginBottom: '2px',
                                marginRight: '4px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Enregistrer en envoyer la facture
                          </Button>
                        )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ManageInvoices;
