import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import * as Moment from 'moment/moment';

const ExportAds = ({ logs }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    logs.map(log => {
      let cleanedCode = {
        title: log.object.title,
        category: log.category,
        price: log.price,
        status: log.object.is_active
          ? 'En ligne'
          : log.object.cancel_reason
          ? log.object.cancel_reason
          : 'Dépubliée',
        validity: Moment(log.object.validity).local().format('DD/MM/YYYY'),
        created_at: Moment(log.object.created_at).local().format('DD/MM/YYYY'),
        user: `${log.object.user?.firstname} ${log.object.user?.lastname} - ${log.object.company?.name}`
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [logs]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Journal">
              <ExcelColumn label="Titre" value="title" />
              <ExcelColumn label="Catégorie" value="category" />
              <ExcelColumn label="Prix" value="price" />
              <ExcelColumn label="Statut" value="status" />
              <ExcelColumn label="Validité" value="validity" />
              <ExcelColumn label="Date de création" value="created_at" />
              <ExcelColumn label="Utilisateur" value="user" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportAds.propTypes = {
  logs: PropTypes.array
};

export default ExportAds;
