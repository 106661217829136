import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'external-recipients';

export async function IndexExtRec() {
  return modelService.IndexModel(`${model}`);
}

export async function PostExtRec(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutExtRec(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteExtRec(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
