import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { elearningEvaluationQuestionGroups } from '../components/tredunion/config';
import * as Moment from 'moment/moment';

const ExportELearningEvaluations = ({ objects }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }
  useEffect(() => {
    objects.map(course => {
      if (course.evaluations.length > 0) {
        let tmpCourseData = [];
        course.evaluations.map(evaluation => {
          let cleanedCode = {
            created_at: Moment(evaluation.created_at)
              .local()
              .format('DD/MM/YYYY'),
            course: evaluation.course ? evaluation.course.title : '',
            student: evaluation.user
              ? `${evaluation.user.firstname} ${evaluation.user.lastname}`
              : ''
          };
          elearningEvaluationQuestionGroups.map(group => {
            group.questions.map(question => {
              cleanedCode[question.name] = evaluation[question.name];
              if (question.hasComment) {
                cleanedCode[question.hasComment] =
                  evaluation[question.hasComment];
              }
              if (question.hasYesComment) {
                cleanedCode[question.hasYesComment] =
                  evaluation[question.hasYesComment];
              }
              if (question.hasPositiveComment) {
                cleanedCode[question.hasPositiveComment] =
                  evaluation[question.hasPositiveComment];
              }
              if (question.hasYesQuestion) {
                cleanedCode[question.hasYesQuestion.name] =
                  evaluation[question.hasYesQuestion.name];
              }
            });
          });
          tmpCourseData.push(cleanedCode);
        });
        tmpCourseData.push({
          created_at: 'Moyenne',
          grade_informations_quality: (
            tmpCourseData.reduce(
              (r, c) => r + c.grade_informations_quality,
              0
            ) / tmpCourseData.length
          ).toFixed(1),
          video_number_grade: (
            tmpCourseData.reduce((r, c) => r + c.video_number_grade, 0) /
            tmpCourseData.filter(course => course.video_number_grade > 0).length
          ).toFixed(1),
          format_grade: (
            tmpCourseData.reduce((r, c) => r + c.format_grade, 0) /
            tmpCourseData.filter(course => course.format_grade > 0).length
          ).toFixed(1),
          utility_grade: (
            tmpCourseData.reduce((r, c) => r + c.utility_grade, 0) /
            tmpCourseData.filter(course => course.utility_grade > 0).length
          ).toFixed(1),
          elements_grade: (
            tmpCourseData.reduce((r, c) => r + c.elements_grade, 0) /
            tmpCourseData.filter(course => course.elements_grade > 0).length
          ).toFixed(1),
          explanations_grade: (
            tmpCourseData.reduce((r, c) => r + c.explanations_grade, 0) /
            tmpCourseData.filter(course => course.explanations_grade > 0).length
          ).toFixed(1),
          chat_grade: (
            tmpCourseData.reduce((r, c) => r + c.chat_grade, 0) /
            tmpCourseData.filter(course => course.chat_grade > 0).length
          ).toFixed(1),
          course_grade: (
            tmpCourseData.reduce((r, c) => r + c.course_grade, 0) /
            tmpCourseData.filter(course => course.course_grade > 0).length
          ).toFixed(1)
        });
        tmpCleanData.push(tmpCourseData);
      }
    });
    setCleanData(tmpCleanData);
  }, [objects]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="w-auto"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter les évaluations
              </IconButton>
            }
          >
            {cleanData.map((course, index) => {
              return (
                <ExcelSheet
                  key={index}
                  data={course}
                  name={string_to_slug(course[0].course)}
                >
                  <ExcelColumn label="Date" value="created_at" />
                  <ExcelColumn label="Formation" value="course" />
                  <ExcelColumn label="Étudiant" value="student" />
                  <ExcelColumn
                    label="Comment évaluez-vous la qualité des informations reçues en amont par TRED UNION pour débuter cette formation dans les meilleures conditions ?"
                    value="grade_informations_quality"
                  />
                  <ExcelColumn
                    label="Commentaire"
                    value="grade_informations_quality_comment"
                  />
                  <ExcelColumn
                    label="Avez-vous rencontré des difficultés techniques pour vous connecter à la chaine E LEARNING ?"
                    value="had_technical_difficulties"
                  />
                  <ExcelColumn
                    label="Commentaire"
                    value="technical_difficulties_comment"
                  />
                  <ExcelColumn
                    label="Pensez-vous que la durée de formation est adaptée ?"
                    value="duration"
                  />
                  <ExcelColumn
                    label="Comment évaluez-vous le nombre de vidéos rapport au programme pour développer le sujet ?"
                    value="video_number_grade"
                  />
                  <ExcelColumn
                    label="Commentaire"
                    value="video_number_comment"
                  />
                  <ExcelColumn
                    label="Comment évaluez-vous le format distanciel par rapport au format présentiel pour traiter le sujet ? (qualité de l'apprentissage)"
                    value="format_grade"
                  />
                  <ExcelColumn
                    label="Si vous n'êtes pas satifait de ce format, quels en ont été les problématiques ou les manques ?"
                    value="format_comment_not_satisfied"
                  />
                  <ExcelColumn
                    label="Si vous êtes satifait de ce format, quels en ont été les atouts ?"
                    value="format_comment_satisfied"
                  />
                  <ExcelColumn
                    label="Pensez-vous que l'apprentissage pendant cette session de formation vous sera utile ?"
                    value="utility_grade"
                  />
                  <ExcelColumn label="Commentaire" value="utility_comment" />
                  <ExcelColumn
                    label="Pensez-vous que les sujets abordés pendant cette session de formation sont adaptés à votre entreprise ?"
                    value="elements_grade"
                  />
                  <ExcelColumn label="Commentaire" value="elements_comment" />
                  <ExcelColumn
                    label="Pensez-vous que les explications données par l'animateur pendant cette session de formation favorisaient la compréhension ?"
                    value="explanations_grade"
                  />
                  <ExcelColumn
                    label="Commentaire"
                    value="explanations_comment"
                  />
                  <ExcelColumn
                    label="Avez-vous échangé avec votre animateur par mail durant la formation ?"
                    value="had_used_chat"
                  />
                  <ExcelColumn
                    label="Si oui, quel est votre degré de satisfaction ?"
                    value="chat_grade"
                  />
                  <ExcelColumn label="Commentaire" value="chat_grade_comment" />
                  <ExcelColumn
                    label="Sur une échelle de 1 à 10, quel est votre degré de satisfaction pour cette session de formation ?"
                    value="course_grade"
                  />
                  <ExcelColumn
                    label="Votre commentaire général sur la formation"
                    value="course_comment"
                  />
                </ExcelSheet>
              );
            })}
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportELearningEvaluations.propTypes = {
  objects: PropTypes.array
};

export default ExportELearningEvaluations;
