import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import logoTredUnion from 'assets/img/tredunion/logo-TRED-UNION.png';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import FooterStandard from '../../commons/FooterStandard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShowResourceByOldLink } from '../../../../services/ResourceService';

const OldResourceDl = () => {
  const [searchParams] = useSearchParams();
  const [fileName] = useState(searchParams.get('file'));
  const [object, setObject] = useState({});
  const [doesntExists, setDoesntExists] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowResourceByOldLink(fileName);
      if (responseObject.success === true) {
        setObject(responseObject.data);
      } else {
        setDoesntExists(true);
        setTimeout(function () {
          navigate('/');
        }, 4500);
      }
    };
    fetchObject();
  }, []);
  return (
    <>
      <NavbarStandard />
      <Section bg="light" className="d-flex align-items-end p-1 mt-7 pt-5">
        <Row className="justify-content-center share-file-page">
          <Col xs={12} md={8} className="mt-4 mb-4 text-center">
            <img src={logoTredUnion} className="logo-tred-union" />
            <h3 className="admin-title mt-4 mb-5">Tred Ressource</h3>
            {doesntExists ? (
              <Alert variant="danger">
                Le fichier n'existe plus. <br />
                <small>
                  Vous allez être redirigé d'ici quelques instants sur l'accueil
                  du site.
                </small>
              </Alert>
            ) : (
              <>
                {object.id && (
                  <>
                    <p className="mb-5">
                      <span className="fileName">{object.title}</span>
                    </p>
                    {downloaded ? (
                      <Alert variant="success">
                        Téléchargement de votre fichier en cours... <br />
                        <small>
                          Vous allez être redirigé d'ici quelques instants sur
                          l'accueil du site.
                        </small>
                      </Alert>
                    ) : (
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                          window.location.href = object.file_url;
                          setDownloaded(true);
                          setTimeout(function () {
                            navigate('/');
                          }, 4500);
                        }}
                        className="btn-partenaires big"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginRight: '5px', marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M13 13v5.585l1.828-1.828 1.415 1.415L12 22.414l-4.243-4.242 1.415-1.415L11 18.585V13h2zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Télécharger mon fichier
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Section>

      <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
        <FooterStandard />
      </div>
    </>
  );
};

OldResourceDl.propTypes = {
  evaluation: PropTypes.any
};

export default OldResourceDl;
