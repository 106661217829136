import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { membersRoutes } from '../../../../routes/authRoutes';
import { IndexCompanyExport } from '../../../../services/CompanyExportService';
import ExportCompanies from '../../../../exports/companiesExports';

const TelechargerAnnuaire = () => {
  const [exports, setExports] = useState([]);
  useEffect(() => {
    const fetchExports = async () => {
      const responseExports = await IndexCompanyExport('user');
      if (responseExports.success === true) {
        setExports(responseExports.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchExports();
  }, []);

  return (
    <Row className="mt-2">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              d={
                membersRoutes.filter(route => route.id === 'exportAnnuaire')[0]
                  .svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          {
            membersRoutes.filter(route => route.id === 'exportAnnuaire')[0]
              .label
          }
        </h5>
      </Col>
      {exports.length > 0 ? (
        <Col xs={12}>
          <Table responsive className="table-fichiers mt-1">
            <thead>
              <tr>
                <th scope="col">Annuaire</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {exports.map((exp, index) => (
                <tr key={index} className="hover-actions-trigger">
                  <td>{exp.title}</td>
                  <td className="text-end">
                    <ExportCompanies exp={exp} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      ) : (
        <Col xs={12}>
          <p>Aucun annuaire disponible pour le moment.</p>
        </Col>
      )}
    </Row>
  );
};

export default TelechargerAnnuaire;
