import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Section from '../../../../common/Section';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import FileUploader from '../../../commons/fileUploader';
import { toast } from 'react-toastify';
import { PutUser } from '../../../../../services/UserService';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { membersRoutes } from '../../../../../routes/authRoutes';
import waitImage from 'assets/img/tredunion/wait-tred1.jpeg';
import { IndexResourceCategoryMember } from '../../../../../services/ResourceCategoryService';
import { ShowResource } from '../../../../../services/ResourceService';
import { IndexAdMembers } from '../../../../../services/AdService';

const MemberBanner = ({ user, setTrigger }) => {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname.replace(/\/+$/, '');
  };
  const [waitings, setWaitings] = useState([]);
  const [waitingsAds, setWaitingsAds] = useState([]);
  const navigate = useNavigate();
  const [showUpdateProfilePic, setShowUpdateProfilePic] = useState(false);
  const [showUpdateBannerPic, setShowUpdateBannerPic] = useState(false);
  const [formUpdateProfilePicData, setFormUpdateProfilePicData] = useState({
    file_url: ''
  });
  const [formUpdateBannerPicData, setFormUpdateBannerPicData] = useState({
    file_url: ''
  });

  const getRecursiveCategories = (category, categories, resources) => {
    categories.push(category);
    category.resources
      .filter(r => r.is_moderated === 1)
      .map(resource => {
        resources.push(resource);
      });
    if (category.childrens.length > 0) {
      category.childrens.map(children => {
        getRecursiveCategories(children, categories, resources);
      });
    }
  };

  useEffect(() => {
    const fetchResourceCategories = async () => {
      const responseResourceCategories = await IndexResourceCategoryMember();
      if (responseResourceCategories.success === true) {
        let splittedCategories = [];
        let splittedResources = [];
        responseResourceCategories.data.map(category => {
          getRecursiveCategories(
            category,
            splittedCategories,
            splittedResources
          );
        });
        setWaitings(
          splittedResources.filter(r => {
            if (r.tracks.length === 0) {
              let date = new Date(r.created_at);
              let dateLimit = new Date('2023-12-01 00:00:00');
              return date > dateLimit;
            } else {
              return false;
            }
          })
        );
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchResourceCategories();
    const fetchAds = async () => {
      const response = await IndexAdMembers();
      if (response.success === true) {
        setWaitingsAds(response.data?.filter(ad => ad.ad_tracks?.length === 0));
      }
    };
    fetchAds();
  }, []);

  function pluck(array, key) {
    return array.map(o => o[key]);
  }

  const handleUpdateProfilePicSubmit = async e => {
    e.preventDefault();
    const response = await PutUser(
      'profile.updateprofilepic',
      user.id,
      formUpdateProfilePicData
    );
    if (response.success === true) {
      toast('Photo de profil mise à jour');
      setShowUpdateProfilePic(false);
      setTrigger(Date.now());
      setTimeout(function () {
        location.reload();
      }, 1000);
    } else if (response.validator) {
      toast('Veuillez sélectionner une image pour pouvoir continuer');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const handleUpdateBannerPicSubmit = async e => {
    e.preventDefault();
    const response = await PutUser(
      'profile.updatebannerpic',
      user.id,
      formUpdateBannerPicData
    );
    if (response.success === true) {
      toast('Photo de couverture mise à jour');
      setShowUpdateBannerPic(false);
      setTrigger(Date.now());
    } else if (response.validator) {
      toast('Veuillez sélectionner une image pour pouvoir continuer');
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-center p-1"
        id="espace-membre"
        style={{
          backgroundImage: `url("${user.banner_picture_url ?? waitImage}")`
        }}
      >
        <Offcanvas
          show={showUpdateBannerPic}
          onHide={() => {
            setShowUpdateBannerPic(false);
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Modifier ma photo de couverture</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form onSubmit={handleUpdateBannerPicSubmit}>
              <FileUploader
                pathToUpload="users/bannerpics"
                formData={formUpdateBannerPicData}
                setFormData={setFormUpdateBannerPicData}
                format="img"
              />
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2 mt-3"
                type="submit"
              >
                Enregistrer
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
        <Row>
          <Col
            xs={12}
            className="d-none d-lg-block text-end btn-change-banner-container"
          >
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2 mt-0"
              onClick={() => {
                setShowUpdateBannerPic(true);
              }}
            >
              Modifier ma photo de couverture
            </Button>
          </Col>
          <Col xs={12} className="text-center d-none d-sm-block">
            <div
              className="user-profile-picture"
              style={{ backgroundImage: `url("${user.profile_picture_url}")` }}
              onClick={() => {
                setShowUpdateProfilePic(true);
              }}
            ></div>
            <Offcanvas
              show={showUpdateProfilePic}
              onHide={() => {
                setShowUpdateProfilePic(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Modifier ma photo de profil</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form onSubmit={handleUpdateProfilePicSubmit}>
                  <FileUploader
                    pathToUpload="users/profilepics"
                    formData={formUpdateProfilePicData}
                    setFormData={setFormUpdateProfilePicData}
                    format="img"
                  />
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2 mt-3"
                    type="submit"
                  >
                    Enregistrer
                  </Button>
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </Row>
      </Section>
      <Section
        className="d-none d-lg-flex align-items-center p-0"
        id="espace-membre-acces"
      >
        <Row>
          <Col
            lg={2}
            className={classNames(
              'membre-acces-tuile cursor-pointer text-center p-3',
              {
                active:
                  usePathname() === '/espace-membre/accueil' ||
                  usePathname() === '/espace-membre/transferer-un-fichier' ||
                  usePathname() === '/espace-membre/trouver-un-membre' ||
                  usePathname() === '/espace-membre/informations'
              }
            )}
            onClick={() => {
              navigate('/espace-membre/accueil');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="36"
              height="36"
            >
              <path
                d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                fill="rgba(165,165,165,1)"
              />
            </svg>
            Mon espace
          </Col>
          {membersRoutes
            .filter(
              route =>
                route.menus.includes('banner') &&
                (route.roles.length === 0 ||
                  route.roles.filter(x => pluck(user.roles, 'id').includes(x))
                    .length > 0) &&
                (route.exceptRoles.length === 0 ||
                  route.exceptRoles.filter(x =>
                    pluck(user.roles, 'id').includes(x)
                  ).length === 0)
            )
            .map((route, index) => (
              <Col
                key={index}
                className={classNames(
                  'membre-acces-tuile cursor-pointer text-center p-3',
                  {
                    active: usePathname() === route.path
                  }
                )}
                onClick={() => {
                  navigate(route.path);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="36"
                  height="36"
                >
                  <path d={route.svg} fill="rgba(165,165,165,1)" />
                </svg>
                {route.label}
                {route.id === 'tredRessources' && waitings.length > 0 && (
                  <span
                    className="badge badge-subtle-warning px-2 me-2"
                    style={{ color: '#b82c46', backgroundColor: '#fbdbe1' }}
                  >
                    {waitings.length}
                  </span>
                )}
                {route.id === 'ads' && waitingsAds.length > 0 && (
                  <span
                    className="badge badge-subtle-warning px-2 me-2 ms-1"
                    style={{ color: '#b82c46', backgroundColor: '#fbdbe1' }}
                  >
                    {waitingsAds.length}
                  </span>
                )}
              </Col>
            ))}
        </Row>
      </Section>
    </>
  );
};

MemberBanner.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  trigger: PropTypes.any,
  setTrigger: PropTypes.func
};

export default MemberBanner;
