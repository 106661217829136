import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import ReactEcharts from 'echarts-for-react';
import * as Moment from 'moment/moment';
import { getColor, rgbaColor } from '../../../../../../helpers/utils';

const getOption = data => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    borderWidth: 1,
    transitionDuration: 0,
    textStyle: {
      fontWeight: 500,
      fontSize: 12,
      color: getColor('dark')
    },
    formatter: params =>
      `${params[0].axisValue} - ${params[0].value} ${
        parseInt(params[0].value) > 1 ? 'réponses' : 'réponse'
      }`
  },
  xAxis: {
    type: 'category',
    data: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29
    ]
      .reverse()
      .map(d => {
        let date = new Date();
        date.setDate(date.getDate() - d);
        return Moment(date).local().format('DD/MM');
      }),
    splitLine: {
      show: true,
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      },
      interval: 0
    },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      }
    },
    axisTick: {
      show: true,
      length: 10,
      lineStyle: {
        color: rgbaColor('#fff', 0.1)
      }
    },
    axisLabel: {
      color: getColor('400'),
      fontWeight: 600,
      fontSize: 12,
      margin: 15,
      interval: window.innerWidth < 768 ? 'auto' : 0,
      formatter: value => value.substring(0, value.length - 3)
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      smooth: true,
      data: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29
      ]
        .reverse()
        .map(d => {
          let total = 0;
          let date = new Date();
          date.setDate(date.getDate() - d);
          data.map(recipient => {
            if (recipient.pivot && recipient.pivot.survey_completed_on) {
              if (
                Moment(date).local().format('DD/MM') ===
                Moment(recipient.pivot.survey_completed_on)
                  .local()
                  .format('DD/MM')
              ) {
                total = total + 1;
              }
            }
          });
          return total;
        }),
      type: 'bar', // line
      symbol: 'emptyCircle',
      itemStyle: {
        color: getColor('white')
      },
      lineStyle: {
        color: rgbaColor(getColor('white'), 0.8)
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor('#fff', 0.5)
            },
            {
              offset: 1,
              color: rgbaColor('#fff', 0)
            }
          ]
        }
      },
      emphasis: {
        lineStyle: {
          width: 2
        }
      }
    }
  ],
  grid: { right: 0, left: 0, bottom: '15%', top: 0 }
});

const LineAnswers = ({ data }) => {
  return (
    <Card className="rounded-3 overflow-hidden h-100 shadow-none">
      <Card.Body
        className="bg-line-chart-gradient"
        as={Flex}
        justifyContent="between"
        direction="column"
      >
        <Row className="align-items-center g-0">
          <Col className="light">
            <h5 className="text-white mb-4">
              Réponses au formulaire de participation
              <br />
              <small>
                <small>30 derniers jours</small>
              </small>
            </h5>
          </Col>
        </Row>
        <ReactEcharts style={{ height: '200px' }} option={getOption(data)} />
      </Card.Body>
    </Card>
  );
};

LineAnswers.propTypes = {
  data: PropTypes.array
};

export default LineAnswers;
