import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: ''
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    // méthodes qui vont changer le state de search
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeSearch } = companiesSlice.actions;

export default companiesSlice.reducer;
