import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'contract-metas';

export async function PostContractMeta(formData) {
  return modelService.PostModel(model, formData);
}

export async function CountInvoicesToProcess() {
  return modelService.IndexModel(`${model}/count-invoices-to-process`);
}
