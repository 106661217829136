import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { IndexAdmin } from '../../../../../../services/CompanyService';
import { toast } from 'react-toastify';
import { Button, Col, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

const ExportStatsResources = ({
  setExportUrl,
  exportStats,
  exportIsLoading
}) => {
  const [trigger, setTrigger] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    companies: [],
    allCompanies: 1,
    start: '',
    end: ''
  });

  useEffect(() => {
    setForm({
      companies: [],
      allCompanies: 1,
      start: '',
      end: ''
    });
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), 0, 1);

    let start = new Date(firstDay.toISOString().split('T')[0]);
    start.setDate(start.getDate() + 1);

    setForm({
      ...form,
      start: start,
      end: new Date(date.toISOString().split('T')[0])
    });

    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    //fetchCompanies();
  }, [trigger]);

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Dates de début et de fin'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {form.start && form.end && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setForm({
              ...form,
              start: '',
              end: ''
            });
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  return (
    <>
      <Dropdown.Item
        eventKey="2"
        onClick={() => {
          //setExportUrl('');
          //exportScoring();
          setShow(true);
        }}
        disabled={exportIsLoading === true}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
            fill="rgba(75,74,77,1)"
          />
        </svg>
        Export des vues
      </Dropdown.Item>

      <Offcanvas
        onShow={() => {
          setTrigger(Date.now());
        }}
        show={show}
        onHide={() => {
          setShow(false);
          setTrigger(Date.now());
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Export scoring</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="gx-2 gy-3 mb-3">
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Dates :</Form.Label>
                <DatePicker
                  onChange={dates => {
                    const [start, end] = dates;
                    setForm({
                      ...form,
                      start: start,
                      end: end
                    });
                  }}
                  startDate={form.start}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={form.end}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  customInput={<DatePickerInput />}
                  locale="fr"
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3 d-none">
              <label>
                <input
                  type="checkbox"
                  id="allCompanies"
                  name="allCompanies"
                  value="1"
                  className="me-1"
                  checked={form.allCompanies === 1 || form.allCompanies === '1'}
                  onChange={e => {
                    setForm({
                      ...form,
                      allCompanies: e.target.checked ? 1 : 0
                    });
                  }}
                />
                Toutes les entreprises ?
              </label>
            </Col>
            {form.allCompanies === 0 && (
              <Col md="12">
                <Form.Group controlId="roles">
                  <Form.Label>Entreprise(s) :</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={companies.map(company => {
                      return {
                        value: company.id,
                        label: company.name
                      };
                    })}
                    placeholder="Choisir une ou plusieurs entreprises..."
                    isMulti
                    name="companies"
                    classNamePrefix="react-select"
                    value={form.companies}
                    onChange={value => {
                      setForm({
                        ...form,
                        companies: value
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShow(false);
            }}
          >
            Annuler
          </Button>
          {form.start && form.end && (
            <Button
              size="sm"
              variant="primary"
              type="submit"
              onClick={e => {
                setShow(false);
                exportStats(form);
              }}
              className="me-2 admin-primary"
            >
              Exporter
            </Button>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ExportStatsResources;

ExportStatsResources.propTypes = {
  setExportUrl: PropTypes.func.isRequired,
  exportStats: PropTypes.func.isRequired,
  exportIsLoading: PropTypes.bool.isRequired
};
