import React from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import FooterStandard from '../../commons/FooterStandard';

const MentionsLegales = () => {
  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{ backgroundImage: `url("${bgActualites}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>Mentions légales</h2>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-flex align-items-end p-1 mt-3">
        <Row className="mt-lg-5 mb-5">
          <Col xs={12}>
            <p className="smaller-p text-justify ">
              <b>Identification</b>
              <br />
              Directeur de la publication : Jean-Christophe EDY
              <br />
              Email : jce@tredunion.fr
              <br />
              Adresse : 4 rue de Lugano - 68145 Horbourg-Wihr
              <br />
              Tél. : 03 89 21 09 82
              <br />
              Forme juridique : SAS
              <br />
              Raison sociale : TRED UNION
              <br />
              SIRET : 512 555 236 (2009 B 308)
              <br />
              Hébergement : <a href="https://hostay.fr">Hostay</a>, une marque
              de l'agence web <a href="https://qwenty.fr">Qwenty SAS</a>
              , dont le siège social est situé : 4 Quai Koch 67100 Strasbourg –
              France
              <br />
              Crédits photos : Shutterstock, Fotolia.
              <br />
              L’utilisateur du site Internet «www.tredunion.fr» reconnaît
              disposer de la compétence et des moyens nécessaires pour accéder
              et utiliser ce site. Il reconnaît également avoir pris
              connaissance de la présente notice légale et s’engage à la
              respecte.
            </p>
            <p className="smaller-p text-justify ">
              <b>Utilisation des Cookies</b>
              <br />
              L’utilisateur est informé que lors de ses visites sur le site, un
              cookie peut s’installer automatiquement sur son logiciel de
              navigation. Un cookie est un élément qui ne permet pas
              d’identifier l’utilisateur mais sert à enregistrer des
              informations relatives à la navigation de celui-ci sur le site
              Internet. L’utilisateur pourra désactiver ce cookie par
              l’intermédiaire des paramètres figurant au sein de son logiciel de
              navigation.
            </p>
            <p className="smaller-p text-justify ">
              <b>Politique de confidentialité (RGPD)</b>
              <br />
              Nous nous engageons à vous offrir un droit d’opposition, de
              retrait et d’accès quant à vos renseignements personnels. Le droit
              d’opposition s’entend comme étant la possiblité offerte aux
              internautes de refuser que leurs renseignements personnels soient
              utilisées à certaines fins mentionnées lors de la collecte. Le
              droit de retrait s’entend comme étant la possiblité offerte aux
              internautes de demander à ce que leurs renseignements personnels
              ne figurent plus, par exemple, dans une liste de diffusion. Pour
              pouvoir exercer ces droits, vous pouvez : Courriel :
              info@tredunion.fr.
            </p>
            <p className="smaller-p text-justify ">
              <b>Propriété intellectuelle</b>
              <br />
              La structure générale du site « www.tredunion.fr » et l'ensemble
              des contenus diffusés sur ce site (images, articles,
              photographies, logos, marques, textes...), y incluant sa
              newsletter, sont protégés par la législation en vigueur en France
              en matière de propriété intellectuelle, et notamment le droit
              d'auteur, les droits voisins, le droit des marques, le droit à
              l'image, ... et par la législation internationale. Les présentes
              Conditions Générales d'Utilisation n'entraînent le transfert
              d'aucun droit de propriété intellectuelle à l'utilisateur, y
              compris dans le cadre du service d'édition électronique de «
              www.tredunion.fr » . Tous les droits de reproduction sont
              réservés, y compris pour les textes, les documents
              téléchargeables, les représentations iconographiques et
              photographiques. A ce titre, à défaut d'autorisation expresse de
              TRED UNION , il est strictement interdit d'exploiter les contenus
              du site « « www.tredunion.fr » » ou de l'édition électronique de «
              www.tredunion.fr » et notamment de les reproduire, représenter,
              modifier ou adapter en tout ou partie. L’utilisateur du service
              d’édition électronique de « www.tredunion.fr » s’interdit
              notamment toute reproduction faite à des fins de revue de presse
              et/ ou de panorama de presse, sauf accord exprès autorisant une
              telle utilisation. En conséquence, à l'exception de la seule
              reproduction nécessaire à la consultation ou au téléchargement de
              l'édition électronique de « www.tredunion.fr » par l'utilisateur,
              et ce à des fins strictement personnelles, la reproduction et la
              représentation, intégrale ou partielle, des pages ou de tout autre
              contenu du site « www.tredunion.fr », par quelque procédé ou
              support que ce soit, constituent une contrefaçon. Les liens
              hypertextes mis en place dans le cadre du site Internet en
              direction d’autres ressources présentes sur le réseau de
              l’Internet, et notamment vers ses partenaires ont fait l’objet
              d’une autorisation préalable expresse et écrite. Les utilisateurs
              visiteurs du site Internet ne peuvent mettre en place un hyperlien
              en direction de ces sites sans l’autorisation expresse et
              préalable de l’exploitant du site Internet. Le présent site est la
              propriété de TRED UNION, établissement situé 4 rue de Lugano -
              68145 Horbourg-Wihr.
            </p>
            <p className="smaller-p text-justify ">
              <b>Responsabilité</b>
              <br />
              Le site « www.tredunion.fr » est un site d’informations lié à son
              métier et revêt un caractère strictement informatif. Il n'emporte
              en soi aucun engagement juridique ni accord contractuel de la part
              de TRED UNION qui se réserve par ailleurs de modifier, à sa
              convenance, les caractéristiques des produits, informations et
              services présentés. L'accès aux produits, informations ou services
              présentés sur le site « www.tredunion.fr » peut faire l'objet de
              restrictions à l'égard de certaines personnes, sociétés ou dans
              certains pays. TRED UNION met tout en œuvre pour offrir aux
              internautes des outils disponibles et des informations fiables.
              Toutefois, TRED UNION ne saurait garantir aux internautes
              l'exactitude, la complétude, l'actualisation en temps réel des
              informations fournies et la disponibilité de son site internet ou
              de sa Newsletter. Il appartient à chaque utilisateur de prendre
              toutes les mesures appropriées de façon à protéger ses propres
              données et/ou logiciels de la contamination d'éventuels virus
              circulant sur internet. En conséquence, la responsabilité de TRED
              UNION ne pourra en aucun cas être engagée du fait de l'utilisation
              des informations fournies et/ou des outils mis à disposition sur
              ce site. Enfin, la responsabilité de TRED UNION ne saurait être
              engagée en cas de difficulté liée à la connexion, à l'inscription,
              ces services dépendant entièrement du fournisseur d'accès à
              Internet de l'utilisateur et non de TRED UNION.
              <br />
              La responsabilité de TRED UNION ne saurait être engagée dans les
              cas suivants :
              <br />- Difficultés de fonctionnement ou interruption de ses
              services indépendamment de la volonté de TRED UNION ; <br />-
              Interruptions momentanées des services ou du site «
              www.tredunion.fr » nécessaires à leur évolution, maintenance ou
              mise à jour ; <br />- Défaillances ou dysfonctionnement du réseau
              internet dans la transmission de données, messages ou documents.
            </p>
            <p className="smaller-p text-justify ">
              <b>Loi applicable</b>
              <br />
              Les présentes Conditions Générales d'Utilisation sont soumises au
              droit français. En cas de litige relatif à l'interprétation,
              l'exécution ou la résiliation des présentes, les Tribunaux de
              Colmar seront seuls compétents.
            </p>
          </Col>
          <Col xs={12}>
            <p className="smaller-p text-justify ">
              <b>
                Pour faire valoir votre droit de suppression des données
                personnelles sur le site Tred Union, vous pouvez compléter le
                formulaire suivant en cliquant sur le lien :{' '}
                <a href="/#contact">Contact</a>
              </b>
            </p>
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default MentionsLegales;
