import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportELearningRequestsHistory = ({ objects }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    objects.map(object => {
      let cleanedCode = {
        created_at: object.created_at,
        company: object.company,
        firstname: object.firstname,
        lastname: object.lastname,
        occupation: object.occupation,
        email: object.email,
        phone: object.phone,
        courses: object.courses,
        initiator: object.initiator,
        historyBy: object.historyBy,
        historyWhen: object.historyWhen
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [objects]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Demandes d'accès Elearning">
              <ExcelColumn label="Date" value="created_at" />
              <ExcelColumn label="Société" value="company" />
              <ExcelColumn label="Prénom" value="firstname" />
              <ExcelColumn label="Nom" value="lastname" />
              <ExcelColumn label="Fonction" value="occupation" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Téléphone" value="phone" />
              <ExcelColumn label="Formation(s)" value="courses" />
              <ExcelColumn label="Rédacteur" value="initiator" />
              <ExcelColumn label="Gestionnaire" value="historyBy" />
              <ExcelColumn label="Gérée le" value="historyWhen" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportELearningRequestsHistory.propTypes = {
  objects: PropTypes.array
};

export default ExportELearningRequestsHistory;
