import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Offcanvas, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  IndexTypeAdminManage,
  PostTypesManage
} from '../../../../../../services/Event/TypeService';
import FormAlert from '../../../../commons/FormAlert';
import PropTypes from 'prop-types';

const ManageTypes = ({
  showManageTypes,
  setShowManageTypes,
  setTypesSelect,
  setSelectedType,
  parentFormData,
  setParentFormData
}) => {
  const handleClose = () => setShowManageTypes(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    name: ''
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = await PostTypesManage(formData);
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    if (response.success === true) {
      setIsLoading(false);
      setSuccessForm(true);

      const responseTypes = await IndexTypeAdminManage();
      if (responseTypes.success === true) {
        let typesArray = [];
        responseTypes.data.map(types => {
          typesArray.push({ value: types.id, label: types.name });
        });
        setTypesSelect(typesArray);
        setParentFormData({
          ...parentFormData,
          event_type_id: response.data.id,
          is_public: formData.is_admin === 1 ? 0 : parentFormData.is_public
        });
        setFormData({
          name: ''
        });
        setTimeout(function () {
          setSelectedType({
            label: response.data.name,
            value: parseInt(response.data.id)
          });
        }, 300);
        handleClose(setFormData);
        toast(`Type d'événement ajouté`);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    }
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageTypes}
        onHide={handleClose}
        // onClose={}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Ajouter une typologie</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          {successForm && !errorForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>Choisir un nom pour la typologie :</Form.Label>
              <Form.Control
                placeholder="Nom de la typologie"
                size="sm"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleFieldChange}
                disabled={!setFormData}
              />
              <div className="d-block mt-4"></div>

              <Button
                variant="primary"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
                disabled={isLoading}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    position: 'relative',
                    bottom: '1px',
                    left: '-1px',
                    paddingRight: '3px'
                  }}
                >
                  <path
                    fill="#FFFFFF"
                    d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                  />
                </svg>
                Enregistrer
              </Button>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
ManageTypes.propTypes = {
  showManageTypes: PropTypes.bool,
  setShowManageTypes: PropTypes.func,
  setTypesSelect: PropTypes.any,
  setSelectedType: PropTypes.any,
  parentFormData: PropTypes.object,
  setParentFormData: PropTypes.func
};

export default ManageTypes;
