import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import FileUploader from '../../../commons/fileUploader';
import FormAlert from '../../../commons/FormAlert';
import {
  PostPartner,
  PutPartner,
  ShowPartner
} from '../../../../../services/PartnerService';
import IconAlert from '../../../../common/IconAlert';
import classNames from 'classnames';
import {
  DeleteUser,
  PostUser,
  PutUser
} from '../../../../../services/UserService';
import Select from 'react-select';
import { IndexPartnerRole } from '../../../../../services/PartnerRoleService';

const ManagePartners = () => {
  const { id } = useParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [object, setObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorFormUser, setErrorFormUser] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [selectedUserIndex, setSelectedUserIndex] = useState({});
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    contact_website: '',
    logo_url: ''
  });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowPartner(id);
      if (responseObject.success === true) {
        setObject(responseObject.data);
        setFormData({
          name: responseObject.data.name,
          contact_website: responseObject.data.contact_website,
          logo_url: responseObject.data.logo_url
        });
        let tmpUsers = responseObject.data.users;
        tmpUsers.map(u => {
          u.roles = [];
          u.partner_roles.map(r => {
            u.roles.push({
              value: r.id,
              label: r.title
            });
          });
        });
        setUsers(tmpUsers);
        console.log(tmpUsers);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchRoles = async () => {
      const responseObjects = await IndexPartnerRole();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.id,
            label: r.title
          });
        });
        setRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchRoles();
    if (id) {
      fetchObject();
    }
  }, [trigger]);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFieldUserChange = (e, indexUser) => {
    let tmpUsers = [...users];
    tmpUsers[indexUser][e.target.name] = e.target.value;
    setUsers(tmpUsers);
  };

  const addUser = () => {
    const list = [...users];
    list.push({
      login: '',
      email: '',
      lastname: '',
      firstname: '',
      partner_id: id,
      phone: '',
      roles: [],
      partner_login_allowed: 0
    });
    setUsers(list);
  };

  const removeUser = async () => {
    if (users[selectedUserIndex].id) {
      let response = await DeleteUser(users[selectedUserIndex].id);
      if (response.success === true) {
        setTrigger(Date.now());
        toast('Compte supprimé');
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else {
      const list = [...users];
      list.splice(selectedUserIndex, 1);
      setUsers(list);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setErrorFormUser(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutPartner(id, formData);
      if (users.length > 0) {
        users.map(user => {
          let tmpRoles = user.roles;
          tmpRoles = tmpRoles?.map(role => role.value);
          user.partner_roles = tmpRoles;
          if (!user.id) {
            postUserForm(user);
          } else {
            putUserForm(user);
          }
        });
      }
    } else {
      response = await PostPartner(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true && !response.validator) {
      const successMessage = `Partenaire ${response.data.name} enregistré.`;
      //toast(successMessage);
      setSuccessForm(successMessage);
      //setTrigger(Date.now());
      setIsLoading(false);
      setTimeout(function () {
        window.location.href = `/administration/partenaires/${response.data.id}/modifier`;
      }, 2500);
    }
  };

  const postUserForm = async user => {
    let responseUser = await PostUser(user, 'admin.createformpartner');
    setErrorFormUser(responseUser.validator);
  };

  const putUserForm = async user => {
    let responseUser = await PutUser('admin.updatefrompartner', user.id, user);
    setErrorFormUser(responseUser.validator);
  };

  return (
    <>
      {((id && object.id !== null && object.id !== undefined) || !id) && (
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                        {' '}
                        {object.id !== null && object.id !== undefined
                          ? `Modifier le partenaire ${object.name}`
                          : 'Ajouter un partenaire'}
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/partenaires';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        {!id ? 'Annuler' : 'Retour'}
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '4px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Enregistrer
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              {errorFormUser && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorFormUser} />
                </div>
              )}
              {successForm && !errorFormUser && !errorForm && (
                <div className="mt-3">
                  <FormAlert
                    className="mt-3"
                    variant="success"
                    data={successForm}
                  />
                </div>
              )}
            </Col>
            <Col xs={12}>
              <Card className="mb-3 pb-3">
                <Card.Body className="bg-light">
                  <Row className="gx-2 gy-3">
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Logo :
                        </Form.Label>
                        <FileUploader
                          pathToUpload="partners/logos"
                          formData={formData}
                          setFormData={setFormData}
                          format="img"
                          databaseField="logo_url"
                          cropperEnabled={false}
                          currentImage={object.logo_url}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="name">
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M3 19V5.7a1 1 0 0 1 .658-.94l9.671-3.516a.5.5 0 0 1 .671.47v4.953l6.316 2.105a1 1 0 0 1 .684.949V19h2v2H1v-2h2zm2 0h7V3.855L5 6.401V19zm14 0v-8.558l-5-1.667V19h5z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Nom :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Nom de la société (exemple : ALTRANS)"
                          value={formData.name || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="contact_website">
                        <Form.Label>Site internet :</Form.Label>
                        <Form.Control
                          type="text"
                          name="contact_website"
                          placeholder="Site internet du partenaire"
                          value={formData.contact_website || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col md="12" className="pb-2 pt-3">
                      <div className="border-dashed-bottom"></div>
                    </Col>

                    {!id ? (
                      <Col md={12}>
                        <IconAlert variant="info" className="mb-0">
                          <p className="mb-0">
                            Enregistrez le partenaire pour pouvoir y ajouter des
                            utilisateurs.
                          </p>
                        </IconAlert>
                      </Col>
                    ) : (
                      <Col md={12}>
                        <h5 className="admin-title companyRightManagePeople">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                              fill="rgba(75,74,77,1)"
                            />
                          </svg>
                          Comptes utilisateurs :
                        </h5>
                        <Row className="mt-3">
                          {users.length > 0 && (
                            <>
                              <Offcanvas
                                show={showDelete}
                                onHide={() => {
                                  setShowDelete(false);
                                }}
                                placement={'end'}
                              >
                                <Offcanvas.Header closeButton>
                                  <Offcanvas.Title>
                                    La suppression du compte est définitive.
                                    <br />
                                    Voulez-vous continuer ?
                                  </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                  <Button
                                    size="sm"
                                    variant="falcon-danger"
                                    className="me-2"
                                    onClick={() => {
                                      setShowDelete(false);
                                      removeUser(selectedUserIndex);
                                    }}
                                  >
                                    Oui, supprimer
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="falcon-default"
                                    className="me-2"
                                    onClick={() => {
                                      setShowDelete(false);
                                    }}
                                  >
                                    Annuler
                                  </Button>
                                </Offcanvas.Body>
                              </Offcanvas>
                              {users.map((user, indexUser) => (
                                <Col xs={12} key={indexUser} className="mb-0">
                                  <Row className="align-items-end">
                                    <Col xs={2} className="pe-0">
                                      <Form.Group controlId="formGridLogin">
                                        <Form.Label>Login</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Login"
                                          name="login"
                                          value={users[indexUser].login || ''}
                                          onChange={e => {
                                            handleFieldUserChange(e, indexUser);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col xs={3} className="px-0">
                                      <Form.Group controlId="formGridEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                          type="email"
                                          placeholder="Email"
                                          name="email"
                                          value={users[indexUser].email || ''}
                                          onChange={e => {
                                            handleFieldUserChange(e, indexUser);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col xs={3} className="px-0">
                                      <Form.Group controlId="formGridLastname">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Nom"
                                          name="lastname"
                                          value={
                                            users[indexUser].lastname || ''
                                          }
                                          onChange={e => {
                                            handleFieldUserChange(e, indexUser);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3} className="px-0">
                                      >
                                      <Form.Group controlId="formGridFirstname">
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Prénom"
                                          name="firstname"
                                          value={
                                            users[indexUser].firstname || ''
                                          }
                                          onChange={e => {
                                            handleFieldUserChange(e, indexUser);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={1}>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        className="me-0 mb-1"
                                        type="button"
                                        onClick={() => {
                                          setSelectedUserIndex(indexUser);
                                          setShowDelete(true);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="14"
                                          height="14"
                                          style={{
                                            marginBottom: '2px'
                                          }}
                                        >
                                          <path
                                            d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                            fill="rgba(255,255,255,1)"
                                          />
                                        </svg>
                                      </Button>
                                    </Col>
                                    <Col xs={4} className="mt-3">
                                      <Form.Group controlId="formGridLastname">
                                        <Form.Label>Téléphone</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Téléphone"
                                          name="phone"
                                          value={users[indexUser].phone || ''}
                                          onChange={e => {
                                            handleFieldUserChange(e, indexUser);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={4} className="mt-3">
                                      <Form.Group controlId="roles">
                                        <Form.Label>Rôles :</Form.Label>
                                        <Select
                                          closeMenuOnSelect={false}
                                          options={roles}
                                          placeholder="Choisir..."
                                          isMulti
                                          name="roles"
                                          classNamePrefix="react-select"
                                          value={users[indexUser].roles || []}
                                          onChange={value => {
                                            let tmpUsers = [...users];
                                            tmpUsers[indexUser].roles = value;
                                            setUsers(tmpUsers);
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={4} className="pt-3">
                                      <label>
                                        <input
                                          type="checkbox"
                                          id="calendar_access"
                                          name="partner_login_allowed"
                                          value="1"
                                          className="me-1"
                                          checked={
                                            users[indexUser]
                                              .partner_login_allowed === 1 ||
                                            users[indexUser]
                                              .partner_login_allowed === '1'
                                          }
                                          onChange={e => {
                                            let tmpUsers = [...users];
                                            tmpUsers[
                                              indexUser
                                            ].partner_login_allowed = e.target
                                              .checked
                                              ? 1
                                              : 0;
                                            setUsers(tmpUsers);
                                          }}
                                        />
                                        Peut se connecter à l'espace partenaire
                                        ?{' '}
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="overlay-trigger-example">
                                              En cochant cette case, la personne
                                              va recevoir une invitation à se
                                              connecter à l'espace partenaire.
                                              En décochant cette case, la
                                              personne ne pourra plus se
                                              connecter à l'espace partenaire.
                                            </Tooltip>
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="18"
                                            height="18"
                                            className="ms-1 mb-1"
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path
                                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                                              fill="rgba(100,99,99,1)"
                                            />
                                          </svg>
                                        </OverlayTrigger>
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" className="pb-3 pt-4">
                                      <div className="border-dashed-bottom"></div>
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                            </>
                          )}
                          <Col
                            xs={12}
                            className={classNames('', {
                              'mt-3': users.length > 0
                            })}
                          >
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                addUser();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '3px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                              </svg>
                              Ajouter un {users.length > 0 ? 'autre' : ''}{' '}
                              compte
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/partenaires';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        {!id ? 'Annuler' : 'Retour'}
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '4px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Enregistrer
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ManagePartners;
