import React, { useState } from 'react';
import Section from '../../../common/Section';
import { Button, Card, Col, Row } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import { useNavigate } from 'react-router';
import * as Moment from 'moment';
import 'moment/locale/fr';
import tredPlaceholder from 'assets/img/tredunion/tred-placeholder.jpg';

const Actualites = ({ posts, showPagination }) => {
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };
  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-end p-1"
        id="actualites"
        style={{ backgroundImage: `url("${bgActualites}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>ACTUALITÉS</h2>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-flex align-items-end p-1 mt-5">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid actualites"
          columnClassName="masonry-grid_column"
        >
          {posts
            .slice(currentPage * 12 - 12, currentPage * 12)
            .map((post, index) => (
              <div key={index}>
                <Card>
                  {post.medias !== null &&
                    post.medias.length > 0 &&
                    post.medias.filter(media =>
                      ['jpg', 'jpeg', 'png', 'gif'].includes(
                        media.file_url.split('.').pop().toLowerCase()
                      )
                    ).length > 0 && (
                      <Card.Img
                        onClick={() => {
                          window.open(
                            `/actualites/${post.id}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }}
                        className="cursor-pointer"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = tredPlaceholder;
                        }}
                        src={
                          post.medias.filter(media =>
                            ['jpg', 'jpeg', 'png', 'gif'].includes(
                              media.file_url.split('.').pop().toLowerCase()
                            )
                          )[0].file_url
                        }
                        variant="top"
                      />
                    )}
                  <Card.Body>
                    <Card.Title as="h5">
                      <span className="post-date d-block pb-2">
                        {Moment(post.created_at)
                          .locale('fr')
                          .format('DD MMMM YYYY')}
                      </span>
                      {post.title}
                    </Card.Title>
                    <Card.Text className="smaller-p">{post.content}</Card.Text>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        window.open(
                          `/actualites/${post.id}`,
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                    >
                      Lire
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            ))}
        </Masonry>
        {showPagination && posts.length > 0 && (
          <Row className="pb-5 pt-3">
            <Col xs={12} className="text-center">
              {currentPage > 1 && (
                <Button
                  variant="primary"
                  className="mx-2 my-2"
                  size="md"
                  onClick={() => {
                    document.getElementById('actualites').scrollIntoView();
                    setTimeout(function () {
                      setCurrentPage(currentPage - 1);
                    }, 700);
                  }}
                >
                  &lt; Page précédente
                </Button>
              )}
              <Button
                variant="primary"
                className="mx-2 my-2"
                size="md"
                onClick={() => {
                  document.getElementById('actualites').scrollIntoView();
                  setTimeout(function () {
                    setCurrentPage(currentPage + 1);
                  }, 800);
                }}
              >
                Page suivante &gt;
              </Button>{' '}
            </Col>
          </Row>
        )}
        {!showPagination && (
          <Row className="pb-5 pt-3">
            <Col className="text-center">
              <Button
                variant="primary"
                size="lg"
                className="big"
                onClick={() => {
                  navigate(`/actualites`);
                }}
              >
                Afficher toutes les actualités
              </Button>{' '}
            </Col>
          </Row>
        )}
      </Section>
    </>
  );
};

Actualites.propTypes = {
  posts: PropTypes.array,
  limit: PropTypes.number,
  showPagination: PropTypes.bool
};

export default Actualites;
