import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportPartners = ({ partners }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    partners.map(partner => {
      partner = partner.originalObject;
      if (!partner?.users || (partner?.users && partner?.users.length === 0)) {
        tmpCleanData.push({
          partner: partner?.name || ''
        });
      } else if (partner.users && partner.users.length > 0) {
        partner.users.map(user => {
          let cleanedCode = {
            partner: partner.name || '',
            lastname: user.lastname || '',
            firstname: user.firstname || '',
            roles: user.partner_roles?.map(r => r.title).join(', ') || '',
            email: user.email || '',
            phone: user.phone || '',
            login: user.login || '',
            partner_login_allowed:
              user.partner_login_allowed === 1 ? 'Oui' : 'Non'
          };
          tmpCleanData.push(cleanedCode);
        });
      }
    });
    setCleanData(tmpCleanData);
  }, [partners]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Partners">
              <ExcelColumn label="Partenaire" value="partner" />
              <ExcelColumn label="Nom" value="lastname" />
              <ExcelColumn label="Prénom" value="firstname" />
              <ExcelColumn label="Rôles" value="roles" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Téléphone" value="phone" />
              <ExcelColumn label="Login" value="login" />
              <ExcelColumn
                label="Autorisé espace partenaire ?"
                value="partner_login_allowed"
              />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportPartners.propTypes = {
  partners: PropTypes.array
};

export default ExportPartners;
