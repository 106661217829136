import React, { useEffect, useState } from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import { toast } from 'react-toastify';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Section from '../../../common/Section';
import bgFiche from 'assets/img/tredunion/bg_fiche.jpeg';
import { useParams } from 'react-router';
import { ShowPost } from '../../../../services/PostService';
import downloadPdf from '../../../../assets/img/tredunion/download-pdf.png';
import { useNavigate } from 'react-router';
import FooterStandard from '../../commons/FooterStandard';

const PostShow = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [trigger] = useState(Date.now());
  const [post, setPost] = useState({});

  useEffect(() => {
    const fetchPost = async () => {
      const responsePost = await ShowPost(id);
      if (responsePost.success === true) {
        setPost(responsePost.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchPost();
  }, [trigger]);

  return (
    <>
      <NavbarStandard />
      {!post ? (
        <Section bg="light" className="d-flex align-items-end p-1">
          <Row className="justify-content-center mt-7">
            <Col className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      ) : (
        <>
          <Section
            bg="light"
            className="d-flex align-items-end p-1"
            id="banner-post"
            style={{ backgroundImage: `url("${bgFiche}")`, minHeight: '340px' }}
          >
            <Row>
              <Col xs={12}>
                <h1>{post.title}</h1>
              </Col>
            </Row>
          </Section>
          <Section bg="light" className="d-flex p-1 mt-3 mb-5">
            <Row>
              <Col xs={12}>
                <p>{post.content}</p>
              </Col>
              {post.medias !== undefined &&
                post.medias
                  .filter(media =>
                    ['jpg', 'jpeg', 'png', 'gif'].includes(
                      media.file_url.split('.').pop().toLowerCase()
                    )
                  )
                  .map((media, index) => (
                    <Col xs={12} key={index} className="mb-4">
                      <img className="mw-100" src={media.file_url} />
                    </Col>
                  ))}
              {post.medias !== undefined &&
                post.medias
                  .filter(media =>
                    ['pdf'].includes(media.file_url.split('.').pop())
                  )
                  .map((media, index) => (
                    <Col className="text-end" xs={12} key={index}>
                      <p className="text-end">
                        <a
                          href={media.file_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="d-inline-block link-brochure pr-2 ">
                            Téléchargez
                            <br />
                            le PDF
                          </span>
                          <img className="d-inline-block" src={downloadPdf} />
                        </a>
                      </p>
                    </Col>
                  ))}
            </Row>
            <Row className="pt-5">
              <Col className="text-center">
                <Button
                  variant="primary"
                  size="lg"
                  className="big"
                  onClick={() => {
                    navigate(`/actualites`);
                  }}
                >
                  Afficher toutes les actualités
                </Button>{' '}
              </Col>
            </Row>
          </Section>
        </>
      )}
      <FooterStandard />
    </>
  );
};

export default PostShow;
