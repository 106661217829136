import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { IndexRepresentativeSectionType } from '../../../../../services/RepresentativeSectionTypeService';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const ManageRepresentative = ({
  formData,
  setFormData,
  usersSelect,
  setFormDataEdited
}) => {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    const fetchTypes = async () => {
      const response = await IndexRepresentativeSectionType();
      if (response.success === true) {
        setTypes(response.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchTypes();
  }, [formData.company_representative_sections]);
  return (
    <>
      {formData.company_representative_sections
        .sort((a, b) => a.order - b.order)
        .map((section, index) => (
          <div key={index} className="d-block mb-4 pe-3">
            <Card.Header
              as="h5"
              className="admin-title companyRightManagePeople p-0 mb-2"
            >
              <small>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginRight: '4px', marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                    fill="rgba(75,74,77,1)"
                  />
                </svg>
                <Form.Select
                  aria-label="Type de section"
                  style={{
                    width: '60%',
                    display: 'inline-block',
                    padding: '0 0 0 3px',
                    border: '1px solid #ced4da',
                    fontWeight: '500'
                  }}
                  name={`company_representative_sections[${index}][type]`}
                  value={formData.company_representative_sections[index].type}
                  onChange={e => {
                    setFormDataEdited(true);
                    setFormData({
                      ...formData,
                      company_representative_sections:
                        formData.company_representative_sections.map(
                          (section, i) =>
                            i === index
                              ? { ...section, type: e.target.value }
                              : section
                        )
                    });
                  }}
                >
                  {types.map((type, i) => (
                    <option key={i} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
                {index > 0 && (
                  <span
                    style={{ cursor: 'pointer', marginLeft: '4px' }}
                    onClick={() => {
                      setFormDataEdited(true);
                      setFormData({
                        ...formData,
                        company_representative_sections:
                          formData.company_representative_sections.map(
                            (section, i) =>
                              i === index
                                ? { ...section, order: index - 1 }
                                : i === index - 1
                                ? { ...section, order: index }
                                : section
                          )
                      });
                    }}
                  >
                    ↑
                  </span>
                )}
                {index <
                  formData.company_representative_sections.length - 1 && (
                  <span
                    style={{ cursor: 'pointer', marginLeft: '4px' }}
                    onClick={() => {
                      setFormDataEdited(true);
                      setFormData({
                        ...formData,
                        company_representative_sections:
                          formData.company_representative_sections.map(
                            (section, i) =>
                              i === index
                                ? { ...section, order: index + 1 }
                                : i === index + 1
                                ? { ...section, order: index }
                                : section
                          )
                      });
                    }}
                  >
                    ↓
                  </span>
                )}
                <small className="float-end pt-2">
                  <span
                    className="text-danger cursor-pointer"
                    onClick={() => {
                      setFormDataEdited(true);
                      setFormData({
                        ...formData,
                        company_representative_sections:
                          formData.company_representative_sections.filter(
                            (section, i) => i !== index
                          )
                      });
                    }}
                  >
                    Retirer
                  </span>
                </small>
              </small>
            </Card.Header>
            <Card.Body className="bg-light p-0">
              <Select
                closeMenuOnSelect={false}
                options={usersSelect}
                placeholder="Choisir un ou plusieurs membre..."
                isMulti
                name={`company_representative_sections[${index}][users]`}
                classNamePrefix="react-select"
                value={formData.company_representative_sections[index].users}
                onChange={value => {
                  setFormDataEdited(true);
                  setFormData({
                    ...formData,
                    company_representative_sections:
                      formData.company_representative_sections.map(
                        (section, i) =>
                          i === index ? { ...section, users: value } : section
                      )
                  });
                }}
              />
            </Card.Body>
          </div>
        ))}
      <a
        className="cursor-pointer"
        onClick={() => {
          setFormDataEdited(true);
          setFormData({
            ...formData,
            company_representative_sections: [
              ...formData.company_representative_sections,
              {
                type: types[0].id,
                users: [],
                order: formData.company_representative_sections.length
              }
            ]
          });
        }}
      >
        <small>+ Ajouter une section</small>
      </a>
    </>
  );
};

ManageRepresentative.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  usersSelect: PropTypes.array.isRequired,
  setFormDataEdited: PropTypes.func.isRequired
};

export default ManageRepresentative;
