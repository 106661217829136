import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form } from 'react-bootstrap';
import { IndexTradePresse } from '../../../../services/PostTradePressService';
import * as Moment from 'moment/moment';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import tredPlaceholder from '../../../../assets/img/tredunion/tred-placeholder.jpg';
import { IndexAdMembers } from '../../../../services/AdService';
import { IndexAdCategories } from '../../../../services/AdCategoryService';
import ManageAdsCategories from '../admins/ads/categories/manage';
import { PostTrack } from '../../../../services/ResourceTrackService';
import { toast } from 'react-toastify';
import { PostAdTrack } from '../../../../services/AdTrackService';

const IndexAdsMembers = () => {
  const [ads, setAds] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchedPosts, setSearchedPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState({});

  const saveTracking = async ad => {
    /*const response = await PostAdTrack({
      ad_id: ad.id
    });
    if (response.success === true && response.data) {
      window.location.href = `/espace-membre/annonces/${response.data.ad.id}`;
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }*/
    window.location.href = `/espace-membre/annonces/${ad.id}`;
  };

  useEffect(() => {
    const fetchObjects = async () => {
      const response = await IndexAdMembers();
      if (response.success === true) {
        setAds(response.data);
        setSearchedPosts(response.data);
        applySearch(searchInput, response.data);
      }
    };
    const fetchCategories = async () => {
      const response = await IndexAdCategories();
      if (response.success) {
        let tmpCat = [];
        response.data.map((c, index) => {
          tmpCat.push({
            id: c.id,
            name: `${c.parent ? `${c.parent.name} > ` : ''}${c.name}`
          });
        });
        tmpCat = tmpCat.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCategories(tmpCat);
      }
    };
    fetchCategories();
    fetchObjects();
  }, []);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };

  const applySearch = (value, objects) => {
    if (value) {
      const searchObjects = objects.filter(object => {
        return (
          object.title
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
        );
      });
      setSearchedPosts(searchObjects);
    } else {
      setSearchedPosts(objects);
    }
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <Col lg={12} className="mt-2">
        <h5 className="mb-4 redHeading align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="me-1"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z"
              fill="rgba(229, 47, 18, 1)"
            />
          </svg>
          Petites annonces
          <Link to={`/espace-membre/mes-annonces`} className="float-end">
            <Button color="primary" size="sm">
              Mes annonces
            </Button>
          </Link>
        </h5>
      </Col>

      <Col xs={6} className="mb-3 d-none">
        <Form.Control
          type="search"
          className="search-input"
          placeholder="Rechercher quelque chose..."
          aria-label="Search"
          value={searchInput}
          size="sm"
          onChange={e => {
            const inputValue = e.target.value;
            setSearchInput(inputValue);
            applySearch(inputValue, ads);
          }}
        />
      </Col>
      <Col xs={12} className="mt-3 mb-3">
        <Form.Group controlId="headquarter_id">
          <Form.Label>Filtrer par catégorie : </Form.Label>
          <Form.Select
            aria-label="Catégorie"
            name="category_id"
            value={filterCategory?.id}
            onChange={e => {
              if (e.target.value) {
                const searchObjects = ads.filter(object => {
                  return (
                    object.category_id === parseInt(e.target.value, 10) ||
                    object.category?.parent_id === parseInt(e.target.value, 10)
                  );
                });
                setSearchedPosts(searchObjects);
              } else {
                setSearchedPosts(ads);
              }
            }}
          >
            <option value="">- Choisir (facultatif) -</option>
            {categories.map((c, index) => (
              <option value={c.id} key={index}>
                {c.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col xs={12}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid actualites"
          columnClassName="masonry-grid_column"
        >
          {searchedPosts.map((post, index) => (
            <Card key={index} style={{ backgroundColor: 'white' }}>
              {post.pictures !== null &&
                post.pictures.length > 0 &&
                post.pictures.filter(media =>
                  ['jpg', 'jpeg', 'png', 'gif'].includes(
                    media.file_url.split('.').pop().toLowerCase()
                  )
                ).length > 0 && (
                  <Card.Img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = tredPlaceholder;
                    }}
                    src={
                      post.pictures.filter(media =>
                        ['jpg', 'jpeg', 'png', 'gif'].includes(
                          media.file_url.split('.').pop().toLowerCase()
                        )
                      )[0].file_url
                    }
                    variant="top"
                  />
                )}
              <Card.Body>
                <Card.Title as="h5">
                  <span className="post-date d-block pb-2">
                    {Moment(post.validated_at ?? post.created_at)
                      .locale('fr')
                      .format('DD MMMM YYYY')}
                    {post.ad_tracks?.length === 0 && (
                      <Badge pill bg="danger" className="ms-2">
                        Nouveau !
                      </Badge>
                    )}
                  </span>
                  <Badge pill bg="primary" className="mb-2">
                    {`${
                      post.category?.parent?.name
                        ? `${post.category.parent.name} > `
                        : ''
                    }${post.category?.name}`}
                  </Badge>
                  <br />
                  {post.title}{' '}
                  {post.price && (
                    <small>
                      <br />
                      {post.price}
                    </small>
                  )}
                </Card.Title>
                <Button
                  color="primary"
                  size="sm"
                  onClick={async () => {
                    saveTracking(post);
                  }}
                >
                  Voir l'annonce
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Masonry>
      </Col>
    </div>
  );
};

export default IndexAdsMembers;
