import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  types: [],
  brandsIsEngine: [],
  brandsNoEngine: []
};

export const purchaseCodesSlice = createSlice({
  name: 'purchase-codes',
  initialState,
  reducers: {
    changeCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
    changeTypes: (state, action) => {
      state.types = [...action.payload];
    },
    changeBrandsIsEngine: (state, action) => {
      state.brandsIsEngine = [...action.payload];
    },
    changeBrandsNoEngine: (state, action) => {
      state.brandsNoEngine = [...action.payload];
    },

    resetFilters: state => {
      state.companies = [];
      state.types = [];
      state.brandsIsEngine = [];
      state.brandsNoEngine = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeCompanies,
  changeTypes,
  changeBrandsIsEngine,
  changeBrandsNoEngine,
  resetFilters
} = purchaseCodesSlice.actions;

export default purchaseCodesSlice.reducer;
