import React from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Modal } from 'react-bootstrap';

const BackModalConfirm = ({ showBackConfirm, setShowBackConfirm }) => {
  return (
    <Modal
      show={showBackConfirm}
      onHide={() => {
        setShowBackConfirm(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>Quitter sans enregistrer ?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="falcon-default"
          size="sm"
          onClick={() => {
            history.back();
          }}
        >
          Oui
        </Button>
        <Button
          variant="falcon-default"
          size="sm"
          onClick={() => {
            setShowBackConfirm(false);
          }}
        >
          Non, rester sur cette page
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BackModalConfirm.propTypes = {
  showBackConfirm: PropTypes.bool,
  setShowBackConfirm: PropTypes.func
};

export default BackModalConfirm;
