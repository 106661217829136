import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  ProgressBar,
  Row,
  Form,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal
} from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import { ShowCourse } from '../../../../services/Elearning/CourseService';
import { membersRoutes } from '../../../../routes/authRoutes';
import {
  CheckVimeoControls,
  PutUser,
  ShowUser
} from '../../../../services/UserService';
import Player from '@vimeo/player';
import IconAlert from '../../../common/IconAlert';
import ElearningCourseVoletSteps from './ElearningCourseVoletSteps';
import ElearningEvaluation from './ElearningEvaluation';
import FalconCloseButton from '../../../common/FalconCloseButton';
import { PostAttemp } from '../../../../services/Elearning/StepAttempService';

const ElearningCourse = () => {
  const [user, setUser] = useOutletContext();
  const [trigger, setTrigger] = useState(Date.now());
  const [course, setCourse] = useState({});
  const [currentStep, setCurrentStep] = useState({});
  const [explanationStep, setExplanationStep] = useState({});
  const [isEvaluationStep, setIsEvaluationStep] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playerPosition, setPlayerPosition] = useState(0);
  const [playerDuration, setPlayerDuration] = useState(100);
  const { id } = useParams();
  const [player, setPlayer] = useState(null);
  const [playerPlay, setPlayerPlay] = useState(false);
  const [endedVideo, setEndedVideo] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showEvaluationMessage, setShowEvaluationMessage] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const scrollStepTitle = () => {
    const id = 'step-title';
    const yOffset = -150;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const updateVideo = vimeoId => {
    setEndedVideo(false);
    setPlayerPlay(false);
    if (player !== null)
      player
        .destroy()
        .then(function () {
          console.log('Player destroyed');
        })
        .catch(function (error) {
          console.log(error);
        });
    setPlayer(null);
    setTimeout(function () {
      const checkControls = async () => {
        const response = await CheckVimeoControls(vimeoId);
        if (response.success === true) {
          let controls = response.data;
          setTimeout(function () {
            let tmpPlayer = new Player('embed-elearning', {
              id: vimeoId,
              controls: controls
            });
            tmpPlayer.on('ended', function () {
              setEndedVideo(true);
              setPlayerPlay(false);
              document.getElementById('quizz').scrollIntoView();
            });
            tmpPlayer
              .getDuration()
              .then(function (duration) {
                setPlayerDuration(duration);
              })
              .catch(function (error) {
                console.log(error);
              });
            setInterval(function () {
              tmpPlayer
                .getCurrentTime()
                .then(function (seconds) {
                  setPlayerPosition(seconds);
                })
                .catch(function (error) {
                  //console.log(error);
                });
            }, 1000);
            setPlayer(tmpPlayer);
          }, 1000);
        }
      };
      checkControls();
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ShowCourse(id);
      if (response.success === true) {
        // setCourse(response.data);
        const responseUser = await ShowUser(
          JSON.parse(localStorage.getItem('user')).id
        );
        if (responseUser.success === true) {
          setUser(responseUser.data);
          let currentCourse = responseUser.data.courses.find(c => c.id == id);
          setCourse(currentCourse);
          if (parseInt(user.course_is_introduced) === 0) {
            setShowWelcomeModal(true);
            await PutUser('course.isintroduced', user.id, {
              courseId: id
            });
          }
          if (parseInt(currentCourse.pivot.course_isEvaluationStep) === 1) {
            setIsEvaluationStep(true);
          } else if (
            currentCourse.pivot.course_current_step_id === null ||
            currentCourse.pivot.course_current_step_id === undefined
          ) {
            setCurrentStep(currentCourse.steps[0]);
            let tmpUser = user;
            tmpUser.course_current_step_id = currentCourse.steps[0].id;
            setUser(tmpUser);
            updateVideo(currentCourse.steps[0].vimeo_id);
            await PutUser('course.currentstep', user.id, {
              courseId: id,
              stepId: currentCourse.steps[0].id
            });
            await PutUser('course.startdate', user.id, {
              courseId: id
            });
          } else {
            let currentStep = currentCourse.steps.find(
              s => s.id == currentCourse.pivot.course_current_step_id
            );
            setCurrentStep(currentStep);
            updateVideo(currentStep.vimeo_id);
          }
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
  }, [trigger]);

  const upgradeStep = async step => {
    let index = course.steps.findIndex(x => x.id === step.id);
    if (
      course.steps[index + 1] !== null &&
      course.steps[index + 1] !== undefined
    ) {
      await PostAttemp({
        step_id: course.steps[index].id,
        course_id: course.id
      });
      const response = await PutUser('course.currentstep', user.id, {
        courseId: id,
        stepId: course.steps[index + 1].id
      });
      if (response.success === true) {
        setTrigger(Date.now());
        setTimeout(function () {
          scrollStepTitle();
        }, 500);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else {
      const response = await PutUser('course.isevaluationstep', user.id, {
        courseId: id
      });
      if (response.success === true) {
        setCurrentStep({});
        setTrigger(Date.now());
        setTimeout(function () {
          scrollStepTitle();
        }, 500);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    }
  };

  return (
    <>
      {course.id !== null && course.id !== undefined && !isLoading && (
        <Row className="mt-2 mb-8">
          <Modal
            show={showWelcomeModal}
            onHide={() => {
              setShowWelcomeModal(false);
            }}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title className="redHeading" as="h5">
                Bienvenue !
              </Modal.Title>
              <FalconCloseButton
                onClick={() => {
                  setShowWelcomeModal(false);
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <p className="smaller-p">
                À chaque étape du cursus, vous avez l’occasion d’évaluer vos
                connaissances grâce à des tests QCM. Vous devez répondre
                correctement à l'ensemble des questions pour passer au module
                suivant. En cas d'erreur, vous devrez à nouveau visionner le
                module concerné et refaire le test.
              </p>
              <p className="smaller-p">
                À tout moment, vous pouvez poser vos questions et communiquer
                avec les formateurs à travers un mail en cliquant sur le bouton
                prévu à cet effet.
              </p>
              <p className="smaller-p">
                À la fin de votre formation, un questionnaire d’évaluation vous
                sera transmis afin de recueillir votre avis sur ce cursus.
              </p>
              <p className="smaller-p">
                Nous sommes heureux de vous accompagner dans l’acquisition de
                nouvelles compétences et vous souhaitons un bel apprentissage !
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  setShowWelcomeModal(false);
                }}
              >
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>
          <Col xs={8}>
            <h5 className="mb-4 redHeading" id="step-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d={
                    membersRoutes.filter(
                      route => route.id === 'tredElearning'
                    )[0].svg
                  }
                  fill="rgba(229,47,18,1)"
                />
              </svg>{' '}
              {course.title}
              {!isEvaluationStep ? (
                <>
                  {currentStep.title !== null &&
                    currentStep.title !== undefined && (
                      <span> - {currentStep.title}</span>
                    )}
                </>
              ) : (
                <> - Évaluation</>
              )}
            </h5>{' '}
          </Col>
          <Col xs={4} className="text-end pe-lg-0">
            <DropdownButton
              as={ButtonGroup}
              size="sm"
              title="Aide"
              className="mb-1"
            >
              {course.teachers.map((teacher, index) => (
                <Dropdown.Item
                  eventKey={index}
                  key={index}
                  onClick={() =>
                    (window.location.href = `mailto:${teacher.email}`)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z" />
                  </svg>{' '}
                  Contacter mon formateur {teacher.lastname} {teacher.firstname}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
          {currentStep.id !== null &&
            currentStep.id !== undefined &&
            !isEvaluationStep && (
              <>
                <Col xs={12}>
                  <p>{currentStep.content}</p>
                </Col>
                <Col xs={12} md={8}>
                  {failedMessage && (
                    <IconAlert
                      variant="danger"
                      dismissible
                      onClose={() => setFailedMessage(false)}
                    >
                      <p className="mb-0 pe-4">
                        Vous y êtes presque ! Visionnez à nouveau la vidéo pour
                        parfaire vos connaissances et réussir votre quiz. Bon
                        courage !
                      </p>
                    </IconAlert>
                  )}
                  {successMessage && (
                    <IconAlert
                      variant="success"
                      dismissible
                      onClose={() => setSuccessMessage(false)}
                    >
                      <p className="mb-0 pe-4">
                        Parfait ! Vos bonnes réponses vous permettent d'accéder
                        au module suivant. Continuez sur cette belle lancée.
                      </p>
                      {explanationStep?.questions?.filter(
                        question =>
                          question.correct_answer_explanation !== null &&
                          question.correct_answer_explanation !== undefined
                      ).length > 0 && (
                        <>
                          <hr />
                          <p>
                            <b>Explications :</b>
                          </p>
                          {explanationStep?.questions
                            ?.filter(
                              question =>
                                question.correct_answer_explanation !== null &&
                                question.correct_answer_explanation !==
                                  undefined
                            )
                            .map((question, index) => (
                              <div key={index} className="mb-2">
                                <b>{question.title}</b>
                                <p className="mb-0">
                                  {question.correct_answer_explanation}
                                </p>
                              </div>
                            ))}
                        </>
                      )}
                    </IconAlert>
                  )}
                  <div id="embed-elearning"></div>
                  {playerPosition > 0 && (
                    <ProgressBar
                      id="progress-video"
                      now={(playerPosition * 100) / playerDuration}
                      className="mb-3"
                    />
                  )}
                  {player !== null && player !== undefined && (
                    <Row className="mt-3">
                      <Col xs={12} className="text-center">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            player.setVolume(1);
                            playerPlay ? player.pause() : player.play();
                            setPlayerPlay(!playerPlay);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{
                              marginBottom: '2px',
                              marginRight: '5px'
                            }}
                          >
                            {playerPlay ? (
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM9 9h2v6H9V9zm4 0h2v6h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            ) : (
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
                                fill="rgba(255,255,255,1)"
                              />
                            )}
                          </svg>
                          {playerPlay
                            ? 'Mettre sur pause'
                            : Math.round(playerPosition) === playerDuration
                            ? 'Recommencer la vidéo'
                            : 'Lire la vidéo'}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-4">
                    {!endedVideo ? (
                      <Col xs={12}>
                        <IconAlert variant="info">
                          <p className="mb-0">
                            Terminez la vidéo ci-dessus pour pouvoir continuer.
                          </p>
                        </IconAlert>
                      </Col>
                    ) : (
                      <>
                        {currentStep.questions !== null &&
                        currentStep.questions !== undefined &&
                        currentStep.questions.length ? (
                          <Col xs={12} id="quizz">
                            <IconAlert variant="info" className="mt-5">
                              <p className="mb-0">
                                Pour continuer, répondez à toutes les questions
                                ci-dessous (parfois, plusieurs réponses sont
                                possibles) :
                              </p>
                            </IconAlert>
                            <Row>
                              {currentStep.questions.map((question, index) => (
                                <Col
                                  xs={12}
                                  key={index}
                                  className="training mb-4"
                                >
                                  <Card>
                                    <Card.Body className="px-3">
                                      <Card.Title as="h5" className="mb-0">
                                        {question.title}
                                      </Card.Title>
                                    </Card.Body>
                                    <ListGroup className="list-group-flush px-3 mb-3">
                                      {question.answers.map(
                                        (answer, indexAnswer) => (
                                          <ListGroupItem
                                            className="px-0 answer-elearning"
                                            key={indexAnswer}
                                          >
                                            <Form.Check
                                              type="checkbox"
                                              id={answer.id}
                                              data-is-right={answer.is_right}
                                              label={answer.title}
                                            />
                                          </ListGroupItem>
                                        )
                                      )}
                                    </ListGroup>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                            <Col xs={12} className="text-end mb-3 mb-lg-0">
                              <hr />
                              <Button
                                color="primary"
                                size="sm"
                                onClick={async () => {
                                  setExplanationStep(currentStep);
                                  let filteredInputsFalseAnswers = [
                                    ...document.querySelectorAll(
                                      '.answer-elearning input'
                                    )
                                  ].filter(
                                    x =>
                                      parseInt(x.dataset.isRight) === 0 &&
                                      x.checked
                                  );
                                  let filteredInputsTrueAnswers = [
                                    ...document.querySelectorAll(
                                      '.answer-elearning input'
                                    )
                                  ].filter(
                                    x =>
                                      parseInt(x.dataset.isRight) === 1 &&
                                      !x.checked
                                  );
                                  if (
                                    filteredInputsFalseAnswers.length === 0 &&
                                    filteredInputsTrueAnswers.length === 0
                                  ) {
                                    upgradeStep(
                                      course.steps.filter(
                                        s => s.id === currentStep.id
                                      )[0]
                                    );
                                    setFailedMessage(false);
                                    setSuccessMessage(true);
                                  } else {
                                    await PostAttemp({
                                      step_id: course.steps.filter(
                                        s => s.id === currentStep.id
                                      )[0].id,
                                      course_id: course.id
                                    });
                                    setTrigger(Date.now);
                                    setFailedMessage(true);
                                    setSuccessMessage(false);
                                    setTimeout(function () {
                                      scrollStepTitle();
                                    }, 250);
                                  }
                                }}
                              >
                                Étape suivante
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="17"
                                  height="17"
                                  style={{ marginBottom: '2px' }}
                                >
                                  <path
                                    d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                                    fill="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </Button>
                            </Col>
                          </Col>
                        ) : (
                          <Col
                            xs={12}
                            id="quizz"
                            className="text-end mb-3 mb-lg-0"
                          >
                            <hr />
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setSuccessMessage(false);
                                setFailedMessage(false);
                                upgradeStep(
                                  course.steps.filter(
                                    s => s.id === currentStep.id
                                  )[0]
                                );
                              }}
                            >
                              Étape suivante
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="17"
                                height="17"
                                style={{ marginBottom: '2px' }}
                              >
                                <path
                                  d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                                  fill="rgba(255,255,255,1)"
                                />
                              </svg>
                            </Button>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
                <Col xs={12} md={4} className="mt-4 mt-lg-0">
                  <ElearningCourseVoletSteps
                    course={course}
                    currentStep={currentStep}
                    player={player}
                    playerPlay={playerPlay}
                    setPlayerPlay={setPlayerPlay}
                    user={user}
                    setFailedMessage={setFailedMessage}
                    setSuccessMessage={setSuccessMessage}
                    setCurrentStep={setCurrentStep}
                    updateVideo={updateVideo}
                    scrollStepTitle={scrollStepTitle}
                    isEvaluationStep={isEvaluationStep}
                    setIsEvaluationStep={setIsEvaluationStep}
                  />
                </Col>
              </>
            )}
          {isEvaluationStep && (
            <>
              <Col xs={12} md={8}>
                {showEvaluationMessage && (
                  <IconAlert
                    variant="info"
                    dismissible
                    onClose={() => setShowEvaluationMessage(false)}
                  >
                    <p className="mb-0">
                      Pour récupérer votre certificat de fin de formation, merci
                      de répondre au questionnaire ci-dessous :
                    </p>
                  </IconAlert>
                )}
                <ElearningEvaluation
                  course={course}
                  scrollStepTitle={scrollStepTitle}
                />
              </Col>
              <Col xs={12} md={4} className="d-none d-md-block">
                <ElearningCourseVoletSteps
                  course={course}
                  currentStep={currentStep}
                  player={player}
                  playerPlay={playerPlay}
                  setPlayerPlay={setPlayerPlay}
                  user={user}
                  setFailedMessage={setFailedMessage}
                  setSuccessMessage={setSuccessMessage}
                  setCurrentStep={setCurrentStep}
                  updateVideo={updateVideo}
                  scrollStepTitle={scrollStepTitle}
                  isEvaluationStep={isEvaluationStep}
                  setIsEvaluationStep={setIsEvaluationStep}
                />
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default ElearningCourse;
