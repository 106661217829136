import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: []
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    changeCompanies: (state, action) => {
      state.companies = [...action.payload];
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeCompanies } = invoicesSlice.actions;

export default invoicesSlice.reducer;
