import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'representative-section-types';

export async function IndexRepresentativeSectionType() {
  return modelService.IndexModel(`${model}`);
}

export async function PostRepresentativeSectionType(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutRepresentativeSectionType(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteRepresentativeSectionType(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
