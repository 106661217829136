import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import congratulation from '../../../../assets/img/animated-icons/congratulation.json';
import Lottie from 'react-lottie-player';

const ElearningCongratulation = () => {
  return (
    <Row className="mt-0">
      <Col xs={12} className="text-center">
        <h5 className="mb-3 mt-0 redHeading text-center">Félicitations !</h5>
        <Lottie
          loop
          animationData={congratulation}
          play
          style={{
            width: '350px',
            height: '220px',
            cursor: 'default',
            textAlign: 'center',
            margin: '0 auto'
          }}
        />
      </Col>
      <Col xs={12}>
        <p className="text-center">
          Vous avez terminé votre formation avec succès ! <br />
          <b>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="13"
              height="13"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                fill="rgba(100,99,99,1)"
              />
            </svg>{' '}
            Votre certificat de formation va vous être envoyé par email d'ici
            quelques minutes.
          </b>
        </p>
        <p className="text-center">
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              window.location.href = '/espace-membre/e-learning';
            }}
          >
            Retour au catalogue E-Learning
          </Button>
        </p>
      </Col>
    </Row>
  );
};

export default ElearningCongratulation;
