import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import FileUploader from '../../../../commons/fileUploader';
import FormAlert from '../../../../commons/FormAlert';
import {
  DeleteCourse,
  PostCourse,
  PutCourse,
  ShowCourseAdmin
} from '../../../../../../services/Elearning/CourseService';
import { IndexTeachers } from '../../../../../../services/UserService';
import Select from 'react-select';
import IconAlert from '../../../../../common/IconAlert';
import SoftBadge from '../../../../../common/SoftBadge';
import Vimeo from '@u-wave/react-vimeo';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  DeleteStep,
  PostStep,
  PutStep
} from '../../../../../../services/Elearning/StepService';
import {
  DeleteAnswer,
  PostAnswer,
  PutAnswer
} from '../../../../../../services/Elearning/AnswerService';
import {
  DeleteQuestion,
  PostQuestion,
  PutQuestion
} from '../../../../../../services/Elearning/QuestionService';

const ManageCourses = () => {
  const { id } = useParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [object, setObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [modalVideoShow, setModalVideoShow] = useState(false);
  const [modalVimeoId, setModalVimeoId] = useState(false);
  const [selectedStep, setSelectedStep] = useState({});
  const [showStepDelete, setShowStepDelete] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [showManageStep, setShowManageStep] = useState(false);
  const [showManageQuestions, setShowManageQuestions] = useState(false);
  const [showManageQuestion, setShowManageQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [showManageAnswer, setShowManageAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [errorFormStep, setErrorFormStep] = useState(false);
  const [errorFormAnswer, setErrorFormAnswer] = useState(false);
  const [errorFormQuestion, setErrorFormQuestion] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image_url: '',
    is_visible: '',
    teachers: [],
    duration: '',
    authorise_requests: 1
  });

  const navigate = useNavigate();

  const [formStepData, setFormStepData] = useState({
    title: '',
    content: '',
    vimeo_id: '',
    order: 0,
    duration: '',
    course_id: id
  });

  const [formQuestionData, setFormQuestionData] = useState({
    title: '',
    correct_answer_explanation: '',
    step_id: '',
    course_id: ''
  });

  const [formAnswerData, setFormAnswerData] = useState({
    title: '',
    is_right: '',
    question_id: ''
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const responseUsers = await IndexTeachers();
      if (responseUsers.success === true) {
        let dataUsers = responseUsers.data;
        let usersArray = [];
        dataUsers.map(user => {
          usersArray.push({
            value: `${user.id}`,
            label: `${user.firstname} ${user.lastname}`
          });
        });
        setTeachers(usersArray);
      }
    };
    const fetchObject = async () => {
      fetchUsers();
      const responseObject = await ShowCourseAdmin(id);
      if (responseObject.success === true) {
        setObject(responseObject.data);
        let usersArray = [];
        responseObject.data.teachers.map(user => {
          usersArray.push({
            value: `${user.id}`,
            label: `${user.firstname} ${user.lastname}`
          });
        });
        setFormData({
          title: responseObject.data.title,
          content: responseObject.data.content,
          image_url: responseObject.data.image_url,
          is_visible: responseObject.data.is_visible,
          duration: responseObject.data.duration,
          teachers: usersArray,
          authorise_requests: responseObject.data.authorise_requests
        });
        if (selectedStep.id) {
          setSelectedStep(
            responseObject.data.steps.filter(
              step => step.id === selectedStep.id
            )[0]
          );
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      fetchObject();
    } else {
      fetchUsers();
    }
  }, [trigger]);

  const handleFieldChange = e => {
    if (e.target.name === 'authorise_requests') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleFieldStepChange = e => {
    setFormStepData({
      ...formStepData,
      [e.target.name]: e.target.value
    });
  };

  const handleFieldQuestionChange = e => {
    setFormQuestionData({
      ...formQuestionData,
      [e.target.name]: e.target.value
    });
  };

  const handleFieldAnswerChange = e => {
    setFormAnswerData({
      ...formAnswerData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutCourse(id, formData);
    } else {
      response = await PostCourse(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Formation enregistrée.`;
      toast(successMessage);
      setSuccessForm(successMessage);
      setTrigger(Date.now());
      if (!id) {
        setIsLoading(true);
        setTimeout(function () {
          window.location.href = `/administration/elearning-formations/${response.data.id}/modifier`;
        }, 2500);
      }
    }
  };

  const handleAnswerSubmit = async e => {
    e.preventDefault();
    setErrorFormAnswer(false);
    setIsLoading(true);
    let response = false;
    if (selectedAnswer.id) {
      response = await PutAnswer(selectedAnswer.id, formAnswerData);
    } else {
      response = await PostAnswer(formAnswerData);
    }
    setErrorFormAnswer(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Réponse enregistrée.`;
      toast(successMessage);
      setTrigger(Date.now());
      setShowManageAnswer(false);
    }
  };

  const handleQuestionSubmit = async e => {
    e.preventDefault();
    setErrorFormQuestion(false);
    setIsLoading(true);
    let response = false;
    if (selectedQuestion.id) {
      response = await PutQuestion(selectedQuestion.id, formQuestionData);
    } else {
      response = await PostQuestion(formQuestionData);
    }
    setErrorFormQuestion(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Question enregistrée.`;
      toast(successMessage);
      setTrigger(Date.now());
      setShowManageQuestion(false);
    }
  };

  const handleSubmitStep = async e => {
    e.preventDefault();
    setErrorFormStep(false);
    setIsLoading(true);
    let response = false;
    if (selectedStep.id !== null && selectedStep.id !== undefined) {
      response = await PutStep(selectedStep.id, formStepData);
    } else {
      response = await PostStep(formStepData);
    }
    setSuccessForm(false);
    setErrorFormStep(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Étape enregistrée.`;
      setShowManageStep(false);
      setSelectedStep({});
      setFormStepData({
        title: '',
        content: '',
        vimeo_id: '',
        order: 0,
        duration: 10,
        course_id: object.id
      });
      toast(successMessage);
      setTrigger(Date.now());
      setTimeout(function () {
        document.getElementById(`step-${response.data.id}`).scrollIntoView();
      }, 800);
    }
  };

  const deleteObject = async () => {
    const response = await DeleteCourse(id);
    if (response.success === true) {
      toast('Formation archivée');
      navigate('/administration/elearning-formations');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteStep = async () => {
    const response = await DeleteStep(selectedStep.id);
    if (response.success === true) {
      setShowStepDelete(false);
      setShowManageStep(false);
      setSelectedStep({});
      setFormStepData({
        title: '',
        content: '',
        vimeo_id: '',
        order: 0,
        duration: 10,
        course_id: object.id
      });
      toast('Étape archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteQuestion = async id => {
    const response = await DeleteQuestion(id);
    if (response.success === true) {
      toast('Question archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteAnswer = async id => {
    const response = await DeleteAnswer(id);
    if (response.success === true) {
      setShowManageAnswer(false);
      toast('Réponse archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {((id && object.id !== null && object.id !== undefined) || !id) && (
        <>
          {id && (
            <Offcanvas
              show={showDelete}
              onHide={() => {
                setShowDelete(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  L'archivage de la formation est définitif. Voulez-vous
                  continuer ?
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    deleteObject();
                  }}
                >
                  Oui, archiver
                </Button>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowDelete(false);
                  }}
                >
                  Annuler
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          )}
          <Form onSubmit={handleSubmit}>
            <Row className="g-3">
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col md>
                        <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                          {' '}
                          {object.id !== null && object.id !== undefined
                            ? `Modifier la formation`
                            : 'Ajouter une formation'}
                        </h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href =
                              '/administration/elearning-formations';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {errorForm && (
                  <div className="mt-3">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                {successForm && !errorForm && (
                  <div className="mt-3">
                    <FormAlert
                      className="mt-3"
                      variant="success"
                      data={successForm}
                    />
                  </div>
                )}
              </Col>
              <Col xs={12}>
                <Card className="mb-3 pb-3">
                  <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Image :
                          </Form.Label>
                          {id ? (
                            <FileUploader
                              pathToUpload="courses/images"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="image_url"
                              cropperEnabled={false}
                              currentImage={object.image_url}
                            />
                          ) : (
                            <FileUploader
                              pathToUpload="courses/images"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="image_url"
                              cropperEnabled={false}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="name">
                          <Form.Label>Titre :</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Titre de la formation"
                            value={formData.title}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="content">
                          <Form.Label>Description :</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="content"
                            value={formData.content}
                            rows={3}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="teachers">
                          <Form.Label>Formateur(s) :</Form.Label>
                          <Select
                            closeMenuOnSelect={false}
                            options={teachers}
                            placeholder="Choisir un ou plusieurs métiers..."
                            isMulti
                            name="trades"
                            classNamePrefix="react-select"
                            value={formData.teachers}
                            onChange={value => {
                              setFormData({
                                ...formData,
                                teachers: value
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <label>Durée</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="number"
                            onWheel={e => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            name="duration"
                            placeholder="Durée de la formation"
                            value={formData.duration}
                            onChange={handleFieldChange}
                          />
                          <InputGroup.Text
                            id="basic-addon2"
                            style={{ color: '#646363' }}
                          >
                            jours
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col md="12" className="mb-3">
                        <IconAlert variant="info" className="mb-0">
                          <p className="mb-0">
                            Cette donnée sert à fixer la limite (en nombre de
                            jours) pendant lesquels l'étudiant doit commencer la
                            formation.
                          </p>
                          <p className="mb-0">
                            Si l'étudiant ne commence pas la formation avant la
                            limite, l'accès à celle-ci sera révoqué et il en
                            sera notifié par un mail automatique.
                          </p>
                          <p className="mb-0">
                            Cette donnée sert aussi à envoyer un mail
                            automatique à l'administrateur quand l'étudiant met
                            trop de temps pour finir la formation une fois
                            commencée.
                          </p>
                        </IconAlert>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="is_visible">
                          <Form.Label>Statut :</Form.Label>
                          <div className="d-block"></div>
                          <input
                            type="radio"
                            id="is_visible_0"
                            name="is_visible"
                            value="0"
                            checked={
                              formData.is_visible === 0 ||
                              formData.is_visible === '0'
                            }
                            onChange={handleFieldChange}
                          />
                          <label
                            htmlFor="is_visible_0"
                            style={{
                              position: 'relative',
                              bottom: '1px',
                              left: '2px',
                              marginRight: '8px',
                              cursor: 'pointer'
                            }}
                          >
                            Hors ligne
                          </label>

                          <input
                            type="radio"
                            id="is_visible_1"
                            name="is_visible"
                            value="1"
                            checked={
                              formData.is_visible === 1 ||
                              formData.is_visible === '1'
                            }
                            onChange={handleFieldChange}
                          />
                          <label
                            style={{
                              position: 'relative',
                              bottom: '1px',
                              left: '2px',
                              marginRight: '8px',
                              cursor: 'pointer'
                            }}
                            htmlFor="is_visible_1"
                          >
                            En ligne
                          </label>
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <label>
                          <input
                            type="checkbox"
                            id="authorise_requests"
                            name="authorise_requests"
                            value="1"
                            className="me-1"
                            checked={
                              formData.authorise_requests === 1 ||
                              formData.authorise_requests === '1'
                            }
                            onChange={handleFieldChange}
                          />
                          Autoriser les inscriptions ?
                        </label>
                        <span className="smaller-p d-block mb-2">
                          Cocher cette case permet aux membres de demander à
                          rejoindre la formation depuis leur espace.
                        </span>
                      </Col>

                      <Col md="12" className="pb-2 pt-3">
                        <div className="border-dashed-bottom"></div>
                      </Col>

                      {!id ? (
                        <Col md={12}>
                          <IconAlert variant="info" className="mb-0">
                            <p className="mb-0">
                              Enregistrez la formation pour pouvoir y ajouter
                              des étapes.
                            </p>
                          </IconAlert>
                        </Col>
                      ) : (
                        <>
                          <Col md={12}>
                            <h5 className="admin-title companyRightManagePeople">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginRight: '4px',
                                  marginBottom: '2px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                                  fill="rgba(75,74,77,1)"
                                />
                              </svg>
                              Étapes de la formation :
                            </h5>
                          </Col>
                          {object.steps && (
                            <Col xs={12}>
                              <Modal
                                show={modalVideoShow}
                                onHide={() => setModalVideoShow(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                              >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                  {modalVimeoId && (
                                    <Vimeo
                                      video={modalVimeoId}
                                      style={{ width: '100%' }}
                                      autoplay
                                      className="vimeo-video"
                                    />
                                  )}
                                </Modal.Body>
                              </Modal>
                              <Table
                                bordered
                                responsive
                                className="admin-table mt-2"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">Ordre</th>
                                    <th scope="col">Titre</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Vidéo</th>
                                    <th scope="col">Questionnaire</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {object.steps
                                    .sort((a, b) => {
                                      if (a.order < b.order) {
                                        return -1;
                                      }
                                      if (a.order > b.order) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                    .map((step, index) => (
                                      <tr key={index} id={`step-${step.id}`}>
                                        <td>{step.order}</td>
                                        <td>
                                          {step.title}{' '}
                                          <span className="d-block mt-2">
                                            <small>
                                              Durée : {step.duration} minutes
                                            </small>
                                          </span>
                                        </td>
                                        <td>{step.content}</td>
                                        <td>
                                          {step.vimeo_id && (
                                            <Button
                                              size="sm"
                                              variant="falcon-primary"
                                              className="me-2"
                                              type="button"
                                              onClick={() => {
                                                setModalVimeoId(step.vimeo_id);
                                                setModalVideoShow(true);
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="14"
                                                height="14"
                                                style={{
                                                  marginBottom: '2px'
                                                }}
                                              >
                                                <path
                                                  d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
                                                  fill="rgba(0,0,0,1)"
                                                />
                                              </svg>
                                            </Button>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {step.questions &&
                                          step.questions.length > 0 ? (
                                            <SoftBadge
                                              bg="success"
                                              className="me-2"
                                            >
                                              {step.questions.length} question
                                              {step.questions.length > 1 && 's'}
                                            </SoftBadge>
                                          ) : (
                                            <SoftBadge
                                              bg="primary"
                                              className="me-2"
                                            >
                                              Aucun questionnaire
                                            </SoftBadge>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <Button
                                            size="sm"
                                            variant="primary"
                                            className="me-2 admin-primary"
                                            type="button"
                                            onClick={() => {
                                              setSelectedStep(step);
                                              setFormStepData({
                                                title: step.title,
                                                content: step.content,
                                                vimeo_id: step.vimeo_id,
                                                order: step.order,
                                                duration: step.duration,
                                                course_id: object.id
                                              });
                                              setShowManageStep(true);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="14"
                                              height="14"
                                              style={{
                                                marginBottom: '2px'
                                              }}
                                            >
                                              <path
                                                d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                                fill="rgba(255,255,255,1)"
                                              />
                                            </svg>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                          )}
                          <Col
                            xs={12}
                            className={classNames('', {
                              'mt-3': object.steps.length > 0
                            })}
                          >
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                setShowManageStep(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '3px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                              </svg>
                              Ajouter une{' '}
                              {object.steps.length > 0 ? 'autre' : ''} étape
                            </Button>
                            <Offcanvas
                              show={showStepDelete}
                              onHide={() => {
                                setShowStepDelete(false);
                              }}
                              placement={'end'}
                            >
                              <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                  {selectedStep.id !== undefined && (
                                    <>
                                      L'archivage de l'étape "
                                      {`${selectedStep.title}`}" est définitif.
                                    </>
                                  )}
                                  <br />
                                  Voulez-vous continuer ?
                                </Offcanvas.Title>
                              </Offcanvas.Header>
                              <Offcanvas.Body>
                                <Button
                                  size="sm"
                                  variant="falcon-danger"
                                  className="me-2"
                                  onClick={() => {
                                    deleteStep();
                                  }}
                                >
                                  Oui, archiver
                                </Button>
                                <Button
                                  size="sm"
                                  variant="falcon-default"
                                  className="me-2"
                                  onClick={() => {
                                    setShowStepDelete(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Offcanvas.Body>
                            </Offcanvas>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Row className="flex-between-center">
                      <Col xs="12">
                        <Button
                          size="sm"
                          variant="falcon-default"
                          className="me-2"
                          onClick={() => {
                            window.location.href =
                              '/administration/elearning-formations';
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style={{ marginBottom: '2px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                          </svg>
                          {!id ? 'Annuler' : 'Retour'}
                        </Button>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '4px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                        {id && (
                          <Button
                            size="sm"
                            variant="danger"
                            className="me-0 float-end"
                            type="button"
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '2px',
                                marginRight: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Archiver
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
          <Form>
            <Offcanvas
              show={showManageStep}
              onHide={() => {
                setShowManageStep(false);
                setSelectedStep({});
                setFormStepData({
                  title: '',
                  content: '',
                  vimeo_id: '',
                  order: 0,
                  duration: 10,
                  course_id: object.id
                });
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {selectedStep.id !== null && selectedStep.id !== undefined
                    ? 'Modifier'
                    : 'Ajouter'}{' '}
                  une étape
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Row className="gx-2 gy-3 mb-3">
                  <Col xs={12}>
                    {errorFormStep && (
                      <div className="mt-0">
                        <FormAlert variant="danger" data={errorFormStep} />
                      </div>
                    )}
                  </Col>
                  <Col md={12}>
                    <IconAlert variant="info">
                      {selectedStep.id ? (
                        <>
                          <p className="mb-0 smaller-p">
                            {selectedStep.questions &&
                            selectedStep.questions.length > 0
                              ? 'Le questionnaire de cette étape est correctement configuré.'
                              : 'Vous pouvez ajouter un questionnaire à cette étape en cliquant sur le bouton ci-dessous :'}
                          </p>
                          <Button
                            variant="falcon-primary"
                            className="me-2 mb-1 mt-2"
                            size="sm"
                            onClick={() => setShowManageQuestions(true)}
                          >
                            Gérer le questionnaire
                          </Button>
                        </>
                      ) : (
                        <p className="mb-0 smaller-p">
                          Enregistrez cette nouvelle étape pour pouvoir y
                          ajouter un questionnaire.
                        </p>
                      )}
                    </IconAlert>
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="name">
                      <Form.Label>Titre :</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Titre de l'étape"
                        value={formStepData.title || ''}
                        onChange={handleFieldStepChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="content">
                      <Form.Label>Description :</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="content"
                        value={formStepData.content || ''}
                        rows={3}
                        onChange={handleFieldStepChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="vimeo_id">
                      <Form.Label>Identifiant Viméo :</Form.Label>
                      <Form.Control
                        type="number"
                        onWheel={e => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        name="vimeo_id"
                        placeholder="324563412"
                        value={formStepData.vimeo_id || ''}
                        onChange={handleFieldStepChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group controlId="order">
                      <Form.Label>Ordre :</Form.Label>
                      <Form.Control
                        type="number"
                        onWheel={e => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        name="order"
                        placeholder="1"
                        value={formStepData.order || ''}
                        onChange={handleFieldStepChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="order">
                      <Form.Label>Durée :</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          onWheel={e => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          name="duration"
                          placeholder="10"
                          value={formStepData.duration || ''}
                          onChange={handleFieldStepChange}
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text
                          id="basic-addon2"
                          className="text-dark"
                        >
                          minutes
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowManageStep(false);
                    setSelectedStep({});
                    setFormStepData({
                      title: '',
                      content: '',
                      vimeo_id: '',
                      order: 0,
                      duration: 10,
                      course_id: object.id
                    });
                  }}
                >
                  Fermer
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  type="submit"
                  onClick={e => {
                    handleSubmitStep(e);
                  }}
                  disabled={isLoading}
                  className="me-2 admin-primary"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{
                      marginBottom: '2px',
                      marginRight: '4px'
                    }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                  Enregistrer
                </Button>
                {selectedStep.id !== null && selectedStep.id !== undefined && (
                  <Button
                    size="sm"
                    variant="danger"
                    className="me-0 float-end"
                    type="button"
                    onClick={() => {
                      setShowStepDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="14"
                      height="14"
                      style={{
                        marginBottom: '2px'
                      }}
                    >
                      <path
                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  </Button>
                )}
              </Offcanvas.Body>
            </Offcanvas>
          </Form>
          <Form>
            <Offcanvas
              show={showManageQuestions}
              onHide={() => {
                setShowManageQuestions(false);
              }}
              placement={'end'}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  Gérer le questionnaire de l'étape "{selectedStep.title}"
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Row className="gx-2 gy-3 mb-3">
                  {selectedStep.questions && selectedStep.questions.length > 0 && (
                    <>
                      {selectedStep.questions.map((question, index) => (
                        <Fragment key={index}>
                          <Col xs={12}>
                            <Alert variant="primary">
                              <Row className="align-items-end">
                                <Col xs="10">
                                  <p className="mb-0">{question.title}</p>
                                  {question.correct_answer_explanation && (
                                    <p className="mb-0 smaller-p">
                                      Si juste :{' '}
                                      <i>
                                        {question.correct_answer_explanation}
                                      </i>
                                    </p>
                                  )}
                                </Col>
                                <Col xs="2" style={{ marginBottom: '2px' }}>
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    className="me-2 admin-primary"
                                    type="button"
                                    onClick={() => {
                                      setShowManageQuestion(true);
                                      setSelectedQuestion(question);
                                      setFormQuestionData({
                                        title: question.title,
                                        correct_answer_explanation:
                                          question.correct_answer_explanation,
                                        step_id: question.step_id,
                                        course_id: question.course_id
                                      });
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="14"
                                      height="14"
                                      style={{
                                        marginBottom: '2px'
                                      }}
                                    >
                                      <path
                                        d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                        fill="rgba(255,255,255,1)"
                                      />
                                    </svg>
                                  </Button>
                                </Col>
                              </Row>
                            </Alert>
                          </Col>

                          <Col md="12">
                            <Form.Group controlId="name">
                              <Form.Label>Réponses :</Form.Label>
                            </Form.Group>
                            {question.answers.length > 0 && (
                              <Table
                                bordered
                                responsive
                                className="admin-table mt-2"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">Label</th>
                                    <th scope="col">Est juste ?</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {question.answers.map((answer, indexA) => (
                                    <tr key={indexA}>
                                      <td>{answer.title}</td>
                                      <td>
                                        {answer.is_right === 1 ? 'Oui' : 'Non'}
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          size="sm"
                                          variant="primary"
                                          className="me-2 admin-primary"
                                          type="button"
                                          onClick={() => {
                                            setShowManageAnswer(true);
                                            setSelectedAnswer(answer);
                                            setFormAnswerData({
                                              title: answer.title,
                                              is_right: answer.is_right,
                                              question_id: answer.question_id
                                            });
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="14"
                                            height="14"
                                            style={{
                                              marginBottom: '2px'
                                            }}
                                          >
                                            <path
                                              d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                              fill="rgba(255,255,255,1)"
                                            />
                                          </svg>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                setSelectedAnswer({});
                                setShowManageAnswer(true);
                                setFormAnswerData({
                                  title: '',
                                  is_right: 0,
                                  question_id: question.id
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '3px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                              </svg>
                              Ajouter une{' '}
                              {question.answers && question.answers.length > 0
                                ? 'autre'
                                : ''}{' '}
                              réponse
                            </Button>
                          </Col>

                          <Col key={index} md="12">
                            <hr />
                          </Col>
                        </Fragment>
                      ))}
                    </>
                  )}
                  <Col xs={12} className="mt-0">
                    <Button
                      size="sm"
                      variant="primary"
                      className="me-2 admin-primary"
                      onClick={() => {
                        setSelectedQuestion({});
                        setShowManageQuestion(true);
                        setFormQuestionData({
                          title: '',
                          correct_answer_explanation: '',
                          step_id: selectedStep.id,
                          course_id: object.id
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        style={{
                          marginBottom: '2px',
                          marginRight: '3px'
                        }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                      Ajouter une{' '}
                      {selectedStep.questions &&
                      selectedStep.questions.length > 0
                        ? 'autre'
                        : ''}{' '}
                      question
                    </Button>
                    <hr />
                  </Col>
                </Row>

                <Modal
                  show={showManageQuestion}
                  onHide={() => {
                    setShowManageQuestion(false);
                    setSelectedQuestion({});
                  }}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <h5 className="admin-title">
                      {selectedQuestion.id ? 'Modifier la ' : 'Ajouter une '}
                      question
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleQuestionSubmit}>
                      <Col xs={12}>
                        {errorFormQuestion && (
                          <div className="mt-0">
                            <FormAlert
                              variant="danger"
                              data={errorFormQuestion}
                            />
                          </div>
                        )}
                      </Col>
                      <Col md="12" className="mb-4">
                        <Form.Group controlId="title">
                          <Form.Label>Titre :</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Titre de la question"
                            value={formQuestionData.title}
                            onChange={handleFieldQuestionChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12" className="mb-4">
                        <Form.Group controlId="title">
                          <Form.Label>Explications si juste :</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="correct_answer_explanation"
                            value={
                              formQuestionData.correct_answer_explanation || ''
                            }
                            rows={3}
                            onChange={handleFieldQuestionChange}
                          />
                        </Form.Group>
                      </Col>
                      <div className="d-block mt-3">
                        {selectedQuestion.id && (
                          <div className="float-start">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Archiver la question (définitif)
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                className="me-0 float-start"
                                type="button"
                                onClick={() => {
                                  deleteQuestion(selectedQuestion.id);
                                  setShowManageQuestion(false);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  style={{
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                    fill="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )}
                        <div className="float-end">
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-2"
                            onClick={() => {
                              setShowManageQuestion(false);
                              setSelectedQuestion({});
                            }}
                          >
                            Annuler
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                              style={{
                                marginBottom: '2px',
                                marginRight: '4px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Enregistrer
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={showManageAnswer}
                  onHide={() => {
                    setShowManageAnswer(false);
                    setSelectedAnswer({});
                  }}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <h5 className="admin-title">
                      {selectedAnswer.id ? 'Modifier la' : 'Ajouter une'}{' '}
                      réponse
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleAnswerSubmit}>
                      <Col xs={12}>
                        {errorFormAnswer && (
                          <div className="mt-0">
                            <FormAlert
                              variant="danger"
                              data={errorFormAnswer}
                            />
                          </div>
                        )}
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="name">
                          <Form.Label>Label :</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Label de la réponse"
                            value={formAnswerData.title}
                            onChange={handleFieldAnswerChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12" className="mt-3">
                        <Form.Group controlId="is_visible">
                          <Form.Label>Est juste ?</Form.Label>
                          <div className="d-block"></div>
                          <Form.Check
                            inline
                            type="radio"
                            id="is_right_0"
                            value="0"
                            label="Non"
                            name="is_right"
                            defaultChecked={formAnswerData.is_right === 0}
                            onChange={handleFieldAnswerChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            id="is_right_1"
                            value="1"
                            label="Oui"
                            name="is_right"
                            defaultChecked={formAnswerData.is_right === 1}
                            onChange={handleFieldAnswerChange}
                          />
                        </Form.Group>
                      </Col>
                      <div className="d-block mt-3">
                        {selectedAnswer.id && (
                          <div className="float-start">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Archiver la réponse (définitif)
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                className="me-0 float-start"
                                type="button"
                                onClick={() => {
                                  deleteAnswer(selectedAnswer.id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  style={{
                                    marginBottom: '2px'
                                  }}
                                >
                                  <path
                                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                    fill="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )}
                        <div className="float-end">
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-2"
                            onClick={() => {
                              setShowManageAnswer(false);
                              setSelectedAnswer({});
                            }}
                          >
                            Annuler
                          </Button>
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="submit"
                            disabled={isLoading}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                              style={{
                                marginBottom: '2px',
                                marginRight: '4px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Enregistrer
                          </Button>
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    setShowManageQuestions(false);
                  }}
                >
                  Fermer
                </Button>
              </Offcanvas.Body>
            </Offcanvas>
          </Form>
        </>
      )}
    </>
  );
};

export default ManageCourses;
