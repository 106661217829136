import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Modal,
  Form,
  Alert,
  Row,
  Tooltip,
  OverlayTrigger,
  Offcanvas,
  Table
} from 'react-bootstrap';
import FalconCloseButton from '../../../../common/FalconCloseButton';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { IndexSurveyQuestionType } from '../../../../../services/Survey/QuestionTypeService';
import { IndexSurveyDunningType } from '../../../../../services/Survey/DunningTypeService';
import {
  DeleteSurveyTemplate,
  IndexSurveyTemplate,
  PostSurveyTemplate,
  PutSurveyTemplate
} from '../../../../../services/Survey/SurveyTemplateService';
import FormAlert from '../../../commons/FormAlert';
import FileUploader from '../../../commons/fileUploader';
import SoftBadge from '../../../../common/SoftBadge';
import ManageSurveyModalQuestion from './ManageModalQuestion';
import moment from 'moment/moment';
import { Editor } from '@tinymce/tinymce-react';
import { DeleteSurveyQuestionGroup } from '../../../../../services/Survey/SurveyService';
import { Circle } from 'react-color/lib/components/circle/Circle';

const ManageSurveyModal = ({
  showFormSurvey,
  setShowFormSurvey,
  questions,
  setQuestions,
  survey,
  setSurvey,
  recipients,
  isQuestionnaire,
  questionGroups,
  setQuestionGroups,
  event
}) => {
  const [trigger, setTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [dunningTypes, setDunningTypes] = useState([]);
  const [showExportTemplate, setShowExportTemplate] = useState(false);
  const [showImportTemplate, setShowImportTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [surveyTemplates, setSurveyTemplates] = useState([]);
  const [errorForm, setErrorForm] = useState(false);

  const handleEditorChange = content => {
    setSurvey(prevState => ({
      ...prevState,
      description: content
    }));
  };

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      const responseObjects = await IndexSurveyQuestionType();
      if (responseObjects.success === true) {
        setQuestionTypes(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchDunningTypes = async () => {
      const responseObjects = await IndexSurveyDunningType();
      if (responseObjects.success === true) {
        setDunningTypes(responseObjects.data.reverse());
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchSurveyTemplates = async () => {
      const responseObjects = await IndexSurveyTemplate();
      if (responseObjects.success === true) {
        setSurveyTemplates(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchQuestionTypes();
    fetchDunningTypes();
    fetchSurveyTemplates();
  }, [trigger]);

  const handleGroupFieldChange = (e, index) => {
    const newArray = questionGroups.map((item, i) => {
      if (index === i) {
        if (e.target) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        } else if (e.hex) {
          return {
            ...item,
            color: e.hex
          };
        }
      } else {
        return item;
      }
    });
    setQuestionGroups(newArray);
  };

  let today = new Date();
  let todayPlus30days = new Date();
  todayPlus30days.setDate(today.getDate() + 30);

  const [templateFormData, setTemplateFormData] = useState({
    title: '',
    survey_dunning_type_id: ''
  });

  const handleSubmitTemplate = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let tmpTemplateFormData = templateFormData;
    tmpTemplateFormData.questions = questions;
    tmpTemplateFormData.questionGroups = questionGroups;
    tmpTemplateFormData.title = templateFormData.title;
    tmpTemplateFormData.description = survey.description;
    tmpTemplateFormData.vimeo_id = survey.vimeo_id;
    tmpTemplateFormData.survey_dunning_type_id = survey.survey_dunning_type_id;
    tmpTemplateFormData.attachments = survey.attachments;
    let response = false;
    if (selectedTemplate.id) {
      response = await PutSurveyTemplate(
        selectedTemplate.id,
        tmpTemplateFormData
      );
    } else {
      response = await PostSurveyTemplate(tmpTemplateFormData);
    }
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      toast('Modèle enregistré.');
      setTrigger(Date.now());
      setShowExportTemplate(false);
      setShowFormSurvey(true);
      setSelectedTemplate(response.data);
      setTemplateFormData({
        title: response.data.title,
        description: response.data.description,
        survey_dunning_type_id: ''
      });
    }
  };

  const deleteTemplate = async template => {
    const response = await DeleteSurveyTemplate(template.id);
    if (
      selectedTemplate &&
      selectedTemplate.id &&
      selectedTemplate.id === template.id
    ) {
      setSelectedTemplate({});
    }
    if (response.success === true) {
      toast('Modèle supprimé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {showFormSurvey && (
        <Modal
          backdrop="static"
          keyboard={false}
          size="xl"
          show={showFormSurvey}
          onHide={() => setShowFormSurvey(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Col xs={2}>
              <b>Formulaire</b>
            </Col>
            <Col className="text-end" xs={9}>
              {(questions.length > 0 || questionGroups.length > 0) && (
                <>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="ms-0 me-0"
                    onClick={() => {
                      setShowFormSurvey(false);
                      setShowExportTemplate(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      {selectedTemplate.id ? (
                        <path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" />
                      ) : (
                        <path d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z" />
                      )}
                    </svg>
                    {selectedTemplate.id
                      ? 'Mettre à jour le modèle'
                      : 'Enregistrer comme modèle'}
                  </Button>
                  {selectedTemplate.id && (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="ms-3"
                      onClick={() => {
                        setShowFormSurvey(false);
                        setSelectedTemplate({});
                        setTemplateFormData({
                          name: '',
                          description: ''
                        });
                        setShowExportTemplate(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z" />
                      </svg>
                      Enregistrer comme nouveau modèle
                    </Button>
                  )}
                </>
              )}
              {surveyTemplates.length > 0 && (
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="ms-3"
                  onClick={() => {
                    setShowFormSurvey(false);
                    setShowImportTemplate(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8zm1-8h3l-4 4-4-4h3V8h2v4z" />
                  </svg>
                  {selectedTemplate.id
                    ? `Importer un autre modèle`
                    : `Importer un modèle`}
                </Button>
              )}
            </Col>
            <FalconCloseButton
              className="me-3"
              onClick={() => setShowFormSurvey(false)}
            />
          </Modal.Header>

          <Modal.Body>
            <Row className="">
              <Col md="12" className="mt-0 mb-3">
                <Form.Group controlId="description">
                  <Form.Label>
                    Description
                    <small> en-tête du formulaire - facultatif</small>
                  </Form.Label>
                  <Editor
                    value={survey.description}
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    init={{
                      browser_spellcheck: true,
                      contextmenu: false,
                      language: 'fr_FR',
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'link'
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'link',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                    onEditorChange={handleEditorChange}
                  />{' '}
                </Form.Group>
              </Col>
              <Col md="12" className="">
                <Form.Group controlId="vimeo_id">
                  <Form.Label>
                    Identifiant Viméo{' '}
                    <small>en-tête du formulaire - facultatif</small>
                  </Form.Label>
                  <Form.Control
                    required={true}
                    type="number"
                    onWheel={e => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    name="vimeo_id"
                    placeholder="324563412"
                    value={survey.vimeo_id || ''}
                    onChange={e => {
                      setSurvey({
                        ...survey,
                        vimeo_id: e.target.value
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="pb-2 pt-3 mb-3 mt-2">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>
            <>
              {questions.length > 0 ? (
                <>
                  {questions.map((question, index) => (
                    <ManageSurveyModalQuestion
                      key={index}
                      question={question}
                      questionGroups={questionGroups}
                      setQuestionGroups={setQuestionGroups}
                      index={index}
                      questions={questions}
                      setQuestions={setQuestions}
                      questionTypes={questionTypes}
                      event={event}
                    />
                  ))}
                </>
              ) : (
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="mt-2 mb-3"
                  onClick={() => {
                    const newArray = [...questions];
                    newArray.push({
                      title: '',
                      required: 1,
                      type_slug: '',
                      isEditable: true,
                      answers: []
                    });
                    setQuestions(newArray);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                      fill="rgba(77,89,105,1)"
                    />
                  </svg>{' '}
                  Ajouter une question
                </Button>
              )}
              {questionGroups.length > 0 && (
                <>
                  {questionGroups.map((group, index) => (
                    <Card
                      key={index}
                      className="mb-3 border-5"
                      style={group.color ? { borderColor: group.color } : {}}
                      id={`group-${index}`}
                    >
                      <SoftBadge variant="primary" className="p-2 text-start">
                        {group.title}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          className="float-end cursor-pointer"
                          onClick={() => {
                            if (group.id) {
                              DeleteSurveyQuestionGroup(group.id);
                            }

                            const newArray = [...questionGroups];
                            newArray.splice(index, 1);
                            setQuestionGroups(newArray);
                          }}
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                            fill="rgba(229,47,18,1)"
                          />
                        </svg>
                      </SoftBadge>
                      <Card.Body>
                        <Form.Label className="w-100 d-block">
                          Nom de la rubrique :
                          {questionGroups.length > 1 && (
                            <span className="float-end">
                              {index !== 0 && (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const newArray = [...questionGroups];
                                    const temp = newArray[index];
                                    newArray[index] = newArray[index - 1];
                                    newArray[index - 1] = temp;
                                    setQuestionGroups(newArray);
                                    setTimeout(() => {
                                      document
                                        .getElementById(`group-${index - 1}`)
                                        .scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                    }, 100);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                                  </svg>
                                </span>
                              )}
                              {index !== questionGroups.length - 1 && (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    const newArray = [...questionGroups];
                                    const temp = newArray[index];
                                    newArray[index] = newArray[index + 1];
                                    newArray[index + 1] = temp;
                                    setQuestionGroups(newArray);
                                    setTimeout(() => {
                                      document
                                        .getElementById(`group-${index + 1}`)
                                        .scrollIntoView({
                                          behavior: 'smooth'
                                        });
                                    }, 100);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                                  </svg>
                                </span>
                              )}
                            </span>
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Nom de la rubrique"
                          value={group.title || ''}
                          name="title"
                          onChange={e => {
                            handleGroupFieldChange(e, index);
                          }}
                        />
                        <div className="d-block mt-3"></div>
                        <Circle
                          color={group.color}
                          onChange={e => {
                            handleGroupFieldChange(e, index);
                          }}
                          width="100%"
                        />
                        <div className="d-block mt-2"></div>
                        {group.color === '' ? (
                          <></>
                        ) : (
                          <Form.Label className="mb-3">
                            Couleur sélectionnée :
                            <span
                              className="rounded-circle d-inline-block"
                              style={{
                                backgroundColor: group.color,
                                height: '28px',
                                width: '28px',
                                position: 'relative',
                                top: '9px',
                                left: '5px'
                              }}
                            ></span>
                          </Form.Label>
                        )}
                        <Row>
                          {group.questions && group.questions.length > 0 && (
                            <Col xs={12} className="mt-3">
                              {group.questions.map(
                                (question, indexQuestion) => (
                                  <ManageSurveyModalQuestion
                                    group={group}
                                    questionGroups={questionGroups}
                                    setQuestionGroups={setQuestionGroups}
                                    questionTypes={questionTypes}
                                    key={indexQuestion}
                                    question={question}
                                    index={indexQuestion}
                                    indexGroup={index}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    event={event}
                                  />
                                )
                              )}
                            </Col>
                          )}
                          <Col xs={12} className="mt-2">
                            {(!group.questions ||
                              group.questions.length === 0) && (
                              <Button
                                size="sm"
                                variant="falcon-default"
                                className="mt-2 me-2"
                                onClick={() => {
                                  const newQuestion = {
                                    title: '',
                                    required: 1,
                                    type_slug: '',
                                    isEditable: true,
                                    answers: []
                                  };
                                  let newArray = [...questionGroups];
                                  if (!newArray[index].questions) {
                                    newArray[index].questions = [];
                                  }
                                  newArray[index].questions.push(newQuestion);
                                  setQuestionGroups(newArray);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                  style={{
                                    marginBottom: '2px',
                                    marginRight: '2px'
                                  }}
                                >
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path
                                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                    fill="rgba(77,89,105,1)"
                                  />
                                </svg>{' '}
                                Ajouter une question à la rubrique
                              </Button>
                            )}
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="mt-2 ms-0"
                              onClick={() => {
                                // duplicate group and questions
                                const newGroup = {
                                  title: group.title,
                                  questions: []
                                };
                                group.questions.forEach(question => {
                                  newGroup.questions.push({
                                    title: question.title,
                                    required: question.required,
                                    type_slug: question.type_slug,
                                    answers: question.answers
                                  });
                                });
                                let newArray = [...questionGroups];
                                newArray.splice(index + 1, 0, newGroup);
                                setQuestionGroups(newArray);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                                  fill="rgba(77,89,105,1)"
                                />
                              </svg>{' '}
                              Dupliquer la rubrique
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                </>
              )}
              <Button
                size="sm"
                variant="falcon-default"
                className="mt-0 mb-3 d-block"
                onClick={() => {
                  const newArray = [...questionGroups];
                  newArray.push({
                    title: '',
                    description: ''
                  });
                  setQuestionGroups(newArray);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(77,89,105,1)"
                  />
                </svg>{' '}
                Ajouter une rubrique de questions
              </Button>
            </>

            <Row>
              <Col lg="12" className="pb-2 pt-3 mb-2 mt-3">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>

            <Col>
              <Form.Group controlId="startDate">
                <Row className="mt-2">
                  <Form.Label>
                    {' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Date d'expiration du formulaire{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Après cette date, les destinataires ne pourront plus
                          accéder au formulaire.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ position: 'relative', bottom: '1px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <Col xs={3}>
                    <DatePicker
                      name="expires_on"
                      dateFormat="dd/MM/yyyy"
                      locale="fr"
                      selected={survey.expires_on}
                      onChange={date => {
                        setSurvey({
                          ...survey,
                          expires_on: date
                        });
                      }}
                      formatWeekDay={startDate => startDate.slice(0, 3)}
                      className="form-control"
                      placeholderText="Date d'expiration"
                      value={survey.expires_on}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Row>
              <Col lg="12" className="pb-2 pt-3 mb-2">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>

            <Col>
              <Form.Group controlId="startDate">
                <Row className="mt-2">
                  <Form.Label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Envoyer le formulaire à une date ultérieure ?{' '}
                    <label style={{ position: 'relative', top: '2px' }}>
                      <input
                        type="checkbox"
                        name="send_later"
                        value="1"
                        className="ms-1"
                        checked={
                          survey.send_later === 1 || survey.send_later === '1'
                        }
                        onChange={e => {
                          setSurvey({
                            ...survey,
                            send_later: e.target.checked ? 1 : 0,
                            starts_on: Date.now()
                          });
                        }}
                      />
                    </label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Par défaut, le formulaire est envoyé aux destinataires
                          lors de l’enregistrement de l’événement. Spécifiez une
                          date pour différer l’envoi du formulaire aux
                          destinataires.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        className="ms-1"
                        style={{ position: 'relative', bottom: '1px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                </Row>
                {(survey.send_later === 1 || survey.send_later === '1') && (
                  <Row className="mb-3">
                    <Col xs={3}>
                      <DatePicker
                        name="starts_on"
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                        selected={survey.starts_on}
                        onChange={date => {
                          setSurvey({
                            ...survey,
                            starts_on: date
                          });
                        }}
                        formatWeekDay={startDate => startDate.slice(0, 3)}
                        className="form-control"
                        placeholderText="Date d'envoi"
                        value={survey.starts_on}
                      />
                    </Col>
                  </Row>
                )}
              </Form.Group>
            </Col>

            <Row>
              <Col lg="12" className="pb-2 pt-2 mb-2">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>
            <Col>
              <Form.Group>
                <Row>
                  <Form.Label className="mb-0 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M10 3h4a8 8 0 1 1 0 16v3.5c-5-2-12-5-12-11.5a8 8 0 0 1 8-8zm2 14h2a6 6 0 1 0 0-12h-4a6 6 0 0 0-6 6c0 3.61 2.462 5.966 8 8.48V17z"
                        fill="rgba(94,110,130,1)"
                      />
                    </svg>{' '}
                    Rappels automatiques ?{' '}
                    <label style={{ position: 'relative', top: '2px' }}>
                      <input
                        type="checkbox"
                        name="survey_dunning"
                        value="1"
                        className="ms-1"
                        checked={
                          survey.survey_dunning === 1 ||
                          survey.survey_dunning === '1'
                        }
                        onChange={e => {
                          e.target.checked
                            ? setSurvey({
                                ...survey,
                                survey_dunning: e.target.checked ? 1 : 0,
                                survey_dunning_type_id: dunningTypes[0].id
                              })
                            : setSurvey({
                                ...survey,
                                survey_dunning: e.target.checked ? 1 : 0,
                                survey_dunning_type_id: ''
                              });
                        }}
                      />
                    </label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Par défaut, le formulaire est envoyé une seule fois
                          aux destinataires de l'événement. Choisissez une
                          fréquence ci-dessous pour activer les relances
                          automatiques jusqu'à la date d'expiration du
                          formulaire.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        className="ms-1"
                        style={{ position: 'relative', bottom: '1px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                </Row>
                {(survey.survey_dunning === 1 ||
                  survey.survey_dunning === '1') && (
                  <Row className="mb-3">
                    <Col xs={4} className="pe-1">
                      <Form.Label className="">Fréquence :</Form.Label>
                      <Form.Select
                        name="survey_dunning_type_id"
                        value={survey.survey_dunning_type_id}
                        onChange={e => {
                          setSurvey({
                            ...survey,
                            survey_dunning_type_id: e.target.value
                          });
                        }}
                      >
                        {/*<option value="">Choisir...</option>*/}
                        {dunningTypes.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xs={4} className="px-1">
                      <Form.Label className="">À partir du :</Form.Label>
                      <DatePicker
                        name="dunning_starts_on"
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                        selected={survey.dunning_starts_on}
                        onChange={date => {
                          setSurvey({
                            ...survey,
                            dunning_starts_on: date
                          });
                        }}
                        formatWeekDay={startDate => startDate.slice(0, 3)}
                        className="form-control"
                        placeholderText="À partir du"
                        value={survey.dunning_starts_on}
                      />
                    </Col>
                    <Col xs={4} className="ps-1">
                      <Form.Label className="">
                        Email en copie des relances{' '}
                        <small>laisser vide pour désactiver</small> :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Adresse email"
                        name="dunning_admin_copy_email"
                        value={survey.dunning_admin_copy_email}
                        onChange={e => {
                          setSurvey({
                            ...survey,
                            dunning_admin_copy_email: e.target.value
                          });
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </Form.Group>
            </Col>
            {(survey.survey_dunning === 1 || survey.survey_dunning === '1') && (
              <>
                <Row>
                  <Col lg="12" className="pb-2 pt-2 mb-2">
                    <div className="border-dashed-bottom"></div>
                  </Col>
                </Row>
                <Col>
                  <Form.Group>
                    <Row>
                      <Form.Label className="mb-0 mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                            fill="rgba(94,110,130,1)"
                          />
                        </svg>{' '}
                        Calcul des points automatique ?{' '}
                        <label style={{ position: 'relative', top: '2px' }}>
                          <input
                            type="checkbox"
                            name="survey_dunning"
                            value="1"
                            className="ms-1"
                            checked={
                              survey.is_counts_for_points === 1 ||
                              survey.is_counts_for_points === '1'
                            }
                            onChange={e => {
                              setSurvey({
                                ...survey,
                                is_counts_for_points: e.target.checked ? 1 : 0
                              });
                            }}
                          />
                        </label>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              En cochant cette case, les destinataires du
                              formulaire qui répondront après X relances seront
                              automatiquement pénalisés via le système de
                              points.
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            className="ms-1"
                            style={{ position: 'relative', bottom: '1px' }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                        </OverlayTrigger>
                      </Form.Label>
                    </Row>
                  </Form.Group>
                </Col>
              </>
            )}
            <>
              <Row>
                <Col lg="12" className="pb-2 pt-2 mb-2">
                  <div className="border-dashed-bottom"></div>
                </Col>
              </Row>
              <Col xs={12}>
                <Form.Label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="17"
                    height="17"
                    style={{ marginRight: '4px', marginBottom: '2px' }}
                  >
                    <path
                      d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784L18 17a6 6 0 0 0-11.996-.225L6 17v1.978a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                  Ajouter des pièces jointes <small>facultatif</small>{' '}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="overlay-trigger-example">
                        Ces pièces jointes seront téléchargeables par les
                        destinataires sur le formulaire.
                      </Tooltip>
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                        fill="rgba(100,99,99,1)"
                      />
                    </svg>
                  </OverlayTrigger>
                </Form.Label>
                {survey.attachments && (
                  <>
                    {survey.attachments.map((attachment, index) => (
                      <Row key={index} className="align-items-center">
                        <Col xs={5} className="pt-2">
                          <FileUploader
                            pathToUpload="surveys/notifications"
                            formData={survey}
                            setFormData={setSurvey}
                            format="any"
                            databaseField="attachment_url"
                            databaseFieldArray="attachments"
                            databaseFieldArrayIndex={index}
                            cropperEnabled={false}
                            progressBar={true}
                            currentImage={attachment.attachment_url || null}
                          />
                        </Col>
                        <Col xs={5} className="pt-2 px-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            className="cursor-pointer"
                            onClick={() => {
                              const newAttachments = survey.attachments.filter(
                                (attachment, i) => i !== index
                              );
                              setSurvey({
                                ...survey,
                                attachments: newAttachments
                              });
                            }}
                          >
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                              fill="#e52f12"
                            />
                          </svg>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
                <Row>
                  <Col xs={12} className="pt-2">
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="mt-2 mb-3"
                      onClick={() => {
                        let tmpSurvey = survey;
                        if (!tmpSurvey.attachments) {
                          tmpSurvey.attachments = [];
                        }
                        tmpSurvey.attachments.push({
                          file_url: ''
                        });
                        setSurvey(tmpSurvey);
                        setTrigger(Date.now());
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                          fill="rgba(77,89,105,1)"
                        />
                      </svg>{' '}
                      Ajouter une pièce jointe
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          </Modal.Body>

          <Modal.Footer className="text-start">
            <Button
              size="sm"
              variant="primary"
              className="admin-primary m-lg-2"
              onClick={() => {
                setShowFormSurvey(false);
                //toast('Formulaire enregistré.');
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>{' '}
              Enregistrer le formulaire
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {surveyTemplates.length > 0 && (
        <Offcanvas
          show={showImportTemplate}
          onHide={() => {
            setShowFormSurvey(true);
            setShowImportTemplate(false);
          }}
          placement={'end'}
          style={{ width: '900px' }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Importer un modèle</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Alert variant="warning">
              Choisir un modèle ci-dessous écrasera votre formulaire actuel.
            </Alert>
            <Table responsive className="table-stats-tracks">
              <thead>
                <tr>
                  <th scope="col">Modèle</th>
                  <th scope="col">Auteur</th>
                  <th scope="col">Création</th>
                  <th scope="col">Dern. modification</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {surveyTemplates.map((template, index) => (
                  <tr key={index}>
                    <td>{template.title}</td>
                    <td>
                      {template.creator?.id && (
                        <>
                          {template.creator.firstname}{' '}
                          {template.creator.lastname}
                        </>
                      )}
                    </td>
                    <td>
                      {moment(template.created_at).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      {moment(template.updated_at).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      <a
                        onClick={() => {
                          setSurvey({
                            ...survey,
                            title: template.title,
                            expires_on: todayPlus30days,
                            starts_on: Date.now(),
                            send_later: 0,
                            survey_dunning: template.survey_dunning_type_id
                              ? 1
                              : 0,
                            survey_dunning_type_id:
                              template.survey_dunning_type_id
                          });

                          setSelectedTemplate(template);
                          setTemplateFormData({
                            ...templateFormData,
                            title: template.title
                          });

                          let attachments = [];
                          template.attachments?.map(attachment => {
                            attachments.push({
                              attachment_url: attachment.file_url
                            });
                          });

                          setSurvey({
                            ...survey,
                            description: template.description,
                            vimeo_id: template.vimeo_id,
                            attachments: attachments
                          });

                          setTrigger(Date.now());

                          let questions = [];
                          template.questions
                            ?.filter(q => !q.group_id)
                            .map((question, index) => {
                              questions.push({
                                title: question.title,
                                required: question.required,
                                type_slug: question.type.slug,
                                isEditable: !(
                                  isQuestionnaire !== true && index === 0
                                ),
                                answers: question.answers?.map(a => a.title)
                              });
                            });
                          let tmpGroups = [];
                          template.question_groups?.map(group => {
                            tmpGroups.push({
                              title: group.title,
                              color: group.color,
                              description: group.description,
                              questions: template.questions
                                ?.filter(q => q.group_id === group.id)
                                .map(q => ({
                                  title: q.title,
                                  required: q.required,
                                  type_slug: q.type.slug,
                                  isEditable: true,
                                  answers: q.answers?.map(a => a.title)
                                }))
                            });
                          });
                          setQuestions(questions);
                          setQuestionGroups(tmpGroups);
                          setShowFormSurvey(true);
                          setShowImportTemplate(false);
                        }}
                        className="cursor-pointer"
                      >
                        Choisir
                      </a>
                    </td>
                    <td>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="overlay-trigger-example">
                            Supprimer le modèle
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className="cursor-pointer"
                          onClick={() => {
                            deleteTemplate(template);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '1px',
                              marginLeft: '3px'
                            }}
                          >
                            <path
                              d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                              fill="#e63757"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowImportTemplate(false);
                setShowFormSurvey(true);
              }}
            >
              Annuler
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <Offcanvas
        show={showExportTemplate}
        onHide={() => {
          setShowExportTemplate(false);
          setShowFormSurvey(true);
          setErrorForm(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Enregistrer un modèle</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && <FormAlert variant="danger" data={errorForm} />}
          <Form.Group controlId="title">
            <Form.Label>Titre du modèle</Form.Label>
            <Form.Control
              value={templateFormData.title || ''}
              type="text"
              name="title"
              placeholder="Titre"
              onChange={e => {
                setTemplateFormData({
                  ...templateFormData,
                  title: e.target.value
                });
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="admin-primary mt-3"
            size="sm"
            disabled={isLoading}
            onClick={handleSubmitTemplate}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                fill="rgba(255,255,255,1)"
              />
            </svg>{' '}
            {selectedTemplate.id
              ? `Mettre à jour le modèle`
              : `Enregistrer le modèle`}
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageSurveyModal;

ManageSurveyModal.propTypes = {
  showFormSurvey: PropTypes.any,
  setShowFormSurvey: PropTypes.any,
  questions: PropTypes.array,
  setQuestions: PropTypes.func,
  questionGroups: PropTypes.array,
  setQuestionGroups: PropTypes.func,
  survey: PropTypes.object,
  setSurvey: PropTypes.func,
  handleChange: PropTypes.func,
  register: PropTypes.any,
  setValue: PropTypes.any,
  recipients: PropTypes.array,
  isQuestionnaire: PropTypes.bool,
  event: PropTypes.bool
};
