import React, { useEffect, useRef, useState } from 'react';
import '../../../../../assets/scss/admin.scss';
import {
  Button,
  Form,
  Card,
  Collapse,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Alert,
  Spinner,
  ButtonGroup,
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import * as Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import DropdownFilter from 'components/common/DropdownFilter';
import DatePicker from 'react-datepicker';
import FormAlert from '../../../commons/FormAlert';
import hours from '../../../commons/data/hours';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import {
  ExportEvents,
  ExportEventsFromHome,
  IndexEventMember,
  PostEvent
} from '../../../../../services/Event/EventService';
import CalendarEventModal from './EventModalMember';
import moment from 'moment/moment';
import IconButton from '../../../../common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import IconAlert from '../../../../common/IconAlert';

const CalendarMember = () => {
  const calendarRef = useRef();
  const [modalEventContent, setModalEventContent] = useState({});
  //const [setScheduleStartDate] = useState();
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Changer de vue');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState('');
  const [formSearch, setFormSearch] = useState({
    tredunion: 0,
    pole: 0,
    formations: 0,
    reunions: 0,
    webinars: 0
  });

  const [calendarChangeTrigger, setCalendarChangeTrigger] = useState(0);

  const handleCalendarChange = () => {
    // Cela incrémente l'état, déclenchant l'effet de réappliquer les filtres
    setCalendarChangeTrigger(prev => prev + 1);
  };

  let today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [formData, setFormData] = useState({
    context: 'members.home',
    title: '',
    description: '',
    start_date: Date.now(),
    end_date: Date.now(),
    start_time: '14:00',
    end_time: '18:00',
    link: '',
    location_label: '',
    location_lat: '',
    location_lng: '',
    start_is_all_day: 0
  });
  const [value, setValue] = useState(null);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    //fields: ['address_components', 'geometry', 'icon', 'name']
  };

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const viewName = [
    'Affichage par mois',
    'Vue semaine',
    'Vu journée',
    'Vue liste'
  ];

  /*const exportEvents = async () => {
    setExportIsLoading(true);
    const responseExport = await ExportEventsFromHome();
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };*/

  const exportEvents = async year => {
    setExportIsLoading(true);
    const responseExport = await ExportEventsFromHome(
      year,
      new URLSearchParams(formSearch).toString()
    );
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleEventClick = info => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      setModalEventContent(info);
      setIsOpenEventModal(true);
    }
  };

  const handleFieldChange = e => {
    if (e.target.name === 'start_is_all_day') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0,
        end_date: e.target.checked ? formData.end_date : formData.start_date,
        start_time: e.target.checked ? '00:00' : '14:00',
        end_time: e.target.checked ? '23:00' : '18:00'
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let tmpFormData = {
      ...formData
    };
    tmpFormData.start_date = Moment(formData.start_date)
      .local()
      .format('YYYY-MM-DD');
    tmpFormData.end_date = Moment(formData.end_date)
      .local()
      .format('YYYY-MM-DD');
    let response = await PostEvent(tmpFormData);

    setSuccessForm(false);
    setErrorForm(response.validator);

    if (response.success === true) {
      setIsLoading(false);
      setTimeout(function () {
        document.getElementById('events').scrollIntoView();
      }, 250);
      setSuccessForm(true);
    } else if (!response.validator) {
      setIsLoading(false);
      toast('Une erreur est survenue, veuillez réesayer plus tard');
    }
  };
  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Affichage par mois':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Vue semaine':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Vu journée':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Vue liste':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
    handleCalendarChange();
  };

  function hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  }

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      setFormData({
        ...formData,
        location_label: place.formatted_address,
        location_lat: place.geometry.location.lat(),
        location_lng: place.geometry.location.lng()
      });
    });
    setValue(null);

    setCalendarApi(calendarRef.current.getApi());

    const fetchEventMember = async () => {
      const responseEventMember = await IndexEventMember();
      if (responseEventMember.success === true) {
        let eventArray = [];
        let tmpCategories = [];
        responseEventMember.data.map(event => {
          eventArray.push({
            id: event.id,
            category: event.category,
            title:
              event.category && event.category.is_holiday === 1
                ? ''
                : event.title,
            className:
              event.category && event.category.is_holiday === 1
                ? 'holidays'
                : '',
            start: `${event.start_date}`,
            time: event.start_time,
            end: event.start_is_all_day
              ? `${moment(event.end_date).add(1, 'd').format('YYYY-MM-DD')} ${
                  event.end_time
                }`
              : `${event.end_date} ${event.end_time}`,
            description: event.description,
            attachments: event.attachments,
            backgroundColor: event.category ? event.category.color : '#ccf2e1',
            textColor: event.category
              ? hex_is_light(event.category.color)
                ? '#000000'
                : '#FFFFFF'
              : '#FFFFFF',
            allDay: true,
            location_lat: event.location_lat,
            location_lng: event.location_lng,
            location: event.location_label,
            organizer: event.company?.name,
            organizerId: event.company?.id,
            start_is_all_day: event.start_is_all_day,
            link: event.link,
            originalEvent: event
          });
          //eventArray.push(event);
          if (
            event.category &&
            tmpCategories.filter(
              c => parseInt(event.category.id) === parseInt(c.id)
            ).length === 0
          ) {
            tmpCategories.push(event.category);
          }
        });
        setEvents(eventArray);
        setCategories(tmpCategories);
      } else {
        toast('une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchEventMember();
  }, [value]);

  useEffect(() => {
    let tredunionActive = false;
    let poleActive = false;
    let formationActive = false;
    let reunionActive = false;
    let webinarsActive = false;

    let eventsFiltered = events.filter(event => {
      const categoryName = event.category?.name.toUpperCase();
      const isHoliday = event.category?.is_holiday === 0;
      const isPublicHoliday = event.category?.is_public_holiday === 0;

      // Déterminez si chaque filtre est actif
      tredunionActive = formSearch.tredunion === 1;
      poleActive = formSearch.pole === 1;
      formationActive = formSearch.formations === 1;
      reunionActive = formSearch.reunions === 1;
      webinarsActive = formSearch.webinars === 1;

      // Appliquez la logique de filtrage en fonction des combinaisons de filtres
      if (tredunionActive && formationActive) {
        // Exclure "POLE" si "Tred Union" et "Formation" sont cochés
        return (
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday &&
          categoryName.includes('FORMATION')
        );
      } else if (tredunionActive && webinarsActive) {
        return (
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday &&
          categoryName.includes('WEBINAIRE')
        );
      } else if (tredunionActive && reunionActive) {
        // Toutes les réunions sauf celles de "POLE"
        return (
          !categoryName.includes('POLE') && categoryName.includes('REUNION')
        );
      } else if (poleActive && formationActive) {
        // Supposons que "FORMATION" chez "POLE" n'existe pas, donc aucun événement n'est retourné
        return false;
      } else if (poleActive && reunionActive) {
        // Afficher uniquement les événements de "POLE" (réunions)
        return (
          categoryName.includes('POLE') && categoryName.includes('REUNION')
        );
      } else {
        // Logique de filtrage par défaut si aucune des combinaisons spécifiques n'est sélectionnée
        const matchesTredunion =
          tredunionActive &&
          !categoryName.includes('POLE') &&
          isHoliday &&
          isPublicHoliday;
        const matchesPole = poleActive && categoryName.includes('POLE');
        const matchesFormation =
          formationActive && categoryName.includes('FORMATION');
        const matchesReunion =
          reunionActive && categoryName.includes('REUNION');
        const matchesWebinars =
          webinarsActive && categoryName.includes('WEBINAIRE');

        return (
          matchesTredunion ||
          matchesPole ||
          matchesFormation ||
          matchesReunion ||
          matchesWebinars
        );
      }
    });

    if (calendarApi.currentDataManager) {
      if (
        !tredunionActive &&
        !poleActive &&
        !formationActive &&
        !reunionActive &&
        !webinarsActive
      ) {
        calendarApi
          .getEventSources()
          .forEach(eventSource => eventSource.remove());
        calendarApi.addEventSource(events);
      } else {
        calendarApi
          .getEventSources()
          .forEach(eventSource => eventSource.remove());
        calendarApi.addEventSource(eventsFiltered);
      }
    }
  }, [formSearch, calendarChangeTrigger]);

  return (
    <>
      <Row id="events">
        <Col xs={6}>
          <h5 className="mb-3 mb-lg-4 redHeading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                fill="rgba(229,47,18,1)"
              />
            </svg>{' '}
            Événement à venir
          </h5>
        </Col>
        <Col xs={6} className="text-end">
          {!successForm && (
            <Button
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              variant="primary"
              className="admin-primary ms-3"
              size="sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path
                  d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              Soumettre un événement à Tred Union
            </Button>
          )}
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        {!successForm ? (
          <>
            {open && (
              <Card className="mb-4">
                <Card.Body className="overflow-hidden px-lg-1 pb-2">
                  <Row className="justify-content-between align-items-center">
                    <Col xs="12">
                      <h5 className="ms-3 text-primary admin-title mb-0">
                        Soumettre un événement à Tred Union
                      </h5>
                    </Col>
                    {!open && (
                      <Col xs="auto" className="me-3 d-flex order-md-3"></Col>
                    )}
                  </Row>
                  <Collapse in={open}>
                    <div className="rounded">
                      <div className="p-card">
                        {errorForm && (
                          <div className="mt-3">
                            <FormAlert variant="danger" data={errorForm} />
                          </div>
                        )}
                        {successForm && !errorForm && (
                          <div className="mt-3">
                            <FormAlert
                              className="mt-3"
                              variant="success"
                              data={successForm}
                            />
                          </div>
                        )}

                        <div>
                          <Row>
                            <Col lg="12">
                              <Form.Group controlId="name">
                                <Form.Label>Titre de l'événement</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="title"
                                  placeholder="Titre"
                                  value={formData.title || ''}
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                              <Col md="12" className="mt-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>
                                    Description{' '}
                                    <small>(facultatif, si concerné)</small>
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="description"
                                    placeholder="Description"
                                    value={formData.description || ''}
                                    onChange={handleFieldChange}
                                  />
                                </Form.Group>
                                <Row>
                                  <Col lg="12" className="pb-2 pt-2">
                                    <div className="border-dashed-bottom"></div>
                                  </Col>
                                  <Col lg="6" className="mt-2 mb-2">
                                    <Form.Group controlId="location_label">
                                      <Form.Label>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Lieu{' '}
                                        <small>(facultatif, si concerné)</small>
                                      </Form.Label>
                                      <GooglePlacesAutocomplete
                                        apiOptions={{
                                          language: 'fr',
                                          region: 'fr'
                                        }}
                                        selectProps={{
                                          noOptionsMessage: () =>
                                            'Tapez un lieu dans la barre de recherche',
                                          loadingMessage: () =>
                                            'Chargement en cours...',
                                          placeholder:
                                            'Commencez à chercher...',
                                          onChange: object =>
                                            geocodeByPlaceId(
                                              object.value.place_id
                                            )
                                              .then(results =>
                                                setFormData({
                                                  ...formData,
                                                  location_label:
                                                    results[0]
                                                      .formatted_address,
                                                  location_lat:
                                                    results[0].geometry.location.lat(),
                                                  location_lng:
                                                    results[0].geometry.location.lng()
                                                })
                                              )
                                              .catch(error =>
                                                console.error(error)
                                              )
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6" className="mt-2 mb-2">
                                    <Form.Group controlId="visio">
                                      <Form.Label>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M11 21v-1.07A7.002 7.002 0 0 1 5 13V8a7 7 0 1 1 14 0v5a7.002 7.002 0 0 1-6 6.93V21h4v2H7v-2h4zm1-18a5 5 0 0 0-5 5v5a5 5 0 0 0 10 0V8a5 5 0 0 0-5-5zm0 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Lien de l'événement en ligne{' '}
                                        <small>(facultatif, si concerné)</small>
                                      </Form.Label>
                                      <Form.Control
                                        type="url"
                                        name="link"
                                        placeholder="Lien"
                                        value={formData.link || ''}
                                        onChange={handleFieldChange}
                                        onBlur={() => {
                                          if (
                                            formData.link !== null &&
                                            formData.link !== undefined &&
                                            formData.link
                                          ) {
                                            if (
                                              !formData.link.startsWith(
                                                'http://'
                                              ) &&
                                              !formData.link.startsWith(
                                                'https://'
                                              )
                                            ) {
                                              setFormData({
                                                ...formData,
                                                link: `https://${formData.link}`
                                              });
                                            }
                                          }
                                        }}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Col lg="12" className="pb-2 pt-3">
                                  <div className="border-dashed-bottom"></div>
                                </Col>
                                <Row className="justify-content-inline">
                                  <Col md="4" className="mt-3">
                                    <Form.Group controlId="start_date">
                                      <Form.Label>
                                        {' '}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                          style={{
                                            marginBottom: '2px',
                                            marginRight: '2px'
                                          }}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Date{' '}
                                        {formData.start_is_all_day === 1 &&
                                          ' de début'}
                                      </Form.Label>
                                      <DatePicker
                                        showYearDropdown
                                        name="start_date"
                                        dateFormat="dd/MM/yyyy"
                                        locale="fr"
                                        selected={formData.start_date}
                                        onChange={date => {
                                          let today = date;
                                          let tomorrow = new Date(date);
                                          tomorrow.setDate(today.getDate() + 1);
                                          let tmpFormData = {
                                            ...formData,
                                            start_date: date,
                                            end_date: formData.start_is_all_day
                                              ? tomorrow
                                              : date
                                          };
                                          setFormData(tmpFormData);
                                        }}
                                        formatWeekDay={startDate =>
                                          startDate.slice(0, 3)
                                        }
                                        className="form-control"
                                        placeholderText="Date de début"
                                        value={formData.start_date}
                                      />
                                    </Form.Group>
                                    <Form.Group
                                      className="mt-3"
                                      controlId="start_is_all_day"
                                    >
                                      <Form.Label>
                                        Plusieurs jours ?
                                        <Form.Check
                                          className="d-inline-block ms-3"
                                          style={{ fontSize: 'medium' }}
                                          type="switch"
                                          id="defaultSwitch"
                                          defaultChecked={
                                            formData.start_is_all_day
                                          }
                                          name="start_is_all_day"
                                          onChange={handleFieldChange}
                                        />
                                      </Form.Label>
                                    </Form.Group>
                                  </Col>
                                  {formData.start_is_all_day === 1 && (
                                    <Col md="4" className="mt-3">
                                      <Form.Group controlId="end_date">
                                        <Form.Label>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="18"
                                            height="18"
                                            style={{
                                              marginBottom: '2px',
                                              marginRight: '2px'
                                            }}
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path
                                              d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                                              fill="rgba(94,110,130,1)"
                                            />
                                          </svg>{' '}
                                          Date de fin
                                        </Form.Label>
                                        <DatePicker
                                          showYearDropdown
                                          name="end_date"
                                          dateFormat="dd/MM/yyyy"
                                          locale="fr"
                                          selected={formData.end_date}
                                          onChange={date => {
                                            let tmpFormData = {
                                              ...formData,
                                              end_date: date
                                            };
                                            setFormData(tmpFormData);
                                          }}
                                          className="form-control"
                                          placeholderText="Date de fin"
                                          value={formData.end_date}
                                        />
                                      </Form.Group>
                                    </Col>
                                  )}

                                  <Col
                                    md="4"
                                    className={
                                      formData.start_is_all_day === 0
                                        ? 'mt-3'
                                        : 'd-none'
                                    }
                                  >
                                    <Form.Group controlId="start_time">
                                      <Form.Label>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                          style={{
                                            marginBottom: '2px',
                                            marginRight: '2px'
                                          }}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Heure de début
                                      </Form.Label>
                                      <Form.Select
                                        name="start_time"
                                        onChange={handleFieldChange}
                                        value={formData.start_time}
                                      >
                                        {hours.map((hour, index) => (
                                          <option key={index} value={hour}>
                                            {hour}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    md="4"
                                    className={
                                      formData.start_is_all_day === 0
                                        ? 'mt-3'
                                        : 'd-none'
                                    }
                                  >
                                    <Form.Group controlId="end_time">
                                      <Form.Label>
                                        {' '}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                          style={{
                                            marginBottom: '2px',
                                            marginRight: '2px'
                                          }}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                                            fill="rgba(94,110,130,1)"
                                          />
                                        </svg>{' '}
                                        Heure de fin
                                      </Form.Label>
                                      <Form.Select
                                        name="end_time"
                                        onChange={handleFieldChange}
                                        value={formData.end_time}
                                      >
                                        {hours.map((hour, index) => (
                                          <option key={index} value={hour}>
                                            {hour}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>

                                  <Col lg="12" className="pb-2 pt-0 mt-2">
                                    <div className="border-dashed-bottom"></div>
                                  </Col>

                                  <Col className="mt-3" lg={12}>
                                    <Button
                                      variant="primary"
                                      className="admin-primary mt-0"
                                      size="sm"
                                      type="submit"
                                      disabled={isLoading}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        height="18"
                                        style={{
                                          position: 'relative',
                                          bottom: '1px',
                                          left: '-1px',
                                          paddingRight: '3px'
                                        }}
                                      >
                                        <path
                                          fill="#FFFFFF"
                                          d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                                        />
                                      </svg>
                                      Soumettre mon événement à Tred Union
                                    </Button>
                                    <Button
                                      size="sm"
                                      variant="falcon-default"
                                      className="float-end"
                                      onClick={() => {
                                        setOpen(false);
                                      }}
                                    >
                                      Annuler
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>{' '}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Card.Body>
              </Card>
            )}
          </>
        ) : (
          <>
            {showSuccessMessage && (
              <Alert
                variant="success"
                dismissible
                onClose={() => setShowSuccessMessage(false)}
              >
                Merci, votre événement a été soumis à Tred Union, nous traitons
                votre demande. <br />
                <a
                  href="#"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Soumettre un autre événement
                </a>
              </Alert>
            )}
          </>
        )}

        <Card className="mt-2 calendar-tred">
          <Card.Header>
            <Row className="align-items-center gx-0">
              <Col>
                <h6>
                  <b>Filtrer :</b>
                </h6>
              </Col>
            </Row>
            <Row className="align-items-center gx-0 mb-4">
              <Col>
                <label className="me-3">
                  <input
                    type="checkbox"
                    name="tredunion"
                    value={formSearch.tredunion}
                    className="me-1"
                    checked={
                      formSearch.tredunion === 1 || formSearch.tredunion === '1'
                    }
                    onChange={event => {
                      setFormSearch({
                        ...formSearch,
                        tredunion: event.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Événements Tred Union{' '}
                </label>
                <label className="me-3">
                  <input
                    type="checkbox"
                    name="pole"
                    value={formSearch.pole}
                    className="me-1"
                    checked={formSearch.pole === 1 || formSearch.pole === '1'}
                    onChange={event => {
                      setFormSearch({
                        ...formSearch,
                        pole: event.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Événements Pole{' '}
                </label>
                <label className="me-3">
                  <input
                    type="checkbox"
                    name="pole"
                    value={formSearch.formations}
                    className="me-1"
                    checked={
                      formSearch.formations === 1 ||
                      formSearch.formations === '1'
                    }
                    onChange={event => {
                      setFormSearch({
                        ...formSearch,
                        formations: event.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Formations{' '}
                </label>
                <label className="me-3">
                  <input
                    type="checkbox"
                    name="pole"
                    value={formSearch.reunions}
                    className="me-1"
                    checked={
                      formSearch.reunions === 1 || formSearch.reunions === '1'
                    }
                    onChange={event => {
                      setFormSearch({
                        ...formSearch,
                        reunions: event.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Réunions{' '}
                </label>
                <label className="me-3">
                  <input
                    type="checkbox"
                    name="pole"
                    value={formSearch.webinars}
                    className="me-1"
                    checked={
                      formSearch.webinars === 1 || formSearch.webinars === '1'
                    }
                    onChange={event => {
                      setFormSearch({
                        ...formSearch,
                        webinars: event.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Webinars{' '}
                </label>
              </Col>
            </Row>
            <Row className="align-items-center gx-0">
              <Col xs="auto" className="d-flex justify-content-end order-md-1">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="nextTooltip">Précédent</Tooltip>}
                >
                  <Button
                    variant="link"
                    className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                    onClick={() => {
                      calendarApi.prev();
                      const selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                      localStorage.setItem(
                        'selectedDateTredUnionMember',
                        selectedDate
                      );
                      setTitle(calendarApi.getCurrentData().viewTitle);
                      handleCalendarChange();
                    }}
                  >
                    <FontAwesomeIcon icon="arrow-left" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="previousTooltip">Suivant</Tooltip>}
                >
                  <Button
                    variant="link"
                    className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                    onClick={() => {
                      calendarApi.next();
                      const selectedDate = Moment(
                        calendarApi.getCurrentData().currentDate
                      )
                        .endOf('month')
                        .local()
                        .format('YYYY-MM-DD');
                      localStorage.setItem(
                        'selectedDateTredUnionMember',
                        selectedDate
                      );
                      setTitle(calendarApi.getCurrentData().viewTitle);
                      handleCalendarChange();
                    }}
                  >
                    <FontAwesomeIcon icon="arrow-right" />
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col xs="auto" className="d-flex justify-content-end order-md-2">
                <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {title ||
                    `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
              </Col>
              <Col md="auto" className="d-md-none">
                <hr />
              </Col>

              <Col className="d-flex justify-content-start order-md-2 ms-3">
                <Button
                  className="me-2"
                  variant="falcon-default"
                  size="sm"
                  icon={faFileExcel}
                  transform="shrink-3"
                  onClick={() => {
                    calendarApi.today();
                    const selectedDate = Moment(
                      calendarApi.getCurrentData().currentDate
                    )
                      .endOf('month')
                      .local()
                      .format('YYYY-MM-DD');
                    localStorage.setItem(
                      'selectedDateTredUnionMember',
                      selectedDate
                    );
                    setTitle(calendarApi.getCurrentData().viewTitle);
                    handleCalendarChange();
                  }}
                >
                  Aujourd'hui
                </Button>
              </Col>

              <Col className="d-flex justify-content-end order-md-2">
                <DropdownFilter
                  className="me-2"
                  filters={viewName}
                  currentFilter={currentFilter}
                  handleFilter={handleFilter}
                  icon="sort"
                  right
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <FullCalendar
              eventOrder={['start', 'time']}
              locale={frLocale}
              ref={calendarRef}
              headerToolbar={false}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin
              ]}
              initialView="dayGridMonth"
              themeSystem="bootstrap"
              dayMaxEvents={10}
              direction={'ltr'}
              height={800}
              stickyHeaderDates={false}
              editable
              selectable
              selectMirror
              select={info => {
                setTimeout(function () {
                  document.getElementById('events').scrollIntoView();
                }, 250);
                setOpen(true);
                let today = info.start;
                let tomorrow = new Date(info.start);
                tomorrow.setDate(today.getDate() + 1);
                setFormData({
                  ...formData,
                  start_date: info.start,
                  end_date: tomorrow
                });
              }}
              eventTimeFormat={eventTimeFormat}
              events={events}
              eventClick={handleEventClick}
              initialDate={
                localStorage.getItem('selectedDateTredUnionMember')
                  ? new Date(
                      localStorage.getItem('selectedDateTredUnionMember')
                    )
                  : new Date()
              }
            />

            {categories.length > 0 && (
              <Row className="px-2 py-3">
                {exportUrl && (
                  <Col xs={12} className="mb-2">
                    <IconAlert
                      variant="success"
                      dismissible
                      id="exportSuccess"
                      onClose={() => setExportUrl('')}
                      className="mt-3"
                    >
                      <p className="mb-0">
                        Export Excel du calendrier disponible :{' '}
                        <a href={exportUrl}>cliquez ici pour le télécharger</a>
                      </p>
                    </IconAlert>
                  </Col>
                )}
                <Col xs={9}>
                  {categories.filter(
                    cat => cat.name === 'Vacances scolaires Zone A'
                  ).length > 0 && (
                    <span className="smaller-p">
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: categories.filter(
                            cat => cat.name === 'Vacances scolaires Zone A'
                          )[0].color,
                          height: '12px',
                          width: '12px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      Vacances scolaires Zone A
                    </span>
                  )}
                  {categories.filter(
                    cat => cat.name === 'Vacances scolaires Zone B'
                  ).length > 0 && (
                    <span className="smaller-p ms-2">
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: categories.filter(
                            cat => cat.name === 'Vacances scolaires Zone B'
                          )[0].color,
                          height: '12px',
                          width: '12px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      Vacances scolaires Zone B
                    </span>
                  )}
                  {categories.filter(
                    cat => cat.name === 'Vacances scolaires Zone C'
                  ).length > 0 && (
                    <span className="smaller-p ms-2">
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: categories.filter(
                            cat => cat.name === 'Vacances scolaires Zone C'
                          )[0].color,
                          height: '12px',
                          width: '12px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      Vacances scolaires Zone C
                    </span>
                  )}
                </Col>
                <Col xs={3} className="text-end pe-3">
                  {exportIsLoading ? (
                    <Spinner animation="border" role="status" size="xxs">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : !exportUrl ? (
                    <DropdownButton
                      as={ButtonGroup}
                      variant="falcon-default"
                      size="sm"
                      title={
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '2px' }}
                          >
                            <path
                              d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                              fill="rgba(75,74,77,1)"
                            />
                          </svg>{' '}
                          Exporter
                        </>
                      }
                      className="me-2"
                    >
                      {[2023, 2024, 2025, 2026].map(year => (
                        <Dropdown.Item
                          key={year}
                          eventKey="1"
                          onClick={() => {
                            exportEvents(year);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{ marginBottom: '2px', marginRight: '2px' }}
                          >
                            <path
                              d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                              fill="rgba(75,74,77,1)"
                            />
                          </svg>
                          {year}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  ) : (
                    ''
                  )}
                </Col>
                <Col xs={12} className="mt-2">
                  {categories
                    .filter(
                      cat =>
                        cat.is_holiday === 0 &&
                        cat.is_public_holiday === 0 &&
                        cat.is_admin === 0
                    )
                    .map((category, index) => (
                      <span className="smaller-p me-2" key={index}>
                        <span
                          className="rounded-circle d-inline-block me-1"
                          style={{
                            backgroundColor: category.color,
                            height: '12px',
                            width: '12px',
                            position: 'relative',
                            top: '1px'
                          }}
                        ></span>
                        {category.name}
                      </span>
                    ))}
                </Col>
              </Row>
            )}
            <CalendarEventModal
              isOpenEventModal={isOpenEventModal}
              setIsOpenEventModal={setIsOpenEventModal}
              modalEventContent={modalEventContent}
            />
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default CalendarMember;
