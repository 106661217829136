import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'elearning/courses';

export async function IndexCourse(context) {
  return modelService.IndexModel(`${model}?context=${context}`);
}

export async function ShowCourse(id) {
  return modelService.ShowModel(`${model}/${id}?context=memberarea`);
}

export async function ShowCourseAdmin(id) {
  return modelService.ShowModel(`${model}/${id}?context=admin`);
}

export async function PutCourse(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostCourse(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteCourse(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
