import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Moment from 'moment';

const EventsList = ({ events }) => {
  return (
    <>
      <Row className="justify-content-center mb-5">
        <Col xs={6}>
          <hr className="mt-0" style={{ color: 'rgb(216 226 239 / 42%)' }} />
        </Col>
        <Col xs={12}></Col>
        <Col xs={12} className="mt-3">
          <h2 className="text-center">Nos événements à venir</h2>
          <p className="mt-3 pb-3 text-center">
            <small>
              Suivez les événements de notre groupement de transporteurs
              routiers et logistique
            </small>
          </p>
        </Col>
        <Col lg={11} xl={9}>
          <Row className="justify-content-center">
            {events.map((event, index) => (
              <Col key={index} xs={12} md={4}>
                <div className="card-event-home p-3">
                  <Row>
                    <Col xs={2}>
                      <div className="calendar">
                        <span className="calendar-month">
                          {Moment(event.start_date)
                            .startOf('month')
                            .format('MMM')}
                        </span>
                        <span className="calendar-day">
                          {Moment(event.start_date).startOf('day').format('DD')}
                        </span>
                      </div>
                    </Col>
                    <Col xs={10} className="ps-4 ps-md-4 ps-lg-3">
                      <h3 className="fs-0 mb-1 home-event-title">
                        <span className="me-1"> {event.title}</span>
                      </h3>
                      {event.company && (
                        <p className="mb-1">
                          par
                          <Link
                            to={`adherents/${event.company.id}`}
                            className="ps-1 text-primary"
                            style={{ color: 'blue' }}
                          >
                            {event.company.name}
                          </Link>
                        </p>
                      )}
                      {event.start_date === event.end_date ? (
                        <p className="mb-0">
                          Le{' '}
                          {Moment(event.start_date)
                            .local()
                            .format('DD/MM/YYYY')}
                        </p>
                      ) : (
                        <p className="mb-0">
                          du{' '}
                          {Moment(event.start_date)
                            .local()
                            .format('DD/MM/YYYY')}{' '}
                          au{' '}
                          {Moment(event.end_date).local().format('DD/MM/YYYY')}
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};
EventsList.propTypes = {
  events: PropTypes.array
};
export default EventsList;
