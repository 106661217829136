import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'trade-press-post-medias';

export async function PostMediaTradePress(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteMediaTradePress(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
