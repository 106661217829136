import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'companies-exports';

export async function IndexCompanyExport(context) {
  return modelService.IndexModel(`${model}?context=${context}`);
}

export async function PostCompanyExport(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutCompanyExport(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteCompanyExport(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
