import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { membersRoutes } from '../../../../routes/authRoutes';
import { IndexTrainingCategory } from '../../../../services/TrainingCategoryService';
import Vimeo from '@u-wave/react-vimeo';

const Visios = () => {
  const [trigger] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(true);
  const [currentCategory, setCurrentCategory] = useState({});
  const [resourceCategories, setResourceCategories] = useState([]);
  const [splittedCategories, setSplittedCategories] = useState([]);

  const colors = Array(
    'e37777',
    '903a3a',
    '8f3838',
    '635d5d',
    '3c3c3c',
    '832525',
    '650b0b',
    '6c3c3c',
    '907575',
    'b24343',
    'af4c4c',
    'a40404',
    '381f1f',
    '541c1c',
    '795e5e',
    'a93f3f',
    'c91212',
    '1c1313',
    '612020',
    'cf3434',
    '730606',
    'd64a4a',
    '654848',
    '535050',
    '888888',
    '342525'
  );

  const getRecursiveCategories = (category, categories) => {
    categories.push(category);
    if (category.childrens.length > 0) {
      category.childrens.map(children => {
        getRecursiveCategories(children, categories);
      });
    }
  };

  useEffect(() => {
    const fetchResourceCategories = async () => {
      const responseResourceCategories = await IndexTrainingCategory();
      if (responseResourceCategories.success === true) {
        setResourceCategories(responseResourceCategories.data);
        let splittedCategories = [];
        responseResourceCategories.data.map(category => {
          getRecursiveCategories(category, splittedCategories);
        });
        setSplittedCategories(splittedCategories);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchResourceCategories();
  }, [trigger]);

  return (
    <Row className="mt-2" id="tred-ressources">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d={
                membersRoutes.filter(route => route.id === 'tredVisios')[0].svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          {membersRoutes.filter(route => route.id === 'tredVisios')[0].label}{' '}
          {currentCategory.id && <>{currentCategory.title}</>}
        </h5>
      </Col>
      {isLoading === true && (
        <Col xs={12}>
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      )}
      {resourceCategories.length > 0 && (
        <Col xs={12}>
          <Row className="px-3">
            {currentCategory.id && (
              <Col xs={12} className="px-1 mt-1 mb-3">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    if (currentCategory.parent_id) {
                      setCurrentCategory(
                        splittedCategories.find(
                          category => category.id === currentCategory.parent_id
                        )
                      );
                    } else {
                      setCurrentCategory({});
                    }
                  }}
                >
                  &lt; Retour
                </Button>
              </Col>
            )}
            <>
              {!currentCategory.id ? (
                <>
                  {resourceCategories.map((category, index) => (
                    <>
                      {category.trainings.length > 0 && (
                        <Col
                          xs={6}
                          lg={3}
                          className="px-1 py-1 d-flex align-items-center"
                          key={index}
                        >
                          <div
                            className="item-container d-flex align-items-end px-2"
                            style={{
                              backgroundColor: `#${
                                colors.reverse()[
                                  [...category.title]
                                    .map(a => parseInt(a, 36) - 10)
                                    .filter(a => a >= 0)[0]
                                ] ?? '832525'
                              }`
                            }}
                            onClick={() => {
                              setCurrentCategory(category);
                            }}
                          >
                            <div className="title d-block pb-3 w-100 text-center">
                              {category.title}
                            </div>
                          </div>
                        </Col>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {currentCategory.childrens.map((category, index) => (
                    <>
                      <Col
                        xs={6}
                        lg={3}
                        className="px-1 py-1 d-flex align-items-center"
                        key={index}
                      >
                        <div
                          className="item-container d-flex align-items-end px-2"
                          style={{
                            backgroundColor: `#${
                              colors.reverse()[
                                [...category.title]
                                  .map(a => parseInt(a, 36) - 10)
                                  .filter(a => a >= 0)[0]
                              ] ?? '832525'
                            }`
                          }}
                          onClick={() => {
                            setCurrentCategory(category);
                          }}
                        >
                          <div className="title d-block pb-3 w-100 text-center">
                            {category.title}
                          </div>
                        </div>
                      </Col>
                    </>
                  ))}
                </>
              )}
            </>
          </Row>
        </Col>
      )}
      {currentCategory.id &&
        currentCategory.trainings &&
        currentCategory.trainings.length > 0 && (
          <Col xs={12} className="mt-3">
            {currentCategory.trainings
              .sort((a, b) => b.id - a.id)
              .map((training, index) => (
                <Row className="px-1" key={index}>
                  <Col lg={12} className="pb-5 training">
                    <Card style={{ height: '100%' }}>
                      <Card.Body className="p-0">
                        {training.vimeo_id.length > 0 ? (
                          <Vimeo
                            video={training.vimeo_id}
                            style={{ width: '100%' }}
                            className="vimeo-video"
                          />
                        ) : (
                          <div
                            className="d-block px-3 pt-3 cursor-pointer"
                            onClick={() => {
                              window.open(
                                training.support_file_url,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="50"
                              height="50"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M13 12h3l-4 4-4-4h3V8h2v4zm2-8H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"
                                fill="rgba(229,47,18,1)"
                              />
                            </svg>
                          </div>
                        )}

                        <Card.Title as="h5" className="mt-4 px-3 pb-3">
                          {training.title}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))}
          </Col>
        )}
    </Row>
  );
};

export default Visios;
