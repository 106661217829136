import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportPurchaseCodesFromIndex = ({ purchaseCodes }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    purchaseCodes.map(purchaseCode => {
      let cleanedCode = {
        title: purchaseCode.titleString,
        type: purchaseCode.type,
        brand: purchaseCode.brand,
        quantity: purchaseCode.quantity,
        user: purchaseCode.user,
        company: purchaseCode.company,
        date: purchaseCode.created_at
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [purchaseCodes]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Télécharger la sélection
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Codes achats">
              <ExcelColumn label="Code" value="title" />
              <ExcelColumn label="Type" value="type" />
              <ExcelColumn label="Marque" value="brand" />
              <ExcelColumn label="Quantité" value="quantity" />
              <ExcelColumn label="Initiateur" value="user" />
              <ExcelColumn label="Entreprise" value="company" />
              <ExcelColumn label="Date" value="date" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportPurchaseCodesFromIndex.propTypes = {
  purchaseCodes: PropTypes.array
};

export default ExportPurchaseCodesFromIndex;
