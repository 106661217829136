import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { usePagination, useSortBy, useTable, useFilters } from 'react-table';
import PropTypes from 'prop-types';
import * as Moment from 'moment/moment';
import SoftBadge from '../../../../../common/SoftBadge';
import DatePicker from 'react-datepicker';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { PutEvent } from '../../../../../../services/Event/EventService';
import { toast } from 'react-toastify';
import ExportRecipients from '../../../../../../exports/recipients';
import IndexLinks from '../links';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeEndDate,
  changeParticipate,
  changeSearch,
  changeStartDate
} from '../../../../../../redux/slices/eventSlice';
import {
  CancelRecipientAnswers,
  RelaunchRecipient,
  UpdateDunningStatusRecipient
} from '../../../../../../services/Survey/SurveyService';

const RecipientsTableEvent = ({
  recipients,
  survey,
  event,
  setParentTrigger
}) => {
  const [initialObjects, setInitialObjects] = useState([]);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedCancel, setSelectedCancel] = useState({});
  const [objects, setObjects] = useState([]);
  const [formSearch, setFormSearch] = useStateWithCallbackLazy({});
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);

  const {
    search: searchStored,
    startDate: startDateStored,
    endDate: endDateStored,
    participate: participateStored
  } = useSelector(state => state.persistedEventReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialFormDataSearch = {
      search: searchStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored),
      participate: participateStored
    };
    setFormSearch(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [initialObjects]);

  useEffect(() => {
    if (formSearch === null) return;

    const updateFormDataSearch = {
      ...formSearch,
      search: searchStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored),
      participate: participateStored
    };
    setFormSearch(updateFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [searchStored, startDateStored, endDateStored, participateStored]);

  useEffect(() => {
    let transformedObjects = [];
    recipients.map(obj => {
      let transformedObject = {
        initialObject: obj,
        firstname: obj.firstname ? obj.firstname : '',
        lastname: obj.lastname ? obj.lastname : '',
        email: obj.email ? obj.email : '',
        pivot: obj.pivot ? obj.pivot : '',
        company:
          obj.main_company && obj.main_company.id ? (
            obj.main_company.name
          ) : obj.partner && obj.partner.id ? (
            obj.partner.name
          ) : obj.company_name ? (
            <>{obj.company_name}</>
          ) : (
            ''
          ),
        user: (
          <>
            {obj.lastname ? (
              <>
                {obj.pivot?.waiting_list === 1 ? (
                  <SoftBadge bg="danger" className="me-1">
                    {isLoading ? (
                      'Chargement en cours...'
                    ) : (
                      <Fragment>
                        En liste d'attente{' '}
                        <span
                          className="text-linkedin cursor-pointer"
                          onClick={() => {
                            outWaitingList(obj);
                          }}
                        >
                          valider inscription
                        </span>
                      </Fragment>
                    )}
                  </SoftBadge>
                ) : (
                  ''
                )}
                {obj.lastname} {obj.firstname}
                {obj.pivot && obj.pivot.survey_access_uuid && (
                  <div className="d-block mt-1">
                    <small style={{ display: 'block', lineHeight: '12px' }}>
                      <a
                        href={`/reponse-formulaire/${obj.pivot.survey_access_uuid}`}
                      >
                        Répondre à la place du membre
                      </a>
                    </small>
                  </div>
                )}
              </>
            ) : obj.partner && obj.partner.id ? (
              <>
                {obj.pivot?.waiting_list === 1 ? (
                  <SoftBadge bg="danger" className="me-1">
                    {isLoading ? (
                      'Chargement en cours...'
                    ) : (
                      <Fragment>
                        En liste d'attente{' '}
                        <span
                          className="text-linkedin cursor-pointer"
                          onClick={() => {
                            outWaitingList(obj);
                          }}
                        >
                          valider inscription
                        </span>
                      </Fragment>
                    )}
                  </SoftBadge>
                ) : (
                  ''
                )}
                {obj.email}
                {obj.pivot && obj.pivot.survey_access_uuid && (
                  <div className="d-block mt-1">
                    <small style={{ display: 'block', lineHeight: '12px' }}>
                      <a
                        href={`/reponse-formulaire/${obj.pivot.survey_access_uuid}`}
                      >
                        Répondre à la place du membre
                      </a>
                    </small>
                  </div>
                )}
              </>
            ) : (
              <>
                {obj.pivot?.waiting_list === 1 ? (
                  <SoftBadge bg="danger" className="me-1">
                    {isLoading ? (
                      'Chargement en cours...'
                    ) : (
                      <Fragment>
                        En liste d'attente{' '}
                        <span
                          className="text-linkedin cursor-pointer"
                          onClick={() => {
                            outWaitingList(obj);
                          }}
                        >
                          valider inscription
                        </span>
                      </Fragment>
                    )}
                  </SoftBadge>
                ) : (
                  ''
                )}
                {obj.email}
                {obj.pivot && obj.pivot.survey_access_uuid && (
                  <div className="d-block mt-1">
                    <small style={{ display: 'block', lineHeight: '12px' }}>
                      <a
                        href={`/reponse-formulaire/${obj.pivot.survey_access_uuid}`}
                      >
                        Répondre à la place du membre
                      </a>
                    </small>
                  </div>
                )}
              </>
            )}
            {survey.dunning_starts_on && survey.survey_dunning_type_id && (
              <div className="d-block mt-3">
                <small>
                  <small style={{ lineHeight: '13px', display: 'block' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="10"
                      height="10"
                      style={{
                        position: 'relative',
                        top: '-1px',
                        marginRight: '2px'
                      }}
                    >
                      <path
                        fill={
                          obj.pivot?.dunning_status === 1
                            ? '#27ae60'
                            : '#f39c12'
                        }
                        d="M21 3C21.5523 3 22 3.44772 22 4V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5V4C2 3.44772 2.44772 3 3 3H21ZM8 15V17H0V15H8ZM5 10V12H0V10H5ZM19.5659 5H4.43414L12 11.8093L19.5659 5Z"
                      ></path>
                    </svg>
                    Relances{' '}
                    {obj.pivot?.dunning_status === 1
                      ? ' activées'
                      : ' désactivées'}{' '}
                    <a
                      style={{ cursor: 'pointer', color: '#2c7be5' }}
                      onClick={async () => {
                        updateStatus({
                          attachable_type: 'Event',
                          attachable_id: event.id,
                          user_id: obj.id,
                          type: obj.type,
                          dunning_status:
                            obj.pivot?.dunning_status === 1 ? 0 : 1
                        });
                      }}
                    >
                      {obj.pivot?.dunning_status === 1
                        ? 'Désactiver'
                        : 'Activer'}
                    </a>
                  </small>
                </small>
              </div>
            )}
            {!obj.pivot?.survey_completed_on && (
              <div className="d-block mt-1 mt-2">
                <small
                  onClick={async () => {
                    toast(
                      'Envoi du mail, veuillez patienter quelques secondes...'
                    );
                    relaunchRecipient({
                      attachable_type: 'Event',
                      attachable_id: event.id,
                      user_id: obj.id,
                      type: obj.type
                    });
                  }}
                  style={{ display: 'block', lineHeight: '12px' }}
                >
                  <a className="cursor-pointer">Renvoyer le mail</a>
                </small>
              </div>
            )}
          </>
        ),
        participateFilter:
          obj.survey_questions &&
          obj.survey_questions.length &&
          obj.survey_questions.filter(
            question =>
              question.is_participation_form_main_question === 1 &&
              question.attachable_id === survey.id &&
              question.pivot &&
              question.pivot.value === 'Oui'
          ).length > 0
            ? 'Oui'
            : obj.survey_questions &&
              obj.survey_questions.length &&
              obj.survey_questions.filter(
                question =>
                  question.is_participation_form_main_question === 1 &&
                  question.attachable_id === survey.id &&
                  question.pivot &&
                  question.pivot.value === 'Non'
              ).length > 0
            ? 'Non'
            : '',
        participate:
          obj.survey_questions &&
          obj.survey_questions.length &&
          obj.survey_questions.filter(
            question =>
              question.is_participation_form_main_question === 1 &&
              question.attachable_id === survey.id &&
              question.pivot &&
              question.pivot.value === 'Oui'
          ).length > 0 ? (
            <>
              <SoftBadge bg="primary" className="">
                Oui
              </SoftBadge>
              {obj.pivot && obj.pivot.did_not_come_to_event === 1 && (
                <div className="d-block">
                  <SoftBadge bg="danger" className="">
                    Ne s'est pas présenté
                  </SoftBadge>
                </div>
              )}
              {obj.survey_questions.filter(
                q =>
                  q.attachable_id === survey.id &&
                  q.attachable_type.includes('Survey') &&
                  q.is_participation_form_main_question === 0
              ).length > 0 && (
                <div className="d-block mt-2">
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {obj.survey_questions
                          .filter(
                            q =>
                              q.attachable_id === survey.id &&
                              q.attachable_type.includes('Survey') &&
                              q.is_participation_form_main_question === 0
                          )
                          .map((q, i) => (
                            <p className="d-block mb-2 text-start" key={i}>
                              {q.title}
                              <br />
                              {q.type && q.type.slug === 'datetime' ? (
                                <>
                                  {Moment(q.pivot.value)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')}
                                </>
                              ) : (
                                <>
                                  {q.pivot.value}{' '}
                                  {q.pivot.comment
                                    ? `(${q.pivot.comment})`
                                    : ''}
                                </>
                              )}
                            </p>
                          ))}
                      </Tooltip>
                    }
                  >
                    <div className="d-block smaller-p mb-0 mt-0 cursor-pointer">
                      <small style={{ lineHeight: '12px', display: 'block' }}>
                        +
                        {
                          obj.survey_questions.filter(
                            q =>
                              q.attachable_id === survey.id &&
                              q.attachable_type.includes('Survey') &&
                              q.is_participation_form_main_question === 0
                          ).length
                        }{' '}
                        autres réponses
                      </small>
                    </div>
                  </OverlayTrigger>
                </div>
              )}
              {Moment(event.end_date).local().isSameOrBefore(Moment()) &&
                obj.survey_questions &&
                obj.survey_questions.length &&
                obj.survey_questions.filter(
                  question =>
                    question.is_participation_form_main_question === 1 &&
                    question.attachable_id === survey.id &&
                    question.pivot &&
                    question.pivot.value === 'Oui'
                ).length > 0 && (
                  <>
                    {isLoading === true ? (
                      <div className="d-block smaller-p mb-0 mt-3 text-muted">
                        <small style={{ lineHeight: '12px', display: 'block' }}>
                          Chargement...
                        </small>
                      </div>
                    ) : (
                      <div
                        className="d-block smaller-p mb-0 mt-3 text-muted"
                        onClick={() => {
                          markAsAbsent(obj);
                        }}
                      >
                        <small
                          className="cursor-pointer"
                          style={{ lineHeight: '12px', display: 'block' }}
                        >
                          {obj.pivot && obj.pivot.did_not_come_to_event === 1
                            ? '+ Marquer comme présent'
                            : 'x Marquer comme absent'}
                        </small>
                      </div>
                    )}
                  </>
                )}
            </>
          ) : obj.survey_questions &&
            obj.survey_questions.length &&
            obj.survey_questions.filter(
              question =>
                question.is_participation_form_main_question === 1 &&
                question.attachable_id === survey.id &&
                question.attachable_type.includes('Survey') &&
                question.pivot &&
                question.pivot.value === 'Non'
            ).length > 0 ? (
            <>
              <SoftBadge bg="warning" className="">
                Non
              </SoftBadge>
            </>
          ) : (
            ''
          ),
        participateToInt:
          obj.survey_questions &&
          obj.survey_questions.length &&
          obj.survey_questions.filter(
            question =>
              question.is_participation_form_main_question === 1 &&
              question.attachable_id === survey.id &&
              question.pivot &&
              question.pivot.value === 'Oui'
          ).length > 0
            ? 1
            : obj.survey_questions &&
              obj.survey_questions.length &&
              obj.survey_questions.filter(
                question =>
                  question.is_participation_form_main_question === 1 &&
                  question.attachable_id === survey.id &&
                  question.pivot &&
                  question.pivot.value === 'Non'
              ).length > 0
            ? 0
            : null,
        survey_completed_on:
          obj.pivot && obj.pivot.survey_completed_on
            ? Moment(obj.pivot.survey_completed_on).local().format('DD/MM/YYYY')
            : '',
        respondant: obj.pivot && obj.pivot.survey_completed_on && (
          <>
            {obj.pivot.admin_respondant
              ? `${obj.pivot.admin_respondant} ${
                  obj.pivot.admin_completed_on
                    ? 'le ' +
                      Moment(obj.pivot.admin_completed_on)
                        .local()
                        .format('DD/MM/YYYY')
                    : ''
                }`
              : 'Le destinataire'}
          </>
        ),
        survey_completed_after_x_dunnings:
          obj.pivot &&
          obj.pivot.survey_completed_on &&
          obj.pivot.survey_completed_after_x_dunnings < 1 ? (
            <>
              <SoftBadge bg="primary" className="ms-1">
                A répondu tout de suite
              </SoftBadge>
              <small className="d-block ms-1 mt-2">
                <a
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    setSelectedCancel(obj);
                    setShowCancel(true);
                  }}
                >
                  x Effacer les réponses et renvoyer une invitation
                </a>
              </small>
            </>
          ) : obj.pivot &&
            obj.pivot.survey_completed_on &&
            obj.pivot.survey_completed_after_x_dunnings ? (
            <>
              <SoftBadge bg="warning" className="ms-1">
                A répondu après {obj.pivot.survey_completed_after_x_dunnings}{' '}
                relance
                {obj.pivot.survey_completed_after_x_dunnings > 1 ? 's' : ''}
              </SoftBadge>
              <small className="d-block ms-1 mt-2">
                <a
                  className="text-danger cursor-pointer"
                  onClick={() => {
                    setSelectedCancel(obj);
                    setShowCancel(true);
                  }}
                >
                  x Effacer les réponses et renvoyer une invitation
                </a>
              </small>
            </>
          ) : (
            ''
          )
      };
      if (
        obj.survey_questions &&
        obj.survey_questions.length > 0 &&
        obj.survey_questions.filter(
          question =>
            question.attachable_id === survey.id &&
            question.attachable_type.includes('Survey')
        ).length > 0
      ) {
        obj.survey_questions
          .filter(
            q =>
              q.attachable_id === survey.id &&
              q.attachable_type.includes('Survey') &&
              q.is_participation_form_main_question === 0
          )
          .map(q => {
            if (q.type && q.type.slug === 'datetime') {
              transformedObject['question_' + q.id] = Moment(q.pivot.value)
                .local()
                .format('DD/MM/YYYY HH:mm');
            } else if (q.type && q.type.slug === 'review') {
              if (q.pivot.comment) {
                transformedObject['question_' + q.id] = q.pivot.value;
                transformedObject[
                  'question_review_' + q.id
                ] = `${q.pivot.value}/10 (${q.pivot.comment})`;
              } else {
                transformedObject['question_' + q.id] = q.pivot.value;
                transformedObject[
                  'question_review_' + q.id
                ] = `${q.pivot.value}/10`;
              }
            } else {
              transformedObject['question_' + q.id] = q.pivot.value;
            }
          });
      }
      transformedObjects.push(transformedObject);
    });
    setInitialObjects(transformedObjects);
    setObjects(transformedObjects);
    setFormSearch({
      search: '',
      startDate: '',
      endDate: '',
      participate: ''
    });
  }, [trigger, recipients]);

  const updateStatus = async form => {
    const response = await UpdateDunningStatusRecipient(form);
    if (response.success === true) {
      toast(
        'Statut en cours de changement, veuillez patienter quelques secondes...'
      );
      setParentTrigger(Date.now());
    } else {
      toast('Erreur lors de la mise à jour du statut');
    }
  };

  const cancelRecipient = async form => {
    const response = await CancelRecipientAnswers(form);
    if (response.success === true) {
      toast('Annulation en cours, veuillez patienter quelques secondes...');
      setParentTrigger(Date.now());
    } else {
      toast('Erreur lors de la mise à jour du statut');
    }
  };

  const relaunchRecipient = async form => {
    const response = await RelaunchRecipient(form);
    if (response.success === true) {
      setParentTrigger(Date.now());
      toast('Email envoyé');
    } else {
      toast('Erreur lors de la mise à jour du statut');
    }
  };

  const updateSearch = (data, objects) => {
    if (
      data.search.length === 0 &&
      data.participate.length === 0 &&
      !data.startDate &&
      !data.endDate
    ) {
      setObjects(objects);
    } else {
      let tmpFilterObjects = objects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            obj.initialObject?.company_name
              ?.toLowerCase()
              .includes(tmpSearch) ||
            (obj.companyToString &&
              obj.companyToString.toLowerCase().includes(tmpSearch)) ||
            (obj.firstname &&
              obj.firstname.toLowerCase().includes(tmpSearch)) ||
            (obj.lastname && obj.lastname.toLowerCase().includes(tmpSearch)) ||
            (obj.email && obj.email.toLowerCase().includes(tmpSearch))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.participate.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          if (data.participate === 'Participe' && obj.participateToInt === 1) {
            return true;
          } else if (
            data.participate === 'Ne participe pas' &&
            obj.participateToInt === 0
          ) {
            return true;
          } else if (
            data.participate === 'A répondu' &&
            obj.survey_completed_on
          ) {
            return true;
          } else if (
            data.participate === "N'a pas répondu" &&
            !obj.survey_completed_on
          ) {
            return true;
          } else if (
            data.participate === "En liste d'attente" &&
            obj.pivot?.waiting_list === 1
          ) {
            return true;
          }
        });
      }
      if (data.startDate && data.endDate) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            data.startDate &&
            obj.pivot &&
            obj.pivot.survey_completed_on &&
            Moment(obj.pivot.survey_completed_on)
              .local()
              .isSameOrAfter(Moment(data.startDate)) &&
            (!data.endDate ||
              (data.endDate &&
                obj.pivot &&
                obj.pivot.survey_completed_on &&
                Moment(obj.pivot.survey_completed_on)
                  .local()
                  .isSameOrBefore(Moment(data.endDate).add(1, 'days'))))
          ) {
            result = true;
          }
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Dates de réponse'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formSearch.startDate && formSearch.endDate && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            dispatch(changeStartDate(''));
            dispatch(changeEndDate(''));
            setFormSearch(
              {
                ...formSearch,
                startDate: '',
                endDate: ''
              },
              data => {
                updateSearch(data, initialObjects, true);
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  const markAsAbsent = async user => {
    setIsLoading(true);
    setTrigger(Date.now());
    let form = {
      context: 'admin.markrecipientabsent',
      recipient: user.id,
      attachable_type: user.type === 'external' ? 'external' : 'user'
    };
    let response = await PutEvent(event.id, form);
    if (response.success === true) {
      setParentTrigger(Date.now());
      setTimeout(function () {
        setIsLoading(false);
        toast('Information enregistrée');
        setTimeout(function () {
          setTrigger(Date.now());
        }, 250);
      }, 2000);
    } else {
      setIsLoading(false);
      setTrigger(Date.now());
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const outWaitingList = async user => {
    setIsLoading(true);
    setTrigger(Date.now());
    let form = {
      context: 'admin.outwaitinglist',
      recipient: user.id,
      attachable_type: user.type === 'external' ? 'external' : 'user'
    };
    let response = await PutEvent(event.id, form);
    if (response.success === true) {
      setParentTrigger(Date.now());
      setTimeout(function () {
        setIsLoading(false);
        toast('Information enregistrée, la personne a reçu sa confirmation');
        setTimeout(function () {
          setTrigger(Date.now());
        }, 250);
      }, 2000);
    } else {
      setIsLoading(false);
      setTrigger(Date.now());
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        show={showCancel}
        onHide={() => {
          setShowCancel(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedCancel.id !== undefined && (
              <>La suppression des réponses est définitive.</>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={async () => {
              cancelRecipient({
                attachable_type: 'Event',
                attachable_id: event.id,
                user_id: selectedCancel.id,
                type: selectedCancel.type
              });
              setSelectedCancel(false);
              setShowCancel(false);
            }}
          >
            Oui, supprimer
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setSelectedCancel(false);
              setShowCancel(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Col xs={12} className="mt-1">
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12} className="text-end">
                <IndexLinks
                  event={event}
                  survey={survey}
                  setParentTrigger={setParentTrigger}
                />
              </Col>
            </Row>
            {initialObjects.length > 0 && (
              <>
                <Row>
                  <Col xs={6} className="mb-0">
                    <Form.Label>Recherche</Form.Label>
                    <Form.Control
                      type="search"
                      className="search-input"
                      placeholder="Rechercher quelque chose..."
                      aria-label="Search"
                      value={formSearch.search}
                      size="sm"
                      style={{ height: '38px' }}
                      onChange={e => {
                        dispatch(changeSearch(e.target.value));
                        setFormSearch(
                          {
                            ...formSearch,
                            search: e.target.value
                          },
                          data => {
                            updateSearch(data, initialObjects, true);
                          }
                        );
                      }}
                    />
                  </Col>
                  <Col xs={3} className="ps-0">
                    <Form.Label>Par date de réponse</Form.Label>
                    <DatePicker
                      onChange={dates => {
                        const [start, end] = dates;
                        dispatch(changeStartDate(start));
                        dispatch(changeEndDate(end));
                        setFormSearch(
                          {
                            ...formSearch,
                            startDate: start,
                            endDate: end
                          },
                          data => {
                            updateSearch(data, initialObjects, true);
                          }
                        );
                      }}
                      startDate={formSearch.startDate}
                      formatWeekDay={day => day.slice(0, 3)}
                      endDate={formSearch.endDate}
                      selectsRange
                      dateFormat="dd/MM/yy"
                      customInput={<DatePickerInput />}
                      locale="fr"
                    />
                  </Col>
                  <Col xs={3} className="ps-0">
                    <Form.Label>Par réponse</Form.Label>
                    <Form.Select
                      aria-label="Par réponse"
                      name="participate"
                      value={formSearch.participate}
                      onChange={e => {
                        dispatch(changeParticipate(e.target.value));
                        setFormSearch(
                          {
                            ...formSearch,
                            participate: e.target.value
                          },
                          data => {
                            updateSearch(data, initialObjects, true);
                          }
                        );
                      }}
                    >
                      <option value="">Choisir...</option>
                      <option value="Participe">Participe</option>
                      <option value="Ne participe pas">Ne participe pas</option>
                      <option value="A répondu">A répondu</option>
                      <option value="N'a pas répondu">N'a pas répondu</option>
                      <option value="En liste d'attente">
                        En liste d'attente
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
                {objects.length > 0 ? (
                  <Row>
                    <Col xs={12}>
                      <Table
                        data={objects}
                        event={event}
                        formSearch={formSearch}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="mb-11">
                    <Col xs={12}>
                      <Alert variant="warning">Aucun résultat.</Alert>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

RecipientsTableEvent.propTypes = {
  recipients: PropTypes.array,
  survey: PropTypes.object,
  event: PropTypes.object,
  setParentTrigger: PropTypes.func
};

// Définir une fonction de filtrage personnalisée qui gère les valeurs undefined
function includesFilter(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    // Vérifier si rowValue est défini
    if (rowValue === undefined || rowValue === null) {
      return false;
    }
    return String(rowValue).includes(filterValue);
  });
}

// SelectColumnFilter component
function SelectColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculer les options pour le filtrage
  const options = React.useMemo(() => {
    const options = new Set();
    // eslint-disable-next-line react/prop-types
    preFilteredRows.forEach(row => {
      const value = row.values[id];
      if (value !== null && value !== undefined && value !== '') {
        options.add(value);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Rendre un champ select
  return (
    <Form.Control
      as="select"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      style={{ width: '100%' }}
      size="sm"
    >
      <option value="">Tous</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
  );
}

function Table({ data, event, formSearch }) {
  const filterTypes = React.useMemo(
    () => ({
      includes: includesFilter
    }),
    []
  );

  function checkAndConvertNumber(cellValue) {
    if (
      cellValue !== null &&
      cellValue !== undefined &&
      cellValue !== '' &&
      !isNaN(cellValue)
    ) {
      return String(cellValue).replace('.', ',');
    }
    return cellValue || '';
  }

  const tmpColumns = [
    {
      accessor: 'company',
      Header: 'Entreprise',
      disableFilters: true
    },
    {
      accessor: 'user',
      Header: 'Destinataire',
      disableFilters: true
    },
    {
      Header: 'Participe ?',
      accessor: 'participateFilter',
      id: 'participate',
      Cell: ({ row }) => row.original.participate,
      Filter: SelectColumnFilter,
      filter: 'includes'
    }
  ];

  // Ajout des colonnes dynamiques basées sur les questions du formulaire de participation
  if (event.participation_form) {
    event.participation_form.questions
      .filter(q => q.is_participation_form_main_question !== 1 && !q.group_id)
      .sort((a, b) => a.order - b.order)
      .map(question => {
        tmpColumns.push({
          accessor:
            question.type.slug === 'review'
              ? `question_review_${question.id}`
              : `question_${question.id}`,
          Header: question.title,
          Cell: ({ value }) => checkAndConvertNumber(value),
          Filter: SelectColumnFilter,
          filter: 'includes'
        });
      });
    if (event.participation_form.question_groups) {
      event.participation_form.question_groups
        .sort((a, b) => a.order - b.order)
        .map(group => {
          event.participation_form.questions
            .filter(q => q.group_id === group.id)
            .sort((a, b) => a.order - b.order)
            .map(question => {
              tmpColumns.push({
                accessor:
                  question.type.slug === 'review'
                    ? `question_review_${question.id}`
                    : `question_${question.id}`,
                Header: question.title,
                Cell: ({ value }) => checkAndConvertNumber(value),
                Filter: SelectColumnFilter,
                filter: 'includes'
              });
            });
        });
    }
  }

  tmpColumns.push({
    accessor: 'survey_completed_on',
    Header: 'Date réponse',
    disableFilters: true,
    Cell: ({ value }) => checkAndConvertNumber(value),
    sortType: (a, b) => {
      var a1 = new Date(a).getTime();
      var b1 = new Date(b).getTime();
      if (a1 < b1) return 1;
      else if (a1 > b1) return -1;
      else return 0;
    }
  });
  tmpColumns.push({
    accessor: 'respondant',
    Header: 'Qui a répondu en dernier ?',
    disableFilters: true
  });
  tmpColumns.push({
    accessor: 'survey_completed_after_x_dunnings',
    Header: 'A répondu après X relances',
    disableFilters: true
  });
  tmpColumns.push({
    accessor: 'email',
    Header: 'Adresse email',
    disableFilters: true
  });

  const columns = React.useMemo(() => tmpColumns, []);

  const defaultColumn = {
    Filter: SelectColumnFilter,
    filter: 'includes'
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // Ajout de rows
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 15,
        sortBy: [{ id: 'company', desc: false }]
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Préparation des données filtrées pour l'export
  const filteredDataForExport = React.useMemo(
    () => rows.map(row => row.original),
    [rows]
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible" id="table-recipients">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      background: '#f9f9f9',
                      position: columnIndex < 2 ? 'sticky' : 'static',
                      zIndex: columnIndex < 2 ? 1 : 0,
                      left:
                        columnIndex < 2
                          ? columnIndex === 1
                            ? `${
                                formSearch.search === ''
                                  ? headerGroup.headers[0].width - 31
                                  : headerGroup.headers[0].width - 50
                              }px`
                            : '0px'
                          : 'auto'
                    }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* Added filter row */}
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={`filter-${index}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    key={`filter-th-${index}-${columnIndex}`}
                    style={{
                      background: '#f9f9f9',
                      position: columnIndex < 2 ? 'sticky' : 'static',
                      zIndex: columnIndex < 2 ? 1 : 0,
                      top: '56px', // Adjust based on your header height
                      left:
                        columnIndex < 2
                          ? columnIndex === 1
                            ? `${
                                formSearch.search === ''
                                  ? headerGroup.headers[0].width - 31
                                  : headerGroup.headers[0].width - 50
                              }px`
                            : '0px'
                          : 'auto'
                    }}
                  >
                    {column.canFilter ? column.render('Filter') : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td
                        key={indexCell}
                        {...cell.getCellProps()}
                        style={{
                          background: 'white',
                          position: indexCell < 2 ? 'sticky' : 'static',
                          left:
                            indexCell < 2
                              ? indexCell === 1
                                ? `${
                                    formSearch.search === ''
                                      ? headerGroups[0].headers[0].width - 31
                                      : headerGroups[0].headers[0].width - 50
                                  }px`
                                : '0px'
                              : 'auto',
                          zIndex: indexCell < 2 ? 0 : 'auto'
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            if (document.getElementById('table-recipients')) {
              document.getElementById('table-recipients').scrollIntoView();
            }
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            if (document.getElementById('table-recipients')) {
              document.getElementById('table-recipients').scrollIntoView();
            }
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            if (document.getElementById('table-recipients')) {
              document.getElementById('table-recipients').scrollIntoView();
            }
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            if (document.getElementById('table-recipients')) {
              document.getElementById('table-recipients').scrollIntoView();
            }
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        {/* Export des données filtrées */}
        <ExportRecipients
          recipients={filteredDataForExport}
          object={event}
          context="event"
        />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  survey: PropTypes.object,
  event: PropTypes.object,
  formSearch: PropTypes.object
};

export default RecipientsTableEvent;
