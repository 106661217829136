import React, { useEffect, useState, Fragment } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexUser } from '../../../../services/UserService';
import { IndexCompany } from '../../../../services/CompanyService';
import { useNavigate } from 'react-router';
import { membersRoutes } from '../../../../routes/authRoutes';

const TrouverUnMembre = () => {
  const [trigger] = useState(Date.now);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(9);
  const trombiSearch = JSON.parse(localStorage.getItem('trombisearch'));
  const [formData, setFormData] = useState({
    company_id:
      trombiSearch && trombiSearch.company_id ? trombiSearch.company_id : '',
    firstname: '',
    lastname: '',
    gender: '',
    occupation: ''
  });
  let navigate = useNavigate();
  useEffect(() => {
    const fetchUsers = async () => {
      const responseUsers = await IndexUser();
      if (responseUsers.success === true) {
        setUsers(responseUsers.data);
        setOriginalUsers(responseUsers.data);
        if (formData.company_id !== '') {
          setUsers(
            responseUsers.data.filter(user => {
              //console.log('user.main_company', user.main_company);
              return (
                user.main_company &&
                parseInt(user.main_company.id) === parseInt(formData.company_id)
              );
            })
          );
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexCompany();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUsers();
    fetchCompanies();
  }, [trigger]);

  const handleFieldChange = e => {
    const tmpFormData = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setFormData(tmpFormData);
    localStorage.setItem('trombisearch', JSON.stringify(tmpFormData));
    let filteredResuts = originalUsers;
    if (tmpFormData.company_id !== '') {
      filteredResuts = filteredResuts.filter(
        user =>
          user.main_company &&
          parseInt(user.main_company.id) === parseInt(tmpFormData.company_id)
      );
    }
    if (tmpFormData.lastname !== '') {
      filteredResuts = filteredResuts.filter(
        user =>
          user.lastname
            .toLowerCase()
            .indexOf(tmpFormData.lastname.toLowerCase()) > -1
      );
    }
    if (tmpFormData.firstname !== '') {
      filteredResuts = filteredResuts.filter(
        user =>
          user.firstname
            .toLowerCase()
            .indexOf(tmpFormData.firstname.toLowerCase()) > -1
      );
    }
    if (tmpFormData.occupation !== '') {
      filteredResuts = filteredResuts.filter(
        user =>
          user.occupation &&
          user.occupation
            .toLowerCase()
            .indexOf(tmpFormData.occupation.toLowerCase()) > -1
      );
    }
    setUsers(filteredResuts);
  };

  return (
    <Row className="mt-2">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d={
                membersRoutes.filter(route => route.id === 'trouverMembre')[0]
                  .svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          {membersRoutes.filter(route => route.id === 'trouverMembre')[0].label}
        </h5>
        <Form>
          <Row className="align-items-center justify-content-center">
            <Col xs={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Société</Form.Label>
                <Form.Select
                  size="sm"
                  aria-label="Filtrer par société"
                  name="company_id"
                  value={formData.company_id}
                  onChange={e => handleFieldChange(e)}
                >
                  <option value="">Choisir une société dans la liste</option>
                  {companies.map((company, index) => (
                    <option value={company.id} key={index}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={4}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Rechercher par nom"
                  name="lastname"
                  value={formData.lastname}
                  onChange={e => handleFieldChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Prénom</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Rechercher par prénom"
                  name="firstname"
                  value={formData.firstname}
                  onChange={e => handleFieldChange(e)}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Fonction</Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Rechercher par fonction"
                  name="occupation"
                  value={formData.occupation}
                  onChange={e => handleFieldChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
      {users.length > 0 && (
        <Col xs={12} className="mt-3">
          <Row>
            {users.map((user, index) => (
              <Fragment key={index}>
                {index < currentPagination && (
                  <Col
                    xs={6}
                    lg={4}
                    key={index}
                    className="mb-4 trombi-container d-flex align-items-stretch"
                  >
                    <Card
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          `/adherents/${user.main_company.id}?back=${window.location.pathname}&user=${user.id}`
                        );
                      }}
                    >
                      <div
                        className="image-top-container"
                        style={{
                          backgroundImage: `url("${user.profile_picture_url}")`
                        }}
                      ></div>
                      <Card.Body>
                        <Card.Title as="h5">
                          {user.firstname} {user.lastname}
                        </Card.Title>
                        {user.mainCompany !== null && (
                          <p className="text-red mb-0">
                            {user.main_company.name}
                          </p>
                        )}
                        <p className="mb-0">{user.occupation}</p>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Fragment>
            ))}
            {users.length > currentPagination && (
              <Col xs={12} id="trombi-paginate" className="text-center">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setCurrentPagination(currentPagination + 9);
                  }}
                >
                  Voir plus de membres
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default TrouverUnMembre;
