import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableRow = ({ data }) => {
  const { label, value, color } = data;
  return (
    <tr>
      <td className="py-3">
        <Flex alignItems="center">
          <p className="text-600 mb-0 ms-2">{label}</p>
        </Flex>
      </td>
      <td className="py-3 text-end">
        <p className="text-700 fw-normal mb-0">
          <FontAwesomeIcon
            icon="circle"
            className={`fs--2 me-2`}
            style={{ color: color }}
          />
          {Math.round(value)}
        </p>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired
};

export default TableRow;
