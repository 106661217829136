let list = [
  {
    key: 'commission',
    label: 'Commission',
    filter: true,
    admin: false
  },
  {
    key: 'resp_achat_tu',
    label: 'Responsable achat TU',
    filter: false,
    admin: false
  },
  {
    key: 'partenaire',
    label: 'Partenaire',
    filter: false,
    admin: false
  },
  {
    key: 'partenaire_telephone',
    label: 'Téléphone',
    filter: false,
    admin: false
  },
  {
    key: 'partenaire_email',
    label: 'Mail partenaire',
    filter: false,
    admin: false
  },
  {
    key: 'date_debut_contrat',
    label: 'Date de début du Contrat',
    filter: false,
    type: 'date',
    admin: false
  },
  {
    key: 'date_fin_contrat',
    label: 'Date de fin du Contrat',
    filter: false,
    type: 'date',
    admin: false
  },
  {
    key: 'etat_contrat',
    label: 'État de contrat',
    filter: true,
    editable: false,
    admin: false
  },
  {
    key: 'facturation_email',
    label: 'Mail facturation',
    filter: false
  },
  {
    key: 'type_contrat',
    label: 'Type de contrat',
    filter: true,
    admin: false
  }
];

[2023, 2024, 2025].map(year => {
  list.push({
    key: `conditions_caa_${year}`,
    label: `Conditions CAA ${year}`,
    filter: false,
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `conditions_rfa_${year}`,
    label: `Conditions RFA ${year}`,
    filter: false,
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `resultat_${year}`,
    label: `Résultat ${year}`,
    filter: false,
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `montant_caa_${year}`,
    label: `Montant CAA ${year}`,
    filter: false,
    sort: true,
    type: 'number',
    year: year,
    filterColsCaa: true,
    filterColsRfa: false,
    filterColsSalon: false
  });
  list.push({
    key: `montant_rfa_${year}`,
    label: `Montant RFA ${year}`,
    filter: false,
    sort: true,
    type: 'number',
    year: year,
    filterColsCaa: false,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `montant_ht_total_${year}`,
    label: `Montant HT total ${year}`,
    filter: false,
    sort: true,
    editable: false,
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsCaaPlusRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `date_depot_facture_caa_rfa_${year}`,
    label: `Date de dépôt facture CAA RFA ${year}`,
    filter: false,
    sort: false,
    editable: false,
    type: 'date',
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `facture_import_caa_rfa_${year}`,
    label: `Espace Dépôt Facture CAA RFA ${year}`,
    filter: false,
    sort: true,
    editable: false,
    isFile: true,
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `etat_facture_caa_rfa_${year}`,
    label: `État de facture CAA RFA ${year}`,
    filter: true,
    editable: false,
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `date_paiement_caa_rfa_${year}`,
    label: `Date de virement de facture CAA RFA ${year}`,
    filter: false,
    sort: false,
    type: 'date',
    year: year,
    filterColsCaa: true,
    filterColsRfa: true,
    filterColsSalon: false
  });
  list.push({
    key: `montant_salon_${year}`,
    label: `Montant SALON HT ${year}`,
    filter: false,
    sort: false,
    type: 'number',
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
  list.push({
    key: `reference_salon_${year}`,
    label: `Référence ${year}`,
    filter: false,
    sort: false,
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
  list.push({
    key: `date_depot_facture_salon_${year}`,
    label: `Date de dépôt facture SALON ${year}`,
    filter: false,
    sort: false,
    editable: false,
    type: 'date',
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
  list.push({
    key: `facture_import_salon_${year}`,
    label: `Espace Dépôt Facture SALON ${year}`,
    filter: false,
    sort: true,
    editable: false,
    isFile: true,
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
  list.push({
    key: `etat_facture_salon_${year}`,
    label: `État de facture SALON ${year}`,
    filter: true,
    editable: false,
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
  list.push({
    key: `date_paiement_salon_${year}`,
    label: `Date de virement de facture SALON ${year}`,
    filter: false,
    sort: false,
    type: 'date',
    year: year,
    filterColsCaa: false,
    filterColsRfa: false,
    filterColsSalon: true
  });
});

export const contractMetaList = list;
