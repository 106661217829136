import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'elearning/logs';

export async function IndexLog() {
  return modelService.IndexModel(model);
}

export async function PostLog(formData) {
  return modelService.PostModel(model, formData);
}
