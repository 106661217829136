import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'point-typology-categories';

export async function IndexPointTypologyCategory() {
  return modelService.IndexModel(`${model}`);
}

export async function PostCategory(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutCategory(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteCategory(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
