import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IndexResourceCategoryMember } from '../../../../services/ResourceCategoryService';

import pictoPdf from '../../../../assets/img/tredunion/pictos/file-pdf.svg';
import IconButton from '../../../common/IconButton';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { PostTrack } from '../../../../services/ResourceTrackService';
import { membersRoutes } from '../../../../routes/authRoutes';
import { useSearchParams } from 'react-router-dom';
import { ShowResource } from '../../../../services/ResourceService';
import TredResourceTable from './TredRessourcesTable';

const TredRessources = () => {
  const [searchParams] = useSearchParams();
  const [trigger, setTrigger] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(true);
  const [waitings, setWaitings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [clickedLabelHistory, setClickedLabelHistory] = useState([]);
  const [uniqueResource, setUniqueResource] = useState({});
  const [resourceCategoriesOriginal, setResourceCategoriesOriginal] = useState(
    []
  );
  const [
    resourceCategoriesOriginalSplitted,
    setResourceCategoriesOriginalSplitted
  ] = useState([]);
  const [resourceOriginalSplitted, setResourceOriginalSplitted] = useState([1]);
  const [resourceCategoriesHistory, setResourceCategoriesHistory] = useState(
    []
  );
  const [resourceCategories, setResourceCategories] = useState([]);
  const [resources, setResources] = useState([]);
  const saveTracking = async resource => {
    setIsLoading(true);
    const response = await PostTrack({
      resource_id: resource.id,
      track_category_id: 2
    });
    if (response.success === true && response.data) {
      window.location.href = response.data.resource.file_url;
      setIsLoading(false);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const colors = Array(
    'e37777',
    '903a3a',
    '8f3838',
    '635d5d',
    '3c3c3c',
    '832525',
    '650b0b',
    '6c3c3c',
    '907575',
    'b24343',
    'af4c4c',
    'a40404',
    '381f1f',
    '541c1c',
    '795e5e',
    'a93f3f',
    'c91212',
    '1c1313',
    '612020',
    'cf3434',
    '730606',
    'd64a4a',
    '654848',
    '535050',
    '888888',
    '342525'
  );

  const getRecursiveCategories = (category, categories, resources) => {
    categories.push(category);
    category.resources
      .filter(r => r.is_moderated === 1)
      .map(resource => {
        resources.push(resource);
      });
    if (category.childrens.length > 0) {
      category.childrens.map(children => {
        getRecursiveCategories(children, categories, resources);
      });
    }
  };

  useEffect(() => {
    setResources([]);
    setResourceCategoriesHistory([]);
    const fetchResourceCategories = async () => {
      const responseResourceCategories = await IndexResourceCategoryMember();
      if (responseResourceCategories.success === true) {
        setResourceCategoriesOriginal(responseResourceCategories.data);
        setResourceCategoriesHistory([
          ...resourceCategoriesHistory,
          responseResourceCategories.data
        ]);
        setResourceCategories(responseResourceCategories.data);
        let splittedCategories = [];
        let splittedResources = [];
        responseResourceCategories.data.map(category => {
          getRecursiveCategories(
            category,
            splittedCategories,
            splittedResources
          );
        });
        setResourceCategoriesOriginalSplitted(splittedCategories);
        setWaitings(
          splittedResources
            .filter(r => {
              if (r.tracks.length === 0) {
                let date = new Date(r.created_at);
                let dateLimit = new Date('2023-12-01 00:00:00');
                return date > dateLimit;
              } else {
                return false;
              }
            })
            ?.sort((a, b) => {
              return b.id - a.id;
            })
        );
        setResourceOriginalSplitted(splittedResources);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchResourceCategories();

    if (searchParams.get('id')) {
      const fetchResource = async () => {
        const responseResource = await ShowResource(searchParams.get('id'));
        if (responseResource.success === true) {
          setUniqueResource(responseResource.data);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      };
      fetchResource();
    }
  }, [trigger]);

  const navigateContent = id => {
    setResourceCategoriesHistory([
      ...resourceCategoriesHistory,
      resourceCategories
    ]);
    setSearchInput('');
    setNoResults(false);
    let resourceCategory = resourceCategories.filter(
      category => category.id === id
    )[0];
    if (resourceCategory.childrens.length > 0) {
      setResourceCategories(resourceCategory.childrens);
    } else {
      setResourceCategories([resourceCategory]);
    }
    setResources(
      resourceCategory.resources
        .filter(r => r.is_moderated === 1)
        .sort((a, b) => {
          return b.id - a.id;
        })
    );
  };

  const handleSearch = e => {
    const searchValue = e.target.value.toLowerCase();

    setSearchInput(searchValue);

    if (searchValue.length > 0) {
      const filteredCategories = resourceCategoriesOriginalSplitted.filter(
        category => category.title.toLowerCase().includes(searchValue)
      );
      setResourceCategories(filteredCategories);

      const filteredResources = resourceOriginalSplitted.filter(resource =>
        resource.title.toLowerCase().includes(searchValue)
      );
      setResources(filteredResources);

      if (filteredCategories.length === 0 && filteredResources.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    } else {
      setResourceCategories(resourceCategoriesOriginal);
      setResources([]);
      setNoResults(false);
    }
  };

  return (
    <Row className="mt-2" id="tred-ressources">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d={
                membersRoutes.filter(route => route.id === 'tredRessources')[0]
                  .svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          {
            membersRoutes.filter(route => route.id === 'tredRessources')[0]
              .label
          }{' '}
          {clickedLabelHistory.length > 0 && (
            <>{`- ${clickedLabelHistory.at(-1)}`}</>
          )}
        </h5>
      </Col>
      {uniqueResource && uniqueResource.id && (
        <Col xs={12} className="mt-3">
          <Row className="px-1">
            <Col xs={12}>
              <Table responsive>
                <tbody>
                  <tr className="hover-actions-trigger">
                    <td>
                      <img
                        src={pictoPdf}
                        className="d-none d-lg-inline"
                        style={{ width: '14px' }}
                      />{' '}
                      {uniqueResource.title}
                    </td>
                    <td className="text-end">
                      <IconButton
                        className="mb-1 me-lg-2"
                        variant="falcon-default"
                        size="sm"
                        icon={faFileDownload}
                        transform="shrink-3"
                        disable={isLoading}
                        onClick={async () => {
                          saveTracking(uniqueResource);
                        }}
                      >
                        Accéder
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      )}
      {waitings.length > 0 && (
        <Col xs={12} className="mt-3">
          <Row className="px-1">
            <Col xs={12}>
              <div className="alert alert-info" role="alert">
                <span className="alert-heading title" style={{ fontSize: 17 }}>
                  <b>
                    <span
                      className="badge badge-subtle-warning px-2 me-2"
                      style={{ color: '#b82c46', backgroundColor: '#fbdbe1' }}
                    >
                      {waitings.length}
                    </span>
                    Vos nouvelles ressources à télécharger :
                  </b>
                </span>
                <TredResourceTable
                  resourceCategoriesOriginalSplitted={
                    resourceCategoriesOriginalSplitted
                  }
                  isLoading={isLoading}
                  saveTracking={saveTracking}
                  setTrigger={setTrigger}
                  waitings={waitings}
                />
              </div>
            </Col>
          </Row>
        </Col>
      )}
      {isLoading === true && (
        <Col xs={12}>
          <Spinner animation="border" role="status" size="xs">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      )}
      <Col xs={12} className="mt-3">
        <Row className="align-items-center mb-3">
          <Col xs={12}>
            <Form.Control
              type="search"
              className="search-input"
              placeholder="Rechercher quelque chose..."
              aria-label="Search"
              value={searchInput}
              size="sm"
              onChange={handleSearch}
            />
          </Col>
          {noResults && (
            <Col xs={12} className="mt-3">
              <p>Aucun résultat pour votre recherche.</p>
            </Col>
          )}
        </Row>
      </Col>
      {resourceOriginalSplitted.length === 0 ? (
        <Col xs={12}>
          <p>Aucune ressource à afficher pour le moment.</p>
        </Col>
      ) : (
        <>
          {resourceCategories.length > 0 && (
            <Col xs={12}>
              {resourceCategoriesOriginalSplitted.length > 0 && <></>}
              <Row className="px-3">
                {resourceCategories.filter(
                  category =>
                    category.parent_id !== undefined &&
                    category.parent_id !== null
                ).length > 0 && (
                  <Col xs={12} className="px-1 mt-1 mb-3">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        if (searchInput.length > 0) {
                          setSearchInput('');
                          setTrigger(Date.now());
                        } else {
                          clickedLabelHistory.splice(-1, 1);
                          if (searchInput.length > 0) {
                            setSearchInput('');
                            setResourceCategories(resourceCategoriesOriginal);
                            setResourceCategoriesHistory([]);
                            setResources([]);
                            setClickedLabelHistory([]);
                          } else {
                            if (resourceCategoriesHistory.length > 1) {
                              let history =
                                resourceCategoriesHistory[
                                  resourceCategoriesHistory.length - 1
                                ];
                              setResourceCategories(history);
                              setResources(
                                resourceCategoriesHistory[
                                  resourceCategoriesHistory.length - 1
                                ][0]?.resources.filter(
                                  r => r.is_moderated === 1
                                )
                              );
                              setResourceCategoriesHistory(
                                resourceCategoriesHistory.slice(0, -1)
                              );
                            } else {
                              setResourceCategories(resourceCategoriesOriginal);
                              setResources([]);
                            }
                          }
                        }
                      }}
                    >
                      &lt; Retour
                    </Button>
                  </Col>
                )}

                {resourceCategoriesHistory[
                  resourceCategoriesHistory.length - 1
                ] &&
                  resourceCategories[resourceCategories.length - 1]?.id !==
                    resourceCategoriesHistory[
                      resourceCategoriesHistory.length - 1
                    ][0]?.id && (
                    <Fragment>
                      {resourceCategories.map((category, index) => (
                        <Fragment key={index}>
                          {(category.childrens.length > 0 ||
                            category.resources.length > 0) && (
                            <Col
                              xs={6}
                              lg={3}
                              className="px-1 py-1 d-flex align-items-center"
                            >
                              <div
                                className="item-container d-flex align-items-end px-2"
                                style={{
                                  backgroundColor: `#${
                                    colors.reverse()[
                                      [...category.title]
                                        .map(a => parseInt(a, 36) - 10)
                                        .filter(a => a >= 0)[0]
                                    ] ?? '832525'
                                  }`
                                }}
                                onClick={() => {
                                  navigateContent(category.id);
                                  let tmpLabelHistory = clickedLabelHistory;
                                  tmpLabelHistory.push(category.title);
                                  setClickedLabelHistory(tmpLabelHistory);
                                }}
                              >
                                <div className="title d-block pb-3 w-100 text-center">
                                  {category.title}
                                </div>
                              </div>
                            </Col>
                          )}
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
              </Row>
            </Col>
          )}
          {resources.length > 0 && (
            <Col xs={12} className="mt-3">
              <Row className="px-1">
                <Col xs={12}>
                  <Table responsive>
                    <tbody>
                      {resources.map((resource, index) => (
                        <tr key={index} className="hover-actions-trigger">
                          <td>
                            <img
                              src={pictoPdf}
                              className="d-none d-lg-inline"
                              style={{ width: '14px' }}
                            />{' '}
                            {resource.title}
                          </td>
                          <td className="text-end">
                            <IconButton
                              className="mb-1 me-lg-2"
                              variant="falcon-default"
                              size="sm"
                              icon={faFileDownload}
                              transform="shrink-3"
                              disable={isLoading}
                              onClick={async () => {
                                saveTracking(resource);
                              }}
                            >
                              Accéder
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default TredRessources;
