import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import * as Moment from 'moment';
import moment from 'moment';

const ExportRecipients = ({ recipients, object, context }) => {
  const [cleanData, setCleanData] = useState([]);
  const [cleanQuestions, setCleanQuestions] = useState([]);
  let tmpCleanData = [];
  let tmpCleanQuestions = [];

  function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  }

  useEffect(() => {
    let foundReviewQuestion = false;
    let objectToMap = context === 'event' ? object.participation_form : object;
    recipients.map(user => {
      let cleanedCode = {
        company:
          typeof user.company === 'string'
            ? user.company?.toUpperCase()
            : user.company?.props?.children?.toUpperCase(),
        lastname: user.initialObject?.lastname?.toUpperCase(),
        firstname: capitalizeFirstLetter(user.initialObject?.firstname),
        email: user.initialObject?.email,
        waitinglist: user.initialObject?.pivot?.waiting_list === 1 ? 'Oui' : ''
        /*recipient: user.initialObject.firstname
          ? `${user.initialObject.lastname} ${user.initialObject.firstname}`
          : user.initialObject.email*/
      };
      objectToMap.questions
        .filter(q => !q.group_id)
        .map(question => {
          if (question.type && question.type.slug === 'datetime') {
            const momentCheck = Moment(
              user[`question_${question.id}`],
              'DD/MM/YYYY HH:mm'
            );
            cleanedCode = {
              ...cleanedCode,
              [`question_${question.id}`]: momentCheck.isValid()
                ? momentCheck.local().format('DD/MM/YYYY HH:mm')
                : ''
            };
          } else {
            if (question.is_participation_form_main_question) {
              cleanedCode = {
                ...cleanedCode,
                [`question_${question.id}`]:
                  user.initialObject.survey_questions.find(
                    q => q.id === question.id
                  )?.pivot.value
              };
            } else {
              cleanedCode = {
                ...cleanedCode,
                [`question_${question.id}`]: user.initialObject.survey_questions
                  ? user.initialObject.survey_questions.find(
                      q => q.id === question.id && !q.group_id
                    )
                    ? user.initialObject.survey_questions.find(
                        q => q.id === question.id && !q.group_id
                      ).pivot
                      ? `${user[`question_${question.id}`]} ${
                          user.initialObject.survey_questions.find(
                            q => q.id === question.id && !q.group_id
                          ).is_participation_form_main_question === 1 &&
                          user.initialObject.survey_questions.find(
                            q => q.id === question.id && !q.group_id
                          ).pivot.value === 'Oui' &&
                          user.initialObject.pivot.did_not_come_to_event === 1
                            ? "(Ne s'est pas présenté)"
                            : ''
                        }`
                      : ''
                    : ''
                  : ''
              };
            }
          }
          if (question.type && question.type.slug === 'review') {
            foundReviewQuestion = true;
            cleanedCode = {
              ...cleanedCode,
              [`question_comment_${question.id}`]: user.initialObject
                .survey_questions
                ? user.initialObject.survey_questions.find(
                    q => q.id === question.id && !q.group_id
                  )
                  ? user.initialObject.survey_questions.find(
                      q => q.id === question.id && !q.group_id
                    ).pivot
                    ? user.initialObject.survey_questions.find(
                        q => q.id === question.id && !q.group_id
                      ).pivot.comment
                    : ''
                  : ''
                : ''
            };
            console.log(cleanedCode);
          }
        });
      objectToMap.question_groups.map(group => {
        objectToMap.questions
          .filter(q => q.group_id === group.id)
          .map(question => {
            if (question.type && question.type.slug === 'datetime') {
              const momentCheck = Moment(
                user[`question_${question.id}`],
                'DD/MM/YYYY HH:mm'
              );
              cleanedCode = {
                ...cleanedCode,
                [`question_${question.id}`]: momentCheck.isValid()
                  ? momentCheck.local().format('DD/MM/YYYY HH:mm')
                  : ''
              };
            } else {
              cleanedCode = {
                ...cleanedCode,
                [`question_${question.id}`]: user[`question_${question.id}`]
              };
            }
            if (question.type && question.type.slug === 'review') {
              foundReviewQuestion = true;
              cleanedCode = {
                ...cleanedCode,
                [`question_comment_${question.id}`]: user.initialObject
                  .survey_questions
                  ? user.initialObject.survey_questions.find(
                      q => q.id === question.id && q.group_id === group.id
                    )
                    ? user.initialObject.survey_questions.find(
                        q => q.id === question.id && q.group_id === group.id
                      ).pivot
                      ? user.initialObject.survey_questions.find(
                          q => q.id === question.id && q.group_id === group.id
                        ).pivot.comment
                      : ''
                    : ''
                  : ''
              };
            }
          });
      });
      cleanedCode = {
        ...cleanedCode,
        survey_completed_on: user.survey_completed_on || '',
        survey_completed_after_x_dunnings:
          user.survey_completed_on &&
          user.initialObject.pivot &&
          user.initialObject.pivot.survey_completed_after_x_dunnings !== -1
            ? user.initialObject.pivot.survey_completed_after_x_dunnings
            : '',
        email: user.initialObject.email
      };
      tmpCleanData.push(cleanedCode);
    });
    if (foundReviewQuestion) {
      let emptyItem = {};
      Object.keys(tmpCleanData[0]).map(key => {
        emptyItem = {
          ...emptyItem,
          [key]: ''
        };
      });
      tmpCleanData.push(emptyItem);
      let lastItem = {};
      Object.keys(tmpCleanData[0]).map(key => {
        if (key.indexOf('question_comment_') !== -1) {
          lastItem = {
            ...lastItem,
            [`question_${key.split('_')[2]}`]: (
              tmpCleanData.reduce((r, c) => {
                if (parseInt(c[`question_${key.split('_')[2]}`]) > 0) {
                  return r + parseInt(c[`question_${key.split('_')[2]}`]);
                } else {
                  return r;
                }
              }, 0) /
              tmpCleanData.filter(
                data => data[`question_${key.split('_')[2]}`] > 0
              ).length
            ).toFixed(1)
          };
        } else if (key === 'company') {
          lastItem = {
            ...lastItem,
            [key]: 'MOYENNE DES NOTES'
          };
        } else {
          lastItem = {
            ...lastItem,
            [key]: ''
          };
        }
      });
      tmpCleanData.push(lastItem);
    }
    setCleanData(tmpCleanData);
    objectToMap.questions
      .filter(q => !q.group_id)
      .map(question => {
        tmpCleanQuestions.push({
          id: question.id,
          title: question.title,
          value: `question_${question.id}`,
          type: question.type ? question.type.slug : ''
        });
        if (question.type && question.type.slug === 'review') {
          tmpCleanQuestions.push({
            id: `comment_${question.id}`,
            title: `Commentaire`,
            value: `question_comment_${question.id}`,
            type: question.type ? question.type.slug : ''
          });
        }
      });
    objectToMap.question_groups.map(group => {
      tmpCleanQuestions.push({
        id: group.id,
        title: group.title,
        value: group.title,
        type: 'group'
      });
      objectToMap.questions
        .filter(q => q.group_id === group.id)
        .map(question => {
          tmpCleanQuestions.push({
            id: question.id,
            title: question.title,
            value: `question_${question.id}`,
            type: question.type ? question.type.slug : ''
          });
          if (question.type && question.type.slug === 'review') {
            tmpCleanQuestions.push({
              id: `comment_${question.id}`,
              title: `Commentaire`,
              value: `question_comment_${question.id}`,
              type: question.type ? question.type.slug : ''
            });
          }
        });
    });
    setCleanQuestions(tmpCleanQuestions);
  }, [recipients]);
  return (
    <>
      {cleanData.length > 0 && cleanQuestions.length > 0 && (
        <>
          <ExcelFile
            filename={`${string_to_slug(object.title)}_${
              object.start_date
                ? moment(object.start_date).format('DD-MM-YYYY')
                : ''
            }_export-du-${moment().format('DD-MM-YYYY')}`}
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter les réponses
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name={string_to_slug(object.title)}>
              <ExcelColumn label="SOCIETE" value="company" />
              <ExcelColumn label="NOM" value="lastname" />
              <ExcelColumn label="Prénom" value="firstname" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="Liste d'attente" value="waitinglist" />
              {cleanQuestions.map((question, index) => (
                <ExcelColumn
                  key={index}
                  label={question.title}
                  value={row => {
                    const val = row[question.value];
                    if (/^\d+\.\d+$/.test(val)) {
                      return parseFloat(val.trim());
                    } else if (/^\d+$/.test(val)) {
                      return parseFloat(val.trim());
                    } else if (val) {
                      return val.trim();
                    } else {
                      return '';
                    }
                  }}
                />
              ))}
              <ExcelColumn label="Date réponse" value="survey_completed_on" />
              <ExcelColumn
                label="A répondu après X relances"
                value="survey_completed_after_x_dunnings"
              />
              <ExcelColumn label="Adresse email" value="email" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportRecipients.propTypes = {
  recipients: PropTypes.array.isRequired,
  object: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired
};

export default ExportRecipients;
