import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: ''
};

export const surveysParticipationSlice = createSlice({
  name: 'surveysParticipation',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeSearch } = surveysParticipationSlice.actions;

export default surveysParticipationSlice.reducer;
