import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'invoices';

export async function IndexInvoice() {
  return modelService.IndexModel(`${model}?context=memberarea`);
}

export async function IndexInvoiceAdmin() {
  return modelService.IndexModel(`${model}?context=admin`);
}

export async function DeleteInvoice(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PostInvoice(formData) {
  return modelService.PostModel(model, formData);
}
