import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'ads-pictures';

export async function PostAdPicture(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteAdPicture(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
