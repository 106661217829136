import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import ReduceAnswersChart from './reduceAnswersChart';
import TableRow from './TableRow';

const ReduceAnswers = ({ question, recipients }) => {
  const [dataChart, setDataChart] = useState([]);
  const [sumResponses, setSumResponses] = useState(0);
  useEffect(() => {
    let tmpData = [];
    if (question.answers && question.answers.length > 0) {
      question.answers.map(answer => {
        let value = 0;
        recipients.filter(recipient => {
          if (
            recipient.survey_questions &&
            recipient.survey_questions.filter(
              q =>
                q.id === question.id &&
                q.pivot &&
                (q.pivot.value === answer.title ||
                  q.pivot.value.split(', ').includes(answer.title))
            ).length > 0
          ) {
            value++;
          }
        });
        tmpData.push({
          color: answer.color,
          label: answer.title,
          value: value
        });
      });
    }
    let sumResponses = tmpData.reduce(function (acc, obj) {
      return acc + obj.value;
    }, 0);
    setSumResponses(sumResponses);
    setDataChart(tmpData.sort((a, b) => b.value - a.value));
  }, []);
  return (
    <>
      {dataChart.length > 0 && sumResponses > 0 && (
        <Col xs={4} className="mt-3">
          <Card className="h-100">
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                  <Form.Label>
                    <b>{question.title}</b>
                  </Form.Label>
                </Col>
              </Row>
              <div className="my-auto py-3 py-md-3">
                {dataChart.length > 0 && (
                  <ReduceAnswersChart
                    data={dataChart}
                    sumResponses={sumResponses}
                  />
                )}
              </div>
              <div className="border-top mt-2">
                <Table size="sm" className="mb-0">
                  <tbody>
                    {dataChart.map((item, index) => (
                      <Fragment key={index}>
                        {item.value > 0 && (
                          <TableRow key={item.label} data={item} />
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

ReduceAnswers.propTypes = {
  recipients: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
  survey: PropTypes.any.isRequired
};

export default ReduceAnswers;
