import React, { useEffect, useState } from 'react';
import Hero from './Hero';
import NavbarStandard from '../../commons/NavbarStandard';
import { IndexHomeSlide } from '../../../../services/HomeSlideService';
import { toast } from 'react-toastify';
import { IndexHome } from '../../../../services/CompanyService';
import { IndexTrade } from '../../../../services/TradeService';
import { IndexEventHomepage } from '../../../../services/Event/EventService';

import LogosTop from './LogosTop';
import Presentation from './Presentation';
import EventsList from './EventsList';
import Adherents from './Adherents';
import Reseau from './Reseau';
import Metiers from './Metiers';
import Actualites from './Actualites';
import { IndexPost } from '../../../../services/PostService';
import Partenaires from './Partenaires';
import { IndexPartner } from '../../../../services/PartnerService';
import Map from './Map';
import Contact from './Contact';
import FooterStandard from '../../commons/FooterStandard';
import Testimonial from '../../commons/Testimonial';
import CatalogueFormations from './CatalogueFormations';
import MoteurRecherche from './MoteurRecherche';

const Homepage = () => {
  const [trigger] = useState(Date.now());
  const [homeSlides, setHomeSlides] = useState([]);
  const [events, setEvents] = useState([]);
  const [posts, setPosts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [trades, setTrades] = useState([]);
  const [selectedTrade, setSelectedTrade] = useState('all');
  const [companiesSelectedTrade, setCompaniesSelectedTrade] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [reseauSectionSelected, setReseauSectionSelected] =
    useState('location');
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(function () {
        if (document.getElementById(window.location.hash.replace('#', ''))) {
          document
            .getElementById(window.location.hash.replace('#', ''))
            .scrollIntoView();
        }
      }, 2000);
    }
    const fetchHomeSlides = async () => {
      const responseHomeSlides = await IndexHomeSlide();
      if (responseHomeSlides.success === true) {
        setHomeSlides(responseHomeSlides.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexHome();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
        setCompaniesSelectedTrade(responseCompanies.data); // initialisation à "Tous"
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchTrades = async () => {
      const responseTrades = await IndexTrade();
      if (responseTrades.success === true) {
        setTrades(responseTrades.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPosts = async () => {
      const responsePosts = await IndexPost();
      if (responsePosts.success === true) {
        setPosts(responsePosts.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPartners = async () => {
      const responsePartners = await IndexPartner();
      if (responsePartners.success === true) {
        setPartners(responsePartners.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchEvents = async () => {
      const responseEvents = await IndexEventHomepage();
      if (responseEvents.success === true) {
        setEvents(responseEvents.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchHomeSlides();
    fetchCompanies();
    fetchTrades();
    fetchPosts();
    fetchPartners();
    fetchEvents();
  }, [trigger]);

  return (
    <>
      <NavbarStandard />
      <Hero homeSlides={homeSlides} />
      <LogosTop companies={companies} />
      {events && events.length > 0 && <EventsList events={events} />}
      <CatalogueFormations />
      <Presentation />
      <MoteurRecherche companies={companies} />
      <Adherents companies={companies} />
      <Reseau
        reseauSectionSelected={reseauSectionSelected}
        setReseauSectionSelected={setReseauSectionSelected}
        selectedTrade={selectedTrade}
        setSelectedTrade={setSelectedTrade}
        companiesSelectedTrade={companiesSelectedTrade}
        setCompaniesSelectedTrade={setCompaniesSelectedTrade}
        companies={companies}
        trades={trades}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
      <Metiers />
      <Actualites posts={posts} showPagination={false} />
      <Partenaires partners={partners} />
      <Map />
      <Contact />
      <Testimonial />
      <FooterStandard />
    </>
  );
};

export default Homepage;
