import React, { Fragment, useEffect, useState } from 'react';
import {
  DeleteCategory,
  IndexPointTypologyCategory,
  PostCategory,
  PutCategory
} from '../../../../../../services/Point/PointTypologyCategoryService';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SoftBadge from '../../../../../common/SoftBadge';
import {
  DeleteTypology,
  PostTypology,
  PutTypology
} from '../../../../../../services/Point/PointTypologyService';
import FormAlert from '../../../../commons/FormAlert';

const ManageTypologies = ({ setParentTrigger, setGrandParentTrigger }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [pointTypologyCategories, setPointTypologyCategories] = useState([]);
  const [showManage, setShowManage] = useState(false);
  const [showManageTypology, setShowManageTypology] = useState(false);
  const [showManageCategory, setShowManageCategory] = useState(false);
  const [showDeleteTypology, setShowDeleteTypology] = useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [selectedTypology, setSelectedTypology] = useState({});
  const [editedPoints, setEditedPoints] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formDataTypology, setFormDataTypology] = useState({
    name: '',
    points: '',
    category_id: '',
    manage_old_points: 1
  });
  const [formDataCategory, setFormDataCategory] = useState({
    name: ''
  });
  useEffect(() => {
    const fetchTypologyCategories = async () => {
      const responseObjects = await IndexPointTypologyCategory();
      if (responseObjects.success === true) {
        setPointTypologyCategories(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchTypologyCategories();
  }, [selectedTypology, trigger, selectedCategory]);

  const handleSubmitTypology = async e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorForm(false);
    let response = null;
    if (selectedTypology.id) {
      response = await PutTypology(selectedTypology.id, formDataTypology);
    } else {
      response = await PostTypology(formDataTypology);
    }
    setIsLoading(false);
    if (response.validator) {
      setErrorForm(response.validator);
    }
    if (response.success === true) {
      setFormDataTypology({
        name: '',
        points: '',
        manage_old_points: 1
      });
      setParentTrigger(Date.now());
      setGrandParentTrigger(Date.now());
      setShowManageTypology(false);
      setSelectedTypology({});
      setErrorForm(false);
      toast('Action enregistrée');
    }
  };

  const handleSubmitCategory = async e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorForm(false);
    let response = null;
    if (selectedCategory.id) {
      response = await PutCategory(selectedCategory.id, formDataCategory);
    } else {
      response = await PostCategory(formDataCategory);
    }
    setIsLoading(false);
    if (response.validator) {
      setErrorForm(response.validator);
    }
    if (response.success === true) {
      setFormDataCategory({
        name: ''
      });
      setParentTrigger(Date.now());
      setShowManageCategory(false);
      setSelectedCategory({});
      setErrorForm(false);
      toast('Action enregistrée');
    }
  };

  const deleteTypology = async () => {
    const response = await DeleteTypology(selectedTypology.id);
    if (response.success === true) {
      setShowDeleteTypology(false);
      toast('Action enregistrée');
      setParentTrigger(Date.now());
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteCategory = async () => {
    const response = await DeleteCategory(selectedCategory.id);
    if (response.success === true) {
      setShowDeleteCategory(false);
      toast('Action enregistrée');
      setParentTrigger(Date.now());
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleManageTypologyClose = () => {
    setShowManageTypology(false);
    setEditedPoints(false);
    setSelectedTypology({});
    setFormDataTypology({
      name: '',
      points: '',
      category_id: '',
      manage_old_points: 1
    });
  };

  const handleManageCategoryClose = () => {
    setShowManageCategory(false);
    setSelectedCategory({});
    setFormDataCategory({ name: '' });
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="admin-primary float-end"
        onClick={() => {
          setShowManage(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          style={{
            marginBottom: '2px',
            marginRight: '3px'
          }}
        >
          <path
            d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
            fill="#646363"
          />
        </svg>
        Gérer les typologies
      </Button>
      {pointTypologyCategories.length > 0 && (
        <Offcanvas
          show={showManage}
          style={{ width: '600px' }}
          onHide={() => {
            setShowManage(false);
          }}
          placement={'start'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Gérer les typologies</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Col xs={12} className="mb-2">
              {pointTypologyCategories.map((category, index) => (
                <div key={index} className="d-block mb-4">
                  <span className="d-block mb-1 smaller-p">
                    <b>{category.name}</b>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Ajouter une typologie au groupe
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className="cursor-pointer"
                        onClick={() => {
                          setFormDataTypology({
                            ...formDataTypology,
                            category_id: category.id
                          });
                          setShowManageTypology(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '3px',
                            marginLeft: '3px'
                          }}
                        >
                          <path
                            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                            fill="#2c7be5"
                          />
                        </svg>
                      </a>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Modifier le groupe
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedCategory(category);
                          setShowManageCategory(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '2px',
                            marginLeft: '3px'
                          }}
                        >
                          <path
                            d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                            fill="#2c7be5"
                          />
                        </svg>
                      </a>
                    </OverlayTrigger>
                  </span>
                  {category.typologies.map((typology, index) => (
                    <div key={index} className="d-block mb-1 smaller-p ps-2">
                      {typology.name}{' '}
                      <SoftBadge
                        pill
                        bg={typology.points > 0 ? 'success' : 'danger'}
                        className=""
                      >
                        {typology.points > 0 ? '+ ' : ''}
                        {typology.points}
                      </SoftBadge>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="overlay-trigger-example">
                            Modifier la typologie
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className="cursor-pointer"
                          onClick={() => {
                            setSelectedTypology(typology);
                            setShowManageTypology(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '0px',
                              marginLeft: '3px'
                            }}
                          >
                            <path
                              d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                              fill="#2c7be5"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>
                      {typology.is_automatic === 1 ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Cette typologie est utilisée de manière
                              automatique dans le système, il est n'est pas
                              autorisé de la supprimer.
                            </Tooltip>
                          }
                        >
                          <a href="#" style={{ cursor: 'not-allowed' }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="grey"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Supprimer la typologie
                            </Tooltip>
                          }
                        >
                          <a
                            href="#"
                            className="cursor-pointer"
                            onClick={() => {
                              setShowDeleteTypology(true);
                              setSelectedTypology(typology);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <div className="d-block mb-4">
                <p className="mb-1 smaller-p text-uppercase">
                  <a
                    href="#"
                    className="cursor-pointer"
                    onClick={() => {
                      setShowManageCategory(true);
                    }}
                  >
                    <b>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="14"
                        height="14"
                        style={{
                          marginBottom: '3px',
                          marginLeft: '3px'
                        }}
                      >
                        <path
                          d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                          fill="#2c7be5"
                        />
                      </svg>
                      Ajouter un nouveau groupe
                    </b>
                  </a>
                </p>
              </div>
            </Col>
            <Button
              size="sm"
              variant="primary"
              type="submit"
              onClick={() => {
                setShowManage(false);
                toast('Typologies enregistrées');
              }}
              className="me-2 admin-primary mb-4"
            >
              Enregistrer et fermer
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <Modal
        show={showManageTypology}
        onHide={handleManageTypologyClose}
        onShow={() => {
          if (selectedTypology.id) {
            setFormDataTypology({
              name: selectedTypology.name,
              points: selectedTypology.points,
              category_id: selectedTypology.category_id,
              manage_old_points: 1
            });
          }
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <p className="mb-0">
            {selectedTypology.id ? 'Modifier' : 'Ajouter'} une typologie
          </p>
        </Modal.Header>
        <Modal.Body>
          {errorForm && (
            <div className="mt-0">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Row>
            {selectedTypology.is_automatic === 1 && (
              <Col xs={12} className="mb-0">
                <Alert variant="warning" className="smaller-p">
                  Cette typologie est utilisée de manière automatique dans le
                  système, vous pouvez uniquement modifier le nombre de points.
                </Alert>
              </Col>
            )}
            <Col xs={12} className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>Libellé :</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Libellé de la typologie"
                  value={formDataTypology.name || ''}
                  disabled={selectedTypology.is_automatic === 1}
                  onChange={e => {
                    setFormDataTypology({
                      ...formDataTypology,
                      name: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>Points :</Form.Label>
                <Form.Control
                  type="number"
                  onWheel={e => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  name="points"
                  placeholder="-100"
                  value={formDataTypology.points || ''}
                  onChange={e => {
                    if (
                      parseInt(selectedTypology.points) !==
                      parseInt(e.target.value)
                    ) {
                      setEditedPoints(true);
                    } else {
                      setEditedPoints(false);
                    }
                    setFormDataTypology({
                      ...formDataTypology,
                      points: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            {editedPoints === true && selectedTypology.id && (
              <Col xs={12} className="">
                <Alert variant="danger" className="smaller-p">
                  <b>Important !</b> Par défaut, la modification des points
                  d'une typologie concerne uniquement les prochains points qui
                  seront ajoutés ou retirés aux entreprises. Veuillez choisir
                  une option :
                  <Form.Check
                    inline
                    type="radio"
                    className="cursor-pointer pt-2"
                    name="manage_old_points"
                    id="manage-old-points-1"
                    value="1"
                    label="Par défaut - ne pas toucher aux anciens points"
                    defaultChecked={formDataTypology.manage_old_points === 1}
                    onChange={e => {
                      setFormDataTypology({
                        ...formDataTypology,
                        manage_old_points: parseInt(e.target.value)
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    className="cursor-pointer pt-0 mt-0"
                    name="manage_old_points"
                    id="manage-old-points-2"
                    value="2"
                    label="Option 1 - Mettre à jour les anciens points de cette année
                      pour la typologie sélectionnée - irréversible"
                    defaultChecked={formDataTypology.manage_old_points === 2}
                    onChange={e => {
                      setFormDataTypology({
                        ...formDataTypology,
                        manage_old_points: parseInt(e.target.value)
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    className="cursor-pointer pt-0 mt-0"
                    name="manage_old_points"
                    id="manage-old-points-3"
                    value="3"
                    label="Option 2 - Mettre à jour les anciens points - toutes années confondues -
                      pour la typologie sélectionnée - irréversible"
                    defaultChecked={formDataTypology.manage_old_points === 3}
                    onChange={e => {
                      setFormDataTypology({
                        ...formDataTypology,
                        manage_old_points: parseInt(e.target.value)
                      });
                    }}
                  />
                </Alert>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="falcon-default"
            onClick={handleManageTypologyClose}
            className="me-2 admin-primary"
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={isLoading}
            onClick={e => {
              handleSubmitTypology(e);
            }}
            className="me-2 admin-primary"
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={showDeleteTypology}
        onHide={() => {
          setShowDeleteTypology(false);
          setSelectedTypology({});
        }}
        placement={'start'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedTypology.id !== undefined && (
              <>
                L'archivage de la ligne "{selectedTypology.name}" est définitif.
                Les lignes de points liées à cette typologie{' '}
                <b>ne seront PAS archivées</b>.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteTypology();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDeleteTypology(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showManageCategory}
        onHide={handleManageCategoryClose}
        onShow={() => {
          if (selectedCategory.id) {
            setFormDataCategory({
              name: selectedCategory.name
            });
          }
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <p className="mb-0">
            {selectedCategory.id ? 'Modifier' : 'Ajouter'} un groupe
          </p>
        </Modal.Header>
        <Modal.Body>
          {errorForm && (
            <div className="mt-0">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>Libellé :</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Libellé du groupe"
                  value={formDataCategory.name || ''}
                  onChange={e => {
                    setFormDataCategory({
                      ...formDataCategory,
                      name: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="falcon-default"
            onClick={handleManageCategoryClose}
            className="me-2 admin-primary"
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={isLoading}
            onClick={e => {
              handleSubmitCategory(e);
            }}
            className="me-2 admin-primary"
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas
        show={showDeleteCategory}
        onHide={() => {
          setShowDeleteCategory(false);
          setSelectedCategory({});
        }}
        placement={'start'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedCategory.id !== undefined && (
              <>
                L'archivage du groupe "{selectedCategory.name}" est définitif.
                Toutes les typologies qu'il contient seront également
                supprimées. Les lignes de points liées aux typologies{' '}
                <b>ne seront PAS archivées</b>.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteCategory();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDeleteCategory(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageTypologies.propTypes = {
  setParentTrigger: PropTypes.func,
  setGrandParentTrigger: PropTypes.func
};

export default ManageTypologies;
