import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Offcanvas
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  DeleteTradePress,
  IndexTradePresse
} from '../../../../../services/PostTradePressService';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import * as Moment from 'moment/moment';
import { toast } from 'react-toastify';
import { TrashIndex, TrashRestore } from '../../../../../services/TrashService';
import Select from 'react-select';
import { changeRoles } from '../../../../../redux/slices/membersSlice';
import useStateWithCallback from 'use-state-with-callback';

const IndexTrash = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [types] = useState([
    'Annonce',
    'Entreprise',
    'Événement',
    'Facture',
    'Partenaire',
    'Article',
    'Tred Ressource',
    'Questionnaire / décl. achat',
    'Formation',
    'Fichier',
    'Utilisateur',
    'Contenu VisMaRoute',
    'Catégorie de ressource',
    'Contrat'
  ]);
  const [type, setType] = useState('');

  useEffect(() => {
    const fecthObject = async () => {
      let response = false;
      response = await TrashIndex();
      if (response.success === true) {
        setInitialApiObjects(response.data);
        applySearch(searchInput, response.data);
        transformObjects(response.data);
      }
    };
    fecthObject();
  }, [trigger]);

  const restore = async object => {
    const response = await TrashRestore({
      id: object.id,
      model_class: object.model_class
    });
    if (response.success === true) {
      toast('Objet restauré');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        title: obj.title
          ? obj.title
          : obj.firstname || obj.lastname
          ? obj.firstname + ' ' + obj.lastname
          : obj.name
          ? obj.name
          : obj.partner?.name
          ? '' +
            obj.partner.name +
            ' ' +
            (obj.specific_label ? obj.specific_label : '')
          : '',
        model_class: obj.model_class,
        model_class_readable: obj.model_class_readable,
        deleted_at: Moment(obj.deleted_at).format('DD/MM/YYYY'),
        actions: (
          <Col className="px-0">
            <a
              className="text-primary cursor-pointer"
              onClick={() => {
                restore(obj);
              }}
            >
              Remettre
            </a>
          </Col>
        )
      });
    });
    setInitialObjects(transformedApiObjects);
    setInitialApiObjects(transformedApiObjects);
  };
  const deleteObject = async () => {
    const response = await DeleteTradePress(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Presse spécialisée supprimée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const applySearch = (value, objects) => {
    if (value) {
      let searchObjects = [];
      searchObjects = objects.filter(object => {
        let search = object.title;
        if (object.firstname) {
          search = object.firstname + ' ' + object.lastname;
        }
        if (object.name) {
          search = object.name;
        }
        return (
          search
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
        );
      });
      setInitialApiObjects(searchObjects);
    } else {
      setInitialApiObjects(objects);
    }
  };

  const applyType = (value, objects) => {
    if (value && value !== 'Choisir...') {
      let searchObjects = [];
      searchObjects = objects.filter(object => {
        let search = object.model_class_readable;
        return (
          search
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
        );
      });
      setInitialApiObjects(searchObjects);
    } else {
      setInitialApiObjects(objects);
    }
  };

  return (
    <>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id !== undefined && (
              <>
                L'archivage de la presse spécialisée "{selectedObject.title}"
                est définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">Corbeille</h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Row className="justify-content-between align-items-center px-3">
            <Col xs={9} className="mb-0 px-0 pt-3">
              <Form.Control
                type="search"
                className="search-input"
                placeholder="Rechercher quelque chose..."
                aria-label="Search"
                value={searchInput}
                size="sm"
                onChange={e => {
                  setSearchInput(e.target.value);
                  applySearch(e.target.value, initialObjects);
                }}
              />
            </Col>
            <Col xs={3} className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                aria-label="Filiale de"
                name="headquarter_id"
                value={type}
                onChange={e => {
                  setType(e.target.value);
                  applyType(e.target.value, initialObjects);
                }}
              >
                <option value="Choisir...">Choisir...</option>
                {types
                  .sort((a, b) =>
                    a.localeCompare(b, 'fr', { sensitivity: 'base' })
                  )
                  .map((c, index) => (
                    <option value={c} key={index}>
                      {c}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>

          <Row>
            {initialApiObjects && initialApiObjects.length > 0 && (
              <Col xs={12} className="mt-3">
                <Table data={initialApiObjects} />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
        Header: 'Titre'
      },
      {
        accessor: 'deleted_at',
        Header: 'Date suppression',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'model_class_readable',
        Header: 'Type'
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default IndexTrash;
