export const appRoutes = {
  label: 'Sociétés',
  children: [
    {
      name: 'Calendrier',
      icon: 'calendar-alt',
      to: '/administration/calendrier',
      active: true
    },
    {
      name: 'Formulaires de participation',
      icon: 'file',
      to: '/administration/formulaires-de-participation',
      active: true
    },
    {
      name: 'Sociétés',
      icon: 'shapes',
      to: '/administration/entreprises',
      active: true
    },

    {
      name: 'Membres',
      icon: 'users',
      to: '/administration/membres',
      active: true
    },
    {
      name: 'Ressources',
      icon: 'folder',
      to: '/administration/ressources',
      active: true
    },
    {
      name: 'Factures',
      icon: 'file-invoice',
      to: '/administration/factures',
      active: true
    },
    {
      name: 'Fichiers',
      icon: 'files',
      to: '/administration/fichiers',
      active: true
    },
    {
      name: 'Codes achats',
      icon: 'barecode',
      to: '/administration/codes-achats',
      active: true
    },
    {
      name: 'Questionnaires',
      icon: 'question',
      to: '/administration/questionnaires',
      active: true
    },
    {
      name: 'Déclarations achats',
      icon: 'cart-plus',
      to: '/administration/declarations-achats',
      active: false
    },
    {
      name: 'Gestion Partenaires',
      icon: 'handshake',
      to: '/administration/contrats-factures-partenaires',
      active: false
    }
  ]
};

export const partnersRoutes = {
  label: 'Partenaires',
  children: [
    {
      name: 'Partenaires',
      icon: 'shapes',
      to: '/administration/partenaires',
      active: true
    }
  ]
};

export const elearningRoutes = {
  label: 'E-learning',
  children: [
    {
      name: 'Formations E-learning',
      icon: 'layer-group',
      to: '/administration/elearning-formations',
      active: true
    },
    {
      name: 'Activité des étudiants',
      icon: 'layer-group',
      to: '/administration/elearning-activites',
      active: true
    },
    {
      name: "Demandes d'accès",
      icon: 'layer-group',
      to: '/administration/elearning-acces',
      active: true
    }
  ]
};

export const bilanRoutes = {
  label: 'Bilans pédagogiques',
  children: [
    {
      name: 'E-learning',
      icon: 'layer-group',
      to: '/administration/bilans-pedagogiques/elearning',
      active: true
    },
    {
      name: 'Présentiel',
      icon: 'layer-group',
      to: '/administration/bilans-pedagogiques/presentiel',
      active: true
    },
    {
      name: 'Webinar',
      icon: 'layer-group',
      to: '/administration/bilans-pedagogiques/webinar',
      active: true
    }
  ]
};

export const visMaRouteRoutes = {
  label: 'Vis Ma Route',
  children: [
    {
      name: 'Gestion Vis ma route',
      icon: 'list',
      to: '/administration/vis-ma-route',
      active: true
    }
  ]
};

export const fileSharingRoutes = {
  label: 'Partage de fichiers',
  children: [
    {
      name: 'Partage de fichiers',
      icon: 'share',
      to: '/administration/partage-fichiers',
      active: true
    }
  ]
};

export const contentsRoutes = {
  label: 'Contenus divers',
  children: [
    {
      name: 'Actualités',
      icon: 'list',
      to: '/administration/actualites',
      active: true
    },
    {
      name: 'Presse spécialisée',
      icon: 'list',
      to: '/administration/presse-specialisee',
      active: true
    },
    {
      name: 'Sliders',
      icon: 'list',
      to: '/administration/sliders',
      active: true
    },
    {
      name: 'Visios (formations)',
      icon: 'list',
      to: '/administration/visios',
      active: true
    },
    {
      name: 'Petites annonces',
      icon: 'list',
      to: '/administration/annonces',
      active: true
    }
  ]
};

export const otherRoutes = {
  label: 'Autre',
  children: [
    {
      name: 'Corbeille',
      icon: 'trash',
      to: '/administration/corbeille',
      active: true
    },
    {
      name: 'Journal système',
      icon: 'cog',
      to: '/administration/journal',
      active: true
    }
  ]
};
export default [
  appRoutes,
  partnersRoutes,
  visMaRouteRoutes,
  elearningRoutes,
  bilanRoutes,
  fileSharingRoutes,
  contentsRoutes,
  otherRoutes
];
