import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Offcanvas,
  Spinner
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import {
  DeleteResourceCategory,
  IndexResourceCategoryAdmin,
  PostResourceCategory,
  PutResourceCategory
} from '../../../../../services/ResourceCategoryService';
import Treeview from '../../../commons/Treeview';
import FormAlert from '../../../commons/FormAlert';
import { IndexRoles } from '../../../../../services/RoleService';
import { IndexPartner } from '../../../../../services/PartnerService';

const IndexResourcesCategories = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [objects, setObjects] = useState([]);
  const [treeviewItems, setTreeviewItems] = useState([]);
  const [splittedCategories, setSplittedCategories] = useState([]);
  const [partners, setPartners] = useState([]);
  const [rolesSelect, setRolesSelect] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useStateWithCallbackLazy({
    title: '',
    parent_id: '',
    partner_id: '',
    roles: []
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitIsLoading(true);
    setErrorForm(false);
    let response = false;
    if (selectedObject.id) {
      response = await PutResourceCategory(selectedObject.id, formData);
    } else {
      response = await PostResourceCategory(formData);
    }
    setErrorForm(response.validator);
    setSubmitIsLoading(false);
    if (response.success === true) {
      setShowManage(false);
      setFormData({
        title: '',
        parent_id: '',
        partner_id: '',
        roles: []
      });
      const successMessage = `Catégorie enregistrée.`;
      toast(successMessage);
      setTrigger(Date.now());
    }
  };

  const deleteObject = async () => {
    setIsLoading(true);
    const response = await DeleteResourceCategory(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Catégorie archivée');
      setTrigger(Date.now());
      setShowManage(false);
      setSelectedObject({});
      setFormData({
        title: '',
        parent_id: '',
        partner_id: '',
        roles: []
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const getRecursiveCategories = (category, categories) => {
    let tmpChildrens = [];
    if (category.childrens_admin && category.childrens_admin.length > 0) {
      category.childrens_admin.map(children => {
        getRecursiveCategories(children, tmpChildrens);
      });
    }
    categories.push({
      name: category.title,
      id: category.id,
      children: tmpChildrens
    });
  };

  const getRecursiveCategoriesAll = (category, categories) => {
    categories.push(category);
    if (category.childrens_admin && category.childrens_admin.length > 0) {
      category.childrens_admin.map(children => {
        getRecursiveCategoriesAll(children, categories);
      });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      const responseObject = await IndexResourceCategoryAdmin();
      if (responseObject.success === true) {
        setObjects(responseObject.data);
        let tmpTreeView = [];
        let splittedCategories = [];
        responseObject.data.map(object => {
          getRecursiveCategories(object, tmpTreeView);
          getRecursiveCategoriesAll(object, splittedCategories);
        });
        setSplittedCategories(splittedCategories);
        setTreeviewItems(tmpTreeView);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchRoles = async () => {
      const responseObject = await IndexRoles();
      if (responseObject.success === true) {
        let rolesArray = [];
        responseObject.data.map(role => {
          rolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        setRolesSelect(rolesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPartners = async () => {
      const responseObject = await IndexPartner();
      if (responseObject.success === true) {
        setPartners(responseObject.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCategories();
    fetchRoles();
    fetchPartners();
  }, [trigger]);

  return (
    <>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id !== undefined && (
              <>
                L'archivage de la catégorie "{selectedObject.name}" est
                définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des catégories
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  window.location.href = '/administration/ressources';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Annuler
              </Button>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setShowManage(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter une catégorie
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {objects && objects.length > 0 && (
        <>
          <Offcanvas
            onShow={() => {
              if (selectedObject.id) {
                let obj = splittedCategories.filter(
                  o => parseInt(o.id) === parseInt(selectedObject.id)
                )[0];
                if (obj) {
                  let rolesArray = [];
                  obj.roles.map(role => {
                    rolesArray.push({
                      value: `${role.id}`,
                      label: role.title
                    });
                  });
                  setFormData({
                    title: obj.title,
                    parent_id: obj.parent_id,
                    partner_id: obj.partner_id,
                    roles: rolesArray
                  });
                }
              }
            }}
            show={showManage}
            onHide={() => {
              setShowManage(false);
              setSelectedObject({});
              setFormData({
                title: '',
                parent_id: '',
                partner_id: '',
                roles: []
              });
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id ? 'Modifier' : 'Ajouter'} une catégorie
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row className="gx-2 gy-3 mb-3">
                <Col xs={12}>
                  {errorForm && (
                    <div className="mt-0">
                      <FormAlert variant="danger" data={errorForm} />
                    </div>
                  )}
                </Col>
                <Col md="12">
                  <Form.Group controlId="name">
                    <Form.Label>Nom de la catégorie :</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Nom de la catégorie"
                      value={formData.title || ''}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group controlId="roles">
                    <Form.Label>Autorisation(s) :</Form.Label>
                    <Select
                      closeMenuOnSelect={false}
                      options={rolesSelect}
                      placeholder="Choisir une ou plusieurs autorisations..."
                      isMulti
                      name="roles"
                      classNamePrefix="react-select"
                      value={formData.roles}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          roles: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId="category_id">
                    <Form.Label>
                      Catégorie <b>parent</b> :
                    </Form.Label>
                    <Form.Select
                      aria-label="Catégorie"
                      name="parent_id"
                      value={formData.parent_id}
                      onChange={e => {
                        handleFieldChange(e);
                      }}
                    >
                      <option value="">Aucune</option>
                      {objects.map((c, index) => (
                        <RecursiveCategory
                          category={c}
                          level={0}
                          key={index}
                          selectedCategory={selectedObject}
                        />
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md="12" className="pb-2 pt-3">
                  <div className="border-dashed-bottom"></div>
                </Col>
                {partners.length > 0 && (
                  <Col xs={12} className="mb-2">
                    <Form.Group controlId="headquarter_id">
                      <Form.Label>
                        Catégorie <b>partenaire</b> ?
                      </Form.Label>
                      <Form.Select
                        aria-label="Catégorie partenaire"
                        name="partner_id"
                        value={formData.partner_id}
                        onChange={handleFieldChange}
                      >
                        <option>N'est pas une catégorie partenaire</option>
                        {partners.map((c, index) => (
                          <option value={c.id} key={index}>
                            {c.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
                <Col md="12" className="pb-2 pt-0">
                  <div className="border-dashed-bottom"></div>
                </Col>
              </Row>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowManage(false);
                  setFormData({
                    title: '',
                    parent_id: '',
                    partner_id: '',
                    roles: []
                  });
                }}
              >
                Annuler
              </Button>
              <Button
                size="sm"
                variant="primary"
                type="submit"
                disabled={submitLoading}
                onClick={e => {
                  handleSubmit(e);
                }}
                className="me-2 admin-primary"
              >
                Enregistrer
              </Button>
              {selectedObject.id && (
                <Button
                  size="sm"
                  variant="danger"
                  className="me-0 float-end"
                  type="button"
                  onClick={() => {
                    setShowDelete(true);
                    setSelectedObject(selectedObject);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '2px'
                    }}
                  >
                    <path
                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </Button>
              )}
            </Offcanvas.Body>
          </Offcanvas>
          <Card className="mt-3">
            <Card.Body className="">
              <Row>
                <Col xs={12} className="mt-3">
                  <Treeview
                    setSelectedObject={setSelectedObject}
                    data={treeviewItems}
                    expanded={['1']}
                    setShowManage={setShowManage}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

const RecursiveCategory = ({ category, level, selectedCategory }) => {
  return (
    <Fragment>
      <option
        disabled={
          selectedCategory.id &&
          parseInt(selectedCategory.id) === parseInt(category.id)
        }
        value={category.id}
      >
        {getRecursiveCategorySpaces(level)}
        {category.title}
        {category.resources && category.resources.length > 0
          ? ` | ${category.resources.length} fichier${
              category.resources.length > 1 ? 's' : ''
            }`
          : ''}
      </option>
      {category.childrens_admin &&
        category.childrens_admin.length > 0 &&
        category.childrens_admin.map((children, index) => (
          <RecursiveCategory
            key={index}
            category={children}
            level={level + 1}
            selectedCategory={selectedCategory}
          />
        ))}
    </Fragment>
  );
};

const getRecursiveCategorySpaces = level => {
  let spaces = '';
  for (let i = 0; i < level; i++) {
    spaces += '--';
  }
  return `${spaces} `;
};

RecursiveCategory.propTypes = {
  category: PropTypes.object,
  level: PropTypes.number,
  disabled: PropTypes.any,
  selectedCategory: PropTypes.object
};

export default IndexResourcesCategories;
