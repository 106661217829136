import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  Row
} from 'react-bootstrap';
import { membersRoutes } from '../../../../routes/authRoutes';
import { toast } from 'react-toastify';
import { IndexCourse } from '../../../../services/Elearning/CourseService';
import Masonry from 'react-masonry-css';
import { useOutletContext } from 'react-router-dom';
import FormAlert from '../../commons/FormAlert';
import { PostRequest } from '../../../../services/Elearning/RequestService';
import IconAlert from '../../../common/IconAlert';
import { useNavigate } from 'react-router';

const Elearning = () => {
  const [trigger] = useState(Date.now());
  const [user] = useOutletContext();
  const [courses, setCourses] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [errorExists, setErrorExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [askIsSent, setAskIsSent] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [formData, setFormData] = useState({
    company: user.main_company ? user.main_company.name : '',
    lastname: '',
    firstname: '',
    occupation: '',
    email: '',
    phone: '',
    course_id: ''
  });
  let navigate = useNavigate();
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleSubmit = async e => {
    setIsLoading(true);
    setErrorExists(false);
    e.preventDefault();
    const response = await PostRequest(formData);
    if (response.success === true) {
      setAskIsSent(true);
      setShowForm(false);
      setErrorForm(false);
      toast('Demande envoyée avec succès.');
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 800);
    } else if (
      response.success === false &&
      response.message &&
      response.message === 'exists'
    ) {
      setErrorExists(response.data);
    } else if (response.validator) {
      setErrorForm(response.validator);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    const fetchCourses = async () => {
      const responseCourses = await IndexCourse('memberarea');
      if (responseCourses.success === true) {
        setCourses(responseCourses.data);
        setInitialApiObjects(responseCourses.data);
        applySearch(searchInput, responseCourses.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCourses();
  }, [trigger]);
  const applySearch = (value, objects) => {
    if (value.length > 2) {
      let searchObjects = [];
      searchObjects = objects.filter(
        object =>
          object.title
            .toLowerCase()
            .replace('.', '')
            .indexOf(searchInput.toLowerCase().replace('.', '')) > -1
      );
      setInitialApiObjects(searchObjects);
    } else {
      setInitialApiObjects(objects);
    }
  };
  return (
    <Row className="mt-2">
      {user.courses.length > 0 && (
        <>
          <Col xs={12} className="actualites elearning-catalog mb-2">
            <h5 className="mb-4 redHeading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d={
                    membersRoutes.filter(
                      route => route.id === 'tredElearning'
                    )[0].svg
                  }
                  fill="rgba(229,47,18,1)"
                />
              </svg>{' '}
              {user.courses.length > 1
                ? 'Mes formations en cours'
                : 'Ma formation en cours'}
            </h5>
            {user.courses.map((course, index) => (
              <Row key={index} className="mb-3">
                <Col xs={12}>
                  <Card>
                    <Card.Img
                      onClick={() => {
                        navigate(`/espace-membre/e-learning/${course.id}`);
                      }}
                      className="cursor-pointer"
                      src={course.image_url}
                      variant="top"
                    />
                    <Card.Body>
                      <Card.Title as="h5">{course.title}</Card.Title>
                      <Card.Text className="smaller-p">
                        {course.description}
                      </Card.Text>
                      <div className="meta-course">
                        <p className="mb-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="13"
                            height="13"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          <b>
                            Formateur
                            {course.teachers.length > 1 ? 's' : ''}
                          </b>{' '}
                          :{' '}
                          {course.teachers.map((teacher, index) => (
                            <Fragment key={index}>
                              {teacher.lastname} {teacher.firstname}
                              {index + 1 !== course.teachers.length ? ', ' : ''}
                            </Fragment>
                          ))}
                        </p>
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="13"
                            height="13"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          <b>Durée</b> : {course.steps.length} module
                          {course.steps.length > 1 ? 's' : ''} - environ{' '}
                          {Math.round(
                            course.steps.reduce(function (acc, obj) {
                              return acc + obj.duration;
                            }, 0) / 60
                          ) === 0
                            ? 1
                            : Math.round(
                                course.steps.reduce(function (acc, obj) {
                                  return acc + obj.duration;
                                }, 0) / 60
                              )}{' '}
                          heure
                          {Math.round(
                            course.steps.reduce(function (acc, obj) {
                              return acc + obj.duration;
                            }, 0) / 60
                          ) > 1
                            ? 's'
                            : ''}
                        </p>
                        <p className="mt-2">{course.content}</p>
                      </div>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          navigate(`/espace-membre/e-learning/${course.id}`);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px', marginRight: '5px' }}
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>

                        {course.pivot.course_start_date !== null &&
                        course.pivot.course_start_date !== undefined
                          ? 'Continuer ma formation'
                          : 'Démarrer ma formation'}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={12} className="my-4">
            <hr />
          </Col>
        </>
      )}
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d={
                membersRoutes.filter(route => route.id === 'tredElearning')[0]
                  .svg
              }
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          Catalogue E-Learning
        </h5>
      </Col>
      {askIsSent === true && selectedCourse && (
        <Col xs={12}>
          <IconAlert variant="success">
            <p className="mb-0">
              Votre demande d'accès à la formation "{selectedCourse.title}" a
              été envoyée avec succès. Un membre de l'équipe Tred Union va
              revenir vers vous rapidement.
            </p>
          </IconAlert>
        </Col>
      )}

      {courses.length > 0 && (
        <>
          <Col xs={12} className="mb-3">
            <Form.Control
              type="search"
              className="search-input"
              placeholder="Rechercher quelque chose..."
              aria-label="Search"
              value={searchInput}
              size="sm"
              onChange={e => {
                setSearchInput(e.target.value);
                applySearch(e.target.value, courses);
              }}
            />
          </Col>
          <Col xs={12}>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonry-grid actualites elearning-catalog"
              columnClassName="masonry-grid_column"
            >
              {initialApiObjects.map((course, index) => (
                <Fragment key={index}>
                  {!user.courses.some(c => c.id === course.id) && (
                    // {true && (
                    <div key={index}>
                      <Card>
                        <Card.Img
                          onClick={() => {
                            setSelectedCourse(course);
                            setShowForm(true);
                          }}
                          className="cursor-pointer"
                          src={course.image_url}
                          variant="top"
                        />
                        <Card.Body>
                          <Card.Title as="h5">{course.title}</Card.Title>
                          <Card.Text className="smaller-p">
                            {course.description}
                          </Card.Text>
                          <div className="meta-course">
                            <p className="mb-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="13"
                                height="13"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M1 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H1zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM21.548.784A13.942 13.942 0 0 1 23 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 21 7c0-1.792-.393-3.493-1.097-5.02L21.548.784zm-3.302 2.4A9.97 9.97 0 0 1 19 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 17 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z"
                                  fill="rgba(100,99,99,1)"
                                />
                              </svg>
                              <b>
                                Formateur
                                {course.teachers.length > 1 ? 's' : ''}
                              </b>{' '}
                              :{' '}
                              {course.teachers.map((teacher, index) => (
                                <Fragment key={index}>
                                  {teacher.lastname} {teacher.firstname}
                                  {index + 1 !== course.teachers.length
                                    ? ', '
                                    : ''}
                                </Fragment>
                              ))}
                            </p>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="13"
                                height="13"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8h4v2h-6V7h2v5z"
                                  fill="rgba(100,99,99,1)"
                                />
                              </svg>
                              <b>Durée</b> : {course.steps.length} module
                              {course.steps.length > 1 ? 's' : ''} - environ{' '}
                              {Math.round(
                                course.steps.reduce(function (acc, obj) {
                                  return acc + obj.duration;
                                }, 0) / 60
                              ) === 0
                                ? 1
                                : Math.round(
                                    course.steps.reduce(function (acc, obj) {
                                      return acc + obj.duration;
                                    }, 0) / 60
                                  )}{' '}
                              heure
                              {Math.round(
                                course.steps.reduce(function (acc, obj) {
                                  return acc + obj.duration;
                                }, 0) / 60
                              ) > 1
                                ? 's'
                                : ''}
                            </p>
                            <p className="mt-2">{course.content}</p>
                          </div>
                          {course.authorise_requests === 1 ? (
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setSelectedCourse(course);
                                setShowForm(true);
                              }}
                            >
                              Demander à participer
                            </Button>
                          ) : (
                            <i>
                              Veuillez contacter Tred Union pour participer à
                              cette formation.
                            </i>
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Fragment>
              ))}
            </Masonry>
          </Col>
        </>
      )}
      {selectedCourse && (
        <Offcanvas
          show={showForm}
          onHide={() => {
            setShowForm(false);
            setErrorForm(false);
            setErrorExists(false);
          }}
          onShow={() => {
            setFormData({
              ...formData,
              course_id: selectedCourse.id
            });
          }}
          placement={'end'}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Demander à participer à la formation {selectedCourse.title}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form onSubmit={handleSubmit}>
              {errorForm && <FormAlert variant="danger" data={errorForm} />}
              {errorExists && <Alert variant="danger">{errorExists}</Alert>}
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="company">
                    <Form.Label>Société du participant</Form.Label>
                    <Form.Control
                      placeholder="Société du participant"
                      value={formData.company}
                      size="sm"
                      name="company"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="my-1"></Col>
                <Col xs={6}>
                  <Form.Group controlId="lastname">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      placeholder="Nom du participant"
                      value={formData.lastname}
                      size="sm"
                      name="lastname"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlId="firstname">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                      placeholder="Prénom du participant"
                      value={formData.firstname}
                      size="sm"
                      name="firstname"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="my-1"></Col>
                <Col xs={12}>
                  <Form.Group controlId="occupation">
                    <Form.Label>Fonction</Form.Label>
                    <Form.Control
                      placeholder="Fonction du participant"
                      value={formData.occupation}
                      size="sm"
                      name="occupation"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="my-1"></Col>
                <Col xs={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      placeholder="Email du participant"
                      value={formData.email}
                      size="sm"
                      name="email"
                      onChange={handleFieldChange}
                      type="email"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                      placeholder="Téléphone du participant"
                      value={formData.phone}
                      size="sm"
                      name="phone"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2 mt-4"
                type="submit"
                disabled={isLoading}
              >
                Envoyer ma demande
              </Button>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </Row>
  );
};

export default Elearning;
