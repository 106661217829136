import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import IconButton from '../../../common/IconButton';
import pictoPdf from '../../../../assets/img/tredunion/pictos/file-pdf.svg';

const TredResourceTable = ({
  waitings,
  resourceCategoriesOriginalSplitted,
  saveTracking,
  setTrigger,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = waitings.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const getFullPath = category => {
    let fullPath = category.title;
    if (category.parent_id !== undefined && category.parent_id !== null) {
      let parent = resourceCategoriesOriginalSplitted.filter(
        c => c.id === category.parent_id
      )[0];
      fullPath = `${getFullPath(parent)} > ${fullPath}`;
    }
    return fullPath;
  };

  return (
    <>
      <Table responsive>
        <tbody>
          {currentItems.map((resource, index) => (
            <tr key={index} className="hover-actions-trigger">
              <td>
                <img
                  src={pictoPdf}
                  className="d-none d-lg-inline"
                  style={{ width: '14px' }}
                />{' '}
                {getFullPath(
                  resourceCategoriesOriginalSplitted.find(
                    category => category.id === resource.category_id
                  )
                )}
                {' - '}
                {resource.title}
              </td>
              <td className="text-end">
                <IconButton
                  className="mb-1 me-lg-2"
                  variant="falcon-default"
                  size="sm"
                  icon={faFileDownload}
                  transform="shrink-3"
                  disable={isLoading}
                  onClick={async () => {
                    saveTracking(resource);
                    setTrigger(Date.now);
                  }}
                >
                  Accéder
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <Pagination.Prev
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {[...Array(Math.ceil(waitings.length / itemsPerPage))].map(
          (_, index) => {
            const pageNumber = index + 1;
            if (
              pageNumber === 1 ||
              pageNumber === Math.ceil(waitings.length / itemsPerPage) ||
              (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
            ) {
              return (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === currentPage}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              );
            } else if (
              pageNumber === currentPage - 2 ||
              pageNumber === currentPage + 2
            ) {
              return <Pagination.Ellipsis key={pageNumber} />;
            }
            return null;
          }
        )}

        <Pagination.Next
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(waitings.length / itemsPerPage)}
        />
      </Pagination>
    </>
  );
};

TredResourceTable.propTypes = {
  waitings: PropTypes.array.isRequired,
  resourceCategoriesOriginalSplitted: PropTypes.array.isRequired,
  saveTracking: PropTypes.func.isRequired,
  setTrigger: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default TredResourceTable;
