import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from '../../../../../../helpers/utils';
import * as echarts from 'echarts/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const calculateParticipe = (data, survey) => {
  return (
    data.filter(recipient => {
      if (recipient.pivot && recipient.pivot.survey_completed_on) {
        if (
          recipient.survey_questions &&
          recipient.survey_questions.filter(
            question =>
              question.is_participation_form_main_question === 1 &&
              question.attachable_id === survey.id &&
              question.pivot.value === 'Oui'
          ).length > 0
        ) {
          return true;
        }
      }
    }).length -
    data.filter(recipient => {
      return recipient.pivot && recipient.pivot.did_not_come_to_event === 1;
    }).length
  );
};

const calculateNeParticipePas = (data, survey) => {
  return data.filter(recipient => {
    if (recipient.pivot && recipient.pivot.survey_completed_on) {
      if (
        recipient.survey_questions &&
        recipient.survey_questions.filter(
          question =>
            question.is_participation_form_main_question === 1 &&
            question.attachable_id === survey.id &&
            question.pivot.value === 'Non'
        ).length > 0
      ) {
        return true;
      }
    }
  }).length;
};

const calculateEnAttente = data => {
  return data.filter(recipient => {
    return recipient.pivot && !recipient.pivot.survey_completed_on;
  }).length;
};

const calculatePasPresente = data => {
  return data.filter(recipient => {
    return recipient.pivot && recipient.pivot.did_not_come_to_event === 1;
  }).length;
};

const getOptions = (data, survey) => ({
  color: ['#6ca45e', '#ec9265', '#4592e3', '#c9332b'],
  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.data.value}`
  },
  series: [
    {
      name: `${data.length} invité${data.length > 1 ? 's' : ''}`,
      type: 'pie',
      radius: ['100%', '87%'],
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: 23,
        color: getColor('dark')
      },
      data: [
        {
          id: 1,
          value: calculateParticipe(data, survey),
          name: 'Participe'
        },
        {
          id: 2,
          value: calculateNeParticipePas(data, survey),
          name: 'Ne participe pas'
        },
        {
          id: 3,
          value: calculateEnAttente(data),
          name: 'En attente de réponse'
        },
        {
          id: 4,
          value: calculatePasPresente(data),
          name: "Ne s'est pas présenté"
        }
      ]
    }
  ]
});

const RoundItem = ({ item, index, color }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index === 0 && 'mt-0'}`}
    >
      <p className="mb-1" style={{ fontSize: '12px' }}>
        <FontAwesomeIcon
          icon="circle"
          className="me-2"
          style={{ color: color }}
        />
        {item.name}
      </p>
      <div className="d-xxl-none">{item.value}</div>
    </Flex>
  );
};

RoundItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.any,
  color: PropTypes.any
};

const LineAnswers = ({ data, survey }) => {
  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs="12">
            <div className="ps-0">
              <ReactEChartsCore
                echarts={echarts}
                option={getOptions(data, survey)}
              />
            </div>
          </Col>
          <Col xs={12} className="pe-2">
            <RoundItem
              item={{
                name: 'Participe',
                value: calculateParticipe(data, survey)
              }}
              index={0}
              color="#6ca45e"
            />
            <RoundItem
              item={{
                name: 'Ne participe pas',
                value: calculateNeParticipePas(data, survey)
              }}
              index={1}
              color="#ec9265"
            />
            <RoundItem
              item={{
                name: 'En attente de réponse',
                value: calculateEnAttente(data)
              }}
              index={0}
              color="#4592e3"
            />
            <RoundItem
              item={{
                name: "Ne s'est pas présenté",
                value: calculatePasPresente(data)
              }}
              index={1}
              color="#c9332b"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

LineAnswers.propTypes = {
  data: PropTypes.array,
  survey: PropTypes.object
};

export default LineAnswers;
