import React, { useEffect, useState, Fragment } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import * as Moment from 'moment';
import { toast } from 'react-toastify';
import IconAlert from '../../../common/IconAlert';
import FormAlert from '../../commons/FormAlert';
import { membersRoutes } from '../../../../routes/authRoutes';
import {
  IndexPurchaseCode,
  PostPurchaseCode
} from '../../../../services/PurchaseCodeService';
import { IndexPurchaseCodeBrand } from '../../../../services/PurchaseCodeBrandService';
import ExportPurchaseCodes from '../../../../exports/purchaseCodes';

const CodesAchats = () => {
  const [trigger, setTrigger] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(false);
  const [uniqueCompanies, setUniqueCompanies] = useState([]);
  const [originalPurchaseCodes, setOriginalPurchaseCodes] = useState([]);
  const [purchaseCodes, setPurchaseCodes] = useState([]);
  const [purchaseCodeBrands, setPurchaseCodeBrands] = useState([]);
  const [purchaseCodeBrandsSearch, setPurchaseCodeBrandsSearch] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const [purchaseCodeIsSent, setPurchaseCodeIsSent] = useState(false);
  const [formData, setFormData] = useState({
    quantity: 1,
    brand_id: ''
  });
  const [formDataSearch, setFormDataSearch] = useState({
    is_engine_type: '',
    brand_id: '',
    company_id: ''
  });
  useEffect(() => {
    const fetchPurchaseCodes = async () => {
      const responsePurchaseCodes = await IndexPurchaseCode();
      if (responsePurchaseCodes.success === true) {
        setPurchaseCodes(responsePurchaseCodes.data);
        setOriginalPurchaseCodes(responsePurchaseCodes.data);
        let tmpUniqueCompanies = [
          ...new Set(
            responsePurchaseCodes.data
              .filter(
                code => code.company !== null && code.company !== undefined
              )
              .map(item => item.company.name)
          )
        ];
        setUniqueCompanies(tmpUniqueCompanies);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPurchaseCodeBrands = async () => {
      const responsePurchaseCodeBrands = await IndexPurchaseCodeBrand();
      if (responsePurchaseCodeBrands.success === true) {
        setPurchaseCodeBrands(responsePurchaseCodeBrands.data);
        setPurchaseCodeBrandsSearch(responsePurchaseCodeBrands.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchPurchaseCodes();
    fetchPurchaseCodeBrands();
  }, [trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await PostPurchaseCode(formData);
    if (response.success === true) {
      toast('Votre code achat a été généré avec succès.');
      setPurchaseCodeIsSent(true);
      setFormData({
        quantity: 1,
        brand_id: ''
      });
      setErrorForm(false);
      setTrigger(Date.now);
    } else if (response.validator) {
      setErrorForm(response.validator);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSearchChange = e => {
    let tmpFormDataSearch = formDataSearch;
    if (e.target.name === 'is_engine_type') {
      tmpFormDataSearch = {
        ...tmpFormDataSearch,
        brand_id: '',
        is_engine_type: e.target.value
      };
    } else {
      tmpFormDataSearch = {
        ...tmpFormDataSearch,
        [e.target.name]: e.target.value
      };
    }
    setFormDataSearch(tmpFormDataSearch);
    let tmpPurchaseCodes = originalPurchaseCodes;
    if (tmpFormDataSearch.is_engine_type !== '') {
      let filteredBrands = purchaseCodeBrands.filter(
        brand =>
          brand.is_engine_type === parseInt(tmpFormDataSearch.is_engine_type)
      );
      setPurchaseCodeBrandsSearch(filteredBrands);
      tmpPurchaseCodes = tmpPurchaseCodes.filter(
        code =>
          code.brand.is_engine_type ===
          parseInt(tmpFormDataSearch.is_engine_type)
      );
    } else {
      setPurchaseCodeBrandsSearch(purchaseCodeBrands);
    }
    if (tmpFormDataSearch.brand_id !== '') {
      tmpPurchaseCodes = tmpPurchaseCodes.filter(
        code => code.brand.id === parseInt(tmpFormDataSearch.brand_id)
      );
    }
    if (tmpFormDataSearch.company_id !== '') {
      tmpPurchaseCodes = tmpPurchaseCodes.filter(
        code =>
          code.company !== null &&
          code.company !== undefined &&
          code.company.name === tmpFormDataSearch.company_id
      );
    }
    setPurchaseCodes(tmpPurchaseCodes);
  };

  return (
    <Row className="mt-2">
      {purchaseCodeBrands.length > 0 && (
        <Col xs={12}>
          <h5 className="mb-4 redHeading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d={
                  membersRoutes.filter(
                    route => route.id === 'tredCodesAchats'
                  )[0].svg
                }
                fill="rgba(229,47,18,1)"
              />
            </svg>{' '}
            Générer un code achat
          </h5>
          {purchaseCodeIsSent ? (
            <IconAlert variant="success">
              <p className="mb-0">
                Votre code achat a été généré avec succès.{' '}
                <a
                  href="#"
                  onClick={() => {
                    setPurchaseCodeIsSent(false);
                  }}
                >
                  Générer un autre code achat
                </a>
              </p>
            </IconAlert>
          ) : (
            <Form onSubmit={handleSubmit}>
              {errorForm && <FormAlert variant="danger" data={errorForm} />}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Marque concernée</Form.Label>
                <Form.Select
                  size="sm"
                  aria-label="Marque concernée"
                  name="brand_id"
                  onChange={e => handleFieldChange(e)}
                >
                  <option>Choisir une marque dans la liste</option>
                  <option disabled>Moteur</option>
                  {purchaseCodeBrands
                    .filter(brand => parseInt(brand.is_engine_type) === 1)
                    .map((brand, index) => (
                      <option key={index} value={brand.id}>
                        -- {brand.title}
                      </option>
                    ))}
                  <option disabled>Non-moteur</option>
                  {purchaseCodeBrands
                    .filter(
                      brand =>
                        parseInt(brand.is_engine_type) === 0 &&
                        brand.title !== 'KASSBOHRER'
                    )
                    .map((brand, index) => (
                      <option key={index} value={brand.id}>
                        -- {brand.title}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              {formData.brand_id !== '' && (
                <>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Indiquez la quantité :</Form.Label>
                    <Form.Control
                      type="number"
                      onWheel={e => {
                        e.preventDefault();
                        e.target.blur();
                      }}
                      size="sm"
                      placeholder="Indiquez la quantité"
                      name="quantity"
                      value={formData.quantity}
                      onChange={e => handleFieldChange(e)}
                    />
                  </Form.Group>
                  {formData.quantity > 0 && (
                    <Col xs={12} className="mt-3">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        Générer le code achat
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </Form>
          )}
        </Col>
      )}
      {originalPurchaseCodes.length > 0 && (
        <>
          <Col xs={12} className="mt-5">
            <h5 className="mb-3 redHeading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                  fill="rgba(229,47,18,1)"
                />
              </svg>{' '}
              Historique
            </h5>
            <Row className="align-items-center">
              <Col xs={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Filtrer par type</Form.Label>
                  <Form.Select
                    size="sm"
                    aria-label="Filtrer par type"
                    name="is_engine_type"
                    value={formDataSearch.is_engine_type}
                    onChange={e => handleSearchChange(e)}
                  >
                    <option value="">Aucun</option>
                    <option value="1">Moteur</option>
                    <option value="0">Non-moteur</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {purchaseCodeBrandsSearch.length > 0 && (
                <Col xs={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Filtrer par marque</Form.Label>
                    <Form.Select
                      size="sm"
                      aria-label="Filtrer par type"
                      name="brand_id"
                      value={formDataSearch.brand_id}
                      onChange={e => handleSearchChange(e)}
                    >
                      <option value="">Aucune</option>
                      {purchaseCodeBrandsSearch.map((brand, index) => (
                        <Fragment key={index}>
                          <option value={brand.id}>{brand.title}</option>
                        </Fragment>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              {uniqueCompanies.length > 0 && (
                <Col xs={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Filtrer par entreprise</Form.Label>
                    <Form.Select
                      size="sm"
                      aria-label="Filtrer par entreprise"
                      name="company_id"
                      value={formDataSearch.company_id}
                      onChange={e => handleSearchChange(e)}
                    >
                      <option value="">Aucune</option>
                      {uniqueCompanies.map((company, index) => (
                        <Fragment key={index}>
                          <option value={company}>{company}</option>
                        </Fragment>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
              {purchaseCodes.length > 0 && (
                <Col xs={12} lg={6} className="pt-2 pt-lg-3">
                  <ExportPurchaseCodes purchaseCodes={purchaseCodes} />
                </Col>
              )}
            </Row>
            {purchaseCodes.length === 0 ? (
              <p>Aucun résultat pour votre recherche</p>
            ) : (
              <Table hover responsive className="table-fichiers mt-1">
                <thead>
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Type</th>
                    <th scope="col">Marque</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Initiateur</th>
                    <th scope="col">Entreprise</th>
                    <th scope="col">Ajouté le</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseCodes.map((purchaseCode, index) => (
                    <tr key={index} className="hover-actions-trigger">
                      <td>{purchaseCode.title}</td>
                      <td>
                        {parseInt(purchaseCode.brand.is_engine_type) === 1
                          ? 'Moteur'
                          : 'Non-moteur'}
                      </td>
                      <td>{purchaseCode.brand.title}</td>
                      <td>{purchaseCode.quantity}</td>
                      <td>
                        {purchaseCode.user !== null &&
                        purchaseCode.user !== undefined
                          ? `${purchaseCode.user.firstname} ${purchaseCode.user.lastname}`
                          : ''}
                      </td>
                      <td>
                        {purchaseCode.company !== null &&
                        purchaseCode.company !== undefined
                          ? purchaseCode.company.name
                          : ''}
                      </td>
                      <td>
                        {Moment(purchaseCode.created_at)
                          .local()
                          .format('DD/MM/YYYY')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default CodesAchats;
