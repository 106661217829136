import { toast } from 'react-toastify';

export default function RequireAuthPartner(navigate) {
  if (!localStorage.getItem('accessToken') && !localStorage.getItem('user')) {
    toast('Veuillez vous connecter pour accéder au contenu');
    console.log('RequireAuthPartner');
    navigate('/login');
  } else if (
    JSON.parse(localStorage.getItem('user')).partner_id === undefined ||
    JSON.parse(localStorage.getItem('user')).partner_id === null
  ) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    navigate('/');
  }
}
