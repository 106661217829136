import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'contracts';

export async function IndexContract(readOnly = false) {
  if (readOnly) {
    return modelService.IndexModel(`${model}/from-member`);
  } else {
    return modelService.IndexModel(`${model}`);
  }
}

export async function showContract(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostContract(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutContract(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteContract(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

/*
Évols contrats performances
 */
export async function contractAskInvoice(id, year, type) {
  let formData = new FormData();
  return modelService.PutModel(
    `${model}/ask-invoice/${id}?year=${year}&type=${type}`,
    formData
  );
}

export async function uploadInvoiceFile(id, year, type, file_url) {
  let formData = new FormData();
  return modelService.PutModel(
    `${model}/upload-invoice/${id}?year=${year}&type=${type}&file_url=${file_url}`,
    formData
  );
}

export async function resetInvoiceState(id, year, type) {
  let formData = new FormData();
  return modelService.PutModel(
    `${model}/reset-invoice/${id}?year=${year}&type=${type}`,
    formData
  );
}

export async function relanceInvoice(id, year, type) {
  let formData = new FormData();
  return modelService.PutModel(
    `${model}/relance-invoice/${id}?year=${year}&type=${type}`,
    formData
  );
}

export async function ExportSheet(year) {
  return modelService.IndexModel(`${model}/export?year=${year}`);
}
