export const getFrontUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://tredunion.fr';
  } else {
    return 'http://localhost:3000';
  }
};

export const elearningEvaluationQuestionGroups = [
  {
    groupLabel: 'Préparation en amont de la formation',
    questions: [
      {
        label:
          'Comment évaluez-vous la qualité des informations reçues en amont par TRED UNION pour débuter cette formation dans les meilleures conditions ?',
        name: 'grade_informations_quality',
        type: 'integer',
        hasComment: 'grade_informations_quality_comment'
      },
      {
        label:
          'Avez-vous rencontré des difficultés techniques pour vous connecter à la chaine E LEARNING ?',
        name: 'had_technical_difficulties',
        type: 'yesNo',
        hasYesComment: 'technical_difficulties_comment'
      }
    ]
  },
  {
    groupLabel: 'Organisation',
    questions: [
      {
        label: 'Pensez-vous que la durée de formation est adaptée ?',
        name: 'duration',
        type: 'radio',
        enum: ['Trop court', 'Adapté', 'Trop long']
      }
    ]
  },
  {
    groupLabel: 'Pédagogie animation',
    questions: [
      {
        label:
          'Comment évaluez-vous le nombre de vidéos rapport au programme pour développer le sujet ?',
        name: 'video_number_grade',
        type: 'integer',
        hasComment: 'video_number_comment'
      },
      {
        label:
          "Comment évaluez-vous le format distanciel par rapport au format présentiel pour traiter le sujet ? (qualité de l'apprentissage)",
        name: 'format_grade',
        type: 'integer',
        hasComment: 'format_comment_not_satisfied',
        hasCommentLabel:
          "Si vous n'êtes pas satifait de ce format, quels en ont été les problématiques ou les manques ?",
        hasPositiveComment: 'format_comment_satisfied',
        hasPositiveCommentLabel:
          'Si vous êtes satifait de ce format, quels en ont été les atouts ?'
      }
    ]
  },
  {
    groupLabel: 'Apprentissage',
    questions: [
      {
        label:
          "Pensez-vous que l'apprentissage pendant cette session de formation vous sera utile ?",
        name: 'utility_grade',
        type: 'integer',
        hasComment: 'utility_comment'
      },
      {
        label:
          'Pensez-vous que les sujets abordés pendant cette session de formation sont adaptés à votre entreprise ?',
        name: 'elements_grade',
        type: 'integer',
        hasComment: 'elements_comment'
      },
      {
        label:
          "Pensez-vous que les explications données par l'animateur pendant cette session de formation favorisaient la compréhension ?",
        name: 'explanations_grade',
        type: 'integer',
        hasComment: 'explanations_comment'
      }
    ]
  },
  {
    groupLabel: 'Post formation',
    questions: [
      {
        label:
          'Avez-vous échangé avec votre animateur par mail durant la formation ?',
        name: 'had_used_chat',
        type: 'yesNo',
        hasYesQuestion: {
          label: 'Si oui, quel est votre degré de satisfaction ?',
          name: 'chat_grade',
          type: 'integer',
          hasComment: 'chat_grade_comment'
        }
      }
    ]
  },
  {
    groupLabel: 'Général',
    questions: [
      {
        label:
          'Sur une échelle de 1 à 10, quel est votre degré de satisfaction pour cette session de formation ?',
        name: 'course_grade',
        type: 'integer'
      },
      {
        label: 'Votre commentaire général sur la formation',
        name: 'course_comment',
        type: 'string'
      }
    ]
  }
];
