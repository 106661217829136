import React, { useEffect, useState } from 'react';
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  DeleteEventCategory,
  IndexCategoriesAdminManage
} from '../../../../../../services/Event/CategoryService';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ManageCategories from './manage';

const IndexEventCategories = ({
  showIndexCategories,
  setShowIndexCategories
}) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [categories, setCategories] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showManageCategories, setShowManageCategories] = useState(false);

  useEffect(() => {
    const fetchCategoriesAdminManage = async () => {
      const responseCategories = await IndexCategoriesAdminManage();
      if (responseCategories.success === true) {
        setCategories(
          responseCategories.data.filter(category => category.is_editable === 1)
        );
      }
    };
    fetchCategoriesAdminManage();
  }, [trigger, showIndexCategories]);

  const deleteObject = async () => {
    const response = await DeleteEventCategory(selectedCategory.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Catégorie archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showIndexCategories}
        onHide={() => {
          setShowIndexCategories(false);
          window.location.reload();
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Catégories d'événements{' '}
            <small>
              <a
                href="#"
                onClick={() => {
                  setSelectedCategory({});
                  setShowManageCategories(true);
                }}
              >
                + Ajouter
              </a>
            </small>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {categories.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className="rounded-circle d-inline-block me-1"
                        style={{
                          backgroundColor: category.color,
                          height: '12px',
                          width: '12px',
                          position: 'relative',
                          top: '1px'
                        }}
                      ></span>
                      {category.name}
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          setSelectedCategory(category);
                          setShowManageCategories(true);
                        }}
                      >
                        Modifier
                      </a>
                    </td>
                    <td>
                      <span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Supprimer la catégorie et tous ses événements
                            </Tooltip>
                          }
                        >
                          <a
                            href="javascript:void(0)"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedCategory(category);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <ManageCategories
        showManageCategories={showManageCategories}
        setShowManageCategories={setShowManageCategories}
        selectedCategory={selectedCategory}
        setParentTrigger={setTrigger}
      />
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedCategory.id !== undefined && (
              <>
                L'archivage de la catégorie "{`${selectedCategory.name}`}" et de
                tous ses événements est définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default IndexEventCategories;

IndexEventCategories.propTypes = {
  showIndexCategories: PropTypes.bool.isRequired,
  setShowIndexCategories: PropTypes.func.isRequired
};
