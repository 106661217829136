import React, { useEffect, useState } from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import illuVisMaRoute from 'assets/img/tredunion/logovismaroute.jpg';
import Section from '../../../common/Section';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { toast } from 'react-toastify';
import { IndexCategories } from '../../../../services/VisMaRouteCategoryService';
import Vimeo from '@u-wave/react-vimeo';
import FooterStandard from '../../commons/FooterStandard';

const VisMaRoute = () => {
  const [trigger] = useState(Date.now());
  const [initialCategories, setInitialCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategoryTitle, setcurrentCategoryTitle] = useState('');
  const [currentCategoryContent, setcurrentCategoryContent] = useState('');
  const [categoryContents, setCategoryContents] = useState([]);
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({});
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };
  useEffect(() => {
    const fetchCategories = async () => {
      const responseCategories = await IndexCategories();
      if (responseCategories.success === true) {
        setInitialCategories(responseCategories.data);
        setCategories(responseCategories.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCategories();
  }, [trigger]);
  return (
    <>
      <NavbarStandard />
      <Section id="vismaroute" bg="light" className="d-flex p-lg-1 pb-0">
        <Row className="justify-content-center">
          <Col lg={content.title === undefined ? 4 : 3}>
            <img
              src={illuVisMaRoute}
              className="mw-100 cursor-pointer"
              onClick={() => {
                window.location.href = '/vis-ma-route';
              }}
            />
          </Col>
        </Row>
        {content.title === undefined && (
          <Row className="mt-2 d-none d-lg-block">
            <Col className="text-center">
              <p>Les conducteurs au service de la collectivité.</p>
            </Col>
          </Row>
        )}
      </Section>
      <Section
        bg="light"
        className="d-flex p-lg-1 pt-2 pt-lg-3 mt-5 mt-lg-3 vismaroute mb-5"
      >
        {(contents.length > 0 ||
          content.title !== undefined ||
          (categories.length > 0 &&
            initialCategories.length > 0 &&
            categories[0].id !== initialCategories[0].id)) && (
          <Row className="mb-4">
            <Col>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setTimeout(function () {
                    if (content.title !== undefined) {
                      setContent({});
                      setCategoryContents([]);
                    } else if (contents.length > 0) {
                      setContents([]);
                      setCategoryContents([]);
                    } else if (categories.length > 0) {
                      setCategories(initialCategories);
                    }
                  }, 500);
                }}
              >
                &lt; Retour
              </Button>
            </Col>
          </Row>
        )}

        {categories.length > 0 &&
          contents.length === 0 &&
          content.title === undefined && (
            <>
              {categories[0].id !== initialCategories[0].id && (
                <>
                  <p className="redHeading mt-5">
                    Vis ma route - {currentCategoryTitle}
                  </p>
                  <p className="smaller-p text-justify mb-5">
                    {currentCategoryContent}
                  </p>
                </>
              )}
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid actualites"
                columnClassName="masonry-grid_column"
              >
                {categories.map((category, index) => (
                  <div key={index}>
                    <Card>
                      <Card.Img
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                          setTimeout(function () {
                            setCategoryContents([]);
                            if (category.childrens.length > 0) {
                              setCategories(category.childrens);
                              setcurrentCategoryTitle(category.title);
                              setcurrentCategoryContent(category.content);
                              setCategoryContents(category.contents);
                            } else if (category.contents.length > 0) {
                              setContents(category.contents);
                              setcurrentCategoryTitle(category.title);
                              setcurrentCategoryContent(category.content);
                            }
                          }, 500);
                        }}
                        className="cursor-pointer"
                        src={category.image_url}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title as="h5">{category.title}</Card.Title>
                        <Card.Text className="smaller-p">
                          {category.content}
                        </Card.Text>
                        {category.childrens.length > 0 ||
                        category.contents.length > 0 ? (
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                              setTimeout(function () {
                                if (category.childrens.length > 0) {
                                  setCategories(category.childrens);
                                  setcurrentCategoryTitle(category.title);
                                  setcurrentCategoryContent(category.content);
                                } else if (category.contents.length > 0) {
                                  setContents(category.contents);
                                  setcurrentCategoryTitle(category.title);
                                  setcurrentCategoryContent(category.content);
                                }
                              }, 500);
                            }}
                          >
                            Voir les contenus
                          </Button>
                        ) : (
                          <p>
                            <i>
                              <small>Contenu à venir...</small>
                            </i>
                          </p>
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Masonry>
              {categoryContents.length > 0 && (
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid actualites"
                  columnClassName="masonry-grid_column"
                >
                  {categoryContents.map((content, index) => (
                    <div key={index}>
                      <Card>
                        <Card.Img
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            setTimeout(function () {
                              setContent(content);
                            }, 500);
                          }}
                          className="cursor-pointer"
                          src={content.image_url}
                          variant="top"
                        />
                        <Card.Body>
                          <Card.Title as="h5">{content.title}</Card.Title>
                          <Card.Text className="smaller-p">
                            {content.content}
                          </Card.Text>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                              setTimeout(function () {
                                setContent(content);
                              }, 500);
                            }}
                          >
                            Voir le contenu
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Masonry>
              )}
            </>
          )}

        {contents.length > 0 && content.title === undefined && (
          <>
            <p className="redHeading mt-5">
              Vis ma route - {currentCategoryTitle}
            </p>
            <p className="smaller-p text-justify mb-5">
              {currentCategoryContent}
            </p>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonry-grid actualites"
              columnClassName="masonry-grid_column"
            >
              {contents.map((content, index) => (
                <div key={index}>
                  <Card>
                    <Card.Img
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setTimeout(function () {
                          setContent(content);
                        }, 500);
                      }}
                      className="cursor-pointer"
                      src={content.image_url}
                      variant="top"
                    />
                    <Card.Body>
                      <Card.Title as="h5">{content.title}</Card.Title>
                      <Card.Text className="smaller-p">
                        {content.content}
                      </Card.Text>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                          setTimeout(function () {
                            setContent(content);
                          }, 500);
                        }}
                      >
                        Voir le contenu
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Masonry>
          </>
        )}

        {content.title !== undefined && (
          <>
            <p className="redHeading mt-5">Vis ma route - {content.title}</p>
            <p className="smaller-p text-justify ">{content.content}</p>
            <div className="d-block w-100 mt-2 mb-3">
              <Vimeo
                video={content.vimeo_id}
                autoplay
                style={{ width: '100%' }}
                className="vimeo-video"
              />
            </div>
          </>
        )}
      </Section>
      <FooterStandard />
    </>
  );
};

export default VisMaRoute;
