import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportMoteurRechercheHome = ({ companies }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    companies.map(company => {
      let cleanedCode = {
        name: company.name,
        address: company.address,
        zip_code: company.zip_code,
        city: company.city,
        contact_website: company.contact_website,
        contact_phone: company.contact_phone,
        contact_email: company.contact_email
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [companies]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto ms-2"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet
              data={cleanData}
              name="Export moteur de recherche Tred Union"
            >
              <ExcelColumn label="Entreprise" value="name" />
              <ExcelColumn label="Adresse" value="address" />
              <ExcelColumn label="Code postal" value="zip_code" />
              <ExcelColumn label="Ville" value="city" />
              <ExcelColumn label="Site internet" value="contact_website" />
              <ExcelColumn label="Téléphone" value="contact_phone" />
              <ExcelColumn label="Email" value="contact_email" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportMoteurRechercheHome.propTypes = {
  companies: PropTypes.array
};

export default ExportMoteurRechercheHome;
