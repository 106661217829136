import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'training-categories';

export async function IndexTrainingCategory() {
  return modelService.IndexModel(`${model}`);
}

export async function PostTrainingCategory(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutTrainingCategory(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteTrainingCategory(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
