import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Spinner
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Select from 'react-select';
import { IndexAdmin } from '../../../../../services/CompanyService';
import {
  DeletePurchaseCode,
  IndexPurchaseCodeAdmin
} from '../../../../../services/PurchaseCodeService';
import ExportPurchaseCodesFromIndex from '../../../../../exports/purchaseCodesIndex';
import { IndexPurchaseCodeBrand } from '../../../../../services/PurchaseCodeBrandService';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeBrandsIsEngine,
  changeBrandsNoEngine,
  changeCompanies,
  changeTypes
} from '../../../../../redux/slices/purchaseCodesSlice';

const IndexPurchaseCodes = () => {
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [types, setTypes] = useState([]);
  const [brandsIsEngine, setBrandsIsEngine] = useState([]);
  const [brandsNoEngine, setBrandsNoEngine] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPurchaseCode, setSelectedPurchaseCode] = useState(false);
  const [formData, setFormData] = useStateWithCallbackLazy({
    companies: [],
    types: [],
    brandsIsEngine: [],
    brandsNoEngine: []
  });

  const {
    companies: companiesStored,
    types: typesStored,
    brandsIsEngine: brandsIsEngineStored,
    brandsNoEngine: brandsNoEngineStored
  } = useSelector(state => state.persistedPurchaseCodesReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialFormDataSearch = {
      companies: companiesStored,
      types: typesStored,
      brandsIsEngine: brandsIsEngineStored,
      brandsNoEngine: brandsNoEngineStored
    };
    setFormData(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, []);

  useEffect(() => {
    if (formData === null) return;

    const updateFormDataSearch = {
      ...formData,
      companies: companiesStored,
      types: typesStored,
      brandsIsEngine: brandsIsEngineStored,
      brandsNoEngine: brandsNoEngineStored
    };
    setFormData(updateFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [
    companiesStored,
    typesStored,
    brandsIsEngineStored,
    brandsNoEngineStored,
    initialObjects
  ]);

  useEffect(() => {
    const fetchObjects = async () => {
      setIsLoading(true);
      const responseObjects = await IndexPurchaseCodeAdmin();
      if (responseObjects.success === true) {
        setInitialApiObjects(responseObjects.data);
        transformObjects(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let dataCompanies = responseCompanies.data;
        let companiesArray = [];
        dataCompanies.map(company => {
          companiesArray.push({
            value: company.name,
            label: company.name
          });
        });
        setCompanies(companiesArray);
      }
    };
    const fetchBrands = async () => {
      const responseBrands = await IndexPurchaseCodeBrand();
      if (responseBrands.success === true) {
        let dataBrands = responseBrands.data;
        let brandsIsEngineArray = [];
        let brandsNoEngineArray = [];
        dataBrands.map(brand => {
          if (brand.is_engine_type === 1) {
            brandsIsEngineArray.push({
              value: brand.title,
              label: brand.title
            });
          } else {
            brandsNoEngineArray.push({
              value: brand.title,
              label: brand.title
            });
          }
        });
        setBrandsIsEngine(brandsIsEngineArray);
        setBrandsNoEngine(brandsNoEngineArray);
      }
    };
    fetchCompanies();
    fetchObjects();
    fetchBrands();
    setTypes([
      {
        value: 'Moteur',
        label: 'Moteur'
      },
      {
        value: 'Non moteur',
        label: 'Non moteur'
      }
    ]);
  }, [trigger]);

  const updateSearch = (data, objects, resetPagination) => {
    if (
      data.companies.length === 0 &&
      data.types.length === 0 &&
      data.brandsIsEngine.length === 0 &&
      data.brandsNoEngine.length === 0
    ) {
      setObjects(objects);
    } else {
      if (resetPagination === true) {
        setTablePageIndex(0);
      }
      let tmpFilterObjects = objects;
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (obj.company === company.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.types.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.types.map(type => {
            if (obj.type === type.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.brandsIsEngine.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.brandsIsEngine.map(brand => {
            if (obj.brand === brand.value) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.brandsNoEngine.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.brandsNoEngine.map(brand => {
            if (obj.brand === brand.value) {
              result = true;
            }
          });
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  const deleteObject = async () => {
    setIsLoading(true);
    const response = await DeletePurchaseCode(selectedPurchaseCode.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Code achat archivé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        title: obj.title,
        type: obj.brand
          ? obj.brand.is_engine_type
            ? 'Moteur'
            : 'Non moteur'
          : '',
        brand: obj.brand ? obj.brand.title : '',
        quantity: obj.quantity,
        user: obj.user ? `${obj.user.firstname} ${obj.user.lastname}` : '',
        company: obj.company ? obj.company.name : '',
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        actions: (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="overlay-trigger-example">Archiver</Tooltip>}
            >
              <Button
                size="sm"
                variant="danger"
                className="me-0 mb-2"
                type="button"
                onClick={() => {
                  setShowDelete(true);
                  setSelectedPurchaseCode(obj);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px'
                  }}
                >
                  <path
                    d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </>
        )
      });
    });
    updateSearch(formData, transformedApiObjects);
    setInitialObjects(transformedApiObjects);
    // setObjects(transformedApiObjects);
  };

  return (
    <>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedPurchaseCode.id !== undefined && (
              <>
                L'archivage du code achat "{selectedPurchaseCode.title}" est
                définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des codes achats
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row>
              {companies.length > 0 && (
                <Col xs={3} className="mb-3">
                  <Form.Label>Société(s)</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={companies}
                    placeholder="Choisir..."
                    isMulti
                    name="companies"
                    classNamePrefix="react-select"
                    value={formData.companies}
                    onChange={value => {
                      dispatch(changeCompanies(value));
                      setFormData(
                        {
                          ...formData,
                          companies: value
                        },
                        data => {
                          updateSearch(data, initialObjects, true);
                        }
                      );
                    }}
                  />
                </Col>
              )}
              {types.length > 0 && (
                <Col xs={3} className="mb-3">
                  <Form.Label>Type(s)</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={types}
                    placeholder="Choisir..."
                    isMulti
                    name="types"
                    classNamePrefix="react-select"
                    value={formData.types}
                    onChange={value => {
                      dispatch(changeTypes(value));
                      setFormData(
                        {
                          ...formData,
                          types: value
                        },
                        data => {
                          updateSearch(data, initialObjects, true);
                        }
                      );
                    }}
                  />
                </Col>
              )}
              {brandsIsEngine.length > 0 && (
                <Col xs={3} className="mb-3">
                  <Form.Label>Marques(s) "Moteur"</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={brandsIsEngine}
                    placeholder="Choisir..."
                    isMulti
                    name="brandsIsEngine"
                    classNamePrefix="react-select"
                    value={formData.brandsIsEngine}
                    onChange={value => {
                      dispatch(changeBrandsIsEngine(value));
                      setFormData(
                        {
                          ...formData,
                          brandsIsEngine: value
                        },
                        data => {
                          updateSearch(data, initialObjects, true);
                        }
                      );
                    }}
                  />
                </Col>
              )}
              {brandsNoEngine.length > 0 && (
                <Col xs={3} className="mb-3">
                  <Form.Label>Marques(s) "Non moteur"</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={brandsNoEngine}
                    placeholder="Choisir..."
                    isMulti
                    name="brandsNoEngine"
                    classNamePrefix="react-select"
                    value={formData.brandsNoEngine}
                    onChange={value => {
                      dispatch(changeBrandsNoEngine(value));
                      setFormData(
                        {
                          ...formData,
                          brandsNoEngine: value
                        },
                        data => {
                          updateSearch(data, initialObjects, true);
                        }
                      );
                    }}
                  />
                </Col>
              )}
              {objects && objects.length > 0 && (
                <Fragment>
                  <Col xs={12} className="mt-3">
                    <Table
                      data={objects}
                      tablePageIndex={tablePageIndex}
                      setTablePageIndex={setTablePageIndex}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data, tablePageIndex, setTablePageIndex }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
        Header: 'Code'
      },
      {
        accessor: 'type',
        Header: 'Type'
      },
      {
        accessor: 'brand',
        Header: 'Marque'
      },
      {
        accessor: 'quantity',
        Header: 'Quantité'
      },
      {
        accessor: 'user',
        Header: 'Membre'
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'created_at',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: tablePageIndex, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageIndex(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportPurchaseCodesFromIndex purchaseCodes={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

export default IndexPurchaseCodes;
