import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FileUploader from '../../../commons/fileUploader';
import FormAlert from '../../../commons/FormAlert';
import { IndexResourceCategoryAdmin } from '../../../../../services/ResourceCategoryService';
import { IndexRoles } from '../../../../../services/RoleService';
import {
  PostResource,
  PutResourceAdmin,
  ShowResourceAdmin
} from '../../../../../services/ResourceService';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

const ManageResource = () => {
  const { id } = useParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesSelect, setRolesSelect] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    file_url: '',
    category_id: '',
    roles: [],
    send_mail: 0
  });
  useEffect(() => {
    const fetchCategories = async () => {
      const responseObject = await IndexResourceCategoryAdmin();
      if (responseObject.success === true) {
        setCategories(responseObject.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchRoles = async () => {
      const responseObject = await IndexRoles();
      if (responseObject.success === true) {
        setRoles(responseObject.data);
        let rolesArray = [];
        responseObject.data.map(role => {
          rolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        setFormData({
          ...formData,
          roles: [
            {
              value: `${
                responseObject?.data?.filter(role => role?.id === 10)[0]?.id
              }`,
              label: responseObject?.data?.filter(role => role?.id === 10)[0]
                ?.title
            }
          ]
        });
        setRolesSelect(rolesArray);
        /*if (!id) {
          let contactAchat = responseObject.data.find(role => role.id === 10);
          console.log(responseObject.data);
          if (contactAchat) {
            setFormData({
              roles: [
                {
                  value: `${contactAchat.id}`,
                  label: contactAchat.title
                }
              ]
            });
          }
        }*/
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchResource = async () => {
      const responseResource = await ShowResourceAdmin(id);
      if (responseResource.success === true) {
        let dataResource = responseResource.data;
        let rolesArray = [];
        dataResource.roles.map(role => {
          rolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        setFormData({
          title: dataResource.title,
          category_id: dataResource.category_id,
          roles: rolesArray
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCategories();
    fetchRoles();
    if (id) {
      fetchResource();
    }
  }, [trigger]);

  const handleFieldChange = e => {
    if (e.target.name === 'send_mail') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked ? 0 : 1
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutResourceAdmin(id, formData);
    } else {
      response = await PostResource(formData);
    }
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    if (response.success === true) {
      setIsLoading(false);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);
      const successMessage = `Ressource enregistrée.`;
      //toast(successMessage);
      setSuccessForm(successMessage);
      setTrigger(Date.now());
      setTimeout(function () {
        window.location.href = `/administration/ressources`;
      }, 2500);
    }
  };

  return (
    <>
      {categories.length > 0 && roles.length > 0 && (
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                        {id ? 'Modifier' : 'Ajouter'} une ressource
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/ressources';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        Annuler
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              {successForm && !errorForm && (
                <div className="mt-3">
                  <FormAlert
                    className="mt-3"
                    variant="success"
                    data={successForm}
                  />
                </div>
              )}
            </Col>
            <Col xs={12}>
              <Card className="mb-3 pb-3">
                <Card.Body className="bg-light">
                  <Row className="gx-2 gy-3">
                    <Col md="12">
                      <Form.Group controlId="name">
                        <Form.Label>Titre :</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Titre"
                          value={formData.title || ''}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    {!id && (
                      <>
                        <Col md="12" className="pb-2 pt-3">
                          <div className="border-dashed-bottom"></div>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label>Fichier :</Form.Label>
                            <FileUploader
                              pathToUpload="resources"
                              formData={formData}
                              setFormData={setFormData}
                              format="any"
                              databaseField="file_url"
                              cropperEnabled={false}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col md="12" className="pb-2 pt-3">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="category_id">
                        <Form.Label>Catégorie :</Form.Label>
                        <Form.Select
                          aria-label="Catégorie"
                          name="category_id"
                          value={formData.category_id}
                          onChange={handleFieldChange}
                        >
                          <option value="">Choisir...</option>
                          {categories.map((c, index) => (
                            <RecursiveCategory
                              category={c}
                              level={0}
                              key={index}
                            />
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="12" className="pb-2 pt-3">
                      <div className="border-dashed-bottom"></div>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="roles">
                        <Form.Label>Autorisation(s) :</Form.Label>
                        <Select
                          closeMenuOnSelect={false}
                          options={rolesSelect}
                          placeholder="Choisir une ou plusieurs autorisations..."
                          isMulti
                          name="roles"
                          classNamePrefix="react-select"
                          value={formData.roles}
                          onChange={value => {
                            setFormData({
                              ...formData,
                              roles: value
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {!id && formData.roles.length > 0 && (
                      <Col xs={12} className="mt-3 d-none">
                        <Alert variant="info">
                          <label>
                            <input
                              type="checkbox"
                              id="send_mail"
                              name="send_mail"
                              value="1"
                              className="me-1"
                              checked={
                                formData.send_mail === 0 ||
                                formData.send_mail === '0'
                              }
                              onChange={handleFieldChange}
                            />
                            Ne pas envoyer de mail ?
                          </label>
                          <span className="d-block smaller-p">
                            Par défaut, les membres disposants des autorisations
                            sélectionnées ci-dessous vont recevoir un email
                            automatique, notifiant l'ajout d'une nouvelle
                            ressource sur leur espace Tred Union. Cochez la case
                            ci-dessus pour ne pas envoyer ce mail automatique.
                          </span>
                        </Alert>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/ressources';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        Annuler
                      </Button>
                      {!successForm && (
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            style={{
                              marginBottom: '2px',
                              marginRight: '4px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                          Enregistrer
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

const RecursiveCategory = ({ category, level, disabled }) => {
  return (
    <Fragment>
      <option
        disabled={
          disabled === true ||
          (category.childrens_admin && category.childrens_admin.length > 0)
        }
        value={category.id}
      >
        {getRecursiveCategorySpaces(level)}
        {category.title}
        {category.resources && category.resources.length > 0
          ? ` | ${category.resources.length} fichier${
              category.resources.length > 1 ? 's' : ''
            }`
          : ''}
      </option>
      {category.childrens_admin &&
        category.childrens_admin.length > 0 &&
        category.childrens_admin.map((children, index) => (
          <RecursiveCategory
            key={index}
            category={children}
            level={level + 1}
          />
        ))}
    </Fragment>
  );
};

const getRecursiveCategorySpaces = level => {
  let spaces = '';
  for (let i = 0; i < level; i++) {
    spaces += '--';
  }
  return `${spaces} `;
};

RecursiveCategory.propTypes = {
  category: PropTypes.object,
  level: PropTypes.number,
  disabled: PropTypes.any
};

export default ManageResource;
