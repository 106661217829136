import ModelService from './../ModelService';

const modelService = new ModelService();

const model = 'surveys';

export async function IndexSurveys(type) {
  if (type) {
    return modelService.IndexModel(`${model}?surveyType=${type}`);
  } else {
    return modelService.IndexModel(`${model}`);
  }
}

export async function IndexSurveysEvent() {
  return modelService.IndexModel(`${model}/index-event`);
}

export async function ShowAdminPanelSurvey(id) {
  return modelService.ShowModel(`${model}/admin-panel/${id}`);
}

export async function ShowPublicSurvey(uuid, linkUuid) {
  return modelService.ShowModel(
    `${model}/${uuid}${linkUuid ? `?link=${linkUuid}` : ''}`
  );
}

export async function ShowAdminSurvey(uuid, linkUuid) {
  return modelService.ShowModel(
    `${model}/admin/${uuid}${linkUuid ? `?link=${linkUuid}` : ''}`
  );
}

export async function ValidateSurvey(questions) {
  return modelService.PutModel(`${model}/validate/`, questions);
}

export async function SubmitSurvey(uuid, questions) {
  return modelService.PutModel(`${model}/${uuid}`, questions);
}

export async function SubmitSurveyAdmin(uuid, questions) {
  return modelService.PutModel(`${model}/admin/${uuid}`, questions);
}

export async function PostSurvey(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutSurvey(id, formData) {
  return modelService.PutModel(`${model}/admin-panel/${id}`, formData);
}

export async function DeleteSurvey(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function SendSurveyNotification(id, formData) {
  return modelService.PutModel(`${model}/${id}/send-notification`, formData);
}

export async function DeleteSurveyQuestion(id) {
  return modelService.DeleteModel(`survey-questions/${id}`);
}

export async function DeleteSurveyQuestionGroup(id) {
  return modelService.DeleteModel(`survey-question-groups/${id}`);
}

export async function UpdateDunningStatusRecipient(formData) {
  return modelService.PutModel(
    `survey-update-dunning-status-recipient`,
    formData
  );
}

export async function CancelRecipientAnswers(formData) {
  return modelService.PutModel(`survey-cancel-recipient-answers`, formData);
}

export async function RelaunchRecipient(formData) {
  return modelService.PutModel(`survey-relaunch-recipient`, formData);
}
