import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Avatar from 'components/common/Avatar';
import { useEffect } from 'react';
import { LogOutUserService } from '../../../../services/AuthService';
import { useNavigate } from 'react-router';
import { membersRoutes } from '../../../../routes/authRoutes';

const NineDotMenu = ({ isAdminArea }) => {
  const [show, setShow] = useState(null);
  const [user, setUser] = useState(false);

  const location = useLocation();
  const usePathname = () => {
    return location.pathname.replace(/\/+$/, '');
  };

  function pluck(array, key) {
    return array.map(o => o[key]);
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setShow(false);
    });
  }, []);

  let navigate = useNavigate();

  return (
    <>
      {user !== false && (
        <Dropdown navbar={true} show={show} onToggle={() => setShow(!show)}>
          <Dropdown.Toggle
            bsPrefix="toggle"
            as={Link}
            to="#"
            className="nav-link pe-0 ps-2 nine-dots pt-1"
          >
            {user &&
            user.profile_picture_url !== undefined &&
            user.profile_picture_url !== null ? (
              <Avatar src={user.profile_picture_url} />
            ) : (
              <Avatar name={`${user.firstname} ${user.lastname}`} />
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg"
            show={show}
          >
            <Card className="dropdown-menu-end shadow-none">
              <SimpleBarReact className="nav-quick-links">
                <Card.Body>
                  <span
                    id="title-nine-dots"
                    className="d-block mb-3 text-center"
                  >
                    Mes accès rapides
                  </span>
                  <Row className="text-center g-0" id="acces-rapides-container">
                    <Col xs={6}>
                      <a
                        className={classNames(
                          'd-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none',
                          {
                            'bg-200':
                              usePathname() === '/espace-membre/accueil' ||
                              usePathname() ===
                                '/espace-membre/transferer-un-fichier' ||
                              usePathname() ===
                                '/espace-membre/trouver-un-membre'
                          }
                        )}
                        href="#"
                        onClick={() => {
                          navigate(
                            user.partner_id !== undefined &&
                              user.partner_id !== null
                              ? '/espace-partenaire'
                              : '/espace-membre/accueil'
                          );
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                          setShow(false);
                        }}
                      >
                        {localStorage.getItem('user') &&
                        JSON.parse(localStorage.getItem('user'))
                          .profile_picture_url !== undefined &&
                        JSON.parse(localStorage.getItem('user'))
                          .profile_picture_url !== null ? (
                          <Avatar
                            size="l"
                            src={
                              JSON.parse(localStorage.getItem('user'))
                                .profile_picture_url
                            }
                          />
                        ) : (
                          <Avatar
                            size="l"
                            mediaClass="fs-2"
                            id="avatar-profile-dots"
                            name={`${user.firstname} ${user.lastname}`}
                          />
                        )}
                        <p className="mb-0 fw-medium text-800 text-truncate fs--2">
                          Mon espace
                        </p>
                      </a>
                    </Col>
                    {user.is_admin === 1 && (
                      <Col xs={6}>
                        <a
                          className={classNames(
                            'd-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none',
                            {
                              'bg-200': isAdminArea === true
                            }
                          )}
                          href="#"
                          onClick={() => {
                            navigate('/administration/calendrier');
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            setShow(false);
                          }}
                        >
                          <Avatar
                            size="l"
                            mediaClass="fs-2"
                            id="avatar-profile-dots"
                            name={`${user.firstname} ${user.lastname}`}
                          />
                          <p className="mb-0 fw-medium text-800 text-truncate fs--2">
                            Administration
                          </p>
                        </a>
                      </Col>
                    )}
                    {user.partner_id === null && (
                      <Fragment>
                        {membersRoutes
                          .filter(
                            route =>
                              route.menus.includes('dotTop') &&
                              (route.roles.length === 0 ||
                                route.roles.filter(x =>
                                  pluck(user.roles, 'id').includes(x)
                                ).length > 0) &&
                              (route.exceptRoles.length === 0 ||
                                route.exceptRoles.filter(x =>
                                  pluck(user.roles, 'id').includes(x)
                                ).length === 0)
                          )
                          .map((route, index) => (
                            <Col key={index} xs={6}>
                              <a
                                className={classNames(
                                  'd-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none',
                                  {
                                    'bg-200': usePathname() === route.path
                                  }
                                )}
                                href="#"
                                onClick={() => {
                                  navigate(route.path);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                  });
                                  setShow(false);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="25"
                                  height="25"
                                >
                                  <path
                                    d={route.svg}
                                    fill="rgba(229,47,18,1)"
                                  />
                                </svg>

                                <p className="mb-0 fw-medium text-800 text-truncate fs--2 mt-2">
                                  {route.label}
                                </p>
                              </a>
                            </Col>
                          ))}
                      </Fragment>
                    )}
                    <Col xs={12}>
                      <hr className="my-3 mx-n3 bg-200" />
                    </Col>
                    {user.partner_id === null && (
                      <Fragment>
                        {membersRoutes
                          .filter(
                            route =>
                              route.menus.includes('dotBottom') &&
                              (route.roles.length === 0 ||
                                route.roles.filter(x =>
                                  pluck(user.roles, 'id').includes(x)
                                ).length > 0) &&
                              (route.exceptRoles.length === 0 ||
                                route.exceptRoles.filter(x =>
                                  pluck(user.roles, 'id').includes(x)
                                ).length === 0)
                          )
                          .map((route, index) => (
                            <Col key={index} xs={6}>
                              <a
                                className={classNames(
                                  'd-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none',
                                  {
                                    'bg-200': usePathname() === route.path
                                  }
                                )}
                                href="#"
                                onClick={() => {
                                  navigate(route.path);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                  });
                                  setShow(false);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="25"
                                  height="25"
                                >
                                  <path
                                    d={route.svg}
                                    fill="rgba(229,47,18,1)"
                                  />
                                </svg>

                                <p className="mb-0 fw-medium text-800 text-truncate fs--2 mt-2">
                                  {route.label}
                                </p>
                              </a>
                            </Col>
                          ))}
                        <Col xs={6}>
                          <a
                            className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none"
                            href="#"
                            onClick={() => {
                              window.location.href = '/#reseau';
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="25"
                              height="25"
                            >
                              <path
                                d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                fill="rgba(229,47,18,1)"
                              />
                            </svg>

                            <p className="mb-0 fw-medium text-800 text-truncate fs--2 mt-2">
                              Trouver un transporteur
                            </p>
                          </a>
                        </Col>
                        <Col xs={12}>
                          <hr className="my-3 mx-n3 bg-200" />
                        </Col>
                      </Fragment>
                    )}
                    <Col xs={6}>
                      <a
                        className={classNames(
                          'd-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none',
                          {
                            'bg-200':
                              usePathname() === '/espace-membre/informations'
                          }
                        )}
                        href="#"
                        onClick={() => {
                          navigate(
                            user.partner_id !== undefined &&
                              user.partner_id !== null
                              ? '/espace-partenaire/informations'
                              : '/espace-membre/informations'
                          );
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          });
                          setShow(false);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="25"
                          height="25"
                        >
                          <path
                            d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                            fill="rgba(100,99,99,1)"
                          />
                        </svg>

                        <p className="mb-0 fw-medium text-800 text-truncate fs--2 mt-2">
                          Mes informations
                        </p>
                      </a>
                    </Col>
                    <Col xs={6}>
                      <a
                        className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none"
                        href="#"
                        onClick={() => LogOutUserService()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="25"
                          height="25"
                        >
                          <path
                            d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"
                            fill="rgba(100,99,99,1)"
                          />
                        </svg>

                        <p className="mb-0 fw-medium text-800 text-truncate fs--2 mt-2">
                          Déconnexion
                        </p>
                      </a>
                    </Col>
                  </Row>
                </Card.Body>
              </SimpleBarReact>
            </Card>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

const QuickLinkItem = ({ avatar, avatarText, img, title, link, hr }) => {
  return (
    <>
      {hr ? (
        <Col xs={12}>
          <hr className="my-3 mx-n3 bg-200" />
        </Col>
      ) : (
        <Col xs={4}>
          <a
            className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none"
            target={'_blank'}
            rel="noopener noreferrer"
            href={link || '#'}
          >
            {avatar && <Avatar src={avatar} size="2xl" />}
            {avatarText && (
              <Avatar
                isExact
                name={avatarText}
                size="2xl"
                mediaClass="fs-2 bg-soft-primary text-primary"
              />
            )}
            {img && <img src={img} width={40} height={40} />}
            <p
              className={classNames(
                'mb-0 fw-medium text-800 text-truncate fs--2',
                {
                  'pt-1': img
                }
              )}
            >
              {title}
            </p>
          </a>
        </Col>
      )}
    </>
  );
};

QuickLinkItem.propTypes = {
  avatar: PropTypes.string,
  avatarText: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  hr: PropTypes.bool
};

NineDotMenu.propTypes = {
  isAdminArea: PropTypes.any
};

export default NineDotMenu;
