import React from 'react';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';

const Map = () => {
  return (
    <>
      <Section bg="light" className="d-flex align-items-end p-0" id="map">
        <Row>
          <Col xs={12} className="p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2665.8827022170894!2d7.401500315796204!3d48.07390697921841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47916f3647b5a68b%3A0xb62a33d3f2094eb9!2s4%20Rue%20de%20Lugano%2C%2068180%20Horbourg-Wihr!5e0!3m2!1sfr!2sfr!4v1654851971294!5m2!1sfr!2sfr"
              style={{ width: '100%', border: '0' }}
              height="300"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Map;
