import React, { useEffect, useState } from 'react';
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  DeleteEventCategory,
  IndexCategoriesAdminManage
} from '../../../../../../services/Event/CategoryService';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ManageCategories from './manage';
import {
  DeleteAdCategory,
  IndexAdCategories
} from '../../../../../../services/AdCategoryService';
import ManageAdsCategories from './manage';

const IndexAdsCategories = ({
  showIndexCategories,
  setShowIndexCategories
}) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [categories, setCategories] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showManageCategories, setShowManageCategories] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      const responseCategories = await IndexAdCategories();
      if (responseCategories.success === true) {
        let tmpCat = [];
        responseCategories.data.map((c, index) => {
          tmpCat.push({
            id: c.id,
            original_name: c.name,
            name: `${c.parent ? `${c.parent.name} > ` : ''}${c.name}`
          });
        });
        tmpCat = tmpCat.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCategories(tmpCat);
      }
    };
    fetchCategories();
  }, [trigger, showIndexCategories]);

  const deleteObject = async () => {
    const response = await DeleteAdCategory(selectedCategory.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Catégorie archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showIndexCategories}
        onHide={() => {
          setShowIndexCategories(false);
          window.location.reload();
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>
            Catégories d'annonces{' '}
            <small>
              <a
                href="#"
                onClick={() => {
                  setSelectedCategory({});
                  setShowManageCategories(true);
                }}
              >
                + Ajouter
              </a>
            </small>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {categories.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td>
                      {category.parent ? `${category.parent.name} > ` : ''}
                      {category.name}
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          setSelectedCategory(category);
                          setShowManageCategories(true);
                        }}
                      >
                        Modifier
                      </a>
                    </td>
                    <td>
                      <span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Supprimer la catégorie
                            </Tooltip>
                          }
                        >
                          <a
                            href="javascript:void(0)"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedCategory(category);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <ManageAdsCategories
        showManageCategories={showManageCategories}
        setShowManageCategories={setShowManageCategories}
        selectedCategory={selectedCategory}
        setParentTrigger={setTrigger}
      />
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedCategory.id !== undefined && (
              <>
                L'archivage de la catégorie "{`${selectedCategory.name}`}" est
                définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default IndexAdsCategories;

IndexAdsCategories.propTypes = {
  showIndexCategories: PropTypes.bool.isRequired,
  setShowIndexCategories: PropTypes.func.isRequired
};
