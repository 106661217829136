import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'trash';

export async function TrashIndex() {
  return modelService.IndexModel(`${model}`);
}

export async function TrashRestore(formData) {
  return modelService.PostModel(`${model}`, formData);
}
