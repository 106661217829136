import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import '../../../../../assets/scss/admin.scss';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Moment from 'moment/moment';
import SoftBadge from '../../../../common/SoftBadge';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { usePagination, useSortBy, useTable } from 'react-table';
import moment from 'moment/moment';
import { useNavigate } from 'react-router';
import DatePicker from 'react-datepicker';
import { IndexCategoriesAdminManage } from '../../../../../services/Event/CategoryService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { IndexAdmin } from '../../../../../services/CompanyService';

// redux
import {
  changeCompanies,
  changeCategories,
  changeSearch,
  changeStartDate,
  changeEndDate,
  changeIsPointsManaged,
  resetFilters
} from '../../../../../redux/slices/calendarSlice';
import { useSelector, useDispatch } from 'react-redux';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import IconButton from '../../../../common/IconButton';
import { faCross, faEraser } from '@fortawesome/free-solid-svg-icons';

const ListAdmin = ({ events }) => {
  const navigate = useNavigate();
  const [formDataSearch, setFormDataSearch] = useStateWithCallbackLazy({
    search: '',
    startDate: '',
    endDate: '',
    categories: [],
    companies: [],
    is_points_managed: 0
  });
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [needValidatePoints, setNeedValidatePoints] = useState([]);
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [companiesSelect, setCompaniesSelect] = useState([]);

  const {
    search: searchStored,
    startDate: startDateStored,
    endDate: endDateStored,
    categories: categoriesStored,
    companies: companiesStored,
    is_points_managed: isPointsManagedStored
  } = useSelector(state => state.persistedCalendarReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialFormDataSearch = {
      search: searchStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored),
      categories: categoriesStored,
      companies: companiesStored,
      is_points_managed: isPointsManagedStored
    };
    setFormDataSearch(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, []);

  useEffect(() => {
    if (formDataSearch === null) return;

    const updateFormDataSearch = {
      ...formDataSearch,
      search: searchStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored),
      categories: categoriesStored,
      companies: companiesStored,
      is_points_managed: isPointsManagedStored
    };
    setFormDataSearch(updateFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [
    searchStored,
    startDateStored,
    endDateStored,
    categoriesStored,
    companiesStored,
    isPointsManagedStored
  ]);

  useEffect(() => {
    let transformedApiObjects = [];
    setNeedValidatePoints(
      events.filter(
        event =>
          event.is_points_managed === 0 &&
          Moment().diff(Moment(event.start_date), 'days') > 1 &&
          event.internal_recipients &&
          event.internal_recipients.length > 0 &&
          event.category &&
          parseInt(event.category.is_editable) === 1
      )
    );
    events
      .filter(
        event =>
          !event.category ||
          (event.category &&
            parseInt(event.category.is_holiday) === 0 &&
            parseInt(event.category.is_public_holiday) === 0)
      )
      .map(obj => {
        transformedApiObjects.push({
          originalObject: obj,
          is_points_managed: obj.is_points_managed,
          simplifiedDate: Moment(obj.start_date).local().format('MM/DD/YYYY'),
          date: (
            <>
              <SoftBadge bg="primary" className="me-2 p-1 text-capitalize">
                {Moment(obj.start_date).local().format('MMMM')}
              </SoftBadge>
              <div className="d-block mt-1"></div>
              {obj.start_is_all_day
                ? `
            ${Moment(obj.start_date).local().format('DD/MM/YYYY')} ${moment(
                    obj.start_time,
                    'HH:mm:ss'
                  ).format('HH:mm')} au
            ${Moment(obj.end_date).local().format('DD/MM/YYYY')} ${moment(
                    obj.end_time,
                    'HH:mm:ss'
                  ).format('HH:mm')}`
                : `${Moment(obj.start_date)
                    .local()
                    .format('DD/MM/YYYY')} de ${moment(
                    obj.start_time,
                    'HH:mm:ss'
                  ).format('HH:mm')} à ${moment(
                    obj.end_time,
                    'HH:mm:ss'
                  ).format('HH:mm')}`}
            </>
          ),
          title: obj.title,
          originalCategory: obj.category ? obj.category.id : '',
          category: (
            <>
              {obj.category && (
                <>
                  <span
                    className="rounded-circle d-inline-block me-2"
                    style={{
                      backgroundColor: obj.category.color,
                      height: '12px',
                      width: '12px',
                      position: 'relative',
                      top: '1px'
                    }}
                  ></span>
                  {obj.category.name}
                  {obj.category.is_admin === 1 && (
                    <small>
                      <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                        cat. interne
                      </SoftBadge>
                    </small>
                  )}
                </>
              )}
              {obj.type && (
                <div className="d-block mt-1">
                  <SoftBadge bg="primary" className="ms-0">
                    {obj.type.name}
                  </SoftBadge>
                </div>
              )}
            </>
          ),
          location: (
            <>
              {obj.location_label && (
                <a
                  href={`https://www.google.com/maps/place/${obj.location_label}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {obj.location_label}
                </a>
              )}
              {obj.link && (
                <div className="d-block mt-1">
                  <SoftBadge bg="primary" className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                      style={{
                        position: 'relative',
                        bottom: '1px',
                        marginRight: '3px'
                      }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M17 9.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4.2zm0 3.159l4 2.8V8.84l-4 2.8v.718zM3 6v12h12V6H3zm2 2h2v2H5V8z"
                        fill="#e52f12"
                      />
                    </svg>
                    Lien de participation en ligne :
                  </SoftBadge>
                  <p className="mb-0">
                    <small>
                      <a href={obj.link} target="_blank" rel="noreferrer">
                        {obj.link}
                      </a>
                    </small>
                  </p>
                </div>
              )}
            </>
          ),
          originalCompany: obj.company ? obj.company.id : '',
          company: (
            <>
              {obj.company && (
                <SoftBadge bg="primary" className="">
                  {obj.company.name}
                </SoftBadge>
              )}
            </>
          ),
          progress: (
            <>
              {obj.participation_form &&
                obj.recipients &&
                obj.recipients.length > 0 && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="overlay-trigger-example">
                        {obj.recipients.length} destinataires -{' '}
                        {obj.recipients.filter(
                          r => r.pivot && r.pivot.survey_completed_on
                        ).length === 0 ? (
                          "personne n'a encore répondu"
                        ) : (
                          <>
                            {
                              obj.recipients.filter(
                                r => r.pivot && r.pivot.survey_completed_on
                              ).length
                            }{' '}
                            {obj.recipients.filter(
                              r => r.pivot && r.pivot.survey_completed_on
                            ).length > 1
                              ? 'ont'
                              : 'a'}{' '}
                            répondu
                          </>
                        )}
                      </Tooltip>
                    }
                  >
                    {obj.recipients.length ===
                    obj.recipients.filter(
                      r => r.pivot && r.pivot.survey_completed_on
                    ).length ? (
                      <div
                        className="d-block text-center cursor-pointer"
                        onClick={() => {
                          navigate(
                            `/administration/evenements/${obj.id}#recipients-table`
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                            fill="#01D27A"
                          />
                        </svg>
                      </div>
                    ) : (
                      <ProgressBar
                        className="cursor-pointer"
                        style={{ height: 8 }}
                        now={
                          ((obj.recipients.length -
                            obj.recipients.filter(
                              r =>
                                !r.pivot ||
                                (r.pivot && !r.pivot.survey_completed_on)
                            ).length) *
                            100) /
                          obj.recipients.length
                        }
                        onClick={() => {
                          navigate(
                            `/administration/evenements/${obj.id}#recipients-table`
                          );
                        }}
                      />
                    )}
                  </OverlayTrigger>
                )}
            </>
          ),
          actions: (
            <>
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Voir la fiche de l'événement
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    navigate(`/administration/evenements/${obj.id}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
              {obj.is_points_managed === 0 &&
                obj.internal_recipients &&
                obj.internal_recipients.length > 0 &&
                Moment().diff(Moment(obj.start_date), 'days') > 1 && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="overlay-trigger-example">
                        À faire - validation des points et absences
                      </Tooltip>
                    }
                  >
                    <Button
                      size="sm"
                      variant="warning"
                      className="me-2"
                      onClick={() => {
                        navigate(`/administration/evenements/${obj.id}`);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M6.382 5.968A8.962 8.962 0 0 1 12 4c2.125 0 4.078.736 5.618 1.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 1 1-14.064 0L3.515 5.93l1.414-1.414 1.453 1.453zM12 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm1-8h3l-5 6.5V14H8l5-6.505V12zM8 1h8v2H8V1z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </Button>
                  </OverlayTrigger>
                )}
            </>
          )
        });
      });
    updateSearch(formDataSearch, transformedApiObjects);
    setInitialObjects(transformedApiObjects);
    const fetchCategoriesAdminManage = async () => {
      const responseCategories = await IndexCategoriesAdminManage();
      if (responseCategories.success === true) {
        let categoriesArray = [];
        responseCategories.data.map(category => {
          categoriesArray.push({
            value: `${category.id}`,
            originalLabel: category.name,
            label: (
              <>
                <span
                  className="rounded-circle d-inline-block me-2"
                  style={{
                    backgroundColor: category.color,
                    height: '12px',
                    width: '12px',
                    position: 'relative',
                    bottom: '0px'
                  }}
                ></span>
                {category.name}
                {category.is_admin === 1 && (
                  <small>
                    <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                      cat. interne
                    </SoftBadge>
                  </small>
                )}
              </>
            )
          });
        });
        setCategoriesSelect(categoriesArray);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name
          });
          if (company.childrens && company.childrens.length > 0) {
            company.childrens.map(children => {
              companiesArray.push({
                value: `${children.id}`,
                label: `-- ${children.name}`
              });
            });
          }
        });
        setCompaniesSelect(companiesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchCategoriesAdminManage();
    fetchCompanies();
  }, [formDataSearch]);

  const updateSearch = (data, objects) => {
    if (
      data.search.length === 0 &&
      !data.startDate &&
      !data.endDate &&
      data.categories.length === 0 &&
      data.companies.length === 0 &&
      data.is_points_managed === 0
    ) {
      setObjects(objects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search && data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            obj.title &&
            obj.title.toLowerCase().includes(tmpSearch.replace('\t', ' '))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.startDate || data.endDate) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            data.startDate &&
            Moment(obj.simplifiedDate)
              .local()
              .isSameOrAfter(Moment(data.startDate)) &&
            (!data.endDate ||
              (data.endDate &&
                Moment(obj.simplifiedDate)
                  .local()
                  .isSameOrBefore(Moment(data.endDate).add(1, 'days'))))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.categories && data.categories.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.categories.map(category => {
            if (parseInt(obj.originalCategory) === parseInt(category.value)) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.companies && data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (parseInt(obj.originalCompany) === parseInt(company.value)) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.is_points_managed && data.is_points_managed === 1) {
        tmpFilterObjects = tmpFilterObjects.filter(
          obj =>
            obj.is_points_managed === 0 &&
            Moment().diff(obj.originalObject.start_date, 'days') > 1 &&
            obj.originalObject.internal_recipients &&
            obj.originalObject.internal_recipients.length > 0 &&
            obj.originalObject.category &&
            parseInt(obj.originalObject.category.is_editable) === 1
        );
      }
      setObjects(tmpFilterObjects);
    }
  };

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Choisir une date'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted cursor-pointer"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formDataSearch.startDate && formDataSearch.endDate && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            dispatch(changeStartDate(''));
            dispatch(changeEndDate(''));
            setFormDataSearch(
              {
                ...formDataSearch,
                startDate: '',
                endDate: ''
              },
              data => {
                updateSearch(data, initialObjects);
                dispatch(changeStartDate(''));
                dispatch(changeEndDate(''));
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  const customFilterForCategoryFilter = useCallback((category, input) => {
    if (input) {
      if (
        category.data &&
        category.data.originalLabel &&
        category.data.originalLabel
          .toLowerCase()
          .includes(input.toLowerCase().replace('\t', ' '))
      ) {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          {initialObjects.length > 0 ? (
            <>
              <Row className="align-items-end">
                <Col xs={3} className="mb-3">
                  <Form.Label>Recherche</Form.Label>
                  <Form.Control
                    type="text"
                    name="search"
                    placeholder="Titre"
                    value={formDataSearch.search || ''}
                    onChange={event => {
                      dispatch(changeSearch(event.target.value));
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          search: event.target.value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    onChange={dates => {
                      const [start, end] = dates;
                      dispatch(changeStartDate(start));
                      dispatch(changeEndDate(end));
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          startDate: start,
                          endDate: end
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                    startDate={formDataSearch.startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={formDataSearch.endDate}
                    selectsRange
                    showMonthDropdown
                    dateFormat="dd/MM/yy"
                    customInput={<DatePickerInput />}
                    locale="fr"
                  />
                </Col>
                <Col xs={3} className="mb-3">
                  <Form.Label>Catégorie(s)</Form.Label>
                  <Select
                    filterOption={customFilterForCategoryFilter}
                    closeMenuOnSelect={false}
                    options={categoriesSelect}
                    placeholder="Choisir..."
                    isMulti
                    name="categories"
                    classNamePrefix="react-select"
                    value={formDataSearch.categories}
                    onChange={value => {
                      dispatch(changeCategories(value));
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          categories: value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
                <Col xs={3} className="mb-3">
                  <Form.Label>Société(s)</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    options={companiesSelect}
                    placeholder="Choisir..."
                    isMulti
                    name="companies"
                    classNamePrefix="react-select"
                    value={formDataSearch.companies}
                    onChange={value => {
                      dispatch(changeCompanies(value));
                      setFormDataSearch(
                        {
                          ...formDataSearch,
                          companies: value
                        },
                        data => {
                          updateSearch(data, initialObjects);
                        }
                      );
                    }}
                  />
                </Col>
              </Row>
              {needValidatePoints.length > 0 && (
                <Row className="mt-2 justify-content-between">
                  <Col xs={8}>
                    <label>
                      <input
                        type="checkbox"
                        name="is_points_managed"
                        value={formDataSearch.is_points_managed || ''}
                        className="me-1"
                        checked={
                          formDataSearch.is_points_managed === 1 ||
                          formDataSearch.is_points_managed === '1'
                        }
                        onChange={event => {
                          dispatch(
                            changeIsPointsManaged(event.target.checked ? 1 : 0)
                          );
                          setFormDataSearch(
                            {
                              ...formDataSearch,
                              is_points_managed: event.target.checked ? 1 : 0
                            },
                            data => {
                              updateSearch(data, initialObjects, true);
                            }
                          );
                        }}
                      />
                      Afficher uniquement les événements en attente de
                      validation des points et absences{' '}
                      <SoftBadge pill bg="warning" className="ms-0">
                        {needValidatePoints.length}
                      </SoftBadge>
                    </label>
                  </Col>
                  <Col xs={4} className="justify-content-end d-flex">
                    <IconButton
                      className="w-auto"
                      size="sm"
                      icon={faEraser}
                      transform="shrink-3"
                      onClick={() => {
                        dispatch(resetFilters());
                        setFormDataSearch(
                          {
                            ...formDataSearch,
                            search: '',
                            startDate: null,
                            endDate: null,
                            categories: [],
                            companies: [],
                            is_points_managed: 0
                          },
                          data => {
                            updateSearch(data, initialObjects);
                          }
                        );
                      }}
                    >
                      Effacer les filtres
                    </IconButton>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col xs={12}>
                  {objects.length > 0 && <Table data={objects} />}
                </Col>
              </Row>
            </>
          ) : (
            <p className="mb-0">
              Aucun événement à afficher
              <br />
              <a
                href={'/administration/evenements/ajouter'}
                className="cursor-pointer"
                onClick={() => {
                  navigate('/administration/evenements/ajouter');
                }}
              >
                + Ajouter un événement
              </a>{' '}
            </p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
ListAdmin.propTypes = {
  events: PropTypes.array.isRequired
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'date',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a.original.simplifiedDate).getTime();
          var b1 = new Date(b.original.simplifiedDate).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'title',
        Header: 'Événement'
      },
      {
        accessor: 'category',
        Header: 'Catégorie'
      },
      {
        accessor: 'location',
        Header: 'Lieu'
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'progress',
        Header: 'Formulaire'
      },
      {
        accessor: 'actions',
        Header: 'Actions'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

export default ListAdmin;
