import React from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import illu1 from 'assets/img/tredunion/montage-lmtr_2-variante-5.jpeg';
import illu2 from 'assets/img/tredunion/montage-LMTR_4.jpeg';
import illu3 from 'assets/img/tredunion/logo-lmtr-rvb-variante-jaune-2019-09-17.png';
import FooterStandard from '../../commons/FooterStandard';

const LMTR = () => {
  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{ backgroundImage: `url("${bgActualites}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>Le Monde du Transport Réuni</h2>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-flex align-items-end p-1 mt-5">
        <Row className="mt-lg-5 mb-5">
          <Col xs={12}>
            <Row className="align-items-center">
              <Col lg={6}>
                <img className="mw-100" src={illu1} />
              </Col>
              <Col lg={6} className="mt-3 mt-lg-0">
                <p className="mb-2 redHeading">
                  Rénover l’image du transport routier aux yeux des français
                </p>
                <p className="smaller-p text-justify">
                  Notre profession souffre d’une vision négative et erronée
                  alors qu’elle présente des avantages objectifs. Le recrutement
                  en est impacté et les besoins ne sont pas comblés. Mais ce
                  n’est pas une fatalité. Ensemble, nous pouvons inverser cette
                  tendance en communiquant positivement sur notre secteur :
                  faisons naître des vocations !
                </p>
                <p className="smaller-p text-justify mb-0">
                  Réaliser une campagne de communication nationale nécessite
                  l’implication de chacun. Comment ? En réunissant l’ensemble de
                  la profession sous la bannière du{' '}
                  <u>Monde du Transport Réuni</u> et en récoltant les fonds
                  nécessaires à ce projet collectif inédit.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mt-lg-6">
              <Col lg={6} className="order-1 mt-3 mt-lg-0 order-lg-0">
                <p className="mb-2 redHeading">Se réunir autour d'une charte</p>
                <p className="smaller-p text-justify mb-0">
                  Afin de transformer en profondeur et sur le long terme l'image
                  du secteur, les membres de l'association se réunissent autour
                  de la charte <u>Le Monde du Transport Réuni</u>. Chaque partie
                  prenante s'engage sur des principes favorisant un travail
                  interne afin de rendre les entreprises du secteur plus
                  attrayantes et militer pour une image positive des métiers.{' '}
                  <a
                    href="https://lemondedutransportreuni.s2.membogo.com/fr/la-charte-lmtr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    La charte pour les entreprises de transport routier
                  </a>
                </p>
              </Col>
              <Col lg={6} className="order-0 order-lg-1">
                <iframe
                  src="//www.youtube.com/embed/hgIrySk7BG8"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="video"
                  style={{ width: '100%', height: '250px' }}
                ></iframe>
              </Col>
            </Row>
            <Row className="align-items-center mt-3 mt-lg-6">
              <Col lg={6}>
                <img className="mw-100" src={illu2} />
              </Col>
              <Col lg={6} className="mt-3 mt-lg-0">
                <p className="mb-2 redHeading">Qui sommes-nous ?</p>
                <p className="smaller-p text-justify mb-0">
                  Afin de porter ce projet, l’association{' '}
                  <u>Le Monde du Transport Réuni</u> a été créée pour regrouper,
                  sur un terrain neutre, l’ensemble des acteurs de la
                  profession. Entreprises de transport, salariés, organismes de
                  formations, organisations professionnelles...{' '}
                  <a
                    href="https://www.lemondedutransportreuni.fr/fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rejoignez-nous, nous sommes déja près de 350 !
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="align-items-center mt-3 mt-lg-6">
              <Col lg={6} className="order-1 order-lg-0 mt-3 mt-lg-0">
                <p className="mb-2 redHeading">Adhésion</p>
                <p className="smaller-p text-justify">
                  L'adhésion comprend une cotisation fixe ainsi qu'une
                  contribution obligatoire permettant de financer le projet de
                  communication. Cette contribution est calculée en fonction de
                  la masse salariale de l'entreprise.
                </p>
                <p className="smaller-p text-justify">
                  <b>L'adhésion est annuelle</b>. Elle est renouvelée chaque
                  année, à la fin du mois anniversaire de l'inscription.
                </p>
                <p className="smaller-p text-justify">
                  - <b>Cotisation: 100 € / Entreprise ou Groupe</b>
                  <br />- <b>Contribution: 1€ / mois / salarié </b> (plafonnée à
                  18 000 €, soit 1 500 salariés). Pour les Groupes, prendre en
                  compte l'ensemble des salariés. Un justificatif du nombre de
                  salarié sera demandé.
                </p>
                <p className="smaller-p text-justify mb-0">
                  <a
                    href="https://www.lemondedutransportreuni.fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Accéder au site web
                  </a>
                </p>
              </Col>
              <Col lg={6} className="order-0 order-lg-1 text-center">
                <img style={{ width: '100%', maxWidth: '350px' }} src={illu3} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default LMTR;
