import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const EncartEntreprise = ({ company }) => {
  const navigate = useNavigate();
  return (
    <>
      {company && (
        <Row>
          {typeof company.logo_url === 'string' && company.logo_url.trim() && (
            <Col xs={12}>
              <img className="company-logo" src={company.logo_url} />
            </Col>
          )}
          <Col xs={12}>
            <p className="mt-2 mb-1 redHeading">{company.name}</p>
            <p className="smaller-p mb-0">{company.address}</p>
            <p className="smaller-p mb-0">
              {company.zip_code} {company.city}
            </p>
            {typeof company.contact_phone === 'string' &&
              company.contact_phone.trim() && (
                <p className="smaller-p mb-0">Tél. : {company.contact_phone}</p>
              )}
          </Col>
          <Col xs={12} className="mt-3">
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                navigate(
                  `/adherents/${company.id}?back=${window.location.pathname}`
                );
              }}
            >
              Voir la fiche de la société
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

EncartEntreprise.propTypes = {
  company: PropTypes.object
};

export default EncartEntreprise;
