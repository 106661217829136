import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'recipient-templates';

export async function IndexRecipientTemplate() {
  return modelService.IndexModel(`${model}`);
}

export async function PostRecipientTemplate(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutRecipientTemplate(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteRecipientTemplate(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function ManageUserToTemplate(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}
