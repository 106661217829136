import React, { useState } from 'react';
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import FormAlert from '../../commons/FormAlert';
import PropTypes from 'prop-types';
import { PostEvaluation } from '../../../../services/Elearning/EvaluationService';
import { toast } from 'react-toastify';
import { PostAttestations } from '../../../../services/Elearning/AttestationService';
import { elearningEvaluationQuestionGroups } from '../../config';

const ElearningEvaluation = ({ course, scrollStepTitle }) => {
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    grade_informations_quality: '',
    grade_informations_quality_comment: '',
    had_technical_difficulties: '',
    technical_difficulties_comment: '',
    duration: '',
    video_number_grade: '',
    video_number_comment: '',
    format_grade: '',
    format_comment_satisfied: '',
    format_comment_not_satisfied: '',
    utility_grade: '',
    utility_comment: '',
    elements_grade: '',
    elements_comment: '',
    explanations_grade: '',
    explanations_comment: '',
    had_used_chat: '',
    chat_grade: '',
    chat_grade_comment: '',
    course_grade: '',
    course_comment: '',
    course_id: course.id
  });
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await PostEvaluation(formData);
    if (response.success === true) {
      const responseAttestation = await PostAttestations({
        course_id: course.id
      });
      if (responseAttestation.success === true) {
        window.location.href = '/espace-membre/e-learning-congratulation';
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else if (response.validator) {
      setErrorForm(response.validator);
      scrollStepTitle();
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const questionGroups = elearningEvaluationQuestionGroups;
  const renderQuestion = question => {
    let elements = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    switch (question.type) {
      case 'integer':
        return (
          <>
            <div className="d-block">
              {elements.map((element, index) => (
                <Form.Check
                  key={index}
                  inline
                  type="radio"
                  id={`${element}-${question.name}`}
                  value={element}
                  label={element}
                  name={question.name}
                  onChange={handleFieldChange}
                />
              ))}
            </div>
            {question.hasComment !== null &&
              question.hasComment !== undefined &&
              formData[question.name] !== '' &&
              parseInt(formData[question.name]) < 7 && (
                <Form.Group className="mt-2">
                  <Form.Label>
                    Commentaire{' '}
                    <small>
                      <b>obligatoire</b>
                    </small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name={question.hasComment}
                    rows={3}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              )}
            {question.hasPositiveComment !== null &&
              question.hasPositiveComment !== undefined &&
              formData[question.name] !== '' &&
              parseInt(formData[question.name]) >= 7 && (
                <Form.Group className="mt-2">
                  <Form.Label>
                    {question.hasPositiveCommentLabel}{' '}
                    <small>
                      <b>obligatoire</b>
                    </small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name={question.hasPositiveComment}
                    rows={3}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              )}
          </>
        );
      case 'yesNo':
        return (
          <>
            <div className="d-block">
              <Form.Check
                inline
                type="radio"
                id={`oui-${question.name}`}
                value="Oui"
                label="Oui"
                name={question.name}
                onChange={handleFieldChange}
              />
              <Form.Check
                inline
                type="radio"
                id={`non-${question.name}`}
                value="Non"
                label="Non"
                name={question.name}
                onChange={handleFieldChange}
              />
            </div>
            {question.hasYesComment !== null &&
              question.hasYesComment !== undefined &&
              formData[question.name] === 'Oui' && (
                <Form.Group className="mt-2">
                  <Form.Label>
                    Si oui, précisez{' '}
                    <small>
                      <b>obligatoire</b>
                    </small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name={question.hasYesComment}
                    rows={3}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
              )}
            {question.hasYesQuestion !== null &&
              question.hasYesQuestion !== undefined &&
              formData[question.name] === 'Oui' && (
                <>
                  <div className="d-block">
                    <Form.Label className="d-block">
                      {question.hasYesQuestion.label}{' '}
                      <small>
                        <b>obligatoire</b>
                      </small>
                    </Form.Label>
                    {elements.map((element, index) => (
                      <Form.Check
                        key={index}
                        inline
                        type="radio"
                        id={`${element}-${question.hasYesQuestion.name}`}
                        value={element}
                        label={element}
                        name={question.hasYesQuestion.name}
                        onChange={handleFieldChange}
                      />
                    ))}
                  </div>
                  {question.hasYesQuestion.hasComment !== null &&
                    question.hasYesQuestion.hasComment !== undefined &&
                    formData[question.hasYesQuestion.name] !== '' &&
                    parseInt(formData[question.hasYesQuestion.name]) < 7 && (
                      <Form.Group className="mt-2">
                        <Form.Label>
                          Commentaire{' '}
                          <small>
                            <b>obligatoire</b>
                          </small>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name={question.hasYesQuestion.hasComment}
                          rows={3}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    )}
                </>
              )}
          </>
        );
      case 'radio':
        return (
          <div className="d-block">
            {question.enum.map((element, index) => (
              <Form.Check
                key={index}
                inline
                type="radio"
                id={`${element}-${question.name}`}
                value={element}
                label={element}
                name={question.name}
                onChange={handleFieldChange}
              />
            ))}
          </div>
        );
      case 'string':
        return (
          <Form.Group className="mt-2">
            <Form.Control
              as="textarea"
              name={question.name}
              rows={3}
              onChange={handleFieldChange}
            />
          </Form.Group>
        );
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      {errorForm && <FormAlert variant="danger" data={errorForm} />}
      <Row>
        {questionGroups.map((group, index) => (
          <Col xs={12} key={index} className="training evaluation mb-4">
            <Card>
              <Card.Body className="px-3">
                <Card.Title as="h5" className="mb-0">
                  {group.groupLabel} :
                </Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush px-3 mb-3">
                {group.questions.map((question, indexQuestion) => (
                  <div key={indexQuestion} className="d-block mb-3">
                    <Form.Label className="d-block mb-2">
                      {question.label}
                    </Form.Label>
                    {renderQuestion(question)}
                  </div>
                ))}
              </ListGroup>
            </Card>
          </Col>
        ))}
        <Col xs={12} className="text-end mb-4 mb-lg-0">
          <Button disabled={isLoading} color="primary" size="sm" type="submit">
            Envoyer et accéder à mon certificat
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="17"
              height="17"
              style={{ marginBottom: '2px' }}
            >
              <path
                d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

ElearningEvaluation.propTypes = {
  course: PropTypes.object,
  scrollStepTitle: PropTypes.func,
  setIsCongratulationStep: PropTypes.func
};

export default ElearningEvaluation;
