import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportBilanPedagogiqueEvent = ({ logs }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    logs.map(obj => {
      let cleanedCode = {
        company: obj.company,
        user: obj.user,
        course: obj.course,
        start: obj.start
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [logs]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Journal">
              <ExcelColumn label="Entreprise" value="company" />
              <ExcelColumn label="Utilisateur" value="user" />
              <ExcelColumn label="Formation" value="course" />
              <ExcelColumn label="Date" value="start" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportBilanPedagogiqueEvent.propTypes = {
  logs: PropTypes.array
};

export default ExportBilanPedagogiqueEvent;
