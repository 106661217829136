import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Offcanvas
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import {
  DeleteCompanyExport,
  IndexCompanyExport,
  PostCompanyExport,
  PutCompanyExport
} from '../../../../../../services/CompanyExportService';
import FormAlert from '../../../../commons/FormAlert';
import Select from 'react-select';
import { IndexRoles } from '../../../../../../services/RoleService';
import SoftBadge from '../../../../../common/SoftBadge';
import ExportCompanies from '../../../../../../exports/companiesExports';
import {
  DeleteEnvironmentalPolicy,
  IndexEnvironmentalPolicy,
  PostEnvironmentalPolicy,
  PutEnvironmentalPolicy
} from '../../../../../../services/EnvironmentalPolicyService';
import {
  DeleteRepresentativeSectionType,
  IndexRepresentativeSectionType,
  PostRepresentativeSectionType,
  PutRepresentativeSectionType
} from '../../../../../../services/RepresentativeSectionTypeService';

const IndexRepresentativeSectionTypes = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [submitLoading, setSubmitIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    is_public: 0
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitIsLoading(true);
    setErrorForm(false);
    let response = false;
    if (selectedObject.id) {
      response = await PutRepresentativeSectionType(
        selectedObject.id,
        formData
      );
    } else {
      response = await PostRepresentativeSectionType(formData);
    }
    setErrorForm(response.validator);
    setSubmitIsLoading(false);
    if (response.success === true) {
      setShowManage(false);
      setFormData({
        name: ''
      });
      const successMessage = `Section enregistrée.`;
      toast(successMessage);
      setTrigger(Date.now());
    }
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        name: obj.name,
        is_public: obj.is_public === 1 ? 'Oui' : '',
        created_at: Moment(obj.created_at).format('DD/MM/YYYY'),
        actions: (
          <Col className="px-0">
            <Button
              size="sm"
              variant="primary"
              className="me-2 admin-primary"
              type="button"
              onClick={() => {
                setShowManage(true);
                setSelectedObject(obj);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px',
                  marginRight: '3px'
                }}
              >
                <path
                  d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
          </Col>
        )
      });
    });
    setInitialObjects(transformedApiObjects);
    setInitialApiObjects(transformedApiObjects);
  };

  const deleteObject = async () => {
    const response = await DeleteRepresentativeSectionType(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Section supprimée');
      setSelectedObject({});
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexRepresentativeSectionType();
      if (responseObjects.success === true) {
        setInitialObjects(responseObjects.data);
        setInitialApiObjects(responseObjects.data);
        setObjects(responseObjects.data);
        setIsLoading(false);
        applySearch(searchInput, responseObjects.data);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const applySearch = (value, objects) => {
    if (value.length > 2) {
      let searchObjects = [];
      searchObjects = objects.filter(
        object =>
          object.titleToString
            .toLowerCase()
            .replace('.', '')
            .indexOf(searchInput.toLowerCase().replace('.', '')) > -1
      );
      setInitialApiObjects(searchObjects);
    } else {
      setInitialApiObjects(objects);
    }
  };

  return (
    <>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id !== undefined && (
              <>
                L'archivage de la section "{selectedObject.name}" est définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des fonctions{' '}
                <small>présentées sur les fiches sociétés</small>
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  window.location.href = '/administration/entreprises';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Annuler
              </Button>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                onClick={() => {
                  setShowManage(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Créer une section
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      <Card className="mt-3">
        <Card.Body className="">
          <Row className="justify-content-between align-items-center px-3">
            <Col xs={12} className="mb-0 px-0">
              <Form.Control
                type="search"
                className="search-input"
                placeholder="Rechercher quelque chose..."
                aria-label="Search"
                value={searchInput}
                size="sm"
                onChange={e => {
                  setSearchInput(e.target.value);
                  applySearch(e.target.value, initialObjects);
                }}
              />
            </Col>
          </Row>
          <Row>
            {initialApiObjects && initialApiObjects.length > 0 && (
              <Col xs={12} className="mt-3">
                <Table data={initialApiObjects} />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Offcanvas
        onShow={() => {
          if (selectedObject.id) {
            let obj = objects.filter(
              o => parseInt(o.id) === parseInt(selectedObject.id)
            )[0];
            if (obj) {
              setFormData({
                name: obj.name,
                is_public: obj.is_public
              });
            }
          }
        }}
        show={showManage}
        onHide={() => {
          setShowManage(false);
          setSelectedObject({});
          setFormData({
            name: '',
            is_public: 0
          });
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id ? 'Modifier' : 'Ajouter'} une section
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="gx-2 gy-3 mb-3">
            <Col xs={12}>
              {errorForm && (
                <div className="mt-0">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
            </Col>
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Nom de la section :</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder=""
                  value={formData.name || ''}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Données publiques ?</Form.Label>
                <Select
                  options={[
                    { value: 1, label: 'Oui' },
                    { value: 0, label: 'Non' }
                  ]}
                  value={
                    formData.is_public
                      ? {
                          value: formData.is_public,
                          label: formData.is_public === 1 ? 'Oui' : 'Non'
                        }
                      : {
                          value: 0,
                          label: 'Non'
                        }
                  }
                  onChange={e => {
                    setFormData({
                      ...formData,
                      is_public: e.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowManage(false);
              setFormData({
                name: '',
                is_public: 0
              });
            }}
          >
            Annuler
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={submitLoading}
            onClick={e => {
              handleSubmit(e);
            }}
            className="me-2 admin-primary"
          >
            Enregistrer
          </Button>
          {selectedObject.id && (
            <Button
              size="sm"
              variant="danger"
              className="me-0 float-end"
              type="button"
              onClick={() => {
                setShowDelete(true);
                setSelectedObject(selectedObject);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="14"
                height="14"
                style={{
                  marginBottom: '2px'
                }}
              >
                <path
                  d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Button>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Nom'
      },
      {
        accessor: 'is_public',
        Header: 'Données publiques ?'
      },
      {
        accessor: 'created_at',
        Header: 'Date de création',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'actions',
        Header: 'Action'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default IndexRepresentativeSectionTypes;
