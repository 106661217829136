import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'link-recipients';

export async function IndexLinks(surveyId) {
  return modelService.IndexModel(`${model}?survey=${surveyId}`);
}

export async function ShowLink(uuid) {
  return modelService.ShowModel(`${model}/${uuid}`);
}

export async function ShowLinkAdmin(uuid) {
  return modelService.ShowModel(`${model}/admin/${uuid}`);
}

export async function SubmitLink(uuid, formData) {
  return modelService.PostModel(`${model}/${uuid}`, formData);
}

export async function PostLink(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutLink(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteLink(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
