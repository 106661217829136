import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LoginUserService } from '../../../services/AuthService';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
//import { useNavigate } from 'react-router-dom';
import logoTredUnion from 'assets/img/tredunion/logo-TRED-UNION.png';
import FormAlert from '../commons/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import NavbarStandard from '../commons/NavbarStandard';
import FooterStandard from '../commons/FooterStandard';
import bgLogin from '../../../assets/img/tredunion/bg_login.jpeg';
import Section from '../../common/Section';
//import RedirectIfAuthenticated from '../../../middlewares/RedirectIfAuthenticated';

const LoginTred = () => <LoginForm />;

const LoginForm = ({ hasLabel }) => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    login: searchParams.get('login') ? searchParams.get('login') : '',
    password: '',
    'g-recaptcha-response': false
  });

  useEffect(() => {
    //RedirectIfAuthenticated(navigate);
  }, []);

  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await executeRecaptcha('check_login_form_tred');
        let formDataSubmit = {
          ...formData,
          'g-recaptcha-response': token
        };
        const response = await LoginUserService(formDataSubmit);
        setIsLoading(false);
        setErrorForm(response.validator);
        if (
          localStorage.getItem('accessToken') &&
          localStorage.getItem('user')
        ) {
          let user = JSON.parse(localStorage.getItem('user'));
          setTimeout(function () {
            if (user.partner_id !== undefined && user.partner_id !== null) {
              navigate('/espace-partenaire');
            } else {
              navigate('/espace-membre/accueil');
            }
          }, 1500);
        } else if (!response.validator) {
          setErrorForm(response.message);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha
          onVerify={() => {
            return;
          }}
        />
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.login || !formData.password || isLoading}
          onClick={handleSubmit}
        >
          Me connecter
        </Button>
      </div>
    );
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex p-0 mt-0"
        style={{ backgroundImage: `url("${bgLogin}")` }}
      >
        <Row className="flex-center min-vh-100 py-0 mt-lg-0 mt-xl-4">
          <Col xs={12} lg={7}>
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <p className="text-center d-none d-lg-block">
                  <img
                    src={logoTredUnion}
                    style={{ width: '100%', maxWidth: '175px' }}
                  />
                </p>
                <p className="redHeading text-center">
                  Connexion à mon espace sécurisé
                </p>
                <Form>
                  {errorForm && <FormAlert variant="danger" data={errorForm} />}

                  <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Identifiant</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? 'Identifiant' : ''}
                      value={formData.login}
                      name="login"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Mot de passe</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? 'Mot de passe' : ''}
                      value={formData.password}
                      name="password"
                      onChange={handleFieldChange}
                      type="password"
                    />
                  </Form.Group>

                  <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                      <Link
                        className="fs--1 mb-0"
                        to={`/forgot-password?login=${formData.login}`}
                      >
                        Mot de passe oublié ?
                      </Link>
                    </Col>
                  </Row>

                  <Form.Group>
                    <GoogleReCaptchaProvider
                      reCaptchaKey="6LePlFwgAAAAAIga3vbKCRndalE_-0ki3eFiw-n3"
                      language="fr-FR"
                    >
                      <ReComponent />
                    </GoogleReCaptchaProvider>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>

      <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
        <FooterStandard />
      </div>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginTred;
