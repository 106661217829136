import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import NavbarStandard from '../../commons/NavbarStandard';
import Section from '../../../common/Section';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import * as Moment from 'moment';
import moment from 'moment/moment';
import IconAlert from '../../../common/IconAlert';

import {
  ShowAdminSurvey,
  ShowPublicSurvey,
  SubmitSurvey,
  SubmitSurveyAdmin,
  ValidateSurvey
} from '../../../../services/Survey/SurveyService';
import FormAlert from '../../commons/FormAlert';
import Vimeo from '@u-wave/react-vimeo';
import QuestionsContainer from './QuestionsContainer';
import PrintSurvey from './exportPDF';
import { useLocation } from 'react-router-dom';
import {
  SubmitLink,
  SubmitLinkOnlyForValidation
} from '../../../../services/Survey/LinkService';
import useStateWithCallback from 'use-state-with-callback';
import Select from 'react-select';
import {
  IndexAdmin,
  IndexCompany,
  IndexHome,
  IndexWithChlidrensRecursive
} from '../../../../services/CompanyService';
import {
  IndexPartner,
  IndexPartnerAdmin
} from '../../../../services/PartnerService';

const SurveyForm = () => {
  const [event, setEvent] = useState({});
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);
  const [survey, setSurvey] = useState({});
  const [expiredDeadline, setExpiredDeadline] = useState(false);
  const [hideOtherQuestions, setHideOtherQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionGroups, setQuestionGroups] = useState([]);
  const { uuid } = useParams();
  const [errorForm, setErrorForm] = useState(false);
  const [isValidated, setIsValidated] = useState(0);
  const [successForm, setSuccessForm] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [full, setFull] = useState(false);
  const [invitationLink, setInvitationLink] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [linkData, setLinkData] = useState({});
  const [isSubmitedLink, setIsSubmitedLink] = useState(false);
  const [linkGeneratedRecipientUuid, setLinkGeneratedRecipientUuid] =
    useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const linkValue = queryParams.get('link');

  useEffect(() => {
    const fetchEvent = async () => {
      let responseObjects = null;
      if (localStorage.getItem('accessToken') && localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.is_admin === 1) {
          setUserIsAdmin(true);
          responseObjects = await ShowAdminSurvey(uuid, linkValue);
        } else {
          responseObjects = await ShowPublicSurvey(uuid, linkValue);
        }
      } else {
        responseObjects = await ShowPublicSurvey(uuid, linkValue);
      }
      if (responseObjects.success === true) {
        setCompanies(responseObjects.data.companies);
        let survey = null;
        if (responseObjects.data.invitationLink) {
          let link = responseObjects.data.invitationLink;
          setInvitationLink(link);
          setLinkData({
            ...linkData,
            company_name: link.default_company
          });
          const fetchCompanies = async () => {
            const responseCompanies = await IndexWithChlidrensRecursive();
            if (responseCompanies.success === true) {
              let companiesArray = [];
              responseCompanies.data.map(company => {
                companiesArray.push({
                  value: `company_${company.id}`,
                  label: company.name
                });
                company.childrens.map(children => {
                  companiesArray.push({
                    value: `company_${children.id}`,
                    label: `-- ${children.name}`
                  });
                });
              });
              const fetchPartners = async () => {
                const responsePartners = await IndexPartner();
                if (responsePartners.success === true) {
                  responsePartners.data.map(partner => {
                    companiesArray.push({
                      value: `partner_${partner.id}`,
                      label: partner.name
                    });
                  });
                  if (
                    link.show_companies_list === 0 ||
                    link.show_companies_list === '0'
                  ) {
                    companiesArray = companiesArray.filter(company => {
                      // filter if value contains company_ :
                      return !company.value.includes('company_');
                    });
                  }
                  if (
                    link.show_partners_list === 0 ||
                    link.show_partners_list === '0'
                  ) {
                    companiesArray = companiesArray.filter(company => {
                      return !company.value.includes('partner_');
                    });
                  }
                  setCompaniesSelect(companiesArray);
                } else {
                  toast(
                    'Une erreur est survenue, veuillez réessayer plus tard'
                  );
                }
              };
              fetchPartners();
            } else {
              toast('Une erreur est survenue, veuillez réessayer plus tard');
            }
          };
          fetchCompanies();
        }
        if (responseObjects.data.participation_form) {
          // cas event
          setEvent(responseObjects.data);
          if (responseObjects.data?.checkFull === true) {
            //setHideOtherQuestions(true);
          }
          setSurvey(responseObjects.data.participation_form);
          survey = responseObjects.data.participation_form;
        } else {
          // cas survey
          setSurvey(responseObjects.data);
          survey = responseObjects.data;
        }
        if (survey && survey.questions && survey.questions.length > 0) {
          let tmpQuestions = [...survey.questions];
          tmpQuestions = tmpQuestions.filter(q => !q.group_id);
          tmpQuestions.map((q, index) => {
            if (q.is_participation_form_main_question === 1) {
              if (q.value) {
                tmpQuestions[index].value = '';
              } else {
                tmpQuestions[index].value = 'Oui';
              }
            } else {
              if (
                responseObjects.data.companies &&
                responseObjects.data.companies.length > 0
              ) {
                responseObjects.data.companies.map(company => {
                  if (
                    q.type &&
                    q.type.slug === 'datetime' &&
                    q[`value_${company.id}`]
                  ) {
                    tmpQuestions[index][`value_${company.id}`] = new Date(
                      q[`value_${company.id}`]
                    );
                  } else {
                    tmpQuestions[index][`value_${company.id}`] = tmpQuestions[
                      index
                    ][`value_${company.id}`] = q[`value_${company.id}`] || '';
                  }
                });
              } else {
                if (q.type && q.type.slug === 'datetime' && q.value) {
                  tmpQuestions[index].value = new Date(q.value);
                } else {
                  tmpQuestions[index].value = q.value || '';
                }
              }
            }
          });
          setQuestions(tmpQuestions);
          if (survey.question_groups && survey.question_groups.length > 0) {
            let tmpGroups = [];
            survey.question_groups.map(group => {
              let questions = [];
              [...survey.questions]
                .filter(q => q.group_id && q.group_id === group.id)
                .map(q => {
                  if (q.type && q.type.slug === 'datetime' && q.value) {
                    q.value = new Date(q.value);
                  } else {
                    q.value = q.value || '';
                  }
                  questions.push(q);
                });
              tmpGroups.push({
                title: group.title,
                description: group.description,
                color: group.color,
                questions: questions
              });
            });
            setQuestionGroups(tmpGroups);
          }
        }
        setIsLoading(false);
      } else if (
        responseObjects.message &&
        responseObjects.message === 'Already completed'
      ) {
        setAlreadyCompleted(true);
        setIsLoading(false);
      } else if (
        responseObjects.message &&
        responseObjects.message === 'Expired deadline'
      ) {
        setExpiredDeadline(true);
        setIsLoading(false);
      } else if (
        responseObjects.message === 'Model not found' ||
        responseObjects.message === 'Object not found'
      ) {
        setNotFound(true);
        setIsLoading(false);
      } else if (
        responseObjects?.message === 'Max number of participants reached'
      ) {
        setFull(true);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (questions.length < 1) {
      fetchEvent();
    }
  }, []);

  function linkify(inputText) {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(
      replacePattern1,
      '<a href="$1" target="_blank">$1</a>'
    );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank">$2</a>'
    );

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(
      replacePattern3,
      '<a href="mailto:$1">$1</a>'
    );

    return replacedText;
  }

  const handleSubmitLink = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await SubmitLink(invitationLink.uuid, {
      ...linkData,
      onlyForValidation: true
    });
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    if (response.success === true) {
      handleSubmit();
    } else {
      setIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    setErrorForm(false);
    let response = null;
    let formData = {
      questions: questions,
      questionGroups: questionGroups,
      companies: companies,
      checkEventFull: event?.checkFull && !hideOtherQuestions
    };
    response = await ValidateSurvey(formData);
    setErrorForm(response.validator);
    if (response.success === true) {
      let uuidParam = uuid;
      if (invitationLink?.uuid) {
        const response = await SubmitLink(invitationLink.uuid, {
          ...linkData,
          checkEventFull: event?.checkFull && !hideOtherQuestions
        });
        uuidParam = response.data;
      }
      if (userIsAdmin) {
        response = await SubmitSurveyAdmin(uuidParam, formData);
      } else {
        response = await SubmitSurvey(uuidParam, formData);
      }
      setErrorForm(response.validator);
      if (response.success === true) {
        setSuccessForm(true);
        setTimeout(function () {
          document.getElementById('success-form').scrollIntoView();
        }, 100);
        setIsLoading(false);
      } else if (response.validator) {
        setErrorForm(response.validator);
        setTimeout(function () {
          document.getElementById('errorform').scrollIntoView();
        }, 100);
        setIsLoading(false);
      } else if (response.message) {
        toast(response.message);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{
          backgroundImage: `url("${bgActualites}")`
        }}
      >
        <Row>
          <Col xs={12}>
            <h2 style={{ lineHeight: '45px' }}>
              {invitationLink?.label
                ? `${invitationLink.label} ${
                    invitationLink.default_company
                      ? `- ${invitationLink.default_company}`
                      : ''
                  }`
                : event.id
                ? 'Formulaire de participation'
                : survey.survey_type &&
                  survey.survey_type.name === 'purchase-declaration'
                ? 'Déclaration achats'
                : 'Questionnaire'}
            </h2>
          </Col>
        </Row>
      </Section>
      {isLoading === true ? (
        <Section bg="light">
          <Row>
            <Col xs={12} className="mt-0">
              <Spinner animation="border" role="status" size="xs">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      ) : (
        <>
          <Section bg="light" className="d-flex align-items-end p-1 mt-0">
            {expiredDeadline === true ? (
              <Row>
                <Col xs={12} className="mt-3">
                  <IconAlert variant="danger" className="smaller-p">
                    La date limite de réponse au formulaire est dépassée. Pour
                    toute question, vous pouvez contacter{' '}
                    <a href="/#contact">Tred Union</a>.
                  </IconAlert>
                </Col>
              </Row>
            ) : alreadyCompleted === true ? (
              <Row>
                <Col xs={12} className="mt-3">
                  <IconAlert variant="info" className="smaller-p">
                    Vos réponses ont déjà été enregistrées. Vous pouvez fermer
                    cette page.
                  </IconAlert>
                </Col>
              </Row>
            ) : notFound === true ? (
              <Row>
                <Col xs={12} className="mt-3">
                  <IconAlert variant="danger" className="smaller-p">
                    Le lien n'existe pas ou a expiré. Pour toute question, vous
                    pouvez contacter <a href="/#contact">Tred Union</a>.
                  </IconAlert>
                </Col>
              </Row>
            ) : full === true ? (
              <Row>
                <Col xs={12} className="mt-3">
                  <IconAlert variant="danger" className="smaller-p">
                    Les inscriptions sont complètes. Pour toute question, vous
                    pouvez contacter <a href="/#contact">Tred Union</a>.
                  </IconAlert>
                </Col>
              </Row>
            ) : (
              <Row className="mt-lg-4 mb-5">
                {errorForm && (
                  <div className="mt-3" id="errorform">
                    <FormAlert variant="danger" data={errorForm} />
                  </div>
                )}
                <Col xs={12}>
                  <Row className="align-items-center">
                    {userIsAdmin && (
                      <>
                        <Col xs={6} className="mb-3 mt-0">
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-2 d-block mt-3"
                            onClick={() => {
                              survey &&
                              survey.survey_type &&
                              survey.survey_type.name === 'purchase-declaration'
                                ? navigate(
                                    `/administration/declarations-achats/${survey.id}#recipients-table`
                                  )
                                : navigate(
                                    `/administration/${
                                      event.id ? 'evenements' : 'questionnaires'
                                    }/${
                                      event.id ? event.id : survey.id
                                    }#recipients-table`
                                  );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              style={{ marginBottom: '2px' }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                            </svg>
                            Retour à l'administration
                          </Button>
                        </Col>
                        {!successForm && (
                          <Col xs={6} className="mb-3 mt-3 text-end">
                            <PrintSurvey
                              isLoading={isLoading}
                              hideOtherQuestions={hideOtherQuestions}
                              setHideOtherQuestions={setHideOtherQuestions}
                              questions={questions}
                              setQuestionGroups={setQuestionGroups}
                              setQuestions={setQuestions}
                              questionGroups={questionGroups}
                              event={event}
                              survey={survey}
                            />
                          </Col>
                        )}
                      </>
                    )}
                    <Col lg={12}>
                      <Card>
                        <Card.Body>
                          <Row>
                            <Col xs={12}>
                              <p className={!event.id ? 'mb-0' : ''}>
                                <small>
                                  <i>
                                    Vous avez été invité(e) à{' '}
                                    {event.id
                                      ? "l'événement"
                                      : 'répondre au questionnaire'}{' '}
                                    :
                                  </i>
                                  <br />
                                </small>
                                <b>{event.id ? event.title : survey.title}</b>
                              </p>
                            </Col>
                            {event.id && (
                              <>
                                <Col xs={12}>
                                  <p className="smaller-p mb-0">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                      style={{
                                        position: 'relative',
                                        bottom: '2px',
                                        paddingRight: '3px'
                                      }}
                                    >
                                      <path fill="none" d="M0 0h24v24H0z" />
                                      <path
                                        d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                                        fill="rgba(100,99,99,1)"
                                      />
                                    </svg>
                                    <b>Date :</b>{' '}
                                    {event.start_is_all_day
                                      ? `du
            ${Moment(event.start_date).local().format('DD/MM/YYYY')}${
                                          event.start_time
                                            ? ' à ' +
                                              moment(
                                                event.start_time,
                                                'HH:mm:ss'
                                              ).format('HH:mm')
                                            : ''
                                        } au
            ${Moment(event.end_date).local().format('DD/MM/YYYY')}${
                                          event.end_time
                                            ? ' à ' +
                                              moment(
                                                event.end_time,
                                                'HH:mm:ss'
                                              ).format('HH:mm')
                                            : ''
                                        }`
                                      : `le ${Moment(event.start_date)
                                          .local()
                                          .format('DD/MM/YYYY')} de ${moment(
                                          event.start_time,
                                          'HH:mm:ss'
                                        ).format('HH:mm')} à ${moment(
                                          event.end_time,
                                          'HH:mm:ss'
                                        ).format('HH:mm')}`}
                                  </p>
                                </Col>
                                {event.location_label && (
                                  <Col xs={12} className="mt-2">
                                    <p className="smaller-p mb-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        style={{
                                          position: 'relative',
                                          bottom: '2px',
                                          paddingRight: '3px'
                                        }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
                                          fill="rgba(100,99,99,1)"
                                        />
                                      </svg>
                                      <b>Lieu : </b>
                                      {event.location_label}
                                    </p>
                                  </Col>
                                )}
                                {event.link && (
                                  <Col xs={12} className="mt-2">
                                    <p className="smaller-p mb-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        style={{
                                          position: 'relative',
                                          bottom: '2px',
                                          paddingRight: '3px'
                                        }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          d="M17 9.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4.2zm0 3.159l4 2.8V8.84l-4 2.8v.718zM3 6v12h12V6H3zm2 2h2v2H5V8z"
                                          fill="rgba(100,99,99,1)"
                                        />
                                      </svg>
                                      <b>Événement en ligne : </b>
                                      <a
                                        href={event.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {event.link}
                                      </a>
                                    </p>
                                  </Col>
                                )}
                                {event.attachments?.length > 0 && (
                                  <Col xs={12} className="mt-2">
                                    <p className="smaller-p mb-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        style={{
                                          position: 'relative',
                                          bottom: '2px',
                                          paddingRight: '3px'
                                        }}
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          d="M14.8287 7.7574L9.1718 13.4143C8.78127 13.8048 8.78127 14.4379 9.1718 14.8285C9.56232 15.219 10.1955 15.219 10.586 14.8285L16.2429 9.17161C17.4144 8.00004 17.4144 6.10055 16.2429 4.92897C15.0713 3.7574 13.1718 3.7574 12.0002 4.92897L6.34337 10.5858C4.39075 12.5384 4.39075 15.7043 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4143L14.8287 19.0711C12.095 21.8048 7.66283 21.8048 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17161L10.586 3.51476C12.5386 1.56214 15.7045 1.56214 17.6571 3.51476C19.6097 5.46738 19.6097 8.63321 17.6571 10.5858L12.0002 16.2427C10.8287 17.4143 8.92916 17.4143 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34319L14.8287 7.7574Z"
                                          fill="rgba(100,99,99,1)"
                                        />
                                      </svg>
                                      <b>
                                        <span
                                          className="text-danger"
                                          style={{ fontSize: 18 }}
                                        >
                                          Important !
                                        </span>{' '}
                                        Veuillez prendre connaissance des pièces
                                        jointes suivantes :{' '}
                                      </b>
                                      {event.attachments.map(
                                        (attachment, index) => (
                                          <span
                                            className="d-block mt-2"
                                            key={index}
                                          >
                                            -{' '}
                                            <a
                                              href={attachment.file_url}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {attachment.file_url.replace(
                                                /^.*[\\/]/,
                                                ''
                                              )}
                                            </a>
                                          </span>
                                        )
                                      )}
                                    </p>
                                  </Col>
                                )}
                              </>
                            )}
                            {survey && (
                              <>
                                {survey.description && (
                                  <Col xs={12} className="mt-2">
                                    <p
                                      className="smaller-p mb-0"
                                      dangerouslySetInnerHTML={{
                                        __html: linkify(
                                          survey.description
                                        ).replace(/\n/g, '<br />')
                                      }}
                                    ></p>
                                  </Col>
                                )}
                                {survey.vimeo_id && (
                                  <Col xs={12} className="mt-2">
                                    <Vimeo
                                      video={survey.vimeo_id}
                                      autoplay
                                      style={{ width: '100%' }}
                                      className="vimeo-video"
                                    />
                                  </Col>
                                )}
                                {survey.attachments &&
                                  survey.attachments.length > 0 && (
                                    <Col xs={12} className="mt-4">
                                      <p className="smaller-p mb-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                          style={{
                                            position: 'relative',
                                            bottom: '2px',
                                            paddingRight: '3px'
                                          }}
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            d="M14.8287 7.7574L9.1718 13.4143C8.78127 13.8048 8.78127 14.4379 9.1718 14.8285C9.56232 15.219 10.1955 15.219 10.586 14.8285L16.2429 9.17161C17.4144 8.00004 17.4144 6.10055 16.2429 4.92897C15.0713 3.7574 13.1718 3.7574 12.0002 4.92897L6.34337 10.5858C4.39075 12.5384 4.39075 15.7043 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4143L14.8287 19.0711C12.095 21.8048 7.66283 21.8048 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17161L10.586 3.51476C12.5386 1.56214 15.7045 1.56214 17.6571 3.51476C19.6097 5.46738 19.6097 8.63321 17.6571 10.5858L12.0002 16.2427C10.8287 17.4143 8.92916 17.4143 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34319L14.8287 7.7574Z"
                                            fill="rgba(100,99,99,1)"
                                          />
                                        </svg>
                                        <b>
                                          <span
                                            className="text-danger"
                                            style={{ fontSize: 18 }}
                                          >
                                            Important !
                                          </span>{' '}
                                          Veuillez prendre connaissance des
                                          pièces jointes suivantes :{' '}
                                        </b>
                                        {survey.attachments.map(
                                          (attachment, index) => (
                                            <span
                                              className="d-block mt-2"
                                              key={index}
                                            >
                                              -{' '}
                                              <a
                                                href={attachment.file_url}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {attachment.file_url.replace(
                                                  /^.*[\\/]/,
                                                  ''
                                                )}
                                              </a>
                                            </span>
                                          )
                                        )}
                                      </p>
                                    </Col>
                                  )}
                              </>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    {successForm ? (
                      <Col xs={12} className="mt-3" id="success-form">
                        <IconAlert variant="success" className="smaller-p">
                          Merci ! Vos réponses ont bien été enregistrées. Vous
                          pouvez fermer cette page.
                        </IconAlert>
                        {userIsAdmin && (
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="me-2 d-block mt-3"
                            onClick={() => {
                              survey &&
                              survey.survey_type &&
                              survey.survey_type.name === 'purchase-declaration'
                                ? navigate(
                                    `/administration/declarations-achats/${survey.id}#recipients-table`
                                  )
                                : navigate(
                                    `/administration/${
                                      event.id ? 'evenements' : 'questionnaires'
                                    }/${
                                      event.id ? event.id : survey.id
                                    }#recipients-table`
                                  );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              style={{ marginBottom: '2px' }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                            </svg>
                            Retour à l'administration
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <>
                        <Col xs={12} className="mt-3">
                          <IconAlert variant="warning" className="smaller-p">
                            Vous avez été invité(e) à compléter le formulaire
                            ci-dessous{' '}
                            <b>
                              <u>
                                avant le{' '}
                                {invitationLink?.expires_at
                                  ? Moment(invitationLink.expires_at)
                                      .local()
                                      .format('DD/MM/YYYY')
                                  : survey && survey.expires_on
                                  ? Moment(survey.expires_on)
                                      .local()
                                      .format('DD/MM/YYYY')
                                  : event.start_date
                                  ? Moment(event.start_date)
                                      .local()
                                      .format('DD/MM/YYYY')
                                  : ''}
                              </u>
                            </b>
                            .<br />
                            Vous pouvez compléter vos réponses et valider le
                            formulaire en cliquant sur{' '}
                            <b>Envoyer mes réponses</b>.
                          </IconAlert>
                        </Col>
                        {invitationLink?.uuid && (
                          <Col lg={12} className="mt-2 mb-4">
                            <Row>
                              <Col md="6">
                                <Form.Group controlId="name">
                                  <Form.Label>
                                    Nom <span className="text-danger">*</span> :
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastname"
                                    placeholder="Votre nom"
                                    value={linkData.lastname || ''}
                                    onChange={e => {
                                      setLinkData({
                                        ...linkData,
                                        lastname: e.target.value
                                      });
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="6" className="mt-4 mt-md-0">
                                <Form.Group controlId="name">
                                  <Form.Label>
                                    Prénom{' '}
                                    <span className="text-danger">*</span> :
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstname"
                                    placeholder="Votre prénom"
                                    value={linkData.firstname || ''}
                                    onChange={e => {
                                      setLinkData({
                                        ...linkData,
                                        firstname: e.target.value
                                      });
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12" className="mt-3">
                                <Form.Group controlId="name">
                                  <Form.Label>
                                    Email <span className="text-danger">*</span>{' '}
                                    :
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Votre adresse email"
                                    value={linkData.email || ''}
                                    onChange={e => {
                                      setLinkData({
                                        ...linkData,
                                        email: e.target.value
                                      });
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12" className="mt-3">
                                {!invitationLink.default_company ? (
                                  <Form.Group controlId="name">
                                    <Form.Label>
                                      Votre entreprise{' '}
                                      <span className="text-danger">*</span> :
                                    </Form.Label>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={companiesSelect}
                                      placeholder="Choisir une société / partenaire..."
                                      name="company_id"
                                      classNamePrefix="react-select"
                                      value={selectedCompany}
                                      onChange={object => {
                                        setLinkData({
                                          ...linkData,
                                          company_name: object.label.replace(
                                            /-- /g,
                                            ''
                                          )
                                        });
                                        setSelectedCompany(object);
                                      }}
                                    />
                                    <Form.Control
                                      type="text"
                                      name="company_name"
                                      className="d-none"
                                      disabled={
                                        !!invitationLink.default_company
                                      }
                                      placeholder="Votre entreprise"
                                      value={
                                        linkData.company_name ||
                                        invitationLink.default_company
                                      }
                                      onChange={e => {
                                        setLinkData({
                                          ...linkData,
                                          company_name: e.target.value
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                ) : (
                                  <Form.Group controlId="name">
                                    <Form.Label>
                                      Votre entreprise{' '}
                                      <span className="text-danger">*</span> :
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="company_name"
                                      disabled={
                                        !!invitationLink.default_company
                                      }
                                      placeholder="Votre entreprise"
                                      value={
                                        linkData.company_name ||
                                        invitationLink.default_company
                                      }
                                      onChange={e => {
                                        setLinkData({
                                          ...linkData,
                                          company_name: e.target.value
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <QuestionsContainer
                          isLoading={isLoading}
                          hideOtherQuestions={hideOtherQuestions}
                          setHideOtherQuestions={setHideOtherQuestions}
                          questions={questions}
                          setQuestionGroups={setQuestionGroups}
                          setQuestions={setQuestions}
                          questionGroups={questionGroups}
                          companies={companies}
                          event={event}
                        />
                        <Col xs={12} className="mt-0 mb-3">
                          <Card>
                            <Card.Body>
                              <label className="mb-0">
                                <input
                                  type="checkbox"
                                  id="is_admin"
                                  name="is_admin"
                                  value="1"
                                  className="me-1"
                                  checked={
                                    isValidated === 1 || isValidated === '1'
                                  }
                                  onChange={e => {
                                    setIsValidated(e.target.checked ? 1 : 0);
                                  }}
                                />
                                <span
                                  style={{
                                    position: 'relative',
                                    bottom: '1px',
                                    fontSize: '16px'
                                  }}
                                >
                                  <b className="text-warning">Obligatoire</b> je
                                  valide mes réponses{' '}
                                  {event?.checkFull && !hideOtherQuestions ? (
                                    <b>
                                      et j'ai compris que je vais être placé en
                                      liste d'attente
                                    </b>
                                  ) : (
                                    ''
                                  )}{' '}
                                  en cochant la case
                                </span>
                              </label>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={12} className="mt-1 mb-7">
                          <Button
                            size="sm"
                            variant="primary"
                            className="me-2 admin-primary"
                            type="submit"
                            disabled={isLoading || !isValidated}
                            onClick={e => {
                              if (!invitationLink?.uuid) {
                                handleSubmit(e);
                              } else {
                                handleSubmitLink(e);
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                              style={{
                                marginBottom: '2px',
                                marginRight: '4px'
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                            Envoyer mes réponses
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
          </Section>
        </>
      )}
    </>
  );
};

export default SurveyForm;
