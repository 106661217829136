import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { membersRoutes } from '../../../../routes/authRoutes';
import Flex from '../../../common/Flex';
import { Link } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import CalendarMember from './events/Calendar';
import MemberSide from '../../../../layouts/MemberSide';
import SoftBadge from '../../../common/SoftBadge';
import * as Moment from 'moment/moment';
import { IndexAdCategories } from '../../../../services/AdCategoryService';
import { IndexAdMembersExpire, PutAd } from '../../../../services/AdService';
import { DeleteAdPicture } from '../../../../services/AdPictureService';
import { toast } from 'react-toastify';

const HomeMembers = () => {
  const [user] = useOutletContext();
  function pluck(array, key) {
    return array.map(o => o[key]);
  }
  const [ads, setAds] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  useEffect(() => {
    const fetchAdsExpire = async () => {
      const response = await IndexAdMembersExpire();
      if (response.success) {
        setAds(response.data);
      }
    };
    fetchAdsExpire();
  }, [trigger]);
  const renewAd = async ad => {
    let response = await PutAd(ad.id, {
      context: 'renew'
    });
    if (response.success === true) {
      toast('Votre annonce a été renouvellée avec succès !');
      setTrigger(Date.now());
    }
  };
  return (
    <Row className="mt-2">
      {(user.main_company?.fill_request_completed_at === null ||
        user.secondary_companies?.find(
          c => c.fill_request_completed_at === null
        )) &&
      user.notifications?.find(r => r.title === 'Tred Contact') ? (
        <Col xs={12} lg={12}>
          <Alert variant="warning">
            <p className="mb-3">
              <b>Important !</b> Veuillez mettre à jour vos informations
              d'entreprise rapidement en cliquant sur le lien ci-dessous :<br />{' '}
            </p>
            {user.main_company?.fill_request_completed_at === null ? (
              <ul style={{ marginBottom: 0 }}>
                <li>
                  <b>{user.main_company?.name}</b>{' '}
                  <a
                    href={`https://www.tredunion.fr/edition-donnees-entreprise/${user.main_company?.fill_request_uuid}`}
                  >{`https://www.tredunion.fr/edition-donnees-entreprise/${user.main_company?.fill_request_uuid}`}</a>
                </li>
              </ul>
            ) : (
              ''
            )}
            {user.secondary_companies?.find(
              c => c.fill_request_completed_at === null
            ) ? (
              <ul>
                {user.secondary_companies
                  .filter(c => c.fill_request_completed_at === null)
                  .map((company, index) => (
                    <li key={index}>
                      <b>{company.name}</b>{' '}
                      <a
                        href={`https://www.tredunion.fr/edition-donnees-entreprise/${company.fill_request_uuid}`}
                      >{`https://www.tredunion.fr/edition-donnees-entreprise/${company.fill_request_uuid}`}</a>
                    </li>
                  ))}
              </ul>
            ) : (
              ''
            )}
          </Alert>
        </Col>
      ) : (
        ''
      )}
      {ads.length > 0 && (
        <Col xs={12} lg={12}>
          <Alert variant="info">
            <p className="mb-2">
              <b>Important !</b> Vos annonces ci-dessous expirent demain :
            </p>
            <ul>
              {ads.map((ad, index) => (
                <li key={index}>
                  <Link to={`/espace-membre/annonces/${ad.id}`}>
                    {ad.title}
                  </Link>{' '}
                  - vous pouvez soit{' '}
                  <b>
                    renouveller votre annonce en{' '}
                    <a
                      href="#"
                      onClick={() => {
                        renewAd(ad);
                      }}
                    >
                      cliquant ici
                    </a>
                  </b>
                  , soit ne rien faire (elle sera supprimée automatiquement
                  demain)
                </li>
              ))}
            </ul>
          </Alert>
        </Col>
      )}
      <Col xs={12}>
        <Row>
          {user.id !== null &&
            user.id !== undefined &&
            user.events &&
            user.surveys &&
            (user.events.length > 0 || user.surveys.length > 0) && (
              <Col xs={12} lg={12}>
                <Alert variant="info">
                  <p className="mb-2">
                    <b>Important !</b> Vous avez été invité à répondre{' '}
                    {user.events.length + user.surveys.length > 1
                      ? 'aux formulaires '
                      : 'au formulaire '}
                    ci-dessous :
                  </p>
                  <ul className="mb-0 pb-0">
                    {user.events.map((event, index) => (
                      <li key={index}>
                        <a
                          target="_blank"
                          rel={'noopener noreferrer'}
                          href={`/reponse-formulaire/${event.pivot.survey_access_uuid}`}
                        >
                          {event.participation_form.title}
                        </a>{' '}
                        -{' '}
                        <b>
                          date limite de réponse :{' '}
                          {Moment(event.participation_form.expires_on)
                            .local()
                            .format('DD/MM/YYYY')}
                        </b>
                      </li>
                    ))}
                    {user.surveys.map((survey, index) => (
                      <li key={index}>
                        <a
                          target="_blank"
                          rel={'noopener noreferrer'}
                          href={`/reponse-formulaire/${survey.pivot.survey_access_uuid}`}
                        >
                          {survey.title}
                        </a>{' '}
                        -{' '}
                        <b>
                          date limite de réponse :{' '}
                          {Moment(survey.expires_on)
                            .local()
                            .format('DD/MM/YYYY')}
                        </b>
                      </li>
                    ))}
                  </ul>
                </Alert>
              </Col>
            )}
          {user?.links?.length > 0 && (
            <Col xs={12} lg={12}>
              <Alert variant="warning">
                <p className="mb-2">
                  <b>Important !</b> Vous devez répondre et/ou diffuser le/les
                  lien(s) vers le/les formulaire(s) suivant(s) :{' '}
                </p>
                <ul className="mb-0 pb-0">
                  {user.links.map((link, index) => (
                    <li key={index}>
                      <a
                        target="_blank"
                        rel={'noopener noreferrer'}
                        href={`/formulaire-entreprise/${link.uuid}`}
                      >
                        {link.survey.title}
                      </a>{' '}
                      -{' '}
                      <b>
                        date limite de réponse :{' '}
                        {Moment(link.expires_at).local().format('DD/MM/YYYY')}
                      </b>
                    </li>
                  ))}
                  {user.surveys.map((survey, index) => (
                    <li key={index}>
                      <a
                        target="_blank"
                        rel={'noopener noreferrer'}
                        href={`/reponse-formulaire/${survey.pivot.survey_access_uuid}`}
                      >
                        {survey.title}
                      </a>{' '}
                      -{' '}
                      <b>
                        date limite de réponse :{' '}
                        {Moment(survey.expires_on).local().format('DD/MM/YYYY')}
                      </b>
                    </li>
                  ))}
                </ul>
              </Alert>
            </Col>
          )}
          <Col xs={12} lg={8}>
            <Row>
              {user.id !== null &&
                user.id !== undefined &&
                user.is_admin === 1 && (
                  <Col xs={12} lg={6} className="mb-3 pe-lg-1">
                    <Card className="h-100 home-cards">
                      <Card.Body className="z-index-1">
                        <Flex className="position-relative">
                          <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="25"
                              height="25"
                            >
                              <path
                                d="M5 12.5c0 .313.461.858 1.53 1.393C7.914 14.585 9.877 15 12 15c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171C17.35 11.349 14.827 12 12 12s-5.35-.652-7-1.671V12.5zm14 2.829C17.35 16.349 14.827 17 12 17s-5.35-.652-7-1.671V17.5c0 .313.461.858 1.53 1.393C7.914 19.585 9.877 20 12 20c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171zM3 17.5v-10C3 5.015 7.03 3 12 3s9 2.015 9 4.5v10c0 2.485-4.03 4.5-9 4.5s-9-2.015-9-4.5zm9-7.5c2.123 0 4.086-.415 5.47-1.107C18.539 8.358 19 7.813 19 7.5c0-.313-.461-.858-1.53-1.393C16.086 5.415 14.123 5 12 5c-2.123 0-4.086.415-5.47 1.107C5.461 6.642 5 7.187 5 7.5c0 .313.461.858 1.53 1.393C7.914 9.585 9.877 10 12 10z"
                                fill="rgba(229,47,18,1)"
                              />
                            </svg>
                          </div>
                          <div className="flex-1">
                            <Link
                              to="/administration/calendrier"
                              className="stretched-link"
                            >
                              <h5 className="card-title mb-1">
                                Administration
                              </h5>
                            </Link>
                            <p className="mb-0 fs--2 text-500 ">
                              Gestion de la plateforme Tred Union
                            </p>
                          </div>
                        </Flex>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              {user.id !== null &&
                user.id !== undefined &&
                user.calendar_access === 1 && (
                  <Col xs={12} lg={6} className="mb-3 pe-lg-1">
                    <Card className="h-100 home-cards">
                      <Card.Body className="z-index-1">
                        <Flex className="position-relative">
                          <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="25"
                              height="25"
                            >
                              <path
                                fill="rgba(229,47,18,1)"
                                d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 6V5h-3v2h-2V5H9v2H7V5H4v4h16zm0 2H4v8h16v-8zM6 13h5v4H6v-4z"
                              />
                            </svg>
                          </div>
                          <div className="flex-1">
                            <h5
                              onClick={() => {
                                document
                                  .getElementById('events')
                                  .scrollIntoView();
                              }}
                              className="card-title mb-1 cursor-pointer"
                            >
                              {' '}
                              <span
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  left: '-3px'
                                }}
                              >
                                Calendrier
                              </span>
                            </h5>
                            <p className="mb-0 fs--2 text-500 ">
                              Accédez aux événements du groupement
                            </p>
                          </div>
                        </Flex>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              {membersRoutes
                .filter(
                  route =>
                    route.menus.includes('home') &&
                    (route.roles.length === 0 ||
                      route.roles.filter(x =>
                        pluck(user.roles, 'id').includes(x)
                      ).length > 0) &&
                    (route.exceptRoles.length === 0 ||
                      route.exceptRoles.filter(x =>
                        pluck(user.roles, 'id').includes(x)
                      ).length === 0)
                )
                .map((route, index) => (
                  <Col xs={12} lg={6} className="mb-3 pe-lg-1" key={index}>
                    <Card key={index} className="h-100 home-cards">
                      <Card.Body className="z-index-1">
                        <Flex className="position-relative">
                          <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="25"
                              height="25"
                            >
                              <path d={route.svg} fill="rgba(229,47,18,1)" />
                            </svg>
                          </div>
                          <div className="flex-1">
                            <Link to={route.path} className="stretched-link">
                              <h5 className="card-title mb-1">{route.label}</h5>
                            </Link>
                            <p className="mb-0 fs--2 text-500 ">
                              {route.description}
                            </p>
                          </div>
                        </Flex>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Col>
          <MemberSide user={user} />
        </Row>
      </Col>
      {user.id !== null && user.id !== undefined && user.calendar_access === 1 && (
        <Col xs={12} className="mt-6">
          <CalendarMember />
        </Col>
      )}
      <Col xs={12} className="mt-5"></Col>
    </Row>
  );
};

export default HomeMembers;
