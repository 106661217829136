import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Row,
  Col,
  Alert,
  Button,
  Offcanvas,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
  Tab,
  Tabs
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as Moment from 'moment/moment';
import SoftBadge from '../../../../common/SoftBadge';
import Vimeo from '@u-wave/react-vimeo';
import LineAnswers from '../surveys/charts/LineAnswers';
import { ShowAdminPanelSurvey } from '../../../../../services/Survey/SurveyService';
import RecipientsTableSurvey from './tables/RecipientsTableSurvey';
import { useSearchParams } from 'react-router-dom';
import ReduceAnswers from './charts/reduceAnswers/reduceAnswers';
import ReviewAnswers from './charts/reviewAnswers';
import TextAnswers from './charts/textAnswers';
import PropTypes from 'prop-types';
import SendNotification from './notification/SendNotification';
import EmailLogsTable from './tables/EmailLogsTable';
import moment from 'moment';

const ShowSurvey = ({ type }) => {
  const [survey, setSurvey] = useState({});
  const [trigger, setTrigger] = useState(Date.now());
  const [recipients, setRecipients] = useState([]);
  const [showRecipients, setShowRecipients] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchsurvey = async () => {
      const responseObjects = await ShowAdminPanelSurvey(id);
      if (responseObjects.success === true) {
        setSurvey(responseObjects.data);
        let tmpRecipients = [];
        if (responseObjects.data.internal_recipients) {
          responseObjects.data.internal_recipients.map(recipient =>
            tmpRecipients.push({
              ...recipient,
              type: 'internal'
            })
          );
        }
        if (responseObjects.data.external_recipients) {
          responseObjects.data.external_recipients.map(recipient =>
            tmpRecipients.push({
              ...recipient,
              type: 'external'
            })
          );
        }
        setRecipients(tmpRecipients);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchsurvey();
    setTimeout(function () {
      if (document.getElementById(window.location.hash.replace('#', ''))) {
        document
          .getElementById(window.location.hash.replace('#', ''))
          .scrollIntoView();
      }
    }, 900);
  }, [trigger]);

  return (
    <>
      {isLoading === true ? (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <>
          {survey.id && (
            <>
              <Card>
                <Card.Body className="overflow-hidden px-lg-3">
                  <Row className="justify-content-between align-items-center">
                    <Col lg={6}>
                      <h3 className="text-primary admin-title mb-0">
                        {type === 'purchase-declaration'
                          ? 'Déclaration'
                          : 'Questionnaire'}{' '}
                        - {survey.title}
                      </h3>
                      <Col xs={12} className="mt-3">
                        <Row>
                          <Col xs={4}>
                            {recipients && recipients.length > 0 && (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="overlay-trigger-example">
                                    {recipients.length} destinataires -{' '}
                                    {recipients.filter(
                                      r =>
                                        r.pivot && r.pivot.survey_completed_on
                                    ).length === 0 ? (
                                      "personne n'a encore répondu"
                                    ) : (
                                      <>
                                        {
                                          recipients.filter(
                                            r =>
                                              r.pivot &&
                                              r.pivot.survey_completed_on
                                          ).length
                                        }{' '}
                                        {recipients.filter(
                                          r =>
                                            r.pivot &&
                                            r.pivot.survey_completed_on
                                        ).length > 1
                                          ? 'ont'
                                          : 'a'}{' '}
                                        répondu
                                      </>
                                    )}
                                  </Tooltip>
                                }
                              >
                                {recipients.length ===
                                recipients.filter(
                                  r => r.pivot && r.pivot.survey_completed_on
                                ).length ? (
                                  <div className="d-block cursor-pointer">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                    >
                                      <path
                                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                                        fill="#01D27A"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <ProgressBar
                                    className="cursor-pointer"
                                    style={{ height: 8 }}
                                    now={
                                      ((recipients.length -
                                        recipients.filter(
                                          r =>
                                            !r.pivot ||
                                            (r.pivot &&
                                              !r.pivot.survey_completed_on)
                                        ).length) *
                                        100) /
                                      recipients.length
                                    }
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    <Col lg={6} className="text-end">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          if (searchParams.get('back')) {
                            navigate(searchParams.get('back'));
                          } else {
                            navigate(
                              `${
                                type === 'purchase-declaration'
                                  ? '/administration/declarations-achats'
                                  : '/administration/questionnaires'
                              }`
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        Retour
                      </Button>
                      <Button
                        variant="primary"
                        className="admin-primary"
                        size="sm"
                        onClick={() => {
                          navigate(
                            `${
                              type === 'purchase-declaration'
                                ? `/administration/declarations-achats/${survey.id}/modifier?back=/administration/declarations-achats/${survey.id}`
                                : `/administration/questionnaires/${survey.id}/modifier?back=/administration/questionnaires/${survey.id}`
                            }`
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '2px' }}
                        >
                          <path
                            d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Modifier{' '}
                        {type === 'purchase-declaration'
                          ? 'la déclaration'
                          : 'le questionnaire'}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row className="mt-0">
                <Col>
                  <Card className="mt-3">
                    <Card.Body>
                      {survey.description ? (
                        <p className="smaller-p mt-2 mb-0">
                          <b>Description :</b>{' '}
                          <span
                            className="smaller-p mb-0"
                            dangerouslySetInnerHTML={{
                              __html: survey.description.replace(
                                /\n/g,
                                '<br />'
                              )
                            }}
                          ></span>
                        </p>
                      ) : (
                        ''
                      )}
                      {survey.creator?.id && (
                        <p className="smaller-p mt-2 mb-0">
                          <b>Créateur :</b> {survey.creator?.firstname}{' '}
                          {survey.creator?.lastname}
                        </p>
                      )}
                      <p className="smaller-p mt-2 mb-0">
                        <b>Date de création :</b>{' '}
                        {Moment(survey.created_at)
                          .local()
                          .format('DD/MM/YYYY HH:mm')}
                      </p>
                      <p className="smaller-p mt-2 mb-0">
                        <b>Date de dernière modification :</b>{' '}
                        {Moment(survey.updated_at)
                          .local()
                          .format('DD/MM/YYYY HH:mm')}
                      </p>
                    </Card.Body>
                  </Card>
                  {recipients.length > 0 && (
                    <Alert className="mt-3 mb-0" variant="primary">
                      <p className="smaller-p mb-1">
                        <b>Destinataires et emails automatiques :</b>
                      </p>
                      <p className="smaller-p mb-0">
                        <a
                          className="cursor-pointer"
                          onClick={() => setShowRecipients(true)}
                        >
                          {recipients.length} personne
                          {recipients.length > 1 && 's'}
                        </a>{' '}
                        {recipients.length > 1
                          ? 'ont été invitées '
                          : 'a été invitée '}
                        à répondre à un questionnaire avant le{' '}
                        {Moment(survey.expires_on).local().format('DD/MM/YYYY')}
                        .
                      </p>
                      <p className="smaller-p mb-0 mt-3">
                        <b>Date d'envoi du formulaire : </b>
                        {survey.starts_on
                          ? moment(survey.starts_on).format('DD/MM/YYYY')
                          : moment(survey.created_at).format('DD/MM/YYYY')}
                      </p>
                      {survey.survey_dunning_type_id ? (
                        <>
                          <p className="smaller-p mb-0 mt-3">
                            <b>Relances : </b> activées - envoi à partir du{' '}
                            {moment(survey.dunning_starts_on).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            - {survey.dunning_type?.title}
                          </p>
                        </>
                      ) : (
                        <p className="smaller-p mb-0 mt-3">
                          <b>Relances :</b> désactivées
                        </p>
                      )}
                      <p className="smaller-p mb-0 mt-3">
                        <b>Expiration du formulaire :</b>{' '}
                        {moment(survey.expires_on).format('DD/MM/YYYY')}
                      </p>
                      <SendNotification survey={survey} />
                    </Alert>
                  )}
                </Col>
              </Row>
              <div className="d-block mt-4"></div>
              <Tabs
                defaultActiveKey={'recipients'}
                id="uncontrolled-tab-example"
              >
                <Tab
                  eventKey="recipients"
                  title="Réponses du formulaire"
                  className="p-0"
                >
                  <RecipientsTableSurvey
                    recipients={recipients}
                    survey={survey}
                    type={type}
                    setParentTrigger={setTrigger}
                  />
                </Tab>
                {survey?.link_recipients?.find(
                  r => r.from_recipients === 1
                ) && (
                  <Tab eventKey="companies" title="Entreprises" className="p-0">
                    <Row>
                      <Col xs={12} className="mt-3 p-3 px-3">
                        <table className="table table-striped table-hover table-stats-tracks">
                          <thead>
                            <th className="ps-0">
                              <b className="p-0">Entreprise</b>
                            </th>
                            <th>
                              <b className="p-0">Nombre de répondants</b>
                            </th>
                          </thead>
                          <tbody>
                            {survey?.link_recipients
                              ?.sort((a, b) =>
                                a.default_company.localeCompare(
                                  b.default_company
                                )
                              )
                              .map((company, index) => (
                                <tr key={index}>
                                  <td>{company.default_company}</td>
                                  <td
                                    className={
                                      recipients.filter(r => {
                                        if (
                                          r.links?.find(
                                            l => l.id === company.id
                                          )
                                        ) {
                                          return r;
                                        }
                                      })?.length === 0
                                        ? 'text-danger'
                                        : ''
                                    }
                                  >
                                    {
                                      recipients.filter(r => {
                                        if (
                                          r.links?.find(
                                            l => l.id === company.id
                                          )
                                        ) {
                                          return r;
                                        }
                                      })?.length
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Tab>
                )}
                <Tab
                  eventKey="logs"
                  title="Historique des emails"
                  className="p-0"
                >
                  <Row>
                    <Col xs={12} className="pt-3" id="email-logs">
                      <EmailLogsTable object={survey} recipients={recipients} />
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="statistics" title="Statistiques" className="p-0">
                  <Row>
                    <Col xs={12} className="mt-4">
                      <LineAnswers data={recipients} />
                    </Col>
                    <Col xs={12} className="mt-3"></Col>
                    {type !== 'purchase-declaration' && (
                      <Col xs={12}>
                        <Row>
                          {survey.questions.map((question, index) => (
                            <Fragment key={index}>
                              {question.type &&
                                ['checkbox', 'radio'].includes(
                                  question.type.slug
                                ) && (
                                  <ReduceAnswers
                                    question={question}
                                    recipients={recipients}
                                    survey={survey}
                                  />
                                )}
                              {question.type &&
                                question.type.slug === 'review' && (
                                  <ReviewAnswers
                                    question={question}
                                    recipients={recipients}
                                  />
                                )}
                              {question.type &&
                                (question.type.slug === 'text' ||
                                  question.type.slug === 'number') && (
                                  <TextAnswers
                                    question={question}
                                    recipients={recipients}
                                  />
                                )}
                            </Fragment>
                          ))}
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Tab>
              </Tabs>

              {recipients.length > 0 && (
                <Offcanvas
                  show={showRecipients}
                  onHide={() => {
                    setShowRecipients(false);
                  }}
                  placement={'end'}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Destinataires</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="pt-0">
                    <Table responsive className="table-stats-tracks">
                      <tbody>
                        {recipients.map((recipient, index) => (
                          <tr key={index}>
                            <td>
                              {recipient.login ? (
                                <>
                                  {recipient.partner && recipient.partner.id ? (
                                    <>
                                      {recipient.email}
                                      <SoftBadge bg="primary" className="ms-1">
                                        {recipient.partner.name}
                                      </SoftBadge>
                                    </>
                                  ) : (
                                    <>
                                      {recipient.lastname} {recipient.firstname}
                                      {recipient.main_company &&
                                        recipient.main_company.name && (
                                          <SoftBadge
                                            bg="primary"
                                            className="ms-1"
                                          >
                                            {recipient.main_company.name}
                                          </SoftBadge>
                                        )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {recipient.company_name &&
                                    `${recipient.company_name} - `}
                                  {(recipient.lastname ||
                                    recipient.firstname) &&
                                    `${recipient.lastname} ${recipient.firstname} - `}
                                  {recipient.email}{' '}
                                  <SoftBadge bg="info" className="ms-1">
                                    Externe
                                  </SoftBadge>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button
                      variant="primary"
                      className="admin-primary"
                      size="sm"
                      onClick={() => {
                        if (type === 'purchase-declaration') {
                          navigate(
                            `/administration/declarations-achats/${survey.id}/modifier?back=/administration/declarations-achat/${survey.id}`
                          );
                        } else {
                          navigate(
                            `/administration/questionnaires/${survey.id}/modifier?back=/administration/questionnaires/${survey.id}`
                          );
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                      Modifier
                    </Button>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="ms-2"
                      onClick={() => {
                        setShowRecipients(false);
                      }}
                    >
                      Fermer
                    </Button>
                  </Offcanvas.Body>
                </Offcanvas>
              )}
              {survey && (
                <Offcanvas
                  show={showSurvey}
                  onHide={() => {
                    setShowSurvey(false);
                  }}
                  placement={'end'}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Questionnaire</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="pt-0">
                    {survey.description && (
                      <>
                        <p className="smaller-p mb-0">
                          <b>Description</b>
                        </p>
                        <p
                          className="smaller-p"
                          dangerouslySetInnerHTML={{
                            __html: survey.description.replace(/\n/g, '<br />')
                          }}
                        />
                      </>
                    )}
                    {survey.vimeo_id && (
                      <Vimeo
                        video={survey.vimeo_id}
                        style={{ width: '100%' }}
                        className="vimeo-video mb-4"
                      />
                    )}
                    <p className="smaller-p mb-0">
                      <b>Questions</b>
                    </p>
                    <Table responsive className="table-stats-tracks">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {survey.questions.map((question, index) => (
                          <tr key={index}>
                            <td>{question.title}</td>
                            <td>
                              {question.type.title}
                              {question.answers && (
                                <>
                                  {question.answers.map((answer, indexI) => (
                                    <div key={indexI} className="d-block">
                                      <SoftBadge bg="primary" className="me-2">
                                        {answer.title}
                                      </SoftBadge>
                                    </div>
                                  ))}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Button
                      variant="primary"
                      className="admin-primary"
                      size="sm"
                      onClick={() => {
                        navigate(
                          `/administration/questionnaires/${survey.id}/modifier?back=/administration/questionnaires/${survey.id}`
                        );
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                      Modifier
                    </Button>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="ms-2"
                      onClick={() => {
                        setShowSurvey(false);
                      }}
                    >
                      Fermer
                    </Button>
                  </Offcanvas.Body>
                </Offcanvas>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

ShowSurvey.propTypes = {
  type: PropTypes.string
};

export default ShowSurvey;
