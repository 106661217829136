import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import Flex from '../../../../../common/Flex';
import SoftBadge from '../../../../../common/SoftBadge';

const ReviewAnswers = ({ question, recipients }) => {
  const [reviews, setReviews] = useState([]);
  const [reviewsAverage, setReviewsAverage] = useState(-1);
  useEffect(() => {
    let tmpReviews = [];
    recipients.map(recipient => {
      if (recipient.survey_questions) {
        let recipientQuestion = recipient.survey_questions.filter(
          q => q.id === question.id
        );
        if (recipientQuestion.length > 0) {
          tmpReviews.push({
            value: recipientQuestion[0].pivot.value,
            comment: recipientQuestion[0].pivot.comment,
            user: recipient
          });
        }
      }
    });
    setReviewsAverage(
      (
        tmpReviews.reduce(function (acc, obj) {
          return acc + parseInt(obj.value);
        }, 0) / tmpReviews.length
      ).toFixed(1)
    );
    setReviews(tmpReviews);
  }, []);

  return (
    <>
      {reviews.length > 0 && reviewsAverage >= 0 && (
        <Col xs={4} className="mt-3">
          <Card className="h-100">
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                  <Form.Label>
                    <b>{question.title}</b>
                  </Form.Label>
                </Col>
                <Col xs={12} className="text-center">
                  <p className="mb-1 mt-2 pt-1">Moyenne des notes</p>
                  <p
                    className={`mb-0 ${
                      reviewsAverage <= 5
                        ? 'text-danger'
                        : reviewsAverage <= 7
                        ? 'text-warning'
                        : 'text-success'
                    }`}
                    style={{ fontSize: 20 }}
                  >
                    <b>{reviewsAverage} / 10</b>
                  </p>
                </Col>
                {reviews.filter(review => review.comment).length > 0 && (
                  <Col xs={12} className="pt-4">
                    <SimpleBarReact
                      className="ask-analytics"
                      style={{ maxHeight: 300 }}
                    >
                      <div className="pt-0">
                        {reviews
                          .filter(review => review.comment)
                          .map((review, index) => (
                            <div
                              key={index}
                              className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3"
                            >
                              <Flex alignItems="center" className="mb-1">
                                <p className="mb-2">
                                  {review.user.firstname ? (
                                    <>
                                      {review.user.firstname}{' '}
                                      {review.user.lastname}
                                    </>
                                  ) : (
                                    <SoftBadge
                                      pill
                                      bg="primary"
                                      className="ms-1"
                                    >
                                      {review.user.email}
                                    </SoftBadge>
                                  )}
                                  {review.user.main_company && (
                                    <SoftBadge
                                      pill
                                      bg="primary"
                                      className="ms-1"
                                    >
                                      {review.user.main_company.name}
                                    </SoftBadge>
                                  )}
                                  {review.user.company_name && (
                                    <SoftBadge
                                      pill
                                      bg="primary"
                                      className="ms-1"
                                    >
                                      {review.user.company_name} (Ext.)
                                    </SoftBadge>
                                  )}
                                </p>
                              </Flex>
                              <div className="d-block border-top border-1 border-300 rounded-2 w-75"></div>
                              <p className="mt-2 mb-0">{review.comment}</p>
                            </div>
                          ))}
                      </div>
                    </SimpleBarReact>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

// Proptypes for this component
ReviewAnswers.propTypes = {
  question: PropTypes.object.isRequired,
  recipients: PropTypes.array.isRequired
};

export default ReviewAnswers;
