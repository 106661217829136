import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: ''
};

export const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeSearch } = surveysSlice.actions;

export default surveysSlice.reducer;
