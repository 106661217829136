import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'trainings';

export async function ShowTraining(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostTraining(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutTraining(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteTraining(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
