import React from 'react';
import NavbarStandard from '../../commons/NavbarStandard';
import logoLTR from 'assets/img/tredunion/logo_letransportrecruteNew.png';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';
import bgActualites from '../../../../assets/img/tredunion/bg_actualites.jpg';
import Vimeo from '@u-wave/react-vimeo';
import FooterStandard from '../../commons/FooterStandard';

const LTR = () => {
  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-7"
        id="actualites"
        style={{ backgroundImage: `url("${bgActualites}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>LETRANSPORTRECRUTE.FR</h2>
          </Col>
        </Row>
      </Section>
      <Section bg="light" className="d-flex align-items-end p-1 mt-5">
        <Row className="">
          <Col xs={8} lg={5}>
            <img className="mw-100" src={logoLTR} />
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col xs={12}>
            <p className="smaller-p text-justify">
              32000 postes à pourvoir dans le monde du transport. C’est 32000
              opportunités de changement de carrière, de trouver sa voie, etc...
              Et pas qu’en qualité de chauffeurs routiers ! Egalement des
              secrétaires, comptables, formateurs, caristes, commerciaux, et
              bien d’autres encore... <b>Alors, et si vous nous rejoigniez ?</b>
            </p>
            <p className="smaller-p text-justify">
              <a
                href="https://www.letransportrecrute.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Accéder au site web
              </a>
            </p>
            <Vimeo
              video="358748409"
              autoplay
              style={{ width: '100%' }}
              className="vimeo-video"
            />
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default LTR;
