import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'elearning/evaluations';

export async function ShowPublicEvaluation(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostEvaluation(formData) {
  return modelService.PostModel(`${model}`, formData);
}
