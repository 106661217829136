import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  startDate: '',
  endDate: '',
  categories: [],
  companies: [],
  is_points_managed: 0
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // méthodes qui vont changer le state de search
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    changeCategories: (state, action) => {
      state.categories = action.payload.map(cat => {
        return {
          value: cat.value,
          label: cat.originalLabel
        };
      });
    },
    changeCompanies: (state, action) => {
      state.companies = [...action.payload];
    },
    changeIsPointsManaged: (state, action) => {
      state.is_points_managed = action.payload;
    },
    resetFilters: state => {
      state.search = '';
      state.startDate = '';
      state.endDate = '';
      state.categories = [];
      state.companies = [];
      state.is_points_managed = 0;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSearch,
  changeCategories,
  changeCompanies,
  changeStartDate,
  changeEndDate,
  changeIsPointsManaged,
  resetFilters
} = calendarSlice.actions;

export default calendarSlice.reducer;
