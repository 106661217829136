import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { IndexRequestHistory } from '../../../../../../services/Elearning/RequestService';
import ExportELearningRequestsHistory from '../../../../../../exports/elearningRequestsHistory';
import SoftBadge from '../../../../../common/SoftBadge';
import { IndexUserElearningRequest } from '../../../../../../services/UserService';
import Select from 'react-select';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const IndexCourseRequestsHistory = () => {
  const [trigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [objects, setObjects] = useState([]);

  const [courses, setCourses] = useState([]);
  const [companiesInput, setCompaniesInput] = useState([]);
  const [managerInput, setManagerInput] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);

  const [formDataSearch, setFormDataSearch] = useStateWithCallbackLazy({
    search: '',
    companies: [],
    courses: [],
    managers: [],
    startDate: '',
    endDate: ''
  });

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Choisir une date'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted cursor-pointer"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formDataSearch.startDate && formDataSearch.endDate && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setFormDataSearch(
              {
                ...formDataSearch,
                startDate: '',
                endDate: ''
              },
              data => {
                updateSearch(data, initialObjects, true);
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };
  const getSearchInputsValues = objects => {
    let tmpManager = [];

    objects.map(obj => {
      if (obj.manager && obj.manager.id) {
        if (
          !tmpManager.filter(
            m => m.value === `${obj.manager.firstname} ${obj.manager.lastname}`
          ).length > 0
        ) {
          tmpManager.push({
            value: `${obj.manager.firstname} ${obj.manager.lastname}`,
            label: `${obj.manager.firstname} ${obj.manager.lastname}`
          });
        }
      }
    });

    let tmpCourses = [];

    objects.map(obj => {
      if (obj.courses) {
        obj.courses.map(course => {
          if (!tmpCourses.filter(c => c.value === course.title).length > 0) {
            tmpCourses.push({
              value: course.title,
              label: course.title
            });
          }
        });
      }
    });
    let tmpCompanies = [];
    objects.map(obj => {
      if (!tmpCompanies.filter(c => c.value === obj.company).length > 0) {
        tmpCompanies.push({
          value: obj.company,
          label: obj.company
        });
      }
      if (
        obj.initiator &&
        obj.initiator.main_company &&
        !tmpCompanies.filter(c => c.value === obj.initiator.main_company.name)
          .length > 0
      ) {
        tmpCompanies.push({
          value: obj.initiator.main_company.name,
          label: obj.initiator.main_company.name
        });
      }
    });
    setCompaniesInput(
      tmpCompanies.sort((a, b) => a.label.localeCompare(b.label))
    );
    setManagerInput(tmpManager);
    setCourses(tmpCourses);
  };

  const coursesToString = courses => {
    let tmpCourses = '';
    if (courses && courses.length > 0) {
      courses.map((obj, index) => {
        tmpCourses += `${obj.title} ${
          index + 1 !== courses.length ? ', ' : ''
        }`;
      });
    }
    return tmpCourses;
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        created_at: Moment(obj.created_at).local().format('DD/MM/YYYY'),
        company: obj.company,
        student: (
          <>
            Prénom : {obj.firstname}
            <span className="d-block mb-1"></span>
            Nom : {obj.lastname}
            <span className="d-block mb-1"></span>
            Fonction : {obj.occupation}
            <span className="d-block mb-1"></span>
            Email :{' '}
            <SoftBadge bg="info" className="me-2">
              <a href={`mailto:${obj.email}`}>{obj.email}</a>
            </SoftBadge>
            <span className="d-block mb-1"></span>
            Téléphone : {obj.phone}
          </>
        ),
        firstname: obj.firstname,
        lastname: obj.lastname,
        studentFullname: `${obj.firstname} ${obj.lastname}`,
        initiatorFullname: obj.initiator
          ? `${obj.initiator.firstname} ${obj.initiator.lastname}`
          : '',
        initiatorCompany: obj.initiator.main_company
          ? `${obj.initiator.main_company.name}`
          : '',
        occupation: obj.occupation,
        email: obj.email,
        phone: obj.phone,
        courses: coursesToString(obj.courses),
        coursesArray: obj.courses,
        historyBy:
          obj.manager && obj.manager.id
            ? `${obj.manager.firstname} ${obj.manager.lastname}`
            : '',
        initiator: obj.initiator
          ? `${obj.initiator.firstname} ${obj.initiator.lastname} ${
              obj.initiator.main_company
                ? `(${obj.initiator.main_company.name})`
                : ''
            }`
          : '',
        historyWhen: Moment(obj.processed_at).local().format('DD/MM/YY'),
        historyWhenMomentObject: Moment(obj.processed_at)
      });
    });
    updateSearch(formDataSearch, transformedApiObjects);
    setInitialObjects(transformedApiObjects);
    setObjects(transformedApiObjects);
  };

  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexRequestHistory();
      if (responseObjects.success === true) {
        getSearchInputsValues(responseObjects.data);
        setInitialApiObjects(responseObjects.data);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUsers = async () => {
      const responseUsers = await IndexUserElearningRequest();
      if (responseUsers.success === true) {
        let tmpUsers = [];
        responseUsers.data.map(user => {
          tmpUsers.push({
            value: user.id,
            label: `${user.firstname} ${user.lastname} - ${user.email}`,
            user: user
          });
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchUsers();
    fetchObjects();
  }, [trigger]);

  const updateSearch = data => {
    if (
      data.search.length === 0 &&
      data.courses.length === 0 &&
      data.companies.length === 0 &&
      data.managers.length === 0 &&
      !data.startDate &&
      !data.endDate
    ) {
      setObjects(initialObjects);
    } else {
      let tmpFilterObjects = initialObjects;
      if (data.search.length > 0) {
        let tmpSearch = data.search.toLowerCase();
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.studentFullname &&
              obj.studentFullname
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.studentFullname &&
              obj.studentFullname
                .split(' ')
                .reverse()
                .join(' ')
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.email && obj.email.toLowerCase().includes(tmpSearch)) ||
            (obj.initiatorFullname &&
              obj.initiatorFullname
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.initiatorFullname &&
              obj.initiatorFullname
                .split(' ')
                .reverse()
                .join(' ')
                .toLowerCase()
                .includes(tmpSearch.replace('\t', ' ')))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (data.courses.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.courses.map(course => {
            if (
              obj.coursesArray &&
              obj.coursesArray.filter(c => c.title === course.value).length > 0
            ) {
              result = true;
            }
          });

          return result;
        });
      }
      if (data.managers.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.managers.map(manager => {
            if (obj.historyBy === manager.value) {
              result = true;
            }
          });

          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (
              obj.company === company.value ||
              obj.initiatorCompany === company.value
            ) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.startDate || data.endDate) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            data.startDate &&
            Moment(obj.historyWhenMomentObject)
              .local()
              .isSameOrAfter(Moment(data.startDate)) &&
            (!data.endDate ||
              (data.endDate &&
                Moment(obj.historyWhenMomentObject)
                  .local()
                  .isSameOrBefore(Moment(data.endDate).add(1, 'days'))))
          ) {
            result = true;
          }
          return result;
        });
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={9}>
              <h3 className="text-primary admin-title mb-0">
                Historique demandes d'accès E-learning
              </h3>
            </Col>
            <Col lg={3} className="text-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="ms-2"
                onClick={() => {
                  window.location.href = '/administration/elearning-acces';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Retour
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="align-items-end mb-3">
              <Col xs={3} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nom, prénom, email"
                  value={formDataSearch.search || ''}
                  onChange={event => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Société(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={companiesInput}
                  placeholder="Choisir..."
                  isMulti
                  name="companies"
                  classNamePrefix="react-select"
                  value={formDataSearch.companies}
                  onChange={value => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        companies: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Formation(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={courses}
                  placeholder="Choisir..."
                  isMulti
                  name="courses"
                  classNamePrefix="react-select"
                  value={formDataSearch.courses}
                  onChange={value => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        courses: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3 d-none">
                <Form.Label>Gestionnaire</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={managerInput}
                  placeholder="Choisir..."
                  isMulti
                  name="managers"
                  classNamePrefix="react-select"
                  value={formDataSearch.managers}
                  onChange={value => {
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        managers: value
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3}>
                <Form.Label>Gérée le</Form.Label>
                <DatePicker
                  onChange={dates => {
                    const [start, end] = dates;
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        startDate: start,
                        endDate: end
                      },
                      data => {
                        updateSearch(data);
                      }
                    );
                  }}
                  startDate={formDataSearch.startDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={formDataSearch.endDate}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  customInput={<DatePickerInput />}
                  locale="fr"
                />
              </Col>
            </Row>
            <Col xs={12} className="mt-3">
              <Table data={objects} />
            </Col>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

function Table({ data }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'created_at',
        Header: 'Date'
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'student',
        Header: 'Étudiant'
      },
      {
        accessor: 'courses',
        Header: 'Formation(s)'
      },
      {
        accessor: 'initiator',
        Header: 'Rédacteur'
      },
      {
        accessor: 'historyBy',
        Header: 'Gestionnaire'
      },
      {
        accessor: 'historyWhen',
        Header: 'Gérée le'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table table-container"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportELearningRequestsHistory objects={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array
};

export default IndexCourseRequestsHistory;
