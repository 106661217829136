import React, { useState } from 'react';
import { Alert, Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormAlert from '../commons/FormAlert';
import { PutUser } from '../../../services/UserService';
import { LogOutUserService } from '../../../services/AuthService';

const InfosPerso = () => {
  const [user] = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteAsked, setDeleteAsked] = useState(false);
  const [formData, setFormData] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email
  });
  const handleSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();
    const response = await PutUser('profile.updateform', user.id, formData);
    if (response.success === true) {
      toast('Modifications enregistrées avec succès.');
    } else if (response.validator) {
      setErrorForm(response.validator);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };
  const handleAskDeleteAcount = async () => {
    setIsLoading(true);
    const response = await PutUser('askdeleteaccount', user.id, formData);
    if (response.success === true) {
      toast('Votre demande a bien été prise en compte.');
      setShowDelete(false);
      setDeleteAsked(true);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <Row className="mt-2">
      <Col xs={12}>
        <h5 className="mb-4 redHeading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
              fill="rgba(229,47,18,1)"
            />
          </svg>{' '}
          Modifier mes informations personnelles
        </h5>
      </Col>
      {user && (
        <Col xs={12}>
          <Form onSubmit={handleSubmit}>
            {errorForm && <FormAlert variant="danger" data={errorForm} />}
            <Row>
              <Col xs={6}>
                <Form.Group controlId="firstname">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Votre prénom"
                    value={formData.firstname}
                    size="sm"
                    name="firstname"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="lastname">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Votre nom"
                    size="sm"
                    value={formData.lastname}
                    name="lastname"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="my-2"></Col>
              <Col xs={6}>
                <Form.Group controlId="email">
                  <Form.Label>Adresse email</Form.Label>
                  <Form.Control
                    placeholder="Votre adresse email"
                    size="sm"
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-4 mb-3">
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  type="submit"
                  disabled={isLoading}
                >
                  Enregistrer mes informations
                </Button>
              </Col>
              <Col xs={12} className="mt-0 mb-3">
                <hr />
              </Col>
              <Col xs={12}>
                <Alert variant="secondary">
                  Vous souhaitez <b>modifier votre mot de passe</b> ?{' '}
                  Déconnectez votre compte en{' '}
                  <Alert.Link href="#!" onClick={() => LogOutUserService()}>
                    cliquant ici
                  </Alert.Link>
                  , puis cliquez sur le bouton "Mot de passe oublié".
                </Alert>
              </Col>
              <Col xs={12} className="mt-0 mb-3">
                <hr />
              </Col>
              <Col xs={12}>
                {deleteAsked === true ? (
                  <Alert variant="success">
                    La suppression de votre compte est en cours.
                  </Alert>
                ) : (
                  <Alert variant="warning">
                    Vous souhaitez <b>supprimer votre compte</b> ? Vous pouvez{' '}
                    <Alert.Link href="#!" onClick={() => setShowDelete(true)}>
                      cliquer ici
                    </Alert.Link>
                    , puis valider votre demande pour qu'un membre de l'équipe
                    Tred Union procède à la suppression de votre compte.
                  </Alert>
                )}
              </Col>
              <Offcanvas
                show={showDelete}
                onHide={() => {
                  setShowDelete(false);
                }}
                placement={'end'}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    La suppression de votre compte est définitive.
                    <br />
                    Voulez-vous continuer ?
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Button
                    size="sm"
                    variant="falcon-danger"
                    className="me-2"
                    disabled={isLoading}
                    onClick={() => {
                      handleAskDeleteAcount();
                    }}
                  >
                    Oui, je confirme la suppression de mon compte
                  </Button>
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    Annuler
                  </Button>
                </Offcanvas.Body>
              </Offcanvas>
            </Row>
          </Form>
        </Col>
      )}
    </Row>
  );
};

export default InfosPerso;
