import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ForgotPasswordEmailService } from '../../../services/AuthService';
import FormAlert from '../commons/FormAlert';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import NavbarStandard from '../commons/NavbarStandard';
import bgLogin from '../../../assets/img/tredunion/bg_login.jpeg';
import logoTredUnion from '../../../assets/img/tredunion/logo-TRED-UNION.png';
import FooterStandard from '../commons/FooterStandard';
import Section from '../../common/Section';

const ForgetPassword = () => {
  return <ForgetPasswordForm />;
};

const ForgetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const [login, setLogin] = useState(searchParams.get('login'));
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const ReComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await executeRecaptcha('check_resetpw_form_tred');
        let formDataSubmit = {
          login: login,
          'g-recaptcha-response': token
        };
        const response = await ForgotPasswordEmailService(formDataSubmit);
        if (response.message === 'passwords.sent') {
          setSubmitDisabled(
            `Un email vient d'être envoyé avec un lien de réinitialisation du mot de passe.`
          );
          setErrorForm(false);
        } else if (response.message === 'passwords.throttled') {
          setSubmitDisabled(
            `Vous avez récemment effectué une demande de réinitialisation, veuillez vérifier votre boîte de réception et vos spams.`
          );
          setErrorForm(false);
        } else if (response.validator) {
          setErrorForm(response.validator);
          setIsLoading(false);
        } else if (response.message && response.message === 'Model not found') {
          setErrorForm('Identifiant inconnu');
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
          setIsLoading(false);
        }
      } catch (err) {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    return (
      <div>
        <GoogleReCaptcha
          onVerify={() => {
            return;
          }}
        />
        <Button
          className="w-100"
          onClick={handleSubmit}
          disabled={!login || isLoading}
        >
          Envoyer le lien de réinitialisation
        </Button>
      </div>
    );
  };

  return (
    <>
      <NavbarStandard />
      <Section
        bg="light"
        className="d-flex p-0 mt-0"
        style={{ backgroundImage: `url("${bgLogin}")` }}
      >
        <Row className="flex-center min-vh-100 py-0 mt-lg-0">
          <Col xs={12} lg={7}>
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <p className="text-center d-none d-lg-block">
                  <img
                    src={logoTredUnion}
                    style={{ width: '100%', maxWidth: '175px' }}
                  />
                </p>
                <p className="redHeading text-center mb-0">
                  Réinitialiser mon mot de passe
                </p>
                <p className="text-center">
                  <small>Saisissez votre identifiant ci-dessous :</small>
                </p>
                <Form className="mt-4">
                  {submitDisabled && (
                    <FormAlert variant="success" data={submitDisabled} />
                  )}
                  {errorForm && <FormAlert variant="danger" data={errorForm} />}
                  {!submitDisabled && (
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder={'Identifiant'}
                        value={login}
                        name="login"
                        onChange={({ target }) => setLogin(target.value)}
                        type="text"
                      />
                    </Form.Group>
                  )}

                  {!submitDisabled && (
                    <Form.Group className="mb-3">
                      <GoogleReCaptchaProvider
                        reCaptchaKey="6LePlFwgAAAAAIga3vbKCRndalE_-0ki3eFiw-n3"
                        language="fr-FR"
                      >
                        <ReComponent />
                      </GoogleReCaptchaProvider>
                    </Form.Group>
                  )}

                  <Link className="fs--1 text-600" to={`/login`}>
                    {!submitDisabled
                      ? 'Annuler'
                      : "Retour à l'écran de connexion"}
                  </Link>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>
      <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
        <FooterStandard />
      </div>
    </>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPassword;
