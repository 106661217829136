import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'partners-roles';

export async function IndexPartnerRole() {
  return modelService.IndexModel(`${model}`);
}

export async function PostPartnerRole(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutPartnerRole(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeletePartnerRole(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
