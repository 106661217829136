import React from 'react';
import Section from '../../../common/Section';
import { Col, Image, Row } from 'react-bootstrap';
import bgAdherents from '../../../../assets/img/tredunion/bg_groupement_adherents.jpg';
import illuCamion from '../../../../assets/img/tredunion/illu-camion.png';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const Adherents = ({ companies }) => {
  let navigate = useNavigate();
  const sliderLogosSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    rows: 4,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          rows: 4,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-6"
        id="adherents"
        style={{ backgroundImage: `url("${bgAdherents}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>
              GROUPEMENT <span>Adhérents</span>
            </h2>
          </Col>
        </Row>
      </Section>
      <Section
        bg="light"
        className="d-flex align-items-end p-1"
        id="adherents-bottom"
      >
        <Row>
          <Col className="text-end container-camion d-none d-lg-block">
            <img src={illuCamion} />
          </Col>
        </Row>
      </Section>
      {companies.length && (
        <Section
          bg="light"
          className="py-3 mb-7"
          id="section-slider-full-companies"
        >
          <Slider {...sliderLogosSettings} className="slick-slider-arrow-inner">
            {companies.map((company, index) => (
              <Image
                className="px-2 cursor-pointer"
                fluid
                rounded
                src={company.logo_url}
                alt=""
                key={index}
                onClick={() => {
                  navigate(`/adherents/${company.id}?back=home-adherents`);
                }}
              />
            ))}
          </Slider>
        </Section>
      )}
    </>
  );
};

Adherents.propTypes = {
  companies: PropTypes.array
};

export default Adherents;
