import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import 'react-app-polyfill/ie9';
import is from 'is_js';

const App = () => {
  useEffect(() => {
    console.log('useeffect app');
    console.log(window.location.pathname);
  }, []);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
