import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  startDate: '',
  endDate: '',
  participate: ''
};

export const surveyIdSlice = createSlice({
  name: 'surveyId',
  initialState,
  reducers: {
    // méthodes qui vont changer le state de search
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    changeParticipate: (state, action) => {
      state.participate = action.payload;
    },
    resetFilters: state => {
      state.search = '';
      state.startDate = '';
      state.endDate = '';
      state.participate = '';
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSearch,
  changeStartDate,
  changeEndDate,
  changeParticipate,
  resetFilters
} = surveyIdSlice.actions;

export default surveyIdSlice.reducer;
