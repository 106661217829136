import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'homeslides';

export async function IndexHomeSlide() {
  return modelService.IndexModel(model);
}

export async function ShowHomeSlide(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostHomeSlide(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutHomeSlide(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteHomeSlide(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
