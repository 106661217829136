import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ShowPublicEvaluation } from '../../../../../../services/Elearning/EvaluationService';
import { Col, Row, Table } from 'react-bootstrap';
import SoftBadge from '../../../../../common/SoftBadge';
import { elearningEvaluationQuestionGroups } from '../../../../config';

const ShowEvaluation = () => {
  const { uuid } = useParams();
  const [object, setObject] = useState({});
  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowPublicEvaluation(uuid);
      if (responseObject.success === true) {
        setObject(responseObject.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObject();
  }, []);
  return (
    <>
      {object.id && (
        <>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="mt-4 mb-4">
              <h3 className="admin-title">
                Résultats de l'évaluation{' '}
                {object.course && <>- Formation "{object.course.title}"</>}
              </h3>
              {object.user.id && (
                <div className="smaller-p mb-3">
                  {object.user.firstname} {object.user.lastname}
                  {object.user.main_company && (
                    <SoftBadge bg="primary" className="ms-1 me-2">
                      {object.user.main_company.name}
                    </SoftBadge>
                  )}
                </div>
              )}
              <Table bordered responsive className="admin-table mt-2">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Réponse</th>
                  </tr>
                </thead>
                <tbody>
                  {elearningEvaluationQuestionGroups.map((group, index) => (
                    <Fragment key={index}>
                      <tr key={index}>
                        <td colSpan={2}>
                          <b>{group.groupLabel}</b>
                        </td>
                      </tr>
                      {group.questions.map((question, indexQuestion) => (
                        <tr key={indexQuestion}>
                          <td>{question.label}</td>
                          <td>
                            {question.type === 'integer'
                              ? `${object[question.name]}/10 ${
                                  object[question.hasComment]
                                    ? `(${object[question.hasComment]})`
                                    : ''
                                }`
                              : object[question.name]}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

ShowEvaluation.propTypes = {
  evaluation: PropTypes.any
};

export default ShowEvaluation;
