import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import { useSearchParams } from 'react-router-dom';
import ManageSurveyModal from './ManageModal';
import ManageRecipients from '../events/recipients/Manage';
import { IndexEventSelect } from '../../../../../services/Event/EventService';
import { toast } from 'react-toastify';
import FormAlert from '../../../commons/FormAlert';
import ModalRecap from './ModalRecap';
import {
  DeleteSurvey,
  PostSurvey,
  PutSurvey,
  ShowAdminPanelSurvey
} from '../../../../../services/Survey/SurveyService';
import * as Moment from 'moment/moment';
import Select from 'react-select';
import requests from '../elearning/requests';
import { IndexUserAdmin } from '../../../../../services/UserService';
import { IndexExtRec } from '../../../../../services/ExternalRecipientService';

const ManageSurveys = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showFormSurvey, setShowFormSurvey] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newSurvey, setNewSurvey] = useState(true);
  const [surveyFormIsCompleted, setSurveyFormIsCompleted] = useState(false);
  const [showCanvasDeleteSurvey, setShowCanvasDeleteSurvey] = useState(false);
  let today = new Date();
  let todayPlus30days = new Date();
  todayPlus30days.setDate(today.getDate() + 30);
  const [survey, setSurvey] = useState({
    title: '',
    description: '',
    vimeo_id: '',
    expires_on: todayPlus30days,
    starts_on: Date.now(),
    send_later: 0,
    survey_dunning: 0,
    survey_dunning_type_id: '',
    dunning_admin_copy_email: '',
    dunning_starts_on: Date.now(),
    is_counts_for_points: 1
  });
  const [formData, setFormData] = useState({
    title: '',
    related_event_id: '',
    event_id: '',
    recipient_roles: [],
    recipient_partner_roles: [],
    recipient_notifications: [],
    recipient_companies: [],
    recipient_users: [],
    recipient_externalUsers: [],
    recipient_informativeUsers: []
  });
  const [recipients, setRecipients] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [events, setEvents] = useState(false);
  const [eventsFromApi, setEventsFromApi] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});

  useEffect(() => {
    setRecipients([]);
    const fetchEventAdmin = async () => {
      const responseEventAdmin = await IndexEventSelect(true);
      if (responseEventAdmin.success === true) {
        setEventsFromApi(responseEventAdmin.data);
        let tmpEvents = [];
        responseEventAdmin.data.map(e => {
          tmpEvents.push({
            value: e.id,
            label: `${e.title} - ${
              e.start_date === e.end_date
                ? Moment(e.start_date).local().format('DD/MM/YYYY')
                : `${Moment(e.start_date).local().format('DD/MM')} - ${Moment(
                    e.end_date
                  )
                    .local()
                    .format('DD/MM/YY')}`
            }`
          });
        });
        setEvents(tmpEvents);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchSurvey = async () => {
      setIsLoading(true);
      const responseSurvey = await ShowAdminPanelSurvey(id);
      if (responseSurvey.success === true) {
        setSurvey({
          ...survey,
          id: responseSurvey.data.id,
          title: responseSurvey.data.title,
          description: responseSurvey.data.description,
          vimeo_id: responseSurvey.data.vimeo_id,
          expires_on: responseSurvey.data.expires_on
            ? new Date(responseSurvey.data.expires_on)
            : todayPlus30days,
          starts_on: responseSurvey.data.starts_on
            ? new Date(responseSurvey.data.starts_on)
            : Date.now(),
          send_later: 1,
          survey_dunning: responseSurvey.data.survey_dunning_type_id ? 1 : 0,
          survey_dunning_type_id: responseSurvey.data.survey_dunning_type_id,
          dunning_starts_on: responseSurvey.data.dunning_starts_on
            ? new Date(responseSurvey.data.dunning_starts_on)
            : Date.now(),
          dunning_admin_copy_email:
            responseSurvey.data.dunning_admin_copy_email || '',
          is_counts_for_points: responseSurvey.data.is_counts_for_points,
          attachments: responseSurvey.data.attachments?.map(a => {
            return {
              attachment_url: a.file_url
            };
          })
        });
        let tmpQuestions = [];
        responseSurvey.data.questions
          .filter(q => !q.group_id)
          .map(question =>
            tmpQuestions.push({
              id: question.id,
              title: question.title,
              required: question.required,
              type_slug: question.type ? question.type.slug : 'text',
              answers: question.answers?.map(a => a.title)
            })
          );
        setQuestions(tmpQuestions);
        let tmpGroups = [];
        responseSurvey.data.question_groups.map(group => {
          let questions = [];
          responseSurvey.data.questions
            .filter(q => q.group_id && q.group_id === group.id)
            .map(q => {
              questions.push({
                id: q.id,
                title: q.title,
                required: q.required,
                type_slug: q.type ? q.type.slug : 'text',
                answers: q.answers?.map(a => a.title)
              });
            });
          tmpGroups.push({
            id: group.id,
            title: group.title,
            description: group.description,
            color: group.color,
            questions: questions
          });
        });
        setQuestionGroups(tmpGroups);
        setSurveyFormIsCompleted(true);
        let recipientRolesArray = [];
        responseSurvey.data.role_recipients.map(role => {
          recipientRolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        let recipientPartnerRolesArray = [];
        responseSurvey.data.partner_role_recipients.map(role => {
          recipientPartnerRolesArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        let recipientNotificationsArray = [];
        responseSurvey.data.notification_recipients.map(role => {
          recipientNotificationsArray.push({
            value: `${role.id}`,
            label: role.title
          });
        });
        let recipientCompaniesArray = [];
        responseSurvey.data.company_recipients.map(company => {
          recipientCompaniesArray.push({
            value: `${company.id}`,
            label: company.name
          });
        });
        let recipientUsersArray = [];
        responseSurvey.data.internal_recipients.map(user => {
          recipientUsersArray.push({
            value: `${user.id}`,
            label:
              user.partner && user.partner.id
                ? `${user.email} - PARTENAIRE - ${user.partner.name}`
                : `${user.lastname} ${user.firstname} ${
                    user.main_company ? ` - ${user.main_company.name}` : ''
                  }`
          });
        });
        let recipientExternalUsersArray = [];
        responseSurvey.data.external_recipients.map(external => {
          recipientExternalUsersArray.push({
            value: external.id,
            label: `${external.company_name || ''} ${external.lastname || ''} ${
              external.firstname || ''
            } ${external.email}`
          });
        });
        let tmpRecipients = [];
        if (responseSurvey.data.internal_recipients) {
          responseSurvey.data.internal_recipients.map(recipient =>
            tmpRecipients.push(recipient)
          );
        }
        if (responseSurvey.data.external_recipients) {
          responseSurvey.data.external_recipients.map(recipient =>
            tmpRecipients.push(recipient)
          );
        }
        setRecipients(tmpRecipients);
        setFormData({
          ...formData,
          title: responseSurvey.data.title,
          recipient_roles: recipientRolesArray,
          recipient_partner_roles: recipientPartnerRolesArray,
          recipient_notifications: recipientNotificationsArray,
          recipient_companies: recipientCompaniesArray,
          recipient_users: recipientUsersArray,
          recipient_externalUsers: recipientExternalUsersArray,
          event_id: responseSurvey.data.attachable_id
        });
        if (responseSurvey.data.attachable_id) {
          setSelectedEvent({
            value: responseSurvey.data.attachable.id,
            label: `${responseSurvey.data.attachable.title} - ${
              responseSurvey.data.attachable.start_date ===
              responseSurvey.data.attachable.end_date
                ? Moment(responseSurvey.data.attachable.start_date)
                    .local()
                    .format('DD/MM/YYYY')
                : `${Moment(responseSurvey.data.attachable.start_date)
                    .local()
                    .format('DD/MM')} - ${Moment(
                    responseSurvey.data.attachable.end_date
                  )
                    .local()
                    .format('DD/MM/YY')}`
            }`
          });
        }
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchEventAdmin();
    if (id) {
      setNewSurvey(false);
      fetchSurvey();
    }
    if (
      searchParams.get('internal_recipients') ||
      searchParams.get('external_recipients')
    ) {
      let recipientUsersArray = [];
      let recipientExternalsArray = [];
      const fetchUsers = async () => {
        const responseUsers = await IndexUserAdmin();
        if (responseUsers.success === true) {
          const responseExternals = await IndexExtRec();
          if (responseExternals.success === true) {
            if (searchParams.get('internal_recipients')) {
              responseUsers.data
                .filter(user =>
                  searchParams
                    .get('internal_recipients')
                    .split(',')
                    .includes(`${user.id}`)
                )
                .map(user => {
                  recipientUsersArray.push({
                    value: `${user.id}`,
                    label:
                      user.partner && user.partner.id
                        ? `${user.email} - PARTENAIRE - ${user.partner.name}`
                        : `${user.lastname} ${user.firstname} ${
                            user.main_company
                              ? ` - ${user.main_company.name}`
                              : ''
                          }`
                  });
                });
            }
            if (searchParams.get('external_recipients')) {
              responseExternals.data
                .filter(user =>
                  searchParams
                    .get('external_recipients')
                    .split(',')
                    .includes(`${user.id}`)
                )
                .map(user => {
                  recipientExternalsArray.push({
                    value: `${user.id}`,
                    label: `${user.company_name || ''} ${user.lastname || ''} ${
                      user.firstname || ''
                    } ${user.email}`
                  });
                });
            }
            let tmpRecipients = [];
            if (searchParams.get('internal_recipients')) {
              responseUsers.data
                .filter(user =>
                  searchParams
                    .get('internal_recipients')
                    .split(',')
                    .includes(`${user.id}`)
                )
                .map(recipient => tmpRecipients.push(recipient));
            }
            if (searchParams.get('external_recipients')) {
              responseUsers.data
                .filter(user =>
                  searchParams
                    .get('external_recipients')
                    .split(',')
                    .includes(`${user.id}`)
                )
                .map(recipient => tmpRecipients.push(recipient));
            }
            setRecipients(tmpRecipients);
            setFormData({
              ...formData,
              recipient_users: recipientUsersArray,
              recipient_externalUsers: recipientExternalsArray,
              title: searchParams.get('name'),
              event_id: searchParams.get('event_id')
            });
          }
        }
      };
      fetchUsers();
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    let tmpFormData = { ...formData };
    tmpFormData.survey = survey;
    tmpFormData.survey.title = formData.title;
    tmpFormData.survey.questions = questions;
    tmpFormData.survey.questionGroups = questionGroups;
    if (survey.starts_on) {
      tmpFormData.survey.starts_on = Moment(survey.starts_on)
        .local()
        .set('hour', 1)
        .set('minute', 1)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (survey.expires_on) {
      tmpFormData.survey.expires_on = Moment(survey.expires_on)
        .local()
        .set('hour', 23)
        .set('minute', 50)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (survey.dunning_starts_on) {
      tmpFormData.survey.dunning_starts_on = Moment(survey.dunning_starts_on)
        .local()
        .set('hour', 1)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      tmpFormData.survey.dunning_starts_on = Moment()
        .local()
        .set('hour', 1)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    let response = null;
    if (id) {
      tmpFormData.context = 'admin.update';
      response = await PutSurvey(id, tmpFormData);
    } else {
      tmpFormData.context = 'admin.store';
      response = await PostSurvey(tmpFormData);
    }
    if (response.success === true) {
      let success = 'Contenu enregistré';
      toast(success);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);
      setTimeout(function () {
        window.location.href = `/administration/questionnaires/${response.data.id}`;
      }, 1500);
    } else if (response.validator) {
      setIsLoading(false);
      setErrorForm(response.validator);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);
    } else {
      toast('Une erreur est survenue');
    }
  };

  const deleteSurvey = async () => {
    const response = await DeleteSurvey(id);
    if (response.success === true) {
      setShowCanvasDeleteSurvey(false);
      toast('Questionnaire supprimé');
      setTimeout(function () {
        window.location.href = '/administration/questionnaires';
      }, 1000);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {survey.id && (
        <Offcanvas
          show={showCanvasDeleteSurvey}
          onHide={() => {
            setShowCanvasDeleteSurvey(false);
          }}
          placement="end"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Suppression du questionnaire</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            La suppression du questionnaire est définitive. Les invitations
            seront annulées mais les participants ne seront pas automatiquement
            notifiés.
            <br /> Voulez-vous continuer ?
            <Row className="mt-4 flex-start">
              {' '}
              <Col lg="12">
                <Button
                  size="sm"
                  className="admin-primary me-2"
                  variant="falcon-danger"
                  onClick={() => {
                    deleteSurvey();
                  }}
                >
                  Oui, supprimer le questionnaire
                </Button>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="admin-primary"
                  onClick={() => {
                    setShowCanvasDeleteSurvey(false);
                  }}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                {id ? 'Modifier' : 'Créer'} un questionnaire
              </h3>
            </Col>
            <Col lg={3} className="d-flex justify-content-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  if (searchParams.get('back')) {
                    navigate(searchParams.get('back'));
                  } else {
                    navigate('/administration/questionnaires');
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  style={{ marginBottom: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                </svg>
                Annuler
              </Button>
              {id && (
                <Button
                  size="sm"
                  variant="falcon-danger"
                  className="me-2"
                  onClick={() => {
                    setShowCanvasDeleteSurvey(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    style={{
                      marginBottom: '2px',
                      marginRight: '3px'
                    }}
                  >
                    <path
                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                      fill="rgba(237,47,47,1)"
                    />
                  </svg>
                  Supprimer
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {errorForm && (
        <div className="mt-3">
          <FormAlert variant="warning" data={errorForm} />
        </div>
      )}
      {isLoading === true ? (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <Card className="mt-4">
          <Card.Body className="overflow-hidden px-lg-3">
            <Row>
              <Col md="12">
                <Form.Group controlId="name">
                  <Form.Label>Titre du questionnaire :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Titre du questionnaire"
                    value={formData.title || ''}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        title: e.target.value
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md="12" className="pb-4 pt-4">
                <div className="border-dashed-bottom"></div>
              </Col>
              {events.length > 0 && (
                <>
                  <Col md="12" className="mt-0">
                    <Row>
                      <Col md="12">
                        <Form.Group controlId="company_id">
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Événement concerné{' '}
                          </Form.Label>{' '}
                          <small>(facultatif)</small>
                          <Select
                            closeMenuOnSelect={true}
                            deleteRemoves={true}
                            options={events}
                            placeholder="Choisir un événement..."
                            name="event_id"
                            classNamePrefix="react-select"
                            value={selectedEvent}
                            onChange={object => {
                              setFormData({
                                ...formData,
                                event_id: parseInt(object.value)
                              });
                              setSelectedEvent(object);
                            }}
                          />
                          {formData.event_id && eventsFromApi.length > 0 && (
                            <>
                              <a
                                className="cursor-pointer ps-1 d-block mt-1"
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    event_id: ''
                                  });
                                  setSelectedEvent({});
                                }}
                              >
                                <small>x Retirer l'événement</small>
                              </a>
                            </>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12" className="pb-3 mb-2 pt-3 mt-3">
                    <div className="border-dashed-bottom"></div>
                  </Col>
                </>
              )}
            </Row>
            <ManageRecipients formData={formData} setFormData={setFormData} />
            <ManageSurveyModal
              showFormSurvey={showFormSurvey}
              setShowFormSurvey={setShowFormSurvey}
              questions={questions}
              setQuestions={setQuestions}
              questionGroups={questionGroups}
              setQuestionGroups={setQuestionGroups}
              survey={survey}
              setSurvey={setSurvey}
              recipients={recipients}
              isQuestionnaire={true}
              newSurvey={newSurvey}
            />
            <Row className="mb-4">
              <Col md="12" className="pb-3 mb-1 pt-1">
                <div className="border-dashed-bottom"></div>
              </Col>
              <Col xs={12}>
                <Form.Label>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="17"
                    height="17"
                    style={{ marginRight: '4px', marginBottom: '2px' }}
                  >
                    <path
                      d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391 1 1.732-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805-1 1.732-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392-1-1.732.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805 1-1.732 1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                  Gestion du questionnaire
                </Form.Label>
              </Col>
              <Col xs={12} className="pt-2">
                <Button
                  size="sm"
                  variant="falcon-default"
                  onClick={() => {
                    setShowFormSurvey(true);
                    setSurveyFormIsCompleted(true);
                    setSurvey({ ...survey, isDeleted: false });
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    {surveyFormIsCompleted === true ? (
                      <path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z" />
                    ) : (
                      <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                    )}
                  </svg>
                  {surveyFormIsCompleted === true
                    ? 'Modifier le questionnaire'
                    : 'Configurer le questionnaire'}
                </Button>
              </Col>
              <Col md="12" className="pb-3 pt-4">
                <div className="border-dashed-bottom"></div>
              </Col>
              <Col xs="12" className="d-flex mb-0 mt-3">
                <Button
                  variant="primary"
                  className="admin-primary"
                  size="sm"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>{' '}
                  Enregistrer le questionnaire
                </Button>
              </Col>
            </Row>
            <ModalRecap
              modalShow={modalShow}
              setModalShow={setModalShow}
              formData={formData}
              survey={survey}
              questions={questions}
              surveyFormIsCompleted={surveyFormIsCompleted}
              id={id}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ManageSurveys;
