// write a basic component that will be used to validate points

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Offcanvas, Table } from 'react-bootstrap';
import Background from '../../../../common/Background';
import corner1 from '../../../../../assets/img/illustrations/corner-1.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PutEvent,
  PutEventManagePoints
} from '../../../../../services/Event/EventService';
import { toast } from 'react-toastify';
import SoftBadge from '../../../../common/SoftBadge';
import { IndexEventTypology } from '../../../../../services/Point/PointTypologyService';
import { IndexAdmin } from '../../../../../services/CompanyService';

const ValidatePoints = ({ event, recipents, setParentTrigger }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [showValidate, setShowValidate] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pointTypologies, setPointTypologies] = useState([]);
  const [filterParticipate, setFilterParticipate] = useState('');
  const [formPoints, setFormPoints] = useState({
    companies: []
  });
  useEffect(async () => {
    const responseCompanies = await IndexAdmin();
    if (responseCompanies.success === true) {
      let tmpCompanies = [];
      recipents.map(recipient => {
        let mainQuestion = recipient.survey_questions.find(
          q =>
            event.participation_form &&
            q.attachable_id === event.participation_form.id &&
            q.attachable_type.includes('Survey') &&
            q.is_participation_form_main_question === 1
        );
        if (
          (recipient.main_company && recipient.main_company.id !== null) ||
          recipient.company_name !== null
        ) {
          if (
            !tmpCompanies.some(
              c =>
                c.id === recipient.main_company?.id ||
                c.name === recipient.company_name
            )
          ) {
            if (
              filterParticipate === 'Participe' &&
              mainQuestion.pivot.value === 'Oui'
            ) {
              if (recipient.main_company) {
                tmpCompanies.push(recipient.main_company);
              } else {
                let c = responseCompanies.data?.find(
                  c => c.name === recipient.company_name
                );
                if (c) {
                  tmpCompanies.push(c);
                }
              }
            } else if (
              filterParticipate === 'Ne participe pas' &&
              mainQuestion.pivot.value === 'Non'
            ) {
              if (recipient.main_company) {
                tmpCompanies.push(recipient.main_company);
              } else {
                let c = responseCompanies.data?.find(
                  c => c.name === recipient.company_name
                );
                if (c) {
                  tmpCompanies.push(c);
                }
              }
            } else if (filterParticipate === '') {
              if (recipient.main_company) {
                tmpCompanies.push(recipient.main_company);
              } else {
                let c = responseCompanies.data?.find(
                  c => c.name === recipient.company_name
                );
                if (c) {
                  tmpCompanies.push(c);
                }
              }
            }
          }
        }
      });
      tmpCompanies.map(company => {
        if (company) {
          company.recipents = [];
          recipents.map(recipient => {
            let mainQuestion = recipient.survey_questions.find(
              q =>
                event.participation_form &&
                q.attachable_id === event.participation_form.id &&
                q.attachable_type.includes('Survey') &&
                q.is_participation_form_main_question === 1
            );
            if (
              (recipient.main_company && recipient.main_company.id !== null) ||
              recipient.company_name !== null
            ) {
              if (
                (recipient.main_company &&
                  recipient.main_company.id === company.id) ||
                recipient.company_name === company.name
              ) {
                if (
                  filterParticipate === 'Participe' &&
                  mainQuestion.pivot.value === 'Oui'
                ) {
                  company.recipents.push(recipient);
                } else if (
                  filterParticipate === 'Ne participe pas' &&
                  mainQuestion.pivot.value === 'Non'
                ) {
                  company.recipents.push(recipient);
                } else if (filterParticipate === '') {
                  company.recipents.push(recipient);
                }
              }
            }
          });
        } else {
          console.log(tmpCompanies, 'tmpCompanies');
        }
      });
      setCompanies(
        tmpCompanies.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        })
      );
    }
    const fetchTypologies = async () => {
      const responseObjects = await IndexEventTypology();
      if (responseObjects.success === true) {
        setPointTypologies(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchTypologies();
  }, [recipents, trigger]);

  const ignorePointValidation = async () => {
    const response = await PutEventManagePoints(event.id);
    if (response.success === true) {
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const markAsAbsent = async user => {
    let form = {
      context: 'admin.markrecipientabsent',
      recipient: user.id,
      attachable_type: user.type === 'external' ? 'external' : 'user'
    };
    let response = await PutEvent(event.id, form);
    if (response.success === true) {
      setParentTrigger(Date.now());
      setTrigger(Date.now());
      toast('Information enregistrée');
    } else {
      setTrigger(Date.now());
      setParentTrigger(Date.now());
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const submitPointValidation = async () => {
    setIsSubmitting(true);
    const response = await PutEventManagePoints(event.id, formPoints);
    setShowValidate(false);
    if (response.success === true) {
      setParentTrigger(Date.now());
      toast('Action enregistrée');
      setTimeout(function () {
        window.location.reload();
      }, 500);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsSubmitting(false);
  };

  const handleRadioCheck = e => {
    let tmpFormPoints = { ...formPoints };
    const actionArray = e.target.id.split('_');
    const companyId = actionArray[1];
    const typologyId = actionArray[3];
    if (!tmpFormPoints.companies.find(c => c.id === companyId)) {
      tmpFormPoints.companies.push({
        id: companyId,
        typologyId: typologyId === 'nothing' ? '' : typologyId
      });
    } else {
      tmpFormPoints.companies[
        tmpFormPoints.companies.findIndex(c => c.id === companyId)
      ] = {
        id: companyId,
        typologyId: typologyId === 'nothing' ? '' : typologyId
      };
    }
    setFormPoints(tmpFormPoints);
  };

  return (
    <>
      <Col xs={6} className="pb-3">
        <Card className={`overflow-hidden`}>
          <Background image={corner1} className="p-card bg-card" />
          <Card.Body className="position-relative">
            <h5
              className={event.is_points_managed === 0 ? 'text-warning' : ''}
              style={{ fontFamily: 'Open Sans' }}
            >
              {event.is_points_managed === 1 ? (
                <>
                  <FontAwesomeIcon
                    icon="check"
                    className="me-2"
                    style={{ color: 'green' }}
                  />
                  Validation des points et absences <small>déjà effectué</small>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon="exclamation-circle"
                    className="me-2"
                    style={{ color: '#F5803E' }}
                  />
                  À faire - validation des points et absences
                </>
              )}
            </h5>
            <p className="fs--1 mb-0">
              L'événement est passé, il vous reste à valider les points pour les
              participants.
              <br />
              Cliquez sur <b>valider les points et absences</b> ci-dessous pour
              accéder au panneau de validation, ou <b>ignorer</b> pour ne plus
              voir ce message sur cet événement.
            </p>
            <Button
              as={Link}
              variant="link"
              className="fs--1 text-warning mt-lg-3 ps-0"
              to="#!"
              onClick={() => {
                setShowValidate(true);
              }}
            >
              Valider les points et absences
              <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
            </Button>
            {/*<div className="d-block mt-0"></div>
            <Button
              as={Link}
              variant="link"
              className="fs--1 text-muted ps-0"
              to="#!"
              onClick={() => {
                ignorePointValidation();
              }}
            >
              Ignorer
            </Button>*/}
          </Card.Body>
        </Card>
      </Col>
      <Offcanvas
        placement={'end'}
        style={{ width: 950 }}
        show={showValidate}
        onHide={() => {
          setShowValidate(false);
          window.location.reload();
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Valider les points et absences</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {companies.length > 0 && pointTypologies.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <thead>
                <tr>
                  <th scope="col">Entreprise</th>
                  <th scope="col">
                    {event.participation_form && (
                      <Form.Select
                        aria-label="Par réponse"
                        size="xs"
                        className="mb-2"
                        name="participate"
                        value={filterParticipate}
                        onChange={e => {
                          setFilterParticipate(e.target.value);
                          setTrigger(Date.now());
                        }}
                      >
                        <option value="">Filtrer...</option>
                        <option value="Participe">Participe</option>
                        <option value="Ne participe pas">
                          Ne participe pas
                        </option>
                      </Form.Select>
                    )}
                    Invité(s)
                  </th>
                  <th scope="col">Points</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                  <tr key={index}>
                    <td>{company.name}</td>
                    <td>
                      {company.recipents.map((recipient, index) => (
                        <div key={index}>
                          {recipient.firstname} {recipient.lastname}
                          {event.participation_form &&
                            event.participation_form.id &&
                            recipient.survey_questions &&
                            recipient.survey_questions.length > 0 && (
                              <>
                                {recipient.survey_questions.find(
                                  q =>
                                    q.attachable_id ===
                                      event.participation_form.id &&
                                    q.attachable_type.includes('Survey') &&
                                    q.is_participation_form_main_question === 1
                                ) && (
                                  <span className="text-muted">
                                    {recipient.survey_questions.find(
                                      q =>
                                        q.attachable_id ===
                                          event.participation_form.id &&
                                        q.attachable_type.includes('Survey') &&
                                        q.is_participation_form_main_question ===
                                          1
                                    ).pivot.value === 'Oui' ? (
                                      <>
                                        <SoftBadge
                                          bg="primary"
                                          className="ms-1"
                                        >
                                          Participe
                                        </SoftBadge>
                                        {recipient.pivot
                                          .did_not_come_to_event === 1 && (
                                          <SoftBadge
                                            bg="danger"
                                            className="ms-1"
                                          >
                                            Ne s'est pas présenté
                                          </SoftBadge>
                                        )}
                                        <span
                                          className="smaller-p mb-3 mt-1 text-muted d-block"
                                          onClick={() => {
                                            markAsAbsent(recipient);
                                          }}
                                        >
                                          <small
                                            className="cursor-pointer"
                                            style={{
                                              lineHeight: '12px',
                                              display: 'block'
                                            }}
                                          >
                                            {recipient.pivot &&
                                            recipient.pivot
                                              .did_not_come_to_event === 1
                                              ? '+ Marquer comme présent'
                                              : 'x Marquer comme absent'}
                                          </small>
                                        </span>
                                      </>
                                    ) : (
                                      <SoftBadge bg="warning" className="ms-1">
                                        Ne participe pas
                                      </SoftBadge>
                                    )}
                                  </span>
                                )}
                              </>
                            )}
                        </div>
                      ))}
                    </td>
                    <td>
                      <Form.Check
                        inline
                        key={index}
                        type="radio"
                        id={`company_${company.id}_typology_nothing`}
                        value={0}
                        defaultChecked={true}
                        label={<div className="text-muted">Ne rien faire</div>}
                        name={`company_${company.id}_typology`}
                        className="d-block mb-0"
                        onChange={e => {
                          handleRadioCheck(e);
                        }}
                      />
                      {pointTypologies.map((typology, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="radio"
                          id={`company_${company.id}_typology_${typology.id}`}
                          value={typology.id}
                          label={
                            <div
                              className={
                                typology.points > 0
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              {typology.name}{' '}
                              <SoftBadge
                                pill
                                bg={typology.points > 0 ? 'success' : 'danger'}
                                className=""
                              >
                                {typology.points > 0 ? '+ ' : ''}
                                {typology.points}
                              </SoftBadge>
                            </div>
                          }
                          name={`company_${company.id}_typology`}
                          className="d-block mb-0"
                          onChange={e => {
                            handleRadioCheck(e);
                          }}
                        />
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              submitPointValidation();
            }}
            className="me-2 admin-primary"
          >
            Enregistrer
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

// write PropTypes for ValidatePoints
ValidatePoints.propTypes = {
  event: PropTypes.object.isRequired,
  recipents: PropTypes.array.isRequired,
  setParentTrigger: PropTypes.func.isRequired
};

export default ValidatePoints;
