import { Col, Tab, Tabs } from 'react-bootstrap';
import Questions from './Questions';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const QuestionsContainer = ({
  questions,
  questionGroups,
  setQuestions,
  setQuestionGroups,
  hideOtherQuestions,
  setHideOtherQuestions,
  companies,
  setCompanies,
  isLoading,
  isExport,
  title,
  event
}) => {
  useEffect(() => {});
  return (
    <>
      {companies && companies.length > 0 && !isExport ? (
        <Col xs={12}>
          <Tabs
            variant="pills"
            defaultActiveKey={`tab-company-${companies[0].id}`}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {companies.map((company, indexCompany) => (
              <Tab
                key={indexCompany}
                eventKey={`tab-company-${company.id}`}
                title={company.name}
                className=""
              >
                <Questions
                  questions={questions}
                  questionGroups={questionGroups}
                  setQuestions={setQuestions}
                  setQuestionGroups={setQuestionGroups}
                  hideOtherQuestions={hideOtherQuestions}
                  setHideOtherQuestions={setHideOtherQuestions}
                  isLoading={isLoading}
                  company={company}
                  companies={companies}
                  setCompanies={setCompanies}
                  event={event}
                />
              </Tab>
            ))}
          </Tabs>
        </Col>
      ) : (
        <>
          <Questions
            questions={questions}
            questionGroups={questionGroups}
            setQuestions={setQuestions}
            setQuestionGroups={setQuestionGroups}
            hideOtherQuestions={hideOtherQuestions}
            setHideOtherQuestions={setHideOtherQuestions}
            isLoading={isLoading}
            isExport={isExport}
            title={title}
            event={event}
          />
        </>
      )}
    </>
  );
};

QuestionsContainer.propTypes = {
  questions: PropTypes.array.isRequired,
  questionGroups: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setQuestionGroups: PropTypes.func.isRequired,
  hideOtherQuestions: PropTypes.bool.isRequired,
  setHideOtherQuestions: PropTypes.func.isRequired,
  company: PropTypes.object,
  companies: PropTypes.array,
  setCompanies: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isExport: PropTypes.bool,
  title: PropTypes.string,
  event: PropTypes.object
};

export default QuestionsContainer;
