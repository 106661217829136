import React, { Fragment, useEffect, useState } from 'react';
import { PostContractMeta } from '../../../../../services/Contract/ContractMetaService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import FormAlert from '../../../commons/FormAlert';
import moment, { now } from 'moment';
import FileUploader from '../../../commons/fileUploader';

const MetaManage = ({
  setParentTrigger,
  contract,
  metaKey,
  value,
  type = '',
  isEditable = true,
  readOnly = false
}) => {
  const [formMeta, setFormMeta] = useState({
    contract_id: '',
    key: '',
    value: ''
  });
  const [errorForm, setErrorForm] = useState(false);
  const [showEditMeta, setShowEditMeta] = useState(false);
  const handlePostMeta = async e => {
    if (e) {
      e.preventDefault();
    }
    setErrorForm(false);
    let response = await PostContractMeta(formMeta);
    if (response.validator) {
      setErrorForm(response.validator);
    }
    if (response.success === true) {
      setParentTrigger(Date.now());
      toast('Donnée enregistrée');
      setFormMeta({
        contract_id: '',
        key: '',
        value: ''
      });
      setShowEditMeta(false);
    } else {
      toast('Une erreur est survenu, veuillez réessayer plus tard');
    }
  };
  useEffect(() => {
    if (
      formMeta.key.includes('etat_facture_caa_rfa_') ||
      formMeta.key.includes('facture_import_caa_rfa_') ||
      formMeta.key.includes('relance_facture_caa_rfa_') ||
      formMeta.key.includes('etat_facture_salon_') ||
      formMeta.key.includes('facture_import_salon_') ||
      formMeta.key.includes('relance_facture_salon_')
    ) {
      handlePostMeta();
    }
  }, [formMeta]);
  const getDiff = (value, prefix) => {
    if (value) {
      const date = moment(value);
      const now = moment();
      let diff = now.diff(date);
      let duration = moment.duration(diff);
      let years = duration.years();
      let months = duration.months();
      let days = duration.days();
      if (years === 0 && months === 0 && days === 0) {
        if (prefix === ' - relancé il y a') return ` - relancé aujourd'hui`;
        return `envoyé aujourd'hui`;
      }
      return `${prefix} ${years > 0 ? `${years} ans ` : ''}${
        months > 0 ? `${months} mois ` : ''
      }${days} j`;
    }
  };
  const checkIsDoubleClickable = () => {
    if (readOnly === true) return false;
    if (
      isEditable === false /*||
      contract.metas?.find(meta => meta.key === 'dereferencement_date')*/
    ) {
      return false;
    }
    if (metaKey.includes('montant_caa_') || metaKey.includes('montant_rfa_')) {
      let metaYear = metaKey.split('_')[2];
      if (contract[`date_paiement_caa_rfa_${metaYear}`]) {
        return false;
      }
    }
    if (metaKey.includes('montant_salon_')) {
      let metaYear = metaKey.split('_')[2];
      if (contract[`date_paiement_salon_${metaYear}`]) {
        return false;
      }
    }
    return true;
  };
  return (
    <Fragment>
      {metaKey.includes('facture_import_caa_rfa_') ||
      metaKey.includes('facture_import_salon_') ||
      (value !== '' && value !== null && value !== undefined) ? (
        <span
          onClick={() => {
            if (contract['dereferencement_date']) {
              // return;
            }
            if (
              (metaKey.includes('etat_facture_caa_rfa_') ||
                metaKey.includes('etat_facture_salon_')) &&
              value === '1'
            ) {
              setFormMeta({
                contract_id: contract.id,
                key: metaKey,
                value: '2'
              });
            }
          }}
          onDoubleClick={() => {
            if (!checkIsDoubleClickable()) return;
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
              selection.removeAllRanges();
            }
            setFormMeta({
              contract_id: contract.id,
              key: metaKey,
              value: contract[metaKey]
            });
            setShowEditMeta(true);
          }}
        >
          {type === 'date' ? (
            moment(value).format('DD/MM/YYYY')
          ) : metaKey.includes('etat_facture_caa_rfa_') ||
            metaKey.includes('etat_facture_salon_') ? (
            value === '1' ? (
              <span className="d-block text-center cursor-pointer">
                <b className="d-block">Demander une facture</b>
              </span>
            ) : value === '2' ? (
              <span className="d-block text-center">
                <b className="d-block">Facture à déposer</b>
              </span>
            ) : value === '3' ? (
              <span className="d-block text-center">
                <b className="d-block mb-3">
                  Impayée (
                  {getDiff(
                    (metaKey.includes('etat_facture_caa_rfa_') &&
                      contract[
                        'date_depot_facture_caa_rfa_' + metaKey.split('_')[4]
                      ]) ||
                      (metaKey.includes('etat_facture_salon_') &&
                        contract[
                          'date_depot_facture_salon_' + metaKey.split('_')[3]
                        ]),
                    'depuis'
                  )}{' '}
                  )
                </b>
                <span
                  className="cursor-pointer d-block mb-2 text-decoration-underline"
                  onClick={() => {
                    setFormMeta({
                      contract_id: contract.id,
                      key: metaKey,
                      value: '2'
                    });
                  }}
                >
                  Annuler et redéposer la facture
                </span>
                <span
                  className="cursor-pointer d-block mb-2 text-decoration-underline"
                  onClick={() => {
                    if (metaKey.includes('etat_facture_caa_rfa_')) {
                      setFormMeta({
                        contract_id: contract.id,
                        key: `relance_facture_caa_rfa_${metaKey.split('_')[4]}`,
                        value: moment().format('YYYY-MM-DD')
                      });
                    } else if (metaKey.includes('etat_facture_salon_')) {
                      setFormMeta({
                        contract_id: contract.id,
                        key: `relance_facture_salon_${metaKey.split('_')[3]}`,
                        value: moment().format('YYYY-MM-DD')
                      });
                    }
                  }}
                >
                  Relancer{' '}
                  {getDiff(
                    (metaKey.includes('etat_facture_caa_rfa_') &&
                      contract[
                        'relance_facture_caa_rfa_' + metaKey.split('_')[4]
                      ]) ||
                      (metaKey.includes('etat_facture_salon_') &&
                        contract[
                          'relance_facture_salon_' + metaKey.split('_')[3]
                        ]),
                    ' - relancé il y a'
                  )}
                </span>
              </span>
            ) : value === '4' ? (
              <span className="d-block text-center">
                <b className="d-block">Payée</b>
              </span>
            ) : value === '5' ? (
              <span className="d-block text-center">
                <b className="d-block">Annulée</b>
              </span>
            ) : (
              ''
            )
          ) : (metaKey.includes('facture_import_caa_rfa_') ||
              metaKey.includes('facture_import_salon_')) &&
            contract['etat_facture_caa_rfa_' + metaKey.split('_')[4]] &&
            contract['etat_facture_caa_rfa_' + metaKey.split('_')[4]] !== '1' &&
            (metaKey.includes('facture_import_caa_rfa_') ||
              (contract['etat_facture_salon_' + metaKey.split('_')[3]] &&
                contract['etat_facture_salon_' + metaKey.split('_')[3]] !==
                  '1')) ? (
            <span>
              {!value ? (
                <>
                  {contract['facturation_email'] !== '' ? (
                    <>
                      <FileUploader
                        pathToUpload="contracts"
                        formData={formMeta}
                        setFormData={setFormMeta}
                        format="any"
                        databaseField="value"
                        cropperEnabled={false}
                        progressBar={true}
                      />
                      {formMeta.value && (
                        <span
                          onClick={() => {
                            setFormMeta({
                              ...formMeta,
                              key: metaKey,
                              contract_id: contract.id
                            });
                          }}
                          className="d-block text-center cursor-pointer text-decoration-underline"
                        >
                          <b className="d-block">Envoyer le fichier</b>
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="d-block text-center">
                      <b className="d-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          fill="red"
                          style={{ position: 'relative', top: '-2px' }}
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path>
                        </svg>{' '}
                        Aucun email de facturation
                      </b>
                    </span>
                  )}
                </>
              ) : (
                <a href={value} target="_blank" rel="noreferrer">
                  {value?.toString()?.split('\\')?.pop()?.split('/')?.pop()}
                </a>
              )}
            </span>
          ) : (
            value
              ?.toString()
              ?.split('\n')
              .map((line, index) => (
                <span key={index}>
                  {' '}
                  {line} <br />{' '}
                </span>
              ))
          )}
        </span>
      ) : (
        <span>
          {metaKey.includes('etat_contrat') ? (
            <span>
              {contract.dereferencement_date
                ? 'Déréférencé le ' +
                  moment(contract.dereferencement_date).format('DD/MM/YYYY')
                : contract.date_debut_contrat &&
                  contract.date_fin_contrat &&
                  new Date(contract.date_debut_contrat) < new Date() &&
                  new Date(contract.date_fin_contrat) > new Date()
                ? 'En cours de validité'
                : contract.date_fin_contrat &&
                  new Date(contract.date_fin_contrat) < new Date()
                ? 'Expiré'
                : ''}
            </span>
          ) : metaKey.includes('montant_ht_total_') ? (
            <span>
              {contract['montant_caa_' + metaKey.split('_')[3]] +
                contract['montant_rfa_' + metaKey.split('_')[3]]}
            </span>
          ) : (
            <span
              onDoubleClick={() => {
                if (!checkIsDoubleClickable()) return;
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                  selection.removeAllRanges();
                }
                setFormMeta({
                  contract_id: contract.id,
                  key: metaKey,
                  value: contract[metaKey]
                });
                setShowEditMeta(true);
              }}
              style={{ display: 'block', width: '150px', height: '45px' }}
            ></span>
          )}
        </span>
      )}
      <Offcanvas
        placement={'bottom'}
        show={showEditMeta}
        onEnter={() => {
          document.getElementById('meta-value')?.focus();
        }}
        onHide={() => {
          setShowEditMeta(false);
          setFormMeta({
            contract_id: '',
            key: '',
            value: ''
          });
        }}
      >
        <div className="container">
          <Offcanvas.Header closeButton className="pb-0">
            <Offcanvas.Title>Modifier la donnée</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="">
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Form onSubmit={handlePostMeta}>
              <Form.Group controlId="firstname">
                <Form.Label>Valeur :</Form.Label>
                {metaKey === 'partenaire' ? (
                  <Form.Select
                    aria-label="Partenaire"
                    name="value"
                    value={formMeta.value}
                    onChange={e => {
                      setFormMeta({
                        ...formMeta,
                        value: e.target.value
                      });
                    }}
                  >
                    <option value="">Choisir...</option>
                    {contract.partner?.users
                      ?.filter(
                        user =>
                          user.lastname !== '' &&
                          user.lastname !== null &&
                          user.lastname !== undefined
                      )
                      .map((u, index) => (
                        <option value={u.id} key={index}>
                          {u.lastname} {u.firstname}
                        </option>
                      ))}
                  </Form.Select>
                ) : type === 'date' ? (
                  <Form.Control
                    type="date"
                    placeholder="Valeur"
                    name="value"
                    id="meta-value"
                    value={formMeta.value}
                    onChange={e => {
                      setFormMeta({
                        ...formMeta,
                        value: e.target.value
                      });
                    }}
                  />
                ) : type === 'number' ? (
                  <Form.Control
                    type="number"
                    onWheel={e => {
                      e.preventDefault();
                      e.target.blur();
                    }}
                    step="any"
                    placeholder="Valeur"
                    name="value"
                    id="meta-value"
                    value={formMeta.value}
                    onChange={e => {
                      setFormMeta({
                        ...formMeta,
                        value: e.target.value
                      });
                    }}
                  />
                ) : (
                  <Form.Control
                    as={'textarea'}
                    placeholder="Valeur"
                    name="value"
                    id="meta-value"
                    value={formMeta.value}
                    onChange={e => {
                      setFormMeta({
                        ...formMeta,
                        value: e.target.value
                      });
                    }}
                  />
                )}

                <br />
                <Button
                  variant="primary"
                  className="admin-primary mt-0"
                  size="sm"
                  type="submit"
                >
                  Enregistrer
                </Button>
              </Form.Group>
            </Form>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </Fragment>
  );
};

MetaManage.propTypes = {
  setParentTrigger: PropTypes.func.isRequired,
  contract: PropTypes.object.isRequired,
  metaKey: PropTypes.string.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  isEditable: PropTypes.bool,
  readOnly: PropTypes.bool
};
export default MetaManage;
