import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'event-types';

export async function IndexTypeAdminManage() {
  return modelService.IndexModel(`${model}?context=admin.event.manage`);
}

export async function PostTypesManage(formData) {
  return modelService.PostModel(`${model}`, formData);
}
