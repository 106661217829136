import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, Col, Form, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  showContract,
  contractAskInvoice,
  uploadInvoiceFile,
  resetInvoiceState,
  relanceInvoice,
  PutContract
} from '../../../../../services/Contract/ContractService';
import { toast } from 'react-toastify';
import FileUploader from '../../../commons/fileUploader';
import SoftBadge from '../../../../common/SoftBadge';
import * as Moment from 'moment/moment';

const ManageContracts3 = ({ id, setParentTrigger }) => {
  const [trigger, setTrigger] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [contract, setContract] = useState({});
  const [formData, setFormData] = useState({});
  const [formUploadFile, setFormUploadFile] = useState({});

  const [initialFormData, setInitialFormData] = useState(null); // Stocke la version initiale

  useEffect(() => {
    const fetchContract = async () => {
      const response = await showContract(id);
      if (response.success === true) {
        setLoading(false);
        setContract(response.data);
        setFormData(response.data);
        setInitialFormData(response.data); // Stocke la version initiale
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      fetchContract();
    }
  }, [id, trigger]);

  useEffect(() => {
    if (
      initialFormData &&
      JSON.stringify(initialFormData) !== JSON.stringify(formData)
    ) {
      setShowSubmit(true); // Affiche le bouton uniquement si le formulaire a été modifié
    } else {
      setShowSubmit(false); // Cache le bouton sinon
    }
  }, [formData, initialFormData]);

  const handleFieldChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const years = [2023, 2024, 2025, 2026, 2027, 2028];

  const generalFields = [
    {
      key: 'commission',
      label: 'Commission',
      type: 'text'
    },
    {
      key: 'resp_achat_tu',
      label: 'Responsable achat TU',
      type: 'text'
    },
    {
      key: 'date_debut_contrat',
      label: 'Date de début du Contrat',
      type: 'date'
    },
    {
      key: 'date_fin_contrat',
      label: 'Date de fin du Contrat',
      type: 'date'
    },
    {
      key: 'facturation_email',
      label: 'Mails facturation séparés par des virgules',
      type: 'text'
    }
  ];

  const caaRfaFieldsPerYear = [
    {
      key: 'conditions_caa',
      label: 'Conditions CAA',
      type: 'textarea'
    },
    {
      key: 'conditions_rfa',
      label: 'Conditions RFA',
      type: 'textarea'
    },
    {
      key: 'resultat',
      label: 'Résultat',
      type: 'textarea'
    },
    {
      key: 'montant_caa',
      label: 'Montant CAA',
      type: 'number'
    },
    {
      key: 'montant_rfa',
      label: 'Montant RFA',
      type: 'number'
    },
    {
      key: 'montant_ht_total',
      label: 'Montant HT total',
      type: 'number',
      readOnly: true
    }
    // Removed 'date_depot_facture_caa_rfa' from editable fields
  ];

  const salonFieldsPerYear = [
    {
      key: 'montant_salon',
      label: 'Montant SALON HT',
      type: 'number'
    },
    {
      key: 'reference_salon',
      label: 'Référence',
      type: 'textarea'
    }
    // Removed 'date_depot_facture_salon' from editable fields
  ];

  const computeMontantHTTotal = year => {
    const montantCaa = parseFloat(formData[`montant_caa_${year}`]) || 0;
    const montantRfa = parseFloat(formData[`montant_rfa_${year}`]) || 0;
    return montantCaa + montantRfa;
  };

  const computeEtatContrat = () => {
    const { date_debut_contrat, date_fin_contrat, dereferencement_date } =
      formData;
    const now = new Date();

    if (dereferencement_date) {
      const date = new Date(dereferencement_date);
      return `Déréférencé le ${date.toLocaleDateString('fr-FR')}`;
    } else if (
      date_debut_contrat &&
      new Date(date_debut_contrat) < now &&
      (!date_fin_contrat || new Date(date_fin_contrat) > now)
    ) {
      return 'En cours';
    } else if (date_fin_contrat && new Date(date_fin_contrat) <= now) {
      return 'Expiré';
    } else {
      return '';
    }
  };

  const computeInvoiceToProcess = (contract, year, context) => {
    let count = 0;
    if (
      context === 'caa_rfa' &&
      contract[`etat_facture_caa_rfa_${year}`] == 2
    ) {
      count++;
    }
    if (context === 'salon' && contract[`etat_facture_salon_${year}`] == 2) {
      count++;
    }
    return count;
  };

  const computeInvoiceToProcessTotal = (contract, year) => {
    return (
      computeInvoiceToProcess(contract, year, 'caa_rfa') +
      computeInvoiceToProcess(contract, year, 'salon')
    );
  };

  const computeInvoiceToPay = (contract, year, context) => {
    let count = 0;
    if (
      context === 'caa_rfa' &&
      contract[`etat_facture_caa_rfa_${year}`] == 3 &&
      !contract[`date_paiement_caa_rfa_${year}`]
    ) {
      count++;
    }
    if (
      context === 'salon' &&
      contract[`etat_facture_salon_${year}`] == 3 &&
      !contract[`date_paiement_salon_${year}`]
    ) {
      count++;
    }
    return count;
  };

  const computeInvoiceToPayTotal = (contract, year) => {
    return (
      computeInvoiceToPay(contract, year, 'caa_rfa') +
      computeInvoiceToPay(contract, year, 'salon')
    );
  };

  const saveContract = async () => {
    setLoading(true);
    try {
      const response = await PutContract(id, formData);
      if (response.success) {
        setTrigger(Date.now());
        setParentTrigger(Date.now());
        setLoading(false);
        toast(`Contrat enregistré`);
        // Update the contract data if necessary
      } else {
        setLoading(false);
        toast(`Erreur lors de la modification du contrat`);
      }
    } catch (error) {
      setLoading(false);
      toast(`Erreur lors de la modification du contrat`);
    }
  };

  const handleAskInvoice = async (year, type) => {
    setLoading(true);
    try {
      const response = await contractAskInvoice(id, year, type);
      if (response.success) {
        //saveContract();
        setTrigger(Date.now());
        setParentTrigger(Date.now());
        setLoading(false);
        toast(`Facture demandée pour l'année ${year}`);
        // Update the contract data if necessary
      } else {
        setLoading(false);
        toast(`Erreur lors de la demande de facture pour l'année ${year}`);
      }
    } catch (error) {
      setLoading(false);
      toast(`Erreur lors de la demande de facture pour l'année ${year}`);
    }
  };

  const handleFileUpload = async (year, type) => {
    setLoading(true);
    try {
      const response = await uploadInvoiceFile(
        id,
        year,
        type,
        formUploadFile.value
      );
      if (response.success) {
        setTrigger(Date.now());
        setParentTrigger(Date.now());
        setLoading(false);
        setFormUploadFile({});
        toast(`Facture déposée pour l'année ${year}`);
        // Update the contract data if necessary
      } else {
        setLoading(false);
        toast(`Erreur lors du dépôt de la facture pour l'année ${year}`);
      }
    } catch (error) {
      setLoading(false);
      toast(`Erreur lors du dépôt de la facture pour l'année ${year}`);
    }
  };

  const handleAnnulerEtRedeposer = async (year, type) => {
    setLoading(true);
    try {
      const response = await resetInvoiceState(id, year, type);
      if (response.success) {
        setLoading(false);
        setTrigger(Date.now());
        setParentTrigger(Date.now());
        toast(`Facture annulée pour l'année ${year}`);
        // Update the contract data if necessary
      } else {
        setLoading(false);
        toast(`Erreur lors de l'annulation de la facture pour l'année ${year}`);
      }
    } catch (error) {
      setLoading(false);
      toast(`Erreur lors de l'annulation de la facture pour l'année ${year}`);
    }
  };

  const handleRelancer = async (year, type) => {
    setLoading(true);
    try {
      const response = await relanceInvoice(id, year, type);
      if (response.success) {
        setLoading(false);
        setTrigger(Date.now());
        setParentTrigger(Date.now());
        toast(`Relance effectuée pour l'année ${year}`);
        // Update the contract data if necessary
      } else {
        setLoading(false);
        toast(`Erreur lors de la relance pour l'année ${year}`);
      }
    } catch (error) {
      setLoading(false);
      toast(`Erreur lors de la relance pour l'année ${year}`);
    }
  };

  return (
    <Fragment>
      <Row>
        {/* General Fields */}
        {generalFields.map((field, index) => (
          <Col xs={12} className="mt-3" key={index}>
            <Form.Group>
              <Form.Label>{field.label} :</Form.Label>
              <Form.Control
                as={field.type === 'textarea' ? 'textarea' : 'input'}
                type={field.type !== 'textarea' ? field.type : undefined}
                name={field.key}
                placeholder={field.label}
                value={formData[field.key] || ''}
                onChange={handleFieldChange}
                readOnly={field.readOnly || false}
              />
            </Form.Group>
          </Col>
        ))}

        {/* Partenaire Selection */}
        <Col xs={12} className="mt-3">
          <Form.Group controlId="partenaire_user_id">
            <Form.Label>Partenaire :</Form.Label>
            <Form.Select
              aria-label="Partenaire"
              name="partenaire_user_id"
              value={formData.partenaire_user_id || ''}
              onChange={e => {
                if (e.target.value) {
                  const user = contract.partner?.users.find(
                    u => u.id === parseInt(e.target.value)
                  );
                  setFormData({
                    ...formData,
                    partenaire_user_id: e.target.value,
                    partenaire_telephone:
                      user?.phone || formData.partenaire_telephone,
                    partenaire_email: user?.email || formData.partenaire_email,
                    facturation_email: user?.email || formData.facturation_email
                  });
                }
              }}
            >
              <option value="">Choisir le partenaire...</option>
              {contract.partner?.users
                ?.filter(
                  user =>
                    user.lastname !== '' &&
                    user.lastname !== null &&
                    user.lastname !== undefined
                )
                .map((u, index) => (
                  <option value={u.id} key={index}>
                    {u.lastname} {u.firstname}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>

        {/* Téléphone */}
        <Col xs={12} className="mt-3">
          <Form.Group>
            <Form.Label>Téléphone :</Form.Label>
            <Form.Control
              type="text"
              name="partenaire_telephone"
              placeholder="Téléphone"
              value={formData.partenaire_telephone || ''}
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Col>

        {/* Mail partenaire */}
        <Col xs={12} className="mt-3">
          <Form.Group>
            <Form.Label>Mail partenaire :</Form.Label>
            <Form.Control
              type="email"
              name="partenaire_email"
              placeholder="Mail partenaire"
              value={formData.partenaire_email || ''}
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Col>

        {/* État de contrat */}
        <Col xs={12} className="mt-3">
          <Form.Group>
            <Form.Label>État de contrat :</Form.Label>
            <Form.Control
              type="text"
              name="etat_contrat"
              value={computeEtatContrat()}
              readOnly
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Collapsible Sections for Each Year */}
      <Col md="12" className="mt-3">
        <Accordion defaultActiveKey="">
          {years.map(year => (
            <Accordion.Item eventKey={year.toString()} key={year}>
              <Accordion.Header>
                {`Données pour l'année ${year}`}
                {computeInvoiceToProcessTotal(contract, year) > 0 && (
                  <div className="d-inline">
                    <SoftBadge bg="success" className="ms-1">
                      {computeInvoiceToProcessTotal(contract, year)} fact. à
                      déposer
                    </SoftBadge>
                  </div>
                )}
                {computeInvoiceToPayTotal(contract, year) > 0 && (
                  <div className="d-inline">
                    <SoftBadge bg="success" className="ms-1">
                      {computeInvoiceToPayTotal(contract, year)} fact. en
                      attente paiem.
                    </SoftBadge>
                  </div>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  {/* CAA/RFA Section */}
                  <Accordion.Item eventKey={`caaRfa_${year}`}>
                    <Accordion.Header>
                      {`CAA/RFA ${year}`}
                      {computeInvoiceToProcess(contract, year, 'caa_rfa') >
                        0 && (
                        <div className="d-inline">
                          <SoftBadge bg="success" className="ms-1">
                            {computeInvoiceToProcess(contract, year, 'caa_rfa')}{' '}
                            fact. à déposer
                          </SoftBadge>
                        </div>
                      )}
                      {computeInvoiceToPay(contract, year, 'caa_rfa') > 0 && (
                        <div className="d-inline">
                          <SoftBadge bg="success" className="ms-1">
                            {computeInvoiceToPay(contract, year, 'caa_rfa')}{' '}
                            fact. en attente paiem.
                          </SoftBadge>
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        {/* Render the fields */}
                        {caaRfaFieldsPerYear.map((field, index) => {
                          const fieldName = `${field.key}_${year}`;
                          let fieldValue = formData[fieldName] || '';
                          if (field.key === 'montant_ht_total') {
                            fieldValue = computeMontantHTTotal(year);
                          }
                          return (
                            <Col xs={12} className="mt-3" key={index}>
                              <Form.Group>
                                <Form.Label>{field.label} :</Form.Label>
                                <Form.Control
                                  as={
                                    field.type === 'textarea'
                                      ? 'textarea'
                                      : 'input'
                                  }
                                  type={
                                    field.type !== 'textarea'
                                      ? field.type
                                      : undefined
                                  }
                                  name={fieldName}
                                  placeholder={field.label}
                                  value={fieldValue}
                                  onChange={handleFieldChange}
                                  readOnly={field.readOnly || false}
                                />
                              </Form.Group>
                            </Col>
                          );
                        })}
                      </Row>

                      {/* Display date_depot_facture_caa_rfa */}
                      {formData[`date_depot_facture_caa_rfa_${year}`] && (
                        <Row className="mt-3">
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Date de dépôt facture CAA RFA :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={new Date(
                                  formData[`date_depot_facture_caa_rfa_${year}`]
                                ).toLocaleDateString('fr-FR')}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {/* Facture Section */}
                      <Row className="mt-3">
                        <Col>
                          {/* Logic for "État de facture CAA RFA" */}
                          {formData[`date_paiement_caa_rfa_${year}`] ? (
                            <p>
                              Payée le{' '}
                              {Moment(formData[`date_paiement_caa_rfa_${year}`])
                                .local()
                                .format('DD/MM/YYYY')}{' '}
                              {formData[`facture_import_caa_rfa_${year}`] ? (
                                <a
                                  target={'_blank'}
                                  rel={'noreferrer'}
                                  href={
                                    formData[`facture_import_caa_rfa_${year}`]
                                  }
                                >
                                  télécharger la facture
                                </a>
                              ) : (
                                ''
                              )}
                            </p>
                          ) : computeMontantHTTotal(year) > 0 &&
                            formData[`etat_facture_caa_rfa_${year}`] == 1 ? (
                            <Button
                              variant="warning"
                              disabled={loading}
                              onClick={() => handleAskInvoice(year, 'caa_rfa')}
                            >
                              Demander une facture
                            </Button>
                          ) : formData[`etat_facture_caa_rfa_${year}`] == 2 ? (
                            <div>
                              <p>Facture à déposer</p>
                              <FileUploader
                                pathToUpload="contracts"
                                formData={formUploadFile}
                                setFormData={setFormUploadFile}
                                format="any"
                                databaseField="value"
                                cropperEnabled={false}
                                progressBar={true}
                              />
                              {!loading && formUploadFile.value ? (
                                <span
                                  onClick={() => {
                                    handleFileUpload(year, 'caa_rfa');
                                  }}
                                  className="d-block cursor-pointer text-decoration-underline"
                                >
                                  <b className="d-block">Envoyer le fichier</b>
                                </span>
                              ) : loading ? (
                                <span className="d-block">
                                  <b className="d-block">Chargement...</b>
                                </span>
                              ) : null}
                            </div>
                          ) : formData[`etat_facture_caa_rfa_${year}`] == 3 ? (
                            <div>
                              <p>
                                <b>
                                  Impayée (envoyée le{' '}
                                  {formData[
                                    `date_depot_facture_caa_rfa_${year}`
                                  ]
                                    ? new Date(
                                        formData[
                                          `date_depot_facture_caa_rfa_${year}`
                                        ]
                                      ).toLocaleDateString('fr-FR')
                                    : ''}
                                  ){' '}
                                  {formData[
                                    `facture_import_caa_rfa_${year}`
                                  ] ? (
                                    <a
                                      target={'_blank'}
                                      rel={'noreferrer'}
                                      href={
                                        formData[
                                          `facture_import_caa_rfa_${year}`
                                        ]
                                      }
                                    >
                                      télécharger la facture
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </b>
                              </p>
                              <Button
                                onClick={() =>
                                  handleAnnulerEtRedeposer(year, 'caa_rfa')
                                }
                                disabled={loading}
                              >
                                Annuler et redéposer la facture
                              </Button>
                              <Button
                                disabled={loading}
                                className={'ms-3'}
                                onClick={() => handleRelancer(year, 'caa_rfa')}
                              >
                                Relancer
                              </Button>
                              {formData[`relance_facture_caa_rfa_${year}`] && (
                                <p className="mt-2">
                                  <b>
                                    Relancée le{' '}
                                    {new Date(
                                      formData[
                                        `relance_facture_caa_rfa_${year}`
                                      ]
                                    ).toLocaleDateString('fr-FR')}
                                  </b>
                                </p>
                              )}
                              {/* Input for date_paiement_caa_rfa */}
                            </div>
                          ) : null}
                          {formData[`etat_facture_caa_rfa_${year}`] != 1 &&
                            formData[`etat_facture_caa_rfa_${year}`] != 2 && (
                              <Form.Group className="mt-3">
                                <Form.Label>
                                  Date de virement de facture CAA RFA :
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name={`date_paiement_caa_rfa_${year}`}
                                  value={
                                    formData[`date_paiement_caa_rfa_${year}`] ||
                                    ''
                                  }
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                            )}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Salon Section */}
                  <Accordion.Item eventKey={`salon_${year}`}>
                    <Accordion.Header>
                      {`Salon ${year}`}
                      {computeInvoiceToProcess(contract, year, 'salon') > 0 && (
                        <div className="d-inline">
                          <SoftBadge bg="success" className="ms-1">
                            {computeInvoiceToProcess(contract, year, 'salon')}{' '}
                            fact. à déposer
                          </SoftBadge>
                        </div>
                      )}
                      {computeInvoiceToPay(contract, year, 'salon') > 0 && (
                        <div className="d-inline">
                          <SoftBadge bg="success" className="ms-1">
                            {computeInvoiceToPay(contract, year, 'salon')} fact.
                            en attente paiem.
                          </SoftBadge>
                        </div>
                      )}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        {/* Render the fields */}
                        {salonFieldsPerYear.map((field, index) => {
                          const fieldName = `${field.key}_${year}`;
                          let fieldValue = formData[fieldName] || '';
                          return (
                            <Col xs={12} className="mt-3" key={index}>
                              <Form.Group>
                                <Form.Label>{field.label} :</Form.Label>
                                <Form.Control
                                  as={
                                    field.type === 'textarea'
                                      ? 'textarea'
                                      : 'input'
                                  }
                                  type={
                                    field.type !== 'textarea'
                                      ? field.type
                                      : undefined
                                  }
                                  name={fieldName}
                                  placeholder={field.label}
                                  value={fieldValue}
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                            </Col>
                          );
                        })}
                      </Row>

                      {/* Display date_depot_facture_salon */}
                      {formData[`date_depot_facture_salon_${year}`] && (
                        <Row className="mt-3">
                          <Col>
                            <Form.Group>
                              <Form.Label>
                                Date de dépôt facture SALON :
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={new Date(
                                  formData[`date_depot_facture_salon_${year}`]
                                ).toLocaleDateString('fr-FR')}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {/* Facture Section for Salon */}
                      <Row className="mt-3">
                        <Col>
                          {/* Logic for "État de facture SALON" */}
                          {formData[`date_paiement_salon_${year}`] ? (
                            <p>
                              Payée le{' '}
                              {Moment(formData[`date_paiement_salon_${year}`])
                                .local()
                                .format('DD/MM/YYYY')}{' '}
                              {formData[`facture_import_salon_${year}`] ? (
                                <a
                                  target={'_blank'}
                                  rel={'noreferrer'}
                                  href={
                                    formData[`facture_import_salon_${year}`]
                                  }
                                >
                                  télécharger la facture
                                </a>
                              ) : (
                                ''
                              )}
                            </p>
                          ) : (
                            ''
                          )}
                          {formData[`montant_salon_${year}`] > 0 &&
                          formData[`etat_facture_salon_${year}`] == 1 ? (
                            <Button
                              variant="warning"
                              disabled={loading}
                              onClick={() => handleAskInvoice(year, 'salon')}
                            >
                              Demander une facture
                            </Button>
                          ) : formData[`etat_facture_salon_${year}`] == 2 ? (
                            <div>
                              <p>Facture à déposer</p>
                              <FileUploader
                                pathToUpload="contracts"
                                formData={formUploadFile}
                                setFormData={setFormUploadFile}
                                format="any"
                                databaseField="value"
                                cropperEnabled={false}
                                progressBar={true}
                              />
                              {!loading && formUploadFile.value ? (
                                <span
                                  onClick={() => {
                                    handleFileUpload(year, 'salon');
                                  }}
                                  className="d-block cursor-pointer text-decoration-underline"
                                >
                                  <b className="d-block">Envoyer le fichier</b>
                                </span>
                              ) : loading ? (
                                <span className="d-block">
                                  <b className="d-block">Chargement...</b>
                                </span>
                              ) : null}
                            </div>
                          ) : formData[`etat_facture_salon_${year}`] == 3 &&
                            !formData[`date_paiement_salon_${year}`] ? (
                            <div>
                              <p>
                                <b>
                                  Impayée (envoyée le{' '}
                                  {formData[`date_depot_facture_salon_${year}`]
                                    ? new Date(
                                        formData[
                                          `date_depot_facture_salon_${year}`
                                        ]
                                      ).toLocaleDateString('fr-FR')
                                    : ''}
                                  ){' '}
                                  {formData[`facture_import_salon_${year}`] ? (
                                    <a
                                      target={'_blank'}
                                      rel={'noreferrer'}
                                      href={
                                        formData[`facture_import_salon_${year}`]
                                      }
                                    >
                                      télécharger la facture
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </b>
                              </p>
                              <Button
                                disabled={loading}
                                onClick={() =>
                                  handleAnnulerEtRedeposer(year, 'salon')
                                }
                              >
                                Annuler et redéposer la facture
                              </Button>
                              <Button
                                className="ms-3"
                                disabled={loading}
                                onClick={() => handleRelancer(year, 'salon')}
                              >
                                Relancer
                              </Button>
                              {formData[`relance_facture_salon_${year}`] && (
                                <p>
                                  <b>
                                    Relancée le{' '}
                                    {new Date(
                                      formData[`relance_facture_salon_${year}`]
                                    ).toLocaleDateString('fr-FR')}
                                  </b>
                                </p>
                              )}
                              {/* Input for date_paiement_salon */}
                            </div>
                          ) : null}
                          {formData[`etat_facture_salon_${year}`] != 1 &&
                            formData[`etat_facture_salon_${year}`] != 2 && (
                              <Form.Group className="mt-3">
                                <Form.Label>
                                  Date de virement de facture SALON :
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  name={`date_paiement_salon_${year}`}
                                  value={
                                    formData[`date_paiement_salon_${year}`] ||
                                    ''
                                  }
                                  onChange={handleFieldChange}
                                />
                              </Form.Group>
                            )}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
      {showSubmit && (
        <Col md="12" className="mt-3">
          <Button
            style={{
              position: 'fixed',
              bottom: '15px',
              display: 'block',
              width: '400px',
              zIndex: '99999'
            }}
            disabled={loading}
            variant="primary"
            onClick={saveContract}
          >
            Enregistrer {contract.partner?.name}
            {contract.specific_label && ` - ${contract.specific_label}`}
          </Button>
        </Col>
      )}
    </Fragment>
  );
};

ManageContracts3.propTypes = {
  id: PropTypes.number,
  setParentTrigger: PropTypes.func
};

export default ManageContracts3;
