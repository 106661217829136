import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

/*const data = [
  { value: 50.3, name: 'Chrome' },
  { value: 20.6, name: 'Safari' },
  { value: 30.1, name: 'Mozilla' }
];*/

const ReduceAnswersChart = ({ data, sumResponses }) => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    let tmpData = [];
    data.forEach(item => {
      tmpData.push({
        value: Math.round((item.value * 100) / sumResponses),
        name: item.label,
        color: item.color
      });
    });
    setChartData(tmpData);
  }, []);
  const getOptions = data => ({
    color: data.map(item => item.color),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.data.value}%`
    },
    legend: { show: false },
    series: [
      {
        type: 'pie',
        radius: ['100%', '65%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderWidth: 4,
          borderColor: getColor('card-bg')
        },
        label: {
          show: false
        },
        emphasis: {
          scale: false
        },
        data
      }
    ]
  });
  return (
    <>
      {chartData.length > 0 && (
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(chartData)}
          style={{ height: 200 }}
        />
      )}
    </>
  );
};

// proptypes for this component
ReduceAnswersChart.propTypes = {
  data: PropTypes.array.isRequired,
  sumResponses: PropTypes.number.isRequired
};

export default ReduceAnswersChart;
