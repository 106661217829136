import React, { useEffect, useState } from 'react';
import '../../../../../assets/scss/admin.scss';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  ExportEvents,
  IndexEventAdmin
} from '../../../../../services/Event/EventService';
import { toast } from 'react-toastify';
import SoftBadge from '../../../../common/SoftBadge';
import * as Moment from 'moment/moment';
import CalendarAdmin from './Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListAdmin from './List';
import { IndexCategoriesAdminManage } from '../../../../../services/Event/CategoryService';
import moment from 'moment';
import IconButton from '../../../../common/IconButton';
import IconAlert from '../../../../common/IconAlert';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import IndexEventCategories from './categories';

const IndexEvents = () => {
  const [eventsDisplayMode, setEventsDisplayMode] = useState('');
  const [events, setEvents] = useState([]);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState('');
  const [currentEvents, setCurrentEvents] = useState([]);
  const [eventsFromApi, setEventsFromApi] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showCanvaIsNotValidated, setShowCanvaIsNotValidated] = useState(false); // Canva des événements en attentes de validations
  const [eventNumberIsNotValidated, setEventNumberIsNotValidated] = useState(0); // Nombres des événements à valider
  const [arrayEventsIsNotValidated, setArrayEventsIsNotvalidated] = useState(
    []
  );
  const [showIndexCategories, setShowIndexCategories] = useState(false);
  const [formSearch, setFormSearch] = useState({
    tredunion: 0,
    pole: 0,
    formations: 0,
    reunions: 0,
    webinars: 0,
    commissions: 0
  });

  const isColorLight = hex => {
    // Variables for red, green, blue values
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (hex.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      hex = hex.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      r = hex[1];
      g = hex[2];
      b = hex[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      hex = +('0x' + hex.slice(1).replace(hex.length < 5 && /./g, '$&$&'));
      r = hex >> 16;
      g = (hex >> 8) & 255;
      b = hex & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 150) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    localStorage.getItem('selectedDateTredUnionAdmin')
      ? setSelectedDate(
          new Date(
            localStorage.getItem('selectedDateTredUnionAdmin')
          ).toISOString()
        )
      : setSelectedDate(new Date().toISOString());
  }, []);

  useEffect(() => {
    setEvents([]);
    const fetchEventAdmin = async () => {
      const responseEventAdmin = await IndexEventAdmin(selectedDate);
      if (responseEventAdmin.success === true) {
        let eventArray = [];
        let tmpEventsFromApi = [];
        responseEventAdmin.data.map(event => {
          let tmpRecipients = [];
          if (event.internal_recipients) {
            event.internal_recipients.map(recipient =>
              tmpRecipients.push(recipient)
            );
          }
          if (event.external_recipients) {
            event.external_recipients.map(recipient =>
              tmpRecipients.push(recipient)
            );
          }
          tmpEventsFromApi.push({
            ...event,
            recipients: tmpRecipients
          });
        });
        setEventsFromApi(tmpEventsFromApi);
        responseEventAdmin.data?.map(event => {
          eventArray.push({
            id: event.id,
            category: event.category,
            title:
              event.category && event.category.is_holiday === 1
                ? ''
                : event.title,
            className:
              event.category && event.category.is_holiday === 1
                ? 'holidays'
                : '',
            start: `${event.start_date}`,
            time: event.start_time,
            end: event.start_is_all_day
              ? `${moment(event.end_date).add(1, 'd').format('YYYY-MM-DD')} ${
                  event.end_time
                }`
              : `${event.end_date} ${event.end_time}`,
            description: event.description,
            backgroundColor: event.category ? event.category.color : '#ccf2e1',
            textColor: event.category
              ? isColorLight(event.category.color)
                ? '#000000'
                : '#FFFFFF'
              : '#FFFFFF',
            location_lat: event.location_lat,
            location_lng: event.location_lng,
            location: event.location_label,
            organizer: event.company,
            organizerId: event.company?.id,
            allDay: true,
            start_is_all_day: event.start_is_all_day,
            link: event.link,
            is_validated: event.is_validated,
            created_at: event.created_at
          });
          //eventArray.push(event);
        });
        let arrayEvents = responseEventAdmin.data.filter(
          d =>
            d.is_validated === 0 ||
            d.is_validated === '0' ||
            d.is_validated === null
        );
        setArrayEventsIsNotvalidated(arrayEvents);
        setEvents(eventArray);
        let checkValidated = responseEventAdmin.data.filter(
          d =>
            d.is_validated === 0 ||
            d.is_validated === '0' ||
            d.is_validated === null
        ).length;
        setEventNumberIsNotValidated(checkValidated);
      }
    };
    const fetchCategories = async () => {
      const responseCategories = await IndexCategoriesAdminManage();
      if (responseCategories.success === true) {
        setCategories(responseCategories.data);
      }
    };
    if (selectedDate !== null && selectedDate !== undefined) {
      fetchEventAdmin();
      fetchCategories();
    }
    if (localStorage.getItem('tredEventsDisplayMode')) {
      setEventsDisplayMode(localStorage.getItem('tredEventsDisplayMode'));
    } else {
      localStorage.setItem('tredEventsDisplayMode', 'calendar');
    }
  }, [selectedDate]);

  const exportEvents = async year => {
    setExportIsLoading(true);
    const responseExport = await ExportEvents(
      year,
      new URLSearchParams(formSearch).toString()
    );
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {showCanvaIsNotValidated && (
        <Offcanvas
          show={showCanvaIsNotValidated}
          onHide={() => {
            setShowCanvaIsNotValidated(false);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Événements en attente de validation
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <>
              <Alert variant="primary">
                Liste des événements soumis par les adhérents depuis leur
                espace. Vous pouvez valider l'événement et y apporter des
                modifications ou refuser l'événement en cliquant sur le bouton
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="14"
                  height="14"
                  style={{
                    marginBottom: '2px',
                    marginRight: '3px',
                    marginLeft: '5px'
                  }}
                >
                  <path
                    d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                    fill="#1a4a89"
                  />
                </svg>{' '}
              </Alert>
              <Table responsive className="table-stats-tracks">
                <thead>
                  <tr>
                    <th scope="col">Événement</th>
                    <th scope="col">Initiateur</th>
                    <th scope="col">Date de soumission</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {arrayEventsIsNotValidated.map((event, index) => (
                    <tr key={index}>
                      <td>{event.title ? event.title : ''}</td>
                      <td>{event.organizer ? event.organizer.name : ''}</td>
                      <td>
                        {event.created_at
                          ? Moment(event.created_at).format('DD/MM/YYYY')
                          : ''}
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          className="me-2 admin-primary"
                          onClick={() => {
                            window.location.href = `/administration/evenements/${event.id}/modifier`;
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '2px',
                              marginRight: '3px'
                            }}
                          >
                            <path
                              d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          </Offcanvas.Body>
        </Offcanvas>
      )}

      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={3}>
              <h3 className="text-primary admin-title mb-0">Calendrier</h3>
            </Col>
            <Col xs={9} className="text-end">
              {eventNumberIsNotValidated > 0 && (
                <div className="float-end">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="overlay-trigger-example">
                        Modération des événements soumis par les membres
                      </Tooltip>
                    }
                  >
                    <Button
                      size={'sm'}
                      variant="falcon-default"
                      className="admin-primary ms-2"
                      onClick={() => setShowCanvaIsNotValidated(true)}
                    >
                      Modération des événements{' '}
                      <SoftBadge pill bg="warning" className="ms-1">
                        {eventNumberIsNotValidated}
                      </SoftBadge>
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
              <Button
                variant="primary"
                className="admin-primary float-end"
                size="sm"
                onClick={() => {
                  window.location.href = '/administration/evenements/ajouter';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Créer un événement
              </Button>
              <IconButton
                className="w-auto me-2"
                variant="falcon-default"
                size="sm"
                icon={faList}
                transform="shrink-3"
                onClick={() => {
                  setShowIndexCategories(true);
                }}
              >
                Gestion des catégories
              </IconButton>
              <IndexEventCategories
                showIndexCategories={showIndexCategories}
                setShowIndexCategories={setShowIndexCategories}
              />
              {exportIsLoading ? (
                <Spinner animation="border" role="status" size="xxs">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : !exportUrl ? (
                <DropdownButton
                  as={ButtonGroup}
                  variant="falcon-default"
                  size="sm"
                  title={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>{' '}
                      Exporter
                    </>
                  }
                  className="me-2"
                >
                  {[2023, 2024, 2025, 2026].map(year => (
                    <Dropdown.Item
                      key={year}
                      eventKey="1"
                      onClick={() => {
                        exportEvents(year);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '2px' }}
                      >
                        <path
                          d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                          fill="rgba(75,74,77,1)"
                        />
                      </svg>
                      {year}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              ) : (
                ''
              )}
              {/*<div
                className="px-0 theme-control-toggle float-end pe-2 ps-2"
                onClick={() => {
                  setEventsDisplayMode(
                    eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                  );
                  localStorage.setItem(
                    'tredEventsDisplayMode',
                    eventsDisplayMode === 'calendar' ? 'list' : 'calendar'
                  );
                }}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="hi">
                      Affichage en mode{' '}
                      {eventsDisplayMode === 'list' ? 'calendrier' : 'liste'}
                    </Tooltip>
                  }
                >
                  <div className="theme-control-toggle-label">
                    <FontAwesomeIcon
                      icon={
                        eventsDisplayMode === 'list' ? 'calendar-alt' : 'list'
                      }
                      transform="shrink-7"
                      className="fs-4"
                      style={{ padding: '3px' }}
                    />
                  </div>
                </OverlayTrigger>
              </div>*/}
            </Col>
          </Row>
          {exportUrl && (
            <IconAlert
              variant="success"
              dismissible
              onClose={() => setExportUrl('')}
              className="mt-3"
            >
              <p className="mb-0">
                Export Excel du calendrier disponible :{' '}
                <a href={exportUrl}>cliquez ici pour le télécharger</a>
              </p>
            </IconAlert>
          )}
          {(eventsDisplayMode === 'calendar' || eventsDisplayMode === 'list') &&
            categories.length > 0 && (
              <CalendarAdmin
                events={events}
                categories={categories}
                formSearch={formSearch}
                setFormSearch={setFormSearch}
                setSelectedDate={setSelectedDate}
              />
            )}
          {/*{eventsDisplayMode === 'list' && (
            <>
              {eventsFromApi.length > 0 ? (
                <ListAdmin events={eventsFromApi} />
              ) : (
                ''
              )}
            </>
          )}*/}
        </Card.Body>
      </Card>
    </>
  );
};

export default IndexEvents;
