import React, { useEffect, useState } from 'react';
import {
  Form,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  DeleteExtRec,
  IndexExtRec,
  PostExtRec,
  PutExtRec
} from '../../../../../../../services/ExternalRecipientService';
import FormAlert from '../../../../../commons/FormAlert';
import Button from 'react-bootstrap/Button';

const IndexExternalRecipients = ({
  showIndex,
  setShowIndex,
  setParentTrigger
}) => {
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [showManage, setShowManage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    company_name: ''
  });
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexExtRec();
      if (responseObjects.success === true) {
        setObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [showIndex, trigger]);

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let response = null;
    if (selectedObject && selectedObject.id) {
      response = await PutExtRec(selectedObject.id, formData);
    } else {
      response = await PostExtRec(formData);
    }
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    setIsLoading(false);
    if (response.success === true) {
      setTrigger(Date.now());
      setParentTrigger(Date.now());
      toast(`Destinataire enregistré`);
      setShowManage(false);
    } else {
      toast('Une erreur est survenu, veuillez réessayer plus tard');
    }
  };

  const deleteObject = async () => {
    const response = await DeleteExtRec(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      setParentTrigger(Date.now());
      toast('Destinataire archivé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        show={showIndex}
        onHide={() => {
          setShowIndex(false);
        }}
        placement={'end'}
        style={{ width: '60%' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Destinataires externes{' '}
            <small>
              <a
                className="cursor-pointer"
                onClick={() => {
                  setSelectedObject({});
                  setFormData({
                    email: '',
                    firstname: '',
                    lastname: '',
                    company_name: ''
                  });
                  setShowManage(true);
                }}
              >
                + Ajouter
              </a>
            </small>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {objects.length > 0 && (
            <Table responsive className="table-stats-tracks">
              <thead>
                <tr>
                  <th scope="col">Entreprise</th>
                  <th scope="col">Dest.</th>
                  <th scope="col">Email</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {objects.map((object, index) => (
                  <tr key={index}>
                    <td>{object.company_name}</td>
                    <td>
                      {object.lastname} {object.firstname}
                    </td>
                    <td>{object.email}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          setSelectedObject(object);
                          setShowManage(true);
                          setFormData({
                            email: object.email,
                            firstname: object.firstname,
                            lastname: object.lastname,
                            company_name: object.company_name
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '1px',
                            marginLeft: '3px'
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M5 19h1.414l9.314-9.314-1.414-1.414L5 17.586V19zm16 2H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L9.243 19H21v2zM15.728 6.858l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
                            fill="rgba(44,123,229,1)"
                          />
                        </svg>
                      </a>
                    </td>
                    <td>
                      <span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Supprimer le destinataire externe de la plateforme
                            </Tooltip>
                          }
                        >
                          <a
                            href="javascript:void(0)"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedObject(object);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showManage}
        onHide={() => {
          setShowManage(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id ? 'Modifier' : 'Ajouter'} un destinataire externe
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email :</Form.Label>
              <Form.Control
                placeholder="Email du destinataire"
                size="sm"
                name="email"
                type="email"
                value={formData.email}
                onChange={e => {
                  setFormData({
                    ...formData,
                    email: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="company_name" className="mt-3">
              <Form.Label>Entreprise :</Form.Label>
              <Form.Control
                placeholder="Nom de l'entreprise"
                size="sm"
                name="company_name"
                type="text"
                value={formData.company_name}
                onChange={e => {
                  setFormData({
                    ...formData,
                    company_name: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="lastname" className="mt-3">
              <Form.Label>Nom :</Form.Label>
              <Form.Control
                placeholder="Nom du destinataire"
                size="sm"
                name="lastname"
                type="text"
                value={formData.lastname}
                onChange={e => {
                  setFormData({
                    ...formData,
                    lastname: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="firstname" className="mt-3">
              <Form.Label>Prénom :</Form.Label>
              <Form.Control
                placeholder="Prénom du destinataire"
                size="sm"
                name="firstname"
                type="text"
                value={formData.firstname}
                onChange={e => {
                  setFormData({
                    ...formData,
                    firstname: e.target.value
                  });
                }}
              />
            </Form.Group>
            <Button
              variant="primary"
              className="admin-primary mt-3"
              size="sm"
              type="submit"
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{
                  position: 'relative',
                  bottom: '1px',
                  left: '-1px',
                  paddingRight: '3px'
                }}
              >
                <path
                  fill="#FFFFFF"
                  d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                />
              </svg>
              Enregistrer
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
        }}
        placement={'end'}
      >
        {selectedObject.id && (
          <>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                L'archivage du destinataire externe "{`${selectedObject.email}`}
                " est définitif.
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
};

// Proptypes for component
IndexExternalRecipients.propTypes = {
  showIndex: PropTypes.bool.isRequired,
  setShowIndex: PropTypes.func.isRequired,
  setParentTrigger: PropTypes.func.isRequired
};

export default IndexExternalRecipients;
