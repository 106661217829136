import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import * as Moment from 'moment/moment';
import SoftBadge from '../../../../../common/SoftBadge';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../../common/IconButton';
import { toast } from 'react-toastify';
import { IndexPointTypologyCategory } from '../../../../../../services/Point/PointTypologyCategoryService';
import {
  DeletePoint,
  PostPoint,
  PutPoint
} from '../../../../../../services/Point/PointService';
import FormAlert from '../../../../commons/FormAlert';
import DatePicker from 'react-datepicker';
import { IndexEventSelect } from '../../../../../../services/Event/EventService';
import Select from 'react-select';
import { IndexSpecificContext } from '../../../../../../services/Point/SpecificContextService';
import ManageTypologies from './manageTypologies';
import { ShowCompanyAdmin } from '../../../../../../services/CompanyService';
const PointCompany = ({
  companyComponent,
  showPointCompany,
  setShowPointCompany,
  setParentTrigger
}) => {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [company, setCompany] = useState({});
  const [trigger, setTrigger] = useState(Date.now());
  const [companyPoints, setCompanyPoints] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  const [loadingSubmitAdd, setLoadingSubmitAdd] = useState(false);
  const [pointTypologyCategories, setPointTypologyCategories] = useState([]);
  const [contexts, setContexts] = useState([]);
  const [selectedContext, setSelectedContext] = useState([]);
  const [showSpecificContextAdd, setShowSpecificContextAdd] = useState(false);
  const [formAddData, setFormAddData] = useState({
    label: '',
    points: 0,
    typology_id: '',
    company_id: '',
    created_at: new Date(),
    event_id: '',
    context: 0,
    attachable_id: '',
    attachable_type: '',
    specific_context_title: '',
    specific_context_month: new Date().getMonth() + 1,
    specific_context_year: moment().format('Y')
  });

  const resetFormData = () => {
    setFormAddData({
      ...formAddData,
      label: '',
      points: 0,
      typology_id: '',
      created_at: new Date(),
      event_id: '',
      context: 0,
      attachable_id: '',
      attachable_type: '',
      specific_context_title: '',
      specific_context_month: new Date().getMonth() + 1,
      specific_context_year: moment().format('Y')
    });
    setErrorForm(false);
  };
  useEffect(() => {
    setFormAddData({
      ...formAddData,
      company_id: company.id
    });
    const fetchCompany = async () => {
      const resp = await ShowCompanyAdmin(companyComponent.id);
      if (resp.success === true) {
        setCompany(resp.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchTypologyCategories = async () => {
      const responseObjects = await IndexPointTypologyCategory();
      if (responseObjects.success === true) {
        setPointTypologyCategories(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (companyComponent.id) {
      fetchCompany();
    }

    fetchTypologyCategories();

    const fetchContexts = async () => {
      let tmpContexts = [];
      const responseEventAdmin = await IndexEventSelect(true);
      if (responseEventAdmin.success === true) {
        responseEventAdmin.data
          .filter(
            e => Moment(e.start_date).format('YYYY') === Moment().format('YYYY')
          )
          .map(e => {
            tmpContexts.push({
              value: e.id,
              model: 'event',
              label: `Évén. - ${e.title} - ${
                e.start_date === e.end_date
                  ? Moment(e.start_date).local().format('DD/MM/YYYY')
                  : `${Moment(e.start_date).local().format('DD/MM')} - ${Moment(
                      e.end_date
                    )
                      .local()
                      .format('DD/MM/YY')}`
              }`
            });
          });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
      const responseSpecificContext = await IndexSpecificContext();
      if (responseSpecificContext.success === true) {
        responseSpecificContext.data
          .filter(
            c => Moment(c.created_at).format('YYYY') === Moment().format('YYYY')
          )
          .map(c => {
            tmpContexts.push({
              value: c.id,
              model: 'specific_context',
              label: `Cont. spé. - ${c.title} - ${moment(c.month, 'MM').format(
                'MMMM'
              )} ${c.year}`
            });
          });
      }
      setContexts(tmpContexts);
    };
    fetchContexts();
  }, [companyComponent, year, trigger]);

  useEffect(() => {
    let tmpPoints = [];
    let tmpTotalPoint = 300;
    if (company.points) {
      company.points.map(point => {
        tmpPoints.push(point);
        if (Moment(point.created_at).local().format('YYYY') === year) {
          tmpTotalPoint += point.points;
        }
      });
    }
    if (company.childrens) {
      company.childrens.map(child => {
        if (child.points) {
          child.points.map(point => {
            tmpPoints.push(point);
            if (Moment(point.created_at).local().format('YYYY') === year) {
              tmpTotalPoint += point.points;
            }
          });
        }
      });
    }
    setTotalPoints(tmpTotalPoint);
    setCompanyPoints(
      tmpPoints.sort(
        (a, b) =>
          new Moment(b.created_at).format('YYYYMMDDHHmm') -
          new Moment(a.created_at).format('YYYYMMDDHHmm')
      )
    );
  }, [company]);

  const handleSubmitManage = async e => {
    e.preventDefault();
    setLoadingSubmitAdd(true);
    setErrorForm(false);
    let tmpFormAddData = { ...formAddData };
    tmpFormAddData.created_at = Moment(tmpFormAddData.created_at)
      .local()
      .format('YYYY-MM-DD');
    let response = null;
    if (selectedPoint && selectedPoint.id) {
      response = await PutPoint(selectedPoint.id, tmpFormAddData);
    } else {
      response = await PostPoint(tmpFormAddData);
    }
    setLoadingSubmitAdd(false);
    if (response.validator) {
      setErrorForm(response.validator);
    }
    if (response.success === true) {
      resetFormData();
      setTrigger(Date.now());
      setShowSpecificContextAdd(false);
      setSelectedContext(null);
      if (setParentTrigger) {
        setParentTrigger(Date.now());
      }
      setErrorForm(false);
      toast('Action enregistrée');
      setShowAdd(false);
    }
  };

  const deleteObject = async () => {
    const response = await DeletePoint(selectedPoint.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Action enregistrée');
      setTrigger(Date.now());
      setParentTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Offcanvas
        show={showPointCompany}
        style={{ width: 900 }}
        onHide={() => {
          setShowPointCompany(false);
          setShowAdd(false);
          setFormAddData({
            label: '',
            points: 0,
            typology_id: '',
            company_id: '',
            created_at: new Date(),
            event_id: '',
            context: 0,
            attachable_id: '',
            attachable_type: ''
          });
          setSelectedContext(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Historique des points</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mb-4">
            <Col xs={12} className="mb-3">
              <Form.Group controlId="category_id">
                <Form.Label>Année :</Form.Label>
                <Form.Select
                  aria-label="Année"
                  name="year"
                  value={year}
                  onChange={e => {
                    setYear(e.target.value);
                  }}
                >
                  <option value={moment().format('YYYY')}>
                    {moment().format('YYYY')}
                  </option>
                  {Array.from({ length: 5 }, (v, k) => k + 1).map(
                    (c, index) => (
                      <Fragment key={index}>
                        {parseInt(
                          moment().subtract(c, 'years').format('YYYY')
                        ) > 2022 && (
                          <option
                            key={index}
                            value={moment().subtract(c, 'years').format('YYYY')}
                          >
                            {moment().subtract(c, 'years').format('YYYY')}
                          </option>
                        )}
                      </Fragment>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              {company.id && (
                <Row>
                  <Col xs={4}>
                    <div className="d-block mt-2">
                      <b>Total : </b>
                      <SoftBadge
                        pill
                        bg={
                          totalPoints > 201
                            ? 'success'
                            : totalPoints > 101
                            ? 'warning'
                            : 'danger'
                        }
                        className="ms-0 badge-points"
                      >
                        {totalPoints} points
                      </SoftBadge>
                    </div>
                  </Col>
                  <Col xs={8}>
                    {Moment().local().format('YYYY') === year && (
                      <IconButton
                        className="mt-1 w-auto float-end"
                        variant="falcon-default"
                        size="sm"
                        icon={faSync}
                        transform="shrink-3"
                        onClick={() => {
                          setShowAdd(true);
                          setSelectedPoint({});
                          resetFormData();
                        }}
                      >
                        Ajouter ou retirer des points
                      </IconButton>
                    )}
                  </Col>
                </Row>
              )}
              <Table responsive className="table-stats-tracks">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Points</th>
                    <th scope="col">Motif</th>
                    <th scope="col">Responsable</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {companyPoints.length > 0 && (
                    <Fragment>
                      {companyPoints
                        .filter(
                          point =>
                            Moment(point.created_at).local().format('YYYY') ===
                            year
                        )
                        .map((point, index) => (
                          <tr key={index}>
                            <td>
                              {Moment(point.created_at)
                                .local()
                                .format('DD/MM/YYYY')}
                            </td>
                            <td>
                              <SoftBadge
                                pill
                                bg={point.points > 0 ? 'success' : 'danger'}
                                className=""
                              >
                                {point.points > 0 ? '+ ' : ''}
                                {point.points}
                              </SoftBadge>
                            </td>
                            <td>
                              {point.label}
                              {point.attachable && (
                                <div className="d-block mt-1">
                                  <SoftBadge pill bg="info" className="">
                                    {point.attachable.start_date ? (
                                      <a
                                        href={`/administration/evenements/${point.attachable.id}`}
                                        target="_blank"
                                        rel={'noreferrer'}
                                      >
                                        {point.attachable.title}
                                      </a>
                                    ) : (
                                      <Fragment>
                                        {point.attachable.title}
                                        {point.attachable.month && (
                                          <span>
                                            {' - '}
                                            {moment(
                                              point.attachable.month,
                                              'MM'
                                            ).format('MMMM')}
                                          </span>
                                        )}
                                        {point.attachable.year && (
                                          <> {point.attachable.year}</>
                                        )}
                                      </Fragment>
                                    )}
                                  </SoftBadge>
                                </div>
                              )}
                            </td>
                            <td>
                              {/*{point.user
                                ? `${point.user.firstname} ${point.user.lastname}`
                                : ''}*/}
                              {point.company && (
                                <SoftBadge pill bg="info" className="">
                                  {point.company.name}
                                </SoftBadge>
                              )}
                            </td>
                            <td>
                              {point.is_prorata !== 1 && (
                                <>
                                  <a
                                    href="#"
                                    className="cursor-pointer me-2"
                                    onClick={() => {
                                      setShowAdd(true);
                                      resetFormData();
                                      setSelectedPoint(point);
                                      setTimeout(() => {
                                        setFormAddData({
                                          points: point.points,
                                          label: point.label,
                                          created_at: new Date(
                                            point.created_at
                                          ),
                                          company_id: point.company_id,
                                          typology_id: point.typology_id,
                                          attachable_id: point.attachable_id,
                                          attachable_type:
                                            point.attachable_type,
                                          context: point.attachable_id ? 1 : 0
                                        });
                                        if (point.attachable_id) {
                                          if (
                                            point.attachable_type.includes(
                                              'Event'
                                            )
                                          ) {
                                            setSelectedContext({
                                              value: point.attachable_id,
                                              model: 'event',
                                              label: `Évén. - ${
                                                point.attachable.title
                                              } - ${
                                                point.attachable.start_date ===
                                                point.attachable.end_date
                                                  ? Moment(
                                                      point.attachable
                                                        .start_date
                                                    )
                                                      .local()
                                                      .format('DD/MM/YYYY')
                                                  : `${Moment(
                                                      point.attachable
                                                        .start_date
                                                    )
                                                      .local()
                                                      .format(
                                                        'DD/MM'
                                                      )} - ${Moment(
                                                      point.attachable.end_date
                                                    )
                                                      .local()
                                                      .format('DD/MM/YY')}`
                                              }`
                                            });
                                          } else if (
                                            point.attachable_type.includes(
                                              'SpecificContext'
                                            )
                                          ) {
                                            setSelectedContext({
                                              value: point.attachable_id,
                                              model: 'specific_context',
                                              label: `Cont. spé. - ${
                                                point.attachable.title
                                              } - ${moment(
                                                point.attachable.month,
                                                'MM'
                                              ).format('MMMM')} ${
                                                point.attachable.year
                                              }`
                                            });
                                          }
                                        }
                                      }, 100);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="14"
                                      height="14"
                                      style={{
                                        marginBottom: '2px'
                                      }}
                                    >
                                      <path
                                        d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                                        fill="#2c7be5"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="#"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setShowDelete(true);
                                      setSelectedPoint(point);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="14"
                                      height="14"
                                      style={{
                                        marginBottom: '2px'
                                      }}
                                    >
                                      <path
                                        d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                        fill="#e63757"
                                      />
                                    </svg>
                                  </a>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        style={{ width: '600px' }}
        show={showAdd}
        onHide={() => {
          setShowAdd(false);
          resetFormData();
          setSelectedPoint({});
        }}
        placement={'start'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ajouter ou retirer des points</Offcanvas.Title>
          <ManageTypologies
            setParentTrigger={setTrigger}
            setGrandParentTrigger={setParentTrigger}
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mb-4">
            <Col xs={12}>
              {pointTypologyCategories.length > 0 && (
                <>
                  {pointTypologyCategories.map((category, index) => (
                    <div key={index} className="d-block mb-3">
                      <p className="mb-1 smaller-p">
                        <b>{category.name}</b>
                      </p>
                      {category.typologies.map((typology, index) => (
                        <Form.Check
                          inline
                          key={index}
                          type="radio"
                          defaultChecked={
                            selectedPoint &&
                            selectedPoint.typology_id === typology.id
                          }
                          id={typology.id}
                          value={typology.id}
                          label={
                            <div>
                              {typology.name}{' '}
                              <SoftBadge
                                pill
                                bg={typology.points > 0 ? 'success' : 'danger'}
                                className=""
                              >
                                {typology.points > 0 ? '+ ' : ''}
                                {typology.points}
                              </SoftBadge>
                              {typology.is_automatic === 1 && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="overlay-trigger-example">
                                      Ces points sont normalement attribués
                                      automatiquement par le système. Vous
                                      pouvez tout de même les attribuer
                                      manuellement si besoin.
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    style={{
                                      position: 'relative',
                                      bottom: '0px',
                                      left: '3px'
                                    }}
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                                      fill="#646363"
                                    />
                                  </svg>
                                </OverlayTrigger>
                              )}
                            </div>
                          }
                          name="typology_id"
                          className="d-block mb-0"
                          onChange={e => {
                            setFormAddData({
                              ...formAddData,
                              typology_id: e.target.value
                            });
                          }}
                        />
                      ))}
                    </div>
                  ))}
                </>
              )}
            </Col>
            <Col xs="12" className="pb-4 pt-0">
              <div className="border-dashed-bottom"></div>
            </Col>
            <Col xs="12" className="pb-4 pt-0">
              <Form.Group controlId="category_id">
                <Form.Label>Responsable :</Form.Label>
                <Form.Select
                  aria-label="Année"
                  name="year"
                  value={formAddData.company_id}
                  onChange={e => {
                    setFormAddData({
                      ...formAddData,
                      company_id: e.target.value
                    });
                  }}
                >
                  <option value={company.id}>{company.name}</option>
                  {company.childrens && company.childrens.length > 0 && (
                    <Fragment>
                      {company.childrens.map((child, index) => (
                        <Fragment key={index}>
                          <option value={child.id}>{child.name}</option>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="created_at">
                <Form.Label>
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm-2 2H9v2H7V5H4v4h16V5h-3v2h-2V5zm5 6H4v8h16v-8z"
                      fill="rgba(94,110,130,1)"
                    />
                  </svg>{' '}
                  Date
                </Form.Label>
                <DatePicker
                  name="created_at"
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  selected={formAddData.created_at}
                  onChange={date => {
                    setFormAddData({
                      ...formAddData,
                      created_at: date
                    });
                  }}
                  formatWeekDay={startDate => startDate.slice(0, 3)}
                  className="form-control"
                  placeholderText="Date"
                  value={formAddData.created_at}
                />
              </Form.Group>
            </Col>
            <Col xs="12" className="pb-2 pt-4">
              <div className="border-dashed-bottom"></div>
            </Col>
            <Col xs={12} className="mt-3">
              <label>
                <input
                  type="checkbox"
                  id="context"
                  name="context"
                  value="1"
                  className="me-1"
                  checked={
                    formAddData.context === 1 || formAddData.context === '1'
                  }
                  onChange={e => {
                    setFormAddData({
                      ...formAddData,
                      context: e.target.checked ? 1 : 0,
                      context_id: null,
                      context_model: null,
                      specific_context_title: '',
                      specific_context_month: new Date().getMonth() + 1,
                      specific_context_year: moment().format('Y')
                    });
                    setShowSpecificContextAdd(false);
                    setSelectedContext(null);
                  }}
                />
                Ajouter un contexte ?{' '}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="overlay-trigger-example">
                      Vous pouvez ajouter un contexte à votre point. Cela permet
                      de classifier vos points en fonction d'événements ou de
                      situations particulières. Cette classification est
                      appliquée dans l'export Excel des points annuels. Si votre
                      ligne de points n'est pas classifiée, elle sera
                      comptabilisée dans la colonne "Autres" de l'export Excel
                      des points annuels.
                    </Tooltip>
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      fill="rgba(100,99,99,1)"
                    />
                  </svg>
                </OverlayTrigger>
              </label>
            </Col>
            {formAddData.context === 1 && (
              <Col xs={12}>
                {!showSpecificContextAdd && (
                  <>
                    <Select
                      closeMenuOnSelect={true}
                      deleteRemoves={true}
                      options={contexts}
                      placeholder="Choisir un événement ou un contexte spécifique..."
                      name="context_id"
                      classNamePrefix="react-select"
                      value={selectedContext}
                      onChange={object => {
                        setFormAddData({
                          ...formAddData,
                          attachable_id: parseInt(object.value),
                          attachable_type: object.model
                        });
                        setSelectedContext(object);
                      }}
                    />
                    <a
                      className="cursor-pointer ps-1 d-block mt-1"
                      onClick={() => {
                        setShowSpecificContextAdd(true);
                        setFormAddData({
                          ...formAddData,
                          attachable_id: null,
                          attachable_type: 'specific_context'
                        });
                        setSelectedContext(null);
                      }}
                    >
                      <small>+ Créer un contexte spécifique</small>
                    </a>
                  </>
                )}

                {showSpecificContextAdd && (
                  <>
                    <Form.Group controlId="name">
                      <Form.Label>Contexte spécifique :</Form.Label>
                      <Form.Control
                        type="text"
                        name="specific_context_title"
                        placeholder="Achat 02 23, Achat véhicules 2023..."
                        value={formAddData.specific_context_title || ''}
                        onChange={e => {
                          setFormAddData({
                            ...formAddData,
                            specific_context_title: e.target.value
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="name" className="mt-2">
                      <Form.Label>
                        Mois concerné par le contexte spécifique :
                      </Form.Label>
                      <Form.Select
                        aria-label="Mois concerné par le contexte spécifique"
                        name="specific_context_month"
                        value={formAddData.specific_context_month || ''}
                        onChange={e => {
                          setFormAddData({
                            ...formAddData,
                            specific_context_month: e.target.value
                          });
                        }}
                      >
                        {[
                          `Janvier ${moment().format('Y')}`,
                          `Février ${moment().format('Y')}`,
                          `Mars ${moment().format('Y')}`,
                          `Avril ${moment().format('Y')}`,
                          `Mai ${moment().format('Y')}`,
                          `Juin ${moment().format('Y')}`,
                          `Juillet ${moment().format('Y')}`,
                          `Août ${moment().format('Y')}`,
                          `Septembre ${moment().format('Y')}`,
                          `Octobre ${moment().format('Y')}`,
                          `Novembre ${moment().format('Y')}`,
                          `Décembre ${moment().format('Y')}`
                        ].map((m, index) => (
                          <option value={index + 1} key={index}>
                            {m}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <a
                      className="cursor-pointer ps-1 d-block mt-1"
                      onClick={() => {
                        setShowSpecificContextAdd(false);
                        setFormAddData({
                          ...formAddData,
                          specific_context_title: '',
                          specific_context_month: new Date().getMonth() + 1,
                          specific_context_year: moment().format('Y'),
                          attachable_id: null,
                          attachable_type: ''
                        });
                      }}
                    >
                      <small>x Annuler</small>
                    </a>
                  </>
                )}
              </Col>
            )}
            <Col xs="12" className="pb-4 pt-3">
              <div className="border-dashed-bottom"></div>
            </Col>
            {errorForm && (
              <div className="mt-0">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Col xs={12} className="pb-7 mb-8">
              <Button
                size="sm"
                variant="primary"
                type="submit"
                disabled={loadingSubmitAdd}
                onClick={handleSubmitManage}
                className="me-2 admin-primary"
              >
                Enregistrer
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
          setSelectedPoint({});
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedPoint.id !== undefined && (
              <>
                L'archivage de la ligne "{selectedPoint.label}" est définitif.
              </>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteObject();
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

PointCompany.propTypes = {
  companyComponent: PropTypes.object.isRequired,
  showPointCompany: PropTypes.bool.isRequired,
  setShowPointCompany: PropTypes.func.isRequired,
  setParentTrigger: PropTypes.func
};

export default PointCompany;
