import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'elearning/questions';

export async function PutQuestion(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PostQuestion(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteQuestion(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
