import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Alert
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import {
  DeleteRequest,
  IndexRequest,
  PutRequest
} from '../../../../../../services/Elearning/RequestService';
import { IndexRoles } from '../../../../../../services/RoleService';
import ExportELearningRequests from '../../../../../../exports/elearningRequests';
import SoftBadge from '../../../../../common/SoftBadge';
import FormAlert from '../../../../commons/FormAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import {
  IndexUserElearningRequest,
  PutUser,
  PostUser
} from '../../../../../../services/UserService';
import Select from 'react-select';
import IconAlert from '../../../../../common/IconAlert';
import { IndexAdmin } from '../../../../../../services/CompanyService';
import { useSearchParams } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCompanies,
  changeCourses,
  changeEndDate,
  changeSearch,
  changeStartDate
} from '../../../../../../redux/slices/requestsSlice';
import { changeIsPointsManaged } from '../../../../../../redux/slices/calendarSlice';

registerLocale('fr', fr);

const IndexCourseRequests = () => {
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());
  const [initialApiObjects, setInitialApiObjects] = useState([]);
  const [initialObjects, setInitialObjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [showOnlyNew, setShowOnlyNew] = useState(false);
  const [roles, setRoles] = useState([]);
  const [usersSelect, setUsersSelect] = useState([]);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedUserId, setSelectedUserId] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormCreate, setShowFormCreate] = useState(false);
  const [courses, setCourses] = useState([]);
  const [companiesInput, setCompaniesInput] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesSelect, setCompaniesSelect] = useState([]);
  const [formData, setFormData] = useState({
    course_id: '',
    notification_to_initiator: ''
  });
  const [formDataCreate, setFormDataCreate] = useState({
    firstname: '',
    lastname: '',
    login: '',
    email: '',
    gender: 0,
    phone: '',
    profile_picture_url: '',
    occupation: '',
    main_company_id: '',
    main_company_invoices_recipient: 0,
    secondary_companies: [],
    roles: [],
    notifications: [],
    signature_file_url: '',
    is_admin: 0,
    course_id: ''
  });
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formDataSearch, setFormDataSearch] = useStateWithCallbackLazy({
    search: '',
    companies: [],
    courses: [],
    startDate: '',
    endDate: ''
  });

  const {
    search: searchStored,
    companies: companiesStored,
    courses: coursesStored,
    managers: managersStored,
    startDate: startDateStored,
    endDate: endDateStored
  } = useSelector(state => state.persistedRequestsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const initialFormDataSearch = {
      search: searchStored,
      companies: companiesStored,
      courses: coursesStored,
      managers: managersStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored)
    };
    setFormDataSearch(initialFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [initialObjects]);

  useEffect(() => {
    if (formDataSearch === null) return;

    const updateFormDataSearch = {
      ...formDataSearch,
      search: searchStored,
      companies: companiesStored,
      courses: coursesStored,
      managers: managersStored,
      startDate: startDateStored && new Date(startDateStored),
      endDate: endDateStored && new Date(endDateStored)
    };
    setFormDataSearch(updateFormDataSearch, data =>
      updateSearch(data, initialObjects)
    );
  }, [
    searchStored,
    companiesStored,
    coursesStored,
    managersStored,
    startDateStored,
    endDateStored
  ]);

  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="mb-3">
      <Form.Control
        color="text-primary"
        placeholder={'Choisir une date'}
        ref={ref}
        onClick={onClick}
        onChange={() => {}}
        value={value}
        className="ps-3 text-muted"
        style={{ paddingTop: '6px', paddingBottom: '6px' }}
      />
      {formDataSearch.startDate && formDataSearch.endDate && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="14"
          height="14"
          onClick={() => {
            setFormDataSearch(
              {
                ...formDataSearch,
                startDate: '',
                endDate: ''
              },
              data => {
                updateSearch(data, initialObjects, true);
                dispatch(changeStartDate(''));
                dispatch(changeEndDate(''));
              }
            );
          }}
          style={{
            position: 'absolute',
            display: 'block',
            right: '4px',
            top: '13px',
            cursor: 'pointer'
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            fill="rgba(216,226,239,1)"
          />
        </svg>
      )}
    </div>
  ));
  DatePickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };
  const getSearchInputsValues = objects => {
    let tmpCourses = [];

    objects.map(obj => {
      if (obj.courses) {
        obj.courses.map(course => {
          if (!tmpCourses.filter(c => c.value === course.title).length > 0) {
            tmpCourses.push({
              value: course.title,
              label: course.title
            });
          }
        });
      }
    });
    let tmpCompanies = [];
    objects.map(obj => {
      if (!tmpCompanies.filter(c => c.value === obj.company).length > 0) {
        tmpCompanies.push({
          value: obj.company,
          label: obj.company
        });
      }
      if (
        obj.initiator &&
        obj.initiator.main_company &&
        !tmpCompanies.filter(c => c.value === obj.initiator.main_company.name)
          .length > 0
      ) {
        tmpCompanies.push({
          value: obj.initiator.main_company.name,
          label: obj.initiator.main_company.name
        });
      }
    });
    setCompaniesInput(
      tmpCompanies.sort((a, b) => a.label.localeCompare(b.label))
    );
    setCourses(tmpCourses);
  };

  const handleFieldChangeCreate = e => {
    setFormDataCreate({
      ...formDataCreate,
      [e.target.name]: e.target.value
    });
  };

  const coursesToString = courses => {
    let tmpCourses = '';
    if (courses && courses.length > 0) {
      courses.map((obj, index) => {
        tmpCourses += `${obj.title} ${
          index + 1 !== courses.length ? ', ' : ''
        }`;
      });
    }
    return tmpCourses;
  };

  const deleteObject = async hideToast => {
    setIsLoading(true);
    const response = await DeleteRequest(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      if (!hideToast) {
        toast('Demande archivée');
      }
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setIsLoading(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    if (showFormCreate) {
      let responseCreate = await PostUser(formDataCreate);
      if (responseCreate.success === true) {
        toast('Membre enregistré');
        let response = await PutUser(
          'admin.elearningrequest',
          responseCreate.data.id,
          formData
        );

        if (response.success === true) {
          deleteObject(true);
          toast(`La demande a bien été traitée.`);
          setShowManage(false);
          setFormData({
            course_id: '',
            notification_to_initiator: ''
          });
          setSelectedObject({});
          setSelectedUserId({});
          setShowFormCreate(false);
        }
      } else {
        toast('Une erreur dans la création');
      }
      setSuccessForm(false);
      setErrorForm(responseCreate.validator);
      setIsLoading(false);
    } else {
      let response = await PutUser(
        'admin.elearningrequest',
        selectedUserId.value,
        formData
      );
      setErrorForm(response.validator);
      setIsLoading(false);
      if (response.success === true) {
        deleteObject(true);
        toast(`La demande a bien été traitée.`);
        setShowManage(false);
        setFormData({
          course_id: '',
          notification_to_initiator: ''
        });
        setSelectedObject({});
        setSelectedUserId({});
        setShowFormCreate(false);
        setTrigger(Date.now);
      }
    }
  };

  const markAsRead = async id => {
    setIsLoading(true);
    let response = await PutRequest(selectedObject.id);
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      toast(`La demande a bien été traitée.`);
      setShowManage(false);
      setFormData({
        course_id: '',
        notification_to_initiator: ''
      });
      setSelectedObject({});
      setSelectedUserId({});
      setShowFormCreate(false);
      setTrigger(Date.now);
    }
  };

  const transformObjects = objects => {
    let transformedApiObjects = [];
    objects.map(obj => {
      transformedApiObjects.push({
        object: obj,
        company: obj.company,
        student: (
          <>
            Prénom : {obj.firstname}
            <span className="d-block mb-1"></span>
            Nom : {obj.lastname}
            <span className="d-block mb-1"></span>
            Fonction : {obj.occupation}
            <span className="d-block mb-1"></span>
            Email :{' '}
            <SoftBadge bg="info" className="me-2">
              <a href={`mailto:${obj.email}`}>{obj.email}</a>
            </SoftBadge>
            <span className="d-block mb-1"></span>
            Téléphone : {obj.phone}
          </>
        ),
        firstname: obj.firstname,
        lastname: obj.lastname,
        studentFullname: `${obj.firstname} ${obj.lastname}`,
        initiatorFullname: obj.initiator
          ? `${obj.initiator.firstname} ${obj.initiator.lastname}`
          : '',
        initiatorCompany: obj.initiator.main_company
          ? `${obj.initiator.main_company.name}`
          : '',
        occupation: obj.occupation,
        email: obj.email,
        phone: obj.phone,
        courses: coursesToString(obj.courses),
        coursesArray: obj.courses,

        initiator: obj.initiator
          ? `${obj.initiator.firstname} ${obj.initiator.lastname} ${
              obj.initiator.main_company
                ? `(${obj.initiator.main_company.name})`
                : ''
            }`
          : '',
        actions: (
          <>
            {obj.processed_at || obj.deleted_at ? (
              <span className="text-success">Traitée</span>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Gérer la demande
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="mb-2 d-block"
                  type="button"
                  onClick={() => {
                    setFormDataCreate(true);
                    setShowManage(true);
                    setShowFormCreate(false);
                    setSelectedObject(obj);
                  }}
                >
                  <FontAwesomeIcon icon={faCogs} />
                </Button>
              </OverlayTrigger>
            )}
          </>
        ),
        createdAt: Moment(obj.created_at).local().format('DD/MM/YY'),
        createdAtMomentObject: Moment(obj.created_at),
        processed_at: obj.processed_at,
        deleted_at: obj.deleted_at
      });
    });
    updateSearch(formDataSearch, transformedApiObjects);
    setInitialObjects(transformedApiObjects);
    // setObjects(transformedApiObjects);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchRoles = async () => {
      const responseObjects = await IndexRoles();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.id,
            label: r.title
          });
        });
        setRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchObjects = async () => {
      const responseObjects = await IndexRequest();
      if (responseObjects.success === true) {
        getSearchInputsValues(responseObjects.data);
        setInitialApiObjects(responseObjects.data);
        transformObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUsers = async () => {
      const responseUsers = await IndexUserElearningRequest();
      if (responseUsers.success === true) {
        setUsers(responseUsers.data);
        let tmpUsers = [];
        responseUsers.data.map(user => {
          tmpUsers.push({
            value: user.id,
            label: `${user.firstname} ${user.lastname} - ${user.email}`,
            user: user
          });
        });

        setUsersSelect(tmpUsers);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        setCompanies(responseCompanies.data);
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name
          });
        });

        setCompaniesSelect(companiesArray);
        if (searchParams.get('company')) {
          setFormDataCreate({
            ...formDataCreate,
            main_company_id: parseInt(searchParams.get('company'))
          });
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };

    fetchRoles();
    fetchUsers();
    fetchObjects();
    fetchCompanies();
  }, [trigger]);

  const updateSearch = (data, objects, resetPagination) => {
    if (
      data.search.length === 0 &&
      data.courses.length === 0 &&
      data.companies.length === 0 &&
      !data.startDate &&
      !data.endDate &&
      !showOnlyNew
    ) {
      setObjects(objects);
    } else {
      if (resetPagination === true) {
        setTablePageIndex(0);
      }
      let tmpFilterObjects = objects;
      if (data.search.length > 0) {
        let tmpSearch = data.search?.toLowerCase();
        // .replace('/[A-zÀ-ú]/g', '/[A-z]/g');

        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            (obj.studentFullname &&
              obj.studentFullname
                .split(' ')
                .reverse()
                .join(' ')
                ?.toLowerCase()
                .includes(
                  tmpSearch.replace('\t', ' ').replace('/[A-zÀ-ú]/', '/[A-z]/')
                )) ||
            (obj.email && obj.email?.toLowerCase().includes(tmpSearch)) ||
            (obj.initiatorFullname &&
              obj.initiatorFullname
                ?.toLowerCase()
                .includes(tmpSearch.replace('\t', ' '))) ||
            (obj.initiatorFullname &&
              obj.initiatorFullname
                .split(' ')
                .reverse()
                .join(' ')
                ?.toLowerCase()
                .includes(tmpSearch.replace('\t', ' ')))
          ) {
            result = true;
          }
          return result;
        });
      }

      if (data.courses.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.courses.map(course => {
            if (
              obj.coursesArray &&
              obj.coursesArray.filter(c => c.title === course.value).length > 0
            ) {
              result = true;
            }
          });

          return result;
        });
      }
      if (data.companies.length > 0) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          data.companies.map(company => {
            if (
              obj.company === company.value ||
              obj.initiatorCompany === company.value
            ) {
              result = true;
            }
          });
          return result;
        });
      }
      if (data.startDate || data.endDate) {
        tmpFilterObjects = tmpFilterObjects.filter(obj => {
          let result = false;
          if (
            data.startDate &&
            Moment(obj.createdAtMomentObject)
              .local()
              .isSameOrAfter(Moment(data.startDate)) &&
            (!data.endDate ||
              (data.endDate &&
                Moment(obj.createdAtMomentObject)
                  .local()
                  .isSameOrBefore(Moment(data.endDate).add(1, 'days'))))
          ) {
            result = true;
          }
          return result;
        });
      }
      if (showOnlyNew) {
        tmpFilterObjects = tmpFilterObjects.filter(
          obj => obj.processed_at === null && obj.deleted_at === null
        );
      }
      setObjects(tmpFilterObjects);
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="d-flex justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Demandes d'accès E-learning
              </h3>
            </Col>
            {/*<Col lg={6} className="text-end">
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2 "
                type="submit"
                onClick={() => {
                  window.location.href =
                    '/administration/elearning-acces-history';
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="icon"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '4px' }}
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z" />
                </svg>
                Historique
              </Button>
            </Col>*/}
          </Row>
        </Card.Body>
      </Card>
      {initialApiObjects.length > 0 && (
        <Card className="mt-3">
          <Card.Body className="">
            <Row className="align-items-end mb-3">
              <Col xs={3} className="mb-3">
                <Form.Label>Recherche</Form.Label>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Nom, prénom, email"
                  value={formDataSearch.search || ''}
                  onChange={event => {
                    dispatch(changeSearch(event.target.value));
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        search: event.target.value
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3}>
                <Form.Label>Date de la demande</Form.Label>
                <DatePicker
                  onChange={dates => {
                    const [start, end] = dates;
                    dispatch(changeStartDate(start));
                    dispatch(changeEndDate(end));
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        startDate: start,
                        endDate: end
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                  startDate={formDataSearch.startDate}
                  formatWeekDay={day => day.slice(0, 3)}
                  endDate={formDataSearch.endDate}
                  selectsRange
                  dateFormat="dd/MM/yy"
                  customInput={<DatePickerInput />}
                  locale="fr"
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Société(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={companiesInput}
                  placeholder="Choisir..."
                  isMulti
                  name="companies"
                  classNamePrefix="react-select"
                  value={formDataSearch.companies}
                  onChange={value => {
                    dispatch(changeCompanies(value));
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        companies: value
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={3} className="mb-3">
                <Form.Label>Formation(s)</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={courses}
                  placeholder="Choisir..."
                  isMulti
                  name="courses"
                  classNamePrefix="react-select"
                  value={formDataSearch.courses}
                  onChange={value => {
                    dispatch(changeCourses(value));
                    setFormDataSearch(
                      {
                        ...formDataSearch,
                        courses: value
                      },
                      data => {
                        updateSearch(data, initialObjects, true);
                      }
                    );
                  }}
                />
              </Col>
              <Col xs={8}>
                <label>
                  <input
                    type="checkbox"
                    name="show_only_new"
                    value={1}
                    className="me-1"
                    checked={showOnlyNew === true}
                    onChange={() => {
                      setShowOnlyNew(!showOnlyNew);
                      if (showOnlyNew) {
                        setObjects(initialObjects);
                      } else {
                        setObjects(
                          initialObjects.filter(
                            obj =>
                              obj.processed_at === null &&
                              obj.deleted_at === null
                          )
                        );
                      }
                    }}
                  />
                  Afficher uniquement les demandes à traiter{' '}
                  <SoftBadge pill bg="warning" className="ms-0">
                    {
                      objects.filter(
                        obj =>
                          obj.processed_at === null && obj.deleted_at === null
                      ).length
                    }
                  </SoftBadge>
                </label>
              </Col>
            </Row>
            <Row>
              <Table
                data={objects}
                tablePageIndex={tablePageIndex}
                setTablePageIndex={setTablePageIndex}
              />
            </Row>
          </Card.Body>
        </Card>
      )}
      <Offcanvas
        show={showDelete}
        onHide={() => {
          setShowDelete(false);
          setShowManage(false);
        }}
        placement={'end'}
        style={{ width: '600px' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedObject.id !== undefined && (
              <>L'archivage de la demande est définitif.</>
            )}
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            disabled={isLoading}
            onClick={() => {
              deleteObject();
              setShowManage(false);
              setShowFormCreate(false);
              setFormDataCreate({
                firstname: '',
                lastname: '',
                login: '',
                email: '',
                gender: 0,
                phone: '',
                profile_picture_url: '',
                occupation: '',
                main_company_id: '',
                main_company_invoices_recipient: 0,
                secondary_companies: [],
                roles: [],
                notifications: [],
                signature_file_url: '',
                is_admin: 0,
                course_id: ''
              });
              setErrorForm(false);
            }}
          >
            Oui, archiver
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(true);
              setShowFormCreate(false);
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showManage}
        style={{ width: '600px' }}
        onHide={() => {
          setShowManage(false);
          setShowFormCreate(false);
          setFormDataCreate({
            firstname: '',
            lastname: '',
            login: '',
            email: '',
            gender: 0,
            phone: '',
            profile_picture_url: '',
            occupation: '',
            main_company_id: '',
            main_company_invoices_recipient: 0,
            secondary_companies: [],
            roles: [],
            notifications: [],
            signature_file_url: '',
            is_admin: 0,
            course_id: ''
          });
          setErrorForm(false);
        }}
        onShow={() => {
          let filterEmail = users.find(
            u => u.email?.toLowerCase() === selectedObject.email?.toLowerCase()
          );
          let filterFullname = users.find(
            u =>
              u.firstname?.toLowerCase() ===
                selectedObject.firstname?.toLowerCase() &&
              u.lastname?.toLowerCase() ===
                selectedObject.lastname?.toLowerCase()
          );
          let filterLastname = users.filter(
            u =>
              u.lastname?.toLowerCase() ===
              selectedObject.lastname?.toLowerCase()
          )[0];
          if (filterEmail) {
            setSelectedUserId({
              value: filterEmail.id,
              label: `${filterEmail.firstname} ${filterEmail.lastname} - ${filterEmail.email}`,
              user: filterEmail
            });
          } else if (filterFullname) {
            setSelectedUserId({
              value: filterFullname.id,
              label: `${filterFullname.firstname} ${filterFullname.lastname} - ${filterFullname.email}`,
              user: filterFullname
            });
          } else if (filterLastname) {
            setSelectedUserId({
              value: filterLastname.id,
              label: `${filterLastname.firstname} ${filterLastname.lastname} - ${filterLastname.email}`,
              user: filterLastname
            });
          }
          setFormData({
            course_id:
              selectedObject.courses.length > 0 && selectedObject.courses[0].id,
            notification_to_initiator: ''
          });
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {showFormCreate === true ? (
              <>Création d'un membre et inscription à la formation</>
            ) : (
              <>Gérer la demande</>
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {errorForm && showFormCreate && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          {successForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          {users.length > 0 && selectedObject.initiator && (
            <>
              <Row className="mb-4">
                {selectedUserId.user &&
                selectedUserId.user.deleted_at &&
                showFormCreate === false ? (
                  <Col xs={12} className="mt-3">
                    <IconAlert variant="warning">
                      <p className="mb-0 smaller-p">
                        <b>Attention</b>, l'étudiant a été archivé du système le{' '}
                        {Moment(selectedUserId.user.deleted_at)
                          .local()
                          .format('DD/MM/YYYY')}
                        . Pour continuer, vous devez rétablir ses accès en
                        cliquant ici :{' '}
                        <a
                          className="cursor-pointer"
                          onClick={async () => {
                            const response = await PutUser(
                              'admin.deletefromtrash',
                              selectedUserId.user.id,
                              {
                                deleted_at: ''
                              }
                            );
                            if (response.success === true) {
                              toast('Opération réalisée avec succès');
                              let tmpUser = {};
                              tmpUser.value = selectedUserId.value;
                              tmpUser.label = selectedUserId.label;
                              tmpUser.user = {
                                ...selectedUserId.user,
                                deleted_at: ''
                              };
                              setSelectedUserId(tmpUser);
                              setTrigger(Date.now());
                              // inscription
                            } else {
                              toast(
                                'Une erreur est survenue, veuillez réessayer plus tard'
                              );
                            }
                          }}
                        >
                          rétablir les accès
                        </a>{' '}
                        ou en{' '}
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            setShowFormCreate(true);
                          }}
                        >
                          lui créant un nouveau compte
                        </a>
                        .
                      </p>
                    </IconAlert>
                  </Col>
                ) : (
                  <Col>
                    {selectedUserId.user &&
                      (showFormCreate === false ? (
                        <Col xs={12} className="mt-0">
                          {selectedUserId.user.email?.toLowerCase() ===
                            selectedObject.email?.toLowerCase() ||
                          (selectedObject.initiator.firstname ===
                            selectedUserId.user.firstname &&
                            selectedObject.initiator.lastname ===
                              selectedUserId.user.lastname) ? (
                            <>
                              <IconAlert variant="info">
                                Le{' '}
                                {Moment(selectedObject.created_at).format(
                                  'DD/MM/YYYY'
                                )}
                                {` ${selectedUserId.user.firstname} ${selectedUserId.user.lastname}` +
                                  ' de la société : ' +
                                  ` ${selectedObject.company}` +
                                  ' à demandé à participer à la formation : ' +
                                  ` ${coursesToString(
                                    selectedObject.courses
                                  )}.`}
                              </IconAlert>
                            </>
                          ) : (
                            <>
                              <Alert variant="warning">
                                <p className="mb-0 ">
                                  La demande concerne un membre inscrit sur Tred
                                  Union avec une autre adresse mail que celle
                                  renseignée par{' '}
                                  {selectedObject.initiator.firstname}{' '}
                                  {selectedObject.initiator.lastname} dans sa
                                  demande.
                                  <span className="d-block mb-3"></span>
                                  Pour être sûr, pouvez-vous valider qu'il
                                  s'agit bien du membre ci-dessous à inscrire à
                                  la formation ?
                                </p>
                                <Select
                                  className="mb-0 mt-3"
                                  closeMenuOnSelect={true}
                                  options={usersSelect}
                                  placeholder="Rechercher..."
                                  classNamePrefix="react-select"
                                  value={selectedUserId}
                                  onChange={value => {
                                    setSelectedUserId(value);
                                  }}
                                />
                                <p className="mb-0 smaller-p mt-3">
                                  Le cas échant,{' '}
                                  <b>
                                    choisissez une autre entrée dans la liste
                                  </b>{' '}
                                  ou{' '}
                                  <b>
                                    créez son compte membre en{' '}
                                    <a
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setShowFormCreate(true);
                                      }}
                                    >
                                      cliquant ici
                                    </a>
                                    .
                                  </b>
                                </p>
                              </Alert>
                            </>
                          )}
                        </Col>
                      ) : (
                        /*Formulaire Post*/
                        <Row className="g-3">
                          <Col xs={12}>
                            <IconAlert variant="info">
                              <p className="mb-0 smaller-p">
                                Formation :{' '}
                                {coursesToString(selectedObject.courses)}.
                              </p>
                            </IconAlert>
                            <Form className="mb-3">
                              <Col xs={12}>
                                <Form.Group controlId="login">
                                  <Form.Label>Login de connexion :</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="login"
                                    placeholder="Login de connexion"
                                    value={formDataCreate.login || ''}
                                    onChange={handleFieldChangeCreate}
                                  />
                                </Form.Group>
                              </Col>
                              <Row>
                                <Col xs={6} className="mb-1 mt-1">
                                  <Form.Group controlId="firstname">
                                    <Form.Label>Prénom :</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="firstname"
                                      placeholder="Prénom du membre"
                                      value={formDataCreate.firstname || ''}
                                      onChange={handleFieldChangeCreate}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={6} className="mb-1 mt-1">
                                  <Form.Group controlId="lastname">
                                    <Form.Label>Nom :</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="lastname"
                                      placeholder="Nom du membre"
                                      value={formDataCreate.lastname || ''}
                                      onChange={handleFieldChangeCreate}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} className="mb-1">
                                  <Form.Group controlId="email">
                                    <Form.Label>Email :</Form.Label>
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      placeholder="Adresse email"
                                      value={formDataCreate.email || ''}
                                      onChange={handleFieldChangeCreate}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={6} className="mb-1">
                                  <Form.Group controlId="phone">
                                    <Form.Label>Téléphone :</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="phone"
                                      placeholder="Numéro de téléphone"
                                      value={formDataCreate.phone || ''}
                                      onChange={handleFieldChangeCreate}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Col md="12" className="mb-1">
                                <Form.Group controlId="occupation">
                                  <Form.Label>Fonction(s) :</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="occupation"
                                    value={formDataCreate.occupation || ''}
                                    onChange={handleFieldChangeCreate}
                                  />
                                </Form.Group>
                              </Col>
                              <Row>
                                <Col xs={6} className="mt-1">
                                  <Form.Group controlId="main_company_id">
                                    <Form.Label>
                                      Société principale :
                                    </Form.Label>
                                    <Form.Select
                                      aria-label="Filiale de"
                                      name="main_company_id"
                                      value={formDataCreate.main_company_id}
                                      onChange={handleFieldChangeCreate}
                                    >
                                      <option>Aucune</option>
                                      {companies.map((c, index) => (
                                        <option value={c.id} key={index}>
                                          {c.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col xs={6} className="mt-1">
                                  <Form.Group controlId="secondary_companies">
                                    <Form.Label>
                                      Société(s) secondaire(s) :
                                    </Form.Label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={companiesSelect}
                                      placeholder="Choisir une ou plusieurs sociétés..."
                                      isMulti
                                      name="secondary_companies"
                                      classNamePrefix="react-select"
                                      value={formDataCreate.secondary_companies}
                                      onChange={value => {
                                        setFormDataCreate({
                                          ...formDataCreate,
                                          secondary_companies: value
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="12" className="mt-3">
                                  <Form.Group controlId="roles">
                                    <Form.Label>
                                      Rôles <small>(autorisations)</small> :
                                    </Form.Label>

                                    <Select
                                      closeMenuOnSelect={false}
                                      options={roles}
                                      placeholder="Choisir un ou plusieurs rôles..."
                                      isMulti
                                      name="roles"
                                      classNamePrefix="react-select"
                                      value={formDataCreate.roles}
                                      onChange={value => {
                                        setFormDataCreate({
                                          ...formDataCreate,
                                          roles: value
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="12" className="mt-3">
                                  <Form.Group controlId="notifications">
                                    <Form.Label>
                                      Reçoit les notifications de :
                                    </Form.Label>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={roles}
                                      placeholder="Choisir un ou plusieurs rôles..."
                                      isMulti
                                      name="notifications"
                                      classNamePrefix="react-select"
                                      value={formData.notifications}
                                      onChange={value => {
                                        setFormData({
                                          ...formData,
                                          notifications: value
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      ))}
                    {selectedObject.initiator && selectedObject.initiator.id && (
                      <Col xs={12} className="mt-0">
                        <label>
                          <input
                            type="checkbox"
                            id="notification_to_initiator"
                            name="notification_to_initiator"
                            value="oki"
                            style={{ position: 'relative', top: '1px' }}
                            className="me-1"
                            checked={formData.notification_to_initiator !== ''}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.checked
                                  ? selectedObject.initiator.email
                                  : ''
                              });
                            }}
                          />
                          Envoyer également un email au rédacteur ?
                        </label>
                        <span className="d-block smaller-p mb-2">
                          Cocher cette case permet d'envoyer une notification
                          automatique au rédacteur de la demande, lui indiquant
                          que l'étudiant a bien été inscrit à sa formation.
                          Destinataire :
                          <SoftBadge bg="primary" className="ms-1">
                            {selectedObject.initiator.firstname}{' '}
                            {selectedObject.initiator.lastname} -{' '}
                            {selectedObject.initiator.email}
                          </SoftBadge>
                        </span>
                      </Col>
                    )}
                  </Col>
                )}
              </Row>
              <Button
                size="sm"
                variant="primary"
                className="me-2 admin-primary"
                disabled={
                  isLoading ||
                  (selectedUserId.user && selectedUserId.user.deleted_at)
                }
                onClick={handleSubmit}
              >
                {showFormCreate === false
                  ? "Valider l'inscription à la formation"
                  : 'Enregistrer le nouveau membre'}
                {/*                Valider l'inscription à la formation*/}
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowManage(false);
                }}
              >
                Annuler
              </Button>
              <br />
              <b className="d-block mt-3">
                <small>- OU -</small>
              </b>
              <p
                style={{ color: '#2C7AE5' }}
                className="smaller-p cursor-pointer"
                onClick={markAsRead}
              >
                Je souhaite simplement marquer la demande comme{' '}
                <span className="text-success">traitée</span>
              </p>
              {/*<OverlayTrigger
                overlay={
                  <Tooltip id="overlay-trigger-example">
                    Archiver la demande d'inscription
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="danger"
                  className="ms-9"
                  type="button"
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="14"
                    style={{
                      marginBottom: '2px'
                    }}
                  >
                    <path
                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>*/}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

function Table({ data, tablePageIndex, setTablePageIndex }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'actions',
        Header: 'Actions'
      },
      {
        accessor: 'createdAt',
        Header: 'Date',
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        accessor: 'company',
        Header: 'Société'
      },
      {
        accessor: 'student',
        Header: 'Étudiant'
      },
      {
        accessor: 'courses',
        Header: 'Formation(s)'
      },
      {
        accessor: 'initiator',
        Header: 'Rédacteur'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: tablePageIndex, pageSize: 15 }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive scrollbar-visible table-container">
        <table
          {...getTableProps()}
          className="table table-striped table-bordered admin-table w-100 d-block d-table"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="table-fixed-header"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    key={`${index}${Date.now()}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" />
                          </svg>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, indexCell) => {
                    return (
                      <td key={indexCell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination d-block mt-3">
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            gotoPage(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(0);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            previousPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-2"
          onClick={() => {
            nextPage();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTablePageIndex(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </Button>
        <Button
          variant="falcon-primary"
          size="sm"
          className="me-3"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageIndex(pageCount - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </Button>{' '}
        <span className="bottom-table">
          Page{' '}
          <strong>
            {pageIndex + 1} sur {pageOptions.length}
          </strong>{' '}
        </span>
        <Form.Select
          className="d-inline-block w-auto ms-3 table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Default select example"
        >
          {[15, 30, 50, 100, 150].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} éléments
            </option>
          ))}
        </Form.Select>
        <ExportELearningRequests objects={data} />
      </div>
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  tablePageIndex: PropTypes.number,
  setTablePageIndex: PropTypes.func
};

export default IndexCourseRequests;
