import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'users';

export async function IndexUser() {
  return modelService.IndexModel(`${model}?context=findmembers`);
}

export async function IndexTeachers() {
  return modelService.IndexModel(`${model}?context=teachers`);
}

export async function IndexUserAdmin() {
  return modelService.IndexModel(`${model}?context=admin`);
}

export async function IndexUserAdminSortByLastname() {
  return modelService.IndexModel(`${model}?context=admin.sortbylastname`);
}

export async function IndexUserElearningRequest() {
  return modelService.IndexModel(`${model}?context=adminelearningrequest`);
}

export async function ShowUser(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function ShowUserFromMembers(id) {
  return modelService.ShowModel(`${model}/show-from-members/${id}`);
}

export async function CheckVimeoControls(vimeo_id) {
  return modelService.ShowModel(`check-vimeo-controls/${vimeo_id}`);
}

export async function ShowUserAdmin(id) {
  return modelService.ShowModel(`${model}/${id}?context=admin`);
}

export async function PutUser(context, id, formData) {
  return modelService.PutModel(`${model}/${id}?context=${context}`, formData);
}

export async function PostUser(formData, context = '') {
  return modelService.PostModel(`${model}?context=${context}`, formData);
}

export async function DeleteUser(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
