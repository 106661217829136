import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Select from 'react-select';
const ManageRecipientsCompanies = ({ formData, setFormData, companies }) => {
  useEffect(() => {}, [formData]);
  return (
    <div>
      {((formData.recipient_users && formData.recipient_users.length > 0) ||
        (formData.recipient_externalUsers &&
          formData.recipient_externalUsers.length > 0)) && (
        <Table bordered className="admin-table mt-4">
          <thead>
            <tr>
              <th scope="col">Destinataires</th>
              <th scope="col" style={{ width: '50%' }}>
                Complètent pour les entreprises :
              </th>
            </tr>
          </thead>
          <tbody>
            {formData.recipient_users && (
              <Fragment>
                {formData.recipient_users.map((recipient, index) => (
                  <tr key={index}>
                    <td>{recipient.label}</td>
                    <td>
                      <Select
                        closeMenuOnSelect={true}
                        options={companies}
                        placeholder="Choisir une ou plusieurs entreprises..."
                        isMulti
                        name="companies"
                        classNamePrefix="react-select"
                        value={formData.recipient_users[index].companies}
                        onChange={value => {
                          setFormData({
                            ...formData,
                            recipient_users: formData.recipient_users.map(
                              (item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    companies: value
                                  };
                                }
                                return item;
                              }
                            )
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
            {formData.recipient_externalUsers && (
              <Fragment>
                {formData.recipient_externalUsers.map((recipient, index) => (
                  <tr key={index}>
                    <td>{recipient.label}</td>
                    <td>
                      <Select
                        closeMenuOnSelect={true}
                        options={companies}
                        placeholder="Choisir une ou plusieurs entreprises..."
                        isMulti
                        name="companies"
                        classNamePrefix="react-select"
                        value={
                          formData.recipient_externalUsers[index].companies
                        }
                        onChange={value => {
                          setFormData({
                            ...formData,
                            recipient_externalUsers:
                              formData.recipient_externalUsers.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      companies: value
                                    };
                                  }
                                  return item;
                                }
                              )
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </Fragment>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

ManageRecipientsCompanies.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
};

export default ManageRecipientsCompanies;
