import React, { Fragment, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DeleteCategories,
  IndexCategories,
  PostCategories,
  PutCategories
} from '../../../../../services/VisMaRouteCategoryService';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DeleteContent } from '../../../../../services/VisMaRouteContentService';
import Masonry from 'react-masonry-css';
import SoftBadge from '../../../../common/SoftBadge';
import FileUploader from '../../../commons/fileUploader';
import FormAlert from '../../../commons/FormAlert';

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 3,
  500: 2
};

const IndexVisMaRoute = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCategoryDelete, setShowCategoryDelete] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image_url: '',
    parent_id: '',
    order: 0
  });
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexCategories();
      if (responseObjects.success === true) {
        setInitialObjects(responseObjects.data);
        setObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteContent(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Contenu archivée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const deleteCategory = async () => {
    const response = await DeleteCategories(selectedObject.id);
    if (response.success === true) {
      setShowCategoryDelete(false);
      setShowAddCategory(false);
      toast('Thème archivé');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let response = false;
    if (selectedObject.id !== null && selectedObject.id !== undefined) {
      response = await PutCategories(selectedObject.id, formData);
    } else {
      response = await PostCategories(formData);
    }
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      toast('Thème enregistré');
      setTrigger(Date.now());
      setSelectedObject({});
      setShowAddCategory(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des contenus Vis Ma Route
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="falcon-default"
                size="sm"
                className="me-2"
                onClick={() => {
                  setFormData({
                    title: '',
                    content: '',
                    image_url: '',
                    parent_id: '',
                    order: 0
                  });
                  setSelectedObject({});
                  setShowAddCategory(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
                    fill="rgba(75,74,77,1)"
                  />
                </svg>
                Ajouter un thème
              </Button>
              <Offcanvas
                show={showAddCategory}
                onHide={() => {
                  setShowAddCategory(false);
                  setFormData({
                    title: '',
                    content: '',
                    image_url: '',
                    parent_id: '',
                    order: 0
                  });
                }}
                placement={'end'}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    {selectedObject.id !== null &&
                    selectedObject.id !== undefined
                      ? 'Modifier'
                      : 'Ajouter'}{' '}
                    un thème
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row className="gx-2 gy-3 mb-3">
                      <Col xs={12}>
                        {errorForm && (
                          <div className="mt-0">
                            <FormAlert variant="danger" data={errorForm} />
                          </div>
                        )}
                        <Form.Group>
                          <Form.Label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="17"
                              height="17"
                              style={{
                                marginRight: '4px',
                                marginBottom: '2px'
                              }}
                            >
                              <path
                                d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                            Image :
                          </Form.Label>
                          {selectedObject ? (
                            <FileUploader
                              pathToUpload="vismaroute"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="image_url"
                              cropperEnabled={false}
                              currentImage={selectedObject.image_url}
                            />
                          ) : (
                            <FileUploader
                              pathToUpload="vismaroute"
                              formData={formData}
                              setFormData={setFormData}
                              format="img"
                              databaseField="image_url"
                              cropperEnabled={false}
                            />
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="name">
                          <Form.Label>Titre :</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Titre du thème"
                            value={formData.title || ''}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group controlId="content">
                          <Form.Label>Description :</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="content"
                            value={formData.content || ''}
                            rows={3}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      {objects.length > 0 && (
                        <>
                          <Col md="12">
                            <Form.Group controlId="parent_id">
                              <Form.Label>
                                Thème parent <small>(facultatif)</small> :
                              </Form.Label>
                              <Form.Select
                                aria-label="Catégorie parent"
                                name="parent_id"
                                value={formData.parent_id || ''}
                                onChange={handleFieldChange}
                              >
                                <option></option>
                                <SelectCategories
                                  categories={objects}
                                  indent={0}
                                />
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md="12">
                            <Form.Group controlId="order">
                              <Form.Label>Ordre :</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="0"
                                  aria-label="Ordre de la catégorie"
                                  aria-describedby="basic-addon2"
                                  type="number"
                                  onWheel={e => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  name="order"
                                  value={formData.order || ''}
                                  onChange={handleFieldChange}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      onClick={() => {
                        setShowAddCategory(false);
                      }}
                    >
                      Annuler
                    </Button>
                    <Button
                      size="sm"
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                      className="me-2 admin-primary"
                      onClick={() => {
                        //
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        style={{ marginBottom: '2px', marginRight: '4px' }}
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                      Enregistrer
                    </Button>
                    {selectedObject.id !== null &&
                      selectedObject.id !== undefined && (
                        <Button
                          size="sm"
                          variant="danger"
                          className="me-0 float-end"
                          type="button"
                          onClick={() => {
                            setShowCategoryDelete(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '2px'
                            }}
                          >
                            <path
                              d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </Button>
                      )}
                  </Form>
                </Offcanvas.Body>
              </Offcanvas>
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                as={Link}
                to="/administration/vis-ma-route/ajouter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter un contenu
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialObjects.length > 0 && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    L'archivage du contenu "{`${selectedObject.title}`}" est
                    définitif.
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas
            show={showCategoryDelete}
            onHide={() => {
              setShowCategoryDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>
                    L'archivage du thème "{`${selectedObject.title}`}" est
                    définitif. Tous ses contenus seront archivés.
                  </>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteCategory();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowCategoryDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <VisMaRouteCategoryBlock
            categories={objects}
            indent={0}
            setSelectedObject={setSelectedObject}
            setShowDelete={setShowDelete}
            setShowAddCategory={setShowAddCategory}
            setFormData={setFormData}
          />
        </>
      )}
    </>
  );
};

const VisMaRouteCategoryBlock = ({
  categories,
  indent,
  setShowDelete,
  setSelectedObject,
  setShowAddCategory,
  setFormData
}) => {
  return (
    <>
      {categories.map((category, index) => (
        <div
          key={index}
          className={classNames('d-block mb-3', {
            'mt-4': indent === 0,
            'mt-3': indent > 0
          })}
        >
          <h5
            className={`admin-title companyRightManagePeople ps-${indent * 3}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginRight: '4px', marginBottom: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M3 21a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H20a1 1 0 0 1 1 1v3h-2V7h-7.414l-2-2H4v11.998L5.5 11h17l-2.31 9.243a1 1 0 0 1-.97.757H3zm16.938-8H7.062l-1.5 6h12.876l1.5-6z"
                fill="rgba(75,74,77,1)"
              />
            </svg>
            {indent > 0 && '>'} {category.title}{' '}
            {category.contents.length > 0 && (
              <Badge bg="secondary" className="me-2">
                {category.contents.length}
              </Badge>
            )}
            <span
              className="cursor-pointer"
              onClick={() => {
                setSelectedObject(category);
                setFormData({
                  title: category.title,
                  content: category.content !== null ? category.content : '',
                  image_url: category.image_url,
                  parent_id: category.parent_id,
                  order: category.order
                });
                setShowAddCategory(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{
                  marginBottom: '0px',
                  marginRight: '3px'
                }}
              >
                <path
                  d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                  fill="rgba(44,123,229,1)"
                />
              </svg>
            </span>
          </h5>
          <div className={`mt-3 ps-${indent * 3}`}>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonry-grid actualites"
              columnClassName="masonry-grid_column"
            >
              {category.contents.map((object, index) => (
                <Fragment key={index}>
                  <div key={index} xs={6} xxl={3} className="mb-3 training">
                    <Card className="h-100">
                      {object.image_url !== null &&
                        object.image_url !== undefined && (
                          <Card.Img src={object.image_url} variant="top" />
                        )}
                      <Card.Body>
                        <Card.Title as="h5" className="mt-0">
                          {object.title}
                        </Card.Title>
                        <Card.Text>
                          {object.content && object.content.length > 100
                            ? object.content.substring(0, 97) + '...'
                            : object.content}
                        </Card.Text>

                        <SoftBadge bg="primary" className="me-2">
                          Ordre : {object.order}
                        </SoftBadge>
                        <div className="d-block mb-3"></div>
                        <Button
                          size="sm"
                          variant="primary"
                          className="me-2 admin-primary"
                          type="button"
                          onClick={() => {
                            window.location.href = `/administration/vis-ma-route/${object.id}/modifier`;
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '2px',
                              marginRight: '3px'
                            }}
                          >
                            <path
                              d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          className="me-0"
                          type="button"
                          onClick={() => {
                            setShowDelete(true);
                            setSelectedObject(object);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '2px'
                            }}
                          >
                            <path
                              d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                </Fragment>
              ))}
            </Masonry>
          </div>
          {category.childrens.length > 0 && (
            <VisMaRouteCategoryBlock
              categories={category.childrens}
              indent={indent + 1}
              setShowDelete={setShowDelete}
              setSelectedObject={setSelectedObject}
              setShowAddCategory={setShowAddCategory}
              setFormData={setFormData}
            />
          )}
        </div>
      ))}
    </>
  );
};

const SelectCategories = ({ categories, indent }) => {
  return (
    <>
      {categories.map((c, index) => (
        <Fragment key={index}>
          <option value={c.id}>
            {indent > 0 &&
              Array.apply(0, Array(indent)).map(function () {
                return '-';
              })}
            {c.title}
          </option>
          {c.childrens.length > 0 && (
            <SelectCategories categories={c.childrens} indent={indent + 1} />
          )}
        </Fragment>
      ))}
    </>
  );
};

SelectCategories.propTypes = {
  categories: PropTypes.array,
  indent: PropTypes.number
};

VisMaRouteCategoryBlock.propTypes = {
  categories: PropTypes.array,
  indent: PropTypes.number,
  setShowDelete: PropTypes.func,
  setSelectedObject: PropTypes.func,
  setShowAddCategory: PropTypes.func,
  setFormData: PropTypes.func
};

export default IndexVisMaRoute;
