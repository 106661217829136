import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import waitImage from 'assets/img/tredunion/wait-tred1.jpeg';

const Hero = ({ homeSlides }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="position-relative h-sm-auto overflow-hidden" id="accueil">
      {homeSlides.length ? (
        <Slider {...sliderSettings} className="slick-slider-arrow-inner">
          {homeSlides.map((slide, index) => (
            <div key={index}>
              <div
                className="carousel-tred"
                style={{
                  backgroundImage: `url('${slide.image_url}')`
                }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...sliderSettings} className="slick-slider-arrow-inner">
          <div>
            <div
              className="carousel-tred"
              style={{
                backgroundImage: `url('${waitImage}')`
              }}
            />
          </div>
        </Slider>
      )}
    </div>
  );
};

Hero.propTypes = {
  homeSlides: PropTypes.array
};

export default Hero;
