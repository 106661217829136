import { toast } from 'react-toastify';

export default function RequireAuthAdmin(navigate) {
  console.log(JSON.parse(localStorage.getItem('user')));
  if (!localStorage.getItem('accessToken') && !localStorage.getItem('user')) {
    toast('Veuillez vous connecter pour accéder au contenu');
    console.log('RequireAuthAdmin');
    navigate('/login');
  } else if (
    JSON.parse(localStorage.getItem('user')).is_admin === null ||
    JSON.parse(localStorage.getItem('user')).is_admin === undefined ||
    JSON.parse(localStorage.getItem('user')).is_admin === 0
  ) {
    toast("Vous n'êtes pas autorisé à accéder à cette page.");
    console.log('RequireAuthAdmin');
    navigate('/');
  }
}
