import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'trade-press-posts';

export async function IndexTradePresse() {
  return modelService.IndexModel(`${model}`);
}

export async function ShowTradePress(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostTradePress(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutTradePress(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteTradePress(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
