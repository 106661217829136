import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import '../../../../../assets/scss/admin.scss';
import * as Moment from 'moment';
import Button from 'react-bootstrap/Button';
import {
  PostEvent,
  PutEvent
} from '../../../../../services/Event/EventService';
import FormAlert from '../../../commons/FormAlert';
import { toast } from 'react-toastify';
import FileUploader from '../../../commons/fileUploader';

const ModalRecap = ({
  setModalShow,
  modalShow,
  formData,
  setFormData,
  selectedType,
  survey,
  questions,
  questionGroups,
  participationFormIsCompleted,
  event
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [disableValidation, setDisableValidation] = useState(false);
  const [trigger, setTrigger] = useState(Date.now());
  const handleSubmit = async e => {
    e.preventDefault();
    setDisableValidation(true);
    setSuccessForm(false);
    setIsLoading(false);
    let tmpFormData = { ...formData };
    tmpFormData.start_date = Moment(formData.start_date)
      .local()
      .format('YYYY-MM-DD');
    tmpFormData.end_date = Moment(formData.end_date)
      .local()
      .format('YYYY-MM-DD');
    if (participationFormIsCompleted === true) {
      let tmpSurvey = { ...survey };
      if (tmpSurvey.starts_on) {
        tmpSurvey.starts_on = Moment(tmpSurvey.starts_on)
          .local()
          .set('hour', 0)
          .set('minute', 1)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (tmpSurvey.expires_on) {
        tmpSurvey.expires_on = Moment(tmpSurvey.expires_on)
          .local()
          .set('hour', 23)
          .set('minute', 50)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      if (tmpSurvey.dunning_starts_on) {
        tmpSurvey.dunning_starts_on = Moment(tmpSurvey.dunning_starts_on)
          .local()
          .set('hour', 0)
          .set('minute', 1)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      tmpFormData.survey = tmpSurvey;
      tmpFormData.questions = questions;
      tmpFormData.questionGroups = questionGroups;
    }
    let response = null;
    if (formData.id) {
      tmpFormData.context = 'admin.update';
      tmpFormData.is_validated = 1;
      response = await PutEvent(formData.id, tmpFormData);
    } else {
      tmpFormData.context = 'admin.store';
      response = await PostEvent(tmpFormData);
    }

    if (response.success === true) {
      const successMessage = () =>
        tmpFormData.context === 'admin.update'
          ? 'Modifications effectuées'
          : `Événement ajouté avec succès`;
      toast(successMessage);
      setSuccessForm(successMessage);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);

      setTimeout(function () {
        window.location.href = `/administration/evenements/${response.data.id}`;
      }, 1500);
    } else {
      toast(
        'Une erreur est survenue, veuillez vérifier votre saisie et que vous avez bien configuré chaque question du formulaire de participation (si concerné)'
      );
      setIsLoading(false);
      setDisableValidation(false);
    }
  };
  useEffect(() => {
    //
  }, [trigger]);
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          // closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <b>Récapitulatif de l'événement</b>
        </Modal.Header>
        <Modal.Body>
          {successForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          <Alert variant="primary">
            <b>{formData.title}</b> de type <b>{selectedType.label}</b>, le{' '}
            <b>{Moment(formData.start_date).local().format('DD/MM/YYYY')}</b> à{' '}
            <b>{formData.start_time}</b> et terminant le{' '}
            <b>{Moment(formData.end_date).local().format('DD/MM/YYYY')}</b> à{' '}
            <b>{formData.end_time}</b>{' '}
            {formData.location_label ? (
              <>
                situé à : <b>{formData.location_label}</b>
              </>
            ) : (
              ''
            )}
          </Alert>
          {((formData.recipient_users && formData.recipient_users.length > 0) ||
            (formData.recipient_externalUsers &&
              formData.recipient_externalUsers.length > 0)) && (
            <Alert variant="primary">
              <b>
                {formData.recipient_users.length +
                  formData.recipient_externalUsers.length}
              </b>{' '}
              personnes sont invitées à cet événement
              {survey &&
              survey.isDeleted !== true &&
              participationFormIsCompleted === true ? (
                <>
                  {' '}
                  et doivent répondre à un formulaire de participation avant le{' '}
                  <b>
                    {survey.expires_on
                      ? Moment(survey.expires_on).local().format('DD/MM/YYYY')
                      : Moment(formData.end_date).local().format('DD/MM/YYYY')}
                  </b>
                  .
                  <br />
                  <br /> Le formulaire de participation sera automatiquement
                  envoyé aux{' '}
                  <b>
                    {formData.recipient_users.length +
                      formData.recipient_externalUsers.length}{' '}
                  </b>
                  destinataires
                  {survey.survey_dunning ? (
                    <>
                      {' '}
                      qui seront relancés automatiquement jusqu'au{' '}
                      <b>
                        {survey.expires_on
                          ? Moment(survey.expires_on)
                              .local()
                              .format('DD/MM/YYYY')
                          : Moment(formData.end_date)
                              .local()
                              .format('DD/MM/YYYY')}
                      </b>
                      .
                    </>
                  ) : (
                    '.'
                  )}
                </>
              ) : (
                '.'
              )}
            </Alert>
          )}
          {formData.is_public === 1 && (
            <Alert variant="primary">
              Cet événement sera <b>visible</b> depuis la page d'accueil du
              site.
            </Alert>
          )}
          {event &&
            event.id &&
            Moment().diff(formData.start_date, 'days') < 1 &&
            (formData.location_label !== event.location_label ||
              formData.link !== event.link ||
              Moment(formData.start_date).local().format('YYYY-MM-DD') !==
                event.start_date ||
              Moment(formData.end_date).local().format('YYYY-MM-DD') !==
                event.end_date ||
              `${formData.start_time}:00` !== event.start_time ||
              `${formData.end_time}:00` !== event.end_time) && (
              <Col xs={12} className="mt-3">
                <label>
                  <input
                    type="checkbox"
                    id="sendMailUpdate"
                    name="sendMailUpdate"
                    value="1"
                    className="me-1"
                    checked={
                      formData.sendMailUpdate === 1 ||
                      formData.sendMailUpdate === '1'
                    }
                    onChange={e => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked ? 1 : 0
                      });
                    }}
                  />
                  Envoyer les modifications aux destinataires ?
                </label>
              </Col>
            )}
          {!participationFormIsCompleted &&
            ((formData.recipient_externalUsers &&
              formData.recipient_externalUsers.length > 0) ||
              (formData.recipient_informativeUsers &&
                formData.recipient_informativeUsers.length > 0) ||
              (formData.recipient_users &&
                formData.recipient_users.length > 0)) && (
              <Col xs={12} className="mt-3">
                <label>
                  <input
                    type="checkbox"
                    id="send_new_event_to_recipients"
                    name="send_new_event_to_recipients"
                    value="1"
                    className="me-1"
                    checked={
                      formData.send_new_event_to_recipients === 1 ||
                      formData.send_new_event_to_recipients === '1'
                    }
                    onChange={e => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.checked ? 1 : 0,
                        attachment_url: ''
                      });
                    }}
                  />
                  {event && event.id
                    ? "Envoyer les notifications de l'événement aux nouveaux destinataires (si vous en avez ajouté) ?"
                    : 'Envoyer les notifications du nouvel événement aux\n' +
                      '                  destinataires ?'}
                </label>
                {(formData.send_new_event_to_recipients === 1 ||
                  formData.send_new_event_to_recipients === '1') &&
                  (!event || (event && !event.id)) && (
                    <>
                      <Row>
                        <Col lg="12" className="pb-2 pt-2 mb-2">
                          <div className="border-dashed-bottom"></div>
                        </Col>
                      </Row>
                      <Col xs={12}>
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784L18 17a6 6 0 0 0-11.996-.225L6 17v1.978a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Transmettre des pièces jointes{' '}
                          <small>facultatif</small>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="overlay-trigger-example">
                                Cette pièce jointe sera transmise à tous les
                                destinataires, dans le premier mail
                                d'invitation.
                              </Tooltip>
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                                fill="rgba(100,99,99,1)"
                              />
                            </svg>
                          </OverlayTrigger>
                        </Form.Label>
                        {formData.attachments && (
                          <>
                            {formData.attachments.map((attachment, index) => (
                              <Row key={index} className="align-items-center">
                                <Col xs={5} className="pt-2">
                                  <FileUploader
                                    pathToUpload="events/notifications"
                                    formData={formData}
                                    setFormData={setFormData}
                                    format="any"
                                    databaseField="attachment_url"
                                    databaseFieldArray="attachments"
                                    databaseFieldArrayIndex={index}
                                    cropperEnabled={false}
                                    progressBar={true}
                                  />
                                </Col>
                                <Col xs={5} className="pt-2 px-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      const newAttachments =
                                        formData.attachments.filter(
                                          (attachment, i) => i !== index
                                        );
                                      setFormData({
                                        ...formData,
                                        attachments: newAttachments
                                      });
                                    }}
                                  >
                                    <path
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                      fill="#e52f12"
                                    />
                                  </svg>
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                        <Row>
                          <Col xs={12} className="pt-2">
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="mt-2 mb-3"
                              onClick={() => {
                                let tmpFormData = formData;
                                if (!tmpFormData.attachments) {
                                  tmpFormData.attachments = [];
                                }
                                tmpFormData.attachments.push({
                                  file_url: ''
                                });
                                setFormData(tmpFormData);
                                setTrigger(Date.now());
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                style={{
                                  marginBottom: '2px',
                                  marginRight: '2px'
                                }}
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                  fill="rgba(77,89,105,1)"
                                />
                              </svg>{' '}
                              Ajouter une pièce jointe
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
              </Col>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="admin-primary"
            size="sm"
            onClick={handleSubmit}
            disabled={isLoading || disableValidation}
          >
            {' '}
            {formData.id
              ? "Enregistrer les modifications de l'événement"
              : 'Enregistrer l’événement'}{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalRecap.propTypes = {
  setModalShow: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired,
  formData: PropTypes.object,
  selectedType: PropTypes.any,
  selectedCategory: PropTypes.any,
  survey: PropTypes.object,
  questions: PropTypes.array,
  questionGroups: PropTypes.array,
  participationFormIsCompleted: PropTypes.bool,
  event: PropTypes.object,
  setFormData: PropTypes.func.isRequired
};

export default ModalRecap;
