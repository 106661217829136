import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { IndexTradePresse } from '../../../../services/PostTradePressService';
import * as Moment from 'moment/moment';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import tredPlaceholder from '../../../../assets/img/tredunion/tred-placeholder.jpg';

const IndexTradePressMembers = () => {
  const [pressPosts, setPressPosts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchedPosts, setSearchedPosts] = useState([]);

  useEffect(() => {
    const fetchObjects = async () => {
      const response = await IndexTradePresse();
      if (response.success === true) {
        setPressPosts(response.data);
        setSearchedPosts(response.data);
        applySearch(searchInput, response.data);
      }
    };
    fetchObjects();
  }, []);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };

  const applySearch = (value, objects) => {
    if (value) {
      const searchObjects = objects.filter(object => {
        return (
          object.title
            .toLowerCase()
            .replace('.', '')
            .indexOf(value.toLowerCase().replace('.', '')) > -1
        );
      });
      setSearchedPosts(searchObjects);
    } else {
      setSearchedPosts(objects);
    }
  };

  return (
    <div style={{ minHeight: '50vh' }}>
      <Col xs={12} className="mt-2">
        <h5 className="mb-4 redHeading d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="me-1"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 4h3v10H6V7zm4 0h2v10h-2V7zm3 0h1v10h-1V7zm2 0h3v10h-3V7z"
              fill="rgba(229, 47, 18, 1)"
            />
          </svg>
          Presse spécialisée
        </h5>
      </Col>

      <Col xs={12} className="mb-3">
        <Form.Control
          type="search"
          className="search-input"
          placeholder="Rechercher quelque chose..."
          aria-label="Search"
          value={searchInput}
          size="sm"
          onChange={e => {
            const inputValue = e.target.value;
            setSearchInput(inputValue);
            applySearch(inputValue, pressPosts);
          }}
        />
      </Col>

      <Col xs={12}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid actualites"
          columnClassName="masonry-grid_column"
        >
          {searchedPosts.map((post, index) => (
            <Card key={index} style={{ backgroundColor: 'white' }}>
              {post.medias !== null &&
                post.medias.length > 0 &&
                post.medias.filter(media =>
                  ['jpg', 'jpeg', 'png', 'gif'].includes(
                    media.file_url.split('.').pop().toLowerCase()
                  )
                ).length > 0 && (
                  <Card.Img
                    onClick={() => {
                      window.open(
                        `/espace-membre/presse-specialisee/${post.id}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                    className="cursor-pointer"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = tredPlaceholder;
                    }}
                    src={
                      post.medias.filter(media =>
                        ['jpg', 'jpeg', 'png', 'gif'].includes(
                          media.file_url.split('.').pop().toLowerCase()
                        )
                      )[0].file_url
                    }
                    variant="top"
                  />
                )}
              <Card.Body>
                <Card.Title as="h5">
                  <span className="post-date d-block pb-2">
                    {Moment(post.created_at)
                      .locale('fr')
                      .format('DD MMMM YYYY')}
                  </span>
                  {post.title}
                </Card.Title>
                <div className="meta-course">
                  <p className="mt-2">{post.content}</p>
                </div>
                <Link to={`/espace-membre/presse-specialisee/${post.id}`}>
                  <Button color="primary" size="sm">
                    Lire
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          ))}
        </Masonry>
      </Col>
    </div>
  );
};

export default IndexTradePressMembers;
