import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal } from 'react-bootstrap';
import '../../../../../assets/scss/admin.scss';
import * as Moment from 'moment';
import Button from 'react-bootstrap/Button';
import FormAlert from '../../../commons/FormAlert';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  PostSurvey,
  PutSurvey
} from '../../../../../services/Survey/SurveyService';

const ModalRecap = ({
  setModalShow,
  modalShow,
  formData,
  survey,
  questions,
  questionGroups,
  surveyFormIsCompleted,
  id
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [disableValidation, setDisableValidation] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    setDisableValidation(true);
    setSuccessForm(false);
    setIsLoading(false);
    let tmpFormData = { ...formData };
    tmpFormData.survey = survey;
    tmpFormData.survey.title = formData.title;
    tmpFormData.survey.questions = questions;
    tmpFormData.survey.questionGroups = questionGroups;
    if (survey.starts_on) {
      tmpFormData.survey.starts_on = Moment(survey.starts_on)
        .local()
        .set('hour', 1)
        .set('minute', 1)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (survey.expires_on) {
      tmpFormData.survey.expires_on = Moment(survey.expires_on)
        .local()
        .set('hour', 23)
        .set('minute', 50)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (survey.dunning_starts_on) {
      tmpFormData.survey.dunning_starts_on = Moment(survey.dunning_starts_on)
        .local()
        .set('hour', 1)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      tmpFormData.survey.dunning_starts_on = Moment()
        .local()
        .set('hour', 1)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    let response = null;
    if (id) {
      tmpFormData.context = 'admin.update';
      response = await PutSurvey(id, tmpFormData);
    } else {
      tmpFormData.context = 'admin.store';
      response = await PostSurvey(tmpFormData);
    }
    if (response.success === true) {
      let success = 'Contenu enregistré';
      toast(success);
      setSuccessForm(success);
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 250);
      setTimeout(function () {
        window.location.href = `/administration/questionnaires/${response.data.id}`;
      }, 1500);
    }
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onShow={() => setDisableValidation(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <b>Récapitulatif du questionnaire</b>
        </Modal.Header>
        <Modal.Body>
          {successForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          <Alert variant="primary">
            <b>{survey.title}</b>
          </Alert>
          {((formData.recipient_users && formData.recipient_users.length > 0) ||
            (formData.recipient_externalUsers &&
              formData.recipient_externalUsers.length > 0)) && (
            <Alert variant="primary">
              <b>
                {formData.recipient_users.length +
                  formData.recipient_externalUsers.length}
              </b>{' '}
              personnes sont invitées à répondre à ce questionnaire
              {survey &&
              survey.isDeleted !== true &&
              surveyFormIsCompleted === true ? (
                <>
                  {' '}
                  avant le{' '}
                  <b>
                    {survey.expires_on
                      ? Moment(survey.expires_on).local().format('DD/MM/YYYY')
                      : Moment(formData.end_date).local().format('DD/MM/YYYY')}
                  </b>
                  .
                  <br />
                  <br /> Le questionnaire sera automatiquement envoyé{' '}
                  <b>
                    {survey.starts_on
                      ? moment(survey.starts_on).format('L') ===
                        Moment().local().format('DD/MM/YYYY')
                        ? "aujourd'hui"
                        : `le ${Moment(survey.starts_on)
                            .local()
                            .format('DD/MM/YYYY')}`
                      : "aujourd'hui"}
                  </b>{' '}
                  aux{' '}
                  <b>
                    {formData.recipient_users.length +
                      formData.recipient_externalUsers.length}{' '}
                  </b>
                  destinataires
                  {survey.survey_dunning ? (
                    <>
                      {' '}
                      qui seront relancés automatiquement jusqu'au{' '}
                      <b>
                        {survey.expires_on
                          ? Moment(survey.expires_on)
                              .local()
                              .format('DD/MM/YYYY')
                          : Moment(formData.end_date)
                              .local()
                              .format('DD/MM/YYYY')}
                      </b>
                      .
                    </>
                  ) : (
                    '.'
                  )}
                </>
              ) : (
                '.'
              )}
            </Alert>
          )}
          {formData.is_public === 1 && (
            <Alert variant="primary">
              Cet événement sera <b>visible</b> depuis la page d'accueil du
              site.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="admin-primary"
            size="sm"
            onClick={handleSubmit}
            disabled={isLoading || disableValidation}
          >
            {' '}
            {formData.id
              ? 'Enregistrer les modifications du questionnaire'
              : 'Enregistrer le questionnaire'}{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ModalRecap.propTypes = {
  setModalShow: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired,
  formData: PropTypes.object,
  survey: PropTypes.object,
  questions: PropTypes.array,
  questionGroups: PropTypes.array,
  surveyFormIsCompleted: PropTypes.bool,
  id: PropTypes.string
};

export default ModalRecap;
