import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'adv-tracks';

export async function PostAdTrack(formData) {
  return modelService.PostModel(model, formData);
}

export async function IndexAdTrack(ad) {
  return modelService.IndexModel(`${model}?ad_id=${ad}`);
}
