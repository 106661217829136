import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Carousel, Col, Row } from 'react-bootstrap';

import { useParams } from 'react-router';
import { ShowTradePress } from '../../../../services/PostTradePressService';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Moment from 'moment';
import downloadPdf from '../../../../assets/img/tredunion/download-pdf.png';
import { ShowAd } from '../../../../services/AdService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PostAdTrack } from '../../../../services/AdTrackService';

const ShowAdMember = () => {
  const { id } = useParams();
  const [ad, setAd] = useState({});

  useEffect(() => {
    const fetchOne = async () => {
      const response = await ShowAd(id);
      if (response.success) {
        setAd(response.data);
        await PostAdTrack({
          ad_id: response.data.id
        });
      }
    };
    if (id) {
      fetchOne();
    }
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className="fw-bold mt-3">{ad.title}</h4>
          <Badge pill bg="primary" className="mb-2">
            {`${
              ad.category?.parent?.name ? `${ad.category.parent.name} > ` : ''
            }${ad.category?.name}`}
          </Badge>
          <span className="post-date d-block pb-2">
            {Moment(ad.validated_at ?? ad.created_at)
              .locale('fr')
              .format('DD MMMM YYYY')}
          </span>
        </div>

        <Button
          color="primary"
          className="mb-3"
          onClick={() => {
            window.location.href = `/espace-membre/annonces`;
          }}
        >
          Retour à la liste
        </Button>
      </div>

      <Card className="my-3">
        <Card.Body>
          {ad.pictures !== undefined && (
            <Carousel
              className="theme-slider"
              nextIcon={<FontAwesomeIcon icon="angle-right" />}
              prevIcon={<FontAwesomeIcon icon="angle-left" />}
            >
              {ad.pictures
                .filter(media =>
                  ['jpg', 'jpeg', 'png', 'gif'].includes(
                    media.file_url.split('.').pop().toLowerCase()
                  )
                )
                .map((media, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={media.file_url}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          )}
          {ad.price && (
            <Col className="mt-3">
              <p className="mt-3">
                <span className="fw-bold">Prix : </span>
                {ad.price}
              </p>
            </Col>
          )}
          <Col className="mt-3">
            <b>Auteur :</b> {ad.user?.firstname} {ad.user?.lastname} (
            {ad.company?.name})
          </Col>
          <Col className="mt-3">
            <p
              style={{ fontSize: 'initial', margin: 0 }}
              dangerouslySetInnerHTML={{ __html: ad.description }}
            ></p>
          </Col>
          {ad.contact_name && (
            <Col className="mt-3">
              <p>
                <span className="fw-bold">Nom du contact : </span>
                {ad.contact_name}
              </p>
            </Col>
          )}
          {ad.contact_phone && (
            <Col className="mt-3">
              <p>
                <span className="fw-bold">Téléphone : </span>
                {ad.contact_phone}
              </p>
            </Col>
          )}
          {ad.contact_email && (
            <Col className="mt-3">
              <p>
                <span className="fw-bold">Email : </span>
                {ad.contact_email}
              </p>
            </Col>
          )}
          <Col className="d-none">
            <a
              href={`mailto:${ad.user?.email}?subject=À propos de l'annonce ${ad.title} sur Tred Union`}
            >
              <Button color="primary" className="mb-3">
                Contacter le vendeur
              </Button>
            </a>
          </Col>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ShowAdMember;
