import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faHandshake } from '@fortawesome/free-regular-svg-icons/faHandshake';

const NavbarVerticalMenuItem = ({
  route,
  resourcesNotModerated,
  eventNumberIsNotValidated,
  contractsInvoicesToProcess
}) => {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          {route.icon === 'files' ? (
            <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={faFileAlt} />
          ) : route.icon === 'file-invoice' ? (
            <FontAwesomeIcon
              style={{ marginLeft: '4px' }}
              icon={faFileInvoice}
            />
          ) : route.icon === 'barecode' ? (
            <FontAwesomeIcon style={{ marginLeft: '3px' }} icon={faBarcode} />
          ) : route.icon === 'folder' ? (
            <FontAwesomeIcon
              style={{ marginLeft: '1px' }}
              icon={faFolderOpen}
            />
          ) : route.icon === 'question' ? (
            <FontAwesomeIcon
              style={{ marginLeft: '3px' }}
              icon={faQuestionCircle}
            />
          ) : route.icon === 'handshake' ? (
            <FontAwesomeIcon style={{ marginLeft: '3px' }} icon={faHandshake} />
          ) : (
            <FontAwesomeIcon icon={route.icon} />
          )}
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.name === 'Ressources' && resourcesNotModerated > 0 && (
        <SoftBadge pill bg="warning" className="ms-2">
          {resourcesNotModerated}
        </SoftBadge>
      )}
      {route.name === 'Calendrier' && eventNumberIsNotValidated > 0 && (
        <SoftBadge pill bg="warning" className="ms-2">
          {eventNumberIsNotValidated}
        </SoftBadge>
      )}
      {route.name === 'Gestion Partenaires' && contractsInvoicesToProcess > 0 && (
        <SoftBadge pill bg="warning" className="ms-2">
          {contractsInvoicesToProcess}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
  resourcesNotModerated: PropTypes.number,
  eventNumberIsNotValidated: PropTypes.number,
  contractsInvoicesToProcess: PropTypes.number
};

export default React.memo(NavbarVerticalMenuItem);
