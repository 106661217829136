import React, { useEffect, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';
import {
  Alert,
  Button,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { IndexRoles } from '../../../../../../services/RoleService';
import { IndexUserAdminSortByLastname } from '../../../../../../services/UserService';
import {
  IndexAdmin,
  IndexAdminInvoices
} from '../../../../../../services/CompanyService';
import PropTypes from 'prop-types';
import {
  DeleteRecipientTemplate,
  IndexRecipientTemplate,
  PostRecipientTemplate,
  PutRecipientTemplate
} from '../../../../../../services/RecipientTemplateService';
import FormAlert from '../../../../commons/FormAlert';
import IndexExternalRecipients from './externals';
import {
  IndexExtRec,
  PostExtRec
} from '../../../../../../services/ExternalRecipientService';
import ManageRecipientsCompanies from './ManageRecipientsCompanies';
import { IndexPartnerRole } from '../../../../../../services/PartnerRoleService';
import moment from 'moment';
const ManageRecipients = ({
  formData,
  setFormData,
  isEvent,
  manageLinkCompanies
}) => {
  const [showImportTemplate, setShowImportTemplate] = useState(false);
  const [showExportTemplate, setShowExportTemplate] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPastAdresses, setShowPastAdresses] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [roles, setRoles] = useState([]);
  const [partnerRoles, setPartnerRoles] = useState([]);
  const [recipientTemplates, setRecipientTemplates] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [usersFromApi, setUsersFromApi] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [externalsFromApi, setExternalsFromApi] = useState([]);
  const [externals, setExternals] = useState([]);
  const [adresses, setAdresses] = useState('');
  const [showIndexExternals, setShowIndexExternals] = useState(false);
  const [templateFormData, setTemplateFormData] = useState({
    title: '',
    recipient_roles: [],
    recipient_partner_roles: [],
    recipient_notifications: [],
    recipient_companies: [],
    recipient_users: [],
    recipient_externalUsers: [],
    recipient_informativeUsers: []
  });

  const handleSubmitTemplate = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let tmpTemplateFormData = templateFormData;
    tmpTemplateFormData.recipient_roles = formData.recipient_roles;
    tmpTemplateFormData.recipient_partner_roles =
      formData.recipient_partner_roles;
    tmpTemplateFormData.recipient_notifications =
      formData.recipient_notifications;
    tmpTemplateFormData.recipient_companies = formData.recipient_companies;
    tmpTemplateFormData.recipient_users = formData.recipient_users;
    tmpTemplateFormData.recipient_externalUsers =
      formData.recipient_externalUsers;
    tmpTemplateFormData.recipient_informativeUsers =
      formData.recipient_informativeUsers;
    let response = false;
    if (selectedTemplate.id) {
      response = await PutRecipientTemplate(
        selectedTemplate.id,
        tmpTemplateFormData
      );
    } else {
      response = await PostRecipientTemplate(tmpTemplateFormData);
    }
    setErrorForm(response.validator);
    setIsLoading(false);
    if (response.success === true) {
      toast('Liste enregistrée.');
      setTrigger(Date.now());
      setShowExportTemplate(false);
      setSelectedTemplate(response.data);
      setTemplateFormData({
        title: response.data.title,
        recipient_roles: [],
        recipient_partner_roles: [],
        recipient_notifications: [],
        recipient_companies: [],
        recipient_users: [],
        recipient_externalUsers: [],
        recipient_informativeUsers: []
      });
    }
  };

  const handleSubmitPastAdresses = async e => {
    e.preventDefault();
    setErrorForm(false);
    setIsLoading(true);
    let adressesArray = [];
    let regex = /[A-Za-z0-9]+[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/g;
    let m;
    while ((m = regex.exec(adresses)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      m.forEach(match => {
        if (match !== null && match !== undefined && match !== '') {
          adressesArray.push(match);
        }
      });
    }
    let usersArray = [];
    usersFromApi.map(u => {
      adressesArray.map(a => {
        if (u.email === a) {
          usersArray.push(u);
        }
      });
    });
    usersArray.map(user => {
      if (
        formData.recipient_users.find(u => u.value === `${user.id}`) ===
        undefined
      ) {
        setFormData(prevState => ({
          ...prevState,
          recipient_users: [
            ...prevState.recipient_users,
            {
              label:
                user.partner && user.partner.id
                  ? `${user.email} - PARTENAIRE - ${user.partner.name}`
                  : `${user.lastname} ${user.firstname} ${
                      user.main_company ? ` - ${user.main_company.name}` : ''
                    }`,
              value: `${user.id}`
            }
          ]
        }));
      }
    });
    let externalsArray = [];
    externalsFromApi.map(u => {
      adressesArray.map(a => {
        if (u.email === a) {
          externalsArray.push(u);
        }
      });
    });
    externalsArray.map(user => {
      if (
        formData.recipient_externalUsers.find(u => u.value === user.id) ===
        undefined
      ) {
        setFormData(prevState => ({
          ...prevState,
          recipient_externalUsers: [
            ...prevState.recipient_externalUsers,
            {
              value: user.id,
              label: `${user.company_name || ''} ${user.lastname || ''} ${
                user.firstname || ''
              } ${user.email}`
            }
          ]
        }));
      }
    });
    // check all adressesArray that are not in usersFromApi or externalsFromApi
    let adressesArrayFiltered = adressesArray.filter(
      a =>
        usersFromApi.find(u => u.email === a) === undefined &&
        externalsFromApi.find(u => u.email === a) === undefined
    );
    if (adressesArrayFiltered.length > 0) {
      adressesArrayFiltered.map(async a => {
        const response = await PostExtRec({
          email: a
        });
        setFormData(prevState => ({
          ...prevState,
          recipient_externalUsers: [
            ...prevState.recipient_externalUsers,
            {
              value: response.data.id,
              label: `${response.data.email}`
            }
          ]
        }));
      });
    }
    setShowPastAdresses(false);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      const responseObjects = await IndexRoles();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.id,
            label: r.title
          });
        });
        setRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPartnerRoles = async () => {
      const responseObjects = await IndexPartnerRole();
      if (responseObjects.success === true) {
        let tmpRoles = [];
        responseObjects.data.map(r => {
          tmpRoles.push({
            value: r.id,
            label: r.title
          });
        });
        setPartnerRoles(tmpRoles);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCompanies = async () => {
      const responseCompanies = await IndexAdmin();
      if (responseCompanies.success === true) {
        let companiesArray = [];
        responseCompanies.data.map(company => {
          companiesArray.push({
            value: `${company.id}`,
            label: company.name
          });
          company.childrens.map(children => {
            companiesArray.push({
              value: `${children.id}`,
              label: `-- ${children.name}`
            });
          });
        });
        setCompanies(companiesArray);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchUsers = async () => {
      const responseObjects = await IndexUserAdminSortByLastname();
      if (responseObjects.success === true) {
        setUsersFromApi(responseObjects.data);
        let tmpUsers = [];
        responseObjects.data.map(u => {
          tmpUsers.push({
            value: u.id,
            label:
              u.partner && u.partner.id
                ? `${u.email} - PARTENAIRE - ${u.partner.name}`
                : `${u.lastname} ${u.firstname} ${
                    u.main_company ? ` - ${u.main_company.name}` : ''
                  }`
          });
        });
        setUsers(tmpUsers);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchExternals = async () => {
      const responseObjects = await IndexExtRec();
      if (responseObjects.success === true) {
        if (
          formData.recipient_externalUsers &&
          formData.recipient_externalUsers.length > 0
        ) {
          let tmpExt = formData.recipient_externalUsers;
          setFormData({
            ...formData,
            recipient_externalUsers: tmpExt.filter(ext => {
              return responseObjects.data.map(u => u.id).includes(ext.value);
            })
          });
        }
        let tmpExternals = [];
        setExternalsFromApi(responseObjects.data);
        responseObjects.data.map(u => {
          tmpExternals.push({
            value: u.id,
            label: `${u.company_name || ''} ${u.lastname || ''} ${
              u.firstname || ''
            } ${u.email}`
          });
        });
        setExternals(tmpExternals);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchRecipientTemplates = async () => {
      const responseObjects = await IndexRecipientTemplate();
      if (responseObjects.success === true) {
        setRecipientTemplates(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchRoles();
    fetchPartnerRoles();
    fetchCompanies();
    fetchUsers();
    fetchExternals();
    fetchRecipientTemplates();
  }, [trigger]);

  const deleteTemplate = async template => {
    const response = await DeleteRecipientTemplate(template.id);
    if (
      selectedTemplate &&
      selectedTemplate.id &&
      selectedTemplate.id === template.id
    ) {
      setSelectedTemplate({});
    }
    if (response.success === true) {
      toast('Liste supprimée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Col xs={12}>
        <Form.Label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            style={{ marginRight: '4px', marginBottom: '2px' }}
          >
            <path
              d="M14 14.252v2.09A6 6 0 0 0 6 22l-2-.001a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 6v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3z"
              fill="rgba(100,99,99,1)"
            />
          </svg>
          Gestion des destinataires
          {usersFromApi &&
            usersFromApi.length > 0 &&
            externalsFromApi &&
            externalsFromApi.length > 0 && (
              <Button
                size="sm"
                variant="falcon-default"
                className="ms-3"
                onClick={() => {
                  setShowPastAdresses(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M7 4V2H17V4H20.0066C20.5552 4 21 4.44495 21 4.9934V21.0066C21 21.5552 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5551 3 21.0066V4.9934C3 4.44476 3.44495 4 3.9934 4H7ZM7 6H5V20H19V6H17V8H7V6ZM9 4V6H15V4H9Z" />
                </svg>
                Coller des adresses
              </Button>
            )}
          <Offcanvas
            show={showPastAdresses}
            onHide={() => {
              setShowPastAdresses(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Coller des adresses email</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form.Group controlId="title">
                <Form.Label>Coller les adresses email :</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  name="adresses"
                  value={adresses || ''}
                  onChange={e => {
                    setAdresses(e.target.value);
                  }}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="admin-primary mt-3"
                size="sm"
                disabled={isLoading}
                onClick={handleSubmitPastAdresses}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>{' '}
                Importer les destinataires
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          {(formData.recipient_roles.length > 0 ||
            formData.recipient_partner_roles?.length > 0 ||
            formData.recipient_notifications.length > 0 ||
            formData.recipient_companies.length > 0 ||
            formData.recipient_users.length > 0 ||
            formData.recipient_externalUsers.length > 0 ||
            formData.recipient_informativeUsers.length > 0) && (
            <>
              <Button
                size="sm"
                variant="falcon-default"
                className="ms-3"
                onClick={() => {
                  setShowExportTemplate(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  {selectedTemplate.id ? (
                    <path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" />
                  ) : (
                    <path d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z" />
                  )}
                </svg>
                {selectedTemplate.id
                  ? 'Mettre à jour la liste'
                  : 'Enregistrer comme liste'}
              </Button>
              {selectedTemplate.id && (
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="ms-3"
                  onClick={() => {
                    setSelectedTemplate({});
                    setTemplateFormData({
                      name: ''
                    });
                    setShowExportTemplate(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    style={{ marginBottom: '2px', marginRight: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z" />
                  </svg>
                  Enregistrer comme nouvelle liste
                </Button>
              )}
            </>
          )}
          {recipientTemplates.length > 0 && (
            <Button
              size="sm"
              variant="falcon-default"
              className="ms-3"
              onClick={() => {
                setShowImportTemplate(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8zm1-8h3l-4 4-4-4h3V8h2v4z" />
              </svg>
              {selectedTemplate.id
                ? `Importer une autre liste`
                : `Importer une liste`}
            </Button>
          )}
        </Form.Label>
      </Col>
      <Col md="12" className="mt-3 mb-3">
        <Row>
          <Col md={6}>
            <Alert variant="primary" className="p-2">
              <Row>
                <Col md={12}>
                  <Form.Group controlId="roles">
                    <Form.Label>
                      Partenaires / membres destinataires{' '}
                      {formData.recipient_users.length > 0 &&
                        `(${formData.recipient_users.length})`}{' '}
                      :{' '}
                      {formData.recipient_users.length > 0 && (
                        <small>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                recipient_users: [],
                                recipient_roles: [],
                                recipient_partner_roles: [],
                                recipient_notifications: [],
                                recipient_companies: []
                              });
                            }}
                          >
                            vider la liste
                          </a>
                        </small>
                      )}
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      options={users}
                      placeholder="Choisir un ou plusieurs utilisateurs..."
                      isMulti
                      id="select-users"
                      name="roles"
                      classNamePrefix="react-select"
                      value={formData.recipient_users}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          recipient_users: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  <Form.Group controlId="roles">
                    <Form.Label>
                      Destinataires externes à la plateforme{' '}
                      {formData.recipient_externalUsers.length > 0 &&
                        `(${formData.recipient_externalUsers.length})`}{' '}
                      :
                      <br />
                      {formData.recipient_externalUsers.length > 0 && (
                        <small>
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                recipient_externalUsers: []
                              });
                            }}
                          >
                            Vider la liste -{' '}
                          </a>
                        </small>
                      )}
                      <small>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            setShowIndexExternals(true);
                          }}
                        >
                          Gérer les externes
                        </a>
                      </small>
                      <IndexExternalRecipients
                        showIndex={showIndexExternals}
                        setShowIndex={setShowIndexExternals}
                        setParentTrigger={setTrigger}
                      />
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      options={externals}
                      placeholder="Choisir un ou plusieurs externes..."
                      isMulti
                      id="select-externals"
                      name="externals"
                      classNamePrefix="react-select"
                      value={formData.recipient_externalUsers}
                      onChange={value => {
                        setFormData({
                          ...formData,
                          recipient_externalUsers: value
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                {isEvent === true && (
                  <Col md={12} className="mt-3">
                    <Form.Group controlId="roles">
                      <Form.Label>
                        Emails des destinataires à informer{' '}
                        {formData.recipient_informativeUsers.length > 0 &&
                          `(${formData.recipient_informativeUsers.length})`}{' '}
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Ces destinataires ne seront pas invités à répondre
                              à un éventuel formulaire ou questionnaire de
                              participation. Ils recevront uniquement un email
                              informatif de l'élément que vous êtes en train de
                              créer ou modifier.
                            </Tooltip>
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            className="ms-1"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                        </OverlayTrigger>
                        <br />
                        {formData.recipient_informativeUsers.length > 0 && (
                          <small>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  recipient_informativeUsers: []
                                });
                              }}
                            >
                              vider la liste
                            </a>
                          </small>
                        )}
                      </Form.Label>
                      <TagsInput
                        value={formData.recipient_informativeUsers}
                        separators={['Enter', 'return', 'Return', ' ', ',']}
                        onChange={data => {
                          setFormData({
                            ...formData,
                            recipient_informativeUsers: data
                          });
                        }}
                        name="informativeUsers"
                        placeHolder="Adresses email..."
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Alert>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="roles">
                  <Form.Label>
                    Ajouter des membres via leur <b>rôle</b>{' '}
                    <small>(autorisations)</small>{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Vous pouvez automatiquement ajouter des utilisateurs
                          dans la liste de gauche en sélectionnant un ou
                          plusieurs rôles (autorisations). Tous les rôles
                          sélectionnés ci-dessous permettront d'inviter les
                          futurs membres correspondants.
                          <br />
                          Exemple : je sélectionne Codir ci-dessous. Dans 3
                          semaines, je créer un nouveau membre Jean Dupont. Si
                          Jean Dupont fait partie du Codir, il vous sera proposé
                          automatiquement de l'ajouter comme destinataire.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={roles}
                    placeholder="Rechercher..."
                    isMulti
                    name="roles"
                    classNamePrefix="react-select"
                    value={formData.recipient_roles}
                    onChange={value => {
                      let tmpUsers = formData.recipient_users;
                      value.map(v => {
                        let usersFilter = usersFromApi.filter(u => {
                          return (
                            u.roles &&
                            u.roles.length > 0 &&
                            u.roles.filter(r => r.id === v.value).length > 0 &&
                            tmpUsers.filter(t => t.value === u.id).length === 0
                          );
                        });
                        if (usersFilter.length > 0) {
                          usersFilter.map(u =>
                            tmpUsers.push({
                              value: u.id,
                              label: `${u.lastname} ${u.firstname} ${
                                u.main_company
                                  ? ` - ${u.main_company.name}`
                                  : ''
                              }`
                            })
                          );
                        }
                      });
                      setFormData({
                        ...formData,
                        recipient_roles: value,
                        recipient_users: tmpUsers
                      });
                      setTrigger(Date.now());
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="roles">
                  <Form.Label>
                    Ajouter des membres via leur <b>notifications</b>{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Vous pouvez automatiquement ajouter des utilisateurs
                          dans la liste de gauche en sélectionnant un ou
                          plusieurs rôles (notifications). Tous les rôles
                          sélectionnés ci-dessous permettront d'inviter les
                          futurs membres correspondants.
                          <br />
                          Exemple : je sélectionne Codir ci-dessous. Dans 3
                          semaines, je créer un nouveau membre Jean Dupont. Si
                          Jean Dupont fait partie du Codir, il vous sera proposé
                          automatiquement de l'ajouter comme destinataire.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={roles}
                    placeholder="Rechercher..."
                    isMulti
                    name="notifications"
                    classNamePrefix="react-select"
                    value={formData.recipient_notifications}
                    onChange={value => {
                      let tmpUsers = formData.recipient_users;
                      value.map(v => {
                        let usersFilter = usersFromApi.filter(u => {
                          return (
                            u.notifications &&
                            u.notifications.length > 0 &&
                            u.notifications.filter(r => r.id === v.value)
                              .length > 0 &&
                            tmpUsers.filter(t => t.value === u.id).length === 0
                          );
                        });
                        if (usersFilter.length > 0) {
                          usersFilter.map(u =>
                            tmpUsers.push({
                              value: u.id,
                              label: `${u.lastname} ${u.firstname} ${
                                u.main_company
                                  ? ` - ${u.main_company.name}`
                                  : ''
                              }`
                            })
                          );
                        }
                      });
                      setFormData({
                        ...formData,
                        recipient_notifications: value,
                        recipient_users: tmpUsers
                      });
                      setTrigger(Date.now());
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3">
                <Form.Group controlId="roles">
                  <Form.Label>
                    Inviter des <b>société</b> via leurs <b>Tred Contact</b> :{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Vous pouvez automatiquement inviter des entreprises
                          via leurs contacts TRED. Tous les contacts TRED
                          sélectionnés recevront un lien d'invitation à faire
                          circuler dans leur entreprise.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={companies}
                    placeholder="Rechercher..."
                    isMulti
                    name="roles"
                    classNamePrefix="react-select"
                    value={formData.recipient_companies}
                    onChange={value => {
                      /*let tmpUsers = formData.recipient_users;
                      value.map(v => {
                        let usersFilter = usersFromApi.filter(u => {
                          return (
                            (u.main_company?.id === parseInt(v.value) ||
                              (u.secondary_companies &&
                                u.secondary_companies.length > 0 &&
                                u.secondary_companies.some(
                                  r => parseInt(r.id) === parseInt(v.value)
                                ))) &&
                            u.roles.some(role => role.id === 2) &&
                            tmpUsers.every(
                              t => parseInt(t.value) !== parseInt(u.id)
                            )
                          );
                        });
                        if (usersFilter.length > 0) {
                          usersFilter.map(u =>
                            tmpUsers.push({
                              value: u.id,
                              label: `${u.lastname} ${u.firstname} ${
                                u.main_company
                                  ? ` - ${u.main_company.name}`
                                  : ''
                              }`
                            })
                          );
                        }
                      });*/
                      setFormData({
                        ...formData,
                        recipient_companies: value
                        //recipient_users: tmpUsers
                      });
                      setTrigger(Date.now());
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="mt-3 mb-7">
                <Form.Group controlId="roles">
                  <Form.Label>
                    Ajouter des <b>partenaires</b> via leur <b>rôle</b>{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="overlay-trigger-example">
                          Vous pouvez automatiquement ajouter des partenaires
                          dans la liste de gauche en sélectionnant un ou
                          plusieurs rôles.
                        </Tooltip>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                          fill="rgba(100,99,99,1)"
                        />
                      </svg>
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={partnerRoles}
                    placeholder="Rechercher..."
                    isMulti
                    name="partner_roles"
                    classNamePrefix="react-select"
                    value={formData.recipient_partner_roles}
                    onChange={value => {
                      let tmpUsers = formData.recipient_users;
                      value.map(v => {
                        let usersFilter = usersFromApi.filter(u => {
                          return (
                            u.partner_roles &&
                            u.partner_roles.length > 0 &&
                            u.partner_roles.filter(r => r.id === v.value)
                              .length > 0 &&
                            tmpUsers.filter(t => t.value === u.id).length === 0
                          );
                        });
                        if (usersFilter.length > 0) {
                          usersFilter.map(u =>
                            tmpUsers.push({
                              value: u.id,
                              label: `${u.email} - PARTENAIRE - ${u.partner?.name}`
                            })
                          );
                        }
                      });
                      setFormData({
                        ...formData,
                        recipient_partner_roles: value,
                        recipient_users: tmpUsers
                      });
                      setTrigger(Date.now());
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          {manageLinkCompanies === true && (
            <Col xs={12}>
              <ManageRecipientsCompanies
                formData={formData}
                setFormData={setFormData}
                companies={companies}
              />
            </Col>
          )}
        </Row>
      </Col>
      {recipientTemplates.length > 0 && (
        <Offcanvas
          show={showImportTemplate}
          onHide={() => {
            setShowImportTemplate(false);
          }}
          placement={'end'}
          style={{ width: '900px' }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Importer une liste</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Alert variant="warning">
              Choisir une liste ci-dessous écrasera votre sélection de
              destinataires actuelle.
            </Alert>
            <Table responsive className="table-stats-tracks">
              <thead>
                <tr>
                  <th scope="col">Liste</th>
                  <th scope="col">Auteur</th>
                  <th scope="col">Création</th>
                  <th scope="col">Dern. modification</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {recipientTemplates.map((template, index) => (
                  <tr key={index}>
                    <td>{template.title}</td>
                    <td>
                      {template.creator?.id && (
                        <>
                          {template.creator.firstname}{' '}
                          {template.creator.lastname}
                        </>
                      )}
                    </td>
                    <td>
                      {moment(template.created_at).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      {moment(template.updated_at).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      <a
                        onClick={async () => {
                          setSelectedTemplate(template);
                          setTemplateFormData({
                            ...templateFormData,
                            title: template.title
                          });

                          let recipientRolesArray = [];
                          template.role_recipients.map(role => {
                            recipientRolesArray.push({
                              value: `${role.id}`,
                              label: role.title
                            });
                          });

                          let recipientPartnerRolesArray = [];
                          template.partner_role_recipients.map(role => {
                            recipientPartnerRolesArray.push({
                              value: `${role.id}`,
                              label: role.title
                            });
                          });

                          let recipientNotificationsArray = [];
                          template.notification_recipients.map(role => {
                            recipientNotificationsArray.push({
                              value: `${role.id}`,
                              label: role.title
                            });
                          });

                          let recipientCompaniesArray = [];
                          template.company_recipients.map(company => {
                            recipientCompaniesArray.push({
                              value: `${company.id}`,
                              label: company.name
                            });
                          });

                          const responseCompanies = await IndexAdminInvoices();
                          let recipientUsersArray = [];
                          template.internal_recipients.map(user => {
                            let tmpPivotCompanies = [];
                            if (user.pivot && user.pivot.companies) {
                              let userPivotCompanies = JSON.parse(
                                user.pivot.companies
                              );
                              userPivotCompanies.map(c => {
                                let company = responseCompanies.data.find(
                                  comp => comp.id === c
                                );
                                if (company) {
                                  tmpPivotCompanies.push({
                                    value: company.id,
                                    label: company.name
                                  });
                                }
                              });
                            }
                            recipientUsersArray.push({
                              value: `${user.id}`,
                              label:
                                user.partner && user.partner.id
                                  ? `${user.email} - PARTENAIRE - ${user.partner.name}`
                                  : `${user.lastname} ${user.firstname} ${
                                      user.main_company
                                        ? ` - ${user.main_company.name}`
                                        : ''
                                    }`,
                              companies: tmpPivotCompanies
                            });
                          });

                          let recipientExternalUsersArray = [];
                          template.external_recipients.map(external => {
                            let tmpPivotCompanies = [];
                            if (external.pivot && external.pivot.companies) {
                              let userPivotCompanies = JSON.parse(
                                external.pivot.companies
                              );
                              userPivotCompanies.map(c => {
                                let company = responseCompanies.data.find(
                                  comp => comp.id === c
                                );
                                if (company) {
                                  tmpPivotCompanies.push({
                                    value: company.id,
                                    label: company.name
                                  });
                                }
                              });
                            }
                            recipientExternalUsersArray.push({
                              value: external.id,
                              label: `${external.company_name || ''} ${
                                external.lastname || ''
                              } ${external.firstname || ''} ${external.email}`,
                              companies: tmpPivotCompanies
                            });
                          });

                          let recipientInformativeUsersArray = [];
                          template.informative_recipients.map(email => {
                            recipientInformativeUsersArray.push(email.email);
                          });

                          setFormData({
                            ...formData,
                            recipient_roles: recipientRolesArray,
                            recipient_partner_roles: recipientPartnerRolesArray,
                            recipient_notifications:
                              recipientNotificationsArray,
                            recipient_companies: recipientCompaniesArray,
                            recipient_users: recipientUsersArray,
                            recipient_externalUsers:
                              recipientExternalUsersArray,
                            recipient_informativeUsers:
                              recipientInformativeUsersArray
                          });

                          setShowImportTemplate(false);
                        }}
                        className="cursor-pointer"
                      >
                        Choisir
                      </a>
                    </td>
                    <td>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="overlay-trigger-example">
                            Supprimer la liste
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className="cursor-pointer"
                          onClick={() => {
                            deleteTemplate(template);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="14"
                            height="14"
                            style={{
                              marginBottom: '1px',
                              marginLeft: '3px'
                            }}
                          >
                            <path
                              d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                              fill="#e63757"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={() => {
                setShowImportTemplate(false);
              }}
            >
              Annuler
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <Offcanvas
        show={showExportTemplate}
        onHide={() => {
          setShowExportTemplate(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Enregistrer une liste</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && <FormAlert variant="danger" data={errorForm} />}
          <Form.Group controlId="title">
            <Form.Label>Titre de la liste</Form.Label>
            <Form.Control
              value={templateFormData.title}
              type="text"
              name="title"
              placeholder="Titre"
              onChange={e => {
                setTemplateFormData({
                  ...templateFormData,
                  title: e.target.value
                });
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            className="admin-primary mt-3"
            size="sm"
            disabled={isLoading}
            onClick={handleSubmitTemplate}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              style={{ marginBottom: '2px', marginRight: '2px' }}
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                fill="rgba(255,255,255,1)"
              />
            </svg>{' '}
            {selectedTemplate.id
              ? `Mettre à jour la liste`
              : `Enregistrer la liste`}
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageRecipients.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  isEvent: PropTypes.bool,
  manageLinkCompanies: PropTypes.bool
};

export default ManageRecipients;
