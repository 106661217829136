import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: ''
};

export const purchaseDeclarationsSlice = createSlice({
  name: 'purchase-declarations',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeSearch } = purchaseDeclarationsSlice.actions;

export default purchaseDeclarationsSlice.reducer;
