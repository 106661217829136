import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Offcanvas
} from 'react-bootstrap';

import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  PostTradePress,
  PutTradePress,
  ShowTradePress
} from '../../../../../services/PostTradePressService';
import FormAlert from '../../../commons/FormAlert';
import IconAlert from '../../../../common/IconAlert';
import FileUploader from '../../../commons/fileUploader';
import classNames from 'classnames';
import { DeleteMedia } from '../../../../../services/PostMediaService';
import { DeleteMediaTradePress } from '../../../../../services/PostMediaTradePressService';
/*import { Editor } from '@tinymce/tinymce-react';*/
const ManageTradePress = () => {
  const { id } = useParams();
  const [medias, setMedias] = useState([]);
  // bool
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  // object
  const [tradePost, setTradePost] = useState({});
  const [selectedMediaIndex, setSelectedMediaIndex] = useState({});
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    files: []
  });
  const addMedia = () => {
    const list = [...medias];
    list.push({
      file_url: '',
      post_id: id
    });
    setMedias(list);
  };
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  /*  const handleEditorChange = content => {
      setFormData(prevState => ({
        ...prevState,
        content: content
      }));
    };*/
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    setErrorForm(false);
    let response = false;
    if (id) {
      response = await PutTradePress(id, formData);
    } else {
      response = await PostTradePress(formData);
    }
    setIsLoading(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    if (response.success === true) {
      const successMessage = `Presse spécialiséé ${response.data.title} enregistrée.`;
      toast(successMessage);
      setTimeout(function () {
        window.location.href = `/administration/presse-specialisee`;
      }, 1500);
      if (!id) {
        setTimeout(function () {
          window.location.href = `/administration/presse-specialisee/${response.data.id}/modifier`;
        }, 2000);
      }
    } else {
      toast('Une erreur est survenue.');
    }
  };

  const removeMedia = async () => {
    if (medias[selectedMediaIndex].id) {
      let response = await DeleteMediaTradePress(medias[selectedMediaIndex].id);
      if (response.success === true) {
        window.location.reload();
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else {
      const list = [...medias];
      list.splice(selectedMediaIndex, 1);
      setMedias(list);
    }
  };

  useEffect(() => {
    const fecthOneTradePress = async () => {
      const response = await ShowTradePress(id);
      if (response.success) {
        setTradePost(response.data);
        setFormData({
          title: response.data.title,
          content: response.data.content
        });
        setMedias([...response.data.medias]);
      }
    };
    if (id) {
      fecthOneTradePress();
    }
  }, []);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="justify-content-between align-items-center">
              <Col lg={'auto'}>
                <h3 className="text-primary admin-title mb-0">
                  {tradePost.id !== null && tradePost.id !== undefined
                    ? `Modifier la presse spécialisée`
                    : 'Ajouter une presse spécialisée'}
                </h3>
              </Col>
              <Col className="text-end">
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  onClick={() => {
                    window.location.href = '/administration/presse-specialisee';
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    style={{ marginBottom: '2px' }}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                  </svg>
                  {!id ? 'Annuler' : 'Retour'}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {errorForm && (
          <div className="mt-3">
            <FormAlert variant="danger" data={errorForm} />
          </div>
        )}
        <Card className="mt-3">
          <Card.Body className="overflow-hidden px-lg-3">
            <Row className="justify-content-between align-items-center">
              <Form>
                <Col md="12">
                  <Form.Group controlId="name">
                    <Form.Label>Titre :</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Titre de la presse spécialisée"
                      value={formData.title}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="12" className="mt-3">
                  <Form.Group controlId="contact_website">
                    <Form.Label>Contenu texte :</Form.Label>
                    {/*   <Editor
                      value={formData.content}
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      init={{
                        language: 'fr_FR',
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                          'link'
                        ],
                        toolbar:
                          'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'link',
                        content_style:
                          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                      onEditorChange={handleEditorChange}
                    />*/}
                    <Form.Control
                      as="textarea"
                      name="content"
                      value={formData.content}
                      rows={3}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
              </Form>
              <Col md="12" className="pb-2 pt-3 mt-2">
                <div className="border-dashed-bottom"></div>
              </Col>
              {!id ? (
                <Col md={12}>
                  <IconAlert variant="info" className="mb-0">
                    <p className="mb-0">
                      Enregistrez l'actualité pour pouvoir y ajouter des
                      fichiers (images, PDF).
                    </p>
                  </IconAlert>
                </Col>
              ) : (
                <Col md={12}>
                  <h5 className="admin-title companyRightManagePeople">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginRight: '4px', marginBottom: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M3 21a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H20a1 1 0 0 1 1 1v3h-2V7h-7.414l-2-2H4v11.998L5.5 11h17l-2.31 9.243a1 1 0 0 1-.97.757H3zm16.938-8H7.062l-1.5 6h12.876l1.5-6z"
                        fill="rgba(75,74,77,1)"
                      />
                    </svg>
                    Médias :
                  </h5>
                  <Row className="mt-3">
                    {medias.length > 0 && (
                      <>
                        <Offcanvas
                          show={showDelete}
                          onHide={() => {
                            setShowDelete(false);
                          }}
                          placement={'end'}
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              La suppression de média est définitive.
                              <br />
                              Voulez-vous continuer ?
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Button
                              size="sm"
                              variant="falcon-danger"
                              className="me-2"
                              onClick={() => {
                                setShowDelete(false);
                                removeMedia(selectedMediaIndex);
                              }}
                            >
                              Oui, supprimer
                            </Button>
                            <Button
                              size="sm"
                              variant="falcon-default"
                              className="me-2"
                              onClick={() => {
                                setShowDelete(false);
                              }}
                            >
                              Annuler
                            </Button>
                          </Offcanvas.Body>
                        </Offcanvas>
                        {medias.map((media, indexMedia) => (
                          <Col xs={12} key={indexMedia} className="mb-4">
                            <Row className="align-items-center">
                              <Col xs={5}>
                                {media.file_url ? (
                                  <FileUploader
                                    pathToUpload="posts"
                                    format="any"
                                    postRoute={`trade-press-post-medias?post_id=${id}`}
                                    cropperEnabled={false}
                                    currentImage={media.file_url}
                                  />
                                ) : (
                                  <FileUploader
                                    pathToUpload="posts"
                                    format="any"
                                    postRoute={`trade-press-post-medias?post_id=${id}`}
                                    cropperEnabled={false}
                                  />
                                )}
                              </Col>
                              <Col xs={7}>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  className="me-0 mb-1"
                                  type="button"
                                  onClick={() => {
                                    setSelectedMediaIndex(indexMedia);
                                    setShowDelete(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                    style={{
                                      marginBottom: '2px'
                                    }}
                                  >
                                    <path
                                      d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                      fill="rgba(255,255,255,1)"
                                    />
                                  </svg>
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </>
                    )}
                    <Col
                      xs={12}
                      className={classNames('', {
                        'mt-3': medias.length > 0
                      })}
                    >
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          addMedia();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{
                            marginBottom: '2px',
                            marginRight: '3px'
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                        </svg>
                        Ajouter un {medias.length > 0 ? 'autre' : ''} média
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>

        <Col xs={12} className="mt-3">
          <Card>
            <Card.Body>
              <Row className="flex-between-center">
                <Col xs="auto">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    onClick={() => {
                      window.location.href =
                        '/administration/presse-specialisee';
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      style={{ marginBottom: '2px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                    {!id ? 'Annuler' : 'Retour'}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    size="sm"
                    variant="primary"
                    className="me-2 admin-primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      style={{ marginBottom: '2px', marginRight: '4px' }}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Form>
    </>
  );
};

export default ManageTradePress;
