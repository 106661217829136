import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'resources';

export async function IndexResourcePartner() {
  return modelService.IndexModel(`${model}?context=partner`);
}

export async function IndexResourceAdmin() {
  return modelService.IndexModel(`${model}?context=admin`);
}

export async function IndexResourceNotModeratedAdmin() {
  return modelService.IndexModel(`${model}?context=admin.countModerated`);
}

export async function ShowResource(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function ShowResourceByOldLink(filename) {
  return modelService.ShowModel(
    `${model}/showoldlinkresource?byFilename=${filename}`
  );
}

export async function ShowResourceAdmin(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostResource(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutResource(id) {
  return modelService.PutModel(`${model}/${id}`, {
    is_moderated: 1
  });
}

export async function PutResourceAdmin(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteResource(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function ExportStatsVues(form) {
  return modelService.PostModel(`${model}/export-stats-vues`, form);
}
