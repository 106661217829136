import React, { Fragment, useEffect } from 'react';
import Section from '../../../common/Section';
import { Button, Col, Image, Row, Spinner } from 'react-bootstrap';
import bgMoteurRecherche from '../../../../assets/img/tredunion/bg-moteur-recherche.jpg';
import illuCamion from '../../../../assets/img/tredunion/illu-camion.png';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { IndexFrenchStates } from '../../../../services/FrenchStateService';
import { IndexCeeCountries } from '../../../../services/CeeCountryService';
import ExportMoteurRechercheHome from '../../../../exports/moteurRechercheHome';

const MoteurRecherche = ({ companies }) => {
  let navigate = useNavigate();
  const [states, setStates] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ceeCountries, setCeeCountries] = React.useState([]);
  const [companiesResults, setCompaniesResults] = React.useState([]);
  const [formData, setFormData] = React.useState({
    from: null,
    to: null
  });
  const [search, setSearch] = React.useState({
    from: null,
    to: null
  });
  useEffect(() => {
    const fetchStates = async () => {
      const responseStates = await IndexFrenchStates();
      if (responseStates.success === true) {
        let states = [
          {
            label: 'Peu importe',
            value: null
          }
        ];
        responseStates.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(state => {
            states.push({
              label: state.name,
              value: state.value
            });
          });
        setStates(states);
      }
    };
    const fetchCeeCountries = async () => {
      const responseCeeCountries = await IndexCeeCountries();
      if (responseCeeCountries.success === true) {
        setCeeCountries(responseCeeCountries.data);
      }
    };
    fetchStates();
    fetchCeeCountries();
  }, []);

  useEffect(() => {
    if (search.from && search.to) {
      setIsLoading(true);
      let results = companies.filter(company => {
        let checkFrom =
          search.from.value === null ||
          company.department?.name === search.from.value;
        let checkTo = false;
        if (search.to.value.includes('state_')) {
          checkTo = company.french_states?.find(
            state => state.name === search.to.label
          );
        } else {
          checkTo = company.cee_countries?.find(
            ceeCountry => ceeCountry.name === search.to.label
          );
        }
        return checkFrom && checkTo;
      });
      setTimeout(() => {
        setCompaniesResults(results);
        setIsLoading(false);
      }, 900);
    }
  }, [search]);
  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-end p-1 mt-6"
        id="adherents"
        style={{ backgroundImage: `url("${bgMoteurRecherche}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2 style={{ lineHeight: '70px' }}>
              MOTEUR DE RECHERCHE <br />
              <span>
                <b>Prestations des adhérents Tred Union</b>
              </span>
            </h2>
          </Col>
        </Row>
      </Section>
      {states.length && (
        <Section
          bg="light"
          className="py-3 mb-5 pt-5"
          id="section-slider-full-companies"
        >
          <Row className="align-items-end">
            <Col xs={12} className="mb-3">
              <h4 id="search-data">
                <b>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="28"
                    height="28"
                    fill="currentColor"
                    style={{
                      marginRight: '10px',
                      position: 'relative',
                      top: '-2px'
                    }}
                  >
                    <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
                  </svg>
                  Je recherche un transporteur...
                </b>
              </h4>
            </Col>
            <Col xs={6} md={4}>
              <p className="mb-2">
                <b>Dans le département :</b>
              </p>
              <Select
                closeMenuOnSelect={true}
                options={states}
                placeholder="Choisir un département..."
                name="from"
                classNamePrefix="react-select"
                value={formData.from}
                onChange={value => {
                  setFormData({
                    ...formData,
                    from: value
                  });
                }}
              />
            </Col>
            <Col xs={6} md={4}>
              <p className="mb-2">
                <b>Qui dessert :</b>
              </p>
              <Select
                closeMenuOnSelect={true}
                options={states
                  .filter(state => state.label !== 'Peu importe')
                  .map(state => ({
                    label: state.label,
                    value: `state_${state.value}`
                  }))
                  .concat(
                    ceeCountries
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(ceeCountry => ({
                        label: ceeCountry.name,
                        value: `country_${ceeCountry.value}`
                      }))
                  )}
                placeholder="Département ou pays..."
                name="to"
                classNamePrefix="react-select"
                value={formData.to}
                onChange={value => {
                  setFormData({
                    ...formData,
                    to: value
                  });
                }}
              />
            </Col>
            <Col xs={12} md={4} className="mt-3 mt-md-0">
              <Button
                variant="primary"
                disabled={!formData.from || !formData.to}
                size="lg"
                style={{ display: 'block', margin: 'auto', width: '100%' }}
                className="big"
                onClick={() => {
                  setSearch(formData);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  fill="currentColor"
                  style={{
                    marginRight: '10px',
                    position: 'relative',
                    top: '-2px'
                  }}
                >
                  <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"></path>
                </svg>
                Lancer la recherche
              </Button>
            </Col>
          </Row>
          {search.from && search.to && (
            <Row className="mt-4 mt-md-5">
              <Col xs={12}>
                <h4>
                  <b>Résultats</b>
                  {companiesResults.length > 0 ? (
                    <ExportMoteurRechercheHome companies={companiesResults} />
                  ) : (
                    ''
                  )}
                </h4>
              </Col>
              {isLoading ? (
                <Col xs={12} className="mt-3">
                  <Spinner animation="border" role="status" size="xs">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              ) : (
                <Fragment>
                  {companiesResults.length === 0 ? (
                    <Col xs={12} className="mt-3">
                      <p>Aucun résultat trouvé</p>
                    </Col>
                  ) : (
                    <Col xs={12} className="mt-3">
                      <Row className="align-items-center">
                        <Fragment>
                          {companiesResults.map((company, index) => (
                            <Col xs={3} key={index}>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/adherents/${company.id}`}
                              >
                                <Image
                                  className="px-2 cursor-pointer"
                                  fluid
                                  rounded
                                  src={company.logo_url}
                                  alt=""
                                />
                              </a>
                            </Col>
                          ))}
                        </Fragment>
                      </Row>
                    </Col>
                  )}
                </Fragment>
              )}
            </Row>
          )}
        </Section>
      )}
    </>
  );
};

MoteurRecherche.propTypes = {
  companies: PropTypes.array
};

export default MoteurRecherche;
