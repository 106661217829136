import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import IconAlert from '../../../common/IconAlert';

const Questions = ({
  questions,
  questionGroups,
  setQuestions,
  setQuestionGroups,
  hideOtherQuestions,
  setHideOtherQuestions,
  company,
  companies,
  setCompanies,
  isExport,
  title,
  event
}) => {
  function hexToRgbA(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }
  return (
    <Col xs={12} className={isExport === true ? 'px-3 py-3' : ''}>
      {title && (
        <h4 className="mb-2">
          <small>{title}</small>
        </h4>
      )}
      {questions.length > 0 && (
        <Row>
          {questions.map((question, index) => (
            <Question
              question={question}
              questions={questions}
              setQuestions={setQuestions}
              index={index}
              hideOtherQuestions={hideOtherQuestions}
              setHideOtherQuestions={setHideOtherQuestions}
              key={index}
              company={company}
              companies={companies}
              setCompanies={setCompanies}
              event={event}
            />
          ))}
        </Row>
      )}
      {((hideOtherQuestions === false && !company) ||
        (company &&
          companies[companies.findIndex(c => c.id === company.id)]
            .hideOtherQuestions !== true)) &&
        questionGroups.length > 0 && (
          <Row className="mt-3">
            {questionGroups.map((questionGroup, indexGroup) => (
              <Col key={indexGroup} xs={12} className="mb-3">
                <Card>
                  <Card.Body
                    style={
                      questionGroup.color
                        ? {
                            backgroundColor: `${hexToRgbA(
                              questionGroup.color,
                              0.2
                            )}`
                          }
                        : {}
                    }
                  >
                    <Card.Title
                      className="pb-3"
                      style={{ fontFamily: 'Open Sans' }}
                    >
                      {indexGroup + 1 + '. '}
                      {questionGroup.title}
                    </Card.Title>
                    {questionGroup.questions.length > 0 && (
                      <>
                        {questionGroup.questions.map((question, index) => (
                          <Question
                            question={question}
                            questions={questionGroup.questions}
                            setQuestions={setQuestions}
                            indexGroup={indexGroup}
                            index={index}
                            hideOtherQuestions={hideOtherQuestions}
                            setHideOtherQuestions={setHideOtherQuestions}
                            key={index}
                            group={questionGroup}
                            questionGroups={questionGroups}
                            setQuestionGroups={setQuestionGroups}
                            company={company}
                            companies={companies}
                            setCompanies={setCompanies}
                          />
                        ))}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
    </Col>
  );
};

const Question = ({
  question,
  questions,
  setQuestions,
  hideOtherQuestions,
  setHideOtherQuestions,
  index,
  indexGroup,
  group,
  questionGroups,
  setQuestionGroups,
  company,
  companies,
  setCompanies,
  event
}) => {
  const [valueSelector, setValueSelector] = React.useState('');
  const [commentSelector, setCommentSelector] = React.useState('');
  const [trigger, setTrigger] = React.useState(Date.now());
  useEffect(() => {
    if (company) {
      setValueSelector(`value_${company.id}`);
      setCommentSelector(`comment_${company.id}`);
    } else {
      setValueSelector('value');
      setCommentSelector('comment');
    }
  }, [company, trigger]);
  return (
    <>
      {valueSelector && (
        <div>
          {(question.is_participation_form_main_question === 1 ||
            (question.type && question.type.slug === 'decisive') ||
            (!group && !company && hideOtherQuestions === false) ||
            (group && !company && group.hidden !== true) ||
            (group && company && !group[`hidden_company_${company.id}`]) ||
            (!group &&
              company &&
              companies[companies.findIndex(c => c.id === company.id)]
                .hideOtherQuestions !== true)) && (
            <Col xs={12} className="mb-3">
              <Card>
                <Card.Body>
                  <p className="smaller-p">
                    <b>
                      {index + 1}. {question.title}
                      {question.required === 1 ? (
                        <span style={{ color: '#E52F12' }}>*</span>
                      ) : (
                        ''
                      )}
                    </b>
                  </p>
                  {question.type && (
                    <>
                      {question.type.slug === 'text' ? (
                        <Form.Control
                          as="textarea"
                          value={
                            group
                              ? questionGroups[indexGroup].questions[index][
                                  valueSelector
                                ]
                              : questions[index][valueSelector]
                          }
                          onChange={e => {
                            if (group) {
                              let tmpQuestionGroups = [...questionGroups];
                              tmpQuestionGroups[indexGroup].questions[index][
                                valueSelector
                              ] = e.target.value;
                              setQuestionGroups(tmpQuestionGroups);
                            } else {
                              let tmpQuestions = [...questions];
                              tmpQuestions[index][valueSelector] =
                                e.target.value;
                              setQuestions(tmpQuestions);
                            }
                          }}
                          rows={3}
                        />
                      ) : question.type.slug === 'number' ? (
                        <Form.Control
                          required={true}
                          type="number"
                          onWheel={e => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          placeholder="1"
                          value={
                            group
                              ? questionGroups[indexGroup].questions[index][
                                  valueSelector
                                ]
                              : questions[index][valueSelector]
                          }
                          onChange={e => {
                            if (group) {
                              let tmpQuestionGroups = [...questionGroups];
                              tmpQuestionGroups[indexGroup].questions[index][
                                valueSelector
                              ] = e.target.value;
                              setQuestionGroups(tmpQuestionGroups);
                            } else {
                              let tmpQuestions = [...questions];
                              tmpQuestions[index][valueSelector] =
                                e.target.value;
                              setQuestions(tmpQuestions);
                            }
                          }}
                        />
                      ) : question.type.slug === 'checkbox' ? (
                        <>
                          {question.answers.length > 0 && (
                            <>
                              {question.answers.map((answer, indexAnswer) => (
                                <label
                                  className="cursor-pointer d-block mt-3"
                                  key={indexAnswer}
                                >
                                  <input
                                    type="checkbox"
                                    value={answer.title}
                                    className="me-1 cursor-pointer"
                                    checked={
                                      group
                                        ? questionGroups[indexGroup].questions[
                                            index
                                          ] &&
                                          questionGroups[indexGroup].questions[
                                            index
                                          ][valueSelector] &&
                                          questionGroups[indexGroup].questions[
                                            index
                                          ][valueSelector].includes(
                                            answer.title
                                          )
                                        : questions[index] &&
                                          questions[index][valueSelector] &&
                                          questions[index][
                                            valueSelector
                                          ].includes(answer.title)
                                    }
                                    onChange={e => {
                                      if (group) {
                                        let tmpQuestionGroups = [
                                          ...questionGroups
                                        ];
                                        if (
                                          !tmpQuestionGroups[indexGroup]
                                            .questions[index][valueSelector]
                                        ) {
                                          tmpQuestionGroups[
                                            indexGroup
                                          ].questions[index][valueSelector] =
                                            [];
                                        }
                                        if (e.target.checked) {
                                          if (
                                            tmpQuestionGroups[indexGroup]
                                              .questions[index] &&
                                            tmpQuestionGroups[indexGroup]
                                              .questions[index][
                                              valueSelector
                                            ] &&
                                            !tmpQuestionGroups[
                                              indexGroup
                                            ].questions[index][
                                              valueSelector
                                            ].includes(e.target.value)
                                          ) {
                                            tmpQuestionGroups[
                                              indexGroup
                                            ].questions[index][
                                              valueSelector
                                            ].push(e.target.value);
                                          }
                                        } else {
                                          tmpQuestionGroups[
                                            indexGroup
                                          ].questions[index][valueSelector] =
                                            tmpQuestionGroups[
                                              indexGroup
                                            ].questions[index][
                                              valueSelector
                                            ].filter(q => q !== e.target.value);
                                        }
                                        setQuestionGroups(tmpQuestionGroups);
                                      } else {
                                        let tmpQuestions = [...questions];
                                        if (
                                          !tmpQuestions[index][valueSelector]
                                        ) {
                                          tmpQuestions[index][valueSelector] =
                                            [];
                                        }
                                        if (e.target.checked) {
                                          if (
                                            tmpQuestions[index] &&
                                            tmpQuestions[index][
                                              valueSelector
                                            ] &&
                                            !tmpQuestions[index][
                                              valueSelector
                                            ].includes(e.target.value)
                                          ) {
                                            tmpQuestions[index][
                                              valueSelector
                                            ].push(e.target.value);
                                          }
                                        } else {
                                          tmpQuestions[index][valueSelector] =
                                            tmpQuestions[index][
                                              valueSelector
                                            ].filter(q => q !== e.target.value);
                                        }
                                        setQuestions(tmpQuestions);
                                      }
                                    }}
                                  />
                                  {answer.title}
                                </label>
                              ))}
                            </>
                          )}
                        </>
                      ) : question.type.slug === 'radio' ||
                        question.type.slug === 'decisive' ? (
                        <>
                          {question.answers.length > 0 && (
                            <>
                              {question.answers
                                .filter(a => {
                                  if (
                                    a.title === 'Oui' &&
                                    question.is_participation_form_main_question &&
                                    event?.checkFull === true
                                  ) {
                                    return true;
                                  }
                                  return true;
                                })
                                .map((answer, indexAnswer) => (
                                  <Form.Check
                                    key={indexAnswer}
                                    inline
                                    type="checkbox"
                                    className={`cursor-pointer check-question-${question.id}-${valueSelector}`}
                                    name={`radio-${question.id}`}
                                    id={`radio-${answer.id}-${valueSelector}`}
                                    value={answer.title}
                                    label={answer.title}
                                    defaultChecked={
                                      (question.is_participation_form_main_question ===
                                        1 &&
                                        answer.title === 'Oui' &&
                                        question.value) ||
                                      (question[valueSelector] &&
                                        question[valueSelector] ===
                                          answer.title)
                                    }
                                    onChange={e => {
                                      Array.from(
                                        document.getElementsByClassName(
                                          `check-question-${question.id}-${valueSelector}`
                                        )
                                      ).map(e => {
                                        if (
                                          e.firstChild.id !==
                                          `radio-${answer.id}-${valueSelector}`
                                        ) {
                                          e.firstChild.checked = false;
                                        }
                                      });
                                      if (group) {
                                        let tmpQuestionGroups = [
                                          ...questionGroups
                                        ];
                                        if (e.target.checked) {
                                          tmpQuestionGroups[
                                            indexGroup
                                          ].questions[index][valueSelector] =
                                            e.target.value;
                                        } else {
                                          tmpQuestionGroups[
                                            indexGroup
                                          ].questions[index][valueSelector] =
                                            '';
                                        }
                                        setQuestionGroups(tmpQuestionGroups);
                                      } else {
                                        let tmpQuestions = [...questions];
                                        if (e.target.checked) {
                                          tmpQuestions[index][valueSelector] =
                                            e.target.value;
                                        } else {
                                          tmpQuestions[index][valueSelector] =
                                            '';
                                        }
                                        setQuestions(tmpQuestions);
                                      }
                                      if (
                                        (!group &&
                                          question.is_participation_form_main_question ===
                                            1) ||
                                        (question.type &&
                                          question.type.slug === 'decisive' &&
                                          !group)
                                      ) {
                                        if (!company) {
                                          if (e.target.value === 'Oui') {
                                            setHideOtherQuestions(false);
                                          } else {
                                            setHideOtherQuestions(true);
                                          }
                                        } else {
                                          if (e.target.value === 'Oui') {
                                            let tmpCompanies = [...companies];
                                            tmpCompanies[
                                              tmpCompanies.findIndex(
                                                c => c.id === company.id
                                              )
                                            ].hideOtherQuestions = false;
                                            if (setCompanies) {
                                              setCompanies(tmpCompanies);
                                            }
                                          } else {
                                            let tmpCompanies = [...companies];
                                            tmpCompanies[
                                              tmpCompanies.findIndex(
                                                c => c.id === company.id
                                              )
                                            ].hideOtherQuestions = true;
                                            if (setCompanies) {
                                              setCompanies(tmpCompanies);
                                            }
                                          }
                                        }
                                      }
                                      if (group) {
                                        let tmpQuestionGroups = [
                                          ...questionGroups
                                        ];
                                        if (company) {
                                          tmpQuestionGroups[indexGroup][
                                            `hidden_company_${company.id}`
                                          ] = e.target.value === 'Non';
                                        } else {
                                          tmpQuestionGroups[indexGroup].hidden =
                                            e.target.value === 'Non';
                                        }
                                        setQuestionGroups(tmpQuestionGroups);
                                      }
                                    }}
                                  />
                                ))}
                            </>
                          )}
                          {question.is_participation_form_main_question === 1 &&
                            event?.checkFull === true && (
                              <IconAlert
                                variant="warning"
                                className="smaller-p"
                              >
                                Les inscriptions sont complètes. Vous pouvez
                                soit indiquer que vous ne participerez pas à
                                l'événement soit compléter les différentes
                                questions{' '}
                                <b>pour être placé en liste d'attente</b>. Pour
                                toute question, vous pouvez contacter{' '}
                                <a href="/#contact">Tred Union</a>.
                              </IconAlert>
                            )}
                        </>
                      ) : question.type.slug === 'review' ? (
                        <>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(answer => (
                            <Form.Check
                              key={answer}
                              inline
                              type="checkbox"
                              className={`cursor-pointer check-question-${question.id}-${valueSelector}`}
                              name={`radio-${question.id}`}
                              id={`radio-${question.id}-${answer}-${valueSelector}`}
                              value={answer}
                              label={answer}
                              defaultChecked={
                                question[valueSelector] &&
                                parseInt(question[valueSelector]) === answer
                              }
                              onChange={e => {
                                Array.from(
                                  document.getElementsByClassName(
                                    `check-question-${question.id}-${valueSelector}`
                                  )
                                ).map(e => {
                                  if (
                                    e.firstChild.id !==
                                    `radio-${question.id}-${answer}-${valueSelector}`
                                  ) {
                                    e.firstChild.checked = false;
                                  }
                                });
                                if (group) {
                                  let tmpQuestionGroups = [...questionGroups];
                                  if (e.target.checked) {
                                    tmpQuestionGroups[indexGroup].questions[
                                      index
                                    ][valueSelector] = e.target.value;
                                  } else {
                                    tmpQuestionGroups[indexGroup].questions[
                                      index
                                    ][valueSelector] = '';
                                  }
                                  setQuestionGroups(tmpQuestionGroups);
                                } else {
                                  let tmpQuestions = questions;
                                  if (e.target.checked) {
                                    tmpQuestions[index][valueSelector] =
                                      e.target.value;
                                  } else {
                                    tmpQuestions[index][valueSelector] = '';
                                  }
                                  setQuestions(tmpQuestions);
                                }
                                setTrigger(Date.now());
                              }}
                            />
                          ))}
                          {((group &&
                            parseInt(
                              questionGroups[indexGroup].questions[index][
                                valueSelector
                              ]
                            ) <= 6) ||
                            (!group &&
                              parseInt(questions[index][valueSelector]) <=
                                6)) && (
                            <>
                              <p className="smaller-p mb-1 mt-2">
                                <b>
                                  Ajoutez vos remarques :{' '}
                                  {question.required === 1 ? (
                                    <span
                                      style={{
                                        color: '#E52F12'
                                      }}
                                    >
                                      *
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </b>
                              </p>
                              <Form.Control
                                as="textarea"
                                value={
                                  group
                                    ? questionGroups[indexGroup].questions[
                                        index
                                      ][commentSelector]
                                    : questions[index][commentSelector]
                                }
                                onChange={e => {
                                  if (group) {
                                    let tmpQuestionGroups = [...questionGroups];
                                    tmpQuestionGroups[indexGroup].questions[
                                      index
                                    ][commentSelector] = e.target.value;
                                    setQuestionGroups(tmpQuestionGroups);
                                  } else {
                                    let tmpQuestions = [...questions];
                                    tmpQuestions[index][commentSelector] =
                                      e.target.value;
                                    setQuestions(tmpQuestions);
                                  }
                                }}
                                rows={3}
                              />
                            </>
                          )}
                        </>
                      ) : question.type.slug === 'datetime' ? (
                        <DatePicker
                          showTimeSelect
                          name="start_date"
                          dateFormat="dd/MM/yyyy HH:mm"
                          locale="fr"
                          onChange={date => {
                            if (group) {
                              let tmpQuestionGroups = [...questionGroups];
                              tmpQuestionGroups[indexGroup].questions[index][
                                valueSelector
                              ] = date;
                              setQuestionGroups(tmpQuestionGroups);
                            } else {
                              let tmpQuestions = [...questions];
                              tmpQuestions[index][valueSelector] = date;
                              setQuestions(tmpQuestions);
                            }
                          }}
                          formatWeekDay={startDate => startDate.slice(0, 3)}
                          className="form-control"
                          placeholderText="Date / heure"
                          value={
                            group
                              ? questionGroups[indexGroup].questions[index][
                                  valueSelector
                                ]
                              : questions[index][valueSelector]
                          }
                          selected={
                            group
                              ? questionGroups[indexGroup].questions[index][
                                  valueSelector
                                ]
                              : questions[index][valueSelector]
                          }
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
      )}
    </>
  );
};

// proptypes for Questions
Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  questionGroups: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setQuestionGroups: PropTypes.func.isRequired,
  hideOtherQuestions: PropTypes.bool.isRequired,
  setHideOtherQuestions: PropTypes.func.isRequired,
  company: PropTypes.object,
  companies: PropTypes.array,
  setCompanies: PropTypes.func,
  isExport: PropTypes.bool,
  title: PropTypes.string,
  event: PropTypes.object
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  index: PropTypes.number,
  indexGroup: PropTypes.number,
  hideOtherQuestions: PropTypes.bool.isRequired,
  setHideOtherQuestions: PropTypes.func.isRequired,
  group: PropTypes.object,
  questionGroups: PropTypes.array,
  setQuestionGroups: PropTypes.func,
  company: PropTypes.object,
  companies: PropTypes.array,
  setCompanies: PropTypes.func,
  event: PropTypes.object
};

export default Questions;
