import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'point-typologies';

export async function IndexEventTypology() {
  return modelService.IndexModel(`${model}?context=event.validate.points`);
}
export async function PostTypology(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutTypology(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteTypology(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
