import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'roles';

export async function IndexRoles() {
  return modelService.IndexModel(`${model}`);
}

export async function PostRoles(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutRoles(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteRoles(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
