import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'invoice-recipients';

export async function DeleteRecipient(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}

export async function PostRecipient(formData) {
  return modelService.PostModel(model, formData);
}
