import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Offcanvas, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Moment from 'moment/moment';
import {
  DeleteHomeSlide,
  IndexHomeSlide
} from '../../../../../services/HomeSlideService';
import SoftBadge from '../../../../common/SoftBadge';

const IndexSliders = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [initialObjects, setInitialObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexHomeSlide();
      if (responseObjects.success === true) {
        setInitialObjects(responseObjects.data);
        setObjects(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const deleteObject = async () => {
    const response = await DeleteHomeSlide(selectedObject.id);
    if (response.success === true) {
      setShowDelete(false);
      toast('Slide supprimée');
      setTrigger(Date.now());
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden px-lg-3">
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <h3 className="text-primary admin-title mb-0">
                Gestion des sliders
              </h3>
            </Col>
            <Col xs={6} className="text-end">
              <Button
                variant="primary"
                className="admin-primary"
                size="sm"
                as={Link}
                to="/administration/sliders/ajouter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Ajouter une slide
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {initialObjects.length > 0 && (
        <>
          <Offcanvas
            show={showDelete}
            onHide={() => {
              setShowDelete(false);
            }}
            placement={'end'}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedObject.id !== undefined && (
                  <>L'archivage de la slide est définitif.</>
                )}
                <br />
                Voulez-vous continuer ?
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Button
                size="sm"
                variant="falcon-danger"
                className="me-2"
                onClick={() => {
                  deleteObject();
                }}
              >
                Oui, archiver
              </Button>
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                Annuler
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
          <Row className="align-items-center mt-4">
            {objects.map((object, index) => (
              <Fragment key={index}>
                <Col key={index} xs={4}>
                  <Card>
                    <Card.Img src={object.image_url} variant="top" />
                    <Card.Body>
                      <Card.Text>
                        Publiée le :{' '}
                        {Moment(object.created_at).local().format('DD/MM/YYYY')}
                      </Card.Text>
                      <SoftBadge bg="primary" className="me-2">
                        Ordre : {object.order}
                      </SoftBadge>
                      <div className="d-block mb-3"></div>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="button"
                        onClick={() => {
                          window.location.href = `/administration/sliders/${object.id}/modifier`;
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '2px',
                            marginRight: '3px'
                          }}
                        >
                          <path
                            d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Éditer
                      </Button>
                      <Button
                        size="sm"
                        variant="danger"
                        className="me-0"
                        type="button"
                        onClick={() => {
                          setShowDelete(true);
                          setSelectedObject(object);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          style={{
                            marginBottom: '2px'
                          }}
                        >
                          <path
                            d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Fragment>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default IndexSliders;
