import React, { useState } from 'react';
import Section from '../../../common/Section';
import { Col, Modal, Row } from 'react-bootstrap';
import bgMetiers from '../../../../assets/img/tredunion/bg_metiers.jpeg';
import playButton from '../../../../assets/img/tredunion/playButton.png';
import logistique from '../../../../assets/img/tredunion/metiers/logistique.png';
import palette from '../../../../assets/img/tredunion/metiers/palette.png';
import volume from '../../../../assets/img/tredunion/metiers/volume.png';
import elog from '../../../../assets/img/tredunion/metiers/elog.png';
import exceptionnel from '../../../../assets/img/tredunion/metiers/exceptionnel.png';
import vrac from '../../../../assets/img/tredunion/metiers/vrac.png';
import plateau from '../../../../assets/img/tredunion/metiers/plateau.png';
import frigo from '../../../../assets/img/tredunion/metiers/frigo.png';
import chariot from '../../../../assets/img/tredunion/metiers/chariot.png';
import cargo from '../../../../assets/img/tredunion/metiers/cargo.png';

const Metiers = () => {
  const [modalShow, setModalShow] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState(null);
  const metiers = [
    {
      key: 'palette',
      image: palette,
      youtubeId: 'mu1klDawGgQ',
      website: 'https://groupe-pole.com/fr',
      title: 'PALETTE',
      description:
        '55 adhérents et 4 plateformes logistiques, réparties idéalement sur le territoire national, permettent de massifier et de rationaliser les flux de marchandises. Grâce à une flotte de véhicules adaptée, les livraisons sont aisément effectuées en centre-ville comme en campagne.'
    },
    {
      key: 'logistique',
      image: logistique,
      youtubeId: 'nsD5pNequh8',
      website: null,
      title: 'LOGISTIQUE',
      description:
        '+ de 800 000 m2 de surface de stockage permet à TRED UNION de proposer tous les services de la logistique.'
    },
    {
      key: 'volume',
      image: volume,
      youtubeId: 'raHF35M2pJ8',
      website: null,
      title: 'VOLUME',
      description:
        '+10% de marchandises transportées. TRED VOLUME propose des solutions innovantes en complément d’une logistique nationale performante.'
    },
    {
      key: 'elog',
      image: elog,
      youtubeId: null,
      website: null,
      title: 'TRED E-LOG',
      description:
        'Vous recherchez un partenaire logistique pour externaliser vos besoins en transports et services de logistiques e-commerce ? Grace à notre réseau, réparti idéaldmeent sur le territoire national, Tred e-log vous propose des solutions e-différentes en réponse à vos besoins réels. Rejoignez-nous et devenons votre partenaire e-différent.'
    },
    {
      key: 'exceptionnel',
      image: exceptionnel,
      youtubeId: 'KoAXv46XBIU',
      website: null,
      title: 'EXCEPTIONNEL',
      description:
        "TRED UNION dispose de l'ensemble des matériels utilisés pour le transport exceptionnel et conventionnel. Plateau surbaissé, porte-chars, élévateur, bras de grue vous garantissent une réponse adaptée à tous vos besoins nationaux et internationaux."
    },
    {
      key: 'vrac',
      image: vrac,
      youtubeId: null,
      website: null,
      title: 'VRAC',
      description:
        "Fort d'un parc de plus de 2000 cartes grises, bennes, bennes céréalière, bennes renforcées, transport fond mouvant FMA, bennes travaux publics, TRED UNION, proposent compétences et expériences au profit des grands noms du secteur agricole, bâtiment et recyclage nationaux et internationaux."
    },
    {
      key: 'plateau',
      image: plateau,
      youtubeId: 'ILYCvkHsuPs',
      website: null,
      title: 'PLATEAU',
      description:
        'Sous la marque TRED PLATEAU, TRED UNION développe le transport routier par plateaux grue, plateaux sur-baissés, plateaux extensibles en toute sécurité.'
    },
    {
      key: 'frigo',
      image: frigo,
      youtubeId: 'hUHhWsoZQPM',
      website: null,
      title: 'FRIGO',
      description:
        'De -20 à +20°C, TRED FRIGO renouvelle l’offre frigorifique en France.'
    },
    {
      key: 'chariot',
      image: chariot,
      youtubeId: 'ayE3pSKsWs4',
      website: 'https://www.tred-chariot.fr/',
      title: 'CHARIOT',
      description:
        'TRED CHARIOT propose une solution globale de logistique pour la livraison de colis lourds, encombrants ou volumineux chez les professionnels et les particuliers permettant une véritable autonomie.'
    },
    {
      key: 'cargo',
      image: cargo,
      youtubeId: 'pb1uwtH9GxY',
      website: null,
      title: 'CARGO',
      description:
        "C'est le métier 1er chez Tred Union avec le lot complet et partiel en semi-remorque. 125 entreprises adhérentes réparties sur le territoire pour répondre aux besoins de transport national et européen."
    }
  ];
  return (
    <Section
      bg="light"
      className="d-flex p-lg-1"
      id="metiers"
      style={{ backgroundImage: `url("${bgMetiers}")` }}
    >
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            style={{ width: '100%' }}
            height="450"
            src={`https://www.youtube.com/embed/${youtubeVideo}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
      <Row>
        <Col xs={12} className="p-0 px-lg-3">
          <h2 className="mt-6 px-3 px-lg-0">MÉTIERS</h2>
          <div className="d-none d-xl-block">
            {metiers.map((metier, index) => {
              return (
                <div
                  key={index}
                  id={`metier_${metier.key}`}
                  className="tuile_metier"
                >
                  <div className="img">
                    <img
                      src={metier.image}
                      style={
                        metier.website === null && metier.youtubeId === null
                          ? { cursor: 'default' }
                          : {}
                      }
                      onMouseEnter={() => {
                        document.getElementById(
                          `desc_${metier.key}`
                        ).style.display = 'block';
                      }}
                      onMouseLeave={() => {
                        document.getElementById(
                          `desc_${metier.key}`
                        ).style.display = 'none';
                      }}
                      onClick={() => {
                        if (metier.website) {
                          window.open(
                            metier.website,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        } else if (metier.youtubeId) {
                          setYoutubeVideo(metier.youtubeId);
                          setModalShow(true);
                        }
                      }}
                    />
                  </div>
                  {metier.youtubeId && (
                    <div className="play">
                      <img
                        src={playButton}
                        onMouseEnter={() => {
                          document.getElementById(
                            `desc_${metier.key}`
                          ).style.display = 'block';
                        }}
                        onMouseLeave={() => {
                          document.getElementById(
                            `desc_${metier.key}`
                          ).style.display = 'none';
                        }}
                        onClick={() => {
                          setYoutubeVideo(metier.youtubeId);
                          setModalShow(true);
                        }}
                      />
                    </div>
                  )}
                  <div className="title">{metier.title}</div>
                  <div className="desc" id={`desc_${metier.key}`}>
                    {metier.description}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-block d-xl-none metiers-mobile">
            <Row className="align-items-center justify-content-center pt-4 pb-4 bg-light mw-100 m-0">
              {metiers.map((metier, index) => {
                return (
                  <Col xs={6} key={index} className="pb-4">
                    <Row className="align-items-center text-center">
                      <Col xs={12}>
                        <img
                          src={metier.image}
                          style={
                            metier.website === null && metier.youtubeId === null
                              ? { cursor: 'default' }
                              : {}
                          }
                          onMouseEnter={() => {
                            document.getElementById(
                              `desc_${metier.key}`
                            ).style.display = 'block';
                          }}
                          onMouseLeave={() => {
                            document.getElementById(
                              `desc_${metier.key}`
                            ).style.display = 'none';
                          }}
                          onClick={() => {
                            if (metier.website) {
                              window.open(
                                metier.website,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            } else if (metier.youtubeId) {
                              setYoutubeVideo(metier.youtubeId);
                              setModalShow(true);
                            }
                          }}
                        />
                      </Col>
                      <Col xs={12} className="title">
                        {metier.title}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default Metiers;
