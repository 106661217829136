import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import FormAlert from '../../../commons/FormAlert';
import {
  PostContent,
  PutContent,
  ShowContent
} from '../../../../../services/VisMaRouteContentService';
import { IndexCategories } from '../../../../../services/VisMaRouteCategoryService';
import PropTypes from 'prop-types';
import FileUploader from '../../../commons/fileUploader';

const ManageVisMaRouteContent = () => {
  const { id } = useParams();
  const [trigger, setTrigger] = useState(Date.now());
  const [object, setObject] = useState({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    vimeo_id: '',
    image_url: '',
    category_id: '',
    order: 0
  });

  useEffect(() => {
    const fetchObject = async () => {
      const responseObject = await ShowContent(id);
      if (responseObject.success === true) {
        setObject(responseObject.data);
        setFormData({
          title: responseObject.data.title,
          content: responseObject.data.content,
          image_url: responseObject.data.image_url,
          vimeo_id: responseObject.data.vimeo_id,
          order: responseObject.data.order,
          category_id: responseObject.data.category_id
        });
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchCategories = async () => {
      const responseCategories = await IndexCategories();
      if (responseCategories.success === true) {
        setCategories(responseCategories.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      fetchObject();
    }
    fetchCategories();
  }, [trigger]);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = false;
    if (id) {
      response = await PutContent(id, formData);
    } else {
      response = await PostContent(formData);
    }
    setSuccessForm(false);
    setErrorForm(response.validator);
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 250);
    setIsLoading(false);
    if (response.success === true) {
      const successMessage = `Contenu enregistré.`;
      toast(successMessage);
      setSuccessForm(successMessage);
      setTrigger(Date.now());
      if (!id) {
        setIsLoading(true);
        setTimeout(function () {
          window.location.href = `/administration/vis-ma-route/${response.data.id}/modifier`;
        }, 2500);
      }
    }
  };

  return (
    <>
      {((id && object.id !== null && object.id !== undefined) || !id) && (
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h3 className="mb-2 mb-md-0 text-primary admin-title mb-0">
                        {' '}
                        {object.id !== null && object.id !== undefined
                          ? `Modifier le contenu Vis Ma Route`
                          : 'Ajouter un contenu Vis Ma Route'}
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/vis-ma-route';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        {!id ? 'Annuler' : 'Retour'}
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '4px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Enregistrer
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {errorForm && (
                <div className="mt-3">
                  <FormAlert variant="danger" data={errorForm} />
                </div>
              )}
              {successForm && !errorForm && (
                <div className="mt-3">
                  <FormAlert
                    className="mt-3"
                    variant="success"
                    data={successForm}
                  />
                </div>
              )}
            </Col>
            <Col xs={12}>
              <Card className="mb-3 pb-3">
                <Card.Body className="bg-light">
                  <Row className="gx-2 gy-3">
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="17"
                            height="17"
                            style={{ marginRight: '4px', marginBottom: '2px' }}
                          >
                            <path
                              d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                              fill="rgba(100,99,99,1)"
                            />
                          </svg>
                          Image :
                        </Form.Label>
                        {id ? (
                          <FileUploader
                            pathToUpload="vismaroute"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="image_url"
                            cropperEnabled={false}
                            currentImage={object.image_url}
                          />
                        ) : (
                          <FileUploader
                            pathToUpload="vismaroute"
                            formData={formData}
                            setFormData={setFormData}
                            format="img"
                            databaseField="image_url"
                            cropperEnabled={false}
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="name">
                        <Form.Label>Titre :</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Titre du contenu"
                          value={formData.title}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="content">
                        <Form.Label>Description :</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="content"
                          value={formData.content}
                          rows={3}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="vimeo_id">
                        <Form.Label>Identifiant Viméo :</Form.Label>
                        <Form.Control
                          type="number"
                          onWheel={e => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                          name="vimeo_id"
                          placeholder="324563412"
                          value={formData.vimeo_id}
                          onChange={handleFieldChange}
                        />
                      </Form.Group>
                    </Col>
                    {categories.length > 0 && (
                      <>
                        <Col md="12">
                          <Form.Group controlId="category_id">
                            <Form.Label>Thème :</Form.Label>
                            <Form.Select
                              aria-label="Thème"
                              name="category_id"
                              value={formData.category_id}
                              onChange={handleFieldChange}
                            >
                              <SelectCategories
                                categories={categories}
                                indent={0}
                              />
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="order">
                            <Form.Label>Ordre dans le thème :</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="0"
                                aria-label="Ordre dans le thème"
                                aria-describedby="basic-addon2"
                                type="number"
                                onWheel={e => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                name="order"
                                value={formData.order}
                                onChange={handleFieldChange}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2"
                        onClick={() => {
                          window.location.href = '/administration/vis-ma-route';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          style={{ marginBottom: '2px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                        </svg>
                        {!id ? 'Annuler' : 'Retour'}
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-2 admin-primary"
                        type="submit"
                        disabled={isLoading}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          style={{ marginBottom: '2px', marginRight: '4px' }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                            fill="rgba(255,255,255,1)"
                          />
                        </svg>
                        Enregistrer
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

const SelectCategories = ({ categories, indent }) => {
  return (
    <>
      {categories.map((c, index) => (
        <Fragment key={index}>
          <option value={c.id}>
            {indent > 0 &&
              Array.apply(0, Array(indent)).map(function () {
                return '-';
              })}
            {c.title}
          </option>
          {c.childrens.length > 0 && (
            <SelectCategories categories={c.childrens} indent={indent + 1} />
          )}
        </Fragment>
      ))}
    </>
  );
};

SelectCategories.propTypes = {
  categories: PropTypes.array,
  indent: PropTypes.number
};

export default ManageVisMaRouteContent;
