import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportLogs = ({ logs }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    logs.map(log => {
      let cleanedCode = {
        id: log.id,
        created_at: log.created_at,
        title: log.title
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [logs]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Journal">
              <ExcelColumn label="ID" value="id" />
              <ExcelColumn label="Date" value="created_at" />
              <ExcelColumn label="Entrée" value="title" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportLogs.propTypes = {
  logs: PropTypes.array
};

export default ExportLogs;
