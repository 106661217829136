import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportInvoices = ({ objects }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    objects.map(object => {
      let cleanedCode = {
        title: object.title,
        company: object.company,
        year: object.year,
        created_at: object.created_at,
        file_url: object.file_url
      };
      tmpCleanData.push(cleanedCode);
    });
    setCleanData(tmpCleanData);
  }, [objects]);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1 w-auto float-end"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Exporter la liste
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Activités Elearning">
              <ExcelColumn label="Désignation" value="title" />
              <ExcelColumn label="Société" value="company" />
              <ExcelColumn label="Année" value="year" />
              <ExcelColumn label="Date" value="created_at" />
              <ExcelColumn label="Fichier" value="file_url" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportInvoices.propTypes = {
  objects: PropTypes.array
};

export default ExportInvoices;
