import React from 'react';
import Section from '../../../common/Section';
import { Col, Row } from 'react-bootstrap';
import bgGroupementPres from '../../../../assets/img/tredunion/bg_groupement_pres-1.jpg';
import bgGroupementPresBottom from '../../../../assets/img/tredunion/bg_groupement_pres-2.jpg';
import downloadPdf from '../../../../assets/img/tredunion/download-pdf.png';
import separateurAdherents from '../../../../assets/img/tredunion/separateur-adherents.jpeg';

const Presentation = () => {
  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-end p-1"
        id="groupement-presentation"
        style={{ backgroundImage: `url("${bgGroupementPres}")` }}
      >
        <Row>
          <Col xs={12}>
            <h2>
              GROUPEMENT <span>Présentation</span>
            </h2>
          </Col>
        </Row>
      </Section>
      <Section
        bg="light"
        className="d-flex align-items-end p-1"
        id="groupement-presentation-bottom"
        style={{ backgroundImage: `url("${bgGroupementPresBottom}")` }}
      >
        <Row>
          <Col lg={8} className="pt-4 pt-lg-7">
            <p className="text-justify">
              <b>
                Engagement, respect, convivialité, considération, solidarité
                sont les valeurs humaines fortes sur lesquelles TRED UNION s’est
                créé début 2009
              </b>
              . En moins de six ans, le groupement Tred Union a conquis plus de
              75% du territoire français évoluant dans des secteurs d’activités
              très diverses : l’industriel, le bâtiment, les travaux publics,
              l’agroalimentaire, le froid, le végétal ou encore l’e-commerce....
            </p>
            <p className="mt-5 mb-3">
              <span className="redHeading">
                <i>
                  TRED UNION, GROUPEMENT DE TRANSPORTEURS ROUTIERS ET
                  LOGISTIQUES.
                </i>
              </span>
            </p>
            <p className="smaller-p text-justify">
              <b>L’objectif de ce réseau ?</b> Développer des synergies dans les
              métiers du transport et de la logistique, être plus compétitifs et
              proposer à nos clients des solutions répondant à des
              problématiques spécifiques comme le transport frigorifique, le
              grand volume, le plateau, le transport de colis volumineux ou la
              messagerie colis et palettes.
            </p>
            <p className="smaller-p text-justify">
              <b>
                Constitué d’entreprises régionales au savoir faire et à l’image
                reconnus
              </b>
              , TRED UNION est soucieux du respect des différences et des
              cultures régionales fortes de ses adhérents. C’est au travers des
              nombreuses rencontres des dirigeants et de l’ensemble des forces
              vives des entreprises que le RESPECT de chacun se développe et
              vient sceller les axes de croissance et de renforcement des
              entrepreneurs qui composent TRED UNION.
            </p>
          </Col>
          <Col lg={4}>
            <div className="video-box">
              <iframe
                title="YouTube video player"
                src="https://www.youtube.com/embed/4VT7JN7xl9k"
                allowFullScreen="allowfullscreen"
              ></iframe>
            </div>
            <p className="mt-4 mb-2 redHeading">Nos engagements</p>
            <p className="smaller-p text-justify ">
              Être garant de l’identité et des activités économiques de nos
              adhérents. Offrir des solutions fiables, simples et économiquement
              compétitives à nos clients. Contribuer à préserver l’environnement
              en améliorant nos taux de remplissage, en réduisant les kilomètres
              inutiles et en travaillant ensemble à la réduction de CO2.
              Renforcer nos accords avec nos partenaires à travers notre cellule
              achat.
            </p>
            <p className="text-end">
              <a
                href="https://storage.googleapis.com/tredunion-plateform-storage/various/Brochure_web_TREDUNION22.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <span className="d-inline-block link-brochure pr-2 ">
                  Téléchargez
                  <br />
                  notre brochure
                </span>
                <img className="d-inline-block" src={downloadPdf} />
              </a>
            </p>
          </Col>
          <Col className="pt-4 d-none d-lg-block">
            <img
              className="mw-100 cursor-pointer"
              src={separateurAdherents}
              onClick={() =>
                document.getElementById('adherents').scrollIntoView()
              }
            />
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Presentation;
