import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import QuestionsContainer from './QuestionsContainer';

export default function PrintSurvey({
  questions,
  questionGroups,
  setQuestions,
  setQuestionGroups,
  hideOtherQuestions,
  setHideOtherQuestions,
  isLoading,
  event,
  survey
}) {
  let componentRef = useRef();
  class ComponentToPrint extends React.Component {
    render() {
      return (
        <QuestionsContainer
          isLoading={isLoading}
          hideOtherQuestions={hideOtherQuestions}
          setHideOtherQuestions={setHideOtherQuestions}
          questions={questions}
          setQuestionGroups={setQuestionGroups}
          setQuestions={setQuestions}
          questionGroups={questionGroups}
          isExport={true}
          title={event.id ? event.title : survey.title}
        />
      );
    }
  }
  return (
    <>
      <div>
        <ReactToPrint
          trigger={() => (
            <Button
              size="sm"
              variant="primary"
              className="me-2 admin-primary"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '4px' }}
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M7 19v-6h10v6h2V7.828L16.172 5H5v14h2zM4 3h13l4 4v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm5 12v4h6v-4H9z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              Imprimer / télécharger PDF
            </Button>
          )}
          content={() => componentRef}
        />

        <div className="d-none">
          <ComponentToPrint ref={el => (componentRef = el)} />
        </div>
      </div>
    </>
  );
}

PrintSurvey.propTypes = {
  questions: PropTypes.array.isRequired,
  questionGroups: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setQuestionGroups: PropTypes.func.isRequired,
  hideOtherQuestions: PropTypes.bool.isRequired,
  setHideOtherQuestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  event: PropTypes.object,
  survey: PropTypes.object
};
