import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: ''
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      state.search = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeSearch } = postsSlice.actions;

export default postsSlice.reducer;
