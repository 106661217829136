import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Offcanvas, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  IndexCategoriesAdminManage,
  PostCategoriesManage
} from '../../../../../../services/Event/CategoryService';
import FormAlert from '../../../../commons/FormAlert';
import { Circle } from 'react-color/lib/components/circle/Circle';
import SoftBadge from '../../../../../common/SoftBadge';
import PropTypes from 'prop-types';
import Select from 'react-select';

const ManageCategoriesFromEvent = ({
  showManageCategories,
  setShowManageCategories,
  setCategoriesSelect,
  setSelectedCategory,
  parentFormData,
  setParentFormData,
  categoryGroupsSelect,
  selectedCategoryGroup,
  setSelectedCategoryGroup
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    color: '',
    group_id: '',
    is_admin: 0
  });

  const handleFieldChange = e => {
    if (e.target) {
      if (e.target.name === 'is_admin') {
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked ? 1 : 0
        });
      } else {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      }
    } else if (e.hex) {
      setFormData({
        ...formData,
        color: e.hex
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    setSuccessForm(false);
    setIsLoading(true);
    let response = await PostCategoriesManage(formData);
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    if (response.success === true) {
      setIsLoading(false);
      setSuccessForm(true);

      const responseCategories = await IndexCategoriesAdminManage();
      if (responseCategories.success === true) {
        let categoriesArray = [];
        responseCategories.data.map(category => {
          categoriesArray.push({
            value: category.id,
            originalLabel: category.name,
            label: (
              <>
                <span
                  className="rounded-circle d-inline-block me-2"
                  style={{
                    backgroundColor: category.color,
                    height: '18px',
                    width: '18px',
                    position: 'relative',
                    top: '3px'
                  }}
                ></span>

                {category.name}
                {category.is_admin === 1 && (
                  <small>
                    <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                      cat. interne
                    </SoftBadge>
                  </small>
                )}
              </>
            )
          });
        });
        setCategoriesSelect(categoriesArray);
        setParentFormData({
          ...parentFormData,
          event_category_id: response.data.id,
          is_public: formData.is_admin === 1 ? 0 : parentFormData.is_public
        });
        setFormData({
          name: '',
          color: '',
          is_admin: 0
        });
        setShowManageCategories(false);
        setSelectedCategory({
          originalLabel: response.data.name,
          label: (
            <>
              <span
                className="rounded-circle d-inline-block me-2"
                style={{
                  backgroundColor: response.data.color,
                  height: '18px',
                  width: '18px',
                  position: 'relative',
                  top: '3px'
                }}
              ></span>
              {response.data.name}
              {response.data.is_admin === 1 && (
                <small>
                  <SoftBadge bg="primary" className="ms-2 me-2 p-1">
                    cat. interne
                  </SoftBadge>
                </small>
              )}
            </>
          ),
          value: parseInt(response.data.id)
        });
        toast(`Catégorie d'événement ajoutée`);
      } else {
        toast('Une erreur est survenu, veuillez réessayer plus tard');
      }
    }
  };
  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManageCategories}
        onHide={() => {
          setShowManageCategories(false);
          setFormData({
            name: '',
            color: '',
            is_admin: 0
          });
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Ajouter une catégorie</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          {successForm && !errorForm && (
            <div className="mt-3">
              <FormAlert
                className="mt-3"
                variant="success"
                data={successForm}
              />
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>
                Choisir un nom et une couleur pour la catégorie :
              </Form.Label>
              <Form.Control
                placeholder="Nom de la catégorie"
                size="sm"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleFieldChange}
              />
              <div className="d-block mt-4"></div>
              <Circle
                color={formData.color}
                onChange={handleFieldChange}
                width="100%"
              />
              <div className="d-block mt-3"></div>
              {formData.color === '' ? (
                <></>
              ) : (
                <Form.Label className="mb-3">
                  Couleur sélectionnée :
                  <span
                    className="rounded-circle d-inline-block"
                    style={{
                      backgroundColor: formData.color,
                      height: '28px',
                      width: '28px',
                      position: 'relative',
                      top: '9px',
                      left: '5px'
                    }}
                  ></span>
                </Form.Label>
              )}
              {categoryGroupsSelect.length > 0 && (
                <>
                  <Col md="12" className="mt-3 mb-3">
                    <div className="border-dashed-bottom"></div>
                  </Col>
                  <Form.Label>
                    Choisir le groupe auquel appartient la catégorie{' '}
                    <small>
                      facultatif, permet d'afficher les événements
                      correspondants dans le calendrier Excel sous le groupe
                    </small>{' '}
                    :
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    placeholder="Choisir le groupe..."
                    options={categoryGroupsSelect}
                    name="event_type_id"
                    classNamePrefix="react-select"
                    onChange={object => {
                      setFormData({
                        ...formData,
                        group_id: parseInt(object.value)
                      });
                      setSelectedCategoryGroup(object);
                    }}
                    value={selectedCategoryGroup}
                  />
                </>
              )}

              <Col md="12" className="mt-3 mb-3">
                <div className="border-dashed-bottom"></div>
              </Col>

              <Row>
                <Col className="mt-3" md="12">
                  <div>
                    <Row>
                      <Col md="auto">
                        <b className="mt-4 smaller-p">Catégorie interne</b>
                      </Col>
                      <Col md="auto" className="ps-0">
                        <Form.Group controlId="is_admin">
                          <input
                            type="checkbox"
                            id="is_admin"
                            name="is_admin"
                            value="1"
                            className="me-1"
                            checked={
                              formData.is_admin === 1 ||
                              formData.is_admin === '1' ||
                              ''
                            }
                            style={{
                              position: 'relative',
                              left: '-5px',
                              top: '2px'
                            }}
                            onChange={handleFieldChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="2"></Col>
                    </Row>
                    <Form.Label>
                      Affichage uniquement sur le calendrier des administrateurs
                    </Form.Label>
                  </div>
                </Col>
              </Row>

              <br />
              <Button
                variant="primary"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
                disabled={isLoading}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{
                    position: 'relative',
                    bottom: '1px',
                    left: '-1px',
                    paddingRight: '3px'
                  }}
                >
                  <path
                    fill="#FFFFFF"
                    d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                  />
                </svg>
                Enregistrer
              </Button>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManageCategoriesFromEvent;

ManageCategoriesFromEvent.propTypes = {
  showManageCategories: PropTypes.bool,
  setShowManageCategories: PropTypes.func,
  deleteEvent: PropTypes.func,
  setCategoriesSelect: PropTypes.func,
  setSelectedCategory: PropTypes.func,
  parentFormData: PropTypes.object,
  setParentFormData: PropTypes.func,
  categoryGroupsSelect: PropTypes.array,
  selectedCategoryGroup: PropTypes.object,
  setSelectedCategoryGroup: PropTypes.func
};
