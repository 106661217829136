import React, { useEffect, useState } from 'react';
import { Col, Form, Offcanvas, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  PostPartnerRole,
  PutPartnerRole
} from '../../../../../../services/PartnerRoleService';
import { toast } from 'react-toastify';
import FormAlert from '../../../../commons/FormAlert';

const ManageRolesPartners = ({
  showManage,
  setShowManage,
  selectedRole,
  setSelectedRole,
  setParentTrigger
}) => {
  const [formData, setFormData] = useState({
    title: ''
  });
  const [errorForm, setErrorForm] = useState(false);
  useEffect(() => {
    if (selectedRole && selectedRole.id) {
      setFormData({
        title: selectedRole.title
      });
    }
  }, [selectedRole]);
  const handleSubmit = async e => {
    e.preventDefault();
    let response = false;
    if (selectedRole && selectedRole.id) {
      response = await PutPartnerRole(selectedRole.id, formData);
    } else {
      response = await PostPartnerRole(formData);
    }
    setErrorForm(response.validator);
    if (response.success === true) {
      toast(`Rôle enregistré`);
      setParentTrigger(Date.now());
      setSelectedRole({});
      setShowManage(false);
      setFormData({
        title: ''
      });
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
    setSelectedRole({});
  };
  return (
    <>
      <Offcanvas
        show={showManage}
        onHide={() => {
          setShowManage(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedRole.id ? 'Modifier le ' : 'Ajouter un '}rôle
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            {errorForm && (
              <div className="mt-3">
                <FormAlert variant="danger" data={errorForm} />
              </div>
            )}
            <Col xs={12}>
              <Form.Group controlId="name">
                <Form.Label>Nom du rôle :</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Nom du rôle"
                  value={formData.title}
                  onChange={e => {
                    setFormData({
                      ...formData,
                      title: e.target.value
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3">
              <Button
                variant="primary"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
              >
                Enregistrer
              </Button>
            </Col>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageRolesPartners.propTypes = {
  selectedRole: PropTypes.object,
  setSelectedRole: PropTypes.func,
  showManage: PropTypes.bool,
  setShowManage: PropTypes.func,
  setParentTrigger: PropTypes.func
};

export default ManageRolesPartners;
