import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'survey-templates';

export async function IndexSurveyTemplate() {
  return modelService.IndexModel(`${model}`);
}

export async function PostSurveyTemplate(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutSurveyTemplate(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteSurveyTemplate(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
