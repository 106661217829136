import HttpService from './HttpService';

const http = new HttpService();

export async function LoginUserService(credentials) {
  let url = 'login';
  return http
    .postData(credentials, url)
    .then(data => {
      if ('token' in data) {
        localStorage.setItem('accessToken', data.token);
        localStorage.setItem('user', JSON.stringify(data.data));
      }
      return data;
    })
    .catch(error => {
      return error;
    });
}

export const LogOutUserService = () => {
  const http = new HttpService();
  let url = 'logout';
  const tokenId = 'accessToken';
  return http.postData([], url, tokenId).then(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    window.location.href = '/';
  });
};

export async function ForgotPasswordEmailService(formData) {
  let url = 'password/email';
  return http
    .postData(formData, url)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
}

export async function ForgotPasswordResetService(credentials) {
  let url = 'password/reset';
  return http
    .postData(credentials, url)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
}
