import React, { Fragment, useState } from 'react';
import { PostContractMeta } from '../../../../../services/Contract/ContractMetaService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, Form, Offcanvas } from 'react-bootstrap';
import FormAlert from '../../../commons/FormAlert';
import Select from 'react-select';
import { PostContract } from '../../../../../services/Contract/ContractService';

const AddContract = ({ setParentTrigger, partners }) => {
  const [formContract, setFormContract] = useState({
    partner_id: ''
  });
  const [showAddContract, setShowAddContract] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddPartner, setSelectedAddPartner] = useState({});
  const handleAddContractSubmit = async e => {
    e.preventDefault();
    setErrorForm(false);
    let response = await PostContract(formContract);
    if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    }
    if (response.success === true) {
      setIsLoading(false);
      setParentTrigger(Date.now());
      toast('Ligne ajoutée avec succès');
      setFormContract({
        partner_id: ''
      });
      setShowAddContract(false);
    } else {
      toast('Une erreur est survenu, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="admin-primary"
        size="sm"
        onClick={() => setShowAddContract(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
        Ajouter une ligne
      </Button>
      <Offcanvas
        placement={'end'}
        show={showAddContract}
        onHide={() => {
          setShowAddContract(false);
          setFormContract({
            partner_id: ''
          });
          setSelectedAddPartner({});
        }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Ajouter une ligne</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {errorForm && (
            <div className="mt-3">
              <FormAlert variant="danger" data={errorForm} />
            </div>
          )}
          <Form onSubmit={handleAddContractSubmit}>
            <Form.Group controlId="firstname">
              <Form.Label>Choisir une société partenaire :</Form.Label>
              {partners?.length > 0 && (
                <>
                  <Select
                    closeMenuOnSelect={true}
                    placeholder="Choisir le partenaire..."
                    options={partners}
                    name="partner_id"
                    classNamePrefix="react-select"
                    onChange={object => {
                      setFormContract({
                        ...formContract,
                        partner_id: parseInt(object.value)
                      });
                      setSelectedAddPartner(object);
                    }}
                    value={selectedAddPartner}
                  />
                </>
              )}
              <br />
              <Button
                variant="primary"
                className="admin-primary mt-0"
                size="sm"
                type="submit"
                disabled={isLoading}
              >
                Enregistrer
              </Button>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

AddContract.propTypes = {
  setParentTrigger: PropTypes.func,
  partners: PropTypes.array
};
export default AddContract;
