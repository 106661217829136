import React from 'react';
import Section from '../../../common/Section';
import PropTypes from 'prop-types';
import bgPartenaires from '../../../../assets/img/tredunion/bg_partenaires.jpeg';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Slider from 'react-slick';

const Partenaires = ({ partners }) => {
  let navigate = useNavigate();
  const sliderLogosSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    rows: 4,
    slidesToShow: 7,
    slidesToScroll: 7,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          rows: 4,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  return (
    <>
      <Section
        bg="light"
        className="d-flex align-items-end p-1"
        id="partenaires"
        style={{ backgroundImage: `url("${bgPartenaires}")` }}
      >
        <h2>PARTENAIRES</h2>
      </Section>
      <Section bg="light" className="pt-3 pb-2 section-partenaires">
        <Row className="justify-content-end">
          <Col xs={12} className="text-lg-end">
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                navigate(`/login`);
              }}
              className="btn-partenaires big"
            >
              ESPACE PARTENAIRE
            </Button>{' '}
          </Col>
        </Row>
      </Section>
      {partners.length && (
        <Section
          bg="light"
          className="py-3 mb-7 section-partenaires"
          id="section-slider-full-companies"
        >
          <Slider {...sliderLogosSettings} className="slick-slider-arrow-inner">
            {partners.map((partner, index) => (
              <Image
                className="px-2 cursor-pointer desaturate"
                fluid
                rounded
                src={partner.logo_url}
                alt=""
                key={index}
                onClick={() => {
                  window.open(
                    partner.contact_website,
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
              />
            ))}
          </Slider>
        </Section>
      )}
    </>
  );
};

Partenaires.propTypes = {
  partners: PropTypes.array
};

export default Partenaires;
