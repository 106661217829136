import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const CanvaDeclineEvent = ({
  showCanvaDeclineEvent,
  setShowCanvaDeclineEvent,
  declineEvent
}) => {
  return (
    <>
      {showCanvaDeclineEvent && (
        <Offcanvas
          show={showCanvaDeclineEvent}
          onHide={() => {
            setShowCanvaDeclineEvent(false);
          }}
          placement="end"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Confirmation de refus d'événement</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            La refus de l'événement est définitif.
            <br /> Voulez-vous continuer ?
            <Row className="mt-4 flex-start">
              {' '}
              <Col lg="12">
                <Button
                  size="sm"
                  className="admin-primary"
                  variant="falcon-danger"
                  onClick={() => {
                    declineEvent();
                  }}
                >
                  Confirmer
                </Button>
                <Button
                  variant="falcon-default"
                  size="sm"
                  className="admin-primary ms-2"
                  onClick={() => {
                    setShowCanvaDeclineEvent(false);
                  }}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default CanvaDeclineEvent;

CanvaDeclineEvent.propTypes = {
  showCanvaDeclineEvent: PropTypes.bool,
  setShowCanvaDeclineEvent: PropTypes.func,
  declineEvent: PropTypes.func
};
