import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  DeletePartnerRole,
  IndexPartnerRole
} from '../../../../../../services/PartnerRoleService';
import {
  Button,
  Offcanvas,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import ManageRolesPartners from './manage';

const IndexRolesPartners = () => {
  const [trigger, setTrigger] = useState(Date.now());
  const [showIndex, setShowIndex] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [roles, setRoles] = useState(Date.now());
  const [selectedRole, setSelectedRole] = useState({});
  useEffect(() => {
    const fetchObjects = async () => {
      const responseObjects = await IndexPartnerRole();
      if (responseObjects.success === true) {
        setRoles(responseObjects.data);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchObjects();
  }, [trigger]);

  const deleteRole = async id => {
    const responseDeleteRole = await DeletePartnerRole(id);
    if (responseDeleteRole.success === true) {
      toast('Rôle supprimé');
      setTrigger(Date.now());
      setShowDelete(false);
      setSelectedRole({});
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="me-2"
        onClick={() => {
          setTrigger(Date.now());
          setShowIndex(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          style={{ marginBottom: '2px', marginRight: '2px' }}
        >
          <path
            d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
            fill="rgba(75,74,77,1)"
          />
        </svg>
        Gérer les rôles
      </Button>

      <ManageRolesPartners
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        setShowManage={setShowManage}
        showManage={showManage}
        setParentTrigger={setTrigger}
      />

      <Offcanvas
        show={showIndex}
        onHide={() => {
          setShowIndex(false);
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Gestion des rôles partenaires{' '}
            <a
              onClick={() => setShowManage(true)}
              className="cursor-pointer ms-2"
            >
              <small>+ Ajouter</small>
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {roles && roles.length > 0 && (
            <Table borderless responsive>
              <tbody>
                {roles.map((role, index) => (
                  <tr key={index}>
                    <td>{role.title}</td>
                    <td className="text-end">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="hi">Modifier</Tooltip>}
                      >
                        <a
                          onClick={() => {
                            setSelectedRole(role);
                            setShowManage(true);
                          }}
                          className="cursor-pointer me-3"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            style={{
                              marginBottom: '1px',
                              marginLeft: '3px'
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"
                              fill="rgba(44,123,229,1)"
                            />
                          </svg>
                        </a>
                      </OverlayTrigger>
                      <span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="overlay-trigger-example">
                              Supprimer le rôle
                            </Tooltip>
                          }
                        >
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedRole(role);
                              setShowDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              style={{
                                marginBottom: '1px',
                                marginLeft: '3px'
                              }}
                            >
                              <path
                                d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"
                                fill="#e63757"
                              />
                            </svg>
                          </a>
                        </OverlayTrigger>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showDelete && selectedRole && selectedRole.id}
        onHide={() => {
          setShowDelete(false);
          setSelectedRole({});
        }}
        placement={'end'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            La suppression du rôle est définitive.
            <br />
            <br />
            Voulez-vous continuer ?
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Button
            size="sm"
            variant="falcon-danger"
            className="me-2"
            onClick={() => {
              deleteRole(selectedRole.id);
            }}
          >
            Oui, je confirme la suppression
          </Button>
          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setShowDelete(false);
              setSelectedRole({});
            }}
          >
            Annuler
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default IndexRolesPartners;
