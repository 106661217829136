import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'vis-ma-route-contents';

export async function ShowContent(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostContent(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutContent(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteContent(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
