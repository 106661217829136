import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import * as Moment from 'moment';
import IconButton from '../components/common/IconButton';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExportTracks = ({ tracks }) => {
  const [cleanData, setCleanData] = useState([]);
  let tmpCleanData = [];
  useEffect(() => {
    tracks.map(track => {
      let cleanedTrack = {
        action: track.category?.title === 'downloaded' ? 'Téléchargé' : 'Vu',
        name:
          track.user !== undefined && track.user !== null
            ? `${track.user.lastname} ${track.user.firstname}`
            : '',
        company:
          track.user !== undefined &&
          track.user !== null &&
          track.user.main_company !== null
            ? track.user.main_company?.name
            : '',
        date: Moment(track.created_at).local().format('DD/MM/YYYY HH:mm')
      };
      tmpCleanData.push(cleanedTrack);
    });
    setCleanData(tmpCleanData);
  }, []);
  return (
    <>
      {cleanData.length > 0 && (
        <>
          <ExcelFile
            element={
              <IconButton
                className="mb-1"
                variant="falcon-default"
                size="sm"
                icon={faFileExcel}
                transform="shrink-3"
              >
                Télécharger (Excel)
              </IconButton>
            }
          >
            <ExcelSheet data={cleanData} name="Statistiques">
              <ExcelColumn label="Initiateur" value="name" />
              <ExcelColumn label="Entreprise" value="company" />
              <ExcelColumn label="Action" value="action" />
              <ExcelColumn label="Date" value="date" />
            </ExcelSheet>
          </ExcelFile>
        </>
      )}
    </>
  );
};

ExportTracks.propTypes = {
  tracks: PropTypes.array
};

export default ExportTracks;
