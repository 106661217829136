import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'partners';

export async function IndexPartner() {
  return modelService.IndexModel(`${model}`);
}

export async function IndexPartnerWithTrashed() {
  return modelService.IndexModel(`${model}/index-with-trashed`);
}

export async function ShowPartner(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function IndexPartnerAdmin() {
  return modelService.IndexModel(`${model}`);
}

export async function PostPartner(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutPartner(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeletePartner(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
