import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ForgotPasswordResetService } from '../../../services/AuthService';
import FormAlert from '../commons/FormAlert';
import NavbarStandard from '../commons/NavbarStandard';
import bgLogin from '../../../assets/img/tredunion/bg_login.jpeg';
import logoTredUnion from '../../../assets/img/tredunion/logo-TRED-UNION.png';
import Section from '../../common/Section';
import FooterStandard from '../commons/FooterStandard';

const PasswordReset = () => <PasswordResetForm />;

const PasswordResetForm = ({ hasLabel }) => {
  const [searchParams] = useSearchParams();

  // State
  const [formData, setFormData] = useState({
    token: searchParams.get('token'),
    login: searchParams.get('login'),
    password: '',
    password_confirmation: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const response = await ForgotPasswordResetService(formData);
    if (response.success === true) {
      navigate(`/login?login=${formData.login}`);
      toast(
        'Mot de passe réinitialisé, vous pouvez maintenant vous connecter.'
      );
    } else if (response.validator) {
      setErrorForm(response.validator);
      setIsLoading(false);
    } else {
      setErrorForm('Lien et/ou identifiant non reconnu');
      setIsLoading(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (searchParams.get('token')) {
    return (
      <>
        <NavbarStandard />
        <Section
          bg="light"
          className="d-flex p-0 mt-0"
          style={{ backgroundImage: `url("${bgLogin}")` }}
        >
          <Row className="flex-center min-vh-100 py-0 mt-lg-0">
            <Col xs={12} lg={7}>
              <Card>
                <Card.Body className="p-4 p-sm-5">
                  <p className="text-center d-none d-lg-block">
                    <img
                      src={logoTredUnion}
                      style={{ width: '100%', maxWidth: '175px' }}
                    />
                  </p>
                  <p className="redHeading text-center">
                    Réinitialiser mon mot de passe
                  </p>
                  <Form
                    className={classNames('mt-3', { 'text-left': hasLabel })}
                    onSubmit={handleSubmit}
                  >
                    {errorForm && (
                      <FormAlert variant="danger" data={errorForm} />
                    )}
                    <Form.Control
                      name="token"
                      type="hidden"
                      value={formData.token}
                    />
                    <Form.Group className="mb-3">
                      {hasLabel && <Form.Label>Identifiant</Form.Label>}
                      <Form.Control
                        placeholder={!hasLabel ? 'Identifiant' : ''}
                        value={formData.login}
                        name="login"
                        onChange={handleFieldChange}
                        type="text"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      {hasLabel && (
                        <Form.Label>Nouveau mot de passe</Form.Label>
                      )}
                      <Form.Control
                        placeholder={!hasLabel ? 'Nouveau mot de passe' : ''}
                        name="password"
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      {hasLabel && (
                        <Form.Label>
                          Confirmation du nouveau mot de passe
                        </Form.Label>
                      )}
                      <Form.Control
                        placeholder={
                          !hasLabel
                            ? 'Confirmation du nouveau mot de passe'
                            : ''
                        }
                        name="password_confirmation"
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>

                    <Button
                      type="submit"
                      className="w-100"
                      disabled={
                        !formData.password ||
                        !formData.password_confirmation ||
                        isLoading
                      }
                    >
                      Enregistrer mon mot de passe
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Section>
        <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
          <FooterStandard />
        </div>
      </>
    );
  } else {
    return <Alert variant="error">Une erreur est survenue.</Alert>;
  }
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordReset;
